﻿@import '../../../../shared/sass/_variables.scss';

.calculate-forest-certificate-modal {
    .attention-icon {
        content: url(../../../../../content/images/svg/attention.svg);
        vertical-align: top;
        margin-left: 5px;
        margin-top: -3px;
    }

    .hide-attention {
        display: none;
    }

    margin-top: 5em;

    .modal-dialog {
        width: 62%;
    }

    .padding-top-6 {
        padding-top: 6px !important;
    }

    .padding-top-2 {
        padding-top: 2px !important;
    }

    .certificate-details {
        font-weight: bold;
    }

    .pad-20 {
        padding: 20px 20px 0px 20px;
    }

    .calculate-forest-certificate-section {
        .width-sixty {
            width: 65%;
        }

        .width-fourty {
            width: 35%;
        }

        .partial {
            padding: 0;
            margin: 0.5em;
            border: 1px solid #616161;
            border-radius: 0.5em;
            background-color: #fff;
            height: 350px;
        }

        .partial-header {
            background-color: #d1d1d1;
            text-transform: uppercase;
            font-family: "Myriad Pro Semibold";
            padding: 0.5em;
            border-radius: 0.5em 0.5em 0 0;
            margin-bottom: 1px;
        }

        .grid-header-checkbox-label {
            margin: 0;
            left: -4px;
            top: 2px;
        }

        .ag-floating-bottom-container {
            font-size: 12px;
            font-weight: bold;
        }

        .ag-row {
            background-color: #f6f6f6;
        }

        .ag-cell {
            [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                top: 5px !important;
            }

            [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                top: 2px !important;
            }
        }

        .sp-grid {

            .cell-aligned-left {
                .ag-header-group-cell-label {
                    text-align: left;
                    padding-left: 4px;
                }
            }
        }

        .button-container {
            padding: 5px;

            .btn {
                width: 45%;
                margin: 4px
            }
        }

        .tree-species-grid {
            border-bottom: 1px #B0AFB0 solid;
        }

        .certification-grid {
            padding-left: 10px;
            border-bottom: 1px #B0AFB0 solid;
        }

        .grid-container {
            border: 1px solid $sp-panel-border-color;
            border-radius: 5px;

            .grid-height {
                height: 300px;
            }

            .ag-header-cell-label {
                padding-top: 7px !important;
            }

            .ag-header-group-cell-label {
                padding-top: 7px !important;
            }
        }
    }

    .p-20 {
        padding: 20px;
    }

    .p-18 {
        padding: 18px;
    }

    .pl-0 {
        padding-left: 0px;
    }

    .pr-10 {
        padding-right: 10px;
    }

    .mt-15 {
        margin-top: 15px;
    }

    .ml-5 {
        margin-left: 5px;
    }
}
