@import '../../../shared/sass/_variables.scss';

.basic-modal-window {

    .execute-button {
        margin-left: 5px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .radio-group {
        margin: 0.5em 1em -0.5em 1em;

        input {
            margin-right: 0.3em;
        }

        label {
            margin-right: 1em;
            vertical-align: middle;
        }
    }

    .search-area {
        display: flex;

        .search-bar {
            display: flex;
            flex-grow: 6;
            border: 1px solid $gray;
            border-radius: 0.5em;
            height: 2em;
            line-height: 2em;
            overflow: hidden;
            padding: 0 0.4em;
            margin: 1em 1em 0 1em;

            span.glyphicon-search,
            span.glyphicon-remove {
                top: 5px;
            }

            input {
                border: 0;
                height: 2em;
                outline: 0;
                padding-left: 0.2em;
                width: 98%;
            }
        }

        .filtering-bar {
            align-self: flex-end;
            display: flex;
            flex-basis: 5em;
            flex-grow: 1;

            label { // center checkbox
                &:before {
                    top: 0;
                }

                &:after {
                    top: 0px;
                }
            }
        }
    }

    .grid-container {
        margin: 1em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-radius: 0.5em;
            height: 300px;

            #centerRow {
                border-radius: 0.5em !important;
            }

            .master-cell-header {
                text-transform: uppercase;
                border-right: 1px solid $darkgray;
            }

            .ag-header-cell-text {
                text-transform: uppercase;
            }

            .ag-header-cell.distribution-group-header,
            .ag-header-cell.distribution-name-header {
                text-transform: none;
            }

            .ag-floating-bottom {
                box-shadow: none;
            }

            .ag-body-viewport {
                overflow-x: hidden;
            }

            [type="checkbox"]:not(:checked) + label,
            [type="checkbox"]:checked + label {
                padding-left: 0;
            }

            .ag-header-icon {
                margin-top:6px;
            }
        }
    }

    .input-area {
        margin: 0 1em 1em 1em;

        label {
            margin-right: 1em;
        }

        input[type="date"] {
            border-radius: 4px;
            border: 1px solid #616161;
            padding-left: 0.3em;
            width: 9em;
        }
    }

    .form-group {
        padding: 0;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }
}

/**
 *   Distributions/Silviculture Grid
 */
.distribution-modal-window,
.silviculture-modal-window,
.real-estate-forestry-modal-window {

    .grid-container {
        .ag-row > .ag-cell:nth-child(3) {
            border-right: none !important;
        }
        /* default checkbox css needs to be overridden to center it */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
            top: 0;
        }

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
            top: 0px;
        }
    }
}

/**
 * Add resource Modal
 */
.add-resource-modal-window {

    .input-area {
        input[type="date"]::-webkit-clear-button {
            -webkit-appearance: none;
            display: none;
        }
    }

    .grid-container {
        .ag-selection-checkbox {
            padding-left: 5px;
        }

        .hide-checkbox .ag-selection-checkbox {
            display: none;
        }

        // for oma kalusto modal we want full lenght first row
        .ag-floating-bottom-container {
            .ag-cell {
                border: none !important;
                padding-left: 0.3em;

                &:first-child {
                    width: 100% !important;
                }
            }
        }
    }
}


/**
 *   Add new work site compartment modal
 */
.addWorkSiteCompartment-modal-window {
    .search-bar {
        border: 1px solid $gray;
        border-radius: 0.5em;
        height: 2em;
        line-height: 2em;
        overflow: hidden;
        padding: 0 0.4em;

        input {
            border: 0;
            height: 2em;
            outline: 0;
            padding-left: 0.2em;
            width: calc(100% - 36px);

            &:placeholder-shown {
                font-style: italic;
            }
        }
    }

    .grid-container {
        .ag-row > .ag-cell:nth-child(3) {
            border-right: none !important;
        }
        /* default checkbox css needs to be overridden to center it */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
            top: 0;
        }

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
            top: 4px;
        }
    }
}

/**
 *  "Tuo pohjatiedot" grid 
 */
.stand-id-modal-window {

    .grid-container {

        .stand-id-section-header {
            width: 285px !important;
        }

        .ag-body-viewport {
            overflow-x: hidden !important;
        }
    }
}

/**
 *   Add Person Grid
 */
.person-modal-window {

    .ag-body-viewport {
        overflow-x: hidden !important;
    }

    .person-name-header {
    }
}

/**
 *   Silviculture grid
 */
.silviculture-modal-window {
    .grid-container {

        .sp-grid {
            .ag-floating-bottom {
                box-shadow: none;
            }
        }

        .ag-row > .ag-cell:nth-child(2) {
            width: calc(50% - 15px) !important;
        }

        .ag-row > .ag-cell:nth-child(3) {
            width: calc(50% - 15px) !important;
            padding-left: 3px;
        }
        /* disable hovers for non-functional fields */
        .ag-body-container .ag-row {
            pointer-events: none;
        }
        /* leave hover for checkbox field */
        .index {
            cursor: hand;
            pointer-events: all;
        }
    }
}

.real-estate-forestry-modal-window {
    .grid-container {
        .sp-grid {
            .ag-floating-bottom {
                box-shadow: none;
            }
        }

        .ag-row > .ag-cell:nth-child(3) {
            padding-left: 3px;
        }
        /* disable hovers for non-functional fields */
        .ag-body-container .ag-row {
            pointer-events: none;
        }
        /* leave hover for checkbox field */
        .index {
            cursor: hand;
            pointer-events: all;
        }
    }
}

.modal-761 {
    width: 761px;
}

.modal-84em {
    width: 84em;
}

.select-bank-account-number-modal-window {
    .grid-container {
        [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
            left: 0.8px;
            top: 1px;
        }

        [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
            top: 4px;
        }

        .modal-grid {
            width: 99.99%;
        }
    }
}

.change-execution-time-and-estimate-modal-window {
    .toteutus-pvm-input {
        width: 100%;
        height: 27px;
        border: 1px solid $darkgray;
        border-radius: 4px;
        padding-left: 5px;
    }

    .max-width-label {
        width: 100%;
    }

    .modal-dialog {
        width: 400px;
        padding: 27px;
    }

    #second-modal-content {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .modal-content {
        box-shadow: none;

        .header {
            font-size: 12px;
        }

        .close-button {
            font-size: 13px;
        }

        .modal-body {
            padding: 25px;

            .toteutus-pvm-form-group {
                margin-bottom: 27px;
            }
        }
    }

    .modal-footer {
        border-top: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .btn {
            padding: 12px 12px;
            width: 147px;
        }

        .btn + .btn {
            margin-left: 10px;
        }

        .btn.btn-silva.change-execution-time-btn {
            width: 47.85%;
        }
    }
}
