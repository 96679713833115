﻿@import '../../../shared/sass/_variables.scss';

.forest-plan-comparison {

  .silva-header {
    @extend %silva-header;
    background-color: inherit;
    padding: 0.5em 0;
    margin: 0;
  }
  
  .sub-header {
    padding-bottom: 0.5em;
  }
  
  .summary-pane {
    padding: 0.5em;
    border-left: 1px solid $darkgray;
    border-right: 1px solid $darkgray;
    border-bottom: 1px solid $darkgray;
    background-color: $gray;
  }
  
  .create-new-forestplan-text {
    margin-top: 9px;
    display: block;
  }

  /* the grid */
  .forest-plan-comparison-grid {
		border: 1px solid $darkgray;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    height: 50vh; // setting retarded viewport height for "responsive" height
    @extend %no-selected-row-highlight; // no row highlighting
    
    .header-top {
      .ag-header-cell-label {
        position: absolute;
        top: -26px; // we want the headers to be aligned top in 2-row-width title areas
      }
    }
    
    .no-right-border {
      border-right: none !important;
    }
    
    // no black hover color for any of the grid rows 
    .ag-cell {
        background-color: white !important;
        color: black !important;
    }

    %text {
        width: calc(100% + 4px);
        height: calc(100% + 10px);
        border: none;
        margin-left: -4px;
        padding-left: 6px;
        margin-top: -5px;
        padding-top: 5px;
        margin-bottom: -5px;
    }

    .white-background-text {
        @extend %text;
        background-color: white;
    }

    .red-background-text {
        @extend %text;
        background-color: #FFD8D8;
    }
        
    .stands-row {
      margin-left: 2px;
      position: relative;
      display: inline-block;  // to get rid of the injected group total count
      width: 100%;            // to get rid of the injected group total count
      .total {
        left: 247px;
        position: absolute;
      }
    }
    
    .ag-body-viewport {
      overflow-x: hidden;
    }
    
    .ag-group-cell-entire-row {
      background-color: $trimble_tf_product_green;
      color: $white;
    }

    .ag-cell-focus {
      border-top: none;
      border-bottom: none;
      border-left: none;
      outline: none;
    }
      
    .ag-header-row:first-child {
      .ag-header-group-cell-no-group:last-child {
        border-bottom: 1px solid #808080;
      }
    }  
      
	  .ag-row {              
      :last-child {
        border-right: 0;
      }
	  }
	  
	  .ag-header-group-cell {
	    text-transform: uppercase;
	    
	    .ag-header-group-cell-label {
	      padding-left: 4px;
	      text-align: left;
	    }
	  }
  }
  
  .sp-grid .ag-floating-bottom {
      box-shadow: none;
  }


  /* footer buttons */
 .grid-footer {
   button:first-child {
     margin-right: 0.5em;
   }
   
   .text {
     display: inline-block;
     margin-right: 1em;
     line-height: initial;
   }
 }
}
