﻿@import '../../shared/sass/_variables.scss';

.forestry-plan-settings {

	.btn {
		padding-right: 0;
		margin-right: 7px;
	}

	.suuJakso-validation{
		.field-validation-tooltip {
			top: 30px !important;
			left: 80px !important;
		}
	}

	.selected-definition-label {
		line-height: 12px;
		margin-top: 0.2em;
	}

	//headers
		.panel {
		border: 1px solid #616161;
		border-radius: 0.5em;
		background-color: #fff;

		.panel-header {
			text-transform: uppercase;
			font-family: "Myriad Pro Semibold";
			border-radius: 0.5em 0.5em 0 0;
		}
	}

	.second-header {
		background-color: #fff;
		padding-bottom: 10px;
	}
	.border-right {
		border-right: 1px solid #616161;
	}
	
	.button-size {
		width: 170px;
		height: 40px;
	}

	.second-partial-header {
		padding: 0.5em;
		margin-bottom: 1px;
	}

	.partial-header {
		text-transform: uppercase;
		padding: 0.5em;
		margin-bottom: 0px;
	}

	.white-on-black-header {
        background-color: black;
        color: white;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        padding: 0.5em;
        width: 100%;
		height: 27px;
		padding: 0em;
        margin-bottom: 0px;
    }
	
	.subtitle {
		background-color: #d1d1d1;
        color: black;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        padding: 0.5em;
        width: 100%;
		padding: 0.5em;
        margin-bottom: 0px;
	}

	.double-subtitle-background {
		height: 50px !important;
	}

	.space-top{
		padding-top: 5px;
	}

	.header-space-top{
		padding-top: 29px;
	}

	.small-left-padding{
		padding-left: 7px !important;
	}

	.text-right-margin{
		margin-left:15px;
	}
	.right-align {
		text-align: right;
	}

	.normal {
		font-weight: normal;
	}

	.radio-group {
		margin-top: 5px;
	}

	.combo-padding {
		padding-left: 5px;
	}

	.label-margin {
		margin-bottom: 0px;
		margin-top: 4px;
	}

	.small-margin-bottom {
		margin-bottom: 4px;
	}

	.small-margin-top {
		margin-top: 3px;
	}

	.medium-margin-bottom {
		margin-bottom: 7px;
	}

	.medium-margin-top {
		margin-top: 6px;
	}

	.large-width{
		width: 99% !important;
	}

	.display-inline {
		display: inline !important;
	}

	.height-fixed {
		height: 22px !important;
	}

	.fine-border {
		border: 1px solid #616161;
	}

	.left-padding {
		padding-left: 4px !important;
	}

	.btn-group {
		border-radius: 0.2em 0.2em 0.2em 0.2em !important;
		padding-top: 1px;
	}
	.no-border {
		border: 0px;
		margin-top: 4px;
	}

	.dropdown-menu-position {
		cursor:pointer;
	}

	.ddm-fix-width-265 {
		width: 265px !important;
		margin-left: 3px;
	}

	.dd-width-140 {
		max-width: 140px  !important;
		min-width: 140px  !important;
	}

	.text-align-right{
		text-align:right;
	}

	.center {
		margin-top: 6px;
	}

	.checkbox-align-top{
		margin-top: -5px;
	}
	.dropDown-align-top{
		margin-top: -1px;
	}

	.menu-position {
		padding-left: 0px;
	}

	.width-fixed {
		width: 60px;
	}

	.form-group {
		margin-top: 7px;
	}

	.textarea {
		width: 535px;
		height: 400px;
		border-radius: 0.5em;
		border: none;
		outline: none; /* removes the default outline */
		resize: none; /* prevents the user-resizing, adjust to taste */
		margin-left: 6px;
		margin-top: 14px;
		padding: 5px;
	}

    .div-height {
        height: 70px;
    }

    .tabs-wrapper {
        padding-left: 7px;
        padding-right: 7px;
    }

	.grid-margin-top{
		margin-top: 4px;
	}

	input[type="radio"] {
		box-shadow: none;
	}

	.right-align {
		text-align: right;
		font-size: 12px;
		background-color: initial;
	}

	.ag-header-cell-label {
		text-transform: none;
	}

	.buttons-group {
		background-color: #D1D1D1;
		padding: 10px;
	}

	.content {
		padding: 10px;

		[class^="col"] {
			padding-left: 0px;
			padding-right: 0px;
		}

		.same-row {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	input[type=radio] {
		margin-top: 0px;
		margin-bottom: 0px;
		height: initial;
		box-shadow: none;
	}

	.float-left {
		text-align: left;
	}



	.right-detail-panel {
		.content {
			padding: 6px 2px;

			input {
				display: inline-block;
				width: initial;
				padding-right: 5px;
				vertical-align: middle;
			}
		}
	}

	.no-margin-bottom {
		margin-bottom: 0px !important;
	}

	.navigation-buttons {
		display: inline-block;

		span {
			vertical-align: middle;
		}

		.button {
			color: rgb(209,209,209);
		}

		.glyphicon {
			font-size: 16px;
		}

		[disabled] {
		}

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		padding-top: 8px;
	}

	.padding-zero {
		padding: 0px !important;
	}
}
