﻿@import '../../../../../../shared/sass/_variables.scss';

.modal-big-lg {
	width: 1138px;

	.modal-content {
		width: 100%;
	}
}

.timber-sale-worksite-modal {

	.modal-dialog {
        margin-top: 0;
        margin-bottom: 0px;
        height: 100%;

        .modal-content {
            position: absolute;
            bottom: 30px;
            min-width: 900px;
        }
    }
}

.new-timber-sale-modal {

	$grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 14px;

	.execute-button {
		margin-left: 5px;
	}

	.ag-theme-fresh {
		.ag-root {
			font-family: $font-family;
		}
	}

	.sp-grid {
		.ag-root {
			border-radius: 0px;
		}

		.ag-row-selected, .ag-row:hover {
			input {
				color: white !important;
			}
		}
	}

	.sp-grid .ag-root .ag-header {
		font-size: 14px !important;
	}

	.customers-joined-members-grid .ag-root, .customers-table.sp-grid .ag-root, .upkeep-grid .ag-root {
		font-size: 14px;
	}

	.sale-type-button {
		width: 100%;
		margin-top: 10px;
	}

	.title-bar {
		> div:not(.clearfix) {
			padding: 1em;
		}

		.header {
			font-family: $font-semibold;
			margin: 0;
			position: relative;
			text-transform: uppercase;
			width: auto;
		}

		.glyphicon-remove:hover {
			color: $blue;
			cursor: pointer;
		}
	}

	.search-area {
        display: flex;

        .search-bar {
            display: flex;
            flex-grow: 6;
            border: 1px solid $gray;
            border-radius: 0.5em;
            height: 2em;
            line-height: 2em;
            overflow: hidden;
            padding: 0 0.4em;
            margin: 1em 1em 0 1em;

            span.glyphicon-search,
            span.glyphicon-remove {
                top: 5px;
            }

            input {
                border: 0;
                height: 2em;
                outline: 0;
                padding-left: 0.2em;
                width: 95%;
            }
        }

        .filtering-bar {
            align-self: flex-end;
            display: flex;
            flex-basis: 5em;
            flex-grow: 1;

            label { // center checkbox
                &:before {
                    top: 0;
                }

                &:after {
                    top: 4px;
                }
            }
        }
    }

	.grid-container {
		margin: 1em;

		.modal-grid {
			border: 1px solid $darkgray;
			border-top-left-radius: 0.5em;
			border-top-right-radius: 0.5em;
			height: 319px;

			#centerRow {
				border-radius: 0.5em !important;
			}

			.ag-header-group-cell-label {
				text-transform: uppercase;
			}

			.master-cell-header, .ag-header-group-cell, .ag-row, .ag-body-container {
				//width: 100% !important;
				///border-right: 0;
			}
		}
	}

	.form-group {
		padding: 0;
	}

	.border-top {
		border-top: 1px solid $gray;
	}

	.border-bottom {
		border-bottom: 1px solid $gray;
	}

	.sp-grid {

		.grid-check-box {
			top: -9px;
		}

		.ag-header-cell {
			//text-align: center;
			//border-right: none;
		}
		/*.main-header {
			text-transform: uppercase;
			width: 80% !important;
		}*/
	}

	.ag-body-viewport .ag-body-container .ag-row-selected .ag-cell {
		color: white !important;
		background-color: black !important;
	}

	.ag-header-container {
		//border-right: none;
		//border-bottom: none;
	}

	.modal-dialog {
		position: relative;
		width: 90% !important;
	}

	.customers-table {
		//a bit of a hack to not make the checkbox-row go to the customer's data on click
		.checkbox-cell {
			cursor: initial;

			input {
				pointer-events: initial;
			}
		}
		//Hide checkbox-cells in group-rows, couldn't find a way to do this easily programmatically
		.ag-row-group .checkbox-cell {
			display: none;
		}

		.ag-row .ag-selection-checkbox {
			position: static;
			margin-left: 6px;
		}

		.unselect-control {
			padding-left: 6px;
			padding-top: 3px;

			[type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
				position: relative;
				padding-left: 25px;
				cursor: pointer;
				top: -10px;
			}
		}
	}
}
