.edit-assortment-prices-modal {
    .upper-font {
        font-weight: bold;
    }

    .ag-header-cell-text {
        overflow: initial !important;
        white-space: normal !important;
        line-height: 18px !important;
    }

    .ag-header-row:nth-child(2) {
        height: 40px !important;
    }

    .ag-row {
        height: 30px !important;
        z-index: 0;
    }

    .ag-row.ag-row-focus {
        z-index: 1;
    }

    .price-matrix {
        padding: 3px 10px;
    }

    .modal-dialog {
        top: 22%;
        width: 97%;
    }

    .modal-body {
        padding-bottom: 0;
        z-index: 1;

        .row {
            z-index: 1;
        }
    }

    .button-left-margin {
        margin-left: 0.5em;
    }

    .group-row {
        display: inline-flex;
        position: absolute;
        top: 5px;
    }

    .total-sums-row {
        height: 45px;
        background-color: $gray;
        margin-top: 15px;
        font-weight: bold;

        .total-meters {
            height: auto;
            float: left;
            padding-left: 15px;
            padding-top: 15px;
            margin-right: 15px;
        }

        .total-price {
            height: auto;
            float: left;
            padding-top: 15px;
            margin-left: 15px;
        }

        .vertical-bar {
            width: 0px;
            height: 30px;
            border-left: 1px solid #B0AFB0;
            float: left;
            margin-top: 8px;
        }
    }

    #editePricesgrid {
        border: 1px solid $half_trimble_brand_grey;
        overflow: hidden;
        border-radius: 3px 3px 3px 3px;
        height: 450px;
        z-index: 0;

        .invalid-price {
            background-color: $lightred;
        }

        .ag-theme-fresh .sp-grid {
            z-index: 0;
        }

        .ag-layout-normal {
            z-index: 0;
        }

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 3px 3px 3px 3px;
        }

        .ag-row {
            z-index: 0;
        }

        .ag-row.ag-row-focus {
            z-index: 1;
        }

        .ag-header {
            background: $trimble-gradient-grey-header;
            height: 70px !important;
        }

        .reason-details {
            background-image: url(../../content/images/svg/Description_black.svg);
            float: right;
            background-size: 100% 100%;

            &.grid-row {
                background-image: url(../../content/images/svg/Description_blue.svg);
                width: 22px;
                height: 25px;
                float: left;
                margin-left: -3px;
            }
        }

        .simpleDropDown {
            height: 23px;
        }

        .pen-icon {
            &.inactive-button {
                background-color: grey;
                opacity: 0.5;
                cursor: not-allowed;
                pointer-events: unset;
            }
        }

        div[col-id="priceDifference"] {
            .cell-wrapper.input-element {
                height: 100%;
            }
        }

        .disabled-pen-icon:hover:not(.black) {
            background-color: #ddd;
            cursor: not-allowed;
        }

        .disabled-pen-icon {
            background-color: #ddd;
            cursor: not-allowed;
        }

        .text-format {
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .custom-drop-down {
        .dropdown-menu {
            top: 100% !important;
            left: 531px !important;
            width: 50em !important;
            z-index: 9999 !important;

            .custom-table .table-row {
                display: flex;
                color: black;
                z-index: 99999 !important;
            }
        }

        .dropdown-menu::after {
            z-index: 9999 !important;
        }
    }

    .dropDown400px {
        position: relative;
        width: 129%;

        .dropdown-menu {

            .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12,
            .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5,
            .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9,
            .col-md-1, .col-md-10, .col-md-11, .col-md-12,
            .col-md-2, .col-md-3, .col-md-4, .col-md-5,
            .col-md-6, .col-md-7, .col-md-8, .col-md-9,
            .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12,
            .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5,
            .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9,
            .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12,
            .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5,
            .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
                min-height: 18px;
                padding-right: 0px;
                padding-left: 5px;
            }

            .table-row:not(.table-header):hover {
                .col-xs-4:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-3:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-2:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-1:not(.grey-background) {
                    background-color: dodgerblue !important;
                }
            }
        }

        .btn {
            height: 23px;
            padding-top: 1px;

            span {
                float: left;
            }
        }

        button[disabled], button.btn[disabled] {
            border: 1px solid #616161;
        }

        background-color: white;
    }
}
