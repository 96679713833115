﻿.add-name-in-list {
    .helper-text {
        font-style: italic;
        margin-top: 5px;
        font-size: 12px;
    }

    .modal-content-margin {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .modal-row-no-margins {
        margin-left: 0px;
        margin-right: 0px;
    }

    .helper-counter {
        padding-left: 98px;
    }

    .modal-dialog {
        width: 350px;
    }

    .admin {
        margin-bottom: 0;
    }
}
