﻿@import '../../../../shared/sass/_variables.scss';

.date-check-modal {

    .modal-dialog {
        top: 225px;
    }

    .contract-term-input {
        border: 1px solid $darkgray !important;
        height: 27px !important;
        padding-left: 2px !important;
        padding-top: 4px !important;
    }

    .pt-15 {
        padding-top: 15px;
    }

    .pb-15 {
        padding-bottom: 15px;
    }

    .margin-top-4 {
        margin-top: 4px;
    }

    .pl-0 {
        padding-left: 0px !important;
    }

    .pr-0 {
        padding-right: 0px;
    }
}
