.select-assortments-for-price-comp {
    .modal-dialog {
        width: 650px;
    }

    .header-name {
        background-color: $trimble_blue_grey_light;
        padding: 15px;
        display: flow-root;

        p {
            margin: 0;
        }
    }
}