﻿@import '../../../../shared/sass/_variables.scss';

$left-margin: 30px;
$small-font-size: 12px;

.mobile-application-activation-modal {
    margin: 30px auto;

    .modal-dialog {
        width: 600px;
    }
}

.mobile-application-activation {

    .content {
        padding: 10px;
    }

    font-family: $font-family;
    font-size: $font-size;

    .font-usual {
        font-family: $font-family;
    }

    .font-small {
        font-size: $small-font-size;
    }

    .tableSearchFilter {
        font-style: italic;
    }

    .execute-button {
        margin-left: 5px;
    }

    .title-bar {
        font-size: $small-font-size;

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
            padding: 0;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }

        > div {
            padding-bottom: 8px;
        }
    }

    .form-group {
        padding: 0;
    }

    .border-bottom {
        border-bottom: 1px solid $lightgray;
        text-align: center;
    }

    .alert-icon-container {
        padding: 20px 20px 0px $left-margin;

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 70px;
        }
    }

    .text-under-icon {
        margin: $left-margin;
        color: $trimble_tf_product_green;
        text-align: center;
        font-family: $font-semibold;
        text-transform: uppercase;
    }

    .detailed-description {
        padding: 1em;
        border-radius: 0;

        :first-child {
            float: left;
            width: 55%;
        }

        :last-child {
            float: right;
            width: 45%;
            text-transform: none;
            font-style: italic;
        }
    }

    .activation-controls, .activation-buttons {
        margin: 20px $left-margin 0 $left-margin;

        .same-left-margin {
            margin-left: -30px;
        }

        .radio-inline {
            margin: 5px 0px 5px 0px;

            input {
                position: relative;
                top: 2px;
            }
        }

        .activation-code {
            input {
                margin: 10px 0px 0px 15px;
                width: calc(100% - 10px);
                border: 1px solid $darkgray;
                border-radius: 4px;
                padding: 0.2em;
                height: 27px;
            }

            ::-webkit-input-placeholder {
                font-style: italic;
            }

            input[disabled] {
                background-color: $disabled-button-background-color;
            }
        }

        button {
            width: 100%;
            margin: 10px 0px 10px 0px;
            padding: 15px;
            white-space: normal;
        }

        .row {
            div[class^="col-"] {
                padding: 0;
            }
        }
    }

    .upper-controls {
        margin: 10px 0px 10px 0px;

        .search-bar {
            border: 1px solid $gray;
            border-radius: 0.5em;
            height: 2em;
            line-height: 2em;
            overflow: hidden;
            padding: 0 0.4em;
            position: relative;
            top: 7px;

            input {
                border: 0;
                height: 2em;
                outline: 0;
                padding-left: 0.2em;
                width: 86%;
            }

            span.glyphicon-remove {
                margin-right: 0px;
            }
        }

        .show-label {
            top: 4px;
        }
    }

    .grid-container {
        margin: 10px 15px 15px 15px;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        overflow: hidden;

        .modal-grid {
            height: 130px;

            #centerRow {
                border-radius: 0.5em !important;
            }

            .ag-header-group-cell-label {
                text-transform: uppercase;
                text-align: left;
            }

            .master-cell-header, .ag-header-group-cell, .ag-row, .ag-body-container {
                width: 100% !important;
                border-right: 0;
            }

            [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
                top: 0px;
                left: 3px;
            }

            [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
                top: 0px;
                left: 3px;
            }

            .second-checkbox {
                .grid-check-box {
                    position: relative;
                    left: 20px;
                }
            }
        }

        .gray-background {
            .ag-body-viewport {
                background-color: $lightgray;

                .ag-row {
                    background-color: $lightgray;
                }

                .white-back-ground {
                    background-color: white;
                }
            }
        }
        /* alternative styling, needs to be set on columns cellClass: 'gray-background'
        .ag-body-viewport {            
            .ag-row {
                .gray-background {
                    background-color: $lightgray;
                }    
                
                .white-back-ground {
                    background-color: white;
                }            
            }            
        }
        */
        .ag-scrolls {
            .ag-header-row {
                position: static;

                .ag-header-group-cell-with-group {
                    position: relative;
                }
            }
        }

        .modal-button-group {
            padding: 10px;
            border-top: 1px solid $darkgray;
            background-color: $gray;

            button {
                padding: 5px;
                width: 100%;
            }
        }

        .devices-grid {
            height: 300px;
        }
    }

    .bottom-buttons {
        padding: 10px;
        text-align: center;

        button {
            display: inline-block;
            min-width: 80px;
            padding: 6px 12px;
            margin: 0.5em 0.25em 0 0.25em;
        }
    }

    .more-marginal-above {
        padding-top: 13px;
    }

    .more-marginal-below {
        padding-bottom: 6px;
    }

    .licenses-row {
        padding: 10px 0px;

        input, select {
            border-radius: 4px;
            border: 1px solid $darkgray;
            width: 50px;
            height: 20px;
            padding-left: 5px;
        }

        input {
            text-align: right;
        }

        input[disabled] {
            background: $gray;
        }

        span, select, input {
            margin: 2px;
        }
    }

    [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 0;
        cursor: pointer;
    }

    .text-left {
        text-align: left;
    }
}


.customer-mobile-app-licenses-devices {

    .title-bar, .licenses-row, .grid-container {
        padding: 1em;
    }

    .title-bar > div {
        padding-bottom: 0px;
    }

    .grid-container {
        margin: 1em;
        padding: 0;

        .sp-grid {
            .ag-overlay-panel {
                .no-rows-bubble {
                    margin-left: unset;
                    text-align: center;
                    width: auto;
                    display: inline-block;
                }
            }
        }
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .bottom-buttons {
        border-top: 1px solid $gray;
    }

    .grid-container {
        margin-top: 0px;
    }

    .bottom-buttons button {
        margin-top: 0;
    }
}

.add-forest-plans {

    .title-bar {
        padding: 1em;

        > div {
            padding-bottom: 0;
        }
    }

    .add-forest-plans-modal {
        .radio-inline {
            input[type="radio"] {
                position: relative;
                top: 2px;
            }
        }

        .ag-theme-fresh {
            .ag-ltr {
                .ag-header-group-cell-label {
                    padding-left: 4px;
                }
            }

            .grid-header-checkbox-label {
                left: 3px;
                top: 3px;
            }
        }
    }

    .form-group {
        border-top: 1px solid $lightgray;
    }

    .upper-controls {
        border-top: none;
    }

    .more-marginal-below {
        padding-bottom: 17px;
    }

    .grid-container {
        .forest-plan-grid {
            height: 125px;
        }
    }
}
