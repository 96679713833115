.difficulty-class-edit {
    .modal-dialog {
        width: 600px;
        top: 220px;
    }

    .left-side-label {
        margin-right: 10px;
        float: left;
    }

    .material-switch-label {
        top: -7px;
    }

    .material-switch-content {
        >input[type="checkbox"]:checked+label::after {
            top: 4px;
        }

        >input[type="checkbox"]:not(:checked)+label::after {
            top: 3px;
        }
    }

    .material-switch-container {
        width: 50px;
        float: left
    }

    .vertical-bar {
        width: 0px;
        height: 30px;
        border-left: 1px solid #B0AFB0;
        float: left;
        margin-top: -7px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .first-row {
        margin-top: 7px;
        margin-bottom: 15px;
    }

    .right-input {
        float: left;
    }

    #difficultyClassEditGrid {
        height: 185px;

        .ag-header {
            background: $trimble-gradient-grey-header;
        }

        .trimble-remove {
            margin-left: 4px;
            width: 14px;
            margin-top: -3px;
        }

        .ag-row-level-0.ag-row-hover .trimble-remove {
            content: url("../../../../content/images/svg/Delete_Entity_White.svg");
            width: 24px;
            margin-left: -1px;
        }

        .ag-row-level-0.ag-row-focus .ag-cell-inline-editing.ag-column-hover .ag-cell-edit-input {
            color: black;
        }

        .list-id-input {
            input {
                border: 0;
                color: black;
                width: 100%;
            }
            
            .feebasis-difficulty-class-default {
                width: 15px;
                height: 15px;
                margin-top: 4px;
            }
        }  

        .ag-body-container input {
            margin-top: 1px;
        }
    }

    .grid-row {
        border-radius: 3px 3px 0px 0px;
        border: 1px solid $half_trimble_brand_grey;
    }

    .no-admin-rights {
        pointer-events: none;
    }
}