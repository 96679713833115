.legend-tool {
    &.panel {
        top: 0.2em;
        left: 0.2em;
        bottom: 0.2em;
        margin: 0;
        background-color: $white;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;

        .header-wrapper {
            vertical-align: middle;
            padding: 1em;
            border-bottom: 2px solid $lightgray;
            text-transform: uppercase;

            .icon {
                height: 1.3em;
            }

            span {
                position: absolute;
                left: 2.5em;
            }

            .close-button {
                cursor: pointer;
            }
        }

        .panel-content {
            display: flex;
            flex-direction: column;
            overflow-y: hidden;
            flex-basis: 35px; // this is the minimum height of an element
            flex-grow: 1;

            .legend-collapse-header {
                text-transform: uppercase;
                padding: 0 0.5em;
                font-weight: bold;
                height: 35px;
                line-height: 35px;
                cursor: pointer;

                span {
                    top: calc(50% - 0.5em);
                    cursor: pointer;

                    &:hover {
                        color: $blue;
                    }
                }

                .turn {
                    transform: rotate(90deg);
                }

                &.border {
                    border-bottom: 1px solid $lightgray;
                }
            }

            .legend-collapse {
                padding: 0 0.5em;
                flex-grow: 1;
                overflow: auto;

                &.border {
                    border-bottom: 1px solid $lightgray;
                }

                .item {
                    min-height: 2em;
                    display: flex;
                    align-items: center;
                    position: relative;

                    > img:not(.additional-green-bubble) {
                        width: 1.5em;
                        margin-right: 0.5em;
                        max-height: 2em;
                    }

                    > img.additional-green-bubble {
                        position: absolute;
                        left: 0.75em;
                        top: 0;
                        height: 0.8em;
                        width: 0.8em;
                    }

                    .missing {
                        width: 1.5em;
                        margin-right: 1em;
                        display: inline-block;
                    }

                    .renderer-image {
                        width: 1.5em;
                        height: 1.5em;
                        overflow: hidden;
                        position: relative;
                        display: inline-block;
                        margin-right: 0.5em;

                        &.red-border {
                            border: 1px solid rgba(255, 0, 0, 1.0);
                        }

                        &.turquoise-border {
                            border: 1px solid rgba(51,0,255, 1.0);
                            background-image: url(/content/images/svg/map/ThemeRasterImages/ThemeRaster_NewWorksiteStandSelectionRaster_BrightBlue.svg);
                            background-repeat: repeat;
                            background-size: 20px 20px;
                        }

                        &.orange-border {
                            border: 1px solid #f18700;
                        }

                        &.purple-border {
                            border: 1px solid rgba(102, 0, 153, 1.0);
                        }

                        &.lime-border {
                            border: 1px solid rgba(58,174,44, 1.0);
                        }

                        &.blue-dashed-border {
                            border: 1px dashed rgba(51,0,255, 1.0);
                        }

                        &.blue-border {
                            border: 1px solid rgba(51,0,255, 1.0);
                        }

                        &.ifl-border {
                            border: 1px solid rgba(0, 0, 255, 1.0);
                        }

                        &.hcv-border {
                            border: 1px solid rgba(255, 0, 255, 1.0);
                        }

                        &.relic-border {
                            border: 1px solid rgba(230, 0, 0, 1.0);
                        }

                        &.relic2-border {
                            border: 1px solid rgba(182, 127, 74, 1.0);
                        }

                        img {
                            width: 3em;
                            height: 3em;
                        }
                    }
                }

                &.storage-targets {
                    .item {
                        min-height: 2.2em;

                        img {
                            width: 3em;
                            padding: 2px;
                        }
                    }
                }

                &.line-targets {
                    .item {
                        img {
                            width: 3em;
                            margin-right: 0.5em;
                        }

                        .line-icon {
                            width: 3em;
                            display: inline-flex;
                            align-items: center;
                            height: 1em;
                            overflow: hidden;
                            margin: 0;
                            margin-right: 0.5em;

                            .free-line {
                                width: 100%;
                                height: 0;
                                margin: 0;
                                padding: 0;
                                display: inline;

                                &.real-estate {
                                    border-bottom: 4px solid rgba(255,0,0,1.0);
                                }

                                &.stand {
                                    border-bottom: 4px solid rgba(102,0,153,1.0);
                                }

                                &.border {
                                    border-bottom: 4px solid rgba(51,0,255,1.0);
                                }
                            }
                        }
                    }
                }
            }

            .crown-legend {
                margin: 0 0.5em;

                .color-footer {
                    margin-top: 10px;
                    font-weight: normal;
                }

                table {
                    width: 100%;
                    font-weight: bold;

                    td {
                        .color-container {
                            display: flex;
                            align-items: center;
                        }

                        .color {
                            border: 1px solid $darkgray;
                            height: 1em;
                            width: 1em;
                            display: inline-block;
                            margin-right: 0.5em;

                            &.color-fud-1 {
                                background-color: rgb(185, 73, 57);
                            }

                            &.color-fud-2 {
                                background-color: rgb(255, 155, 43);
                            }

                            &.color-fud-3 {
                                background-color: rgb(255, 223, 98);
                            }

                            &.color-fud-4 {
                                background-color: rgb(255, 249, 173);
                            }

                            &.color-fud-5 {
                                background-color: rgb(218, 217, 199);
                            }

                            &.color-fud-6 {
                                background-color: rgb(144, 211, 137);
                            }

                            &.color-fud-7 {
                                background-color: rgb(39, 170, 48);
                            }

                            &.color-fud-8 {
                                background-color: rgb(23, 156, 51);
                            }

                            &.color-fud-9 {
                                background-color: rgb(18, 122, 52);
                            }

                            &.color-fud-10 {
                                background-color: rgb(13, 91, 49);
                            }

                            &.color-fud-11 {
                                background-color: rgb(180, 179, 179);
                            }

                            &.color-fud-12 {
                                background-color: rgb(59, 138, 130);
                            }

                            &.color-fud-13 {
                                background-color: rgb(84, 184, 223);
                            }

                            &.color-fud-14 {
                                background-color: rgb(142, 0, 117);
                            }

                            &.color-fud-15 {
                                background-color: rgb(12, 0, 255);
                            }

                            &.color-ha-1 {
                                background-color: rgb(0, 96, 0);
                            }

                            &.color-ha-2 {
                                background-color: rgb(96, 154, 0);
                            }

                            &.color-ha-3 {
                                background-color: rgb(160, 221, 0);
                            }

                            &.color-ha-4 {
                                background-color: rgb(255, 250, 0);
                            }

                            &.color-ha-5 {
                                background-color: rgb(255, 134, 0);
                            }

                            &.color-ha-6 {
                                background-color: rgb(255, 36, 0);
                            }

                            &.color-ha-254 {
                                background-color: rgb(128, 255, 255);
                            }

                            &.color-30 {
                                background-color: rgb(96, 40, 10);
                            }

                            &.color-28 {
                                background-color: rgb(138, 94, 72);
                            }

                            &.color-26 {
                                background-color: rgb(109, 86, 140);
                            }

                            &.color-24 {
                                background-color: rgb(169, 19, 255);
                            }

                            &.color-22 {
                                background-color: rgb(255, 58, 204);
                            }

                            &.color-20 {
                                background-color: rgb(255, 51, 102);
                            }

                            &.color-18 {
                                background-color: rgb(255, 51, 0);
                            }

                            &.color-16 {
                                background-color: rgb(255, 130, 0);
                            }

                            &.color-14 {
                                background-color: rgb(255, 183, 0);
                            }

                            &.color-12 {
                                background-color: rgb(255, 230, 0);
                            }

                            &.color-10 {
                                background-color: rgb(204, 255, 0);
                            }

                            &.color-8 {
                                background-color: rgb(0, 225, 0);
                            }

                            &.color-6 {
                                background-color: rgb(0, 255, 204);
                            }

                            &.color-4 {
                                background-color: rgb(0, 102, 255);
                            }

                            &.color-2 {
                                background-color: rgb(51, 0, 255);
                            }

                            &.bwcolor-30 {
                                background-color: rgb(255, 255, 255);
                            }

                            &.bwcolor-28 {
                                background-color: rgb(255, 255, 255);
                            }

                            &.bwcolor-26 {
                                background-color: rgb(255, 255, 255);
                            }

                            &.bwcolor-24 {
                                background-color: rgb(240, 240, 240);
                            }

                            &.bwcolor-22 {
                                background-color: rgb(220, 220, 220);
                            }

                            &.bwcolor-20 {
                                background-color: rgb(200, 200, 200);
                            }

                            &.bwcolor-18 {
                                background-color: rgb(180, 180, 180);
                            }

                            &.bwcolor-16 {
                                background-color: rgb(160, 160, 160);
                            }

                            &.bwcolor-14 {
                                background-color: rgb(140, 140, 140);
                            }

                            &.bwcolor-12 {
                                background-color: rgb(120, 120, 120);
                            }

                            &.bwcolor-10 {
                                background-color: rgb(100, 100, 100);
                            }

                            &.bwcolor-8 {
                                background-color: rgb(80, 80, 80);
                            }

                            &.bwcolor-6 {
                                background-color: rgb(60, 60, 60);
                            }

                            &.bwcolor-4 {
                                background-color: rgb(40, 40, 40);
                            }

                            &.bwcolor-2 {
                                background-color: rgb(20, 20, 20);
                            }

                            &.bwcolor-0 {
                                background-color: rgb(255, 255, 255);
                            }
                        }
                    }
                }
            }
        }
    }
}
