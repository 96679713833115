﻿@import '../../shared/sass/_variables.scss';

.user-menu-directive {
    font-family: $font-primary;
    font-size: 12px;
    color: $trimble_grey;
    text-align: left;
    $text-padding: 19px;

    .icon-user-white {
        background-image: url(../../../content/images/svg/User_Menu_white.svg);
    }

    .menu-active {
        background-color: $trimble_dk_blue;
    }

    .menu-normal {
        background-color: none;
    }

    .user-menu {
        position: absolute;
        z-index: 1000;
        top: 38px;
        width: 260px;
        background: $trimble_white 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $trimble_header_shadow_color;
        opacity: 1;
        right: 20px;

        .language-row {
            height: 35px;
            cursor: unset;
        }

        .user-information {
            margin-top: 10px;
            margin-bottom: 10px;

            .user-menu-info {
                padding-left: $text-padding;
                padding-right: $text-padding;
                user-select: none;
            }

            .bold-font {
                font-family: $font-semibold;
                font-weight: 600;
            }
        }

        .divider {
            border-top: 1px solid $half_trimble_brand_grey;
            margin-bottom: 1px;
            margin-top: 1px;
        }

        .text-button {
            padding-left: $text-padding;
            padding-right: 10px;
            user-select: none;
            cursor: pointer;
            line-height: 35px;
            vertical-align: middle;

            .btn-group {
                display: flex;

                .btn {
                    text-align: left;
                }
            }
        }

        .text-button:hover {
            background-color: $trimble_very_light_grey;
        }

        .menu_logout_icon {
            height: 20px;
            content: url("../../content/images/svg/Logout_user_menu.svg");
            margin-right: 5px;
            vertical-align: top;
            margin-top: 6px;
        }

        .logout-button {
            padding-left: 17px;
        }
    }

    .image-button {
        width: 40px;
        height: 30px;
        cursor: pointer;
    }

    .image-button:hover {
        background-color: $trimble_dk_blue;
    }
}
