.storage_pile_cards-modal {
    .modal-dialog {
        width: 58%;
        top: 14%;
        
        .modal-body {
            padding: 0;
        }
    }

    #storagePileCardsModal {
        height: 328px;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid $half_trimble_brand_grey;

        .ag-header {
            background: $trimble-gradient-grey-header;
        }

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 3px 3px 0px 0px;
        }

        .input-element {
            input {
                padding-top: 0;
                margin-bottom: 2px;
                margin-left: 0px;
                color: black;
                width: 100%;
                border: none;

                &.input-with-border {
                    border: $grid-border;
                    border-radius: 4px;
                }
            }
        }

        .default-quality {
            pointer-events: none;
        }

        .ag-header-cell {
            padding-top: 2px;
        }

        .ag-column-hover {
            .input-element input {
                background-color: $white;
            }
        }

        .ag-row-hover {
            .input-element input {
                background-color: $white;
            }
        }

        .text-format {
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .first-row {
        height: 60px;
        background-color: $gray;

        .description {
            height: auto;
            font-style: italic;
            float: left;
            padding-left: 15px;
            padding-top: 15px;
            margin-right: 45px;
        }
    }

    .second-row {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .btn-margin {
        margin-right: 5px;
    }
}