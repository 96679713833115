.default-factory-prices {
    $header-color: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;
    padding-left: 0px;  
    font-size: 14px;

    .default-factory-prices-panel {
        border-radius: 0.5em 0.5em 0.5em 0.5em;
        border: 1px solid #616161;
    }

    .selected-definition, .view-option-select, .dropdown-menu {
        width: 100%;
    }

    .btn.btn-default.dropdown-toggle {
        width: 100%;
        height: 30px;
        padding: 3px 6px;
        border: 1px solid $half_trimble_brand_grey;

        @media screen and (max-width: 1300px) {
            border: 1px solid #B0AFB0;
        }
    }

    .select-option-label {
        font-size: 12px;
        font-weight: 600;
        height: 28px;
        padding-top: 6px;
        font-family: "Myriad Pro Semibold";
    }

    @media screen and (max-width: 1600px) {
        .select-option-label {
            padding-top: 0;
            margin-top: 6px;
            margin-right: 0px;
        }
    }

    @media screen and (max-width: 1300px) {
        .select-option-label {
            padding-top: 0;
            margin-top: -2px;
            margin-right: 0px;
        }
    }

    .navigation-section {
        height: 32px;

        .dropdown-menu {
            margin-top: 0px;
            position: sticky;

            @media screen and (max-width: 1300px) {
                width: 218px;
            }
        }
    }

    #defaultFactoryPricesGrid {
        height: 618px;

        .select-all-wrapper {
            top: -5px;
            left: 2px;
        }
        
        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 0;
        }

        .change-information-header {
            height: 16px;
            padding-left: 7px;
        }

        .ag-selection-checkbox {
            padding-left: 1px;
        }

        .who-modified-icon {
            width: 16px;
            height: 16px;
            margin-top: -3px;
            margin-left: 4px;  
        }

        .ag-row-level-0.ag-row-focus .who-modified-icon {
            content: url("../../../../content/images/svg/WhoModifiedIcon_black.svg");
        }

        .ag-row-hover.ag-row-level-0.ag-row-focus .who-modified-icon {
            content: url("../../../../content/images/svg/WhoModifiedIcon_white.svg")
        }

        .ag-row-selected.ag-row-level-0.ag-row-focus .who-modified-icon {
            content: url("../../../../content/images/svg/WhoModifiedIcon_white.svg")
        }

        .ag-floating-bottom-container .ag-row .ag-cell {
            background-color: #d1d1d1;
        }

        .ag-floating-bottom {
            border-top: 1px solid #616161;
        }

        [col-id="modifyInformation"] {
            cursor: default;
        }

        .ag-header-select-all {
            padding-left: 3px;
        }
    }

    .panel-header.grid-header-styling {
        border-bottom: $grid-border;
        padding: 4px;
        height: 25px;
        font-family: "Myriad Pro", Verdana;
        font-weight: 700;
        color: black;
        background: #e2e2e2;

        &.modal-header {
            border-radius: 3px 3px 0px 0px;            
            border: $grid-border;
            padding-left: 10px;

            @media screen and (max-width: 1600px) {
                border: 1px solid $half_trimble_brand_grey;
            }
        }
    }

    .search-section {
        margin-bottom: 15px;

        .silva-search {
            height: 30px;
            border: 1px solid $half_trimble_brand_grey;
            border-radius: 4px;

            @media screen and (max-width: 1300px) {
                border: 1px solid  $half_trimble_brand_grey
            }    
        }
    }

    .buttons-group {
        $button-group-padding: 10px;

        background-color: $lightgray;
        padding: $button-group-padding;
        border-top: 1px solid #616161;
        border-radius: 0 0 0.5em 0.5em;

        .buttons-wrapper {
            :nth-child(2), :nth-child(3) {
                margin-left:$button-group-padding - 2px;
            }
        }

        .special-width {
            width: 420px;
        }

        .smaller-button-width {
            width: 250px;            
        }
    }
    
    .grid-edit-modal {
        the-header, the-body {
            width: 100%;
            padding-right: 0;
            border-left: $grid-border;
            border-bottom: $grid-border;
            padding-left: 0.5em;

            @media screen and (max-width: 1600px) {   
                border-left: $grid-border;
                border-right: $grid-border;
                border-bottom: $grid-border;
            }
        }        

        .header-row {
            the-header .trimble-remove.remove-header {
                border-right:$grid-border;
            }
        }

        the-header {
            background: #e2e2e2;

            .header-column label {
                height: 21px;
                padding-top: 2px;
                font-family: $font-primary, $font-secondary;
                font-weight: 700;
                color: $black;
                margin-top: 1px;
            }
        }

        the-body {
            border-radius: 0px 0px 3px 3px;
        } 

        the-title {
            .panel-header {
                height: 26px;
            }
        }

        width: calc(100% - 3.2%);

        .header-column {
            .change-information-header {
                height: 16px;
                width: 16px;
                margin-left: 8px;
                margin-top: 6px;

                @media screen and (max-width: 1800px) {
                    margin-left: 6px;
                }

                @media screen and (max-width: 1600px) {
                    margin-left: 4px;
                }

                @media screen and (max-width: 1300px) {
                    margin-left: 2px;
                    margin-top: 16px;
                }
            }    
        }

        .body-column {
            padding-bottom: 4.5px;
            padding-top: 1.5px;

            .change-information-body {
                height: 16px;
                width: 28px;
                margin-left: 3px;
                margin-top: 0px;
                cursor: pointer;

                @media screen and (max-width: 1800px) {
                    width: 27px;
                }

                @media screen and (max-width: 1600px) {
                    width: 24px;
                    margin-left: 2px;
                }

                @media screen and (max-width: 1300px) {
                    width: 20px;
                }

                &.icon-disabled {
                    opacity: 0.3;
                    cursor: no-drop;
                    pointer-events: none;
                }
            }

            &.trimble-remove.icon-disabled {
                opacity: 0.3;
                cursor: no-drop;
                pointer-events: none;
            }

           &.who-modified-information {
               cursor: default;

               img {
                   cursor: default;
               }
           }
        }

        .header-column,
        .body-column {
            &.width-11 {
                $width-11: 11%;
                width: $width-11;
                border-right: $grid-border;

                .multiselection-drop-down {
                    .checkboxes {
                        width: $width-11;
                    }
                }
            }            

            &.width-12 {
                $width-12: 12%;
                width: $width-12;
                border-right: $grid-border;
                .custom-button {
                    max-width: 195px;
                }

                .multiselection-drop-down {
                    .checkboxes {
                        width: $width-12;
                    }
                }
                
                @media screen and (max-width: 1800px) {
                    .custom-button {
                        max-width: 191px;
                    }  
                }

                @media screen and (max-width: 1600px) {
                    .custom-button {
                        max-width: 166px;
                    }  
                }

                @media screen and (max-width: 1300px) {
                    .custom-button {
                        max-width: 135px;
                    }  

                    .btn-group .custom-button {
                        border: 1px solid #707070;
                    }
                }

                @media screen and (min-width: 2000px) {
                    .custom-button {
                        max-width: 220px;
                    }  
                }

                @media screen and (min-width: 2400px) {
                    .custom-button {
                        max-width: none;
                    }
                }
            }

            &.width-13 {
                $width-13: 13%;
                width: $width-13;
                border-right: $grid-border;

                .multiselection-drop-down {
                    .checkboxes {
                        width: $width-13;
                    }
                }

                @media screen and (max-width: 1800px) {
                    .custom-button {
                        max-width: 191px;
                    }  
                }

                @media screen and (max-width: 1600px) {
                    .custom-button {
                        max-width: 157px;
                    }  
                }

                @media screen and (max-width: 1300px) {
                    .custom-button {
                        max-width: 135px;
                    }
                }
            }

            &.width-14 {
                width: 14%;
                border-right: $grid-border;
            }

            &.width-4 {
                width: 4%;
                border-right: $grid-border;
            }

            &.width-5 {
                width: 5%;
                border-right: $grid-border;
            }

            &.width-6 {
                width: 6%;
                border-right: $grid-border;
            }

            &.width-7 {
                $width-7: 7%;
                width: $width-7;
                border-right: $grid-border;

                .multiselection-drop-down {
                    .checkboxes {
                        width: $width-7;
                    }
                }
            }

            &.width-2 {
                width: 2%;
                border-right: $grid-border;
            }
        }

        .dropdown-menu .drop-down-table .first-column {
            width: 25%;
            padding-left: 4px
        }

        .border-bottom {
            padding-left: 17px;
            padding-bottom: 17px;
            padding-right: 16px;
            padding-top: 16px;
            border-bottom: 0;
        }   
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: textfield;
            margin: 0;
        }

        .body-row .the-body .body-column {
            .form-control {
                border: 1px solid #707070;
                border-radius: 2px;
                color: #000000;
            }

            .simpleDropDown {
                border: 1px solid #707070;
                border-radius: 2px;

                @media screen and (max-width: 1300px) {
                    border: 1px solid #707070;
                }
            }

            .edit-down-options {
                width: 100%;
            }

            .custom-drop-down {
                .custom-button {
                    border: 1px solid #707070;
                    border-radius: 2px;                   
                    text-align: left;
                    width: 85% !important;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    text-align: left;
                    margin-top: 1px;
     
                    @media screen and (max-width: 1300px) {
                        border: 1px solid #707070;
                    }
                }
        
                .btn.dropdown-toggle {
                    border: 1px solid #707070;
                    border-radius: 2px;                    
                    border-left-style: none;
                    width: 13%;
                    margin-top: 1px;
                    margin-left: -2px;
                    margin-right: 0;

                    @media screen and (max-width: 1300px) {
                        border: 1px solid #707070;
                    }
                }
        
                .silva-search input {
                    width: 79% !important;
                }
            }
        }

        .simpleDropDown {
            font-size: 14px;
        }  

        .edit-down-options[disabled="disabled"] {
            button,
            button[disabled="disabled"]:hover {
                border: 1px solid #616161;
                background-color: #eee;
            }
        }
        
        .remove-header {
            cursor: default;
        }
    }

    .simpleDropDown[disabled="disabled"] {
        background-color: #eee;
    }

    .select-option-dropDown {
        margin-right: 15px;

        .active-advanced-filter-modal {
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 1300px) {
        .select-option-dropDown {
            margin-right: 30px;
        }
    }    

    field-validation {
        display: flex;
        width: 100%;

        > div {
            display: flex;
            width: 100%;
        }
    }
     
    .multiselection-drop-down {
        > div {
            display: flex;
            width: 100%;
            flex-grow: 0;
        }
        display: flex;
        width: 100%;
        $padding-left: 5px;
        line-height: 30px;
        width: 100%;
        border-radius: 0px;               

        .titleBox {            
            width: 100%;            
            border-radius: 2px;
            border: 1px solid $darkgray;
            line-height: 22px;
            height: 25px;     
            display: flex;            
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: baseline;      
            overflow: hidden;            

            .titleBoxText {    
                display: flex;      
                flex-grow: 1;              
                min-width: 0;                                                     
                overflow: hidden;
                word-break: break-all;
                white-space: wrap;                                
                text-overflow: ellipsis;         
                text-wrap: wrap;      
                overflow-wrap: break-word;
                word-wrap: break-word;
                color: #555;
                height: 17px;                
            }

            .remove-triger-buttons {
                display: flex;
                margin-left: auto;
            }

            .topmargin {
                margin-top: 5px;
            }
        }

        .checkboxes {            
            border-radius: 0px;
            display: flex;
            flex-flow: column;                    

            .select-all-label {
                line-height: 30px;
            }            

            .checkboxesList {
                width: 100%;

                .checkboxLabel {
                    line-height: 35px;
                }

                .checkboxes-list-wrapper {
                    padding-left: $padding-left;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: baseline;
                    justify-content: flex-start;

                    input {
                        display: none;
                    }

                    label {
                        width: 100%;
                        padding-left:30px;
                        display: flex;
                        align-items: center;
                        border: none;
                    }

                    [type="checkbox"]:not(:checked)+label:before, [type="checkbox"]:checked+label:before, [type="checkbox"]:not(:checked)+label:after, [type="checkbox"]:checked+label:after {
                        top: auto;
                    }
                }
            }

            hr {
                width: 100%;
            }
        }

        .multiselectionSearch {
            height: 30px;
            margin-bottom: 10px;
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: baseline;

            .multiselectionSearchInput {
                border: none;                
            }

            .glyphicon-remove  {
                margin-right: 10px;
            }
        }
    }    

    .drop-down-margin {
        padding: 2px;
    }
}