/* responsive styles */

/* mobile */
@media (max-width: $media-mobile) {

	.admin {
		.flex {
			flex-direction: column;
		}
		.partial {
			border: 0px;
			padding: 0px;

			.partial-header {
				border-radius: 0;
			}
		}

		.row, .flex-row {
			>*{
				margin-left: 0.5em;
				margin-right: 0.5em;
			}
		}

		.partial.logos .logo-upload .flex-column .logo-header {
			background: none;
			height: 100%;
			text-align: right;
			span {
				margin: 0.5em;
			}
		}

		.admin-bottomrow.row {
			button.adminform-save.adminform-save {
				width: 98%;
				height: 50px;
				margin-left: 0.5em;
			}

		 	.infosecurity-footer.infosecurity-footer .infosecurity-wrapper.infosecurity-wrapper {
				width: 100%;
			}

			.file-upload-directive {
				.col-xs-7,
				.col-xs-5 {
					padding-left: 0;
					padding-right: 0;
				}

			}
			.btn-upload {
				min-width: 75px;
			}
		}
	}

}
