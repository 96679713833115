@import "../../../../../shared/sass/_variables.scss";

.price-period-modal {
    .uppercase {
        text-transform: uppercase;
    }

    .modal-dialog {
        width: 40%;
        top: 20%;

        .modal-body {
            padding: 0 15px;
        }
    }

    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        top: 0;
    }

    .grid-container {
        margin: 15px 0px 0px;
        border: 1px solid $sp-panel-border-color;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom: none;

        .sp-grid {
            height: 200px;

            .grid-check-box {
                top: -8px;
            }

            .ag-root.ag-root {
                border-radius: 0px;
            }

            .cell-header-right {
                .ag-header-cell-text {
                    float: right !important;
                }
            }
        }

        .select-all-wrapper {
            left: 3px;
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }

        .ag-theme-fresh .ag-ltr .ag-selection-checkbox {
            padding-left: 3px;
            float: left;
        }

        .ag-theme-fresh .ag-header-select-all {
            padding-left: 6px;
        }

        .no-case {
            .ag-header-cell-label,
            .ag-header-group-cell-label {
                text-transform: none !important;
            }
        }
    }

    .grid-buttons {
        background: #d1d1d1 0% 0% no-repeat padding-box;
        border: 1px solid $sp-panel-border-color;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 10px;
        margin: 0px 0px 10px 0px;
        border-top: none;
    }
}
