﻿@import '../../shared/sass/_variables.scss';

$tab-border-width: 1px;

.measurement-base-containers {
    .app-container {
        display: inline-block;
        padding: 0px;
        border: $tab-border-width $half_trimble_brand_grey solid;
        margin-right: 0 - $tab-border-width; //prevent
        background-color: $white;
        height: auto;

        .app-container-header {
            text-transform: uppercase;
            padding: 0.5em;
            margin-bottom: 1px;
            color: $white;
            background-color: $black;

            .border-left {
                border-left: $tab-border-width $white solid;
            }
        }

        .app-container-content {
            margin: 30px;
        }

        .app-container-footer {
            padding: 10px;
            border-top: 0.75px solid $half_trimble_brand_grey;
            background: $gray 0% 0% no-repeat padding-box;
            margin: 0px;
            bottom: 0;
            position: absolute;
            width: 100%;

            .empty-footer {
                padding: 17px;
            }
        }
    }

    .app-container-secondary {
        display: inline-block;
        padding: 0px;
        border: $tab-border-width $half_trimble_brand_grey solid;
        background-color: $white;
        border-right: 0px;

        .app-container-secondary-header {
            text-transform: uppercase;
            padding: 0.5em;
            margin-bottom: 1px;
            background-color: $gray;
            padding-left: 20px;

            .border-left {
                border-left: $tab-border-width $half_trimble_brand_grey solid;
            }
        }

        .app-container-secondary-content {
            margin: 20px;
        }

        .app-container-secondary-footer {
            padding: 10px;
            background: $gray 0% 0% no-repeat padding-box;
            margin: 0px;
            bottom: 0;
            width: 100%;
            height: 7em;
        }
    }
    //buttons
    .md-buttons {
        clear: both;
        padding: 0px;
        margin-left: 0px;
        margin-right: 0px;

        button {
            padding: 10px;
            margin: 30px 0px;
        }
    }

    .grid-buttons {
        background: $gray 0% 0% no-repeat padding-box;
        border-top: $tab-border-width $half_trimble_brand_grey solid;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
