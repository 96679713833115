﻿@import '../../../../shared/sass/_variables.scss';

.harvester-file-details-modal-window {
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .tab-container {
        position: relative;
        top: 4px;
    }

    .tabs-wrapper {
        margin: 20px;
    }

    .col-xs-6 {
        width: 53%;
    }

    .col-6 {
        width: 47.3%;
    }

    .modal-dialog {
        width: 85%;
        margin-top: 5px;

        .modal-trimble-button-container {
            margin-top: 1em;
        }
    }

    .execute-button {
        margin-left: 5px;
    }
    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        cursor: not-allowed;
    }

    [type="checkbox"]:checked + label:after {
        cursor: not-allowed;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .col-xs-3, .col-xs-9, .col-xs-1, .col-xs-2, .col-xs-5, .col-xs-4, .col-xs-8 {
        padding: 0em;
        margin-left: 0em;

        .control-label {
            margin-top: 4px;
        }
    }

    .col-xs-1 {
        .control-label {
            width: 100%;
            text-align: center;
        }
    }

    .controller-grid-container {
        margin: 0.8em;
    }

    .assortment-grid-section {
        display: flex;
        margin: 0.8em;

        .ag-menu {
            z-index: 1 !important;
        }
    }

    .padding-right-20 {
        padding-right: 20px;
    }

    .padding-top-20 {
        padding-top: 20px;
    }

    .grid-section {
        display: flex;
        padding-left: 40px;
        padding-bottom: 40px;
        padding-right: 40px;

        .ag-menu {
            z-index: 1 !important;
        }
    }

    .data-section {
        display: flex;
        padding: 40px;

        .ag-menu {
            z-index: 1 !important;
        }
    }

    .margin-40 {
        margin: 40px;
    }

    .padding-40 {
        padding: 40px;

        .form-group {
            padding: 0;
        }
    }

    .table-width-matrix {
        border-collapse: separate;
        border: 1px solid $darkgray;
        border-radius: 5px;

        tr:first-child th:first-child {
            border-radius: 5px 0 0 0 !important;
        }

        tr:first-child th:last-child {
            border-radius: 0 5px 0 0 !important;
        }

        tr:last-child th:first-child {
            border-radius: 0 0 0 5px !important;
        }

        tr:last-child th:last-child {
            border-radius: 0 0 5px 0 !important;
        }

        tr {
            height: 30px;
        }

        tr > td:first-child, th:first-child {
            padding-left: 5px;
        }

        tr:nth-child(even) {
            background: #F3F3F7 0% 0% no-repeat padding-box;
            opacity: 1;
        }

        td, th {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
            width: 127px;
        }

        td {
            font-family: "Myriad Pro";
            font-size: 12px;
        }

        .th-main-header {
            background: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;
            color: black;
            text-transform: none;
            font-family: "Myriad Pro Semibold";
            font-size: 12px;
            margin-bottom: 0px;
            opacity: 1;
            padding-top: 12px;
            padding-bottom: 12px;
            width: 127px;
        }

        .th-side-header {
            background: rgba(226, 226, 231, 0.5) 0% 0% no-repeat padding-box;
            color: black;
            text-transform: none;
            font-family: "Open Sans Semibold";
            font-size: 12px;
            margin-bottom: 0px;
        }

        .th-bottom-header {
            background: #E2E2E7 0% 0% no-repeat padding-box;
            color: black;
            font-family: "Open Sans Semibold";
            font-size: 12px;
            margin-bottom: 0px;
            opacity: 1;
        }

        .matrix-header {
            height: 60px;
        }

        .line-header {
            width: 140px;
            height: 2px;
            border-bottom: 1px solid $gray;
            transform: translateY(17px) translateX(-12px) rotate( 25deg );
            position: absolute;
            z-index: 0;
        }

        .mm {
            position: relative;
            bottom: -1em;
            float: left;
        }

        .cm {
            position: relative;
            top: -1em;
            float: right;
        }
    }

    .cell-width-quarter {
        width: 6%;
    }

    .cell-width-half {
        width: 50%;
    }

    .cell-width-three-quarters {
        width: 75%;
    }

    .cell-width-7percent {
        width: 7%;
    }

    .cell-width-18percent {
        width: 18%;
    }

    .padding-left {
        padding-left: 1em;
    }

    .padding-right {
        padding-right: 1em;
    }

    .padding-bottom {
        padding-bottom: 0.5em;
    }

    .padding-top {
        padding-top: 0.5em;
    }

    .controller-grid-container,
    .forest-plans-grid-container,
    .real-estates-grid-container,
    .assortment-tab-grid-container {
        display: flex;
        flex-flow: column;
        // margin: 0 0.5em 0 0.5em;
        .modal-grid {
            border: 1px solid $darkgray;
            border-radius: 0.5em;

            #centerRow {
                border-radius: 0.5em;
            }

            .shared-header {
                width: 100% !important;
                border-right: 0;
            }

            .no-border-bottom {
                border-bottom: 0;
            }

            .noborder-right {
                border-right: 0;
            }
        }

        .align-right { // for all cells
            text-align: right;
        }

        .align-center {
            text-align: center !important;
        }

        .lowercase {
            .ag-header-cell-label,
            .ag-header-group-cell-label {
                text-transform: lowercase !important;
            }
        }

        .upercase {
            .ag-header-cell-label,
            .ag-header-group-cell-label {
                text-transform: uppercase !important;
            }
        }

        .ag-header-group-cell-label {
            text-transform: uppercase;
            text-align: left;
        }

        .ag-header-group-text {
            margin-left: 2px;
        }

        .orange {
            color: #F68B1F;
        }

        .blue {
            color: #00ADEE;
        }

        .forest-plans-grid,
        .real-estates-grid {
            height: 300px;
        }

        .assortment-grid-height {
            height: 32em;
        }

        .stemtype-grid-height,
        .stemtypedistribution-grid-height {
            height: 23.5em;
        }

        .treespecies-grid-height {
            height: 13.5em;
        }

        .unclassified-grid-height {
            height: 9.5em;
        }

        .class-distribution-grid {
            height: 458px;
        }

        .ag-floating-bottom-viewport {
            background-color: $gray !important;
        }


        .ag-floating-bottom-container {

            .ag-row {
                background-color: $gray !important;
            }

            .ag-cell {
                background-color: transparent !important; // override disabled-cell color
                border-right: none;
                color: black !important;
            }
        }
        // disable mouse hover effect
        .ag-body-container {
            .ag-row:hover {
                .ag-cell {
                    background-color: $lightgray !important;
                    color: black !important;
                }
            }
        }
    }

    .forest-plans-grid-container {
        width: 50%;
        padding-left: 40px;
        border-left: 0.75px solid $half_trimble_brand_grey;

        .form-group {
            padding-left: 1em;
        }

        .paddingleft20 {
            padding-left: 20px;
        }
    }

    .real-estates-grid-container {
        width: 50%;
        padding-right: 40px;

        .form-group {
            padding: 0em;
        }
    }

    .assortment-tab-grid-container {
        height: auto;
        margin-right: 0.8em;
        width: 100%;

        .form-group {
            padding: 0em;
        }
    }

    .assortment-tab {
        height: 700px;
        overflow-y: auto;
        width: 100.9%;
        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    .divider-line {
        border-color: $gray;
    }

    .border-top {
        border-top: 1px solid $gray;
        padding: 0em;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .bottom-buttons {
        padding: 0.8em;
    }

    .sp-grid {

        .main-header {
            text-transform: uppercase;
            width: 570px !important;
        }
    }

    .ag-body-viewport {
        border-bottom: $grid-border;
    }


    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
        top: 5px; // default 8
    }

    [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
        top: 5px; // default 11
    }

    .details-grid-container {
        .ag-header-group-text {
            margin-left: 0px;
        }
    }
}
