﻿@import '../../shared/sass/_variables.scss';

header.hideNavigation + .partial-header {
    .customer-guideline {
        height: calc(100vh - 46px);
        top: 46px;
    }

    .guideline-ul {
        height: calc(100vh - 46px - 5em);
    }
}

.customer-guide-info-directive {
    font-family: $font-primary;
    font-size: 14px;
    color: $trimble_grey;
    text-align: left;
    $text-padding: 19px;

    .guide-header {
        padding: 0.5em;
        border-radius: 0.5em 0.5em 0 0;
        margin-bottom: 0;
    }

    .icon-info-guideline {
        margin-left: 1em;
        margin-top: 1em;
    }

    .guideline-info-header {
        margin-left: 3em;
        margin-top: 0.5em;
        font-family: $font-semibold;
        font-weight: 600;
        text-transform: uppercase;
    }

    .guideline-info-subheader {
        margin-left: 3em;
        text-transform: none;
    }

    .divider {
        border-top: 1px solid $half_trimble_brand_grey;
        margin-bottom: 1px;
        margin-top: 1px;
        margin-left: 1px;
    }

    .icon-close-guideline {
        margin-top: -2em;
        margin-right: 0.5em;
    }

    .customer-guideline {
        position: absolute;
        z-index: 1000;
        width: 39em;
        top: 177px;
        background: $trimble_white 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $trimble_header_shadow_color;
        opacity: 1;
        height: calc(100vh - 177px);
        right: 0px;
    }

    .guideline-list {
        margin-top: 0.5em;
        margin-left: -1em;
        text-transform: none;
    }

    .guideline-ul {
        width: 39em;
        height: calc(100vh - 177px - 5em);
        overflow-y: auto;
    }
}
