.cf-business-tab {
    overflow-y: hidden;
    padding-left: 15px;
    padding-right: 15px;

    .separation-line {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row {
        padding: 0px;
        border-width: 0px;
        margin-top: 5px;
        margin-bottom: 0px !important;
    }

    .align-published {
        padding-right: 0px;
        bottom: 9px;
        margin-top: -2px;
        height: 27px;
    }

    .cf-business-tab-taxarea {
        .font-grey-background {
            margin-top: 0;
        }
    }
}