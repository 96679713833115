@import '../../shared/sass/_variables.scss';

.common-grid {
    @import "app/shared/sass/_variables.scss";
    @import "app/shared/sass/_grid.scss";
    height: 100%;

    .spinner {
        margin-top: 100px;
    }

    .hidespinner {
        display: none;
    }

    .expand-button {
        padding-left: 9px;
        padding-right: 9px;

        &:hover {
            color: $trimble_tf_product_green;
        }

        span {
            top: 3px;
            font-size: 17px;
        }
    }

    .common-grid-toolbar {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        button {
            flex: 0;
           // margin-left: 6px;
        }

        button:hover(:not(.btn-silva)) {
            background: initial;
        }

        .ng-common-grid-toolbar-transclude,
        sp-common-grid-toolbar {
            width: 100%;
            display: inherit;
            display: flex;
            align-items: center;
        }

        .pull-right {
            margin-left: auto;
        }

        .dropdown-menu {
            .dropdown-subheader {
                white-space: nowrap;
                text-transform: uppercase;
                font-size: 0.8em;
                margin: 0.5em;
                font-weight: bold;
            }
        }
    }

    .panel.panel-sp-grid {
        margin: 0;
    }

    .sp-common-grid-toolbar-item {
        padding-right: .5em;

        &:last-child {
            padding-right: 0;
        }

        label {
            margin: 0;
        }

        .advanced-filtering-options-checkbox {
            top: 0px !important;
            margin-bottom: 5px;
        }
        // fix checkbox alignment
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
            top: 0;
        }

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
            top: 0px;
        }

        .advanced-filtering-dropdown {
            [type="checkbox"]:not(:checked) + label:before,
            [type="checkbox"]:checked + label:before {
                top: 8px;
            }

            [type="checkbox"]:not(:checked) + label:after,
            [type="checkbox"]:checked + label:after {
                top: 12px;
            }
        }
    }

    sp-common-grid-footer {
        overflow: hidden;
        display: block;

        .btn.pull-right {
            margin-left: .2em;
        }
    }    

    .common-grid-no-footer {
        .ag-root {
            border-radius: 0.5em;
            font-family: inherit;
        }
    }

    .common-grid-grid {
        height: 420px !important;

        .hasError {
            background-color: $lightred !important;
        }        

        .ag-cell.disabled {
            background-color: $disabled-background-color;
            border-bottom: none;
        }

        .ag-cell.empty-cell {
            display: none;
        }

        .ag-cell.text-right input {
            text-align: right;
        }

        .common-grid-selection-header-cell {
            display: flex;
            justify-content: center;
            height: 100%;
            align-items: center;
        }

        .ag-header-group-cell-label {
            padding: 4px 2px 4px 2px !important;

            .ag-header-group-text {
                padding-left: 2px;
            }
        }        

        textarea:focus, input:focus {
            outline: none;
        }


        select {
            height: 23px;
            margin: 0;
            margin-top: -1px;
            padding: 0px 0px 0px 4px;
        }

        .grid-input {
            max-width: 100%;
        }

        .grid-input + span.tooltiptext {
            visibility: hidden;
            width: 160px;
            background-color: #1e77a8;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            top: 150%;
            left: 50%;
            margin-left: -80px;
            word-wrap: normal;
        }

        .grid-input + span.tooltiptext::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #1e77a8 transparent;
        }

        .grid-input:hover, .grid-input:focus + span.tooltiptext {
            visibility: visible;
        }

        .ag-cell-focus {
            overflow: visible !important;
        }

        .ag-filter {
            margin: .5em;

            label {
                .ag-filter-checkbox {
                    top: 0.2em;
                    position: relative;
                    margin-right: 0.5em;
                }
            }

            .ag-filter-value {
                margin: 0;
            }
        }
    }

    .common-grid-footer {
        margin: 5px;
    }

    .flex-container {        
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 100%;        
        justify-content: center;
        align-items: center;

        .flex-item {
            flex: auto;
            width: 100%;
            height: 100%;
        }
    }

    .icon-button {
        //Override bootstrap's styles on icon-buttons
        > .btn {
            &:hover,
            &:focus {
                .icon-settings {
                    background-image: url(../../content/images/svg/settings-cog-green.svg);
                }
            }
        }

        &.open {
            .icon-printer {
                background-image: url(../../content/images/svg/print-green.svg);
            }

            .icon-favorite {
                background-image: url(../../content/images/svg/favorite-green.svg);
            }
        }
    }

    .icon {
        height: 17px;
        width: 18px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;

        &.icon-printer {
            background-image: url(../../content/images/svg/print.svg);

            &:hover,
            &:focus {
                background-image: url(../../content/images/svg/print-green.svg);
            }
        }

        &.icon-settings {
            width: 25px;
            //height: 16px;
            background-image: url(../../content/images/svg/settings-cog.svg);

            &:hover,
            &:focus {
                background-image: url(../../content/images/svg/settings-cog-green.svg);
            }
        }

        &.icon-exportCSV {
            width: 25px;
            //height: 16px;
            background-image: url(../../content/images/svg/Export_Grey.svg);

            &:hover,
            &:focus {
                background-image: url(../../content/images/svg/Export_LimeGreen.svg);
            }
        }



        &.icon-favorite {
            background-image: url(../../content/images/svg/favorite.svg);

            &:hover,
            &:focus {
                background-image: url(../../content/images/svg/favorite-green.svg);
            }
        }
    }

    .expand-table-button {
        background-color: $trimble_white;
        &:hover span {
            color: $trimble_tf_product_green;
        }

        span {
            color: $darkgray;
            font-size: 17px;
            vertical-align: middle;
        }

        .btn {
            width: 34px;
            padding: 6px;
        }
    }
}

.common-grid-grid-auto-height {
    .common-grid .common-grid-grid {
        height: 100% !important;
    }
}