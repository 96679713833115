﻿.advance-payment-setting {

    label {
        text-transform: uppercase;
    }

    .full-height {
        height: calc(100vh - 22em);
    }

    .active-text {
        margin-top: 7px;
    }

    .margin-top-8 {
        margin-top: 8px;
    }

    .margin-left {
        margin-left: 15px;
    }

    .mb-0 {
        margin-bottom: 0px;
    }

    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        top: 0;
    }

    .not-allowed {
        cursor: no-drop !important;
        pointer-events: none;
    }
}
