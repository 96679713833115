.accordion-group-container {
    height: 100% !important;

    &.accordion-open {
        .header-accordion {
            background-color: $black !important;
            color: $white;
        }
    }

    .header-accordion {
        margin-top: -3px;
        height: 24px;

        p {
            width: fit-content;
            margin-top: 6px;
            padding-left: 3px;
        }

        i {
            margin-top: 3px;
        }

        &.control-label {
            height: 34px;
            padding-left: 0.2em;
            padding-right: 0.2em;

            p {
                padding-left: 6px;
                padding-top: 4px;
            }

            i {
                padding-top: 4px;
            }
        }
    }

    .panel-heading {
        height: 31px;
    }

    .panel-title {
        height: 24px;
    }
}