﻿@import '../../../../../shared/sass/_variables.scss';

.create-energy-trade-area-modal {
    .modal-dialog {
        width: 32%;
        top: 28%;

        .modal-body {
            padding: 0;
        }
    }

    .additional-settings {


        .padding-left-50px {
            padding-left: 50px !important;
        }

        .selection-header {
            padding-bottom: 10px;
            font-weight: bold;
        }

        .div-selection-error {
            background-color: #F4D1D3;

            [type="checkbox"]:not(:checked) + label:before {
                border: 1px solid #c81922 !important;
            }
        }

        .trade-selection {
            display: flex;
            align-items: center;
            justify-content: center;

            > div.first-item {
                width: 50%;
                padding: 6.5px 5px 6.5px 8px;
            }

            > div.second-item {
                width: 50%;
                padding: 6.5px 0px 6.5px 33px;
            }


            [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                margin: 0;
            }

            [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
                top: 0;
            }

            [type="checkbox"]:checked + label:after {
                top: 0px;
            }
        }

        .species-blocks {
            display: flex;
            align-items: center;
            justify-content: center;

            .species-block {
                width: 50%;
                padding: 5px 10px 5px 25px;

                [type="radio"] {
                    margin-right: 5px;
                }

                .control-row {
                    label {
                        width: 100px;
                    }

                    span {
                        width: 30px;
                        text-align: right;
                    }
                }
            }

            .species-block-second-item {
                width: 50%;
                padding: 5px 10px 5px 60px;

                [type="radio"] {
                    margin-right: 5px;
                }

                .control-row {
                    label {
                        width: 100px;
                    }

                    span {
                        width: 30px;
                        text-align: right;
                    }
                }
            }

            .option-selected {
                color: $gray;
            }
        }
    }

    .first-row {
        height: 45px;
        background-color: #E2E2E7;

        .message {
            height: auto;
            float: left;
            padding-left: 15px;
            padding-top: 15px;
            margin-right: 15px;
            font-style: italic;
        }
    }

    .change-execution-time-btn {
        width: 100px;
    }

    .second-row {
        padding: 15px 20px;
    }
}

