﻿@import '../../../../../shared/sass/variables';

.contract-terms-phrases-modal {
    .border-left {
        border-left: 1px solid #B0AFB0;
    }

    select {
        border: 1px solid $darkgray !important;
        height: 27px !important;
        padding-left: 2px !important;
        padding-top: 4px !important;
    }

    .contract-term-input {
        border: 1px solid $darkgray !important;
        height: 27px !important;
        padding-left: 2px !important;
        padding-top: 4px !important;
    }

    .pt-15 {
        padding-top: 15px;
    }

    .pb-15 {
        padding-bottom: 15px;
    }

    .border-bottom {
        border-bottom: 1px solid #B0AFB0
    }

    .date-input {
        padding-right: 0px;
    }

    .grid-border {
        /*border-left: 1px solid #616161;
        border-right: 1px solid #616161;
        border-top: 1px solid #616161;
        border-top-right-radius: 0.5em;
        border-top-left-radius: 0.5em;*/
        .padding-bottom {
            padding-bottom: 2px;
        }

        .grid-container {
            margin: 15px 0px 0px 0px;
            border: 1px solid grey;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);

            .sp-grid .ag-floating-bottom-container .ag-cell {
                background-color: #d1d1d1;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    left: 4px;
                }
            }

            .select-all-wrapper {
                left: 5px;
            }

            .sp-grid .ag-root.ag-root {
                border-radius: 4px !important;
            }

            .msmt-grid {
                height: 300px;

                .black-exclaimation {
                    @extend .background-proprieties;
                    background-size: 6px;
                    background-image: url(../content/images/svg/CriticalNotificationSymbol_Black.svg);
                }

                .ag-body-viewport {
                    overflow-y: auto !important;
                    overflow-x: auto !important;
                    background-color: white;
                }

                .sp-grid {
                    .ag-header-cell-label {
                        padding-top: 6px !important;
                    }

                    .ag-body {
                        background-color: white;
                    }
                }

                .ag-header-cell-label {
                    padding-top: 6px !important;
                }

                .padding-top-6 {
                    padding-top: 6px;
                }

                .padding-top-5 {
                    padding-top: 5px;
                }
            }       
            
            .ag-row-focus,
            .ag-row-hover,
            .ag-row-selected {
                .pen-icon[disabled] {
                    background-color: $lightgray;
                    cursor: not-allowed;
                }
            }
        }

        .grid-header-checkbox-label {
            margin: 0;
            left: -4px;
            top: 2px;
        }

        .ag-floating-bottom-container {
            font-size: 12px;
            font-weight: bold;
        }

        .ag-cell {
            [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                top: 5px !important;
            }

            [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                top: 2px !important;
            }
        }

        .cell-align-center {
            text-align: center;
        }


        .sp-grid {
            .cell-aligned-left {
                .ag-header-group-cell-label {
                    text-align: left;
                    padding-left: 4px;
                }
            }
        }

        .button-container {
            padding: 5px;
            background-color: #e8e8e8;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;


            .btn {
                width: 16%;
                margin: 4px;
                height: 30px !important;
                padding: 0px;
            }

            .update-btn {
                width: 296px;
            }
        }

        .button-container-grid {
            padding: 10px;
            background-color: #e8e8e8;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
}

.contract-terms {
    .pen-icon {
        border: none;
    }    
}

.contract-term [type="checkbox"]:not(:checked) + label:before, 
.contract-term [type="checkbox"]:checked + label:before {
    top: 10px;
}

.contract-term [type="checkbox"]:not(:checked) + label:after, 
.contract-term [type="checkbox"]:checked + label:after {
    top: 10px;
    left: -4px;
}

.contract-term {
    [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
        top: 10px;
        left: -4px;
    }

    .ag-row .checkbox-cell,
    .ag-row .ag-cell[col-id="selection"],
    .ag-row .common-grid-checkbox,
    .ag-row .ag-cell[col-id="$selected"] {
        padding-left: 2px !important;
        padding-top: 3px !important;
    }
}
