﻿.regeneration-criteria {
	margin-top: 0px !important;
	
	.btn {
		padding-left: 6px !important;
	}

	.header-padding {
		padding-left: 5px;
		padding-top: 3px;
	}

	.panel-header {
		background: $grid-header-background;
		text-transform: uppercase;
		font-family: "Myriad Pro Semibold";
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom: none;
	}

	.top-round-border {
		border: 1px solid rgb(97,97,97);
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom: none;
	}

	.navigation-grid {
		height: 50vh;
		border: 1px solid rgb(97,97,97);
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		user-select: none !important;
	}

	.editable-text {
		textarea:focus, input:focus {
			color: black;
		}

		input, select, textarea {
			color: black;
		}
	}

	.left-align {
		text-align: left;
		font-size: 12px;
		background-color: white;
	}

	.last-ui-header {
		border-right: none !important;
		font-size: 12px;
		text-transform: none !important;
		padding-right: 2px;
	}

	.header-generic {
		font-size: 12px;
		text-transform: none !important;
	}

	.header-parent {
		border-bottom: none;
	}

	.header-empty {
		border-top: none;
	}

	.right-border-hide {
		border-right: none;
		padding-right: 3px !important;
	}

	.sp-grid .ag-root .ag-header .ag-header-row .right-border-hide {
		border-right: none;
	}

	hr-margin {
		margin-top: 12px !important;
		margin-bottom: 12px !important;
	}

	.fine-border {
		border: 1px solid #616161;
	}

	.btn-text-left {
		text-align: left !important;
	}

	.dropdown-menu-position {
		width: 100% !important;
		cursor: pointer;
	}

	.btn-group {
		width: 100%;
		border-radius: 0.2em 0.2em 0.2em 0.2em !important;
		padding-top: 1px;
	}

	.settings-item {
		vertical-align: middle;
		display: inline-block;
		line-height: 34px;
		height: 34px;
	}

	.center {
		margin-top: 6px;
	}

	.height-fixed {
		height: 22px !important;
	}
}
