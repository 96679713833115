﻿.ledger-balance-interval-modal {
    .modal-dialog {
        top: 15%;
        width: 25%;
    }

    .container-padding {
        padding: 20px;
        margin-left: 20px;
        margin-right: 0px;
    }

    .padding-left-30px {
        padding-left: 30px;
    }
}
