.price-edit {
    .modal-dialog {
        width: 400px;
        top: 265px;
    }

    .align-left {
        width: 4%;
        float: left;

        input {
            width: 15px;
            height: 15px;
            margin-top: 2px;
        }  
    }

    .max-width-label {
        margin-left: -6px;
        width: 100%;
    }

    .form-control {
        border-radius: 2px;
        border: 1px solid #707070;
        width: 100px;
        // input[type="number"]::-webkit-inner-spin-button {
        //     opacity: 1;
        // }
    }

    .modal-trimble-information-header-title {
        font-size: 14px;
    }
}