﻿@import '../../shared/sass/_variables.scss';

.productInformation {
    &.container {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .grid-edit-modal {
        width: calc(100% - 60px);

        .productInformation-search {
            float: left;
            line-height: 28px;
            height: 30px;
            margin: 0 0.5em 0 0;
            background-color: $trimble_white;
        }

        .header-column, .body-column {
            &.width-2 {
                width: 2%;
                border-right: 1px solid #d1d1d1;
            }

            &.width-4 {
                width: 4%;
            }

            &.width-5 {
                width: 5%;
            }

            &.width-6 {
                width: 6%;
            }

            &.width-7 {
                width: 7%;
            }

            &.width-8 {
                width: 8%;
            }

            &.width-9 {
                width: 9%;
            }

            &.width-10 {
                width: 10%;
            }

            &.width-12 {
                width: 12%;
            }

            &.width-15 {
                width: 15%;
            }

            &.width-16 {
                width: 16%;
            }

            &.width-18 {
                width: 18%;
            }

            &.width-55 {
                width: 55%;
            }
        }
    }

    .productInformation-settings-row {
        width: 100%;
        margin-bottom: 42px;

        .productInformation-search {
            float: left;
            line-height: 28px;
            height: 30px;
            margin: 0 0.5em 0 0;
        }

        .productInformation-views {
            float: left;
            padding-top: 5px;
            padding-left: 10px;

            input[type="radio"] + label {
                color: inherit;
                padding-left: 5px;
                padding-right: 15px;
                text-transform: uppercase;
                vertical-align: top;
            }

            .label-alignment {
                padding-top: 1px;
            }
        }

        .productInformation-buttons {
            float: right;
            vertical-align: middle;
            display: inline-block;
            line-height: 28px;
            height: 30px;
            margin-top: -2px;

            .table-settings-button {
                margin-left: 0.5em;
            }

            .settings-item {
                vertical-align: middle;
                display: inline-block;
                line-height: 34px;
                height: 34px;
                margin: 0em 0.5em 0 0;
            }

            .icon {
                height: 17px;
                width: 18px;
                line-height: 30px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;

                &.icon-settings {
                    width: 25px;
                    //height: 16px;
                    background-image: url(../../content/images/svg/settings-cog.svg);

                    &:hover,
                    &:focus {
                        background-image: url(../../content/images/svg/settings-cog-green.svg);
                    }
                }
            }

            .icon-button {
                > .btn {
                    &:hover,
                    &:focus {
                        background-color: $trimble_white;

                        .icon-settings {
                            background-image: url(../../content/images/svg/settings-cog-green.svg);
                        }
                    }

                    background-color: $trimble_white;
                    border-bottom-left-radius: 4px;
                    border-top-left-radius: 4px;
                    margin-left: 0.5em;
                }

                &.open {
                    .icon-printer {
                        background-image: url(../../content/images/svg/print-green.svg);
                    }
                }
            }

            button:disabled {
                background-color: $disabled-background-color;
            }

            .btn {
                background-color: initial;
            }

            .button-border {
                background-color: white;
                border: 1px solid darkgray;
                border-radius: 5px;
            }

            .icon {
                height: 17px;
                width: 18px;
                line-height: 30px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
            }

            .icon.icon-printer {
                background-image: url(../../content/images/svg/print.svg);
            }
        }
    }

    .ag-header-cell {
        text-transform: uppercase;
    }
}
