﻿@import '../../../shared/sass/_variables.scss';

$bolierplateTextsSelectorGridHeight: 541px;
$panel-margin: 3px;
$shadow-color: #00000029;

.attachments-container {
    height: $bolierplateTextsSelectorGridHeight;
    font-family: "Myriad Pro", Verdana;
    font-size: 12px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    margin-top: 2px;

    *[class*="col-"] {
        padding: 0px;
    }

    .active-text {
        top: 5px;
        left: 15px;
    }

    .disable-anchor {
        pointer-events: none;
        opacity: 0.5;
    }

    .attachment-search {
        line-height: 30px;
        height: 30px;
        margin: 0 6px 13px 0;
        width: 20%;
    }

    .silva-search {
        .glyphicon {
            top: 0px;
        }
    }

    .glyphicon-search {
        left: 5px;
    }

    .material-switch-label {
        top: -7px;
    }

    .material-switch-content {
        > input[type="checkbox"]:checked + label::after {
            top: 4px;
        }

        > input[type="checkbox"]:not(:checked) + label::after {
            top: 3px;
        }
    }

    .material-switch-container {
        width: 50px;
        float: left;
        top: 5px;
    }

    .attachments-selection-grid {
        height: $bolierplateTextsSelectorGridHeight;
        box-shadow: 0px 3px 6px #00000029;
    }

    .attachments-container-content {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $shadow-color;
        border: 0.75px solid #B0AFB0;
        border-radius: 3px;
        /*button.btn-upload {
            border: none !important;
        }*/
        .row {
            margin: 0px;
        }

        .titleBoxText {
            width: calc(100% - 50px);
        }

        .selection-grid-footer {
            padding: 5px;
            background: #E2E2E7 0% 0% no-repeat padding-box;
            width: 100%;
            font-style: italic;

            .pull-right {
                margin-right: 38px;
            }
        }

        .padding-top-10 {
            padding-top: 10px;
        }

        .attachment-header {
            height: 30px;
            padding: 6px 0px 8px 6px;
            background: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;
            text-transform: uppercase;
            font-weight: 700;
        }

        .attachment-header-black {
            height: 30px;
            padding: 6px 0px 8px 6px;
            text-transform: uppercase;
            background: #000000 0% 0% no-repeat padding-box;
            color: #FFFFFF;
            font-weight: 700;
        }

        .attachment-custom-width-label {
            width: 22%;
        }

        .attachment-custom-width-control {
            width: 78%;
        }

        .fields-content {
            padding: 30px;
            display: inline-block;
            width: 100%;

            .multiselection-drop-down .checkboxes .checkboxesList {
                width: 100%;
            }
        }

        .attachment-input-field {
            height: 30px;
            border: 0.5px solid #B0AFB0;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            width: 100%;
            border-radius: 5px;
        }

        select.attachment-input-field[disabled] {
            background-color: #eee;
        }

        .browse-button {
            height: 30px;
            margin-left: 5px;
            width: 20%;
        }

        .browse-input-field {
            height: 30px;
            border: 0.5px solid #B0AFB0;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 5px;
            background-color: #eee;
        }

        .vertical-line {
            border-left: 0.5px solid #B0AFB0;
            height: 60px;
            position: absolute;
            left: 10%;
            margin-left: -3px;
            top: 0;
        }

        .attachment-vertical-line {
            border-left: 0.5px solid #B0AFB0;
            height: 30px;
            position: absolute;
            left: 10%;
            margin-left: -3px;
            top: 0;
            padding-left: 30px;
        }

        .check-box-group {
            left: 50px;
            top: 0px;
        }

        .check-box-label {
            padding-top: 8px;
        }

        .fill-textarea {
            height: 155px;
        }

        .text-textarea {
            height: 100% !important;
            width: 100%;
            margin: 0px;
            border: 0.75px solid #B0AFB0;
        }

        .attachment-footer {
            border-radius: 3px 3px 0px 0px;
            background: #F3F3F7 0% 0% no-repeat padding-box;
            height: 64px;
        }

        .attachment-button {
            margin: 15px;
        }

        .multiselection-drop-down {
            line-height: 30px;
            width: 100%;
            border-radius: 0px;
            height: 30px;

            .titleBox {
                width: 100%;
                border-radius: 5px;
                height: 30px;
            }

            .checkboxes {
                width: 100%;
                border-radius: 0px;
                z-index: 3;

                .select-all-label {
                    line-height: 30px;
                }

                .checkboxesList .checkboxLabel {
                    line-height: 35px;
                }
            }

            .multiselectionSearch {
                height: 30px;
                margin-bottom: 5px;

                .multiselectionSearchInput {
                    width: 95%;
                }
            }
        }

        .file-upload-directive input.form-control {
            height: 30px !important;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
            border: 0.5px solid #B0AFB0 !important;
        }

        .file-upload-directive button {
            height: 30px !important;
            /*            border-bottom-right-radius: 0px !important;
            border-top-right-radius: 0px !important;*/
            border: 1px solid #B0AFB0 !important;
        }

        .file-upload-directive .btn-upload {
            border: none !important;
            border: 0px !important;
        }

        .admin .partial .upload-privacy-notice .file-upload-directive .input-group .input-group-btn button.btn[disabled] {
            border: 1px solid #616161 !important;
        }

        .admin .partial .upload-privacy-notice .file-upload-directive .input-group .input-group-btn button.btn[disabled] {
            border: 1px solid #616161 !important;
            border-bottom-right-radius: 5px !important;
            border-top-right-radius: 5px !important;
        }
    }

    .container-padding {
        padding-left: 15px;
    }
}
