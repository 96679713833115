﻿@import '../../../../shared/sass/_variables.scss';

.upkeep-wrapper {
    overflow: hidden;
    position: relative;

    .selection-section {
        display: flex;
        margin-bottom: 15px;

        .display-period-text {
            margin-top: 5px;
        }

        .selection-section-content {
            padding-right: 0px;
            width: 75%;
        }

        .display-flex {
            display: flex;
        }

        .height-s {
            height: 30px;
        }

        .specification-option {
            /*margin-left: 10px;
                margin-right: 10px;*/
            width: 275px;
            text-transform: uppercase;
            border: 1px solid #d1d1d1;
        }
    }

    .margin-left-right-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .no-padding {
        padding: 0px;
    }

    .visibility-hidden {
        visibility: hidden;
        cursor: not-allowed;
    }

    .disabled {
        cursor: not-allowed;
    }

    .upkeep-inner-wrapper {
        overflow: hidden;
        height: 100%;
        width: 100%;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
    }

    .placeholder-content {
        height: 86%;
    }

    .upkeep-content {
        position: relative;
        border: 1px solid $darkgray;
        border-radius: 0 0em 0.5em;
        overflow: hidden;
        padding: 1em;
        background-color: $white;
    }

    .upkeep-tabs {
        clear: both;
        top: 1px;
        position: relative;
    }

    .upkeep-tab {
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        padding: 0.2em 0 0.2em 0em;
        border-radius: 0.5em 0.5em 0 0;
        border: 1px solid $darkgray;
        border-bottom: 1px solid $darkgray;
        position: relative;
        font-family: $font-semibold;
        background-color: $gray;
        cursor: pointer;
        z-index: 1;
        width: calc(25% - 0.16em);

        &.active {
            background-color: $white;
            border-bottom: 1px solid $white;

            &:hover {
                background: inherit;
                color: inherit;
                border-bottom: 1px solid white;
            }
        }

        &:hover {
            background: black;
            color: white;
        }
    }

    .upkeep-search {
        position: relative;
        top: 0px;
        z-index: 1;
        width: 275px;

        &.hideSearch {
            visibility: hidden;
        }

        input[type="text"] {
            display: inline-block;
            border: 0;
            //Setting height to 100% causes a weird rendering bug in chrome
            height: 99%;
            width: calc(100% - 38px);
            max-width: initial;

            &:focus {
                outline-width: 0;
            }
        }

        .glyphicon-remove {
            cursor: pointer;

            &:hover {
                color: $trimble_tf_product_green;
            }
        }
    }

    .upkeep-actionbar {
        background-color: $lightgray;
        overflow: hidden;
        height: 14%;
        padding: 0 0 1em 0;
        border: 0 2px 2px 2px solid $darkgray;

        button {
            margin-right: 20px;

            &:first-of-type {
                margin-left: 1em;
            }

            &:last-of-type {
                margin-right: 1em;
            }
        }

        .upkeep-add-row {
            border: 1px solid $darkgray;
            border-left: 0;
            border-right: 0;
            background-color: $gray;
            padding: 0.5em 1em;
            margin-bottom: 1em;
            cursor: pointer;

            &:hover {
                background-color: $darkgray;
            }

            &.disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }

        .amount-of-picked-rows {
            line-height: 34px;
            height: 34px;
            display: inline-block;
        }
    }

    .save-button {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.upkeep-wrapper {
    .silva-header {
        @extend %silva-header;
    }

    #new:last-of-type:not(.active) {
        background-color: rgba($blue, 0.1);

        &:hover {
            background-color: $black;
        }
    }


    select,
    input[type="text"],
    input[type="number"] {
        border-radius: 4px;
        color: black;
        max-width: 260px;
        width: 100%;
        display: block;
        margin: 0 auto;

        &.stretch {
            width: 90%;
            max-width: initial;
        }

        &.small {
            max-width: 50px;
        }

        & + input {
            margin-top: 5px;
        }
    }

    select {
        font-size: 12px;
    }


    .kemera-settings-wrapper {
        .third {
            width: 33.33%;
            display: inline-block;
            vertical-align: top;
        }

        .two-thirds {
            width: 66.66%;
        }

        .form-group {
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;

            label {
                margin-bottom: 0;
            }
        }

        .vertical-top {
            vertical-align: top;
        }
    }

    .kemera-inner-content {
        padding: 1em;

        .full-width {
            width: 100%;
        }

        &.third {
            border-right: 1px solid $lightgray;
        }

        input, select {
            width: 140px;
            display: inline-block;
            padding: 0.2em;
            text-align: right;
        }

        label {
            min-width: 100px;
            width: calc(100% - 143px);
        }

        .form-group + .label-headers {
            margin-top: 1em;

            label {
                margin-bottom: 0.5em;
            }
        }

        .label-headers label {
            margin-bottom: 1em;
            color: $darkgreen;
            min-width: initial;
            width: 50%;

            &.uppercase {
                text-transform: uppercase;
            }

            &:last-of-type {
                text-align: right;
            }
        }
    }

    .distribution-table {
        #new.invalidRow {
            background-color: initial;

            &:hover {
                background-color: initial;
            }
        }

        .distribution-not-unique-warning {
            + input {
                color: $red;
                border-color: $red;
            }

            font-size: 10px;
            color: $red;
        }
    }
}

select.shrink-unselected:not(:focus) {
    max-width: 50px;
}

.town-tab-invalid-data-tooltip {
    .field-validation-tooltip {
        font-size: 10px;

        .tooltip-inner {
            padding: 2px;
        }
    }
}
