﻿@import '../../../shared/sass/_variables.scss';

.mobileAppAdmin {

    .mobileAppAdmin-panel {
        min-height: calc(100vh - 7px - 172px);

        .mobileAppAdmin-rounded-corners {
            border: 1px solid $darkgray;
            border-radius: 0.5em;
            overflow: hidden;
            margin: 5px -1px 5px 0px;

            .mobileAppAdmin-row {
                margin-left: 0px;
                margin-right: -1px;

                a {
                    text-decoration: underline;
                }
            }

            .round-padding {
                padding-top: 1em;
                padding-bottom: 1em;
            }

            .round-padding-bottom {
                padding-bottom: 1em;
            }

            .devices-list-grid {
                border: 1px solid #616161;
                border-radius: 0.5em;
                overflow: hidden;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            .mobileAppAdmin-column {
                padding: 0px;
            }

            .mobileAppAdmin-col-no-lr-padding {
                padding: 0px;
            }

            .mobileAppAdmin-col-left-padding {
                padding-left: 1em;
                padding-right: 1em;
            }

            .mobileAppAdmin-row-left-padding {
                padding-left: 7px;
                padding-right: 7px;
            }

            .white-on-black-header {
                background-color: black;
                color: white;
                text-transform: uppercase;
                font-family: "Myriad Pro Semibold";
                padding: 0.5em;
            }

            .white-left-border {
                border-left: 1px solid $trimble_white;
            }

            .black-on-grey-header {
                background-color: #d1d1d1;
                text-transform: uppercase;
                font-family: "Myriad Pro Semibold";
                padding: 0.5em;
                border-radius: 0.5em 0.5em 0 0;
            }

            .form-control {
                padding: 0.2em;
                height: 27px;
            }

            .settings-item {
                vertical-align: middle;
                display: inline-block;
                line-height: 27px;
                height: 27px;
            }

            .custom-margin {
                margin-left: 10px;
                margin-right: auto;
            }

            .buttons-row {
                margin-left: -13px;
            }

            .button-large-top-align {
                padding: 6px;
                margin-left: -13px;
                margin-top: 1px;
            }

            .reduce-width {
                width: 51%;
                float: left;
            }

            .input-no-round-corner {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .resize-height {
                height: 18px;
            }

            .resize-img-big-button {
                padding: 0px;
                height: 34px;
            }

            .button-line-button1 {
                border-top: 1px solid #616161;
                border-right: 1px solid #616161;
                border-bottom: 1px solid #616161;
                border-radius: 0;
                border-radius: 0;
                height: 27px;
                width: 15%;
                padding: 0px 2px 1px 2px;
            }

            .button-line-button3 {
                border: 1px solid #616161;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: none;
                height: 27px;
                width: 15%;
                padding: 0px 2px 1px 2px;
            }

            .left-padding {
                padding-right: 22px;
            }

            .top-padding {
                padding-top: 1em;
            }

            .custom-black-row {
                margin-left: -15px;
                margin-right: -14px;
            }

            .report {
                padding: 0;
            }

            .no-margin-top {
                margin-top: 0px;
            }

            .custom-label {
                margin-top: 10px;
                margin-bottom: 4px;
            }

            .equipment-tablefoot-actionbar {
                border: 1px solid $darkgray;
                border-top: 0;
                border-radius: 0.5em;
                background-color: $lightgray;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                overflow: hidden;
                padding: 0.5em 0.5em;

                .max-width {
                    width: 100%;
                }
            }

            .readonly-text {
                font-style: italic;
                background-color: #d1d1d1;
                font-size: 12px;
            }

            .textarea-height {
                height: 71%;
            }

            .white-background {
                background: white;
            }

            .header-upper-part {
                text-transform: uppercase;
            }

            .grid-header-text {
                text-transform: uppercase;
                text-align: left;
            }

            .display-column-center {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            .mobileAppAdmin-selection-grid {
                .ag-cell {
                    font-size: $default-font-size;
                    padding-left: 4px;
                }

                .grid-check-box-selection {
                    top: -7px;
                }

                .ag-header-group-cell-label {
                    text-align: left;
                    padding-left: 4px;
                }


                .center-column {
                    .ag-header-cell-text {
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                    }
                }
            }

            .no-rows-bubble {
                margin-left: 36.5%;
                padding: 10px;
                background-color: white;
                border: 1px solid #d1d1d1;
                border-radius: 5px;
                text-align: left;
                width: 162px;
            }

            ::-webkit-input-placeholder {
                font-style: italic;
            }

            :-moz-placeholder {
                font-style: italic;
            }

            ::-moz-placeholder {
                font-style: italic;
            }

            :-ms-input-placeholder {
                font-style: italic;
            }

            .panel-body {
                padding: 0px;

                .form-group {
                    padding: 10px;
                }

                .interface-keys {
                    background-color: $lightgray;
                    padding: 5px 0px;

                    .form-group {
                        padding: 5px 10px;
                    }
                }

                .user-roles {
                    padding: 10px;

                    .form-group {
                        padding: 0px;
                    }

                    .extra-vertical-padding {
                        padding: 7px 0px;
                    }
                }
            }
        }

        .section-bottom-buttons {
            margin-top: 12px;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .btn {

                &:nth-child(1) {
                    padding: 1em 2em 1em 2em;
                }

                &:nth-child(2) {
                    margin-left: 12px;
                    padding: 1em 2em 1em 2em;
                    margin-right: 12px;
                }

                &:nth-child(3) {
                    margin-left: 12px;
                    padding: 1em 2em 1em 2em;
                    margin-right: 12px;
                }

                &:nth-child(4) {
                    padding: 1em 2em 1em 2em;
                }
            }
        }

        .select-all-devices {
            left: 3px;
            top: 3px;
            position: absolute;
        }
    }

    .full-height {
        height: 100%;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .border-top {
        border-top: 1px solid $darkgray;
    }

    .border-right {
        border-right: 1px solid $darkgray;
    }

    .border-left {
        border-left: 1px solid $darkgray;
    }

    .border-bottom {
        border-bottom: 1px solid $darkgray;
    }

    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
        top: 0;
    }

    [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
        top: 0px;
    }

    .width-half {
        width: 50%;
    }

    .no-padding {
        padding: 0px;
    }

    .simpleDropDown:disabled, .activationCode {
        background-color: $disabled-button-background-color;
    }

    [type="checkbox"]:disabled + label {
        color: initial;
    }
}
