.sa-release-to-transport-modal {
    
    .red-background {
        background-color: $lightred !important;
    }

    .gray-background {
        background-color: $disabled-background-color;
    }

    .date-field-width {
        width: 100%;
    }

    .modal-grid {
        .ag-row-selected {
            input.red-background[type="date"] {
                color: black !important;
            }
        }
    }

    .setDate {
        margin-right: 7px;
    }

    .radio-group {
        margin-top: 4px;
        width: 221px;

        label {
            margin-right: 1em;
            vertical-align: middle;
        }

        input {
            margin-right: 0.3em;
        }
    }

    #release-to-transport-modal-grid-resource-chain{
        .ag-theme-fresh {
            &.common-grid-grid{
                height: 190px;
            }
        } 
        
        #agHeaderCellLabel{
            visibility: hidden;
        }
    }

    #release-to-transport-modal-grid-resource-chain-details{
        .ag-theme-fresh {
            &.common-grid-grid{
                height: 190px;
            }
        }  
  
        .panel-sp-grid {
            margin-top: 21px;
        }
    }
    
    .start-date{
        margin-bottom: 4px;
        margin-top: 8px;

        input{
            border-radius: 4px;
            border: 1px solid #616161;
            padding-left: 0.3em;
            width: 142px;
            margin-left: 5px;
        }
    }

    .partial-box-header{
        background-color: #d1d1d1;
        text-transform: uppercase; 
        margin-bottom: 1px;
        border-radius: 0;

        .font-usual{
            text-transform: none;
            font-family: "Myriad Pro", Verdana;
            font-size: 12px;
            margin-left: 8px;
            font-style: italic;
        }
    }

    [col-id="startDate"],[col-id="endDate"]{
        pointer-events: none;
    }

    .silva-search-common {
        width: 530px;
    }

    .modal-body {
        &.select-plots-modal{
            padding-bottom: 2px;
        }
    }

    .divider{
        width: 1px;
        height: 28px;
        margin-right: 8px;
        background-color: #e5e5e5;
    } 
}