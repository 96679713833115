﻿@import '../../shared/sass/_variables.scss';
@import './serviceProvider/contract/contracts.scss';
@import './timberTradeAndHarvestSpecifications/defaultAssortmentGroups/editAssortmentModal.scss';
@import './timberTradeAndHarvestSpecifications/defaultFactoryPrices/priceModal.scss';
@import './woodforceSpecifications/feeBasisDifficultyClass/feeBasisDifficultyClassEditModal.scss';

.mhy {
    $font-family: "Myriad Pro", Verdana;
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: $font-family;
    font-size: 12px;

    .grid-header-styling {
        font-weight: bold;
        font-size: 12px !important;
    }

    .align-text-right {
        text-align: right;
    }

    .customer-bottom-buttons {
        margin: 1em 0.2em 1em 0.2em;
    }

    .row { //override Bootstrap's row default settings for maring
        margin: 0px;
    }
    //TODO find a better solution
    *[class*="col-"] { //override Bootstrap's col- default settings for padding
        padding: 0px;
    }

    button:disabled {
        border: 1px solid #FFF;
        background-color: rgb(209, 209, 209);
        cursor: not-allowed;
    }

    .settings-item {
        vertical-align: middle;
        display: inline-block;
        line-height: 34px;
        height: 34px;
    }

    .view-option-select {
        cursor: pointer;

        button {
            line-height: 20px;
            min-width: 217px;
            text-align: left;
            padding-left: 10px;
        }
    }

    .drop-down-with-checkboxes {
        .btn {
            background-color: white !important;
            border: 1px solid #d1d1d1 !important;
        }

        .checkbox-wrapper * {
            z-index: 100;
        }

        .dropdown-toggle {
            width: 24px;
        }

        .dropdown-menu {
            font-family: "Myriad Pro", Verdana;
            font-size: 12px;
            color: black;

            > div {
                overflow-y: hidden;
                height: auto;
            }
        }

        tr {
            height: 40px;
        }

        .replacing-checkbox {
            top: -5px;
        }

        td:first-child {
            width: 17%;
        }

        td:last-child {
            width: 83%;
        }
    }

    .custom-drop-down {

        .btn {
            padding-top: 2px;
            padding-bottom: 2px;
            background-color: white;
        }

        button:disabled {
            border: 1px solid darkgray;
            background-color: #D1D1D1;
            cursor: not-allowed;
        }

        .dropdown-menu {
            color: black;
        }
    }

    .dropDownMinWidth250px {
        .dropdown-menu {
            min-width: 250px;
        }
    }

    .right-align {
        text-align: right;
        font-size: 12px;
        background-color: white;
    }

    .left-align {
        input {
            text-align: left !important;
        }
    }

    .editable-text { //moved one level up, to be applied to all myh grids
        textarea:focus, input:focus {
            color: black;
            text-align: right;
        }

        input, select, textarea {
            color: black;
            text-align: right;
        }

        input {
            border-style: none;
            width: 100%;
            height: 19px !important;
        }
    }

    .grid-input {
        text-align: left;
    }

    .ag-theme-fresh {
        .ag-root {
            font-family: $font-family;
        }
    }

    .price-limits {        
        .navigation-grid {
            height: 50vh;            
        }                
    }

    .left-text {
        width: calc(100% - 85px);
    }

    .header-padding {
        padding-left: 5px;
        padding-top: 3px;
    }

    .height-fixed {
        height: 22px !important;
    }

    .menu-position {
        padding-left: 0px;
    }

    .width-fixed {
        width: 83px;
    }

    .calculation-settings {

        .btn {
            padding-right: 0;
        }

        .aikaVali-validation {
            .field-validation-tooltip {
                left: 130px !important;
            }
        }

        .pisteLkm-validation {
            .field-validation-tooltip {
                left: 130px !important;
                top: 230px !important;
            }
        }

        .pisteLkm-input-align {
            margin-top: -60px !important;
        }

        .aikaVali-input-align {
            margin-top: -18px !important;
        }

        .right-panel {
            border: 1px solid #616161;
            border-radius: 0.5em;
            background-color: #fff;
            padding-bottom: 140px;
            margin-left: 0px;

            .panel-header {
                text-transform: uppercase;
                font-family: "Myriad Pro Semibold";
                border-radius: 0.5em 0.5em 0 0;
            }
        }

        .partial-header {
            text-transform: uppercase;
            padding: 0.5em;
            margin-bottom: 1px;
        }

        .label-margin {
            margin-bottom: 0px;
        }

        .form-group {
            margin-top: 7px;
        }

        .btn-text {
            font-family: "Myriad Pro", Verdana;
        }

        .center {
            margin-top: 6px;
        }

        .button-size {
            width: 170px;
            height: 40px;
        }

        .hr-margin {
            margin-top: 12px !important;
            margin-bottom: 12px !important;
        }

        .fine-border {
            border: 1px solid #616161;
        }

        .btn-text-left {
            text-align: left !important;
        }

        .dropdown-menu-position {
            width: 100% !important;
            cursor: pointer;
        }

        .settings-item {
            vertical-align: middle;
            display: inline-block;
            line-height: 34px;
            height: 34px;
        }

        .btn-group {
            width: 100%;
            border-radius: 0.2em 0.2em 0.2em 0.2em !important;
            padding-top: 1px;
        }

        .left-padding {
            padding-left: 2px !important;
        }
    }

    .forest-reproductive-material-sales-batch-list {
        .btn-spacing {
            margin-right: 10px !important;
        }

        .top-round-border {
            border: 1px solid rgb(97,97,97);
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .settings-item {
            vertical-align: middle;
            display: inline-block;
            line-height: 28px;
            height: 30px;
            margin-top: 0.5em;
        }

        .button-small {
            width: 135px;
        }

        .button-medium {
            width: 180px;
        }

        .button-large {
            width: 180px;
            height: 40px;
        }

        .buttons-group-border {
            background-color: #D1D1D1;
            padding: .5em 1em;
            border: 1px solid #616161;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            border-top: 0px;
        }        

        .forest-reproductive-settings-row {
            width: 100%;
            height: 36px;

            .forest-reproductive-search {
                float: left;
                line-height: 28px;
                height: 30px;
                margin: 0.5em 0.5em 0 0;
            }

            .forest-reproductive-views {
                float: left;
                padding-top: 10px;
                padding-left: 10px;

                input[type="radio"] + label {
                    color: inherit;
                    padding-left: 5px;
                    padding-right: 15px;
                    margin-top: 1px;
                    margin-bottom: 0;
                }

                input[type=radio]:focus {
                    outline: none;
                }

                .label-alignment {
                    padding-top: 1px;
                }
            }
        }

        .grid-edit-modal .body-row .the-body .body-column {
            label {
                border-color: transparent;
            }

            .forest-reproductive-material-last-label {
                position: relative;
                left: 2px;
                border: none;
            }

            .label-disabled {
                pointer-events: none;
            }
        }

        .margin-top-bottom {
            margin: 10px 0px;
        }        
    }

    .forest-reproductive-material-grid {
        .checkbox-align-right {
            input[type="checkbox"]+label {
                justify-content: flex-end !important;
            }
        }
        .navigation-grid {
            height: 50vh;            
        }

        .active-batch-wrapper {
            display: flex;
            width: 100%;
            height: 100%;
            padding: 0 4px;
             * > {
                display: flex;           
             }             

             label {
                 justify-content: right !important;
             }
        }
    }
    .white-on-black-header {
        background-color: black;
        color: white;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        padding: 0.2em 0.5em 0.1em 0.5em;
        width: 100%;
        margin-bottom: 0px;

        .ag-header-group-cell-label {
            text-align: left;
        }
    }
    .panel-header {
        background-color: #d1d1d1;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        border-radius: 0.5em 0.5em 0 0;
    }

    .border {
        border: 1px solid rgb(97,97,97);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .border-right {
        border-right: 1px solid rgb(97,97,97);
    }

    .border-left {
        border-left: 1px solid rgb(97,97,97);
    }

    .border-top {
        border-top: 1px solid rgb(97,97,97);
    }

    .border-bottom {
        border-bottom: 1px solid rgb(97,97,97);
    }

    .bottom-padding {
        padding-bottom: 2em !important;
        vertical-align: middle;
    }

    .no-border-radius {
        border-radius: 0 !important;
    }

    .define-prices-forestry-work {
       
        .header-left {
            text-align: left !important;
        }

        .header-right {
            text-align: right !important;
        }

        .last-cell-padding {
            padding-right: 5px;
        }

        .left-cell-padding {
            padding-left: 5px;
        }

        .right-panel {
            border: 1px solid #616161;
            border-radius: 5px;
            background-color: #fff;
            padding-bottom: 140px;

            .panel-header {
                background-color: #d1d1d1;
                text-transform: uppercase;
                font-family: "Myriad Pro Semibold";
                border-radius: 0.5em 0.5em 0 0;
            }
        }

        .navigation-grid {
            height: 50vh;
            border: 1px solid rgb(97,97,97);
            border-radius: 5px;
            user-select: none !important;
        }

        .editable-text {
            textarea:focus, input:focus {
                color: black;
            }

            input, select, textarea {
                color: black;
            }
        }

        .left-align {
            text-align: left;
            font-size: 12px;
            background-color: white;
        }

        .header-generic {
            font-size: 12px;
            text-transform: none !important;
        }

        .header-parent {
            border-bottom: none;
        }

        .header-empty {
            border-top: none;
        }

        .right-border-hide {
            border-right: none;
            padding-right: 5px;
        }
    }

    .harvesting-costs {

        .white-background {
            background-color: $white;
        }

        .ag-cell {
            overflow: visible;
        }

        .ag-body-viewport {
            overflow: hidden !important;
        }

        .sp-grid .ag-root {
            border-radius: 5px !important;
            font-size: 12px;
        }

        .navigation-grid {
            height: 268px;
            border: 1px solid rgb(97,97,97);
            border-radius: 5px;
            user-select: none !important;
        }

        .right-align {
            text-align: right;
            font-size: 12px;
            background-color: white;
        }

        .editable-text {
            textarea:focus, input:focus {
                color: black;
            }

            input, select, textarea {
                color: black;
            }
        }

        .left-align {
            text-align: left;
            font-size: 12px;
            background-color: white;
        }

        .black-cell {
            background-color: rgb(239,239,239);
            text-align: left;
            font-size: 12px;
        }

        .lower-case {
            text-transform: lowercase !important;
        }

        .header-generic {
            font-size: 12px;
            text-transform: none !important;
        }

        .white-on-black-header {
            background-color: black;
            color: white;
            text-transform: uppercase;
            font-family: "Myriad Pro Semibold";
            padding: 0.5em;
            width: 100%;
            margin-bottom: 0px;
        }

        .content {
            [class^="col"] {
                padding-left: 5px;
                padding-right: 5px;
            }

            .same-row {
                padding-left: 10px;
                padding-right: 10px;
            }

            input[type=radio] {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }

        .panel-header {
            background-color: #d1d1d1;
            text-transform: uppercase;
            font-family: "Myriad Pro Semibold";
            border-radius: 0.5em 0.5em 0 0;
        }

        .border {
            border: 1px solid rgb(97,97,97);
            border-radius: 5px;
        }

        .upper-case {
            text-transform: uppercase !important;
        }
    }

    .bottom-bottons {
        margin-top: 10px;

        .btn-silva {
            padding: 10px;
            min-width: 150px;
        }
    }

    input[type='text'] {
        padding: 0.2em;
        height: 27px;
    }

    .partial {
        padding: 0px;

        .blue {
            background-color: $blue;
            color: $white;
        }

        .lime {
            background-color: $trimble_tf_product_green;
            color: $white;
        }
        /* link block */
        .link-block {
            font-family: $font-primary;
            font-size: 12px;

            .col-lg-2 {
                padding: 7.5px;
                display: inline;
            }

            .inner {
                box-shadow: 0 0 3px $half_trimble_brand_grey, 0 0 3px $half_trimble_brand_grey;
                height: 250px;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                background-color: $trimble_white;
            }

            .large-nav-button {
                cursor: pointer;
                background-color: $trimble_white;
                padding-right: 0px !important;
                padding-left: 0px !important;


                &:hover {
                    background-color: $trimble_white;
                    color: $trimble_black;

                    .topic {
                        color: $trimble_tf_product_green;
                    }

                    .icon {
                        border-color: $trimble_grey_light;

                        &-timberSales {
                            background-image: url("/content/images/svg/MHYButtons_1_SpecificationsForTimberSales_MouseOver.svg");
                        }

                        &-forestPlanning {
                            background-image: url("/content/images/svg/MHYButtons_2_ForestryPlansAndEealEstateValueAssessments_MouseOver.svg");
                        }

                        &-silviculturalWorks {
                            background-image: url("/content/images/svg/MHYButtons_4_ForestryWorkExecutorList_MouseOver.svg");
                        }

                        &-productInformation {
                            background-image: url("/content/images/svg/MHYButtons_5_ProductCatalog_MouseOver.svg");
                        }

                        &-reproductiveMaterial {
                            background-image: url("/content/images/svg/MHYButtons_6_ForestReproductiveMaterialSalesBatchList_MouseOver.svg");
                        }

                        &-serviceProviders {
                            background-image: url("/content/images/svg/MHYButtons_7_WorkExecutorList_MouseOver.svg");
                        }

                        &-forestMachines {
                            background-image: url("/content/images/svg/MHYButtons_8_Machines_MouseOver.svg");
                        }

                        &-boilerplateTexts {
                            background-image: url("/content/images/svg/MHYButtons_3_BoilerplateTexts_MouseOver.svg");
                        }

                        &-defaultAssortments {
                            background-image: url("/content/images/svg/MHYButtons_9_TimberTradeAndHarvestSpecification_MouseOver.svg");
                        }

                        &-difficultyClasses {
                            background-image: url("/content/images/svg/MHYButtons_10_WoodForceSpecification_MouseOver.png");
                        }
                    }
                }

                a:hover, a:active {
                    text-decoration: none;
                }

                .icon {
                    text-align: center;
                    border-bottom: 01px solid $trimble_grey_light;

                    div {
                        height: 50px;
                        margin: 0.5em 0;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    &-timberSales {
                        background-image: url("/content/images/svg/MHYButtons_1_SpecificationsForTimberSales.svg");
                    }

                    &-forestPlanning {
                        background-image: url("/content/images/svg/MHYButtons_2_ForestryPlansAndEealEstateValueAssessments.svg");
                    }

                    &-silviculturalWorks {
                        background-image: url("/content/images/svg/MHYButtons_4_ForestryWorkExecutorList.svg");
                    }

                    &-productInformation {
                        background-image: url("/content/images/svg/MHYButtons_5_ProductCatalog.svg");
                    }

                    &-reproductiveMaterial {
                        background-image: url("/content/images/svg/MHYButtons_6_ForestReproductiveMaterialSalesBatchList.svg");
                    }

                    &-serviceProviders {
                        background-image: url("/content/images/svg/MHYButtons_7_WorkExecutorList.svg");
                    }

                    &-forestMachines {
                        background-image: url("/content/images/svg/MHYButtons_8_Machines.svg");
                    }

                    &-boilerplateTexts {
                        background-image: url("/content/images/svg/MHYButtons_3_BoilerplateTexts.svg");
                    }

                    &-defaultAssortments {
                        background-image: url("/content/images/svg/MHYButtons_9_TimberTradeAndHarvestSpecification.svg");
                    }

                    &-difficultyClasses {
                        background-image: url("/content/images/svg/MHYButtons_10_WoodForceSpecification.png");
                    }
                }

                .topic {
                    color: $trimble_black;
                    margin: 0;
                    margin-bottom: -0.5em;
                    margin-top: 2em;
                    padding: 0;
                    font-family: $font-semibold;
                    text-transform: uppercase;
                    font-size: 12px;
                }

                .last-topic {
                    margin-bottom: 0.5em;
                    margin-top: 0em;
                }

                p {
                    text-align: center;
                    padding: 0.5em;
                    padding-left: 2em;
                    padding-right: 2em;
                }
            }
        }
    }

    @import './definingStumpagePrices-style.scss';
    @import './forestryWorkChains-style.scss';
    @import './forestryPlanSettings.scss';
    @import './timberBuyer.scss';
    @import './executors.scss';
    @import './products.scss';
    @import './regenerationCriteriaStyle.scss';
    @import './correctionSettings-style.scss';
    @import './productInformation.scss';
    @import './components/productChain.scss';
    @import './forestEquipment.scss';
    @import './forestEquipmentHandling.scss';
    @import './forestEquipmentChaining.scss';
    @import './serviceProvider/serviceProviders.scss';
    @import './serviceProvider/contract/contracts.scss';
    @import './timberTradeAndHarvestSpecifications/defaultAssortmentGroups/defaultAssortmentGroups.scss';
    @import './timberTradeAndHarvestSpecifications/defaultFactoryPrices/defaultFactoryPrices.scss';
    @import './woodforceSpecifications/feeBasisDifficultyClass/feeBasisDifficultyClass.scss';
    @import './timberTradeAndHarvestSpecifications/generalCosts/generalCosts.scss';
    @import './timberTradeAndHarvestSpecifications/generalCosts/addEditGeneralCost/addEditGeneralCostsModal.scss';
}

@import './components/productListModal.scss';
@import './components/machineAdditionalInfoModal.scss';
@import 'boilerplateTexts.scss';
@import './components/silvalogisAccountInfoModal.scss';
