﻿@import '../../../shared/sass/_variables.scss';

.basic-detail {
    .md-buttons {
        clear: both;
        padding: 0px;
        margin-left: 20px;
        margin-right: 20px;

        button {
            padding: 10px;
            margin: 20px 0px;
        }
    }

    .container-scroll {
        overflow-y: auto;
        max-height: 220px;
    }

    .display-none {
        display: none;
    }

    .height-300 {
        height: 300px !important;
    }

    .height-250 {
        height: 250px !important;
    }

    .pad-0 {
        padding: 0px;
    }

    .pad-right-0 {
        padding-right: 0px;
    }

    .margin-top-5 {
        margin-top: 5px;
    }

    .margin-top-10 {
        margin-top: 10px;
    }

    .border-left {
        border-left: 0.5px solid $half_trimble_brand_grey;
    }

    .border-bottom {
        border-bottom: 0.5px solid $half_trimble_brand_grey;
    }

    .border-top-left-radius {
        border-top-left-radius: 0.3em
    }

    .border-top-right-radius {
        border-top-right-radius: 0.3em
    }

    .border-bottom-left-radius {
        border-bottom-left-radius: 0.3em
    }

    .border-bottom-right-radius {
        border-bottom-right-radius: 0.3em
    }

    .base {
        padding: 0px 15px;
    }

    .custom-drop-down {
        button.btn.custom-button[disabled] {
            border: 1px solid #616161 !important;
            border-right: none !important;
        }

        button.dropdown-toggle[disabled] {
            border: 1px solid #616161 !important;
            border-left: none !important;
        }
    }

    .app-container-content.first-container{
        position: absolute;
        width: 93%;
    }
}
