﻿.kuutio-call-for-offer {
    .contract-created-info {
        text-align: left;
    }

    .bottom-block {
        height: 10px;
    }

    .readonly-textfield {
        pointer-events: none;
    }

    .offer-items {
        margin-top: 23px;
        margin-left: 10px;
    }

    .bottom-offer-items {
        margin-left: 15px !important;
        margin-bottom: 15px !important;
    }

    .last-offer-item {
        margin-bottom: 23px !important;
    }


    .work-site-customer-notes {
        height: 210px;
        overflow-y: auto;
        resize: none;
    }

    .checkbox-label {
        line-height: 12px !important;
        vertical-align: bottom;
        margin-left: 15px;
        margin-top: -1px;
    }

    .checkbox-input {
        margin-top: 5px;
    }

    padding: 0px;
    font-family: "Myriad Pro", Verdana;
    font-size: 12px;
    width: 100%;

    .form-control {
        font-size: 12px;
        // &:disabled {         //are we showing the disabled fields as usual or not
        //     background-color: initial;
        // }
    }

    .partial {
        padding: 0;
        margin: 2px;
        border: 1px solid #616161;
        border-radius: 0.5em;
        background-color: #fff;
        overflow: hidden;
    }

    .partial-header {
        background-color: #d1d1d1;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        padding: 0.5em;
        border-radius: 0.5em 0.5em 0 0;
    }

    .white-on-black-header {
        background-color: black;
        color: white;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        padding: 0.5em;
        width: 100%;
        margin-bottom: 0px;
    }

    button.inline {
        display: inline !important;
        width: 14px;
        padding-top: 5px;
        padding-bottom: 0px;
        padding-right: 24px;
    }

    select.inline {
        display: inline !important;
        width: 100%;
    }

    .checkbox-wrapper {
        float: left;

        label {
            padding-left: 10px;
        }
    }

    label.alingWithCheckbox {
        top: 8px;
        padding-left: 25px;
    }

    .textarea-with-label {
        padding: 5px 5px 5px 5px;

        textarea {
            margin-top: 0px;
            margin-right: 10px;
        }
    }

    .aling-with-checkbox {
        padding-left: 40px;
    }

    .panel-parent {

        .panel-element {
            margin-top: 5px;
            margin-bottom: 5px;

            .form-group {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
    }

    .form-group {
        margin-left: 5px;
        margin-right: 5px;
    }

    .partial-column {
        padding-left: 0px;
        padding-right: 0px;
    }

    .right-column {
        border-left: 1px solid gray;
        height: 456px;
    }

    .align-left {
        padding-left: 0px;
    }

    .sp-grid {
        height: 226px;

        .ag-root {
            border-radius: 0 !important;
            font-size: 12px;
        }

        .grid-check-box {
            top: -9px;
        }
    }

    .date-display {
        background-color: rgb(209, 209, 209);
        padding: 5px;
        border-radius: 5px;
        text-align: center;
    }

    .replacing-checkbox {
        padding-left: 0px !important;
        cursor: pointer;
        height: 0px;
        overflow: visible;
        pointer-events: none;
        background: none !important;
    }

    .property-contract-bottom-buttons {
        margin-top: 15px;

        button {
            border: 1px solid transparent;
            border-radius: 4px;
            padding: 10px 60px;
        }
    }
}
