.fee-basis-difficulty-class {
    $header-color: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;
    font-size: 14px;

    .fee-basis-difficulty-class-panel {
        border-radius: 0.5em 0.5em 0.5em 0.5em;
        border: 1px solid #616161;

        .row.buttons-group {
            height: 48px;
            background-color: #e8e8e8;
            border-radius: 0 0 0.5em 0.5em;
        }
    }
    
    .selected-definition, .view-option-select, .dropdown-menu {
        width: 100%;
    }

    .btn.btn-default.dropdown-toggle {
        width: 100%;
        height: 30px;
        padding: 3px 6px;
        border: 1px solid $half_trimble_brand_grey;

        @media screen and (max-width: 1300px) {
            border: 1px solid $half_trimble_brand_grey;
        }
    }

    .select-option-label {
        font-size: 12px;
        font-weight: 600;
        height: 28px;
        padding-top: 6px;
        font-family: "Myriad Pro Semibold";
    }

    @media screen and (max-width: 1600px) {
        .select-option-label {
            padding-top: 0;
            margin-top: -2px;
            width: 99px;
            margin-right: 22px; 
        }
    }

    @media screen and (max-width: 1300px) {
        .select-option-label {
            padding-top: 0;
            margin-top: -2px;
            margin-top: -2px;
            margin-right: 0px;
        }
    }

    .search-section {
        margin-bottom: 15px;
        margin-left: 13px;

        .silva-search {
            height: 30px;
            border: 1px solid $half_trimble_brand_grey;
            border-radius: 4px;

            @media screen and (max-width: 1300px) {
                border: 1px solid  $half_trimble_brand_grey;
                margin-left: 15px;
            }   
        }
    }

    .panel-header.grid-header-styling {
        border-bottom: 1px solid #808080;
        padding: 4px;
        height: 25px;
        font-family: "Myriad Pro", Verdana;
        font-weight: 700;
        color: black;
        background: #e2e2e2;

        &.modal-header {
            border-radius: 3px 3px 0px 0px;
            border: 1px solid $half_trimble_brand_grey;
        }
    }

    .navigation-section {
        height: 32px;
        margin-bottom: 13px;

        .dropdown-menu {
            margin-top: 0px;
            position: sticky;

            @media screen and (max-width: 1300px) {
                width: 218px;
            }
        }
    }

    #feeBasisDifficultyClassGrid {
        height: 626px;

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 0;
        }

        [type="checkbox"]:not(:checked)+label:before,
        [type="checkbox"]:checked+label:before {
            top: 0;
        }
    
        [type="checkbox"]:checked+label:after {
            top: 0px;
        }

        .ag-floating-bottom-container .ag-row .ag-cell {
            background-color: #d1d1d1;
        }

        .ag-floating-bottom {
            border-top: 1px solid #616161;
            border-bottom: 1px solid #616161;
        }
    }

    .select-option-dropDown {
        margin-right: 2px;
    }
}