﻿@import '../../../../../shared/sass/_variables.scss';

.accounting-assortment {
    height: 570px;

    .align-center {
        display: flex;
        align-items: center;
    }

    .master-header {
        @extend .align-center;
        padding-left: 2px !important;
    } 

    // .ag-header-container {
    //     .ag-header-cell {
    //         @extend .align-center;
    //     }
    // }

    .margin-5 {
        margin: 5px -10px;
    }

    .partial-header {
        @extend %silva-header;
    }

    .unit-rate-grid {
        height: 530px;
    }

    .assortment-info-label {
        margin-top: 21px;
        border-radius: 0 0 0.5em 0.5em;
        margin-left: -14px;
        margin-right: -14px;
        text-transform: none;
        letter-spacing: 0px;
        color: #363545;
        opacity: 1;
    }

    // .ag-theme-fresh .ag-header-cell-label {
    //     padding: 6px 2px;
    // }

    // .ag-header-group-cell-label {
    //     text-transform: uppercase;
    //     text-align: left;
    //     padding-left: 5px !important;
    // }

    // .ag-cell.disabled {
    //     background-color: inherit !important;
    // }

    // .ag-cell.disabled.ag-cell {
    //     border-bottom: none !important;
    // }

    .form-group {
        padding: 0em;
    }

    .modal-grid {
        border: 1px solid $darkgray;
        border-radius: 4px;

        #centerRow {
            border-radius: 0.5em;
        }

        .shared-header {
            width: 100% !important;
            border-right: 0;
        }

        .no-border-bottom {
            border-bottom: 0;
        }

        .noborder-right {
            border-right: 0;
        }
    }

    // .ag-cell-label-container {
    //     padding-left: 3px;
    // }

    // .ag-floating-bottom-container {

    //     .ag-row {
    //         background-color: $gray !important;
    //     }

    //     .ag-cell {
    //         background-color: transparent !important; // override disabled-cell color
    //         border-right: none;
    //         color: black !important;
    //     }

    //     .ag-cell:first-child {
    //         padding-left: 0.5em;
    //         width: 200px !important; // for the first cell with "total rows"
    //     }
    // }
    // disable mouse hover effect
    // .ag-body-container {
    //     .ag-row:hover {
    //         .ag-cell {
    //             background-color: $lightgray !important;
    //             color: black !important;

    //             input {
    //                 &[disabled] {
    //                     background-color: $disabled-background-color;
    //                     color: black;
    //                 }
    //             }
    //         }
    //     }
    // }
}
