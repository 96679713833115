@import '../../../../../../shared/sass/variables';
@import '../../../../../../shared/sass/mixins';

.change-status-modal {
    .modal-dialog {
        width: 295px;
    }

    .text-container {
        background-color: $gray;
        padding: 10px 15px;
    }

    .content {
        &-title {
            @extend .text-container;
            text-transform: uppercase;
        }

        &-description {
            @extend .text-container;
            font-style: italic;
        }

        &-options {
            @include flex(column, center, normal);
            padding: 15px;

            button {
                margin-bottom: 10px;
                text-transform: uppercase;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .modal-trimble-button-container {
        @include flex(column, center, center);

        .cancel-btn {
            width: 120px;
        }
    }

}