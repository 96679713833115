﻿@import '../../../../shared/sass/variables';

.timber-trade {
    $font-family: "Myriad Pro", Verdana;
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: $font-family;
    font-size: 12px;
    padding-right: 15px;
    margin-top: 5px;

    .hand-cursor{
        cursor: pointer;
        text-decoration: underline;
    }

    .container-row {
        display: flex;
        margin-bottom: 1em;

        .left-panel, .right-panel {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .left-panel {
        width: 50%;
    }

    .right-panel {
        width: 50%;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .panel-header {
        background: #d3d3d3;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
    }

    .rounded-header {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .header-padding {
        padding-left: 5px;
        padding-top: 4px;
        padding-bottom: 3px;
        margin-bottom: 0px;
    }

    .border {
        border: 1px solid rgb(97,97,97);
        border-radius: 5px;
        height: 745px;    
        background-color: $white;
    }

    .tarjousten-format {
        border-radius: 4px;
        width: 130px;
        font-size: 12px;
    }

    .height-s {
        height: 27px !important;
        width: 100%;
    }

    .fine-border {
        border: 1px solid #ccc;
    }

    .align-right {
        padding-left: 6px !important;
    }

    .padding-top-xs {
        padding-top: 4px !important;
        padding-left: 2px !important;
    }

    .label-padding-top {
        margin-bottom: 0px;
    }

    .label-padding-left {
        padding-left: 5px;
        padding-right: 0px;
        padding-top: 6px;
    }

    .no-padding-right {
        padding-right: 0px;
    }

    .no-padding-left {
        padding-left: 0px;
    }

    .settings-item {
        height: 27px;
        margin-top: -4px;
    }

    .blue-background {
        background-color: $darkblue;
    }

    .form-control {
        padding: 0px;
    }

    .row-height {
        height: 27px;
    }

    .cuttings-table {
        width: 100%;

        tr {
            height: 27px;
        }

        tr > td:first-child, th:first-child {
            padding-left: 5px;
        }

        tr > td, th {
            width: 25%;

            input {
                width: 100%;
                height: 100%;
                border: none;
                text-align: right;
            }

            input:focus {
                border: none;
            }
        }
    }

    .white-on-black-header {
        background-color: $black;
        color: $white;
        text-transform: none;
        font-family: "Myriad Pro Semibold";
        padding: 0.5em;
        margin-bottom: 0px;
    }

    .white-on-black-header-grid {
        background-color: $black;
        color: $white;
        text-transform: none;
        font-family: "Myriad Pro Semibold";
    }

    .grey-bg {
        background-color: $gray;
    }

    .white-bg {
        background-color: $white;
    }

    .padding-top {
        padding-top: 5px;
    }


    .column-right {
        text-align: right !important;
        padding-right: 5px;
    }

    .no-padding {
        padding-left: 0px;
    }

    .radio-group-row {
        height: 2.4em;
        padding-top: 0.6em;
    }

    .radio-inline {
        margin-top: -3px;
    }

    .grid-input {
        text-align: right !important;
        border: 0;
        width: 100%;
        padding-right: 5px;
    }

    .align-right {
        text-align: right !important;
        text-transform: none !important;
    }

    .full-height {
        height: 100%;
    }

    .editable-text {
        textarea:focus, input:focus {
            color: $black;
        }

        input, select, textarea {
            color: $black;
        }
    }

    label {
        margin-bottom: 0px;
    }

    .label-14 {
        font-size: 14px;
    }

    .cell-padding {
        padding-top: 4px;
        padding-bottom: 2px;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
    }

    .padding-lr-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .customers-tablefoot-actionbar-timber {
        border-radius: 0.5em;
        overflow: hidden;
        margin: 0;
        background-color: $lightgray;
        padding: 0.5em 1em;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: 1px solid $darkgray;

        button {
            margin-right: 1em;

            &.no-margin {
                margin-right: 0;
            }
        }
    }

    .button-margin-right, .customers-tablefoot-actionbar button {
        margin-right: 0.5em;
    }

    .select-yes-no {
        option:first-child {
            display: none;
        }
    }

    .sp-grid {
        font-family: inherit;
        height: 552px;;

        #centerRow {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }

        .ag-floating-bottom-container {
            .ag-row {
               // background: $grid-header-background;
               background: $gray;
            }

            .ag-cell:nth-child(2) {
                margin-left: -20px;
            }

            .ag-cell {
                border: none !important;
                background: $gray;
            }
        }

        .ag-floating-bottom-viewport {
            background-color: $gray;
        }

        .ag-row-odd {
            background-color: $white !important;
        }

        .ag-root {
            border-radius: 0 0 0 0 !important;
        }

        .ag-cell {
            font-size: 12px;
            padding-top: 4px;
        }

        .ag-header-cell {
            text-transform: none;
        }

        .ag-bl-center-row {
            width: 570px;
        }
    }

    .only-top-border {
        border: 0;
        border-top: 1px solid;
    }

    .laatu-selection-box {
        height: 23px;
        padding: 2px;
    }

    .padding-laatu-selection-box {
        padding: 2px;
    }

    .icon {
        height: 17px;
        width: 18px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;

        &.icon-printer {
            background-image: url(../../content/images/svg/print.svg);

            &:hover,
            &:focus {
                background-image: url(../../content/images/svg/print-green.svg);
            }
        }
    }

    .print-div {
        padding-left: 23px;

        .btn {
            background-color: $white !important;
        }
    }

    .cost-input {
        width: 100%;
        border: none;
        text-align: right;
    }

    .label-cost-input {
        font-weight: inherit;
    }

    .hide-right-border {
        border-right-style: hidden;
    }

    .hide-left-border {
        border-left-style: hidden;
    }

    .right-border-radius {
        border-radius: 0 0 4px 0;
    }
}
