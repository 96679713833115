.file-upload-directive {
	border: 0!important;
	position: relative;

	.form-group {
		margin: 0;
		padding: 0;
		border: 0!important;

		div {
			border: 0!important;
			padding-right: 0.5em!important;
		}

		div:last-child {
			padding-right: 0!important;
		}
	}

	input[type=file] {
		position: absolute;
		opacity: 0;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		cursor: inherit;
		z-index: 2;
		max-width: 100%;
		display:none;
	}

	.input-group {
		button {
			z-index: 1;
		}
	}

	input.form-control {
		width: 100%!important;
		height: 28px!important;
	}

	.input-group-btn button {
		background-color: $white;
		border: 1px solid #616161;
		width: 28px!important;
		padding: 6px 0;
	}

	button {
		height: 28px!important;
		line-height: 14px;
		width: 100%;
	}

	label {
		position: relative;
		width: 100%;
		margin-bottom: 0;
	}
}
