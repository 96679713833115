
.loader-container {
	position: fixed;
	width: 200px;
	height: 200px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	z-index: 10000;
	display: none;
	
	&:not(:empty) {
		animation: fade-in 200ms linear;
		display: initial;	
	}
}
