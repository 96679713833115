@import '../../../../shared/sass/variables';

.harvest-area-planning {
    .harvest-planning-grid {
        .sp-grid {
            height: 454px !important;
        }
    }

    #harvestAreaPlanningGrid {
        border-radius: 0.5em 0.5em 0 0;
        border: 1px solid $darkgray;
        overflow: hidden;

        .select-all-wrapper {
            left: 2px;
            top: -5px;
        }

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 0.5em;
        }

        .versioning-information-header {
            background-size: 6px;
            background-image: url(../content/images/svg/CriticalNotificationSymbol_Black.svg);
            background-repeat: no-repeat;
            margin-top: 4px;
            margin-left: 7px;
            height: 22px;
        }

        .versioning-information-col {
            background-size: 6px;
            background-image: url(../content/images/svg/CriticalNotificationSymbol_Orange.svg);
            height: 22px;
            margin-left: 5px;
            background-repeat: no-repeat;
        }
    }

    .icon-button {
        //Override bootstrap's styles on icon-buttons
        > .btn {
            &:hover,
            &:focus {
                background-color: $trimble_white;

                .icon-settings {
                    background-image: url(../../content/images/svg/settings-cog-green.svg);
                }
            }

            background-color: $trimble_white;
        }

        &.open {
            .icon-printer {
                background-image: url(../../content/images/svg/print-green.svg);
            }
        }
    }

    .table-settings-button {
        margin-bottom: 12px;
    }

    .icon {
        height: 17px;
        width: 18px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;

        &.icon-settings {
            width: 25px;
            //height: 16px;
            background-image: url(../../content/images/svg/settings-cog.svg);

            &:hover,
            &:focus {
                background-image: url(../../content/images/svg/settings-cog-green.svg);
            }
        }
    }

    .button-icon {
        background-color: $trimble_blue_light;
        background-image: url(/content/images/svg/OutputListIcon_white.svg);
        background-size: 14px 19px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 19px;
        width: 20px;
        background-repeat: no-repeat;
        background-position: right 3px center;

        &.disabled {
            opacity: 0.5;
        }

        &:hover:not(.black) {
            background-color: $trimble_blue_mid;
            color: $trimble_white;
        }
    }

    .icon-header {
        height: 20px;
    }

    .buttons-group {
        background-color: $lightgray;
        border-radius: 0 0 0.5em 0.5em;
        border: 1px solid $darkgray;
        border-top: none;
        padding: 0.5em;
    }

    .ag-floating-bottom-viewport {
        border-top: 1px solid #616161;

        .ag-row {
            background-color: $gray !important;
        }
    }

    .grid-edit-modal {
        width: calc(100% - 20%);
        left: 10%;

        .header-row {
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
        }

        .body-row {
            height: 100%;
        }

        .sp-grid .ag-root-wrapper .ag-root-wrapper-body .ag-root .ag-header .ag-header-row .ag-header-cell {
            border-color: darkgray;
        }

        .ag-theme-fresh .ag-tool-panel-wrapper .ag-column-select-panel {
            border-bottom: none;
        }

        .the-header {
            width: 100%;
            padding: 0 !important;
            border: 1px solid $darkgray;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            .header-column {
                text-align: center;
                border-right: $grid-border !important;

                label {
                    padding-top: 0px !important;
                }
            }

            .header-column:last-child {
                border-right: none !important;
            }
        }


        .the-body {
            width: 100%;
            padding: 0 !important;
            border-radius: 0 0 3px 3px;
            border: 1px solid $darkgray;
            border-top: none;

            .body-column {
                border-right: 1px solid $gray;
                padding: 5px;

                .simpleDropDown,
                .grid-input {
                    border: 1px solid #707070;
                    border-radius: 2px;
                }

                &.no-border-rigth {
                    border-right: 0;
                }

                .edit-down-options {
                    .btn.dropdown-toggle {
                        width: 10%;
                        padding-left: 0px;
                        margin-left: 0px;
                        height: 25px;
                        border-radius: 0;
                    }

                    .btn.custom-button {
                        text-align: left;
                        margin-right: 0;
                        height: 25px;
                        border-radius: 0;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;

                        span {
                            width: 92px;
                            display: block;
                            margin-right: 0 !important;

                            ng-container {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                width: 200px;
                                display: block;
                            }
                        }
                    }

                    .drop-down-table {
                        padding-left: 5px;
                    }
                }

                .dropdown-menu{
                    width: auto !important;
                }
            }
        }

        .border-bottom {
            padding: 15px;
            border-bottom: none;

            .width-19 {
                width: 12%;
            }

            .width-15 {
                width: 10%;
            }

            .width-10 {
                width: 5%;
            }

            .no-delivery-contracts-width-25 {
                width: 25%;
            }
        }
    }
}
