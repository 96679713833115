﻿.plot-view {
    $plot-line-height: 27px;

    .top-section {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        height: 30px;

        .dropDownMinWidth250px {
            margin-left: -30px !important;
            width: 260px !important;
            margin-right: 6px !important;
            margin-top: -2px;
            background-color: $white;

            .dropdown-menu {
                width: 500px !important;
                margin-top: 0px;

                .drop-down-table {
                    overflow-y: auto !important;
                    height: 150px;
                }
            }

            .custom-button {
                text-align: left;
            }

            .btn {
                height: $plot-line-height;
                padding-top: 3px;
                padding-left: 6px;
            }

            .table-cell {
                padding-top: 4px !important;
            }

            .first-column {
                width: 12%;
                padding-left: 6px !important;
            }

            .second-column {
                width: 56%;
            }

            .third-column {
                width: 32%;
                padding-right: 6px !important;
            }

            .background-color {
                background-color: #d3d3d3;
            }
        }

        .green {
            color: #008752;
            font-weight: 600;
            flex-grow: 1;
        }

        .left-position {
            left: -8px;
        }

        .underlined {
            text-decoration: underline !important;
            cursor: pointer !important;
        }

        .details-padding {
            padding-left: 10px;
        }

        .no-padding {
            padding-left: 0px;
            padding-right: 0px;
        }

        .padding-left-s {
            padding-left: 6px;
            padding-right: 0px;
        }

        .plot-input {
            height: $plot-line-height;
            width: 180px;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .left-arrows-padding {
            padding-right: 0px;
            padding-left: 0px;
            top: 1px;
        }

        .right-arrows-padding {
            padding-right: 0px;
            padding-left: 0px;
            top: 1px;
        }

        .browser-topic {
            margin-right: 1em;
            text-transform: uppercase;
            font-weight: 600;
            flex-grow: 1;
        }

        .browsing-button {
            height: 1.5em;
            width: 1.5em;
            background-position: center bottom;
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 0.5em;

            &:hover {
                cursor: pointer;
            }

            &.disabled {
                opacity: 0.4;
                pointer-events: none;
            }

            &:last-child {
                margin-right: 0;
            }

            &.step-to-first {
                background-image: url('../../../../../content/images/svg/Step-to-first.svg');

                &:hover {
                    background-image: url('../../../../../content/images/svg/Step-to-first-MouseOver.svg');
                }
            }

            &.step-to-last {
                background-image: url('../../../../../content/images/svg/Step-to-last.svg');

                &:hover {
                    background-image: url('../../../../../content/images/svg/Step-to-last-MouseOver.svg');
                }
            }

            &.step-forward {
                background-image: url('../../../../../content/images/svg/Step-forward.svg');

                &:hover {
                    background-image: url('../../../../../content/images/svg/Step-forward-MouseOver.svg');
                }
            }

            &.step-backward {
                background-image: url('../../../../../content/images/svg/Step-backward.svg');

                &:hover {
                    background-image: url('../../../../../content/images/svg/Step-backward-MouseOver.svg');
                }
            }
        }

        .selected-plot {
            display: flex;
            width: 13em;
            border: 1px solid $darkgray;
            border-radius: 4px;
            align-items: center;
            margin-right: 0.5em;
            margin-bottom: 3px;

            > div {
                display: inline-block;
                padding: 0.2em;

                .glyphicon-play {
                    transform: rotate(90deg);
                }
            }

            .down-arrow {
                font-size: 50%;
                color: $darkgray;
                margin-right: 0.5em;
            }

            .plot {
                flex-grow: 1;
            }
        }
    }

    .middle-section {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 12px;

        .settings-item {
            margin-bottom: -3px;

            .font {
                font-weight: 100;
            }
        }

        .pull-up {
            margin-top: -3px;
        }

        .plot-input {
            width: 100%;
            height: $plot-line-height;
            padding-left: 6px;
        }

        .dd-less-padding {
            padding-bottom: 4px;
            padding-top: 4px;
            padding-left: 2px;
        }

        .plot-input-small {
            width: 47%;
            height: $plot-line-height;
            padding-left: 6px;
        }

        .padding-m {
            padding-left: 15px;
            padding-right: 15px;
        }

        .date-padding {
            padding-right: 2px;
        }

        .wrapper {
            border: 1px solid $darkgray;
            border-top: none;
            border-radius: 0 0 0.5em 0.5em;
            display: table;
            width: 100%;
            background-color: $white;

            span {
                line-height: $plot-line-height;
            }
        }

        .left-content {            
            padding-top: 15px;
            height: 100%;

            .bottom-space {
                padding-bottom: 15px;

                .dash-mark {
                    padding-left: 7px;
                    padding-top: 3px;
                }
            }

            .span {
                padding-top: 5px;
            }

            .plot-resoruces {
                border-radius: 4px;
                height: 110px;
                overflow-y: auto;
                padding: 4px;

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        list-style-type: none;
                    }
                }
            }
        }

        .right-content {
            border-left: 1px solid $darkgray;
            padding-top: 8px;
            padding-bottom: 10px;

            .bottom-space {
                padding-bottom: 19px;

                .height {
                    min-height: 125px;
                    padding-left: 6px;
                    resize: vertical;
                    margin-top: 0px;
                }
            }

            .top-space {
                margin-top: 20px;
            }

            .dropDownMinWidth350px {                
                width: 101%;               

                .dropdown-menu {
                    width: 350px;
                    margin-top: 0px;
                    z-index: 1000;
                }

                .custom-button {
                    text-align: left;
                }

                .btn {
                    height: $plot-line-height;
                    padding-top: 3px;
                    padding-left: 6px;
                }

                .table-cell {
                    padding-top: 4px !important;
                }

                .first-column {
                    width: 25%;
                    padding-left: 6px !important;
                }

                .second-column {
                    width: 60%;
                }

                .background-color {
                    background-color: #d3d3d3;
                }
            }

            .item-image {
                padding-left: 3px;
            }

            .margin-top-more {
                margin-top: 10px;
            }
        }
    }

    .panel-header {
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        border-radius: 0.5em 0.5em 0 0;
        border: 1px solid $darkgray;
        background-color: #D1D1D1;
        padding-left: 5px;
        height: 30px;
        line-height: 30px;
    }

    .bottom-section {

        .body {
            border-right: 1px solid $darkgray;
            border-left: 1px solid $darkgray;
            border-bottom: 1px solid $darkgray;
            border-radius: 0 0 0.5em 0.5em;
            margin-bottom: 15px;
            padding-top: 10px;
            padding-left: 15px;
            padding-right: 15px;
            background-color: #ffffff;
        }

        .upper-part {
            margin-left: -8px;
            margin-bottom: 10px;

            .silva-search {
                margin-left: 7px;
            }

            .search {
                border: none !important;
            }

            .glyphicon-remove {
                margin-top: 4px;
            }
        }

        .grid {
            .grid-border {
                border-left: 1px solid $darkgray;
                border-right: 1px solid $darkgray;
                border-top: 1px solid $darkgray;
                border-top-right-radius: 0.5em;
                border-top-left-radius: 0.5em;

                .grid-header-checkbox-label {
                    margin: 0;
                    left: -4px;
                    top: 2px;
                }

                .ag-floating-bottom-container {
                    font-size: 12px;
                    font-weight: bold;
                }

                .ag-cell {
                    [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                        top: 5px !important;
                    }

                    [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                        top: 2px !important;
                    }
                }                
            }
        }
    }

    .buttons-section {
        margin-bottom: 14px;

        .footer-section {
            height: 50px;
            margin: 0px;
            border: 1px solid $darkgray;
            border-radius: 0.5em;
            background-color: #e8e8e8;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            overflow: hidden;
            padding: 0.5em 0.5em;
        }
        
        .medium {
            width: 170px;
        }

        .large {
            width: 16%;
            height: 38px;
            margin-bottom: 14px;
        }

        .xlarge {
            width: 30%;
            height: 38px;
        }

        .spacing-grid-buttons {
            margin-left: 1%;
        }

        .spacing-bottom-buttons {
            margin-left: 1%;
        }

        .btn {
            padding-left: 1%;
            padding-right: 1%;            
        }
    }

    .text-overflow-ellipsis {
        text-overflow: ellipsis;
    }

    .date-format {
		width: 65px;
	}
}
