﻿@import '../../../../shared/sass/_variables.scss';

.timber-sale-plan-divide-modal {

    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .execute-button {
        margin-left: 5px;
    }

    .invoice-modal {
        .close-div {
            padding-left: 92% !important;
        }
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }

        .close-div {
            position: absolute;
        }
    }

    .div-textarea {
        .bill-info-input {
            resize: none;
        }
    }

    .current-wood-assortment-row {
        background-color: #d3d3d3;
        margin: 0;
        font-family: "Myriad Pro Semibold";

        .col-xs-12 {
            > div {
                height: 3em;
                padding-top: 0.9em;
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
        }
    }

    .grid-container {
        margin: 1em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            height: 350px;

            #centerRow {
                border-radius: 0.5em !important;
            }

            .inactive_cell {
                background-color: $lightgray;
            }

            .active_cell {
                background-color: white;
            }

            .align-right {
                text-align: right !important;
                text-transform: none !important;
            }

            .align-left {
                text-align: left !important;
            }
        }

        .ag-header-group-cell-label {
            text-align: inherit !important;
            padding-right: 0px !important;
        }

        .ag-cell {
            border-right: 1px solid darkgray;
        }

        .grid-input {
            text-align: right !important;
            border: 0;
            width: 100%;
            height: 100%;
            padding-right: 7px;
        }

        .ag-row:hover .ag-cell {
            color: white !important;
            background-color: black !important;
        }

        .ag-floating-bottom {
            box-shadow: none !important;
            border-top: 1px solid $darkgray;
        }

        .ag-floating-bottom-full-width-container {
            border-top: 1px solid $black;
        }

        .ag-floating-bottom-viewport {

            .ag-floating-bottom-container {
                width: 100% !important;

                .ag-row {
                    background-color: #d3d3d3;
                }

                .ag-row:hover .ag-cell {
                    color: black !important;
                    background-color: #d3d3d3 !important;
                }

                :first-child .ag-cell {
                    overflow: visible;
                }

                .ag-cell {
                    border: none !important;
                    padding-right: 9px;
                    padding-top: 4px;
                    background-color: #d3d3d3 !important;
                }
            }
        }

        .no-bottom-border {
            border-bottom: none;
        }

        .no-top-padding {
            .ag-header-cell-label {
                padding-top: 0;
                padding-right: 5px;
            }
        }

        .padding-left {
            .ag-header-group-cell-label {
                padding-left: 4px !important;
            }
        }
    }

    .form-group {
        padding: 0;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }


    .ag-header-container {
        border-right: none;
        background: linear-gradient(rgba(0,0,0,0.075), #d3d3d3);
    }

    .modal-dialog {
        position: relative;
        width: 80% !important;
    }

    .editable-text {
        textarea:focus, input:focus {
            color: black;
            background-color: white;
        }

        input, select, textarea {
            color: black;
            background-color: white;
        }
    }
}
