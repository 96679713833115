.chart-legend-component {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 20px;
    width: 100%;

    .chart-legend {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0;
        padding: 10px;
    
        .legend-text {   
            display: flex;    
            justify-content: center;
            align-items: center;
            width: 100%;
            margin:0;
            padding: 0 5px;    
            font-family: Verdana;
            font-size: 10px;            
        }
    }    
}