﻿@import '../../../../shared/sass/_variables.scss';

$paymentDeductionTextsSelectorGridHeight: 535px;
$panel-margin: 3px;
$shadow-color: #00000029;

.payment-deduction {
    font-family: "Myriad Pro", Verdana;
    font-size: 12px;
    margin-bottom: 30px;
    margin-top: 2px;

    .multiselection-drop-down .checkboxes .checkboxesList {
        width: 230px;
    }

    .ag-theme-fresh .ag-ltr .ag-header-group-cell {
        border-right: 0px;
    }
    /* .ag-theme-fresh .ag-cell-focus {
        border: 0px;
    }*/

    .container-padding {
        padding-left: 0px;
    }

    .specification-option {
        width: 290px;
    }

    .payment-border-radius {
        border-radius: 4px;
    }

    .deducted-from-border {
        border: 1px solid #d1d1d1;
    }

    .height-s {
        height: 30px;
    }

    .control-label {
        padding-top: 5px;
    }

    .width-full {
        width: 100%;
    }

    .margin-left-5 .ag-header-cell-text {
        margin-left: auto;
        margin-right: auto;
    }

    .selection-section {
        display: flex;
        margin-bottom: 15px;

        .material-switch-container {
            margin-left: 5px;
        }

        .material-switch-label {
            top: -7px;
        }

        .material-switch-content {
            > input[type="checkbox"]:checked + label::after {
                top: 4px;
            }

            > input[type="checkbox"]:not(:checked) + label::after {
                top: 3px;
            }
        }

        .material-switch-container {
            width: 50px;
            float: left;
            padding-top: 5px;
        }

        .active-text {
            /*width: 8%;*/
            margin-right: 10px;
            margin-left: 15px;
            margin-top: 5px;
        }

        .status-text {
            width: 8%;
            margin-left: 5px;
            margin-top: 5px;
        }
    }

    .ag-header-group-cell-label {
        text-align: left;
        padding: 6px !important;
        padding-left: 5px !important;
        text-align: left !important;
        left: 8px !important;
    }
    /* .ag-header-cell {
        padding: 2px !important;
        padding-left: 0px !important;
    }*/

    .deduction-container-content {
        width: 99.7%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $shadow-color;
        border: 0.75px solid #B0AFB0;
        border-radius: 7px;
        /*button.btn-upload {
            border: none !important;
        }*/
        .row {
            margin: 0px;
        }

        .titleBoxText {
            width: calc(100% - 50px);
        }

        .selection-grid-footer {
            padding: 5px;
            background: #E2E2E7 0% 0% no-repeat padding-box;
            width: 100%;
            font-style: italic;
            height: 28px;
            color: #767876;
        }

        .padding-top-10 {
            padding-top: 10px;
        }

        .deduction-header {
            height: 60px;
            /*padding: 6px 0px 8px 6px;
            background: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;*/
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
            text-transform: uppercase;
            font-weight: 700;
            border-bottom: 1px solid gray;

            .deduction-sub-header {
                border-bottom: 1px solid gray;
                /* padding-left: 0px; */
                height: 30px;
                padding-top: 6px;
                padding-left: 6px;
            }
        }

        .border-top-right-left-radius {
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
        }

        .deduction-header-black {
            height: 30px;
            padding: 6px 0px 8px 6px;
            text-transform: uppercase;
            background: #000000 0% 0% no-repeat padding-box;
            color: #FFFFFF;
            font-weight: 700;
        }

        .deduction-custom-width-label {
            width: 22%;
        }

        .deduction-custom-width-control {
            width: 78%;
        }

        .fields-content {
            padding: 30px;
            display: inline-block;
            width: 100%;
        }

        .deduction-input-field {
            height: 30px;
            border: 0.5px solid #B0AFB0;
            /*background: #FFFFFF 0% 0% no-repeat padding-box;*/
            width: 100%;
            border-radius: 5px;

            :disabled {
                background-color: #eee;
            }
        }

        select.deduction-input-field[disabled] {
            background-color: #eee;
        }

        .browse-button {
            height: 30px;
            margin-left: 5px;
            width: 20%;
        }

        .browse-input-field {
            height: 30px;
            border: 0.5px solid #B0AFB0;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 5px;
        }

        .vertical-line {
            border-left: 0.5px solid #B0AFB0;
            height: 60px;
            position: absolute;
            left: 10%;
            margin-left: -3px;
            top: 0;
        }

        .deduction-vertical-line {
            border-left: 0.5px solid #B0AFB0;
            height: 30px;
            position: absolute;
            left: 10%;
            margin-left: -3px;
            top: 0;
            padding-left: 30px;
        }

        .check-box-group {
            left: 50px;
            top: 0px;
        }

        .check-box-label {
            padding-top: 8px;
        }

        .fill-textarea {
            height: 155px;
        }

        .text-textarea {
            height: 100% !important;
            width: 100%;
            margin: 0px;
            border: 0.75px solid #B0AFB0;
        }

        .deduction-footer {
            border-radius: 3px 3px 0px 0px;
            background: #F3F3F7 0% 0% no-repeat padding-box;
            height: 64px;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px
        }

        .deduction-button {
            margin: 15px;
        }

        .multiselection-drop-down {
            line-height: 30px;
            width: 100%;
            border-radius: 0px;
            height: 30px;

            .titleBox {
                width: 100%;
                border-radius: 5px;
                height: 30px;
            }

            .checkboxes {
                width: 95%;
                border-radius: 0px;
                z-index: 3;

                .select-all-label {
                    line-height: 30px;
                }

                .checkboxesList .checkboxLabel {
                    line-height: 35px;
                }
            }

            .multiselectionSearch {
                height: 30px;
                margin-bottom: 5px;

                .multiselectionSearchInput {
                    width: 95%;
                }
            }
        }

        .sp-grid {
            height: $paymentDeductionTextsSelectorGridHeight;
        }
    }

    .width-100{
        width: 100%;
    }
}
