﻿.edit-payment-setting-modal {
    .modal-dialog {
        top: 15%;
        width: 800px;
    }

    .margin-15 {
        margin: 15px;
    }

    .pt-10 {
        padding-top: 5px;
    }

    .active-text {
        margin-top: 7px;
    }

    .margin-top-8 {
        margin-top: 8px;
    }
}
