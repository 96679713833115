﻿@import '../../shared/sass/_variables.scss';

.text-edit-modal-window {

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .modal-dialog {
        width: 400px;
    }

    .btn-silva {
        width: 178px;
    }

    .product-info-texttitle {
        font-family: $font-semibold;
    }

    .product-info-textarea {
        height: 90px;
        width: 370px;
        resize: none;
    }

    .form-group {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;

        .padded-container {
            padding: 0 !important;
        }
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .padding-bottom-0 {
        padding-bottom: 0px;
    }
}
