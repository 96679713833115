﻿@import '../../shared/sass/_variables.scss';

$modal-width: 1140px;
$unit_width: floor($modal-width / 100);
$control-margin: 2px;
$standard-padding: 1em;
$border-color: $darkgray;

.modal-backdrop {
    z-index: 1029;
}

.grid-edit-modal {
    font-size: 12px;

    @media (max-width: 1000px) {
        margin-left: -8em;
    }

    position: fixed;
    top: 40%;
    z-index: 1030;
    background-color: white;
    border: 1px solid $border-color;
    max-height: calc(100% - 100px);
    width: $modal-width;

    .button {
        width: 1.5em;
        height: 1.5em;
        display: inline-block;
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
    }

    .grid-edit-modal-footer-buttons {
        height: 45px;
    }

    ul.buttons, li {
        display: inline-block;
    }

    ul.buttons {
        padding: 0;
        text-align: center;
        padding-left: 500px;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    %disabledNavigation {
        opacity: 0.4;
        pointer-events: none;
    }

    &.disabled {
        li {
            @extend %disabledNavigation;
        }
    }

    li.disabled {
        @extend %disabledNavigation;
    }

    .button-back {
        background-image: url(../content/images/svg/Step-backward.svg);
    }

    .button-next {
        background-image: url(../content/images/svg/Step-forward.svg);
    }

    &:not(.disabled) {
        .button-back:not(.disabled):hover, .button-back:active {
            background-image: url(../content/images/svg/Step-backward-MouseOver.svg);
            cursor: pointer;
        }

        .button-next:not(.disabled):hover, .button-next:active {
            background-image: url(../content/images/svg/Step-forward-MouseOver.svg);
            cursor: pointer;
        }
    }

    .title-bar {
        font-size: 14px;

        > div:not(.clearfix) {
            padding: $standard-padding;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            text-transform: uppercase;
            width: auto;
            display: inline;
            z-index: 0;
            height: 3em;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .date-format {
        border: none !important;
        background-color: inherit;
    }

    .border-bottom {
        border-bottom: 1px solid $border-color;
    }

    .header-row {
        background: #d3d3d3;
        font-family: $font-semibold;
        text-transform: uppercase;

        .the-header {
            display: table;
            padding: 0 $standard-padding;

            .header-column {
                display: table-cell;
                border-right: 1px solid $white;
                height: 100%;
                vertical-align: top;
                padding-left: 2px;

                label {
                    padding-top: 6px;
                    word-break: break-word;
                    margin: $control-margin;
                    width: calc(100% - #{$control-margin} - #{$control-margin});
                }
            }

            .header-column:last-child {
                border-right: none;
            }
        }
    }

    .body-row {
        height: 29px;

        .the-body {
            display: table;
            padding: 0 $standard-padding;

            .body-column {
                display: table-cell;
                border-right: 1px solid $gray;

                label, .bill-info-div {
                    padding-top: 5px;
                }

                input, select, label {
                    border-radius: 4px;
                    border-style: solid;
                    border-color: $border-color;
                    border-width: 1px;
                }

                button, input, select, label {
                    padding: 2px;
                    height: 25px;
                    margin: $control-margin;
                    width: calc(100% - #{$control-margin} - #{$control-margin});
                }

                button, input, label {
                    padding-left: 4px;
                }
            }
        }
    }

    .truncate-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    $class-slug: width !default;

    @for $i from 1 through 40 {
        .#{$class-slug}-#{$i} {
            width: $i *$unit_width;
        }
    }

    .first-column-kmr {
        padding-left: 33px;
    }

    .dropDownMinWidth550px {
        padding-left: 35px;

        .dropdown-menu {
            width: 650px !important;
        }

        .btn {
            height: 25px;
            padding-top: 3px;
        }
    }

    .no-padding-top {
        padding-top: 0px;
    }

    .alv-validation {
        .field-validation-tooltip {
            top: 75px !important;
        }
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .buttons-row {
        padding: 0px;

        button {
            margin: 5px;
        }

        .permanent-buttons {
            display: inline;

            button {
                float: right;
                margin: 5px 5px 5px 0px;
            }
        }

        .commands-buttons {
            display: inline;
            float: left;
            padding: 5px $standard-padding;

            .commands-container {
                display: inline-block;
                padding: 5px 15px;
                float: left;

                button {
                    float: left;
                }

                input[type="radio"] {
                    top: 2px;
                }
            }

            .commands-container:first-child {
                padding-left: 0;
                border-right: 1px solid $darkgray;
            }
        }
    }

    .instruction-section {
		background-color: $gray;
        font-size: 14px;
        padding: 1em;
        margin-bottom: 1%;
    }
}
