.kemera-tab-accordion {
    .ajankohta-right-padding {
        padding-right: 20px !important;
    }

    .kmrNro-validation {
        .field-validation-tooltip {
            left: 390px !important;
        }
    }

    .input-format-half {
        width: 145px;
        border-radius: 4px;
        padding-left: 6px !important;
        border: 1px solid #ccc;
        height: 27px !important;
    }

    .no-right-padding {
        padding-right: 0px;
    }

    .cb {
        margin-top: 0px !important;
        margin-left: 20px !important;
        padding-left: 10px !important;
    }

    .kmrsahko-position {
        top: -4px;
        float: right !important;
    }

    .cb-label-position {
        padding-right: 0px;
        margin-top: 7px;
        margin-bottom: 0px;
    }
}