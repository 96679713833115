.stand-information-tool {
	max-width: 400px;

	&.header-icon {
		height: 1.5em;
		margin-right: 0.5em;
	}

	&.stand-information-container {
		position: absolute;
		background-color: rgba(0,0,0,0.75);
		display: none;
		z-index: 500;
		padding: 0;
		pointer-events: none;
		border-radius: 0.5em;
		border: 0;
	}

	&:after {
		/* add a triangle to the bottom of it */
	    border-left: 5px dashed transparent;
	    border-right: 5px dashed transparent;
	    margin-left: -6px;
	    width: 0;
	    height: 0;
	    position: absolute;
	    content: '';
	}

	&.arrow-bottom:after {
	    border-top: 20px solid rgba(0,0,0,0.75);
	    border-bottom: 0;
	    bottom: -20px;	
	}

	&.arrow-top:after {
		/* add a triangle to the bottom of it */
	    border-bottom: 20px solid rgba(0,0,0,0.75);
	    border-top: 0;
	    top: -20px;
	}

	&.arrow-right:after {
	    right: 20px;		
	}

	&.arrow-left:after {
		left: 20px;
	}

	.stand-information-table {
		table-layout: fixed;

		thead {
			th {
				padding: 0.5em 0;

				&:first-child {
					padding-left: 0.5em;
				}

				&:last-child {
					padding-right: 0.5em;
				}
			}

			tr {
				border-bottom: 1px solid $white;
			}
		}

		tbody {
			tr:first-child {
				td {
					padding-top: 0.5em;
				}
			}

			tr:last-child {
				td {
					padding-bottom: 0.5em;
				}
			}
			tr {
				td:first-child {
					padding-right: 0.25em;
				}
				td:last-child {
					padding-left: 0.25em;
				}
			}
			td {
				&.table-header {
					color: $trimble_tf_product_green;
					text-transform: uppercase;
				}
			}

			td:first-child {
				padding-left: 0.5em;
			}

			td:last-child {
				padding-right: 0.5em;
			}
		}

		tfoot {
            border-top: 1px solid $gray;

			div {
				padding: 0 0.5em;

				&:first-child {
					padding-top: 0.5em;
				}

				&:last-child {
					padding-bottom: 0.5em;
				}

				&.footer-header {
					color: $trimble_tf_product_green;
					text-transform: uppercase;
				}

				&.harvesting-area-additional-info {
					-webkit-line-clamp: 3;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					padding: 0;
					margin: 0.5em;

					&.last {
						margin-top: 0;
					}
				}
			}
		}

	}

	color: $white;
}
