﻿.signature-details {
    border: 1px solid #616161;
    border-radius: 0.5em;

    .signature-details-body {
        .row {
            margin-right: 0px;
            margin-left: 0px;
            padding: 2px 0px;
        }

        .row:nth-of-type(even) {
            background-color: #f6f6f6;
        }

        .row:last-child {
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
        }
    }

    .signature-details-header {
        height: 30px;
        padding: 6px 0px 8px 6px;
        background: transparent linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3) 0% 0% no-repeat padding-box;
        text-transform: uppercase;
        font-weight: 700;
        padding-left: 5px;
        border-bottom: 0.75px solid #B0AFB0;
        font-size: 12px;
    }

    .date-picker-width {
        width: 45%;
    }

    .signature-details-input-field {
        height: 30px;
        border: 1px solid #616161 !important;
        width: 100%;
        border-radius: 5px;
        padding-left: 6px;
    }
}
