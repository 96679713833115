.customer-history {

    .bankAccount-input-group {
        display: grid;
    }

    .bank-account-container input {
        width: calc(42% - 4px) !important;
    }

    .content-taxman {
        height: 205px;
    }

    .content #updater {
        padding-left: 2px;
    }

    #creationDate {
        padding-left: 5px;
    }

    div.partial-header.space-before {
        border-radius: 0;
    }

    .payment-position {
        margin-right: -1em;
        margin-left: 0.4em;
    }

    .margin-right-50 {
        margin-right: 50% !important;
    }
}
