.find-real-estate-popup {
    .modal-size {
        width: 400px;
    }

	.modal-header {
		text-transform: uppercase;
		font-weight: bold;

        .close-button {
            cursor: pointer;
        }
	}

	.real-estate-info-container {
		border: 1px solid $gray;
		border-radius: 6px;

		.real-estate-info-header {
			background-color: $lightgray;
			padding: 0.5em;
			text-transform: uppercase;
			font-weight: bold;
		}

		.real-estate-info-body {
			padding-bottom: 0.5em;
		}
	}

    .arrow-container {
        position: relative;
        height: 20px;

        .registry-position {
            position: absolute;
            height: 21px;
            left: calc(50% + 2px);
            width: 45%;
        }

        .arrows {
            margin-top: 9px;
            border-top: 1px solid $green;
            height: 100%;
        }

        .arrow-box-left {
            position: relative;
            display: inline-block;
            height: inherit;
            //border-top: 1px solid $green;
            border-left: 1px solid $green;

            &.registry {
                width: 45px;
            }

            &.forest-plan {
                width: 28px;

                .mapIsExpanded & {
                    width: 25px;
                }
            }

            &:after {
                top: 100%;
                border: solid transparent;
                content: "";
                position: absolute;
                border-top-color: $green;
                border-width: 5px;
                left: -5px;
            }
        }

        .arrow-text-wrapper {
            text-align: center;
            position: absolute;
            width: 100%;
            display: inline-block;
            vertical-align: top;
            color: $green;
        }

        .arrow-text {
            background: white;
            padding: 0 4px;
            display: inline-block;
        }

        .arrow-box-right {
            position: relative;
            display: inline-block;
            height: inherit;
            //border-top: 1px solid $green;
            border-right: 1px solid $green;
            //margin-top: 9px;
            right: 0;
            position: absolute;

            &.registry {
                width: 45px;
            }

            &.forest-plan {
                width: 28px;

                .mapIsExpanded & {
                    width: 25px;
                }
            }

            &:after {
                top: 100%;
                border: solid transparent;
                content: "";
                position: absolute;
                border-top-color: $green;
                border-width: 5px;
                left: calc(100% - 5px);
            }
        }
    }

    .full-row {
        display: flex;
        margin: 0;
	    .row-item {
	        flex: 1;
	        margin: 0;
	        padding: 0;
	        text-align: center;

	        label {
	            margin-bottom: 0;
	        }
	    }

        .separator.horizontal {
            margin: .1em 0 .1em 0;
            float: left;
            width: 1em;
            padding-top: 1em;
            font-size: 140%;
            position: relative;
            text-align: center;
            min-height: 100%;
            vertical-align: bottom;
        }
	}

	.modal-footer {
		display: flex;

		button {
			flex-grow: 1;
		}
	}
}