﻿@import '../../../../shared/sass/_variables.scss';

.change-status-modal {

    .modal-dialog {
        top: 225px;
        width: 350px;
    }

    .container-fluid {
        padding: 15px;

        .row {
            padding: 5px 0px;
        }
    }

    .additional-information {
        background-color: $lightgray;
        font-style: italic;
        padding: 5px 15px;
    }

    .app-container-footer {
        padding: 15px;
        border: none;
        height: 7em;

        .row {
            padding: 5px;
        }
    }
}
