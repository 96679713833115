.theme-maps-tool {
    &.panel { //Override the default styling in map-control-panels.scss to fill the entire height
        top: .2em;
        left: .2em;
        bottom: .2em;
        overflow: hidden;
        margin: 0;
        display: flex;
        flex-direction: column;

        &.wrapper {
            display: flex;
            flex: 1;
            border-left: 1px solid $gray;

            &:first-of-type {
                border-left: 0;
            }
        }

        .content {
            margin: 0;
            overflow: hidden;
        }

        .explanations-content {
            display: flex;
            flex: 1 0;
        }
    }

    .header-block {
        padding: 0.25em;
    }

    .form-group .form-control {
        height: 27px;
    }

    .services-separated-select {
        pointer-events: none;
    }

    &.block {
        display: flex;
        flex-flow: column;
        margin-top: 0.25em;

        &.dropdown-selections {
            padding: 0 0.5em;
        }
    }

    &.col {
    }

    &.icon {
        width: 1.3em;
    }

    &.copy-icon-wrap {
        flex: 1;
        margin-right: 1em;
        height: 1em;
        align-self: flex-end;
    }

    &.copy-icon {
        width: .8em;
        margin-bottom: .5em;
    }

    &[type="checkbox"]:not(:checked) + label:before, &[type="checkbox"]:checked + label:before {
        top: 0;
    }

    &[type="checkbox"]:not(:checked) + label:after, &[type="checkbox"]:checked + label:after {
        top: 3px;
    }

    &.block.bottom-buttons {
        justify-content: center;
        padding: 0;
        flex-flow: row;

        > .wrapper {
            flex: 1;
            justify-content: center;
            align-items: center;
            padding: .5em;

            > .btn { //Make the buttons a bit smaller
                padding-top: .2em;
                padding-bottom: .2em;
            }
        }
    }

    &.table-container {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &.theme-table {
        width: 100%;
        margin-top: .5em;
        table-layout: fixed;

        .last {
            td {
                padding-bottom: 0.5em;
            }
        }

        .header-cell {
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }

        .explanation-container {
            display: flex;
            align-items: center;
        }

        .renderer-image-container {
            border: 1px solid black;
            display: inline-block;
            height: 18px;
            width: 18px;
            overflow: hidden;
            padding: 0;
            margin: 0;

            .renderer-image {
                height: 1.5em;
                width: 1.5em;
            }
        }

        .explanation-label {
            display: block;
            font-size: 13px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 170px;
        }

        th {
            padding-bottom: 0.5em;
            text-transform: uppercase;

            &:first-child {
                padding-left: 0.75em;
                width: 65%;
            }

            &:last-child {
                padding-right: 0.75em;
                width: 16%;
            }
        }

        .ws3-row-style {
            td {
                padding-bottom: 0.5em;
                padding-top: 0.5em;

                i {
                    margin-right: 0.5em;
                    height: 1em;
                    width: 1em;
                }

                .open {
                    transform: rotate(90deg);
                }
            }
        }

        .ws3-row-style + .ws3-data {
            td {
                padding-top: 0.5em;
            }
        }

        .ws3-data.ws3-data-last {
            td {
                padding-bottom: 0.5em;
            }
        }

        td {
            vertical-align: middle;
            padding-bottom: 0.2em;

            &:first-child {
                padding-left: 0.75em;
                padding-right: 0.5em;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                span {
                    margin-left: 0.2em;
                }
            }

            &:last-child {
                padding-right: 0.75em;
            }

            &.no-break {
                text-overflow: initial;
                white-space: initial;
            }
        }

        .second-class {
            border-top: 1px solid $gray;

            td {
                padding-top: 0.25em;
                padding-bottom: 0.25em;
            }

            .header-cell {
                padding-top: 0.5em;
                padding-bottom: 0.5em;
            }
        }
    }

    &.time-classes {
        border-top: 1px solid $gray;
        margin-top: 0.5em;
        padding-top: 0.5em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0.5em;
        padding-right: 0.5em;

        button {
            margin-top: 0.5em;
        }
    }
    // aikaväli taulukko
    &.time-classes-selection {
        padding: 0;
        margin-left: 25px;
        width: calc(100% - 25px);

        td {
            padding-right: 0.25em;
            padding-bottom: 0.25em;
            white-space: nowrap;

            &:not(:first-child) {
                text-align: center;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        .checkbox-label {
            margin-bottom: 0;
        }

        .year {
            min-width: 70px;
        }
    }

    & .lime {
        color: $trimble_tf_product_green;
    }

    & .gray {
        color: $gray;
    }

    & .full-width {
        width: calc(100% - 10px) !important;
    }

    &.selection-header-group {
        padding: 0;
        padding-top: 0.5em;
        margin: 0;
        border: 0;

        .selection-header {
            width: 100%;
            background-color: $black;
            color: white;
            text-transform: uppercase;
            padding: 0.25em 0.75em;
            margin-top: 1px;
        }
    }

    .fake-select {
        pointer-events: none;
    }

    &.separated-list {
        &.worksite-separated-list {
            table {
                tbody {
                    tr {
                        td:first-child {
                            text-align: left;
                        }
                    }
                }
            }
        }

        table {
            width: 100%;
            border: 1px solid $gray;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-collapse: initial;
            font-weight: 700;

            tbody {
                tr:first-child {
                    td {
                        padding-top: 0.25em;
                    }
                }

                tr {
                    td {
                        padding-left: 0.25em;
                        padding-right: 0.25em;
                        padding-bottom: 0.25em;

                        label {
                            margin-bottom: 0;
                        }

                        &:first-child {
                            text-align: center;
                            border-right: 1px solid $lightgray;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }

            tfoot {
                td {
                    border-top: 1px solid $lightgray;
                    padding: 0.25em;
                    text-transform: uppercase;
                }
            }
        }

        .button-container {
            padding: 0.5em;
            background-color: $lightgray;
            border-bottom: 1px solid $gray;
            border-left: 1px solid $gray;
            border-right: 1px solid $gray;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            button .full-width {
                width: 100%;
                text-align: center;
            }
        }
    }

    .uppercase {
        text-transform: uppercase;
    }
}
