﻿.route-information-tool {
    max-width: 500px;
    display: block;
    position: absolute;

    .header-img {
        display: inline-block;
    }

    &.header-icon {
        height: 1.5em;
        margin-right: 0.5em;
    }

    .bubble-header-text {
        margin-top: 2px;
        max-width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        width: 140px;
    }

    &.all-route-information-container {
        width: 455px;
        height: 30px;
        position: fixed;
        background-color: rgba(0,0,0,0);
        display: none;
        z-index: 500;
        padding: 0;
        pointer-events: none;
        border-radius: 0.5em;
        border: 0;
        color: $white;
    }

    &.route-information-container {
        width: 305px;
        height: 170px;
        position: fixed;
        background-color: rgba(0,0,0,0.75);
        display: none;
        z-index: 500;
        padding: 0;
        pointer-events: none;
        border-radius: 0.5em;
        border: 0;
    }

    &:after {
        /* add a triangle to the bottom of it */
        border-left: 5px dashed transparent;
        border-right: 5px dashed transparent;
        margin-left: -6px;
        width: 0;
        height: 0;
        position: absolute;
        content: '';
    }

    &.arrow-bottom:after {
        border-top: 20px solid rgba(0,0,0,0.75);
        border-bottom: 0;
        bottom: -20px;
    }

    &.arrow-top:after {
        /* add a triangle to the bottom of it */
        border-bottom: 20px solid rgba(0,0,0,0.75);
        border-top: 0;
        top: -20px;
    }

    &.arrow-right:after {
        right: 20px;
    }

    &.arrow-left:after {
        left: 20px;
    }

    .all-route-information-table {
        table-layout: fixed;
        width: auto !important;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 5px;

        thead {
            th {
                padding: 0.5em 0;

                &:first-child {
                    padding-left: 0.5em;
                }

                &:last-child {
                    padding-right: 0.5em;
                }
            }

            tr {
                border-bottom: 1px solid $white;
            }
        }

        tbody {
            tr:first-child {
                td {
                    padding-top: 0.5em;
                }
            }

            tr:last-child {
                td {
                    padding-bottom: 0.5em;
                }
            }

            tr {
                td:first-child {
                    padding-right: 0.25em;
                }

                td:last-child {
                    min-width: 200px;
                    padding-left: 0.25em;
                }
            }

            td {
                &.table-header {
                    color: $trimble_tf_product_green;
                    text-transform: uppercase;
                    white-space: nowrap;
                }

                &.all-info-table-header {
                    color: $white !important;
                    text-transform: none !important;
                    max-width: 450px !important;
                    text-align: center !important;
                }
            }

            td:first-child {
                padding-left: 0.5em;
            }

            td:last-child {
                padding-right: 0.5em;
            }
        }

        tfoot {
            border-top: 1px solid $gray;

            div {
                padding: 0 0.5em;

                &:first-child {
                    padding-top: 0.5em;
                }

                &:last-child {
                    padding-bottom: 0.5em;
                }

                &.footer-header {
                    color: $trimble_tf_product_green;
                    text-transform: uppercase;
                }

                &.harvesting-area-additional-info {
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    padding: 0;
                    margin: 0.5em;

                    &.last {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .route-information-table {
        table-layout: fixed;

        thead {
            th {
                padding: 0.5em 0;

                &:first-child {
                    padding-left: 0.5em;
                }

                &:last-child {
                    padding-right: 0.5em;
                }
            }

            tr {
                border-bottom: 1px solid $white;
            }
        }

        tbody {
            tr:first-child {
                td {
                    padding-top: 0.5em;
                }
            }

            tr:last-child {
                td {
                    padding-bottom: 0.5em;
                }
            }

            tr {
                td:first-child {
                    padding-right: 0.25em;
                }

                td:last-child {
                    min-width: 200px;
                    padding-left: 0.25em;
                }
            }

            td {
                &.table-header {
                    color: $trimble_tf_product_green;
                    text-transform: uppercase;
                }

                &.all-info-table-header {
                    color: $white !important;
                    text-transform: none !important;
                    max-width: 450px !important;
                    text-align: center !important;
                }
            }

            td:first-child {
                padding-left: 0.5em;
            }

            td:last-child {
                padding-right: 0.5em;
            }
        }

        tfoot {
            border-top: 1px solid $gray;

            div {
                padding: 0 0.5em;

                &:first-child {
                    padding-top: 0.5em;
                }

                &:last-child {
                    padding-bottom: 0.5em;
                }

                &.footer-header {
                    color: $trimble_tf_product_green;
                    text-transform: uppercase;
                }

                &.harvesting-area-additional-info {
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    padding: 0;
                    margin: 0.5em;

                    &.last {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    color: $white;
}

.gps-routes {
    .close-button {
        margin-right: 0.0em !important;
        margin-top: 0.0em !important;
    }

    .rollback-changes {
        padding-top: 0px !important;
        padding-bottom: 12px !important;

        &:hover {
            background-color: $white !important;
            color: rgb(51, 51, 51) !important;

            .glyphicon {
                color: rgb(51, 51, 51) !important;
                border-color: rgb(51, 51, 51) !important;
            }
        }
    }

    .layer-title {
        left: 0px !important;
        text-transform: uppercase;
    }

    .all-info-button {
        display: inline-block;
        height: 14px !important;
        width: 17px !important;
        margin-top: 14px !important;
        margin-left: 0.9em !important;
    }

    .header-topic {
        margin-left: 2.4em;
        text-transform: uppercase;
        margin-top: 1em;
        width: 205px;
        display: inline-block;
    }

    .sublayer-styleline {
        margin-left: 0.8em !important;
        padding-top: 0px;
        width: 20px;
        height: 10px;
        border-bottom: 3px dashed blue;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        margin-left: 3px;
    }

    .sublayer-styleline-color-1 {
        border-bottom: 3px solid #ff00ff;
    }

    &.route-panel {
        top: 0.2em !important;
    }

    .layers-scroll-wrapper {
        overflow-y: auto;
    }

    .settings-row {
        margin: 0px;
        padding: 0px;
        line-height: 50px !important;
        vertical-align: central;
        margin-bottom: auto;
        margin-top: auto;
    }

    .settings-row-first {
    }

    .button-wrapper {
        border-bottom: 1px solid $gray;
        width: 300px;
    }

    .restore-defaults {
        margin-left: 0.6em;
        width: 288px;
        height: 45px;
    }

    .restore-defaults-checkbox {
        margin-bottom: 23px !important;
        margin-left: 10px;
    }

    .restore-defaults-checkbox-label {
        padding-left: 0px !important;
        padding-bottom: 17px;
        margin-left: 6px !important;
    }

    .show-favorites {
        width: 30px;
    }

    .layer-favorites-button {
        margin-bottom: 8px;
    }

    .settings-item {
        vertical-align: middle;
        display: inline-block;
        line-height: 44px;
        height: 44px;
        min-width: 60px;
        margin: auto 0.5em 0 0;
        margin-left: 0.8em;
        right: 0px;
        padding: 0px;

        ul {
            > li {
                &.graybk {
                    background-color: $lightgray;
                }
            }

            label {
                line-height: initial;
                display: block;
                font-weight: initial;
                font-size: 13px;
                position: relative;
                padding-left: 1.2em;

                input {
                    margin-right: 0.5em;
                    position: absolute;
                    left: 0;
                    margin-top: 0;
                }
            }

            button {
                margin: 0 0.5em;
                width: calc(100% - 1em);
            }

            div {
                .divider {
                    margin: 0;
                }
            }
        }
    }

    .dropdown-toggle {
        margin-top: 0.4em;
        margin-bottom: 0em;
    }

    .dropdown-subheader {
        text-transform: uppercase;
        font-size: 0.8em;
        margin: 0.5em;
        margin-left: 1.7em;
        font-weight: bold;
        margin-bottom: 0.6em;
    }

    .dropdown-emptytext {
        text-transform: uppercase;
        font-size: 0.8em;
        margin: 0.5em;
        margin-left: 1.7em;
        font-weight: normal;
        opacity: 0.4;
        text-align: center;
    }

    .saved-lists-dropdown-for-map {
        margin-top: 0px;
        padding-top: 8px;
        max-width: 260px;
        min-width: 250px;
        top: 40px;

        > li > a {
            position: relative;
            white-space: initial;
            cursor: initial;
        }

        .favoritelist-name {
            max-width: calc(100% - 50px);
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            vertical-align: middle;
            margin-top: 10px;
            position: absolute;
        }

        .favoritelist-remove {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 12px;
            font-size: 12px;
            color: inherit;
            width: 20px;
            cursor: pointer;
        }

        .favoritelist-warning {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            margin-right: 25px;
            height: 14px;
            font-size: 12px;
            color: inherit;
            width: 17px;
            cursor: pointer;
            background-image: url(/content/images/svg/map/Warning_icon.svg);
        }

        &.site-expanded {
            left: -22px; /* adjust favorites toolbar to fit to the viewport */
        }
    }

    .dropdown-menu > li > a {
        height: 45px;
    }

    ul.dropdown-menu {
        max-height: 330px;
        overflow-y: auto;
        width: 320px;

        .divider {
            margin: 0;
        }
    }

    .icon {
        height: 17px;
        width: 18px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;

        &.icon-printer {
            background-image: url(/content/images/svg/print.svg);

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/print-green.svg);
            }
        }

        &.icon-settings {
            width: 25px;
            //height: 16px;
            background-image: url(/content/images/svg/settings-cog.svg);

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/settings-cog-green.svg);
            }
        }

        &.icon-favorite {
            background-image: url(/content/images/svg/favorite.svg);

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/favorite-green.svg);
            }
        }
    }

    .icon-button {
        //Override bootstrap's styles on icon-buttons
        > .btn {
            &:hover,
            &:focus {
                background-color: initial;

                .icon-settings {
                    background-image: url(/content/images/svg/settings-cog-green.svg);
                }
            }

            background-color: initial;
        }

        &.open {
            .icon-printer {
                background-image: url(/content/images/svg/print-green.svg);
            }

            .icon-favorite {
                background-image: url(/content/images/svg/favorite-green.svg);
            }
        }
    }

    .generic-warning {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        margin-left: 5px;
        margin-right: 5px;
        height: 14px;
        font-size: 12px;
        color: inherit;
        width: 17px;
        cursor: pointer;
        background-image: url(/content/images/svg/map/Warning_icon.svg);
    }

    &.panel { //Override the default styling in map-control-panels.scss to fill the entire height
        top: 5.2em;
        left: .2em;
        bottom: .2em;
        margin: 0;
        display: flex;
        flex-direction: column;

        .header-wrapper {
            padding: 1em 0.5em;
            padding-bottom: 0.9em;
            text-transform: uppercase;
            border-bottom: 1px solid black !important;

            .logo {
                height: 1.6em;
                width: 2.2em;
                margin-left: 2px;
                filter: invert(50%);
            }
        }

        .header-override {
            position: relative !important;
            left: 0px !important;
            top: 0px !important;
        }
    }

    &.set-defaults-arrow {
        border: 1px solid black;
        padding: .3em;
        border-radius: .3em;
    }

    &.rollback-changes {
        padding: 0.5em;
        padding-top: 0px;
        min-height: calc(28px + 1em);

        .arrow {
            width: 3em;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .content {
        height: calc(100% - 55px);
        display: flex;
        flex-direction: column;
        flex-basis: 35px;
        flex-grow: 1;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;

        .empty-layer-list-container {
            margin-top: 1.0em;
            margin-left: 1.0em;
            font-style: italic;
        }

        .layer-list-container {
            flex-grow: 1;
            overflow-y: auto !important;
            overflow-x: hidden;
            height: 100%;
            margin-right: 1px;

            .layer-list {
                padding: .2em 0 2px 0;

                > li {
                    list-style: none;
                    padding-top: 0.5em;
                    padding-bottom: 0.5em;
                    position: relative;

                    &:last-of-type {
                        margin-bottom: 0em;
                    }

                    &.gray {
                        padding-top: 10px;
                        background-color: $lightgray;
                    }

                    &.graybk {
                        background-color: $lightgray;
                    }

                    .slider-row {
                        display: flex;
                        flex-basis: 25px;
                        margin-left: 1.1em;
                        height: 26px;
                        margin-right: 0.2em;

                        .arrow-container {
                            position: relative;
                            height: 20px;
                            width: 2em;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding-right: 0.3em;
                        }

                        .slider-checkbox {
                            width: 2.5em;
                            display: flex;

                            [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
                                top: 4px;
                            }

                            [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
                                top: 4px;
                            }
                        }

                        input {
                            max-width: 3.5em;
                        }
                    }

                    .sublayer-container-row {
                        display: flex;
                        flex-basis: 25px;
                        margin-left: 1em;

                        .sublayer-title-container {
                            min-width: 115px;
                            margin-right: 0.5em;
                        }

                        .sublayer-length-container {
                            min-width: 30px;
                            margin-right: 0.1em;
                        }

                        .sublayer-styleline-container {
                            min-width: 20px;
                            margin-right: 0.1em;
                        }

                        .arrow-container {
                            margin-top: -0.6em;
                            position: relative;
                            height: 20px;
                            width: 2em;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        input {
                            max-width: 3.5em;
                        }
                    }

                    .subsublayer-container {
                        margin-top: 0.5em;
                        margin-left: -200px;
                    }

                    .subsublayer-container-row {
                        display: flex;
                        flex-basis: 25px;
                        margin-left: 1em;

                        .arrow-container {
                            position: relative;
                            height: 20px;
                            width: 2em;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        input {
                            max-width: 3.5em;
                        }
                    }

                    > label {
                        top: -10px;
                        margin-left: .3em;

                        &.sublist-checkbox {
                            top: -15px;
                            margin-left: .5em;
                        }
                    }

                    > .layer-list {
                        padding-left: 2em;
                        margin: .6em -0.5em 0 -0.5em;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        background: $lightgray;
                        margin-left: 1px;

                        .layer-title {
                            left: 20px;
                        }
                    }

                    &.dndPlaceholder {
                        background: $lightgray;
                        padding: 0px;
                        min-height: 40px;
                    }

                    .sublist {
                        padding-left: 1.5em;
                        margin-top: 0.4em;
                        border: 0;
                        background: $lightgray;
                        // fix for aligning checkboxes properly in the sublist.
                        [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
                            top: 0px;
                        }

                        [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
                            top: 0px;
                        }

                        .sublist-item {
                            padding-top: 1px;
                            padding-bottom: 1px;

                            &:not(:first-child) {
                                margin-top: 0;
                            }

                            &:first-child {
                                margin-top: 0.5em;
                            }

                            &:last-child {
                                padding-bottom: 0.5em;
                            }
                        }

                        .sublist-item-plain {
                            height: 20px;
                        }

                        .subsublist-item {
                            height: 20px;

                            &:not(:first-child) {
                                padding-left: 0em;
                                margin-top: 0;
                            }

                            &:last-child {
                                padding-bottom: 1em;
                            }
                        }
                    }
                }
            }
        }
    }

    &.slider-drag-and-drop-button {
        width: 1em;
        border: 1px solid $gray;
        border-right: 0;
        height: 25px; // for some reason slider being 24 pixels high still requires this to be 25px to align properly. 24px would happen by default...
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("/content/images/svg/UpAndDownBlackArrow.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 50%;

        &:active {
            background-color: $black;
            background-image: url("/content/images/svg/UpAndDownWhiteArrow.svg");
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .tooltip-inner {
        background: $blue !important;
        color: white;
    }

    .tooltip {
        display: none !important;
    }

    .top-level-tooltip {
        display: block !important;
        padding-left: 0px;
        padding-top: 0px;
        position: fixed;
        user-select: none;
    }

    .top-level-tooltip-main.top {
        margin-top: -22px; //	Set the toolbit a bit lower than default
        margin-left: -33px; //	Set the toolbit a bit lower than default
    }

    .top-level-tooltip-inner {
        background: $blue;
        color: white;
    }

    .top-level-tooltip-arrow {
        //border-top-color: $blue;
        display: none;
    }

    &.opacity-slider-wrapper {
        max-width: 190px;
        position: relative;
        flex-grow: 1;

        .tooltip-main.top {
            margin-top: -26px; //	Set the toolbit a bit lower than default
        }

        .tooltip-inner {
            background: $blue;
            color: white;
        }

        .tooltip-arrow {
            //border-top-color: $blue;
            display: none;
        }
    }

    &.layer-title {
        pointer-events: none; //Ignore clicks to this element (click through to the element under it)
        position: absolute;
        z-index: 800;
        left: 0.0em !important;
        right: 0.5em;
        font-size: 90%;
        top: 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 90%;
        overflow: hidden;
    }

    .sublayer-title {
        padding-top: 0px;
        white-space: nowrap;
        text-overflow: ellipsis;
        //max-width: 150px;
        overflow: hidden;
        display: block;
        font-size: 90%;
        width: 123px !important;
        text-overflow: ellipsis;
    }

    &.sublayer-length {
        padding-top: 0px;
        white-space: nowrap;
        text-overflow: ellipsis;
        //max-width: 150px;
        overflow: hidden;
        display: block;
        font-size: 90%;
    }

    &.subsublayer-title {
        padding-top: 0px;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden;
        font-size: 90%;
    }

    &.opacity-input {
        position: relative;
    }

    &.arrow-open-panel {
        transition: transform 100ms linear;
        position: relative;
        top: 4px;
        // to make the arrow stay in place when transroming
        height: 1.1em;
        width: 1.1em;

        &.open {
            transform: rotate(90deg);
        }
    }

    &.arrow-open-sub-panel {
        transition: transform 100ms linear;
        position: relative;
        top: 11px;
        // to make the arrow stay in place when transroming
        height: 1.1em;
        width: 1.1em;

        &.open {
            transform: rotate(90deg);
        }
    }

    &.arrow-placeholder {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 10px;
    }

    &.arrow-warning-placeholder {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 5px;
        width: 17px;
        height: 14px;
        cursor: pointer;
        background-image: url(/content/images/svg/map/Warning_icon.svg);
    }

    .arrow-info-placeholder-unselected-route {
        margin-left: 0.5em;
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 0px;
        width: 15px;
        height: 20px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_WhiteBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_LimeGreenBackground.svg);
        }
    }

    &.arrow-info-placeholder-disabled {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 0px;
        width: 20px;
        height: 20px;
        cursor: not-allowed;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_GreyBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_GreyBackground.svg);
        }
    }

    &.arrow-info-placeholder-unselected-other {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 0px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_WhiteBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_LimeGreenBackground.svg);
        }
    }

    .arrow-info-placeholder-selected-route {
        margin-left: 0.5em;
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 0px;
        width: 15px;
        height: 20px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_LimeGreenBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_LimeGreenBackground.svg);
        }
    }

    &.arrow-info-placeholder-selected-other {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 0px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_BlackBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_LimeGreenBackground.svg);
        }
    }

    &.arrow-sublayer-warning-placeholder {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 7px;
        width: 17px;
        height: 14px;
        cursor: pointer;
        background-image: url(/content/images/svg/map/Warning_icon.svg);
    }

    &.arrow-subsublayer-warning-placeholder {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 2px;
        left: 5px;
        width: 17px;
        height: 14px;
        cursor: pointer;
        background-image: url(/content/images/svg/map/Warning_icon.svg);
    }

    .show-favorites-list {
        margin-top: 6px;
    }
}
