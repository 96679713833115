.set-start-and-end-date-modal-window {
    .modal-dialog {
        width: 339px;
    }
    
    .basic-modal-window{
        height: 273px;
    }
    
    .modal-content{

        .modal-body {
            height: 175px;
            width: 285px;
            padding: 25px;
            padding-left: 20px;

            .start-date{
                margin-bottom: 27px;
        
                #startDate{
                    border: 1px solid #616161;
                    border-radius: 4px;
                    width: 295px;
                    height: 27px;
                }
            }

            .end-date{
                #endDate{
                    border: 1px solid #616161;
                    border-radius: 4px;
                    width: 295px;
                    height: 27px;
                }
            }

            .ng-empty{
                background-color: #FFD8D8;
            }

            .invalid-date {
                background-color: #FFD8D8;
             }
        }

        .modal-footer{
            padding-top: 16px;

            .btn-silva {
                width: 147px;
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }

        .form-group {
            width: 285px;

            >input{
                width: 285px;
            }
            
            >label{
                width: 285px;
            }
        }

        .popover-content{
            height: 45px;
            padding-top: 2px;
            padding-bottom: 0;
        }
    }
}