﻿@import '../../shared/sass/_variables.scss';

.custom-drop-down {
    font-family: $font-family;
    font-size: $default-font-size;
    z-index:9999 !important;

    .btn-group, .btn-group-vertical {
        position: unset;
    }

    button {
        border: 1px solid $darkgray;
        background-color: inherit;
        cursor: pointer;
    }

    button:disabled {
        background-color: $lightgray;
        cursor: not-allowed;
    }

    .btn-group {
        width: 100%;
        display: flex;
    }

    .custom-button {
        width: calc(100% - 26px);
        overflow: hidden;
    }

    .placeholder {
        color: $trimble_brand_grey;
        font-style: italic;
    }

    .is-disabled {
        background-color: $disabled-button-background-color;
        cursor: not-allowed;
        pointer-events: none;

        button[disabled] {
            background-color: $disabled-button-background-color;
        }
    }

    .is-invalid {
        background-color: #FFD8D8;

        button {
            background-color: #FFD8D8;
        }
    }

    .disable-option {
        pointer-events: none;
        background-color: $disabled-button-background-color;
    }

    .dropdown-menu {
        width: 100%;
        cursor: pointer;
        position: fixed;

        > div {
            width: 100%;
            height: 200px;
            overflow-y: scroll;
        }

        .silva-search {
            height: 32px;
            width: calc(100% - 10px);
            margin-bottom: 5px;
            margin-left: 5px;
            overflow-y: hidden;
            line-height: 32px;
        }

        .custom-table {
            display: block;
            width: 100%;

            .table-row {
                display: block;
                width: 100%;

                .table-cell {
                    display: inline-block;
                    height: 25px;
                    padding: 0px;
                    vertical-align: middle;
                    text-overflow: ellipsis;
                }

                .one-column {
                    width: 90%;
                    text-indent: 10px;
                }

                .first--from-two-columns {
                    width: 20%;
                    text-indent: 10px;
                }

                .second--from-two-columns {
                    width: 70%;
                }

                .small-column {
                    width: 8%;
                }

                .kmr-koodi-column {
                    width: 12%;
                }

                .alv-column {
                    width: 6%;
                }

                .name-column {
                    width: 30%;
                }

                .flag-border {
                    border: 1px solid #d1d1d1;
                }
            }

            .table-row:not(.table-header):hover {
                background-color: $gray;
            }

            .table-body {
                display: block;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
            }

            .table-cell-row {
                display: block;
                width: 100%;
                padding: 0px;
            }
        }

        input[type="search"] {
            width: 90% !important;
            border: none !important;
        }

        .glyphicon-remove {
            line-height: 30px;
            float: right;
        }
    }
}
