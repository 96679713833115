﻿@import '../../../shared/sass/_variables.scss';

.display-info {

	img {
		max-width: 100%;
		max-height: 100%;
		width: 100%;
	}

	.portrait {
		height: 80px;
		width: 30px;
	}

	.landscape {
		height: 170px;
		width: 100%;
	}

	.square {
		height: 75px;
		width: 75px;
	}

	.info-box-container {
		margin: 0.5em 1em !important;
		padding: 0.5em;
		border-radius: 5px;
		border: 2px solid $gray;
		border-bottom: 1px solid $gray;
		color: $black;
		position: relative;

		&.green {
			background-color: $trimble_tf_product_green;
		}

		&.blue {
			background-color: $blue;
		}

		&.turquise {
			background-color: $turquise;
		}

		&.white {
			background-color: $white;
			color: $black;
			border-bottom: 1px solid $gray;
		}
	}
}