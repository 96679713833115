﻿@import '../../../shared/sass/_variables.scss';

.worksite-list-container {
    padding-right: 0px;

    .list-view-settings-row {
        .timber-trade-filter-component {
            .advanced-filtering-dropdown .dropdown-content {
                height: 173px;
            }
        }

        .hide-timber-trade-contract-status {
            .advanced-filtering-dropdown .dropdown-content {
                height: 146px;
            }
        }

        .investment-filter-component {
            .advanced-filtering-dropdown .dropdown-content {
                height: 425px;
                width: 806px;

                .dropdown-table {
                    .table-cell {
                        .dropdown-date-input {
                            width: 161px;
                        }

                        .multiselection-drop-down .checkboxes {
                            width: 301px;
                        }
                    }
                }
            }
        }

        .contractors-filter-component,
        .sale-rows-filter-component,
        .product-rows-filter-component {
            .advanced-filtering-dropdown {
                width: 818px;

                .dropdown-content {
                    .dropdown-table {
                        .table-cell {
                            .dropdown-date-input {
                                width: 169px;
                            }

                            .multiselection-drop-down .checkboxes {
                                width: 315px;
                            }
                        }
                    }
                }
            }
        }

        .special-width-component {
            .advanced-filtering-dropdown {
                .dropdown-content {
                    width: 942px;

                    .border-bottom.border-right {
                        width: 33.33%;
                    }
                }
            }
        }
    }

    .worksite-list-content {
        overflow: hidden;
        margin-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        .investment-grid-border {
            border-bottom: 1px solid $darkgray;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
        }

        .worksite-list-grid {
            height: 50vh;

            .contract-extraction-color-blue {
                color: $trimble_blue;
            }
        }

        .investment-grid-height {
            height: calc(100vh - 20.5em) !important;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
        }

        .upper-grid-right-buttons {
            padding-right: 7px;
        }

        .upper-grid-left-buttons {
            padding-left: 6px;
        }

        .ag-bl-center-row {
            border-top-right-radius: 0.5em;
        }

        .investment-border {
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
        }

        .grid-border {
            border-left: 1px solid #616161;
            border-right: 1px solid #616161;
            border-top: 1px solid #616161;
            border-top-right-radius: 0.5em;
            border-top-left-radius: 0.5em;

            .ag-column-select-panel {
                border-bottom: none;

                .ag-column-select-label {
                    font-size: 12px;
                }
            }

            .grid-header-checkbox-label {
                margin: 0;
                left: -4px;
                top: 2px;
            }

            .ag-selection-checkbox {
                padding-left: 1px;
            }

            .ag-floating-bottom {
                background-color: $gray;
                border-top: 1px solid $darkgray;
            }

            .ag-floating-bottom-container {
                font-size: 12px;
                font-weight: bold;

                .ag-cell {
                    background-color: $gray;
                }
            }

            .ag-cell {

                [type="checkbox"]:not(:checked) + label::after,
                [type="checkbox"]:checked + label::after {
                    top: 5px !important;
                }

                [type="checkbox"]:not(:checked) + label::before,
                [type="checkbox"]:checked + label::before {
                    top: 2px !important;
                }
            }
        }

        .investement-bottom-grid-border {
            border-left: 1px solid #616161;
            border-right: 1px solid #616161;
            border-top: 1px solid #616161;
            border-bottom: 1px solid #616161;
            border-radius: 0.5em;
        }

        .select-all-wrapper {
            left: 7px;
            top: -6px;
            position: absolute;
        }
    }

    .worksite-list-footer {
        .customers-tablefoot-actionbar {
            border-top: 1px solid $darkgray;
            margin: 0;

            .button-option {
                width: 22em;

                .add-button {
                    width: 16em;
                }

                .option {
                    width: 19em;
                }

                button {
                    margin-right: 0px;
                    width: auto;

                    &.no-margin {
                        margin-right: 0;
                    }
                }
            }
        }

        .button-option {
            width: 22em;

            .add-button {
                width: 16em;
            }

            .option {
                width: 19em;
            }

            button {
                margin-right: 0px;
                width: auto;

                &.no-margin {
                    margin-right: 0;
                }
            }
        }
    }

    .button-width {
        width: 100%;
    }

    .status-locked {
        float: left;
        /*    background-color: #363545;*/
        background-image: url(../../../../content/images/svg/lock_grey.svg);
        /*    color: #FFFFFF;*/
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .status-open {
        float: left;
        /*background-color: #009AD9;*/
        background-image: url(../../../../content/images/svg/lock_open.svg);
        /*color: #FFFFFF;*/
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .status-cancelled {
        float: left;
        /*background-color: #C81922;*/
        background-image: url(../../../../content/images/svg/cancel.svg);
        /*color: #FFFFFF;*/
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .status-paid {
        float: left;
        /*background-color: #3AAE2C;*/
        background-image: url(../../../../content/images/svg/event_available.svg);
        /* color: #FFFFFF;*/
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .status-extended {
        float: left;
        background-image: url(../../../../content/images/svg/event_extended.svg);
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }
}