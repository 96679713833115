.delivery-destination-basic-details {
    padding: 0px;

    .left-part-content {
        border-right: 1px solid $darkgray
    }

    .border-right {
        border-right: 1px solid $half_trimble_brand_grey;
    }

    .border-bottom {
        border-bottom: 1px solid $half_trimble_brand_grey;
    }

    .padding-left-6 {
        padding-left: 6px;
    }

    .padding-left-40 {
        padding-left: 40px;
    }

    .padding-left-ten {
        padding-left: 10px;
    }

    .no-padding-left {
        padding-left: 0px;
    }

    .padding-top-40 {
        padding-top: 40px;
    }

    .transport-ban-img {
        margin-top: -3px;
    }

    .form-group {
        &.padding-top {
            padding-top: 25px;
        }

        &.padding-left {
            padding-left: 35px;
        }

        &.padding-right {
            padding-right: 25px;
        }

        &.padding-bottom {
            padding-bottom: 25px;
        }

        &.padding-top-40 {
            padding-top: 40px;
        }

        &.padding-bottom-40 {
            padding-bottom: 40px;
        }

        input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    .first-two-elements {
        width: 53%;
        float: left;
    }

    .last-two-elements {
        width: 47%;
        float: right;
    }

    .transport-ban {
        width: 39%;
    }

    .transport-ban-border {
        width: 8%;
        height: 1px;
        float: left;
    }

    .port-storage-calculation {
        width: 48%;
    }
    
    .port-storage-calculation-border {
        height: 1px;
        float: left;
        width: 5%;
    }

    .payment-by-measurementdata {
        width: 62%;
    }

    .payment-by-measurementdata-border {
        width: 7%;
        height: 1px;
        float: left;
    }

    .active {
        width: 31%;
    }

    .margin-top-2 {
        margin-top: -2px;
    }

    .form-control.notes {
        height: 150px;
        max-height: 250px;
        min-height: 150px;
        resize: vertical;
    }

    @media screen and (max-width: 1800px) {
        .payment-by-measurementdata {
            width: 64%;
        }
    
        .payment-by-measurementdata-border {
            width: 5%;
            height: 1px;
            float: left;
        }
    }

    @media screen and (max-width: 1600px) {
        .first-two-elements {
            width: 100%;
        }

        .last-two-elements {
            width: 100%;
            padding-top: 10px;
        }

        .form-group .no-border-rigth-on-zoom {
            border-right: 0px;
        }

        .transport-ban {
            width: 45%;
        }

        .transport-ban-border {
            width: 0%;
            height: 0px;
            float: left;
        }
    
        .port-storage-calculation.border-right {
            width: 50%;
            border-right: 0px;
        }

        .port-storage-calculation-border {
            height: 0px;
            float: left;
            width: 0%;
        }
    
        .payment-by-measurementdata {
            width: 45%;
            padding-left: 40px;
        }

        .payment-by-measurementdata-border {
            height: 0;
            float: left;
            width: 0%;
        }
    
        .active {
            width: 40%;
        }
    }

    @media screen and (max-width: 1300px) {
        .first-two-elements {
            width: 100%;
        }

        .last-two-elements {
            width: 100%;
            padding-top: 10px;
        }

        .transport-ban {
            width: 45%;
        }

        .transport-ban-border {
            width: 0%;
            height: 0px;
            float: left;
        }
    
        .port-storage-calculation.border-right{
            width: 50%;
            border-right: 0px;
        }

        .port-storage-calculation-border {
            height: 0px;
            float: left;
            width: 0%;
        }
    
        .payment-by-measurementdata {
            width: 45%;
            padding-left: 40px;
        }

        .payment-by-measurementdata-border {
            height: 0;
            float: left;
            width: 0%;
        }
    
        .active {
            width: 40%;
        }
    }

    @media screen and (max-width: 1100px) { 
        .first-two-elements {
            width: 45%;
            float: left;
            padding-left: 10px;
        }

        .last-two-elements {
            width: 55%;
            float: right;
            padding-top: 0px;
        }

        .transport-ban {
            width: 51%;
            float: left;
        }
    
        .port-storage-calculation.border-right {
            float: left;
            width: 49%;
            border-right: 1px solid #d1d1d1;
        }
    
        .payment-by-measurementdata {
            width: 57%;
            float: left;
        }
    
        .active {
            width: 40%;
            float: left;
        }
    }

    .material-switch-container {
        float: left;
        margin-right: 15px;

        .material-switch-content>label::before {
            top: 0px
        }
    }

    
    .switch-label {
        padding-top: 0px;
    }

    .contact-persons {
        .edit-contact-person {
            width: 11%;
            height: 27px;
            float: left;
            display: inline-flex;

            .contact-person-icon {
                width: 35%;
                height: 93%;
                padding-top: 2px;
                margin-top: 1px;
                border-radius: 2px;
                cursor: pointer;
            }

            .pen-icon {
                background-size: 100% 100%;                
            }

            .add-contact-person-icon {
                background-color: $trimble_blue_light;
                background-image: url(../../content/images/svg/add-box-24px.svg);
                float: right;
                background-size: 100% 100%;
                border-radius: 0.3em;

                &:hover {
                    background-color: $trimble_blue_mid;
                    color: $trimble_white;
                }
            }

            .button-disabled {
                opacity: 1;
                cursor: no-drop;
                background-color: $trimble_button_disabled
            }
        }
        
        .select-contact-persons {
            width: 89%;
            float: left;

            .contact-persons-header {
                background-color: #d3d3d3;
            }
    
            .custom-button {
                height: 27px;
                padding-top: 4px;
                text-align: left;
                padding-left: 7px;
            }
    
            .dropdown-toggle {
                height: 27px;
                padding-top: 3px;
            }

            .custom-table {
                .table-row {
                    .table-cell {
                        padding-top: 2px;
            
                        &.name-column {
                            width: 33%;
                            padding-left: 5px;
                            padding-top: 4px;
                        }
            
                        &.phone-column {
                            width: 33%;
                            padding-top: 4px;
                        }
            
                        &.email-column {
                            width: 34%;
                            padding-top: 4px;
                        }
                    }
                }
            }           
        }

        @media screen and (max-width: 1800px) {
            .edit-contact-person {
                width: 12%;
            }

            .select-contact-persons {
                width: 88%;
            }
        }

        @media screen and (max-width: 1600px) {
            .edit-contact-person {
                width: 14%;
            }

            .select-contact-persons {
                width: 86%;
            }
        }

        @media screen and (max-width: 1300px) {
            .edit-contact-person {
                width: 17%; 
            }

            .select-contact-persons {
                width: 83%;
            }
        }

        @media screen and (max-width: 1100px) {
            .edit-contact-person {
                width: 10%;
            }

            .select-contact-persons {
                width: 90%;
            }
        }
    }
}

.change-delivery-point-active-status {
    width: 525px !important;

    .alert-message {
        font-family: $font-primary, $font-secondary;
    }
}