﻿.index-map-wrapper {
	width:200%; 
	height:200%; 
	position: absolute;
    overflow: hidden;
    -webkit-transform-origin : 0% 0%;
    -moz-transform-origin : 0% 0%;
    -o-transform-origin : 0% 0%;
    -ms-transform-origin : 0% 0%;
    transform-origin : 0% 0%;
    transform: scale(.5);
    -webkit-transform: scale(.5);
    -moz-transform: scale(.5);
    -ms-transform: scale(.5);
    -o-transform: scale(.5);
}

.map-wrapper {
    overflow: hidden;
    border-radius: 0.5em;
}
    
.index-map {
    &.panel {
        @extend %silva-box;
        position: absolute;
        right: 2.5em;
        top: .1em;
        width: 300px;
        height: 250px;
        z-index: $sp-map-toolbar-z-index;

        &.single-row-toolbar {
            right: calc(2.5em + 35px);
        }
    }

    &.close-button {
        margin-top: .3em;
        margin-right: .4em;
    }

    &.content {
        margin: .4em;
        position: relative;
    }
}