﻿@import '../../../../shared/sass/_variables.scss';
$border: 1px solid #616161;
$text-field-margin-top: 6px;
$header-padding-left-right: 6px;
$header-title-font-family: "Myriad Pro Semibold";
$header-title-font-size: 12px;

.power-of-authorize {
	.text-align-right {
		text-align: right;
	}

	.active {
		@include btn-silva($white, $blue);
		@extend %capital-letters;
	}

	.bottom-button {
		padding: 10px;
		margin-left: 7px;
	}

	.bottom-button:first-child {
		margin-left: 0px;
	}

	.power-of-authorize-header {
		overflow: auto;
		padding: 4px 0px 3px 0px;

		.content-padding {
			padding: 0px $header-padding-left-right 0px;
		}

		.content-padding-right {
			padding-right: $header-padding-left-right;
		}

		.header-title {
			font-family: $header-title-font-family;
			font-size: $header-title-font-size;
		}
	}

	.power-of-authorize-header.first-row {
		background-color: #d1d1d1;
		border-radius: 5px 5px 0px 0px;
		border-top: $border;
		border-left: $border;
		border-right: $border;

		span {
			font-weight: bold;
		}

		span.capitalize {
			text-transform: capitalize;
		}
	}

	.power-of-authorize-header.second-row {
		background-color: $black;
		border-left: $border;
		border-right: $border;
		padding: 9px 6px;

		.col-xs-3 {
			padding-left: 6px;
			padding-right: 0px;
			width: 33.333333333%;
		}

		.col-xs-10 {
			padding: 0px;
		}

		.input-group {
			padding-bottom: 7px;
			padding-top: 7px;
		}

		.btn.btn-silva {
			width: 100%;
		}

		.date-container {
			padding: 0px;

			.form-group.has-feedback {
				padding-right: 1px;
			}

			.form-group {
				padding: 0;

				.form-control {
					background-color: $gray;
					padding-top: 0.5em;
					padding-left: 0.5em;
					height: 33.6px;
					width: 101%;
					font-size: 13px;

					&:focus {
						border: 1px solid $gray;
						box-shadow: none;
					}
				}

				.form-control-feedback {
					width: 36px;
					right: 3px;
					border-left: solid 1px $black;
					padding-left: 3px;
				}

				img {
					width: 26px;
					height: 24px;
					padding-bottom: 2px;
				}
			}
		}
	}

	.col-half-offset {
		margin-left: 3.83981%;
	}

	.input-group-addon {
		padding: 0px 12px;
	}

	.power-of-authorize-content {
		overflow: auto;
		border-left: $border;
		border-right: $border;
		background-color: #fff;
		font-size: 12px;

		.text-field-align {
			padding-right: 5px;
			padding-bottom: 2px;
			padding-top: 6px;
			margin-top: 0px !important;
		}

		.no-margin-padding {
			margin: 0px;
			padding: 0px;
		}

		.left-border {
			margin-left: -20px;
			border-left: $border;
		}

		.left-side {
			border-right: $border;
		}

		.text-field-disabled {
			background-color: #eee;
		}

		.rb-horizontal-line {
			padding-left: 0px;
			margin-left: -10px !important;
			margin-bottom: 0px !important;
			width: 103.2%;
		}

		.subheader {
			background-color: black;
			color: white;
			padding-top: 5px;
			padding-bottom: 5px;
			padding-left: 7px;
		}

		.grey-subheader {
			background-color: #d3d3d3;
		}

		.checkbox-align {
			margin-top: 7px;
			margin-left: 3px;
		}

		.border-bottom {
			border-bottom: 1px solid #d1d1d1;
			padding-right: 0px;
		}

		.subheader:after {
			margin-top: 3px;
			border-top: 10px solid #000;
			border-left: 6.5px solid transparent;
			border-right: 6.5px solid transparent;
			bottom: 0px;
			content: "";
			position: absolute;
			left: 1.5%;
			top: 24.5px;
			width: 0;
			height: 0;
		}

		.grey-subheader:after {
			border-top: 10px solid #d3d3d3;
		}

		.input-text-no-borders {
			border: none;
			border-color: transparent;
			resize: none;
			width: 100%;
			overflow: hidden;
			margin: 0px;
			background: transparent;
			padding-left: 5px;
		}

		.input-text-no-borders:focus {
			outline: none;
		}

		.subheader-first-text {
			padding-top: 13px;
		}
	}

	.grid-footer {
		border-top: $border !important;
		padding: 0px;

		label {
			font-style: italic;
			font-weight: lighter;
			padding-left: 12px;
			padding-bottom: 11px;
			padding-top: 11px;
			margin-bottom: 0px;
			font-size: 13px;
			color: #616161;
		}
	}

	.power-of-attorney-footer-buttons {
		margin: 10px 0px;
	}
}
