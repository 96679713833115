.tabs-wrapper {
    position: relative;

    .tab-container {
        position: relative;
        top: 1px; // fix the container so it overlaps the content creating a border effect
        .tab {
            display: inline-block;
            text-align: center;
            text-transform: uppercase;
            font-family: $font-semibold;
            margin: 0 0.2em 0 0.2em;
            padding: 0.2em 0 0.2em 0;
            border: 1px solid $darkgray;
            border-bottom: 0px;
            cursor: pointer;
            border-radius: 0.5em 0.5em 0 0;
            background: $gray;

            .customer-info & {
                border-bottom: 1px solid $darkgray;
            }

            &.selected {
                background: $white;
                border-bottom: 1px solid white;

                &:hover {
                    background: inherit;
                    color: inherit;
                    border-bottom: 1px solid white;

                    img {
                        filter: brightness(1);
                    }
                }
            }

            &.notSelected {
                img {
                    filter: brightness(0);
                }
            }

            &:hover {
                background: black;
                color: white;

                img {
                    filter: invert(1) grayscale(4) contrast(10);
                }
            }

            &.tabDisabled {
                pointer-events: none;
                opacity: 0.5;
            }

            &.tabDisabledSpecialDesign {
                pointer-events: fill;
                cursor: not-allowed;
                color: black;

                &:hover {
                    background: #d1d1d1;

                    img {
                        filter: invert(1) grayscale(4) contrast(10);
                    }
                }
            }

            .tabicon {
                padding-bottom: 1px;
            }
        }
    }

    .notSelectedConstantTab {
        display: none;
    }

    .tabs-content {
        border-right: 1px solid $darkgray;
        border-left: 1px solid $darkgray;
        border-bottom: 1px solid $darkgray;
        margin: 0 0.2em 0 0.2em;
        border-radius: 0.3em;
        padding: 1em;

        .customer-info & {
            border-radius: 0 0 0.3em 0.3em;
        }

        &:empty {
            display: none;
        }
    }

    .tab-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
    
        .tab-title {
            width: 90%;
        }
    
        .missing-info-warning {
            width: 10%;
            margin-right: 5px;
        }

        .missing-compulsory-info-content {
            height: 19px;
            width: 19px;
        }

        .missing-title {
            padding-top: 2px;
            font-size: 13px;
            height: 19px;
        }
    }
}
