@import "../../shared/sass/_variables.scss";
@import '../../shared/sass/sp-panel.scss';

/**
 *   Filter dropdown used to narrod down search ("rajaa hakua")
 *   Used e.g. /workingSites/inventoryStatusesReleaseToTransport
 */
.advanced-filtering-options {
    border: 1px solid $gray;
    border-radius: $sp-panel-border-radius;
    height: 34px;
    margin-right: 7px;
    padding-top: 7px;
    padding-left: 5px;
    padding-right: 5px;
    width: 190px;
    background: #fff;

    .filter-type {
        .col-xs-12 {
            padding-left: 0px;
            padding-right: 0px;
        }
    }


    input {
        border-width: 1px;
        border-style: solid;
        border-color: $gray;
    }

    .advanced-filtering-options-checkbox {
        top: -9px !important;

        &:hover {
            cursor: default;
        }
    }

    .advanced-filtering-options-title {
        margin-left: -5px;

        &:hover {
            cursor: pointer;
        }
    }

    .glyphicon-triangle-bottom {
        margin-left: 5px;

        &:hover {
            cursor: pointer;
        }
    }

    .advanced-filtering-dropdown {
        position: absolute;
        z-index: 1;
        line-height: 34px !important;

        .dropdown-content {
            // width: 620px;   // override per content
            // height: 388px;  // override per content
            background-color: white;
            border: 1px solid $gray;
            border-radius: $sp-panel-border-radius;
            margin-left: -6px;
            margin-top: 5px;
            z-index: 1;
            display: flex;

            input:placeholder-shown {
                font-style: italic;
            }

            .filter-panel {
                width: 90%;
            }

            .buttons-wrapper {
                width: 10%;

                .btn {
                    height: 50%;
                    width: 100%;
                }

                .dropdown-search-button {
                    height: 100%;
                    border-radius: 0px 4px 4px 0px;
                }
            }

            .dropdown-table {
                width: 100%;
                height: -webkit-fill-available;

                .border-right {
                    border-right: 1px solid $gray;
                }

                .border-bottom {
                    border-bottom: 1px solid $gray;
                }

                .border-left {
                    border-left: 1px solid $gray;
                }

                tr:last-of-type {
                    td {
                        border-bottom: none;
                    }
                }

                tr {
                    td.border-right {
                        width: 50%;
                    }
                }

                .table-cell {
                    padding: 10px;

                    .multiselection-drop-down {
                        .titleBox {
                            width: 100%;
                        }

                        .checkboxes {
                            width: 232px;
                        }

                        .checkboxesList {
                            width: calc(100% - 10px);
                        }
                    }

                    .display-inline {
                        display: inline-block;
                    }

                    .dropdown-date-input {
                        width: 125px;
                        height: 30px;
                        border-radius: 5px;
                    }

                    .multiselection-height {
                        line-height: 34px;

                        .remove-triger-buttons {
                            margin-top: 1px;
                            margin-right: 0;
                        }
                    }

                    .arrow-class {
                        cursor: not-allowed;
                        user-select: none;
                        content: url("../../../../content/images/svg/material-expand_more_black.svg");
                        color: black;
                        display: inline-block;
                        width: 7%;
                        margin-right: 5px;
                        opacity: 50%;
                    }
                }

                .table-cell-smaller-padding-top {
                    padding-top: 2px;
                }

            }

        }
    }
}