﻿@import '../../shared/sass/_variables.scss';

.product-quality {
    font-family: $font-family;
    font-size: $default-font-size;

    button {
        border: 1px solid $darkgray;
        background-color: inherit;
        cursor: pointer;
    }

    .no-right-border {
        border-right-style: none
    }

    .invalid {
        background-color: $lightred;
    }

    button:disabled {
        background-color: $lightgray;
        cursor: not-allowed;
    }

    .btn-group {
        width: 100%;

        .custom-button {
            border: 1px solid $field-border-color;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-right: none;
            text-align: left;
            width: calc(100% - 26px);
            overflow: hidden;

            @media screen and (max-width: 1300px) {
                border: 1px solid $field-border-color;
                border-right: none;
            }
        }

        .dropdown-toggle {
            width: 26px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border: 1px solid $field-border-color;
            box-shadow: none;
            padding: 0px 4px 0px 0px !important;

            .dropdown-toggle-arrow {
                background-image: url("../../../content/images/svg/material-expand_more_black.svg");
                background-repeat: no-repeat;
                background-size: 20px;
                padding: 0px 5px;
                background-position: center;
            }
        }
    }

    .is-disabled {
        background-color: $disabled-button-background-color;
        cursor: not-allowed;
        pointer-events: none;

        button[disabled] {
            background-color: $disabled-button-background-color;
        }
    }

    .dropdown-menu {
        width: 100%;
        cursor: pointer;

        .dropdown-table {
            width: 100%;
            line-height: 20px;

            tr:hover {
                background-color: $black;
                color: $white;
            }

            .default-column {
                [type="checkbox"]:not(:checked)+label:before,
                [type="checkbox"]:checked+label:before {
                   content: '';
                   display: none;
                }
   
               [type="checkbox"]:not(:checked)+label:after, 
               [type="checkbox"]:checked+label:after {
                    content: '';
                    display: none;
               }

                label:hover:before {
                    border-color: $black !important;
               }   
               
               .product-modal-label  {
                    background-image: url("../../../content/images/svg/material-radio_button_unchecked.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: inherit;
                    background-size: 20px;
                    top: 0px;
                    margin: 0px;

                    &:hover {
                        background-image: url("../../../content/images/svg/material-radio_button_unchecked_hover.svg");
                    }

                    &.is-default {
                        background-image: url("../../../content/images/svg/material-radio_button_checked.svg");
                    }

                    &.is-disabled {
                        opacity: 0.5;
                    }
               }
            }
        }

        tr:nth-child(even) {
            background: $trimble_very_light_grey;
        }

        tr:nth-child(odd) {
            background: $trimble_white;
        }

        .checkbox-column {
            width: 25%;
        }

        .name-column {
            width: 40%;
        }

        .default-column {
            width: 35%;
        }

        .quality-header {
            text-transform: uppercase;
            text-align: center;
        }

        .round-cb {
            [type="checkbox"]:not(:checked) + label:before,
            [type="checkbox"]:checked + label:before {
                border-radius: 13px;
                top: 10px;
            }
        }

        .custom-table {
            display: block;
            width: 100%;

            .table-row {
                display: block;
                width: 100%;

                .table-cell {
                    display: inline-block;
                    height: 25px;
                    padding: 0px;
                    vertical-align: middle;
                    text-overflow: ellipsis;
                }

                .flag-border {
                    border: 1px solid #d1d1d1;
                }
            }

            .table-row:not(.table-header):hover {
                background-color: $gray;
            }

            .table-body {
                display: block;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
            }

            .table-cell-row {
                display: block;
                width: 100%;
                padding: 0px;
            }
        }
    }
}
