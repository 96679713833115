﻿@import '../../../../../../shared/sass/_variables.scss';

.new-product-row-modal {

    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;

    .small-font {
        font-size: 13px;
    }


    .disabled-background {
        background-color: $disabled-background-color;
    }


    .execute-button {
        margin-left: 5px;
    }

    .radio-group-product-type {
        margin-top: 15px;
        margin-left: -30px;
        padding-top: 3px;

        label:first-of-type {
            margin-right: 20px;
            margin-left: -10px;
            padding-right: 10px;
        }

        label:nth-of-type(2) {
            margin-right: 30px;
        }

        .lisa-label {
            margin-right: 10px;
        }

        .radio-label {
            padding-right: 10px;

            input[type=radio] {
                margin-top: 2px;
                position: absolute;
            }
        }
    }


    .lower-part {
        margin-left: 5px;
        margin-right: 5px;
    }

    .lower-part-compartments {
        margin-left: 5px;
        margin-right: 5px;
    }


    .ag-theme-fresh {
        .ag-root {
            font-family: $font-family;
        }
    }

    .sp-grid {
        .ag-root {
            border-radius: 0px;
        }

        .ag-row-selected, .ag-row:hover {
            input {
                color: white !important;
            }
        }
    }

    .overwrite-hover {
        .ag-row-hover {
            background-color: $disabled-background-color;
            color: $black;

            .ag-cell {
                background-color: $disabled-background-color !important;
                color: $black !important;
            }
        }
    }

    .sp-grid .ag-root .ag-header {
        font-size: 14px !important;
    }

    .customers-joined-members-grid .ag-root, .customers-table.sp-grid .ag-root, .upkeep-grid .ag-root {
        font-size: 14px;
    }

    .sale-type-button {
        width: 100%;
        margin-top: 10px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .search-area {
        display: flex;

        .search-bar {
            display: flex;
            flex-grow: 6;
            border: 1px solid $gray;
            border-radius: 0.5em;
            height: 2em;
            line-height: 2em;
            overflow: hidden;
            padding: 0 0.4em;
            margin: 1em 1em 0 1em;

            span.glyphicon-search,
            span.glyphicon-remove {
                top: 5px;
            }

            input {
                border: 0;
                height: 2em;
                outline: 0;
                padding-left: 0.2em;
                width: 100%;
            }
        }

        .filtering-bar {
            align-self: flex-end;
            display: flex;
            flex-basis: 5em;
            flex-grow: 1;

            label {
                &:before {
                    top: 0;
                }

                &:after {
                    top: 4px;
                }
            }
        }
    }

    .grid-container {
        margin: 1em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            height: 319px;

            #centerRow {
                border-radius: 0.5em !important;
            }

            .ag-header-group-cell-label {
                text-transform: uppercase;
            }
        }
    }

    .grid-container-worksite-compartments-select {
        margin: 1em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            height: 319px;

            #centerRow {
                border-radius: 0.5em !important;
            }

            .ag-header-group-cell-label {
                text-transform: uppercase;
                margin-left: -5px;
                text-align: left;
            }

            .select-all-wrapper {
                margin-left: -2px;
            }
        }
    }

    .grid-container-worksite-compartments {
        margin: 1em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            height: 200px;

            #centerRow {
                border-radius: 0.5em !important;
            }

            .ag-header-group-cell-label {
                text-transform: uppercase;
                margin-left: -5px;
            }
        }
    }

    .implementation-date {
        font-family: $font-semibold;
        margin: 10px;
        position: relative;
        width: auto;
        margin-left: 15px;
        margin-bottom: 13px;

        input {
            margin-left: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            outline: 0;
            padding-left: 0.2em;
            flex-grow: 6;
            border: 1px solid $gray;
            border-radius: 0.5em;
            line-height: 2em;
            overflow: hidden;
            padding: 0 0.4em;
            height: 2em;
        }
    }

    .form-group {
        padding: 0;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .sp-grid {

        .grid-check-box {
            top: -9px;
        }
    }

    .ag-body-viewport .ag-body-container .ag-row-selected .ag-cell {
        color: white !important;
        background-color: black !important;
    }

    .modal-dialog {
        position: relative;
        width: 90% !important;
    }

    .customers-table {
        .checkbox-cell {
            cursor: initial;

            input {
                pointer-events: initial;
            }
        }

        .ag-row-group .checkbox-cell {
            display: none;
        }

        .ag-row .ag-selection-checkbox {
            position: static;
            margin-left: 3px;
        }

        .unselect-control {
            padding-left: 6px;
            padding-top: 3px;

            [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                top: -10px;
            }
        }
    }

    .center-notes-icon {
        margin-left: -1px;
    }
}
