﻿@import '../../shared/sass/_variables.scss';
$add-worksite-compartment-area-margins: 10px;
$add-worksite-compartment-modal-width: 500px;

.add-worksite-compartment-modal .modal-dialog {
    width: $add-worksite-compartment-modal-width;

    .add-worsite-compartment {
        font-family: $font-family;
        font-size: $default-font-size;

        .modal-content {
            .title-bar {
                > div:not(.clearfix) {
                    padding: 1em;
                }

                border-bottom: solid 1px $gray;

                .header {
                    font-family: $font-semibold;
                    margin: 0;
                    position: relative;
                    text-transform: uppercase;
                    width: auto;
                }

                .glyphicon-remove:hover {
                    color: $blue;
                    cursor: pointer;
                }
            }

            .modal-body {
                padding: 0px 15px 0px 15px;

                .search-bar {
                    border: 1px solid $gray;
                    border-radius: 0.5em;
                    height: 2em;
                    line-height: 2em;
                    overflow: hidden;
                    padding: 0 0.4em;
                    margin: 1.5em 0;

                    input {
                        border: 0;
                        height: 2em;
                        outline: 0;
                        padding-left: 0.2em;
                        width: 92%;

                        &:placeholder-shown {
                            font-style: italic;
                        }
                    }
                    span.glyphicon-remove {
                        margin-right: 0px;
                    }
                }                
            }

            .modal-footer {
                .btn {
                    width: 100%;
                }
            }
        }
        .search-bar {
            border: 1px solid $gray;
            border-radius: 0.5em;
            height: 2em;
            line-height: 2em;
            overflow: hidden;
            padding: 0 0.4em;

            input {
                border: 0;
                height: 2em;
                outline: 0;
                padding-left: 0.2em;
                width: 88%;
            }

            span.glyphicon-remove {
                margin-right: 0px;
            }
        }
    }
}
