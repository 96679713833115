@import "../../../../shared/sass/_variables.scss";

.body-third-row-container {
    margin-bottom: 10px !important;
}

.harvesting-area-storage-amounts {

    .input-border {
        select {
            border: 1px solid #616161 !important;
        }
    }

    .sp-grid {
        border-radius: 0;
    }

    .grid-edit-modal {
        top: calc(100vh - 420px);
        width: calc(100vw - 75px);
        font-size: 14px !important;
        left: 30px;

        .the-body .body-column label {
            text-align: center;
            padding-right: 16px !important;
            padding-left: 0px !important;
        }

        .the-body > .body-column > input {
            margin-top: 1px !important;
        }

        .width-3 {
            width: 32px !important;
        }

        .padding-righ-em {
            padding-right: 1em !important;
        }

        .margin-fix {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }

        .label-checkbox {
            border-width: 0px !important;
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: textfield;
            margin: 0;
        }

        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
            display: none !important;
        }

        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
            content: '';
            width: 18px;
            height: 18px;
            border-radius: 2px;
            background-color: $trimble_white;
            background-image: url(../../../content/images/svg/Checkbox_default_enabled_unselected_18x18px.svg);
            background-size: 18px;
            position: absolute;
            left: auto;
            top: 3px;
        }

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
            content: '';
            position: absolute;
            top: 3px;
            left: auto;
            line-height: 0.8;
            width: 18px;
            height: 18px;
            background-image: url(../../../content/images/svg/Checkbox_default_enabled_selected_18x18px.svg);
            background-size: 18px;
            margin-left: -3px;
        }

        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
            width: 18px;
            height: 18px;
            background-image: url(../../../content/images/svg/Checkbox_default_disabled_unselected_18x18px.svg);
            background-size: 18px;
        }

        .modal-trimble-title {
            margin-left: 20px !important;
        }

        .header-row {
            .the-header {
                .header-column {
                    padding-left: 0px !important;

                    label {
                        width: 100% !important;
                    }
                }

                .first-column {
                    display: block;
                    border-right: 1px solid $trimble_white;
                }

                .second-header-column {
                    border-left: 1px solid $trimble_white;
                }
            }
        }

        .header-band-column {
            border-bottom: 1px solid #f6f6f6 !important;
            margin-bottom: 0px !important;
            text-align: center;
        }

        .vertical-align {
            vertical-align: middle !important;
        }

        .horizontal-align {
            display: block !important;
            margin: 0 auto !important;
        }

        .editing-modal-checkbox {
            position: initial;
            width: 15px !important;
        }

        .body-second-row-container {
            margin-bottom: 9px;
        }

        .second-header {
            margin-top: 9px !important;
        }

        input.date-format {
            border-radius: 4px !important;
            border-style: solid !important;
            border-color: #616161 !important;
            border-width: 1px !important;
        }

        input#harvestingDate.date-format {
            width: 99%;
            height: 26px;
        }

        .buttons-row .permanent-buttons {
            display: table;
            float: right;
            margin-right: 10px;
        }

        .body-column {
            padding-top: 4px;
            padding-bottom: 6px;
        }

        .body-row {
            .the-body {
                .body-column {

                    button,
                    input,
                    select,
                    label {
                        height: 26px !important;
                    }

                    .edit-down-options {
                        .btn.dropdown-toggle {
                            width: 10%;
                            padding-left: 0px;
                            margin-left: 0px;
                        }

                        .btn.custom-button {
                            text-align: left;
                            margin-right: 0;
                        }

                        .drop-down-table {
                            padding-left: 5px;
                        }
                    }
                }

                .first-column {
                    display: block;
                    border-right: none;
                }

                .second-body-column {
                    border-left: 1px solid #d1d1d1;
                }
            }
        }

        .body-row .the-body {
            padding: 0px 14px;
        }

        .body-row .the-body .body-column {
            border-right: 1px solid #d1d1d1;
        }

        .body-row .the-body .body-column .no-border-right {
            border-right: 0px !important;
        }

        .buttons-row .commands-buttons .commands-container:first-child {
            padding-left: 20px;
        }

        .no-delivery-contracts-width-175 {
            width: 175px;
        }

        .no-delivery-contracts-width-308 {
            width: 308px;
        }

        .no-delivery-contracts-width-309 {
            width: 309px;
        }

        .no-delivery-contracts-width-60 {
            width: 60px;
        }

        .no-delivery-contracts-width-61 {
            width: 61px;
        }

        .no-delivery-contracts-width-120 {
            width: 120px;
        }

        .no-delivery-contracts-width-221 {
            width: 221px;
        }

        .remaining-width {
            width: 243px;
        }

        .width-additional {
            width: 144px;
        }

        .width-measurement {
            width: 230px;
        }
    }

    .center {
        text-align: center;
    }

    .header-ok > label {
        margin: 0px !important;
        margin-top: 2px !important;
    }

    .reload-button {
        display: none;
        font-size: 18px;
        color: $darkgray;
        float: right;

        &:hover {
            color: $trimble_tf_product_green;
            cursor: pointer;
        }
    }

    .harvesting-area-grid {
        .ag-header-group-cell-label {
            border: none;
            text-transform: uppercase;
        }

        .common-grid {
            border-radius: 0px;

            .sp-grid .ag-root-wrapper .ag-root-wrapper-body .ag-root .ag-header .ag-header-row .ag-header-cell {
                border-color: darkgray;
            }

            .panel-sp-grid > .panel-heading {
                border-bottom: 1px solid;
                border-color: darkgray;
            }

            .ag-body-container {

                .ag-row-focus,
                .ag-row-hover,
                .ag-row-selected {
                    .select-picker-drop-down .dropdown.bootstrap-select.invalid {

                        .btn.dropdown-toggle,
                        .filter-option,
                        .filter-option-inner-inner,
                        .caret {
                            background-color: $black !important;
                            color: $white !important;
                        }
                    }
                }
            }
        }

        .common-grid-toolbar {
            margin-bottom: 1em;

            .color-definition-bar {
                padding-left: 0px;
                padding-right: 10px;

                .definition-item {
                    padding-right: 10px;

                    #green {
                        width: 15px;
                        height: 15px;
                        background: #82C558;
                    }

                    #orange {
                        width: 15px;
                        height: 15px;
                        background: #F68B1F;
                    }

                    #blue {
                        width: 15px;
                        height: 15px;
                        background: #00ADEE;
                    }

                    .item-icon {
                        padding-left: 15px;
                    }

                    .item-image {
                        padding-left: 0px;

                        .image {
                            height: 20px;
                            margin-top: -2px;
                        }
                    }

                    .item-text {
                        padding-left: 5px;
                        font-weight: bold;
                    }
                }
            }
        }

        .common-grid-grid {
            height: 498px !important;

            .ag-header {
                background: linear-gradient(rgba(0.075, 0.075, 0.075, 0.15), #d3d3d3);
            }

            .ag-root {
                border-radius: 0 0 0 0; //Override ag-grid default
            }

            [type="checkbox"]:not(:checked) + label,
            [type="checkbox"]:checked + label {
                right: -2px;
                bottom: 7px;
            }

            .common-grid-selection-header-cell {
                padding-left: 5px !important;
            }
        }

        .green {
            background-color: #82C558;
            text-align: center;
        }

        .orange {
            background-color: #F68B1F;
            text-align: center;
        }

        .blue {
            background-color: #00ADEE;
            text-align: center;
        }

        .image {
            height: 20px;
            margin-top: 3px;
        }

        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
            width: 18px;
            height: 18px;
            background-image: url(../../../content/images/svg/Checkbox_default_disabled_unselected_18x18px.svg);
            background-size: 18px;
        }

        [type="checkbox"]:disabled + label {
            color: #aaa;
        }

        .ban-image {
            height: 20px;
        }

        .lock-closed-icon {
            margin: 0 auto;
            width: 29px;
            height: 16px;
            display: block;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .timber-truck-icon {
            margin: 0 auto;
            width: 29px;
            height: 16px;
            display: block;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("/content/images/svg/TimberTruck_Green.svg");
            padding: 12px;
        }

        .timber-truck-icon-empty {
            margin: 0 auto;
            width: 29px;
            height: 16px;
            display: block;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("/content/images/svg/TimberTruck_Empty_Grey.svg");
        }

        .ag-row-hover .ag-column-hover .tooltips .timber-truck-icon {
            background-image: url("/content/images/svg/TimberTruck_Blue.svg");
        }


        .dropdown-menu {
            color: $black;
            margin: auto;
        }

        .dropdown-menu > * {
            margin: 5px;
        }

        .info-button {
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(/content/images/svg/TransferStorageDocumentInfoButton_LimeGreen.svg);
            //background-image: url(../../content/images/svg/information.svg);
            cursor: pointer;
            margin: auto;
            display: block;
            height: 19px;
            width: 19px;

            &.disabled {
                background-image: url(/content/images/svg/TransferStorageDocumentInfoButton_Grey.svg);
                cursor: default;
            }

            &:hover {
                background-image: url(/content/images/svg/TransferStorageDocumentInfoButton_Blue.svg);
            }
        }

        .info-button-disabled {
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(/content/images/svg/TransferStorageDocumentInfoButton_Grey.svg);
            margin: auto;
            display: block;
            height: 19px;
            width: 19px;
        }

        .is-disabled {
            background-color: #e8e8e8 !important;
        }

        .output-list-button-icon {
            background-color: $trimble_blue_light;
            background-image: url(/content/images/svg/OutputListIcon_white.svg);
            background-size: 14px 19px;
            border-radius: 0.3em;
            cursor: pointer;
            margin: auto;
            display: block;
            height: 19px;
            width: 20px;
            background-repeat: no-repeat;
            background-position: right 3px center;

            &.black {
                background-color: $black;
                color: $trimble_white;
                cursor: default;
            }

            &.disabled {
                background-color: $trimble_blue_light;
                color: $trimble_white;
                cursor: default;
                opacity: 0.5;
            }

            &:hover:not(.black) {
                background-color: $trimble_blue_mid;
                color: $trimble_white;
            }
        }

        .background-color-orange {
            background-color: $trimble_forestry_orange;            
        }

        [col-id=auto] {
            cursor: pointer;
            padding: 0;
        }

        .tooltips {
            height: 27px;
        }
    }
}
