﻿@import 'shared/sass/_variables.scss';

//override container globally
.container {
    padding-right: 0px;
    padding-left: 0px;
}

.main-view-container {
    & .page-content {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 15px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

//page bottom is 30 px margin. can not be included in page-content as it can not be overridden nor corrected in map views
.page-bottom {
    margin-bottom: 30px;
}


//map pages should have only 15 px margins. this correction is temporary, while refactoring we should consider useing a global margin for all the pages
.map-page {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-top: -15px;
}

.partial {
    margin: 0px;
}

/*we use a lot of cases where we don't want column marginals*/
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6,
.col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6,
.col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6,
.col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    &.no-padding {
        padding: 0;
    }

    &.no-left-right {
        padding-left: 0px;
        padding-right: 0px;
    }

    &.no-top-bottom {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

/*corrections for contents where bootstrap columns need 15 px distance inbetween, and also need to contain the same size of content, in our case buttons*/
.large-btn-container-corrector {
    width: calc(100% + 15px);
    margin-left: -7.5px;
    margin-top: -7.5px;
}

@media (max-width: $media-desktop) {
    .large-btn-container-corrector {
        width: 100%;
        margin-left: 0px;
    }
}

/*link blocks are used for large navigation buttons. distance should be 15 px inbetween. recommended some em value. originally we used 0.5 em with font size 14*/
.link-block {
    .col-lg-2 {
        padding: 7.5px;
        display: inline;
    }
}