﻿@import '../../../../shared/sass/_variables.scss';

.timber-buyer-report-dialog-window-class {
    /*z-index: 1000 !important;*/
    .modal-dialog {
        top: 26%;
        width: 630px;
        height: 250px;
    }

    .modal-trimble-button-container {
        margin-top: 0;
    }
}

.timber-buyer-report-base-containers {
    .error {
        color: red;
    }

    .date-picker-padding {
        padding: 20px 30px 20px 30px;
    }

    .p-20 {
        padding: 20px;
    }

    .margin-right-15 {
        margin-right: 15px;
    }

    .horizontal-line {
        border-top: 0.5px solid #B0AFB0;
        margin-top: 20px;
    }

    .left-padding-l {
        padding-left: 15px !important;
    }

    .top-margin {
        margin-top: 5px;
        margin-bottom: 0px !important;
    }

    .fine-border {
        border: 1px solid #ccc;
    }

    .pdf-btn {
        margin-right: 0.5em;
    }

    .date-padding.date-custom-width {
        width: 161px;
    }

    div.date-picker {
        padding: 0 0 0 20px;
    }

    bottom-padding-allowed {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 15px !important;
    }

    .group {
        display: flex;
    }

    .pull-left-l {
        right: 13px;
    }

    .weekpicker-input {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 0 !important;
    }

    .weekpicker-btn {
        background-color: white;
        border: 1px solid #ccc;
        border-left: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 9px 4px 2px 7px;

        .calendar-icon {
            cursor: pointer;
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            background: url("/content/images/svg/calendar_today_black.svg") no-repeat;
            padding: 6px 7px 6px 11px;
            background-size: 16px
        }
    }

    .align-right {
        padding-left: 6px !important;
    }

    .text-align-vertically {
        padding-bottom: 5px !important;
    }
}
