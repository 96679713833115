﻿@import '../../../shared/sass/_variables.scss';


.alert-window-template-modal {

    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;

    .modal-trimble-title {
        line-height: unset;
    }

    table {

        tbody {
            tr {
                height: 30px;

                .modal-trimble-close {
                    margin-top: 0px;
                }

                td {
                    padding: 0px 5px 0px 5px;
                    font-family: $font-semibold;
                    margin-bottom: 13px;
                    margin-top: 14px;
                }

                .first-column {
                    width: 86%;
                    text-transform: uppercase;
                }

                .second-column {
                    width: 1%;

                    .critical-message {
                        background-size: 7px;
                        width: 15px;
                        height: 25px;
                        background-repeat: no-repeat;
                    }

                    .critical-message-icon {
                        background-image: url(../content/images/svg/CriticalNotificationSymbol_Orange.svg);
                    }
                }

                .third-column {
                    width: 12%;

                    div {
                        text-align: center;
                    }
                }

                .fourth-column {
                    width: 1%;
                }
            }
        }
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .middle-part {
        height: 300px;
        border-top: 1px solid $gray;

        div:first-child {
            height: 90%;
            display: block;
            overflow-x: hidden;
            overflow-y: auto;
            margin: 20px 20px 20px 20px;
        }
    }

    .image-container {
        background-position: 90% bottom;
        background-repeat: no-repeat;
    }

    .error {
        @extend .image-container;
        background-image: url(../content/images/svg/ErrorNotification_background.svg);
    }

    .fix_completed {
        @extend .image-container;
        background-image: url(../content/images/svg/FixCompletedNotification_background.svg);
    }

    .service_break {
        @extend .image-container;
        //to do
        background-image: url(../content/images/svg/GearWheels_background.svg);
    }

    .software_update {
        @extend .image-container;
        background-image: url(../content/images/svg/Update_background.svg);
    }

    .bulletin {
        @extend .image-container;
        background-image: url(../content/images/svg/Bulletin_background.svg);
        background-size: 180px;
    }

    .form-group {
        padding: 0;
    }

    .lower-part {
        margin-left: 5px;
        margin-right: 5px;

        .ok-btn {
            width: 100%;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}
