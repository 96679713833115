﻿.plots-tab {
	.upper-part {
		margin-left: -8px;
        margin-bottom: 12px;
		.silva-search {
			 margin-left: 7px;
		 }
		.search{
			border: none !important;
		}
		.glyphicon-remove{
			margin-top: 4px;
		}
	}

	.grid {
		.grid-border {
            border-left: 1px solid #616161;
            border-right: 1px solid #616161;
            border-top: 1px solid #616161;
            border-top-right-radius: 0.5em;
            border-top-left-radius: 0.5em;
            .grid-header-checkbox-label {
                margin: 0;
                left: -4px;
                top: 2px;
            }
            .ag-floating-bottom-container {
                font-size: 12px;
                font-weight: bold;
            }
            .ag-cell {
                [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                    top: 2px !important;
                }
                [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                    top: 2px !important;
                }
            }
            .sp-grid {
				.ag-header-group-cell-label {
					text-align: left !important;
				}
                .cell-aligned-left {
                    .ag-header-group-cell-label {
                        text-align: left;
                        padding-left: 2px;
						padding-right: 2px;
                    }
                }
				.ag-cell-no-focus {
					.ag-selection-checkbox {
						padding-left: 2.3px;
					}
				}
                .plot-resources p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    text-align: left;
                    margin-bottom: 0;
                    white-space: nowrap;
                    width: 125px;
                }
                .ag-row-focus,
                .ag-row-hover {
                    .date-input-for-grid {
                        color: white !important;
                    }
                }
            }
        }
	}

	.buttons-section {
		 .footer-section {
			height: 50px;
            margin: 0px;
            border: 1px solid #616161;
            border-radius: 0.5em;
            background-color: #e8e8e8;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            overflow: hidden;
            padding: 0.5em 0.5em;
        }
		 .medium {
			 width:170px;
		 }
		 .margin {
			 margin-left: 6px;
		 }
	}
}