﻿@import '../../../../shared/sass/_variables.scss';

.lisa-kuviotietoja-modal {
    .realestate.container {
        padding: 0;
        margin: 0;

        .stand-special-features-tab {
            height: 450px;
        }
    }

    .sub-title {
        padding: 10px;
        text-transform: none !important;
    }

    .header-full-width {
        width: 100%;
    }

    .lisa-kuviotietoja-accordion-container {
        width: 100%;

        .stand-accordion {

            .panel-default {
                border-bottom: 1px solid #d1d1d1 !important;
            }

            .panel-heading {
                color: #333;
                background-color: $white;
                border-color: $white;
                height: 38px;

                .panel-title {
                    padding: 10px;
                }
            }
        }

        .panel-open {

            .glyphicon-play {
                transform: rotate(90deg);
            }
        }

        .panel-open, .panel-default:hover {

            .panel-heading {
                background-color: $black !important;
                color: $white !important;

                .glyphicon {
                    color: $white !important;
                }

                .panel-title {
                    padding: 10px;

                    .accordion-toggle {
                        color: $white !important;
                    }
                }
            }
        }

        .uib-accordion {
            width: 100%;
        }

        .modal-controls-container {
            padding: 10px 30px !important;
        }
    }

    .glyphicon-play {
        margin-right: 2px;
    }

    .form-group {
        width: 100%;
        margin-left: 0px !important;

        .bottom-buttons {
            width: 100%;
        }
    }

    .silviculture-list, .special-features-list {
        width: 100%;
        height: 400px;
        overflow-y: auto;
    }

    .partial-box-header {
        padding-left: 30px;
    }

    .sub-header {
        padding: 1em;
        font-style: italic;
        font-family: $font-primary,$font-secondary;
    }

    .modal-trimble-button-container {
        border-top: 0px;
    }
}
