﻿@import '../../../shared/sass/_variables.scss';

.change-status-type-modal {
    padding-bottom: 0px;
    .app-container-secondary-content {
        &.first {
            height: 210px;
        }
    }

    .additional-information {
        background-color: $lightgray;
        font-style: italic;
        padding: 1em;

        &.top {
            background-color: $white;
        }
    }

    w-100 {
        width: 100% !important;
    }

    .app-container-footer {
        border: none;
        height: 7em;
    }

    .border-left {
        border-left: 0.5px solid $half_trimble_brand_grey;
    }
}
