@import '../../shared/sass/_variables.scss';
@import "../../components/customers/components/realEstateTabs/measuresTab";
@import "../../components/customers/components/realEstateTabs/woodsTab";
@import "../../components/customers/components/realEstateTabs/suggestedOperationsTab";
@import "../../components/customers/components/realEstateTabs/completedOperationsTab";

header.hideNavigation + .main-view-container .realestate.container {
    height: calc(100vh - 80px);

    .full-screen-stand-pane {
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 4.4em);
    }
}

main.realestate {
    height: calc(100vh - 210px);

    &.navigationHidden {
        height: calc(100vh - 75px) !important;
    }

    &.full-screen {
        height: calc(100vh - 210px);
    }
}

main.realestate {
    .browser-container {
        margin-bottom: 1em;

        &.full-screen {
            margin-bottom: 15px;
        }
    }
}

.no-left{
    &.checkbox-label {
        left: 0px !important;
    }
}

.realestate {
    .extra-content {   
        line-height: 33px;
        margin-right: 6px;
    }

    .title-block {
        font-weight: bold;
        text-transform: uppercase;
    }

    .right-block {
        text-align: right;
    }

    .overflow-auto {
        overflow: auto;
    }

    .full-screen-border {
        border: 1px solid #616161;
        border-radius: 0.5em;
        background-color: #ffffff;
    }

    .full-screen-stand-pane {
        #stand-special-features-tab-label {
            margin-bottom: -20px;
        }

        #stand-special-features-tab {
            padding-top: 20px;

            .stand-special-features-tab {
                height: 95%;
                margin-top: -41px;

                .stand-special-features {
                    padding-top: 41px;

                    .special-features-list {
                        height: 99%;
                        overflow-y: auto;
                        position: relative;
                        margin-top: 0px !important;
                    }
                }
            }
        }

        &.with-browser {
            height: calc(100% - 60px);
        }

        &.without-browser {
            height: calc(100% - 30px);
        }

        overflow-y: auto;
        flex: auto;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;

        .full-screen-stand-container {
            overflow-y: visible;
            width: 100%;
            height: calc(100% - 3.5em);

            .form-group {
                padding: 0;
            }

            .flex-container {
                align-items: stretch;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                height: calc(100% - 2em);
            }

            .scroll-wrapper {
                overflow-y: auto;
                height: calc(100vh - 24em);
                width: 100%;
            }

            .noscroll-wrapper {
                height: 100% !important;
                overflow-y: visible;
                width: 100%;
            }

            .partial-box-header {
                margin: 0;
                padding: 0.2em;
            }

            .close-fullscreen {
                padding: 0.2em;
            }

            .pane-title {
                background-color: $black;
                border-bottom: none;
                border-radius: 0;
                color: $white;
                font-family: $font-semibold;
                font-size: 14px;
                text-transform: uppercase;
            }

            .pane {
                flex: 1 1 33%;
                height: 100%;
                width: 0;
                min-width: 420px;
                background-color: $white;
                overflow: visible;
                position: relative;
            }

            .general-info {
                height: auto !important;
            }

            .woods {
                height: auto !important;
            }

            .cuttings {
                height: auto !important;
            }

            .toimenpiteet {
                height: auto !important;
            }

            .stand-cuttings { // hax to fix legacy
                .title-padding {
                    padding: 0;

                    .cutting-title-padding {
                        padding: 0.2em;
                    }
                }
            }

            .silvi-special {
                height: auto !important;

                @media (max-width: 1690px) {
                    border-right: 1px solid $darkgray;
                }
            }

            .silviculture,
            .special {
                height: calc(50% - 2em);
            }
        }
    }

    .title-separator {
        border-top: 1px solid white !important;
        margin: 0 !important;
    }

    .rounded-corners {
        border-radius: 4px;
        border-width: 0px;
    }

    &.container {
        /*        width: 100%;
        margin: 0;*/
        display: flex;
        flex-wrap: wrap;
    }

    &.sidebarHidden {
        max-width: 0px;
        margin-right: 0;
    }

    &.partial-box {
        margin: 0px;

        &.sidebarHidden {
            max-width: 0px;
            margin-right: 0;
        }

        &.right {
            flex: 5 2 485px;
            display: flex;
            padding: 0;
            border: 0;
            align-items: stretch;
            flex-direction: column;
            height: 100%;
        }

        .scroll-wrapper {
            display: flex;
            overflow: hidden;
            height: 100%;
            border: 1px solid $darkgray;
            border-radius: 0.5em;

            form {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;

                .content-container {
                    overflow-y: auto;
                    background: white;
                }
            }
        }
    }

    &.partial-box-header {
        margin-top: 0.5em;
        padding: 0.2em;
        min-height: 2em;
        line-height: 1.6em;

        ~ .realestate.partial-box-header {
            margin-top: .3em;
            min-height: 2em;
        }
    }


    &.owner-info, &.map {
        margin: 0;
    }

    &.owner-info {
        margin-bottom: 1em;
        border: 1px solid $darkgreen;
        vertical-align: middle;
        font-size: 110%;
        flex: 0 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: middle;
    }

    &.owner-asnro, &.owner-basic-info {
        flex: 0 0 fit-content;
        display: flex;
        align-items: center;
    }

    &.owner-asnro {
        border-left: 1px solid $darkgreen;
        margin-right: 0.5em;
        padding-left: 0.5em;
    }

    &.owner-basic-info {
        margin-left: 0.5em;
    }

    &.owner-title {
        margin-right: .2em;
        font-weight: bold;
    }

    &.map {
        @extend %silva-box;
        flex: 1 1 100%;
    }

    &.text-green {
        color: $darkgreen;
    }

    &.btn {
        padding: 2px 0px 2px 0px;
        flex: 1;
    }

    &.form-group {
        display: inline-block;
        width: 100%;
        //padding-right: 2%;
        vertical-align: middle;

        &.margin-top {
            margin-top: 0.5em;
        }
    }

    &.full-row {
        display: flex;
        margin: 0;
    }

    &.full-row-new-1 {
        display: flex;
        margin: 0;
        height: 40px;
        padding: 8px;
        padding-bottom: 0px;
    }

    &.full-row-new-2 {
        display: flex;
        margin: 0;
        height: 48px;
        padding: 8px;
    }

    &.row-item {
        flex: 1;
        margin: 0;
        padding: 0;
        text-align: center;

        label {
            margin-bottom: 0;
        }
    }

    &.separator {
        margin: .1em 0 .1em 0;

        &.horizontal {
            float: left;
            width: 1em;
            padding-top: 1em;
            font-size: 140%;
            position: relative;
            text-align: center;
            min-height: 100%;
            vertical-align: bottom;
        }
    }

    &.bottom-row {
        display: flex;
        align-items: center;
        margin-top: .5em;
        border-top: 1px solid $gray;

        .bottom-column {
            flex: 1;
            padding: 0.5em;

            &:last-child {
                border-left: 1px solid $gray;
            }
        }
    }

    &.bottom-buttons {
        background-color: $lightgray;
        padding: 0;
        border-top: 1px solid $darkgray;
        min-height: 43px;

        > .row-item {
            padding: .4em 0 .4em 0;
            margin: .3em;
        }
    }

    .new-realestate-buttons {
        border-top: 1px solid $darkgray;
        border-bottom: 1px solid $darkgray;
        margin-top: 1em;

        > .row-item {
            padding: .4em 0 .4em 0;
            margin: 1em;
        }
    }

    .silvanet-date {
        color: $darkgreen;
        border: none;
        background-color: white;
        padding: 0;
    }

    &.checkbox-label { //Adjust the position of the checkbox-hack that we are using
        top: -8px;
        left: 6px;
        margin: 0;
    }

    &.forest-amount-date {
        width: 61%;
        padding: 0 0.5em 0 0;
        display: inline-block;

        input[type="date"]::-webkit-calendar-picker-indicator,
        input[type="date"]::-webkit-inner-spin-button {
            display: none;
        }
    }

    &.forest-amount-search {
        width: 37%;
    }

    &.additional-info-text {
        padding: 0 .5em 0 .5em;

        > .form-control {
            height: 30px;
            padding: 0 .2em 0 .2em;
        }
    }

    .input-field {
        padding-right: 0;
    }

    .reload-button {
        font-size: 18px;
        padding-top: 3px;
        padding-left: 6px;
        color: $darkgray;

        &:hover {
            color: $trimble_tf_product_green;
            cursor: pointer;
        }
    }

    .title-data {
        font-size: 12px;
        text-transform: none;
        line-height: 1.9em;
    }

    .notes {
        .form-group {
            margin: 0.5em;
            padding: 0;

            .textarea-wrapper {
                border-radius: 4px;
                display: flex;
                height: auto;

                textarea {
                    border: 0;
                    height: auto;
                    margin: 0;
                    outline: none;
                    resize: none;
                    width: 100%;
                }
            }
        }
    }

    .no-padding {
        padding: 0 !important;
    }

    .no-margin {
        margin: 0 !important;
    }

    .padded-container {
        padding: 0.5em;
    }

    .active-forest-plan {
        .forest-plan-type-row {
            padding-top: 8px;

            .col-xs-5 {
                width: 45%;
            }

            .col-xs-7 {
                width: 55%;
            }
        }

        .forest-plan-input-fields {
            display: inline-flex;
        }

        .custom-drop-down {
            padding: 5px 7px;

            .btn-group {
                border: 1px solid $darkgray;
                border-radius: 5px;

                .btn {
                    text-align: left;
                    padding: 2px 2px;
                    border: none;
                }

                .custom-button {
                    text-indent: 4px;
                }

                .custom-button:hover {
                    background-color: white;
                }

                .dropdown-toggle {
                    box-shadow: none;

                    .caret {
                        position: relative;
                        right: -10px;
                    }
                }
            }

            .dropdown-menu {
                .table-body {
                    padding: 0px 10px;
                }

                .table-row {
                    padding: 5px 0px;
                }

                .table-cell-row {
                    height: 15px;
                }

                .table-cell {
                    width: 12%;
                    text-align: left;

                    &.kunta-cell {
                        width: 22%;
                    }
                }

                .table-header {
                    border-bottom: 1px solid $gray;
                    padding-bottom: 10px;
                }

                .lisatieto-cell {
                    width: 100%;
                    text-align: left;
                    text-indent: 0px;
                }

                .muokkausPvm-cell {
                    width: 30%;
                }
            }
        }
    }
    /* STAND PANE */
    .stand-pane {
        position: relative;
        overflow: hidden;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        //height: 902px;
        uib-accordion {
            display: flex;
            flex-grow: 1;
            width: 100%;
            height: 100%;

            .panel-group {
                display: flex;
                flex-direction: column;
                width: 100%;

                .panel-open {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;

                    .panel-collapse.collapse.in {
                        overflow-y: auto;
                        height: 100% !important;
                    }
                }
            }
        }

        .absolute-container {
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 42px;
        }

        .stand-accordion {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            flex-grow: 1;

            .border-right-white {
                border-right: 1px solid $white;
            }

            .title-padding {
                padding: 0.2em;
            }

            .title-data-align {
                padding: 0 0.2em;
                text-align: right;
            }

            .form-group {
                padding: 0;
            }

            .glyphicon {
                color: #333;
                padding-left: 0.1em;
            }
        }
        // endof stand-accordion
    }
    // endof stand-pane
    .cutting-buttons-top,
    .cutting-buttons-bottom,
    .silviculture-buttons,
    .special-buttons {
        button:first-child {
            margin-right: 0.5em;
            width: calc(66.6% - 0.5em);
        }

        button:nth-child(3) {
            margin-top: 0.5em;
        }
    }

    .silviculture-buttons,
    .special-buttons {
        border-bottom: 1px solid $darkgray;
    }

    .cutting-buttons-bottom {
        padding-top: 0;
    }

    .full-screen-button {
        font-size: 20px;
        padding-left: 0.3em;
    }
    /* override messy realestate form styles */
    &.partial-box-header {
        margin-top: 0.5em;
        padding: 0.2em;
        vertical-align: middle;
        width: 100%;
        display: inline-block;
    }

    label.control-label {
        margin: 0.3em 0;
    }
    /* override default uib-accordion styles */
    .panel-group {
        margin-bottom: 0;

        .panel {
            border: none !important;
            margin-top: 0px;

            .panel-heading {
                border-radius: 0;
                padding: 0;

                &:hover {
                    background: $black !important;
                    color: $white !important;
                }

                .panel-title {
                    color: #333;
                    font-family: $font-semibold;
                    font-size: 14px;
                    text-transform: uppercase;

                    .accordion-toggle {
                        color: #333;
                        outline: none;
                        text-decoration: inherit;
                    }

                    .control-label-forestasset {
                        background-color: $white;
                        border-bottom: 1px solid $gray;
                        border-top: 1px solid $gray;
                        padding: 0.0em !important;
                    }

                    .control-label {
                        background-color: $white;
                        border-bottom: 1px solid $gray;
                        padding: 0.2em;

                        &:hover {
                            background: $black !important;
                            color: $white !important;

                            .glyphicon {
                                color: $white;
                            }
                        }

                        &.open {
                            background-color: $black;
                            border-bottom: none;

                            .accordion-title {
                                color: $white;
                            }

                            .glyphicon {
                                color: $white;
                                transform: rotate(90deg);
                            }
                        }

                        .accordion-title {
                            margin-left: 0.2em;
                        }
                    }
                }
            }

            .panel-body {
                border-bottom: 1px solid $gray;
                border-top: none;
                padding: 0;
            }
        }
    }
    // endof panel-group
    @mixin stand-dropdown {
        color: initial;
        width: 100%;
        padding: initial;
        padding-left: 7px;
        max-height: 21px;
        font-size: 12px;
    }

    #special-accordion-group .panel-body {
        height: 100%;

        .stand-special-features-tab {
            height: 100%;
        }
    }



    .stand-woods,
    .stand-cuttings,
    .stand-silviculture,
    .stand-special-features {
        overflow: -webkit-paged-x;

        .main-pane {
            border-bottom: 1px solid $darkgray;
        }

        .stand-grid-three {
            height: 307px;
            border-top: 1px solid $darkgray;

            .ag-body-viewport {
                //overflow-y: hidden;  // no effect since grid height is big enough
            }

            .ag-header-group-cell-label {
                padding-left: 7px;
                padding-right: 7px;

                .title-left-side {
                    float: left;
                    text-transform: uppercase;
                }
            }

            .ag-header-cell:first-child {
                text-align: left; // PTL title cell
            }

            .title-right-side {
                float: right;
            }

            .roundwood-title-cell {
                max-width: 77px;
            }

            .ag-floating-bottom {
                box-shadow: none;
            }
            /* disable hovers for grayed out fields */
            .ag-body-container .ag-row {

                &:hover .ag-cell:not(.roundwood-cell) {
                    color: black;
                }

                &:last-child { // to get the actual viewport last row bottom border
                    border-bottom: 1px solid $darkgray;
                }
            }

            .ptl-cell {
                border-bottom: 1px solid $gray;
                background: $lightgray;
                text-align: left;
            }

            .roundwood-cell {
                text-align: center;
                cursor: hand;
                pointer-events: all;

                &.gray {
                    border-bottom: 1px solid $gray;
                    background: $lightgray;
                    pointer-events: none;
                }
            }

            .totalvol-cell,
            .accumulation-cell {
                border-bottom: 1px solid $gray;
                background: $lightgray;
                text-align: center;
            }

            .ag-row .ag-row-inline-editing {
                color: $black !important;
            }

            .ag-row-focus,
            .ag-row-hover {
                .ag-cell {
                    color: $black !important;

                    &.round-wood {
                        color: $white !important;
                    }
                }
            }
        }
        // endof three
        .stand-grid-four {
            margin-top: 0.5em;
            height: 130px;
            border-top: 1px solid $darkgray;
            border-bottom: 1px solid $darkgray;
            margin-bottom: 0.5em;

            .ag-body-viewport {
                //overflow-y: hidden;  // no effect since grid height is big enough
            }

            .ag-body-container {
                text-align: left;
            }

            .ag-header-cell:first-child {
                text-align: left; // TUNNUKSET title cell
            }

            .ag-row {
                pointer-events: none;
            }

            .ag-cell {
                background: $lightgray;
                border-bottom: 1px solid $gray;
            }

            .ag-floating-bottom {
                box-shadow: none;
            }

            .accumulation-ppl-header,
            .ids-cell {
                text-align: left;
            }

            .ppl-cell, .age-cell, .ppa-cell, .lpm-cell, .pit-cell, .vol-cell {
                text-align: center;
            }
        }
        // endof four
        .stand-grid { /* grid without controls */
            text-align: right;

            .ag-root {
                border-radius: 0;
            }

            .ag-header {
                font-size: 12px;
            }

            .ag-header-cell {
                text-align: center;
            }

            .ag-body-viewport {
                overflow-x: hidden !important;

                .ag-cell-value {
                    font-size: 12px;
                    padding-left: 4px;
                    padding-right: 4px;
                }

                .ag-cell-edit-input {
                    //color: black;
                    height: 100% !important;
                    width: 100% !important;
                }

                .stand-dropdown {
                    @include stand-dropdown;
                }

                .red-bg-cell {
                    background-color: $red;
                }
            }
        }
        // endof stand-grid
        .control-rows-pane {
            border-bottom: 1px solid $darkgray;

            .add-row {
                width: 38.333%;
            }

            .add-row,
            .remove-row {
                cursor: pointer;
                border-right: 1px solid $gray;

                .glyphicon {
                    padding-right: 0.5em;
                }
            }

            .add-row,
            .remove-row {
                &:hover, &:hover .glyphicon {
                    color: $trimble_tf_product_green;
                }
            }
        }

        .cutting-title-padding {
            padding-left: 0.3em;
        }

        .partial-box-header {
            border-top: 1px solid $darkgray;
        }

        ul.silviculture-list,
        ul.special-features-list {
            width: 100%;
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: inline-block;

            li.forest-asset-item {
                border-bottom: 0px solid black !important;
            }

            li.item {
                border-bottom: 1px solid $black;

                &:last-child {
                    border-bottom: 1px solid $darkgray;
                }

                .top-forestasset {
                    background-color: $white;
                    padding: 4px;

                    .special-feature-group {
                        border: 1px solid $darkgray;
                        margin: 6px;
                        border-radius: 7px;

                        .special-feature-topic {
                            font-size: 12px;
                            font-weight: bold;
                            text-transform: uppercase;
                            background-color: $lightgray;
                            padding: 5px;
                            border-bottom: 1px solid $darkgray;
                            width: 100%;
                            border-top-left-radius: 7px;
                            border-top-right-radius: 7px;
                        }

                        .special-feature-content {
                            background-color: $white;
                            margin: 5px;
                        }
                    }
                }

                .top {
                    background-color: $gray;
                    padding: 4px 4px 4px 0;

                    .index {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        background-color: $blue;
                        color: $white;
                        display: inline-block;
                        width: 27px;
                        height: 27px;
                        text-align: right;
                        padding-right: 7px;
                        padding-top: 4px;
                    }

                    .work-code {
                        cursor: pointer;
                        width: calc(100% - 62px);
                        display: inline-block;

                        input[type="search"] {
                            width: 70% !important;
                        }

                        .custom-button, .dropdown-toggle {
                            padding-top: 0.2em;
                            padding-bottom: 0.2em;
                            padding-left: 8px;
                            background-color: white;
                        }

                        .custom-button {
                            text-align: left;
                        }

                        &.code {
                            margin-left: 30px;
                        }

                        &.additional {
                            margin-left: 33px;
                        }

                        &.ng-invalid {
                            .custom-button {
                                background-color: #FFD8D8;
                            }

                            .dropdown-toggle {
                                background-color: #FFD8D8;
                            }

                            .dropdown-menu {
                                background-color: #FFD8D8;

                                input[type="search"] {
                                    background-color: #FFD8D8;
                                }

                                .silva-search {
                                    background-color: #FFD8D8;
                                }
                            }
                        }
                    }

                    .remove-button {
                        color: $white;
                        background-color: $black;
                        border-radius: 6px;
                        padding: 6px;
                        top: 0;
                        vertical-align: top;

                        &:hover {
                            cursor: pointer;
                            background-color: $trimble_tf_product_green;
                        }
                    }
                }

                .middle {
                    background-color: $lightgray;

                    .item {
                        border-left: 1px solid $gray;
                        padding: 0 0.5em;
                        padding-bottom: 3px;

                        input {
                            padding-right: 5px;
                            text-align: right;
                        }
                    }

                    .item:first-child {
                        border-left: none;
                        padding-left: 33px;
                        width: 32%;

                        input {
                            text-align: left;
                        }
                    }
                }

                .bottom {
                    background-color: $lightgray;

                    .row {
                        padding: 3px 0;
                        border-top: 1px solid $gray;
                    }

                    .label-container {
                        padding-left: 13px;
                        padding-right: 0;
                        width: 30%;
                    }

                    .item {
                        padding-left: 9px;
                        padding-right: 0;
                        width: 69%;
                    }
                }

                .middle,
                .bottom {
                    label {
                        display: block;
                        font-size: 12px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    // endof stand-woods, stand-cuttings, stand-silviculture, stand-special-features
    .tuo-kuviotiedot-white {
        background-image: url(../../../content/images/svg/TempFile_SmallWhiteIcon.svg);
        background-repeat: no-repeat;
        background-position: left;
        background-position-x: 10px;
    }

    .icon-kuviotiedot-black {
        background-image: url(../../../content/images/svg/TempFile_SmallBlackIcon.svg);
        background-repeat: no-repeat;
        background-position: left;
        background-position-x: 10px;
    }

    .new-property {
        height: 100%;
        overflow: auto;

        .scroll-wrapper {
            display: flex;
            overflow: hidden;
            height: 100%;
            border: 1px solid $darkgray;
            border-radius: 0.5em;

            form {
                width: 100%;

                > div {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .content-container {
                        overflow-y: auto;
                        background: white;
                    }
                }
            }
        }
    }

    .full-screen-accordian {
        overflow: auto;
        height: calc(100vh - 18em);

        &.navigationHidden {
            height: calc(100vh - 120px) !important;
        }

        .scroll-wrapper {
            display: flex;
            overflow: hidden;
            height: 100%;
            border: 1px solid $darkgray;
            border-radius: 0.5em;

            form {
                width: 100%;

                > div {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .content-container {
                        overflow-y: auto;
                        background: white;
                    }
                }
            }
        }
    }


    .no-margin {
        margin: 0px;
    }

    .width-100 {
        width: 100%;
    }

    .height-100 {
        height: 100%;
    }

    .no-padding {
        padding: 0px;
    }

    .pad-left-0 {
        padding-left: 0px;
    }

    .pad-right-0 {
        padding-right: 0px;
    }

    accordion-group-component {
        max-height: 72%
    }

    accordion-group-component-forestasset {
        max-height: 72% !important;
    }

    @media screen and (max-width: 1800px) {
        accordion-group-component {
            max-height: 67%
        }
    }

    @media screen and (max-width: 1600px) {
        accordion-group-component {
            max-height: 58%
        }
    }

    @media screen and (max-width: 1300px) {
        accordion-group-component {
            max-height: 39%
        }
    }

    .stand-silviculture-tab {
        overflow-y: visible;
    }
}

@import './components/newForestPlanDirective.scss';
@import './components/standAndRealEstateBrowser/standAndRealEstateBrowserDirective.scss';
@import './components/renumberForestAssetStandsModal.scss';
