﻿@import '../../shared/sass/_variables.scss';

$unified-left-margin: 10px;

.timber-buyer {
    $valittu-select-width: 68px;

    input[type="radio"] {
        box-shadow: none;
    }

    .right-align {
        text-align: right;
        font-size: 14px;
        background-color: initial;
    }

    .ag-header-cell-label {
        text-transform: none;
        padding-left: $unified-left-margin - 8px;
    }

    .navigation-grid {
        height: 640px;
    }

    .timber-info-grid {
        height: 320px;

        .ag-cell {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .checkbox-cell {
            text-overflow: unset;
        }

        .ag-header-cell {
            .select-all-wrapper {
                position: relative;
                top: -5px;
                left: 2px;
            }
        }        
    }

    .buttons-group {
        background-color: #D1D1D1;
        padding: 10px;        
    }

    .white-background {
        background: white;
    }

    .timberBuyer-panel {
        border: 1px solid #616161;
        border-radius: 0.5em;
        overflow: hidden;
    }

    .content {
        padding-top: 5px;

        [class^="col"] {
            padding-left: 5px;
            padding-right: 5px;
        }

        .same-row {
            padding-left: 10px;
            padding-right: 10px;
        }

        input[type=radio] {
            margin-top: 0px;
            margin-bottom: 0px;
            height: 14px;
        }

        .simpleDropDown {
            display: inline-block;
            border-color: $darkgray;
            border-radius: 3px;
        }

        .align-right {
            text-align: right;
        }

        .label-right-margin {
            margin-right: 5px;
        }
    }

    .left-panel {
    }

    .right-panel {
        margin-left: 5px;

        .panel-header {
            background: $grid-header-background;
            text-transform: uppercase;
            font-family: "Myriad Pro Semibold";
            border-radius: 0.5em 0.5em 0 0;
            padding: 3px $unified-left-margin;
        }

        .bottom-bottons {
            .changeBuyerCodeButton {
                margin-right: 10px;
            }

            .align-with-the-upper-button {
                margin-left: 10px;
            }
        }
    }

    .right-detail-panel {
        .content {
            padding: 6px 2px;

            input {
                display: inline-block;
                width: initial;
                padding-right: 5px;
                vertical-align: middle;
            }
        }

        .form-group {
            padding-left: $unified-left-margin - 2px;
        }
    }

    .white-on-black-header {
        background-color: black;
        color: white;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        padding: 0.5em $unified-left-margin;
        width: 100%;
        margin-bottom: 0px;
    }

    .no-margin-bottom {
        margin-bottom: 0px !important;
    }

    .navigation-buttons {
        display: block;

        span {
            vertical-align: middle;
        }

        .button {
            color: rgb(209,209,209);
        }

        .glyphicon {
            font-size: 16px;
        }

        [disabled] {
            opacity: 0.4;
            pointer-events: none;
        }

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-top: 8px;
        position: relative;
    }

    .valittu-select {
        width: $valittu-select-width;
    }

    .valittu-select-sibling {
        width: calc(100% - #{$valittu-select-width});

        .view-option-select {
            width: 100%;

            button {
                width: 100%;
            }
        }
    }

    .ag-cell {
        text-overflow: ellipsis;

        select:disabled {
            background-color: #d1d1d1;
        }

        .simpleDropDown {
            border-color: #D1D1D1;
            width: 100%;
            color: black;
            border-radius: 3px;
        }

        input, select, input[type="text"], input[type="number"] {
            color: inherit;
            background-color: inherit;
        }
    }



    .ag-theme-fresh .ag-header-cell-text {
        white-space: normal;
        padding-left: 0px;
        font-size: 12px;
        text-transform: uppercase;

        .ag-root {
            //font-family:$font-family;
        }

        span {
            text-transform: lowercase;
        }
    }

    .padding-zero {
        padding: 0px !important;
    }

    .form-horizontal {
        .left-detail-panel {
            .content {
                border-right: 1px solid #D1D1D1;
            }
        }

        .right-detail-panel {
            .form-group {
                padding-top: 3px;
                padding-bottom: 3px;

                &.cutting-type-row {
                    padding-left: 0px;
                }

                .control-label {
                    padding-top: 0px;
                }
            }
        }
    }

    .sp-grid {
        .ag-root {
            border-radius: 0px;
        }

        .ag-row-selected, .ag-row:hover {
            input {
                color: white !important;
            }

            select, .simpleDropDown {
                color: black;
                background-color: white;
            }
        }

        input[disabled], select[disabled] {
            cursor: not-allowed;
            background-color: #eee;
            opacity: 1;
        }
    }

    .btn-increased-gap {
        margin-left: 5px;
        margin-right: 5px;
    }

    .dropDownMinWidth450px {
        .dropdown-menu {
            min-width: 450px;
        }
    }

    .editable-text input[type="text"], .editable-text textarea {
        text-align: left;
    }

    .grid-edit-modal {
        .commands-buttons {

            .commands-container {
            }
        }
    }

    $modal-width: 1140px;
    $unit_width: $modal-width / 100;
    $class-slug: width !default;

    .grid-edit-modal {
        width: $modal-width;
    }

    @for $i from 1 through 25 {
        .#{$class-slug}-#{$i} {
            width: $i *$unit_width;
        }
    }
}
