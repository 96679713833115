﻿@import '../../shared/sass/_variables.scss';

.stand-basic-modal-window {
    width: 900px;

    .execute-button {
        margin-left: 5px;
    }

    .border-margin {
        margin-left: 1px;
        margin-right: 1px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .header-next-rows {
            padding: 0em 1em 1em 1em !important;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .radio-group {
        margin: 0.5em 1em -0.5em 1em;

        input {
            margin-right: 0.3em;
        }

        label {
            margin-right: 1em;
            vertical-align: middle;
        }
    }

    .search-area {
        display: flex;

        .search-bar {
            display: flex;
            flex-grow: 6;
            border: 1px solid $gray;
            border-radius: 0.5em;
            height: 2em;
            line-height: 2em;
            overflow: hidden;
            padding: 0 0.4em;
            margin: 1em 1em 0 1em;

            span.glyphicon-search,
            span.glyphicon-remove {
                top: 5px;
            }

            input {
                border: 0;
                height: 2em;
                outline: 0;
                padding-left: 0.2em;
                width: 97%;
            }
        }

        .filtering-bar {
            align-self: flex-end;
            display: flex;
            flex-basis: 5em;
            flex-grow: 1;

            label { // center checkbox
                &:before {
                    top: 0;
                }

                &:after {
                    top: 4px;
                }
            }
        }
    }

    .grid-container {
        margin: 1em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-radius: 0.5em;
            height: 180px;

            #centerRow {
                border-radius: 0.5em !important;
            }

            .master-cell-header {
                text-transform: uppercase;
                border-right: 1px solid $darkgray;
            }

            .ag-header-cell-text {
                text-transform: uppercase;
            }

            .ag-header-cell.distribution-group-header,
            .ag-header-cell.distribution-name-header {
                text-transform: none;
            }

            .ag-floating-bottom {
                box-shadow: none;
            }

            .ag-body-viewport {
                overflow-x: hidden;
            }

            .ag-ltr {
                .ag-header-group-cell-label {
                    padding-left: 4px;
                }
            }
        }
    }

    .input-area {
        margin: 0 1em 1em 1em;

        label {
            margin-right: 1em;
        }

        input[type="date"] {
            border-radius: 4px;
            border: 1px solid #616161;
            padding-left: 0.3em;
            width: 9em;
        }
    }

    .form-group {
        padding: 0;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
        width: 898px;
    }

    .checkboxes-wrapper {
        padding: 0px 10px 10px 14px;

        .checkboxes-wrapper-header {
            text-transform: uppercase;
            margin: 0px 0px 10px 0px;
            font-family: "Myriad Pro Semibold";    
        }

        .checkbox-wrapper {
            float: left;
        }

        label.alingWithCheckbox {
            padding-left: 5px;
        }

        [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
            top: 0px;
        }

        [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
            top: 4px;
        }
    }

    .bottom-buttons {
        clear: both;
    }

    .float-left {
        float: left;
    }

    .icon-kuviotiedot-black {
        background-image: url(../../../content/images/svg/TempFile_SmallBlackIcon.svg);
        background-repeat: no-repeat;
        width: 14px;
        height: 14px;
        margin-right: 5px;
        margin-top: 2px;
    }
}
