.silva-search-common {
    vertical-align: middle;
    align-items: center;
    display: flex;
    line-height: 34px;
    height: 34px;
    margin: 0;

    input {
        margin: 0;
        outline: 0;
    }

    .glyphicon {
        margin-bottom: 3px;
    }

    .glyphicon:last-child {
        margin-right: 0;
        margin-left: 5px;
    }

    .glyphicon:first-child {
        margin-left: 0;
        margin-right: 5px;
    }
}
