.customer-personal-data {
    .display-flex {
        display: flex
    }

    .attachment-icon-container {
        display: flex;
        margin-left: 33px;
        padding-top: 4px;
    }

    .close-button {
        margin-left: auto;
        padding-top: 10px;
        margin-right: 10px;
    }

    &.partial {
        margin: 0 0.2em !important;
    }

    .form-group {
        .wrap {
            margin: 0.5em;
            width: calc(100% - 1em);
        }
    }

    .ownerships-list {
        list-style-type: none;
        margin: 0;
        max-height: 77px;
        overflow: auto;
        padding: 0;
        width: 100%;
        padding: 10px 0px 10px 10px;
    }

    .invalid-percent {
        background-color: #FFD8D8 !important;
        color: black !important;
    }

    .distribution-scroll-list {
        //max-height: 200px;
    }

    .label-right {
        float: right;
        margin-right: 0.5em;
    }

    .partial.distribution-table {
        margin: 0.5em 0;

        .partial-header {
            padding: 0.5em !important;
        }
    }

    .custom-drop-down .dropdown-menu input[type="search"] {
        margin-left: 36px;
        margin-top: 4px;
    }

    .col-xs-5-5 {
        width: 49%;
    }

    .custom-drop-down {
        .dropdown-toggle {
            padding-top: 3px;
            padding-bottom: 3px;
        }

        .dropdown-menu {
            margin-top: 0em;
            width: 14.5em;
            height: 18em;
            border-color: $darkgray;
            z-index: 1040;
        }

        .text-right {
            .control-label {
                margin-left: 0.5em !important;
            }
        }

        .silva-search {
            padding-left: 10px !important;
            margin-top: 5px;
            border-color: $darkgray;

            .glyphicon {
                top: 39px;
                left: 17px;
                /* top: 2.6em;
                left: -0.5em;*/
            }

            .glyphicon-remove {
                left: 14px !important;
                top: -32.2px;
            }
        }

        .btn.custom-button {
            text-align: left;
        }
    }

    .invalid-field {
        background-color: $lightred;
    }

    .input-upper {
        text-transform: uppercase;
    }
}
