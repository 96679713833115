.map-working-sites-marking-tool {
    .panel-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.loading-mask {
        height: calc(100% - 4px);
        width: calc(100% - 2px);
        position: absolute;
        left: 0;
        background: rgba(255,255,255,1.0);
        z-index: 1000;
        margin: 1px;
        overflow: hidden;
        max-width: 298px;
        text-align: center;
        vertical-align: middle;
        line-height: 30px;
    }

    &.loading-mask-wrapper {
        position: relative;
        overflow: hidden;
        min-width: 300px;
        height: 100%;
    }

    &.loading-text-wrapper {
        position: relative;
        float: left;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.panel {
        // override the default styling
        top: 0;
        bottom: 0.2em;
        background-color: $white;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .panel-header {
            vertical-align: middle;
            padding: 0.5em;
            min-height: 35px;
            border-bottom: 2px solid $lightgray;
            text-transform: uppercase;

            .icon {
                width: 1em;
                margin-left: 0.3em;
            }

            span {
                position: absolute;
                left: 2.5em;
            }

            .close-button {
                cursor: pointer;
            }
        }
        // styles for the interrupt element right after the tab block
        .interrupt-element {
            position: relative;
            min-height: 35px;
            max-height: 35px;
            height: 35px;
            display: flex;
            width: 100%;

            .interrupt-block {
                background-color: white;
                padding-left: 2.5em;
                border-bottom: 1px solid $gray;
                margin: 0;
                z-index: 1;
                position: relative;
                height: 35px;
                line-height: 35px;
                vertical-align: middle;
                flex-grow: 1;

                .interrupt-button {
                    border: 1px solid $gray;
                    padding: 4px;
                    border-radius: 5px;
                    position: absolute;
                    left: 0.5em;
                    top: calc(50% - 12px);
                    height: 24px;
                }

                &:hover {
                    background-color: $black;
                    color: $white;
                    border-color: $black;

                    .interrupt-button {
                        color: $white;
                        background-color: $black;
                    }
                }
            }
        }
    }
}

@import './workingSitesMarkingToolTools/transportMarkings.scss';
@import './forestPlanMarkingTools/forestPlanMarkings.scss';