﻿@import '../../../../shared//sass/_variables.scss';

$tab-border-width: 1px;
$cell-height: 27px;

.ledger-summary {
    .list-view-settings-row {
        .advanced-filtering-dropdown .dropdown-content {
            height: 366px;

            .dropdown-table .dropdown-date-input {
                width: 161px;
            }
        }
    }

    .ledger-summary-table {
        margin: 0.5em 0 0;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;
        height: 40vh !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .ag-menu {
            top: 49px !important;
        }

        .checkbox-cell {

            // checkbox is rendered as an image
            img {
                cursor: pointer;
            }
        }

        .ag-header {
            // for some reason the whole header is jumping up after select-all-click
            position: initial;
        }

        .ag-side-bar {
            background-color: $white !important;
            border-right: none !important;
            border-right: none !important;
            border-top: none !important;
            font-size: 12px;

            .ag-column-select-panel {
                margin-top: 0.5em;
            }
        }

        //Hide checkbox-cells in group-rows, couldn't find a way to do this easily programmatically
        .ag-row-group .checkbox-cell {
            display: none;
        }

        .ag-row .ag-selection-checkbox {
            position: static;
            margin-left: 4px;
            margin-top: 2px;
            float: left;
        }

        .unselect-control {
            padding-left: 6px;
            padding-top: 3px;

            [type="checkbox"]:not(:checked)+label,
            [type="checkbox"]:checked+label {
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                top: -10px;
            }
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-selected {
                    color: inherit;
                    background-color: inherit !important;

                    .ag-cell {
                        color: white;
                        background-color: black;
                    }

                    [type="date"] {
                        color: white;
                    }
                }

                .ag-row-hover {
                    [type="date"] {
                        color: white;
                    }
                }
            }
        }

        .icon-width {
            background-size: 15px 15px;
            height: 15px;
            width: 15px;
        }

        .status-padding {
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 83%;
            text-align: left;
        }

        .center-check-box {
            display: flex;
            justify-content: center;
        }

        .ag-ltr .ag-header-select-all {
            margin-left: 5px;
        }
    }

    .ledger-summary-tablefoot-actionbar {
        border: 1px solid #616161;
        border-top: 0;
        /*        border-radius: 0.5em;*/
        background-color: #e8e8e8;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;
    }

    .ledger-summary-tablefoot-actionbar-collapse {
        border: 1px solid #616161;
        border-top: 0;
        border-radius: 0.5em;
        background-color: #e8e8e8;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;
    }

    .ledger-overall-summary-table {
        border: 1px solid $darkgray;
        border-top-width: 0px;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;
        height: 25vh !important;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        .ag-menu {
            top: 24px !important;
        }

        .checkbox-cell {

            // checkbox is rendered as an image
            img {
                cursor: pointer;
            }
        }

        .ag-cell {
            padding: 0px;

            .cell-wrapper {
                width: 100%;
                height: 27px;
                background-color: inherit;
                padding: 0px 3px 0px 3px;
            }
        }

        .ag-header {
            // for some reason the whole header is jumping up after select-all-click
            position: initial;
        }

        .ag-tool-panel {
            background-color: $white !important;
            border-left: 1px solid $darkgray !important;
            border-right: none !important;
            border-right: none !important;
            border-top: none !important;
            font-size: 12px;
            width: 285px;

            .ag-column-select-panel {
                margin-top: 0.5em;
            }
        }

        //Hide checkbox-cells in group-rows, couldn't find a way to do this easily programmatically
        .ag-row-group .checkbox-cell {
            display: none;
        }

        .ag-row .ag-selection-checkbox {
            position: static;
            margin-left: 3px;
        }

        .unselect-control {
            padding-left: 6px;
            padding-top: 3px;

            [type="checkbox"]:not(:checked)+label,
            [type="checkbox"]:checked+label {
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                top: -10px;
            }
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-selected {
                    color: inherit;
                    background-color: inherit !important;

                    .ag-cell {
                        color: white;
                        background-color: black;
                    }

                    [type="date"] {
                        color: white;
                    }
                }

                .ag-row-hover {
                    [type="date"] {
                        color: white;
                    }
                }
            }
        }

        .icon-width {
            background-size: 15px 15px;
            height: 15px;
            width: 15px;
        }

        .status-padding {
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 83%;
            text-align: left;
        }

        .center-check-box {
            display: flex;
            justify-content: center;
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }
}


@import './contractLedgerDetails/contractLedgerDetails.scss';