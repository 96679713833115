﻿@import '../../../../../shared/sass/_variables.scss';

.contract-ledger-details-modal {

    .multiselection-drop-down .checkboxes {
        width: calc(100% - 37em);
    }

    .spinner-container {
        top: 50%;
        left: 50%;

        .spinner-background {
            background-color: gray;
            opacity: 0.1;
            width: 100%;
            height: inherit;
        }
    }

    .modal-lg {
        width: 90% !important;
    }

    .ledger-summary-tablefoot-actionbar {
        border: 1px solid #616161;
        border-top: 0;
        /*        border-radius: 0.5em;*/
        background-color: #e8e8e8;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;
    }

    .contract-ledger-details-table {
        border: 1px solid $darkgray;
        border-bottom-width: 0px;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;
        height: 40vh !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .ag-menu {
            top: 49px !important;
        }

        .checkbox-cell {
            // checkbox is rendered as an image
            img {
                cursor: pointer;
            }
        }

        .ag-header {
            // for some reason the whole header is jumping up after select-all-click
            position: initial;
        }

        .ag-tool-panel {
            background-color: $white !important;
            border-left: 1px solid $darkgray !important;
            border-right: none !important;
            border-right: none !important;
            border-top: none !important;
            font-size: 12px;
            width: 285px;

            .ag-column-select-panel {
                margin-top: 0.5em;
            }
        }
        //Hide checkbox-cells in group-rows, couldn't find a way to do this easily programmatically
        .ag-row-group .checkbox-cell {
            display: none;
        }

        .ag-row .ag-selection-checkbox {
            position: static;
            margin-left: 3px;
        }

        .unselect-control {
            padding-left: 6px;
            padding-top: 3px;

            [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                top: -10px;
            }
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-selected {
                    color: inherit;
                    background-color: inherit !important;

                    .ag-cell {
                        color: white;
                        background-color: black;
                    }

                    [type="date"] {
                        color: white;
                    }
                }

                .ag-row-hover {
                    [type="date"] {
                        color: white;
                    }
                }
            }
        }

        .icon-width {
            background-size: 15px 15px;
            height: 15px;
            width: 15px;
        }

        .status-padding {
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 83%;
            text-align: left;
        }

        .center-check-box {
            display: flex;
            justify-content: center;
        }
    }

    .contract-ledger-summary-table {
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;
        height: 25vh !important;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        .ag-menu {
            top: 24px !important;
            max-height: 85%;
        }

        .checkbox-cell {
            // checkbox is rendered as an image
            img {
                cursor: pointer;
            }
        }

        .ag-header {
            // for some reason the whole header is jumping up after select-all-click
            position: initial;
        }

        .ag-cell {
            padding: 0px;

            .cell-wrapper {
                width: 100%;
                height: 27px;
                background-color: inherit;
                padding: 0px 3px 0px 3px;
            }
        }

        .ag-tool-panel {
            background-color: $white !important;
            border-left: 1px solid $darkgray !important;
            border-right: none !important;
            border-right: none !important;
            border-top: none !important;
            font-size: 12px;
            width: 285px;

            .ag-column-select-panel {
                margin-top: 0.5em;
            }
        }
        //Hide checkbox-cells in group-rows, couldn't find a way to do this easily programmatically
        .ag-row-group .checkbox-cell {
            display: none;
        }

        .ag-row .ag-selection-checkbox {
            position: static;
            margin-left: 3px;
        }

        .unselect-control {
            padding-left: 6px;
            padding-top: 3px;

            [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                top: -10px;
            }
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-selected {
                    color: inherit;
                    background-color: inherit !important;

                    .ag-cell {
                        color: white;
                        background-color: black;
                    }

                    [type="date"] {
                        color: white;
                    }
                }

                .ag-row-hover {
                    [type="date"] {
                        color: white;
                    }
                }
            }
        }

        .icon-width {
            background-size: 15px 15px;
            height: 15px;
            width: 15px;
        }

        .status-padding {
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 83%;
            text-align: left;
        }

        .center-check-box {
            display: flex;
            justify-content: center;
        }
    }

    .navigation-buttons {
        text-align: center;
        margin: 0;
        padding-left: 1em;
        padding-top: 0.2em;
        animation: fade-in 500ms linear;
        text-transform: uppercase;
        font-size: 14px;
        padding-bottom: 0.6em;

        a {
            text-decoration: none;
        }

        &.disabled {
            li {
                @extend %disabledNavigation;
            }
        }

        li.disabled {
            @extend %disabledNavigation;
        }

        ul.buttons, li {
            display: inline-block;
        }

        ul.buttons {
            padding: 0;
        }

        .navigation-button-description-prev {
            margin-right: 0.5em;
        }

        .navigation-button-description-next {
            margin-left: 0.5em;
        }

        .button {
            width: 1.5em;
            height: 1.5em;
            display: inline-block;
            background-position: center bottom;
            background-size: contain;
            background-repeat: no-repeat;
            vertical-align: middle;
        }
        /* static buttons*/
        .button-to-start {
            background-image: url(../../../../../../content/images/svg/Step-to-first.svg);
            cursor: pointer;
        }

        .button-back {
            background-image: url(../../../../../../content/images/svg/Step-backward.svg);
            cursor: pointer;
            width: 1.35em;
            height: 1.35em;
        }

        .button-next {
            background-image: url(../../../../../../content/images/svg/Step-forward.svg);
            cursor: pointer;
            width: 1.35em;
            height: 1.35em;
        }

        .button-to-end {
            background-image: url(../../../../../../content/images/svg/Step-to-last.svg);
            cursor: pointer;
        }

        &:not(.disabled) {
            /* hover buttons */
            .button-to-start:not(.disabled):hover, .button-to-start:active {
                background-image: url(../../../../../../content/images/svg/Step-to-first-MouseOver.svg);
            }

            .button-back:not(.disabled):hover, .button-back:active {
                background-image: url(../../../../../../content/images/svg/Step-backward-MouseOver.svg);
            }

            .button-next:not(.disabled):hover, .button-next:active {
                background-image: url(../../../../../../content/images/svg/Step-forward-MouseOver.svg);
            }

            .button-to-end:not(.disabled):hover, .button-to-end:active {
                background-image: url(../../../../../../content/images/svg/Step-to-last-MouseOver.svg);
            }
        }
    }
}
