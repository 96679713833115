@import '../../shared/sass/_variables.scss';

$dropdown-search-button-width: 13%;

.customers {
    animation: fade-in 250ms linear;

    &:not(.container) {
        animation: fade-in 250ms linear;
    }

    &.print-preview {

        .custom-drop-down-arow {
            margin-left: 0;
            margin-right: 0;
            padding-right: 0;
            transform: rotate(-90deg) translateY(1px);
        }
    
        .customers-table,
        .customers-tablefoot-actionbar,
        .settings-row-top {
            width: 842px;
            margin: 0 auto;

            .ag-body-viewport {
                overflow-x: hidden;
            }
        }
    }

    .popover {
        left: 0px !important;
    }
}

.customers .customers-table {
    margin: 0.5em 0 0;
    overflow: hidden;
    height: 50vh;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.customers-table {
    .checkbox-cell {

        // checkbox is rendered as an image
        img {
            cursor: pointer;
        }
    }

    .ag-header {
        // for some reason the whole header is jumping up after select-all-click
        position: initial;
    }

    //Hide checkbox-cells in group-rows, couldn't find a way to do this easily programmatically
    .ag-row-group .checkbox-cell {
        display: none;
    }

    .ag-row .ag-selection-checkbox {
        position: static;
        margin-left: 5px;
    }

    .unselect-control {
        padding-left: 6px;
        padding-top: 3px;

        [type="checkbox"]:not(:checked)+label,
        [type="checkbox"]:checked+label {
            position: relative;
            padding-left: 8px;
            cursor: pointer;
            top: -10px;
        }
    }
}

.customers-table-wrapper .list-view-settings-row {

    .checkbox-setting {
        [type="checkbox"]:not(:checked)+label,
        [type="checkbox"]:checked+label {
            padding-left: 15px;
    
        }
    }
    


    .advanced-filtering-dropdown .dropdown-content {
        width: 670px;

        .dropdown-table {

            .locality-text {
                border-radius: 5px;
                width: 23.5%;
                text-align: center;
            }

            .table-cell {
                .multiselection-drop-down .checkboxes {
                    width: 250px;
                }
            }
        }
    }

    .silva-header {
        @extend %silva-header;
        background: initial;
    }

    .table-settings-button {
        margin-right: 0.5em;
    }

    .selected-option a {
        background-color: darken($white, 5);
    }


    .membership-payment-updated {
        margin-left: 0.5em;
        margin-right: 0;

        .payment-label {
            font-weight: bold;
        }

        .transperent-color {
            background-color: transparent;
        }
    }


    .icon {
        height: 17px;
        width: 18px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;

        &.icon-printer {
            background-image: url(../../content/images/svg/print.svg);

            &:hover,
            &:focus {
                background-image: url(../../content/images/svg/print-green.svg);
            }
        }
    }

    .group-wrapper {
        display: flex;
        flex-direction: row;
    }

    .customer-list-filter {
        .advanced-filtering-dropdown .dropdown-content {
            height: 409px;
        }
    }

    .property-list-filter {
        .advanced-filtering-dropdown .dropdown-content {
            height: 255px;
        }
    }
}

.customers-tablefoot-actionbar {
    border: 1px solid $darkgray;
    border-top: 0;
    border-radius: 0.5em;
    background-color: $lightgray;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    overflow: hidden;
    padding: 0.5em 0.5em;

    button {
        margin-right: 0.5em;

        &.no-margin {
            margin-right: 0;
        }
    }
}