﻿@import '../../../../../shared/sass/_variables.scss';

.general-costs-modal-window {
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .modal-dialog {
        width: 96.8%;
        margin-top: 23em;

        .modal-trimble-button-container {
            margin-top: 0;
        }
    }

    .simpleDropDown {
        border: 1px solid #616161;
        border-radius: 2px;

        @media screen and (max-width: 1300px) {
            border: 1px solid #707070;
        }
    }

    .grid-multiselect {
        .multiselection-drop-down {
            top: 87px;

            .glyphicon-remove {
                color: black !important;
            }

            .glyphicon-search {
                color: black !important;
            }

            .multiselectionSearchInput {
                width: 92% !important;
            }

            .titleBox {
                background-color: white;
            }

            .checkboxes-list-wrapper {
                padding-left: 5px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: baseline;
                justify-content: flex-start;
                color: black;

                input[type="checkbox"]+label {
                    justify-content: flex-start !important;

                    &::before {
                        margin-right: 5px !important;
                    }
                }
            }
        }
    }

    .ag-cell {
        text-overflow: ellipsis;

        select:disabled {
            background-color: #d1d1d1;
        }

        .simpleDropDown {
            width: 100%;
            color: black;
            border-radius: 3px;
        }

        input[type="date"] {
            line-height: inherit;
        }
    }

    .modal-grid {
        padding: 9px;
    }

    .multiselection-drop-down {
        line-height: 30px;
        width: 790px;
        border-radius: 0px;
        position: fixed;

        .titleBox {
            width: 100%;
            border-radius: 5px;
            border: 1px solid $darkgray;
            line-height: 22px;

            .titleBoxText {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 93%;
                color: #555;
                height: 17px;
            }

            .topmargin {
                margin-top: 5px;
            }
        }

        .checkboxes {
            width: 100%;
            border-radius: 0px;

            .select-all-label {
                line-height: 30px;
            }

            .checkboxesList .checkboxLabel {
                line-height: 35px;
            }

            .checkboxesList {
                width: 100%;
            }
        }

        .multiselectionSearch {
            height: 30px;
            margin-bottom: 10px;
            margin-top: 8px;

            .multiselectionSearchInput {
                width: 92%;
                height: 25px !important;
            }
        }
    }

    .sp-grid {
        height: 80px;

    }


    .ag-row {
        height: 30px;
        transform: none !important;
    }

    .required_field {
        background-color: #FFD8D8 !important;
    }
}

.general-cost-delete-dialog-btn-class {
    padding-right: 0px !important;
    padding-left: 0px !important;

    .btn:first-child {
        margin-right: 52% !important;
    }

    .btn:last-child {
        margin-left: 0px !important;
    }
}