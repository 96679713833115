﻿@import '../../../../../../shared/sass/_variables.scss';
.get_cost_from_cfb-modal {
    .pad-15 {
        padding: 15px;
    }

    .ag-theme-fresh {
        border: 1px solid grey;
    }

    .ag-floating-bottom {
        display: none;
    }

    .display-flex {
        display: flex;
        width: 100%;
    }

    .width-80{
        width: 80%;
    }
}
