﻿.filter-by-forestplan-id {
    .row-no-margin {
        margin-right: 0;
        margin-left: 0;
        height: 100%;
    }

    .directive-margin {
        margin: 0em 0.5em 0.5em 0.5em;
    }

    .filter-forest-plan-form {
        .group-settings.settings-item {
            margin-top: 0;
            margin-bottom: 2px;
        }
    }

    input[type="number"] {
        outline: none;
        border: 1px solid $gray;
        border-radius: 4px;
        width: 29.5%;
        height: 30px;
        padding-left: 12px;

        &:disabled {
            cursor: not-allowed;
        }

        &::-webkit-input-placeholder {
            text-align: center;
            align-content: center;
            font-style: italic;
        }
    }
}