﻿.adjustment-modal {
    $table-border-color: darkgray;

    .modal-dialog {
        top: 15%;
        width: 1000px;
    }

    table {
        border-collapse: separate;
        border-spacing: 0;
        min-width: 350px;
        margin-bottom: 0px !important;
    }

    table thead tr th,
    table tbody tr td {
        border-right: 1px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;
        padding: 5px;
        text-align: center;
    }

    table thead tr th {
        background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgb(211, 211, 211)) !important;
    }

    table thead tr th:first-child,
    table tbody tr td:first-child {
        border-left: 1px solid $table-border-color;
    }

    table thead tr th {
        border-top: 1px solid $table-border-color !important;
        font-size: 12px;
    }

    /* top-left border-radius */
    table thead tr:first-child th:first-child {
        border-top-left-radius: 5px;
    }
    /* top-right border-radius */
    table thead tr:first-child th:last-child {
        border-top-right-radius: 5px;
    }
    /* bottom-left border-radius */
    table tbody tr:last-child td:first-child {
        border-bottom-left-radius: 5px;
    }
    /* bottom-right border-radius */
    table tbody tr:last-child td:last-child {
        border-bottom-right-radius: 5px;
    }

    .table-header {
        background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 5px 3px 0 0;
        padding: 3px 10px;
    }

    .adjustment-content {
        padding: 15px;
    }

    input[type=number], input[type="text"] {
        width: 100%;
        height: 25px;
        color: black;
        text-align: right;
        border-radius: 4px;
        border-width: 1px;
    }
}
