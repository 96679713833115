﻿@import '../../shared/sass/_variables.scss';

.change-standids-for-selected-forest-plans {
    font-family: $font-family;
    font-size: $default-font-size;

    .execute-button {
        margin-left: 5px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .new-forest-plan-panel {
        margin: 0px 20px 20px 20px;
    }

    .search-bar {
        border: 1px solid $gray;
        border-radius: 0.5em;
        height: 2em;
        line-height: 2em;
        overflow: hidden;
        padding: 0 0.4em;

        input {
            border: 0;
            height: 2em;
            outline: 0;
            padding-left: 0.2em;
            width: 88%;
        }

        span.glyphicon-remove {
            margin-right: 0px;
        }
    }

    .grid-container {
        margin: 10px 15px 0px 15px;

        .modal-grid {
            border: 1px solid $darkgray;
            border-radius: 0.5em;
            height: 130px;

            #centerRow {
                border-radius: 0.5em !important;
            }

            .ag-header-group-cell-label {
                text-transform: uppercase;
            }

            .master-cell-header, .ag-header-group-cell, .ag-row, .ag-body-container {
                width: 100% !important;
                border-right: 0;
            }
        }

        .ag-scrolls {
            .ag-header-row {
                position: static;

                .ag-header-group-cell-with-group {
                    position: relative;
                }
            }
        }
    }

    .second-grid {
        padding-top: 5px;
        padding-bottom: 15px;
    }

    .form-group {
        padding: 0;

        .form-control-centered {
            text-align: center;
        }
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .sp-grid {

        .grid-check-box {
            top: -9px;
        }

        .ag-header-cell {
            text-align: center;
            border-right: none;
        }

        .main-header {
            text-transform: uppercase;
            width: 570px !important;
        }

        .ag-header-group-cell-label, .ag-header-cell {
            text-align: left;
        }

        .no-ellipsis {
            text-overflow: unset;
            padding-left: 5px;
        }
    }

    .ag-header-container {
        border-right: none;
        border-bottom: none;
    }

    .linked-real-estate-grid {
        .ag-body-viewport {
            background-color: $gray;

            .ag-row {
                background-color: $gray;

                .ag-cell {
                    background-color: $gray;
                }
            }
        }
    }

    .modal-step {
        background-color: $blue;
        color: white;
        border-radius: 0px 5px 5px 0px;
        padding: 5px 15px 5px 5px;
        box-sizing: border-box;
        display: inline-block;
        font-family: "Myriad Pro", Verdana;
        font-size: 14px;
        height: 27px;
        line-height: 18px;
        list-style-type: none;
        padding-right: 7px;
        padding-top: 4px;
        text-align: right;
        width: 27px;
    }

    .modal-step-line {
        padding: 4px 4px 4px 25px;
    }

    .no-padding-modal-step {
        padding: 0 !important;
    }

    .padding-to-modal-step {
        padding-left: 20px;
    }

    .modal-paragraph {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .italic {
        font-style: italic;
    }

    .bigger-margin-top {
        margin-top: 10px;
    }

    input[type=radio] {
        margin-top: 3px;
    }

    .modal-content {
        box-shadow: none;
    }

    .new-forest-plan-popup {
        .forest-plan-id {
            .get-new-id-button {
                button {
                    width: 181px;
                    height: 27px;
                    float: left;
                    padding: 0px;
                    margin-left: 10px;
                }
            }
        }

        .forest-plan-type-label {
            padding-left: 2px;
            padding-top: 4.5px;
        }

        .forest-plan-dropdown {
            padding-left: 16px;
            width: 64.2%;
        }
    }
}
