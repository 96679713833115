﻿@import './shared/sass/_variables.scss';

$font-size-normal: 12px;
$font-size-smaller: 10px;
$font-size-bigger: 14px;
//abstract classes start
@mixin no-margin-and-padding {    
    margin: 0;
    padding: 0;
}
@mixin flex-container-base {     
    @include no-margin-and-padding;
    display: flex;   
    align-items: center;
    width: 100%;
    height: 100%;        
}
@mixin flex-container {
    @include flex-container-base;        
    justify-content: center;    
}
@mixin flex-container-left {
    @include flex-container-base;    
    align-items: left;
}
@mixin flex-container-right {
    @include flex-container-base;     
    align-items: right;
}
@mixin inline-flex-container {
    @include flex-container;
    display: inline-flex;
}
@mixin checkbox-handling {
    input[type="checkbox"] {                            //this is the old checkbox selection
        display: none;
    }
    input[type="checkbox"] + label {                    //these must be refactored to the new ag-icon ASAP
        @include flex-container;
        width: initial;
        position: static;                               //disable the possbile top , left, bottom and right properties set in views                                                                                                 
        
        &::before {
            @include inline-flex-container;            
            position: static;                           //disable the possbile top , left, bottom and right properties set in views                                                                                                                                                  
        }

        &::after {
            display: none;
        }                                                
    }

    input[type="checkbox"]:checked + label {
        padding: 0;
        margin: 0;

        &::before {
            @extend .ag-icon-checkbox-checked;
        }
    }
    input[type="checkbox"]:not(:checked) + label {
        padding: 0;
        margin: 0;
        
        &::before {                                                    
            @extend .ag-icon-checkbox-unchecked;
        }
    }
    input[type="checkbox"]:disabled:checked + label {
        padding: 0;
        margin: 0;

        &::before {
            @extend .ag-icon-checkbox-checked-disabled;
        }
    }
    input[type="checkbox"]:disabled:not(:checked) + label {
        padding: 0;
        margin: 0;

        &::before {
            @extend .ag-icon-checkbox-unchecked-disabled;
        }
    }
}
@mixin override-input {    
    color: white;
    background-color: black; 
    border: none;    
}
@mixin grouping-row-handling {
    span[class*="ag-row-group-indent-"] {       //grouping rows styling
        display: flex;                            
        justify-content: left;
        align-items: center;     
        margin: 0; 
        padding-left: 16px;                                                

        > span {                                //.ag-group-expanded, .ag-group-contracted, .ag-group-value all these are spans
            display: inline-flex;                            
            justify-content: left;
            align-items: center;
            margin: 0 4px 0 0;

            > * {
                margin: 0;
            }
        } 
        
        .ag-group-expanded , .ag-group-contracted {
            padding: 0;            
            background-color: white;
        }
    }
    
    .flex-container {
        label {
            margin: 0;
        }
    }
    .divider {
        flex-grow: 1;
    }
}
//abstract classes end

.sp-grid-old {
    //Structure up to V19    
    font-family: $font-family;            
    border-radius: 0.5em;
    overflow: hidden;  
    background-color: $white;    

    &.grid-border {
        border: $grid-border;
    }
    &.top-grid-border-not-rounded {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    &.bottom-grid-border-not-rounded {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;;
    }

    .ag-root-wrapper {                                      //ag-root-wrapper           => NO action
        .ag-root-wrapper-body {                             //ag-root-wrapper-body      => NO action            
            .ag-root {                                      //ag-root                   => NO action
                border: none;                

                .ag-header {
                    background: $grid-header-background;        
                    font-family: $font-family;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 18px;
                
                    .ag-header-row {
                        .ag-header-group-cell {
                            @include flex-container;    
                            font-weight: bold;
                            
                            .ag-header-group-cell-label {
                                margin: 0;
                                padding: 0;                   
                            }

                            &.no-border-bottom {
                                border-bottom: 0;
                            }
                        }     
                        .ag-header-group-cell-left {
                            justify-content: left;
                        }    
                        .ag-header-cell {                
                            border-right: $grid-border;
                            padding: 0;
                            text-transform: uppercase;
                           
                            &.align-center {
                                @include flex-container;
                            } 
            
                            .ag-cell-label-container {
                                @include flex-container;
            
                                .ag-header-icon {
                                    order: 2;
                                }
            
                                .ag-header-cell-label {
                                    padding-left: 4px;
                                }
                            }  
                        }

                        .header-checkbox-selection {         //will be removed
                            @include flex-container;
            
                            .ag-header-select-all {
                                @include flex-container;
            
                                & > * {
                                    @include flex-container;
                                }
                            }
            
                            .ag-cell-label-container, .ag-checkbox-label {
                                display: none;
                            }
                        }
                    }

                    .ag-pinned-left-header {
                        .ag-pinned-left-header {
                            .ag-header-row {
                                $some: 1;
                            }
                        }
                        .ag-header-viewport {
                            .ag-header-container {
                                .ag-header-row {
                                    .ag-header-group-cell {
                                        .ag-header-group-cell-label {
                                            $some: 1;
                                        }
                                    }
                                }
                            }
                        }
                        .ag-pinned-right-header {
                            $some: 1;
                        }
                    }
                }
                .ag-body {                                  //ag-body-viewport          => rename step 4
                    font-size: $font-size-normal;

                    .ag-body-viewport-wrapper {             //ag-center-cols-clipper    => rename step 3
                        .ag-body-viewport {                 //ag-center-cols-viewport   => rename step 2
                            .ag-body-container {            //ag-center-cols-container  => rename step 1
                                .ag-row {                   //row                       => NO action
                                    .ag-cell {              //ag-cell                   => NO action
                                        font-family: $font-primary;  
                                                                                                  
                                        &.align-center {
                                            @include flex-container;
                                        }

                                        &.checkbox-selection, &.center-horizontally {
                                            @include flex-container;

                                            .ag-cell-wrapper {
                                                @include flex-container;
                                            }

                                            > span {
                                                justify-content: center;
                                                padding: 0;
                                            }
                                        }                                                                            
                                    }                                         
                                }                                                                
                            }
                        }
                    }                                        
                    .ag-body-viewport-wrapper .ag-body-viewport .ag-body-container , 
                    .ag-full-width-viewport .ag-full-width-container .ag-row-group {             
                        .ag-row-hover, .ag-row-selected, .ag-row-focus {
                            color: white;
                            background-color: black;                   
                        }
                    } 
                }
                .ag-floating-bottom {
                    background-color: $gray;   
                    cursor: initial;
                    font-size: $font-size-normal;
                    font-weight: bold;

                    .ag-row {
                        .ag-cell {
                            background-color: $gray;                            
                        }
                    }                                    
                }

                .ag-row {                                   //common row styles
                    .ag-cell {
                        padding: 0 4px 0 4px;

                        .material-switch-container {
                            .material-switch-content {
                                height: 23px;

                                >input[type="checkbox"]:checked+label::after,
                                >input[type="checkbox"]:not(:checked)+label::after {
                                    width: 20px;
                                    height: 20px;
                                    top: 6px !important;
                                }

                                input[type="checkbox"]:checked+label::before,
                                >input[type="checkbox"]:not(:checked)+label::before {
                                    width: 40px;
                                    height: 16px;
                                    background-image: none;
                                }
    
                                .material-switch-label {
                                    @include inline-flex-container;
                                    height: 20px;
                                    position: relative;
                                    vertical-align: text-top;
                                }
                            }  
                        } 
                    }                                          
            
                    .checkbox-selection , .checkbox-cell {
                        @include flex-container;
            
                        .ag-cell-wrapper {                
                            padding: 0;
                            margin: 0;
                                    
                        }            
                    }    
                    .ag-cell-wrapper {
                        .ag-selection-checkbox {
                            @include flex-container;
        
                            * {
                                margin: 0;
                            }
                        }                 
                    }                                                                               
                }
            }
        }
        .ag-side-bar {     
            border: none;   
            .ag-side-buttons {
                display: none;
            }
    
            .ag-tool-panel-wrapper {            
                width: 300px;
                overflow-y: hidden;
                border-left: $grid-border;;
                
                .ag-column-panel {   
                    min-height: 0;     

                    .ag-pivot-mode-panel {
                        display: none;
                    }
    
                    .ag-column-select-panel {                                                   
                        .ag-primary-cols-header-panel {
                            display: none;
                        }
                        .ag-primary-cols-list-panel {
                            .ag-column-drag {
                                display: none;
                            }
                        }
                    }
    
                    .ag-column-drop {
                        display: none;
                    }
                }
            }
        }
        .ag-status-bar {
            display: none;                                  //status bars are displayed by default since version 19 , so we will hide it here
        }
        .ag-paging-panel {                                  //hidden by default
            $some: 1;
        }
        .ag-theme-fresh {                                   //filters
            .ag-menu {
                .ag-tab-header {
                    $some: 1;
                }
                .ag-tab-body {
                    padding: 7px;

                    .ag-filter {
                        @include flex-container;

                        > div {
                            width: 96%;
                        }

                        .ag-filter-body-wrapper {                            
                            .ag-filter-header-container {
                                margin: 0;    
                                label {
                                    padding: 0;
                                }                                
                                .ag-filter-value {
                                    font-weight: normal;
                                }                             
                            }                            

                            .ag-filter-body {
                                padding: 4px 0;                                
                            }

                            .ag-filter-filter {
                                width: 100%;
                                margin: 0;
                                padding: 0 7px;
                            }

                            .ag-filter-select {
                                width: 100%;
                                margin: 4px 0;                              
                                padding: 0;
                            }

                            .ag-filter-header-container {
                                label {
                                    margin: 0;

                                    .ag-filter-checkbox {
                                        top: 0;
                                    }
                                }
                            }

                            .ag-set-filter-list {
                                .ag-virtual-list-viewport {
                                    overflow-x: hidden;

                                    .ag-set-filter-item {
                                        @include flex-container-left;
                                        font-weight: normal;        
                                        
                                        .ag-filter-checkbox {
                                            padding: 0;
                                            top: 0;
                                        }
                                    }
                                }                                
                            }
                        }
                    }                    
                }
            }
        }
    }   
    
    //classes that override the settings above will come here
    &.smaller-font-size-ag-root {
        .ag-root-wrapper {                                      
            .ag-root-wrapper-body {                                         
                .ag-root {                                      
                    .ag-body {
                        font-size: $font-size-smaller;
                    }
                }
            }
        }        
    }
    
    //classes that override the settings above will come here
}

.sp-grid {
    //Structure up to V19
    font-family: $font-family;
    border-radius: 0.5em;
    overflow: hidden;
    background-color: $white;

    &.grid-border {
        border: $grid-border;
    }

    &.top-grid-border-not-rounded {
        border-top-left-radius: 0;
        border-top-left-radius: 0;
    }

    &.bottom-grid-border-not-rounded {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        ;
    }

    .ag-root-wrapper { //ag-root-wrapper           => NO action
        .ag-root-wrapper-body { //ag-root-wrapper-body      => NO action
            .ag-root { //ag-root                   => NO action
                border: none;

                .ag-header { //header section
                    background: $grid-header-background;
                    font-family: $font-family;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 18px;
                    text-transform: uppercase;

                    .ag-header-row {
                        @include flex-container-left;

                        .ag-header-group-cell {
                            @include flex-container;
                            font-weight: bold;

                            .ag-header-group-cell-label {
                                margin: 0;
                                padding: 0;
                            }

                            &.no-border-bottom {
                                border-bottom: 0;
                            }
                        }

                        .ag-header-group-cell-left {
                            justify-content: left;
                        }

                        .ag-header-cell {
                            border-right: $grid-border;
                            padding: 0 4px;
                            text-transform: uppercase;

                            &.align-center {
                                @include flex-container;

                                .generic-header, .ag-cell-label-container {
                                    @include flex-container;

                                    .ag-header-cell-label {
                                        justify-content: center;
                                    }
                                }
                            }

                            &.align-right {
                                @include flex-container;

                                .generic-header, .ag-cell-label-container {
                                    @include flex-container;

                                    .ag-header-cell-label {
                                        justify-content: right;
                                    }
                                }
                            }

                            .ag-cell-label-container {
                                @include flex-container-left;

                                .ag-header-icon {
                                    order: 2;
                                }
                            }

                            > span:not([class*="ag-row-group-indent-"]), > div:not(.ag-header-cell-resize) {
                                @include flex-container-left;

                                > span, > label {
                                    margin: 0;
                                }

                                &.ag-cell-wrapper {
                                    justify-content: center;
                                    padding: 0;
                                }

                                @include checkbox-handling;
                            }

                            .divider {
                                flex-grow: 1;
                            }

                            &.special-height-header-90 {
                                height: 90px !important;
                            }
                        }

                        .header-checkbox-selection {
                            @include flex-container;
                            padding: 0 !important;

                            .ag-header-select-all {
                                @include flex-container;

                                & > * {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }

                            .ag-cell-label-container, .ag-checkbox-label {
                                display: none !important;
                            }
                        }
                    }

                    .ag-pinned-left-header, .ag-header-viewport {
                        .ag-header-row {
                            .ag-header-cell {
                                @include flex-container;
                                @include checkbox-handling;
                                padding: 0 4px;

                                .ag-header-select-all {
                                    @include flex-container;

                                    .ag-checkbox-unchecked, .ag-checkbox-checked, .ag-checkbox-indeterminate {
                                        @include flex-container;
                                    }
                                }

                                &.no-header-label {
                                    .ag-cell-label-container {
                                        display: none;
                                    }
                                }

                                > div:not(.ag-header-cell-resize) { //the resize element should maintain its initial size
                                    @include flex-container-left;
                                }
                            }
                        }

                        .ag-header-viewport {
                            .ag-header-container {
                                .ag-header-row {
                                    .ag-header-group-cell {
                                        .ag-header-group-cell-label {
                                            $some: 1;
                                        }
                                    }
                                }
                            }
                        }

                        .ag-pinned-right-header {
                            $some: 1;
                        }
                    }
                }

                .ag-body { //body section                  ag-body-viewport          => rename step 4
                    font-size: $font-size-normal;

                    .ag-body-viewport-wrapper { //ag-center-cols-clipper    => rename step 3
                        .ag-body-viewport { //ag-center-cols-viewport   => rename step 2
                            .ag-body-container { //ag-center-cols-container  => rename step 1
                                .ag-row { //row                       => NO action
                                    .ag-cell { //ag-cell                   => NO action
                                        @include flex-container-left;
                                        font-family: $font-primary;
                                        margin: 0;
                                        padding: 0 4px 0 4px; //default padding

                                        input, select, textarea {
                                            font-size: $font-size-normal;
                                        }

                                        &.no-padding { //overrides default padding
                                            padding: 0;
                                        }

                                        &.disable-checkbox-event {
                                            pointer-events: none;
                                            opacity: 0.5;
                                        }

                                        > span:not([class*="ag-row-group-indent-"]), > div {
                                            @include flex-container-left;

                                            > span, > label {
                                                margin: 0;
                                            }

                                            &.ag-cell-wrapper {
                                                justify-content: center;
                                                padding: 0;
                                            }

                                            @include checkbox-handling;

                                            field-validation {
                                                @include flex-container-left;

                                                > div {
                                                    @include flex-container-left;
                                                }
                                            }
                                        }

                                        @include grouping-row-handling;
                                    }

                                    &.ag-row-group { //we should not apply to the grouping row the above settings
                                        span[class*="ag-row-group-indent-"] {
                                            margin: 0;
                                            padding: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .ag-full-width-viewport {
                        .ag-full-width-container {
                            .ag-row-group {
                                @include flex-container-left;
                                background-color: $gray;
                                font-size: $font-size-normal;

                                .ag-cell {
                                    padding: 0 4px 0 4px;
                                }

                                @include grouping-row-handling;
                            }
                        }
                    }

                    .ag-body-viewport-wrapper .ag-body-viewport .ag-body-container,
                    .ag-full-width-viewport .ag-full-width-container .ag-row-group,
                    .ag-pinned-left-cols-viewport-wrapper .ag-pinned-left-cols-viewport .ag-pinned-left-cols-container {
                        .ag-cell {
                            &.align-right {
                                justify-content: right;
                                text-align: right;

                                > span, > div, input {
                                    justify-content: right;
                                    text-align: right;
                                }
                            }

                            &.align-left {
                                justify-content: flex-start;
                                text-align: left;

                                > span, > div, input {
                                    justify-content: flex-start;
                                    text-align: left;
                                }

                                .ag-cell-wrapper {
                                    justify-content: flex-start;

                                    .ag-selection-checkbox {
                                        justify-content: flex-start;
                                    }
                                }
                            }

                            &.align-center {
                                justify-content: center;
                                text-align: center;

                                > span, > div, input {
                                    justify-content: center;
                                    text-align: center;
                                }
                            }

                            &.checkbox-selection, &.center-horizontally, &.checkbox-cell {
                                @include flex-container;

                                .ag-cell-wrapper {
                                    @include flex-container;
                                }

                                > span {
                                    justify-content: center;
                                    padding: 0;
                                }
                            }
                        }

                        .ag-row-hover, .ag-row-selected, .ag-row-focus {
                            color: white;
                            background-color: black;

                            .ag-group-expanded, .ag-group-contracted {
                                span {
                                    background-color: white !important;
                                }
                            }

                            button:not(.pen-icon, .graph-icon):not(.btn), input, optgroup, select, textarea {
                                color: black;
                                background-color: white;
                            }

                            .override-input {
                                @include override-input;

                                input, select, textarea {
                                    @include override-input;
                                }
                            }
                        }
                    }
                }

                .ag-floating-bottom {
                    background-color: $gray;
                    cursor: initial;
                    font-size: $font-size-normal;
                    font-weight: bold;
                    border-top: $grid-border;

                    .ag-floating-bottom-viewport {
                        border: none;

                        .ag-cell {
                            @include flex-container-left;

                            &.align-center {
                                justify-content: center;
                            }

                            &.align-right {
                                justify-content: right;
                            }
                        }
                    }

                    .ag-row {
                        .ag-cell {
                            background-color: $gray;
                        }
                    }
                }

                .ag-row { //common row styles
                    .ag-cell {
                        &:disabled * {
                            background-color: $lightgray;
                        }

                        input:not([type="checkbox"]):not([type="radio"]):not([type="date"]), select, textarea {
                            border: 1px solid $darkgray;
                            border-radius: 4px;
                            color: black;
                            background-color: white;
                            width: 100%;
                            height: calc(100% - 2px);
                            padding: 0 4px 0 4px;
                            margin: 0;
                            display: inline-flex;
                            justify-content: left;
                            align-items: center;
                        }

                        input[type="checkbox"], input[type="radio"] {
                            margin: 0;
                        }

                        &.contains-text {
                            span {
                                display: block !important;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }
                    }

                    &:not(.ag-row-hover):not(.ag-row-selected):not(.ag-row-focus) {
                        input:not([type="checkbox"]):not([type="radio"]):not([type="date"]), select, textarea {
                            &:disabled {
                                background-color: $lightgray;
                            }
                        }
                    }

                    .checkbox-selection, .checkbox-cell {
                        @include flex-container;

                        .ag-cell-wrapper {
                            padding: 0;
                            margin: 0;
                        }
                    }

                    .ag-cell-wrapper {
                        .ag-selection-checkbox {
                            @include flex-container;

                            * {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .ag-side-bar {
            border: none;

            .ag-side-buttons {
                display: none;
            }

            .ag-tool-panel-wrapper {
                width: 300px;
                overflow-y: hidden;
                border-left: $grid-border;
                ;

                .ag-column-panel {
                    min-height: 0;

                    .ag-pivot-mode-panel {
                        display: none;
                    }

                    .ag-column-select-panel {
                        .ag-primary-cols-header-panel {
                            display: none;
                        }

                        .ag-primary-cols-list-panel {
                            .ag-column-drag {
                                display: none;
                            }
                        }
                    }

                    .ag-column-drop {
                        display: none;
                    }
                }
            }
        }

        .ag-status-bar {
            display: none; //status bars are displayed by default since version 19 , so we will hide it here
        }

        .ag-paging-panel { //hidden by default
            $some: 1;
        }

        .ag-theme-fresh { //filters
            .ag-menu {
                .ag-tab-header {
                    $some: 1;
                }

                .ag-tab-body {
                    padding: 7px;

                    .ag-filter {
                        @include flex-container;

                        > div {
                            width: 96%;
                        }

                        .ag-filter-body-wrapper {
                            .ag-filter-header-container {
                                margin: 4px 0;

                                .ag-filter-value {
                                    font-weight: normal;
                                }

                                label {
                                    margin: 0;
                                    padding: 0;

                                    .ag-filter-checkbox {
                                        top: 0;
                                    }
                                }
                            }

                            .ag-filter-body {
                                padding: 4px 0;
                            }

                            .ag-filter-filter {
                                width: 100%;
                                margin: 0;
                                padding: 0 7px;
                            }

                            .ag-filter-select {
                                width: 100%;
                                margin: 4px 0;
                                padding: 0;
                            }

                            .ag-set-filter-list {
                                .ag-virtual-list-viewport {
                                    overflow-x: hidden;

                                    .ag-set-filter-item {
                                        @include flex-container-left;
                                        font-weight: normal;

                                        .ag-filter-checkbox {
                                            padding: 0;
                                            top: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.multiline { //handle multiline rows
        .ag-cell, .ag-header-cell {
            > span, .generic-header {
                @include flex-container;
                @include checkbox-handling;
                padding: 0 4px;
                flex-direction: column;
                align-items: flex-start;

                > *, input, select, textarea {
                    height: 100%;
                    width: 100%;
                }

                > :not(:last-child) {
                    border-bottom: $grid-lighter-border;
                }

                .divider {
                    height: 100%;
                    border-right: $grid-lighter-border;
                }

                .multiline-row {
                    @include flex-container-left;

                    input[type="checkbox"] + label {
                        width: initial !important;
                        margin-left: 4px !important;

                        & + span, & + label {
                            margin-left: 4px !important;
                        }
                    }

                    input[type="radio"] {
                        width: initial !important;
                        flex-grow: 0;
                        margin-left: 4px !important;

                        & + span, & + label {
                            margin: 0 0 0 4px !important;
                        }
                    }
                }
            }
        }

        .generic-header {
            > *:not(.multiline-row) {
                padding-left: 4px;
            }

            .multiline-row {
                > *:not(.divider) {
                    margin-left: 4px;
                }
            }
        }
    }
}

.sp-grid, .sp-grid-old {
    height: 300px;                                          //default height for grid

    input[type="date"]  {        
        // &:hover::-webkit-calendar-picker-indicator {
        //     background: url(../../content/images/svg/calendar_today_white.svg) no-repeat; 
        // }

        &:disabled {            
            &::-webkit-calendar-picker-indicator {
                display: none;                
            }
        }
    }   

    .ag-row-hover , .ag-row-selected , .ag-row-focus {
        input[type="date"] {
            // &::-webkit-calendar-picker-indicator {
            //     background: url(../../content/images/svg/calendar_today_white.svg) no-repeat; 
            // }
    
            &:disabled {
                background-color: black !important;
                color: white !important;
                &::-webkit-calendar-picker-indicator {
                    display: none;
                }
            }            
        }
       
        .grey-cell-style {
            background-color: black;
        }
    
        .icon-user {
            background-image: url(../../../content/images/svg/User_Menu_white.svg);
        }
       
        .trimble-remove {
            content: url("../../../../content/images/svg/Delete_Entity_White.svg");
        }

        .lock-closed-icon {
            background-image: url(../../content/images/svg/OfflineLockWhiteClosed.svg);
        }
    }

    .ag-row.ag-row-group.ag-row-hover {
        color: $black;
    }    

    //classes that override the settings above will come here
    &.smaller-font-size-ag-root {
        .ag-root-wrapper {                                      
            .ag-root-wrapper-body {                                         
                .ag-root {                                      
                    .ag-body {
                        font-size: $font-size-smaller;                      
                    }
                }
            }
        }        
    }           
    &.input-border-none {
        input, select, textarea {
            border: none !important;
        }
    }    
    &.no-row-selection {
        .ag-row {
            color: initial !important;
            background-color:initial !important;                                          
        }
    }        
    &.ag-floating-bottom-no-border {
        .ag-floating-bottom {
            border: none !important;
        }
    }
    &.no-text-in-pinned-left-header {
        .ag-pinned-left-header {
            .ag-header-group-text {
                display: none;
            }
        }
    }
    &.ag-row-border-bottom {
        .ag-row {
            border-bottom: $grid-border !important;
        }
    }    
    //classes that override the settings above will come here
}

.sp-grid-container {
    border: $grid-border;
    border-radius: 0.5em;      
    
    .grid-buttons {
      border-top: $grid-border;
      border-bottom-right-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
      padding: 0.5em;
      margin: 0 !important;
      background: $lightgray;
    }

    .grid-container-footer {
        display: flex;
        justify-content: left;                                              //elements are aligned horizontally to left   
        align-items: center;
        width: 100%;
        height: 100%;
        padding-left: 0.5em;                                                //padding left equivalent to apply margin left to the first child 
        background-color: $lightgray;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;                

        > * {
            margin: 0.5em 0.5em 0.5em 0;                                    //the rest of the elements have the same margin right
        }
        
        .align-right {
            margin-left: auto;                                              //apply this to the first element starting with which you want to align to right
        }

        &.border-top {
            border-top: $grid-border;
        }

        &.border-bottom {
            border-bottom: $grid-border;
        }
    }    
}

//generic classes 
.input-background-inherit {
    .ag-body {
        input , select, textarea, .ag-cell {            
            background-color: inherit !important;
        }  
        
        .ag-row-hover, .ag-row-selected, .ag-row-focus {        
            input, select, textarea, .ag-icon, .ag-cell {            
                color: white !important;
            } 

            input.invalid {
                background-color: $lightred !important;
            }
            
            select.invalid {
                background-color: $lightred !important;
            } 
        }
    }
}
.top-grid-border-not-rounded {
    .sp-grid {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
.bottom-grid-border-not-rounded {
    .sp-grid {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
.ag-floating-bottom-no-border {
    .ag-floating-bottom {
        border: none !important;
    }
}
.border-top {
    border-top: $grid-border !important;
}
.inputs-not-distinguished {
    .ag-body {
        input, select, textarea {
            border: none !important;
            background-color: inherit !important;
        }

        input.invalid {
            background-color: $lightred !important;
        } 

        select.invalid {
            background-color: $lightred !important;
        } 

        .ag-row-hover, .ag-row-selected, .ag-row-focus {        
            input, select, textarea, .ag-icon, .ag-cell {            
                color: white !important;
                background-color: black !important;

                select option {
                    color: black;
                    background-color: white;
                }

                input.invalid {
                    background-color: $lightred !important;
                } 

                select.invalid {
                    background-color: $lightred !important;
                } 
            }      
            
            .ag-group-expanded , .ag-group-contracted {
                span.ag-icon {                
                    background-color: white !important;
                }                                
            }
        }

        input[type="date"]  {                            
            &::-webkit-calendar-picker-indicator {
                display: none;
            }            
        }
    }
}
.border-none {
    border: none !important;
}
//generic classes
