.pdf-test {

	&.print {
		color: red;
		display: none;
	}

}

@media print {

	//In here we can also place additional styling that only applies to prints

	.pdf-test {
		&.no-print {//Hide other parts of the page
			display: none;
		}
		&.print {//Show the print area
			display: initial;
		}
	}
}