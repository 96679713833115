﻿.work-site-sync-modal-window {
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .float-left {
        text-align: left;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .payment-message-section {
        background-color: #E2E2E7;
        padding-left: 14px;
        padding-top: 15px;
        padding-bottom: 15px;

        .label-font {
            margin-bottom: 0px;
        }
    }

    .grid-container {
        margin: 1em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-radius: 0.5em;
            height: 300px;

            #centerRow {
                border-radius: 0.5em !important;
            }

            .ag-header-group-cell-label {
                text-transform: uppercase;
                text-align: left;
            }

            .master-cell-header, .ag-header-group-cell, .ag-row, .ag-body-container {
                width: 100% !important;
                border-right: 0;
            }
        }
    }

    .no-right-border {
        border-right: none;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .form-group {
        padding: 0;
    }

    .fixed-size {
        margin: 7px;
        width: 150px;
        height: 40px;
    }

    .label-font {
        font-weight: 100 !important;
    }

    .visibility-hidden{
        visibility: hidden;
    }
}
