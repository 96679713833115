﻿.edit-product-exception-list-modal {
    .modal-dialog {
        width: 80%;
        top: 22%;

        .modal-body {
            padding: 0;
        }
    }

    .ag-row.ag-row-group.ag-row-hover {
        background-color: $gray !important;
        color: $black !important;
    }

    .sp-grid {
        .ag-body .ag-body-viewport-wrapper .ag-body-viewport .ag-body-container .ag-row-group {
            display: flex;
            justify-content: left;
            align-items: center;
            background-color: $gray;
            font-size: 12px;
            padding: 0 0 0 4px;
        }

        .ag-body {
            .ag-row-group.ag-row-hover {
                .ag-cell {
                    background-color: transparent !important;
                    color: $black !important;
                }
            }
        }

        .ag-row-group {
            .ag-row-hover, .ag-row-selected, .ag-row-focus {
                background-color: transparent !important;
                color: $black !important;
            }
        }
    }

    #editProductExceptionList {
        height: 450px;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid $half_trimble_brand_grey;
        z-index: 0;

        .ag-theme-fresh .sp-grid {
            z-index: 0;
        }

        .ag-layout-normal {
            z-index: 0;
        }

        .ag-row {
            z-index: 0;
        }

        .ag-row.ag-row-focus {
            z-index: 1;
        }

        .ag-header {
            background: $trimble-gradient-grey-header;
        }

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 3px 3px 0px 0px;
        }

        .input-element {
            input {
                padding-top: 0;
                margin-bottom: 2px;
                margin-left: 0px;
                color: black;
                width: 100%;
                border: none;

                &.input-with-border {
                    border: $grid-border;
                    border-radius: 4px;
                }

                &.invalid-price {
                    background-color: $lightred;
                }
            }
        }

        .invalid-price {
            background-color: $lightred;
        }

        .default-quality {
            pointer-events: none;
        }

        .ag-header-cell {
            padding-top: 2px;
        }

        .ag-column-hover {
            .input-element input {
                background-color: $white;
            }
        }

        .ag-row-hover {
            .input-element input {
                background-color: $white;
            }
        }

        .remove-header {
            margin-top: 0px;
            margin-left: 5px;
        }

        .remove-column {
            margin: -2px auto;
            margin-left: 5px;
            background-position: unset;
        }

        .ag-header-group-cell {
            text-transform: uppercase;
        }
    }

    .custom-drop-down {
        .dropdown-menu {
            top: 100% !important;
            left: 245px !important;
            width: 80em !important;
            z-index: 9999 !important;

            .custom-table .table-row {
                display: flex;
                color: black;
                z-index: 99999 !important;
            }
        }

        .dropdown-menu::after {
            z-index: 9999 !important;
        }
    }

    .dropDown400px {
        position: relative;
        width: 129%;
        margin-bottom: 5px;

        .dropdown-menu {

            .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12,
            .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5,
            .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9,
            .col-md-1, .col-md-10, .col-md-11, .col-md-12,
            .col-md-2, .col-md-3, .col-md-4, .col-md-5,
            .col-md-6, .col-md-7, .col-md-8, .col-md-9,
            .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12,
            .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5,
            .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9,
            .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12,
            .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5,
            .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
                min-height: 18px;
                padding-right: 0px;
                padding-left: 5px;
            }

            .table-row:not(.table-header):hover {
                .col-xs-4:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-3:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-2:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-1:not(.grey-background) {
                    background-color: dodgerblue !important;
                }
            }
        }

        .btn {
            height: 23px;
            padding-top: 1px;

            span {
                float: left;
            }
        }

        button[disabled], button.btn[disabled] {
            border: 1px solid #616161;
        }

        background-color: white;
    }

    .first-row {
        height: 45px;
        background-color: $gray;

        .product-name {
            height: auto;
            float: left;
            padding-left: 15px;
            padding-top: 15px;
            margin-right: 15px;
        }

        .assortment-label {
            float: left;
            font-weight: bold;
            margin-right: 5px;
        }
    }

    .second-row {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .button-left-margin {
        margin-left: 0.5em;
    }    
}

.popover {
    z-index: 9999 !important;
}
