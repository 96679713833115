@import '../../../../../../shared/sass/variables';

.general-information-accordion {
    .left-border-radius {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }

    .orange-btn {
        background-color: #ff9400;
    }

    .green-btn {
        background-color: $trimble_tf_product_green;
    }

    .orange-btn:hover {
        background-color: black;
    }

    .green-btn:hover {
        background-color: black;
    }

    .hr-margin {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .sync-style {
        padding: 5px;
        border-radius: 4px;
    }

    .sync-position {
        padding: 0;
        padding-left: 11px;

        .btn {
            padding: 0px;
            border-width: 0px;
        }
    }

    @media screen and (max-width: 1800px) {
        .sync-position {
            padding-left: 6px;
        }
    }

    @media screen and (max-width: 1600px) {
        .sync-position {
            padding-left: 0px;
        }
    }

    @media screen and (max-width: 1300px) {
        .sync-position {
            margin-left: -7px;
        }
    }

    .general-info {
        .col-xs-3,
        .col-xs-2,
        .col-xs-9 {
            padding: 0;
            padding-left: 15px;
        }

        .text-align-vertically {
            padding-right: 0;
            text-overflow: ellipsis;
        }

        input {
            margin: 0;
        }
    }

    .tabs-position {
        .tabs-wrapper {
            margin-left: 11px;
            margin-right: 11px;
        }

        .row {
            display: flex;

            label {
                @include flex(row, flex-start, flex-start);
                width: 18%;
                margin-right: 35px;
                padding-left: 15px;
            }

            .input-group {
                width: 100%;

                input.form-control {
                    &.postal-code {
                        width: 5em;
                        margin: 0px;
                    }

                    &.city {
                        margin: 0px;
                        width: calc(100% - 5em);
                    }

                    &.puukauppa-xs {
                        width: 7em;
                        margin: 0px;
                    }

                    &.puukauppa-s {
                        margin: 0px;
                        width: calc(100% - 7em);
                    }
                }
            }

            .input-wrapper {
                width: 72%;
                margin-right: 15px;

                .input-group {
                    display: flex;
                    flex-direction: row;
                }
            }

            .address-wrapper {
                display: flex;
                flex-direction: column;
                width: 72%;

                label {
                    @include flex(row, flex-start, center);
                    margin-right: 15px;
                }

                .input-wrapper {
                    width: 100%;
                }
            }
        }
    }

    .small-horizontal-padding {
        padding-left: 7px;
        padding-right: 2px;
    }

    .glyphicon-envelope {
        font-size: 20px;
        color: $trimble_tf_product_green;
        margin-top: -26px;
        width: 10%;
        line-height: 26px;
        text-align: center;
        vertical-align: middle;
        border: 0px;
        border-left: 1px solid $darkgray;
    }

    .ng-invalid {
        background-color: $lightred;
    }

    .email-input {
        width: 99.5%;
        padding: 0px !important;
        border: 1px solid $darkgray !important;

        input {
            width: 90%;
            border: 0px;
            height: 100%;
            padding-left: 0.2em;
            border: none !important;

            &:not(.ng-invalid) {
                background: none;
            }

            &.ng-invalid {
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
            }
        }
    }

    .los-validation {
        .field-validation-tooltip {
            margin-top: 0px !important;
        }
    }

    .pno-validation {
        .field-validation-tooltip {
            left: -65px !important;
        }
    }

    .ptp-validation {
        .field-validation-tooltip {
            left: 110px !important;
        }
    }

    .puhMatka-validation {
        .field-validation-tooltip {
            top: 0px !important;
        }
    }

    .puhTyo-validation {
        .field-validation-tooltip {
            top: 0px !important;
        }
    }

    .sahkoposti-validation {
        .field-validation-tooltip {
            left: 450px !important;
        }
    }

    .puhKoti-validation {
        .field-validation-tooltip {
            top: 0px !important;
        }
    }

    .light-grey-background {
        background-color: #eee;
        padding-right: 7px;
        padding-left: 12px;
    }

    .grid-bottom {
        padding-top: 7px;
        padding-left: 13px;
        padding-right: 0px;
        padding-bottom: 5px !important;
    }

    .arrow-container {
        position: relative;
        height: 20px;

        .main-registry-position {
            position: absolute;
            height: 21px;
            left: calc(44% + 74px);
            width: calc(41.25% - 89px);

            @media screen and (max-width: 1600px) {
                .arrows {
                    margin-left: -12px;
                    margin-right: -4px;

                    .arrow-box-right {
                        margin-right: -4px;
                    }
                }
            }

            @media screen and (max-width: 1300px) {
                .arrows {
                    margin-left: -21px;
                    margin-right: -7px;

                    .arrow-box-right {
                        margin-right: -7px;
                    }
                }
            }
        }

        .registry-position {
            position: absolute;
            height: 21px;
            left: calc(41% + 3px);
            width: calc(41.25% - 92px);

            @media screen and (max-width: 1600px) {
                margin-left: 13px;

                .arrows {
                    margin-left: -12px;
                    margin-right: -2px;

                    .arrow-box-right {
                        margin-right: -2px;
                    }
                }
            }

            @media screen and (max-width: 1300px) {
                .arrow-text-wrapper {
                    margin-left: 14px;
                }

                .arrows {
                    margin-right: -13px;
                    margin-left: -12px;

                    .arrow-box-right {
                        margin-right: -14px;
                    }
                }
            }
        }

        .forest-plan-position {
            position: absolute;
            left: 9px;
            height: 21px;
            top: 21px;
            width: 42.5%;

            .mapIsExpanded & {
                left: 8px;
            }
        }

        .arrows {
            margin-top: 9px;
            border-top: 1px solid $green;
            height: 100%;
        }

        .arrow-box-left {
            position: relative;
            display: inline-block;
            height: inherit;
            border-left: 1px solid $green;

            &.registry {
                width: 45px;
            }

            &.forest-plan {
                width: 28px;

                .mapIsExpanded & {
                    width: 25px;
                }
            }

            &:after {
                top: 100%;
                border: solid transparent;
                content: "";
                position: absolute;
                border-top-color: $green;
                border-width: 5px;
                left: -5px;
            }
        }

        .arrow-text-wrapper {
            text-align: center;
            position: absolute;
            width: 100%;
            display: inline-block;
            vertical-align: top;
            color: $green;
        }

        .arrow-text {
            background: #eee;
            padding: 0 4px;
            display: inline-block;
        }

        .arrow-box-right {
            position: relative;
            display: inline-block;
            height: inherit;
            border-right: 1px solid $green;
            right: 0;
            position: absolute;

            &.registry {
                width: 45px;
            }

            &.forest-plan {
                width: 28px;

                .mapIsExpanded & {
                    width: 25px;
                }
            }

            &:after {
                top: 100%;
                border: solid transparent;
                content: "";
                position: absolute;
                border-top-color: $green;
                border-width: 5px;
                left: calc(100% - 5px);
            }
        }
    }

    .house-special-width {
        width: 12.5%;
    }

    .empty-label {
        width: 9%;
    }

    @media screen and (max-width: 1300px) {
        .empty-label {
            width: 7%;
        }
    }

    .estate-field {
        width: 12.5%;
        padding-left: 1%;
    }

    .main-mala-align {
        padding-left: 3px;
    }

    .grid-padding {
        padding-left: 0px;
        padding-right: 2px;
    }

    .grey-background {
        background-color: #d1d1d1;
    }

    .dropDown30vw {
        .dropdown-menu {
            width: 30vw !important;

            .col-lg-1,
            .col-lg-10,
            .col-lg-11,
            .col-lg-12,
            .col-lg-2,
            .col-lg-3,
            .col-lg-4,
            .col-lg-5,
            .col-lg-6,
            .col-lg-7,
            .col-lg-8,
            .col-lg-9,
            .col-md-1,
            .col-md-10,
            .col-md-11,
            .col-md-12,
            .col-md-2,
            .col-md-3,
            .col-md-4,
            .col-md-5,
            .col-md-6,
            .col-md-7,
            .col-md-8,
            .col-md-9,
            .col-sm-1,
            .col-sm-10,
            .col-sm-11,
            .col-sm-12,
            .col-sm-2,
            .col-sm-3,
            .col-sm-4,
            .col-sm-5,
            .col-sm-6,
            .col-sm-7,
            .col-sm-8,
            .col-sm-9,
            .col-xs-1,
            .col-xs-10,
            .col-xs-11,
            .col-xs-12,
            .col-xs-2,
            .col-xs-3,
            .col-xs-4,
            .col-xs-5,
            .col-xs-6,
            .col-xs-7,
            .col-xs-8,
            .col-xs-9 {
                min-height: 18px;
            }

            .table-row:not(.table-header):hover {
                .col-xs-3:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-2:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-1:not(.grey-background) {
                    background-color: dodgerblue !important;
                }
            }
        }

        .btn {
            height: 27px;
            padding-top: 3px;
        }

        background-color: white;

        &.ng-invalid-required {
            background-color: $lightred;
        }
    }

    .half-width {
        width: 45%;
    }

    .grid-text-right {
        text-align: right;
        padding-right: 3px;
        padding-left: 0px;
    }

    .colon-align {
        margin-top: 3px;
        margin-left: 4px;
    }

    .realestate-align {
        margin-top: 20px;
    }

    .font-size-s {
        font-size: 12px;
    }

    .left-cell-padding {
        padding-left: 3px;
        padding-right: 0px;
    }

    .country-code {
        white-space: pre;
        padding-right: 30px;
    }

    .country-dropdown {
        padding-left: 32px;
    }

    .countryDropDown30vw {
        .custom-drop-down > div {
            width: 11.5em;
        }

        .dropdown-menu {
            .col-xs-3 {
                min-height: 18px;
            }

            .table-row:not(.table-header):hover {
                .col-xs-3:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-2:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-1:not(.grey-background) {
                    background-color: dodgerblue !important;
                }
            }
        }

        .btn {
            height: 27px;
            padding-top: 3px;
        }

        background-color: white;

        &.ng-invalid-required {
            background-color: $lightred;
        }
    }
}
