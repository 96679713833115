/* Primary colors */
$white: #ffffff;
$black: #000000;
$gray: #d1d1d1;
$lime: #83c557;
$blue: #0e77aa;
$red: #CC6565;
$green: #00623A;

/* Accent colors */
$darkgray: #616161;
$darkgreen: #008752;
$darkblue: #92afd2;
$orange: #ff9400;
$lightblue: #a3d2e5;
$lightgray: #e8e8e8;
$lightred: #FFD8D8;

/* Font face */
$font-primary: 'Myriad Pro';
$font-secondary: 'Verdana';
$font-semibold: 'Myriad Pro Semibold';

/* Sizes */
$media-desktop: 1200px;
$media-mobile: 768px;

$grid-header-background : linear-gradient(rgba(0,0,0,.075), #d3d3d3);
$grid-border: 1px solid #808080;
$grid-lighter-border: 1px solid $gray;
$font-family: 'Myriad Pro', Verdana;
$default-font-size: 12px;
$disabled-background-color: #e8e8e8;
$disabled-input-color: #ddd;
$lighter-grey-color:  #efefef;
$turquise: rgb(6,197,172);
$disabled-button-background-color: #eee;
$font-size: 14px;
$field-border-color: #707070;

/*Trimble colors*/
$trimble_dk_blue: #002D5B; //header bar button hover
$trimble_blue: #00437B; //used where needed
$trimble_blue_mid: #005F9E; //button hover, breadcrumb link
$trimble_orange: #FFA500;
$trimble_orange_hover: #FF8B00;
$trimble_green: #3AAE2C;
$trimble_green_hover: #1E8A44;
$trimble_grey: #363545; //navigation icon and text color
$trimble_brand_grey: #767876; //icon color
$half_trimble_brand_grey: #B0AFB0; //separator line color
$trimble_grey_light: #E5E5E5; //small icon button hover color
$trimble_blue_grey_light: #E2E2E7; //grid's sum row background color
$trimble_very_light_grey: #F3F3F7;  //background and modal window's header color
$trimble_very_very_light_grey: #F9F9FB; //menu item's hover color
$trimble_white: #FFFFFF; //general color
$trimble_black: #000000; //general color
$trimble_tf_product_green: #3AAE2C; //main accent color //rgb(58,174,44)
$trimble_forestry_orange: #FFA500; //secondary accent color
$trimble_blue_light: #009AD9; //button default color
$trimble_button_disabled: rgba($trimble_blue_light, 0.5); //button disabled bg color
$trimble_header_shadow_color: rgba(0, 0, 0, 0.29);//shadow color from XD file
$trimble_header_icon_shadow_color: rgba(0, 0, 0, 0.21);//shadow color for show/hide icon

$trimble_grey_shadow: rgba($trimble_grey, 0.5);
$trimble_gradient_blue_start: rgba(0,67,123,1);
$trimble_gradient_blue_end: rgba(0,95,158,1);

$trimble-gradient-grey-header: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;