﻿.map-layers {

	&.panel {
		top: .2em;
		left: .2em;
		bottom: .2em;
		margin: 0;
		display: flex;
		flex-direction: column;

		.header-wrapper {
			padding: 1em 0.5em;
			border-bottom: 1px solid #808080;

			.logo {
				height: 1.5em;
				margin-left: 5px;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		flex-basis: 35px;
		flex-grow: 1;
		margin-left: 0;
		margin-right: 0;
		margin-top: 0;

		.format-data {
			width: 40px;
			background-color: inherit;
			border: none;
		}
		.align-right {
			text-align:right;
			margin-left: -4px
		}
		.table-header {
			background: $gray;
			justify-content: center;
			padding: .2em;
			padding-top: 10px;
			padding-bottom: 10px;
			height: 50px;

			img {
				height: 2.1em;
				padding-right: 3px;
				cursor: pointer;
			}

			.align-totals {
				padding-left: 8px;
				padding-top: 6px;
			}
		}

		.warning-message {
			padding-top: 10px;
			padding-left: 10px;
			padding-right: 5px;

			span {
			font-style: italic;
			display: block;
		}
		}

		.selected-compartments {
			padding-top: 10px;
		}

		.header-padding {
			padding-left: 10px;
			padding-right: 10px;
		}

		.header-padding-s {
			padding-left: 0px;
			padding-right: 10px;
		}

		.cell-padding {
			padding-left: 10px;
			padding-right: 10px;
		}

		.cell-padding-s {
			padding-left: 0px;
			padding-right: 10px;
		}

		.no-padding {
			padding-left: 0px;
			padding-right: 0px;
		}

		.remove-padding {
			padding-right: 10px;
		}

		.truncate {
			text-overflow: ellipsis;
			width: 130px;
			white-space: nowrap;
			overflow: hidden;
			font-weight: 200;
		}

		.kha-font{
			font-weight: 200!important;
		}
		.scrollable-area {
			overflow: auto;
			height: 70%;
		}
	}

	.top-margin {
		margin-top: 3px;
	}
	.lbl-align {
		margin-bottom: 4px;
		margin-top: 2px;
	}
	.lbl-padding {
	   padding-left: 3px;
	}
	.cb-padding {
		padding-left: 5px;
	}
	.cb-lbl-padding {
		padding-left: 10px;
		padding-right: 0px;
	}

	.bottom-buttons {
		border-top: 1px solid $darkgray !important;
		background: $gray;
		overflow: hidden;
		justify-content: center;
		padding: .2em;
		border-bottom-left-radius: 0.5em;
		border-bottom-right-radius: 0.5em;		
        position: absolute;
        bottom: 0;
        width: inherit;

		> .wrapper {
			flex: 1;
			justify-content: center;
			align-items: center;
			padding: .5em;
			border-left-color: transparent;

			> .btn {
				padding-top: .4em;
				padding-bottom: .4em;
				width: 273px;
			}
		}
	}

	.block {
		padding: .5em 1em;
	}

	.col {
		&.wrapper {
			display: flex;
			flex: 1;
			border-left: 1px solid $gray;
			padding-left: 8px;
			padding-right: 8px;

			&:first-of-type {
				border-left: 0;
			}
		}
	}

	.row {
		display: flex;
		flex-flow: row;
		margin: 0;

		&.last-content-row {
			border-bottom: none;
		}
	}
}
