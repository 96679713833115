﻿.product-info-modal-window {

	.title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

	.product-info-texttitle {
	    font-family: $font-semibold;
	}

    .product-info-textarea {
		height: 120px;
		width: 570px;
		resize: none;
	}

    .form-group {
        padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
		padding-bottom: 5px;

		.padded-container {
			padding: 0 !important;
		}
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }
}
