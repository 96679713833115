@import '../../../../shared/sass/_variables.scss';

.ready-to-harvest-modal {
    .information-header {
        height: 40px;
        line-height: 40px;
        background-color: $lightgray;
    }

    .into-title {
        font-size: 14px;
        display: inline-block;
    }

    .grid-container {
        border: 0.75px solid $trimble_brand_grey;
        border-radius: 5px;
        border-bottom-width: 0px;

        .modal-grid {
            height: 190px;

            .ag-theme-fresh .ag-menu {
                height: 185px;
            }
        }

        .invalid-cell-value {
            background-color: $lightred;
        }

        .ag-row-focus,
        .ag-row-hover {
            .invalid-cell-value {
                background-color: $lightred;
                color: $black;
            }
        }

        .ag-floating-bottom {
            border-top: 0.75px solid $trimble_brand_grey;
            border-bottom: 0.75px solid $trimble_brand_grey;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            .ag-floating-bottom-viewport .ag-floating-bottom-container .ag-cell {
                padding-left: 4px;
            }
        }
    }

    .general-information-text {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 15px;
        line-height: 40px;

        pre {
            border: 0px;
            padding: 0;
            margin: 0;
            background-color: $white;
            font-family: $font-primary, $font-secondary;
            font-size: 14px;
        }
    }   

    .icon {
        height: 25px;
        width: 25px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 9px;

        &.general-info-img {
            background-image: url(../content/images/svg/info_blue.svg);
        }

        &.woodForce-info-img {
            background-image: url(../content/images/svg/warning_red.svg);
        }

        &.stand-info-img {
            background-image: url(../content/images/svg/icon_warning.svg);
        }

    }
}