.missing-compulsory-info-container {
    padding-left: 13px;
    
    .missing-compulsory-info-content {
        width: 24px;
        text-align: center;
        color: $orange;
        font-family: arial;
        border-radius: 4px;
        height: 24px;
        font-size: 12px;
        background: $orange;
        margin-left: 20px;
    }

    .missing-compulsory-info-content.bottom:before {
        content: "";
        width: 6px;
        height: 6px;
        position: absolute;
        right: 9px;
        bottom: -3px;
        text-align: center;
        font-size: 17px;
        transform: rotate(45deg);
        background-color: $orange;
    }

    .missing-compulsory-info-content.top:before {
        content: "";
        width: 6px;
        height: 6px;
        position: absolute;
        right: 9px;
        bottom: 21px;
        text-align: center;
        font-size: 17px;
        transform: rotate(45deg);
        background-color: $orange;
    }

    .missing-compulsory-info-content.left:before {
        content: "";
        width: 6px;
        height: 6px;
        position: absolute;
        right: -3px;
        bottom: 9px;
        text-align: center;
        font-size: 17px;
        transform: rotate(45deg);
        background-color: $orange;
    }

    .missing-compulsory-info-content.rigth:before {
        content: "";
        width: 6px;
        height: 6px;
        position: absolute;
        right: 21px;
        bottom: 9px;
        text-align: center;
        font-size: 17px;
        transform: rotate(45deg);
        background-color: $orange;
    }

    .missing-title {
        color: $white;
        text-align: center;
        padding-top: 3px;
        font-size: 17px;
        height: 24px;
    }

    .missing-compulsory-text {
        background-color: $trimble_grey;
        width: max-content;
        height: 30px;
        border-radius: 0;
        font-size: 12px;
        border: none;

        .popover-content {
            padding-top: 5px;
        }

        .arrow:after{
            border-top-color: $trimble_grey;
        }
    }
}