﻿@import "../../../../shared/sass/_variables.scss";
$margin-value: 0.5em;
$stand-tab-line: 1px solid $darkgray;
$stand-grid-cell-padding: 0.5em;

@mixin stand-dropdown {
	color: initial;
	width: 100%;
	padding: initial;
	padding-left: 7px;
	max-height: 21px;
	font-size: 12px;
}

@mixin stand-grids-column-to-left {
	text-align: left;
	padding-left: $stand-grid-cell-padding;
}

@mixin stand-grids-column-to-right {
	text-align: right;
	padding-right: $stand-grid-cell-padding;
}

@mixin grid-text-alignment {
	.ag-header-cell:first-child {
		@include stand-grids-column-to-left;
	}

	.ag-header-cell:not(:first-child) {
		@include stand-grids-column-to-right;
	}

	.ag-cell:first-child {
		@include stand-grids-column-to-left;
	}

	.ag-cell:not(:first-child) {
		@include stand-grids-column-to-right;
	}
}

.stand-woods-tab {
	.stand-woods {
		.full-width {
			width: 100%;
		}

		.wood-header {
			text-transform: capitalize !important;
			font-weight: 100;
		}

		.volume-header {
			text-transform: none !important;
			font-weight: 100;
		}

		.grid-container-with-padding {
			padding: 5px;
		}

		.grid-with-border {
			border: 1px solid #616161;
		}

		.grid-container {
			.grid-cel {
				text-align: right;
			}

			.ag-theme-fresh .ag-row-selected {
				background-color: $black;
			}

			.sp-grid {
				.ag-body-viewport {
					.ag-cell-edit-input {
						color: black;
					}
				}
			}

			.stand-forest-grid {
				border-top: $stand-tab-line;
				border-bottom: $stand-tab-line;
				height: auto;

				.stand-dropdown {
					@include stand-dropdown;
				}

				.ag-cell > div > input {
					width: 100%;
					height: 100%;
					text-align: right;
					padding-right: 0.5em;
					background-color: inherit;
					border: none;
				}

				.ag-cell:not(:first-child) {
					padding-right: 2px;
				}

				.ag-header-cell {
					.ag-sort-ascending-icon,
					.ag-sort-descending-icon {
						display: none;
					}
				}
			}

			.stand-calculated-values-grid {
				margin-top: $margin-value;
				border-top: $stand-tab-line;
				height: 109px;

				.ag-root {
					border-radius: 0em;
				}

				.ag-body-viewport {
					background: white;

					.ag-body-container {
						.ag-row:last-child {
							.ag-cell {
								border-bottom: $stand-tab-line;
							}
						}
					}
				}

				.ag-cell {
					background: $lightgray;
					border-bottom: 1px solid $gray;
				}

				.ag-row {
					pointer-events: none;
				}
			}

			.dead-tree-wraper {
				width: 100%;
				margin-top: $margin-value - 0.3em;

				.dead-tree-grid {
					border-top: $stand-tab-line;
					border-bottom: $stand-tab-line;
					height: auto;

					.stand-dropdown {
						@include stand-dropdown;
					}

					.header-group-margin {
						margin-left: $margin-value + .27em;
						margin-right: $margin-value;
					}

					.header-group-text {
						line-height: 2em;
						margin-right: $margin-value;
					}

					.pvm-date {
						border: 1px solid $darkgray;
						border-radius: 4px;
						padding: 0.2em;
						height: 24px;
						font-family: $font-primary, $font-secondary;
						font-size: 14px;
						font-weight: normal;
						width: 100%;
						color: #555;
					}
				}

				.control-rows-pane {
					cursor: pointer;
					border-right: 1px solid #d1d1d1;

					.add-row {
						border-right: 1px solid #d1d1d1;
					}

					.add-row-fullscreen {
						width: 50%;
						cursor: pointer;
						border-right: 1px solid #d1d1d1;
					}

					.add-row:hover, .add-row-fullscreen:hover {
						color: $trimble_tf_product_green;
					}

					.add-row-icon {
						padding-right: 0.5em;
					}

					.total-row {
						cursor: default;
					}
				}

				.dead-tree-pvm {
					text-transform: uppercase;
					font-family: $font-semibold;
					font-size: $default-font-size;

					.header-text {
						background: $grid-header-background;
						padding: 7px 48px 10px 5px;
						font-size: $default-font-size;
					}

					.header-data {
						padding-left: 5px;

						.pvm-text {
							padding-right: 10px;
						}
					}
				}
			}
		}

		.stand-woods-header {
			margin: $margin-value;

			.column-padding {
				padding: 0 0 0 $margin-value;
			}

			.row-margin {
				margin-top: $margin-value;
			}

			.save-button {
				margin-left: $margin-value;
			}

			.col-xs-8 {
				.col-xs-7 {
					width: 51%;
				}

				.col-xs-5 {
					width: 49%;
				}
			}
		}
	}
}
