﻿@import '../../../../../shared/sass/_variables.scss';

.general-rules-tab {
    border: 0.75px solid #767876;
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    .align-center {
        display: flex;
        align-items: center;
    }

    .master-header {
        @extend .align-center;
        padding-left: 2px !important;
    }

    .tabs-wrapper {
        display: flex;
        height: 100%;

        .tab-section {
            height: 50%;
            padding: 15px;
        }

        .tab-section:nth-child(2),
        .tab-section:nth-child(4) {
            border-top: 0.75px solid #B0AFB0;
        }
    }

    .form-control {
        height: 28px;
        padding: 4px 12px;
    }

    .text-header-black {
        height: 30px;
        padding: 6px 0px 8px 6px;
        text-transform: uppercase;
        background: #000000 0% 0% no-repeat padding-box;
        text-transform: uppercase;
        color: #FFFFFF;
        font-weight: 700;
        padding-left: 5px;
    }

    .general-rule-placing {
        margin-top: -10px;
        width: 610px;
        height: 594px;

        &:first-child {
            border-right: 0.75px solid #B0AFB0;
        }
    }

    .right-border {
        border-right: 1px solid $trimble_grey;
    }

    .margin-rl-0 {
        margin-right: 0px;
        margin-left: 0px;
    }

    .margin-top-10 {
        margin-top: 10px;
    }

    .width-58 {
        width: 58%;
    }

    .width-67 {
        width: 67.5%;
    }

    .width-42 {
        width: 42%;
        font-family: "Myriad Pro", Verdana;
        font-size: 12px;
        font-weight: 700;
    }

    .padding-right-12 {
        padding-right: 12px;
    }

    .text-header {
        font-size: 14px;
        padding-top: 7px;
        padding-bottom: 10px;
        font-family: "Myriad Pro Semibold", Verdana;
    }

    .text-input-field {
        height: 30px;
        border: 0.5px solid #B0AFB0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        width: 100%;
        border-radius: 5px;
        padding-left: 4px;
    }

    .text-input-field-grid {
        height: 25px;
    }

    .accouting-rules {
        display: flex;
        flex-direction: row;
    }

    .grid-section {
        display: flex;

        .ag-menu {
            z-index: 1 !important;
        }
    }

    .display-flex {
        display: flex;
    }

    .disable-text {
        background: #E5E5E5 0% 0% no-repeat padding-box;
        border: 0.5px solid #707070;
        opacity: 1;
    }

    .icon-info {
        opacity: 1;
        position: absolute;
        right: 20px;
        margin-top: 5px;
        float: right;
    }

    .force-value-wrapper {
        display: flex;
        flex-direction: column;

        .tablefoot-actionbar {
            overflow: hidden;
            padding-bottom: 0.5em;
            padding-top: 5px;
            background: #e8e8e8 0% 0% no-repeat padding-box;
            border-radius: 0px 0px 5px 5px;
            opacity: 1;
            border-left: 0.75px solid #B0AFB0;
            border-right: 0.75px solid #B0AFB0;
            border-bottom: 0.75px solid #B0AFB0;

            button {
                margin-right: 0.5em;

                &.no-margin {
                    margin-right: 0;
                }
            }
        }
    }

    .general-rule-grid-container {
        display: flex;
        flex-flow: column;
        width: 100%;

        .form-group {
            padding: 0em;
        }

        .modal-grid {
            border: 1px solid $darkgray;
            border-radius: 4px;

            #centerRow {
                border-radius: 0.5em;
            }

            .shared-header {
                width: 100% !important;
                border-right: 0;
            }

            .no-border-bottom {
                border-bottom: 0;
            }

            .noborder-right {
                border-right: 0;
            }
        }

        .vat-code-grid,
        .purchase-area-grid {
            height: 265px;
        }

        .force-value-grid {
            height: 219px;
        }
    }
}