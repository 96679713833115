﻿@import "../../../shared/sass/variables.scss";

.payment-receiver-modal .modal-big-lg,
.deduction-modal .modal-big-lg {
    width: 96.5%;
}

.cell-align-center {
    text-align: center;
}

.payment-accordion-tab-container {
    button[disabled]:hover:not(.black) {
        background-color: rgba(0, 154, 217, 0.5) !important;
        opacity: 1 !important;
        border: 1px solid rgba(0, 154, 217, 0);
        cursor: not-allowed;
    }

    .update-btn {
        border-style: none;
    }

    button[disabled] {
        background-color: rgba(0, 154, 217, 0.5) !important;
    }

    .grid-header-checkbox-label {
        margin: 0;
        left: 0px;
        top: 2px;
    }

    .small-grid {
        height: 181px;
        border-radius: 0px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .large-grid {
        max-height: 445px;
        border-radius: 0px;
    }

    .deduction-height {
        height: 224px;
    }

    .deduction-section-height {
        height: 225px;
    }

    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .panel-group .panel + .panel {
        margin-top: 0px !important;
    }

    button.icon-button {
        padding: 1px;
    }

    .panel-border {
        border: 0.75px solid #767876;
        border-radius: 5px;
    }

    .panel-padding {
        padding-right: 0px;
        padding-left: 0px;
    }

    .payment-container {
        margin: 10px !important;

        .negative-payment {
            color: $orange;
        }

        .btn-payment-warning {
            background-color: $orange;
            border: 1px solid $orange;
        }

        .positive-payment {
            color: #009AD9;
        }

        .margin-right-5 {
            margin-right: 5px;
        }

        .pen-icon {
            border-style: none;
        }

        .graph-icon {
            border-style: none;
            color: white !important;
        }

        .auto-renew-icon {
            background-color: $trimble_blue_light;
            margin: 0;
        }

        .auto-renew-icon:hover:not(.black) {
            background-color: #005F9E;
        }


        .disabled-pen-icon:hover:not(.black) {
            opacity: 0.6;
            cursor: not-allowed;
        }

        .disabled-pen-icon {
            opacity: 0.6;
            cursor: not-allowed;
        }

        .non-payment-container-content {
            padding-left: 10px;

            .overall-status-grid-content {
                padding-left: 10px;
            }

            .deduction-distrution-content {
                margin-top: 10px;

                .deduction-content {
                    padding-left: 0px;
                    padding-right: 10px;
                }
            }
        }

        .action-needed {
            background-color: $orange;
        }

        .pen-header.negate {
            display: contents;
        }
    }

    .sp-grid .ag-floating-bottom-container .ag-cell {
        background-color: #d1d1d1;
    }

    .grid-cell-calender {
        .calender {
            height: 21px;
            color: $black;

            tbody {
                background-color: #e8e8e8;
            }
        }

        .btn {
            height: 21px;
            border: solid 1px darkslategrey;
            color: $black;
        }
    }

    .right-algin-header {
        float: right;
    }

    .ml-auto {
        margin-left: auto;
        padding-top: 3px;
    }

    .float-right {
        float: right;
    }

    .plr-0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .p-0 {
        padding: 0 !important
    }

    .m-0 {
        margin: 0 !important
    }

    .pl-0 {
        padding-left: 0px !important;
    }

    .pl-5 {
        padding-left: 5px
    }

    .ml-5 {
        margin-left: 5px;
    }

    .pr-5 {
        padding-right: 5px;
    }

    .mr-5 {
        margin-right: 5px;
    }

    .m-5 {
        margin: 5px;
    }

    .m-lr-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .m-t-15 {
        margin-top: 15px;
    }

    .p-r-0 {
        padding-right: 0px;
    }

    .p-l-0 {
        padding-left: 0px;
    }


    .border-top {
        border-top: 1px solid grey;
        border-left: 0px;
    }

    .button-container {
        background-color: #e8e8e8;

        .btn {
            margin: 5px 10px 5px 10px;
        }

        button:first-child {
            margin-left: 10px;
        }

        button:last-child {
            margin-right: 10px;
        }
    }

    .gray-background {
        background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
    }

    .distribution-payment-receiver-grid {
        .ag-root.ag-root {
            border-radius: 5px;
        }

        .cell-header-right {
            .ag-header-cell-text {
                float: right !important;
            }
        }
    }

    .status-grid {
        .ag-root.ag-root {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
        }

        .cell-header-right {
            .ag-header-cell-text {
                float: right !important;
            }
        }
    }

    .overall-status-grid {
        .ag-root.ag-root {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
        }

        .ag-root .ag-header {
            border-bottom: none;
        }
    }

    uib-accordion {
        *:focus {
            outline: none !important;
        }

        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;

        .panel-default > .panel-heading {
            background-color: $black !important;
            color: $white !important;
            border-color: $white;
            border-radius: 3px 3px 0 0;
            font-size: 1em;
            padding: .5em;
            min-height: initial;

            a, i.glyphicon {
                color: currentColor;
                text-decoration: none;
            }

            .panel-title {
                i.glyphicon {
                    padding-right: .5em;
                }

                font-weight: bold;
                font-size: 1em;
                text-transform: uppercase;
            }
        }

        .panel-group {
            border: 0.75px solid $trimble_brand_grey;
            border-radius: 5px;
        }

        .panel {
            border: 0;
            min-height: 32px;

            &.panel-open > .panel-heading,
            & > .panel-heading:hover {
                background-color: $black;
                color: $white;
            }
        }

        .panel-collapse {
            .panel-body {
                padding-bottom: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        .panel-body {
            padding: 0px !important;
        }

        .panel-group {
            height: 100%;
            margin: 0;
            display: flex;
            flex-direction: column;

            .panel-open {
                height: calc(100% - 125px) !important;
            }

            .panel-default {
                height: 32px;
                border-radius: 0px !important;
            }

            .panel-collapse.in {
                overflow-y: auto;
            }
        }
    }

    .panel-header {
        //background: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;
        background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 3px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        padding: 3px 5px;
        font-size: 12px;
        border-bottom: 0.75px solid $trimble_brand_grey
    }

    .payment-content {
        .payment-content-text {
            background-color: white;
        }

        .sp-grid {
            height: 231px;
        }
    }


    .display-flex {
        display: flex
    }

    .float-right {
        float: right
    }

    .float-left {
        float: left
    }


    .grid-container {

        .sp-grid {
            border-radius: 0px;


            .grid-check-box {
                top: -8px;
            }

            .ag-root.ag-root {
                border-radius: 0px;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    top: -8px;
                }
            }

            .cell-header-right {
                .ag-header-cell-text {
                    float: right !important;
                }
            }
        }

        .select-all-wrapper {
            left: 3px;
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }
    }

    .mar-10 {
        margin: 10px;
    }

    .margin-right-10 {
        margin-right: 10px;
    }
}

.action-required {
    background-color: $orange;
    border: solid 1px $orange;
    z-index: 1020 !important;
    cursor: pointer;

    .popover-inner {
        .popover-content {
            padding: 0px 8px;
            font-size: 17px;
        }
    }
}

.action-required.popover.left > .arrow {
    border-left-color: transparent !important;
}

.action-required.popover.left > .arrow:after {
    border-left-color: $orange;
    right: 3px;
    bottom: -7px;
}

.payment-accordion-tab-container .payment-container .negate .auto-renew-icon:hover:not(.black) {
    background-color: $orange;
}


.action-needed .tooltip-inner {
    word-spacing: 120px;
    max-width: 100px;
}

.payment-accordion-tab-container {
    .panel {
        border: 0;
        min-height: 32px;

        &.panel-open > .panel-heading,
        & > .panel-heading:hover {
            border-radius: 0px;
            background-color: $black;
            color: $white;
        }
    }

    .measurement-base-containers .grid-buttons {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}
