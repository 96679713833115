﻿/* Original code source: http://codepen.io/CreativeJuiz/pen/BiHzp */
/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  display: none;
}

[type="checkbox"]:not(:checked)+label,
[type="checkbox"]:checked+label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

[type="checkbox"]:disabled:not(:checked)+label,
[type="checkbox"]:disabled:checked+label {
  cursor: not-allowed;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked)+label:before,
[type="checkbox"]:checked+label:before {
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: $trimble_white;
  background-image: url(../../../content/images/svg/Checkbox_default_enabled_unselected_18x18px.svg);
  background-size: 18px;
  position: absolute;
  left: 0;
  top: 8px;
}

/* checked mark aspect */

[type="checkbox"]:not(:checked)+label:after,
[type="checkbox"]:checked+label:after {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  line-height: 0.8;
  width: 18px;
  height: 18px;
  background-image: url(../../../content/images/svg/Checkbox_default_enabled_selected_18x18px.svg);
  background-size: 18px;
  // transition: all .2s;
}

/* checked mark aspect changes */
[type="checkbox"]:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

[type="checkbox"]:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked)+label:before,
[type="checkbox"]:disabled:checked+label:before {
  // box-shadow: none;
  // border-color: #bbb;
  // background-color: $disabled-input-color !important;
  width: 18px;
  height: 18px;
  background-image: url(../../../content/images/svg/Checkbox_default_disabled_unselected_18x18px.svg);
  background-size: 18px;
  cursor: not-allowed;
}

[type="checkbox"]:disabled:checked+label:after {
  width: 18px;
  height: 18px;
  background-image: url(../../../content/images/svg/Checkbox_default_disabled_selected_18x18px.svg);
  background-size: 18px;
  cursor: not-allowed;
}

[type="checkbox"]:disabled+label {
  color: #aaa;
}

/* accessibility */
[type="checkbox"]:checked:focus+label:before,
[type="checkbox"]:not(:checked):focus+label:before {
  // border: 1px dotted $trimble_tf_product_green;
}

/* hover style just for information */
label:hover:before {
  // border: 1px solid $trimble_tf_product_green !important;
}