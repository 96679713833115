@import '../../shared/sass/variables';
$footer-height: 30px;
$top-bar-height: 65px;

.help-top-divider-green {
    height: 5px;
    border-bottom: 1px solid $trimble_tf_product_green;
}

.info-panel {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-right: -30px;
    margin-left: -30px;
    background-color: $white;
    display: flex;
    align-items: center;
    height: 50px;
    flex-direction: row;
    padding: 10px;
    box-shadow: 0px 3px 6px #00000029;

    .close-button {
        background-image: url(../content/images/svg/Modal_Close_Grey.svg);
        width: 12px;
        height: 12px;
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        cursor: pointer;
    }

    .button-wrapper {
        width: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px;
    }

    .help-panel {
        width: 80%;
    }

    img {
        height: 25px;
        color: $trimble_blue_light;
        margin-left: 25px;
        margin-right: 15px;
    }

    a {
        color: $trimble_blue_light;
        margin-right: 3px;
    }

    button {
        margin-left: 5px;
    }
}

.help-info-container {
    padding-top: 20px;
    padding-left: 30px;
    position: relative;

    div {
        color: $trimble_tf_product_green;
        display: inline-block;
        vertical-align: middle;
    }

    .silvapro-text {
        color: $gray;
        margin-right: 10px;
    }

    .help-icon {
        background-size: 40px;
        height: 40px;
        width: 40px;
        margin-right: 10px;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url(../content/images/svg/help_icon_green.svg);
    }

    .help-info-divider {
        border-bottom: 1px solid $gray;
        margin-left: 5px;
        width: calc(100% - 50px - 20px - 88px);
    }

    &:before {
        display: inline-block;
        content: " ";
        position: absolute;
        left: -9px;
        bottom: 12px;
        height: 19px;
        width: 19px;
        transform: rotate(45deg);
        background-color: $gray;
    }
}

#Help {
    height: calc(100vh - 11em);
    align-content: flex-start;

    .help-30 {
        width: 350px;
    }

    .help-70 {
        width: calc(100% - 1em - 350px);
    }

    .silva-search {
        height: 30px;
        line-height: 30px;
        margin: 1em 0;
        width: 100%;
    }

    .partial-header {
        margin-top: 0;
        border-radius: 0;
        @extend %silva-header;
    }

    .help-content-container,
    .help-tree-container {
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        background-color: #fff;
        height: 70vh;
        overflow: auto;
    }

    .help-content-container {
        position: relative;
        background-image: url(../content/images/svg/Help_background_image.svg);
        background-size: 25em;
        background-repeat: no-repeat;
        background-position: 95% 95%;
    }

    .help-tree-container {
        margin-right: 1em;

        a {
            color: inherit;
            padding: 10px;
        }

        .tree-icon {
            color: $trimble_tf_product_green;
        }

        li:hover {
            background-color: $lightgray;
        }

        li.active a {
            border-radius: 0;
            background-color: $lightgray;
            text-decoration: underline;
        }
    }

    .help-content {
        padding: 1em;

        table {
            margin: 2em 1em;
        }

        td,
        th {
            border: 1px solid $gray;
            padding: 5px;
        }
    }

    .tree-icon {
        margin-right: 0.5em;
    }

    .level-1 {
        border-top: 1px solid $lightgray;

        &:first-of-type {
            border-top: 0;
        }
    }
}
