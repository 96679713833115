﻿.filter-string-field {
    .row-no-margin {
        margin-right: 0;
        margin-left: 0;
        height: 100%;
    }

    .directive-margin {
        margin: 0em 0.5em 0.5em 0.5em;
    }

    .col-all-zero {
        .group-settings.settings-item {
            margin-top: 0;
            margin-bottom: 2px;
        }
    }

    .silva-search.settings-item {
        margin-top: 0px;
        margin-bottom: 0px;
        height: 30px;
        width: 95.9%;
        padding-left: 6px;
        padding-right: 6px;
        line-height: 29px;
        border-radius: 4px;

        .glyphicon.glyphicon-search{
            top:3px;
        }

        .glyphicon.glyphicon-remove{
             top:3px;
        }
    }

    input[type="text"] {
        outline: none;
        padding-left: 1px;

        &:disabled {
            cursor: not-allowed;
        }
    }
}
