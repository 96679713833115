///Map constants:

$sp-map-toolbar-width: 35px;
$sp-map-toolbar-z-index: 800;
$sp-map-toolbar-opacity: 0.8;
$sp-map-toolbar-padding: 3px;//adding for the sides (left & right)
$sp-map-panel-width: 300px;
$sp-print-preview-control-width: 300px;

.sp-map.map-container {
	position: relative;
	display: flex;
	overflow: hidden;
    margin:0px;
    padding:0px;

    .hide-sidebar-button {
    	position: absolute;
    	top: 0;
    	bottom: 0;
    	left: 0;
		height: 3em;
		width: 3em;
    	background-color: rgba(0,0,0,0.8);
    	color: $white;
    	margin: auto;
    	z-index: 10;
    	line-height: 3em;
    	text-align: left;
    	border-radius: 0 35px 35px 0;

    	&:hover {
    		background-color: $trimble_tf_product_green;
    		cursor: pointer;
    	}

    	span {
    		transition: transform 400ms linear;
    		transform: rotate(180deg);
    		font-size: 2em;
    		top: 0.25em;
    		position: absolute;
    	}

    	&.sidebar-hidden span {
	    	transform: rotate(0);
    	}
    }
}

.grayscale-blur-map {
	pointer-events: none;
    -webkit-filter: blur(5px) grayscale(100%);
    -moz-filter: blur(5px) grayscale(100%);
    -o-filter: blur(5px) grayscale(100%);
    -ms-filter: blur(5px) grayscale(100%);
    filter: blur(5px) grayscale(100%);
}

.map-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;

    &.drag {
        //If the map has state "drag", use pointer as cursor
        cursor: pointer;
    }

    &.drawing-operation {
        //If the map has state "drag", use pointer as cursor
        cursor: url(/content/images/CrossHairCursor.png) 10 10, crosshair;
    }

    &.picking-operation {
        //If the map has state "drag", use pointer as cursor
        cursor: default;
    }

    &.measure {
        //If the map has state "drag", use pointer as cursor
        cursor: url(/content/images/CrossHairCursor.png) 10 10, crosshair;
    }

    &.pick-multi-area {
        //If the map has state "drag", use pointer as cursor
        cursor: url(/content/images/CrossHairCursor.png) 10 10, crosshair;
    }

    &.defineBorders {
        //If the map has state "defineBorders", use pointer as zoom-in
        cursor: zoom-in;
    }

    &.information {
        cursor: default;
    }
}

.ol-zoom.ol-unselectable.ol-control{
	background-color: transparent;
}

.ol-control {
	>.ol-zoom-in, >.ol-zoom-out {
		font-size: 1.8em;//Make zoom buttons larger
        background-color: black;
        &:hover {
		    background-color: $trimble_tf_product_green;
            color: $white;
	    }
	}
}

.coordinate-display {
    z-index: 10;
    color: black;
    left: auto;
    right: 154px;
    bottom: 13px;
    position: absolute;
    width: 250px;
    text-align: left;
}

.coordinate-display-prints {
    background-color: rgba(255.0,255.0, 255.0, 0.5);
    right: 50px;
    padding: 3px;
    padding-left: 10px;
    border-radius: 3px;
}

.sp-map .ol-scale-line-inner {
    color: black;
    border: 2px solid #000;
    border-top: none;
    font-size: initial;
    font-weight: bold;
    margin: 2px;
    margin-right: 3px;
    margin-left: auto;
}

.sp-map .ol-scale-line {
    left:auto;
    right:10px;
    width: 400px;
    background-color: rgba(255.0,255.0, 255.0, 0.5);
    text-align: right;
}


.sp-map.toolbar {
	//z-index: $sp-map-toolbar-z-index;
	position: absolute;
	right: 0px;
	top: 10px;
	width: $sp-map-toolbar-width;
	background: rgba(0,0,0, $sp-map-toolbar-opacity);
	padding: 30px 0 30px 0;
	border-radius: $sp-map-toolbar-width 0 0 $sp-map-toolbar-width;
	cursor: pointer;

    &.wide {
        width: $sp-map-toolbar-width * 2;

        .toolbar-icon {
            float: left;
        }
    }
}

//The line dividing groups of icons
.sp-map.toolbar-divider {
	border-top: 1px solid rgba(225,225,225,$sp-map-toolbar-opacity);
	height: 1px;
	width: $sp-map-toolbar-width - ($sp-map-toolbar-padding * 2);
	float: right;
	margin-right: $sp-map-toolbar-padding;
	padding-top: 2px;
	padding-bottom: 2px;
}

.sp-map.toolbar-icon {
    //z-index: $sp-map-toolbar-z-index;
    float: right;
    text-align: center;
    width: $sp-map-toolbar-width;
    height: $sp-map-toolbar-width;
    background-size: cover;
    background-repeat: no-repeat;

    &:hover, &.selected {
        background-color: $trimble_tf_product_green;
    }

    &.home {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_Home.svg");
    }

    &.drag {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_Drag.svg");
    }

    &.pick {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_Arrow.svg");
    }

    &.pickMulti {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_MultiselectionTool.svg");
    }

    &.information {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_InformationTool.svg");
    }

    &.gpsTracking {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_LocateToOwnPosition.svg");

        &.disabled {
            background-image: url("/content/images/svg/map/toolbar/MapToolBar_LocateToOwnPosition_Disabled.svg");
        }
    }

    &.orto {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_OrtoMap.svg");

        &.disabled {
            background-image: url("/content/images/svg/map/toolbar/MapToolBar_OrtoMap_Disabled.svg");
        }
    }

    &.realEstateBorders {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_RealEstateBorders.svg");
    }

    &.findRealEstate {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_FindRealEstates.svg");
    }

    &.layers {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_MapLayers.svg");
    }

    &.routes {
        background-size: 30px 30px;
        background-position: 3px;
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_Tracks_and_Routes.svg");
    }

    &.prints {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_Prints.svg");
    }

    &.drawEditor, &.workingSitesMarking, &.workingSitesDrawEditor {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_DrawEditor.svg");
    }

    &.measure {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_Measure.svg");
    }

    &.themeMaps {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_ThemeMaps.svg");
    }

    &.define-borders {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_DefineMapBorders.svg");
    }

    &.indexMap {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_IndexMap.svg");
    }

    &.definitions {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_Definitions.svg");
    }

    &.expand {
        font-size: 20px;
        line-height: $sp-map-toolbar-width;
        color: $white;
    }

    &.standInformation {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_StandInformationTool.svg");
    }

    &.legend {
        background-image: url("/content/images/svg/map/toolbar/MapToolBar_LegendTool.svg");
    }
}

.real-estate-marker-label {
    opacity: 1;
    color: #ffffff;
    position: absolute;
    background-color: #111111;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    padding: 2px;
    border-radius: 3px;
    border: 0px solid #111111;
    bottom: 48px;
    left: -18px;
    min-width: 70px;
}

.real-estate-marker-label-content {
    cursor: default;
    margin:0px;
    padding:0px;
}

.real-estate-marker-label:after, .real-estate-marker-label:before {
    top: 100%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    cursor: default;
}
/*
.real-estate-marker-label:after {
    border-top-color: #111111;
    border-width: 12px;
    left: 8px;
    margin-left: -5px;
}
*/
.real-estate-marker-label:not(.no-arrow):before {
    border-top: 15px solid $black;
    border-left: 5px dashed transparent;
    border-right: 5px dashed transparent;
    left: 18px;
    margin-left: -6px;
}

.sp-map-info-bubble {
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 799;
    padding: 0;
    border-radius: 0.5em;
    border: 0;
    display: block;
    color: #ffffff;
    padding: 0.25em 0.5em;

    &:after {
        border-top: 20px solid rgba(0, 0, 0, 0.75);
        border-bottom: 0;
        bottom: -20px;
        left: 20px;
        border-left: 5px dashed transparent;
        border-right: 5px dashed transparent;
        margin-left: -6px;
        width: 0;
        height: 0;
        position: absolute;
        content: '';
    }
}

@import './components/map-control-panels.scss';
@import './components/gpsRouteDirective.scss';
@import './components/layerControlDirective.scss';
@import './components/poimintalistaDirective.scss';
@import './components/drawEditorToolDirective.scss';
@import './components/workingSitesDrawingToolDirective.scss';
@import './components/printPreviewDirective.scss';
@import './components/measureToolDirective.scss';
@import './components/themeMapsToolDirective.scss';
@import './components/standInformationToolDirective.scss';
@import './components/genericInformationToolDirective.scss';
@import './components/indexMapDirective.scss';
@import './components/legendToolDirective.scss';
@import './components/findRealEstateToolDirective.scss';
@import './components/workingSitesMarkingToolDirective.scss';
@import './components/gpsTrackingDirective.scss';