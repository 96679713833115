﻿@import '../../../../shared/sass/_variables.scss';
.contract {
    $font-family: "Myriad Pro", Verdana;
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    $form-control-height: 27px;
    font-family: $font-family;
    font-size: 12px;
    margin-top: 5px;
    height: 871px;

    .ag-cell-edit-input {
        color: black;
    }

    .amount {
        width: 100%;
        border: 0;
        background-color: transparent;
    }

    .ag-row-hover .amount {
        color: white;
    }

    .grid-number {
        color: white;
        width: 25px;
        height: 25px;
        background-color: $blue;
        text-align: center;
        padding-top: 4px;
        float: left;
        font-size: 14px;

        +div {
            float: left;
            padding: 4px;
        }
    }

    .panel-heading-round-left {
        background-color: $lightgray;
        color: black;
        text-transform: uppercase;
        border-bottom: 0px solid black;
        font-weight: bold;
        font-size: 12px;
        padding: 5px;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
    }

    .grid-button {
        background-color: #e8e8e8;
        height: 47px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        margin-top: -2px;

        button {
            margin: 9px;
            height: 30px;
            width: 125px;
        }
    }

    .glyphicon-remove{
        padding-left: 6px;
    }

    .glyphicon-remove:hover{
        color: white;
        padding-left: 6px;
    }

    .grid-container {
        height: 228px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        .ag-body-container {
            .ag-row {
                &.ag-row-level-0 {
                    height: 30px;
                    .ag-cell-no-focus {
                        height: 27px;
                    }

                    .bootstrap-select {
                        .dropdown-toggle {
                            &[title="Nothing selected"] {
                                background-color: #FFD8D8;

                                .filter-option {
                                    display: none;
                                }
                            }

                            &[title=""] {
                                background-color: #FFD8D8;

                                .filter-option {
                                    display: none;
                                }
                            }

                        }
                    }
                }
            }
        }
        .ag-cell-not-inline-editing {
            &.ag-cell-focus {
                overflow: visible;
            }

            &.ag-cell-value{
                .bootstrap-select {
                    width: inherit;
                    margin-left: -3px;
                    height: 25px;
                    margin-top: -2px;

                    .dropdown-toggle {
                        height: 25px;

                        .filter-option {
                            margin-top: -4px;
                            height: 17px;
                        }
                    }
                }
            }

            .dropdown-menu {
                margin-top: 0;
                width: auto;

                .bs-searchbox {
                    margin-bottom: 7px;
                    width: inherit;
                    height: 20px;
                    margin-top: -5px;

                    .form-control {
                        height: 20px;
                        border-color: #e8e8e8;
                        box-shadow: none;
                    }
                }

                >.selected{
                    >a {
                        background-color: #e8e8e8;
                        color: black;
                        outline-color:  #e8e8e8;
                    }
                }

                >.active {
                    >a {
                        background-color: #e8e8e8;
                        color: black;
                    }
                }

                [role="listbox"] {
                    max-height: 130px;
                    width: inherit;
                    overflow-x: hidden;
                    overflow-y: auto;
                }

                .no-results {
                    color: black;
                }
            }

            .bs-caret{
                content: url(../../content/images/svg/Dropdown_downarrow.svg);
                margin-bottom: 5px;
            }
        }

        .contract-info-grid {
            height: 100%;
        }

        .ag-floating-bottom {
            border: 1px solid rgb(97, 97, 97);
            padding: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background-color: #d1d1d1;
        }

        .dropdown {
            width: 100%;
            color: black;
        }
    }

    .main-container {
        background-color: white;
    }

    .top-container {
        padding: 0;
        border: 1px solid rgb(97, 97, 97);
        border-radius: 8px;

        .left-container {
            float: left;
            border-right: 1px solid rgb(97, 97, 97);
            width: 33.33%;
            height: 157px;
            background: #fff;
            border-bottom-left-radius: 7px;
        }

        .center-container {
            width: 33.33%;
            display: inline-block;
            border-right: 1px solid rgb(97, 97, 97);
            height: 157px;
            background: #fff;
        }

        .right-container {
            float: right;
            width: 33.33%;
            height: 157px;
            background: #fff;
            border-bottom-right-radius: 7px;
        }

        .first-container {
            border-bottom: 1px solid rgb(97, 97, 97);
        }

        .container-elements {
            margin: 15px 10px 15px 10px;
            height: 44px;

            div {
                margin-bottom: -3px;
            }

            label {
                width: 95px;
            }

            input {
                border-radius: 5px;
                border: solid 1px grey;
                width: 260px;
                font-size: 14px;
                padding-left: 5px;
            }

            .checkbox-label {
                width: 300px;
                margin-bottom: 14px;
            }

            [type="checkbox"]:not(:checked)+label:before,
            [type="checkbox"]:checked+label:before {
                top: 0px;
                width: 18px;
                height: 18px;
                font-size: 14px;
                font-weight: 700px;
                line-height: 19.3px;
            }

            [type="checkbox"]:not(:checked)+label:after,
            [type="checkbox"]:checked+label:after {
                top: 1px;
                font-size: 18px;
                font-weight: 700px;
                line-height: 19.3px;
            }

            textarea {
                width: 355px;
                height: 107px;
                resize: none;
                margin: 0;
                border: 1px solid rgb(97, 97, 97);
                border-radius: 5px;
            }
        }
    }

    .group-container {
        padding: 0;
        margin-top: 10px;
        height: 560px;
        width: 380px;
    }

    .in-group-first-container {
        padding: 0;
        border: 1px solid rgb(97, 97, 97);
        border-radius: 8px;
        height: 275px;
    }

    .in-group-second-container {
        padding: 0;
        border: 1px solid rgb(97, 97, 97);
        border-radius: 8px;
        margin-top: 10px;
        height: 275px;
    }

    .third-container {
        padding: 0;
        border: 1px solid rgb(97, 97, 97);
        border-radius: 8px;
        margin-top: 10px;
        width: 64.8em;
        height: 560px;
        margin-left: 1em;

        .grid-container {
            height: 513px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            .ag-cell-not-inline-editing {
                .dropdown-menu {
                    width: inherit;
                }
            }
        }
    }

    .contract-footer {
        margin-top: 20px;

        .command-button {
            width: 138px;
            height: 40px;
            float: left;
            .btn-silva {
                width: inherit;
                height: inherit;
            }
        }

        .delete-button {
            margin-left: -15px;
        }

        .copy-button {
            margin-left: 9px;
        }

        .save-button {
            margin-right: -15px;
            float: right;
        }
    }

    .span {
        .navigation-buttons {
            padding-top: 0px;
        }
    }
}