﻿@import '../../../../shared/sass/_variables.scss';

.upsert-advance-payment-modal {
    .modal-dialog {
        top: 16%;
        width: 400px;
    }

    .margin-top-15 {
        margin-top: 15px;
    }

    .margin-right-10 {
        margin-right: 10px;
    }

    .dialog-footer {
        border-top: 0.75px solid #B0AFB0;
        padding: 30px
    }

    .align-text {
        line-height: 30px;
    }

    .calender-container {
        .calender {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border: solid 1px darkslategrey;
            width: 207px;
            padding: 0.2em;
        }

        .input-group-btn {
            .btn {
                border: solid 1px darkslategrey;
                color: #000000;
                border-left: none;

                &:focus {
                    outline: none;
                }
            }


            .invalid-date {
                background-color: #FFD8D8 !important;
                color: black !important;
            }

            .valid-date {
                background-color: #ffffff !important;
                color: black !important;
            }
        }
    }

    .past-date-icon {
        padding-right: 3px;
    }

    .past-date-msg {
        padding-top: 3px;
        padding-left: 0px;
    }

    .form-control {
        padding: 0.2em;
    }

    .margin-left-15 {
        margin-left: 15px;
    }

    .content {
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #B0AFB0;
        border-radius: 3px;
        display: inline-block;

        .panel-header {
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            border-radius: 0 0 0 0;
            padding: 5px 4px;
            height: 25px;
            border-bottom: 1px solid #808080;
        }

        .current-month {
            border: solid 1.5px #009ad9;
            padding-left: 7px;
            padding-bottom: 7px;
            border-radius: 5px;
        }

        .display-flex-wrap {
            display: flex;
            flex-wrap: wrap;

            .uib-datepicker .uib-daypicker {

                .uib-title {
                    border-color: white;
                }

                .uib-weeks .uib-day .btn:disabled {
                    /*font-size: 14px;*/
                    font-weight: normal;
                    color: gray !important;
                    background-color: #fff;
                }

                .uib-weeks .uib-day {
                    span.text-info {
                        color: black;
                    }

                    .btn:enabled {
                        /*font-size: 14px;
                   font-weight: bold;*/
                        color: black;
                        background-color: #3AAE2C;
                    }

                    .btn:enabled:hover {
                        color: black;
                        background-color: #ff9400;
                    }
                }

                .uib-weeks .uib-day:hover {
                    /*font-size: 14px;
                   font-weight: bold;*/
                    color: deeppink !important;
                }

                .uib-weeks .uib-day .btn-default {
                    border-color: white;
                }

                .uib-weeks .h6 {
                    background-color: $lightgray;
                    width: 22px;
                    /*padding-left: 2px;*/
                    padding-right: 4px;
                }
            }
        }

        .btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
            color: #fff;
            background-color: #31b0d5 !important;
            border-color: #269abc;
        }

        .selected-date {
            .btn {
                /*height: 36px !important;*/
                background-color: #3AAE2C !important;
                /*opacity: 0.65;*/
                padding: 5px;
                /*border-radius: 50% !important;*/
                display: inline-block !important;
            }

            .btn:hover {
                /*height: 36px;*/
                background-color: $orange !important;
                color: black;
                /*border-radius: 50%;*/
                display: inline-block;
            }
        }

        .selected-date:hover {
            .btn:hover {
                /*height: 36px;*/
                background-color: $orange !important;
                color: black;
                /*border-radius: 50%;*/
                display: inline-block;
            }

            .active:hover {
                /*height: 36px;*/
                background-color: $orange;
                color: black;
                /*border-radius: 50%;*/
                display: inline-block;
            }

            .active {
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }

        .btn.active {
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .non-selected-date {
            .btn {
                /*height: 36px;*/
                background-color: $white;
                color: black;
                /*border-radius: 50%;*/
                display: inline-block;
            }
        }

        .non-selected-date:hover {
            .btn:hover {
                /*height: 36px;*/
                background-color: #3AAE2C;
                color: black;
                opacity: 0.65;
                /*border-radius: 50%;*/
                display: inline-block;
            }
        }

        .calendar-container {
            padding: 20px;

            .box-sizing-unset {
                box-sizing: unset;
            }

            .month-title {
                font-size: 15px;
                line-height: 1.5;
            }

            .align-center {
                text-align: center;
            }

            .calendar {
                margin: 15px;
            }

            .text-muted {
                color: transparent;
                display: none;
            }

            .today-date {
                .text-info {
                    text-align: center;
                    letter-spacing: 0px;
                    color: #005F9E;
                    font-weight: bold;
                    opacity: 1;
                }

                .btn {
                    /*height: 36px !important;*/
                    /* background-color: #009AD9;
                    color: black;*/
                    color: #009AD9;
                    font-weight: bold;
                    /*border-radius: 50% !important;*/
                    display: inline-block !important;
                }
            }
        }

        .accountperiod-container {

            .grids .grid-border .ag-cell [type="checkbox"]:not(:checked) + label::after, .grids .grid-border .ag-cell [type="checkbox"]:checked + label::after {
                top: 0px !important;
                width: 20px;
                height: 20px;
            }

            .grids .grid-border .ag-cell .col-xs-1 {
                width: 55px;
            }

            .grids .grid-border .ag-cell [type="checkbox"]:not(:checked) + label::before, .grids .grid-border .ag-cell [type="checkbox"]:checked + label::before {
                top: 2px !important;
            }

            .grid-header-checkbox-label {
                left: -4px;
            }

            .material-switch-container .material-switch-content > input[type="checkbox"]:checked + label::after {
                background: #3AAE2C;
                left: 20px;
            }

            .material-switch-container .material-switch-content > input[type="checkbox"]:checked + label::before {
                background: #3AAE2C;
                border: 1px solid #3AAE2C;
                opacity: 0.5;
            }

            .material-switch-container .material-switch-content > label::after {
                left: 0px;
            }

            .accounting-grid {
                height: 525px !important;
            }

            .date-container {
                height: 22px;
                width: 100%;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                background-image: none;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
        }
    }

}
