.user-info {
    animation: fade-in 150ms linear;
    @extend %silva-box;
    padding: 0;
    overflow: hidden;
    /* change the default behavior of checkbox elements to better fit the space they're in */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        top: 0;
    }

    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
        top: 0px;
    }

    .border {
        padding-bottom: 0.5em;
        border-bottom: 1px solid $gray;
    }

    .break-first,
    .break-second,
    .break-third,
    .break-seventh {
        border-right: 1px solid $darkgray;
    }

    .info-region-section {
        width: 50%;
        min-width: 405px;

        &.top {
            min-height: 240px;
        }

        &.middle {
            min-height: 135px;
        }

        &.bottom {
            min-height: 80px;
        }
    }

    .wide-input {
        //225 is the label-width
        width: calc(100% - 135px);
        //Break to next line when reaching the min-width
        min-width: 150px;
        float: left;
        padding-right: 15px;
        padding-left: 15px;

        .input-group {
            width: 100%;
        }
    }

    .wide-right-input {
        width: calc(100% - 200px);
        min-width: 150px;
        float: left;
        padding-right: 15px;
        padding-left: 15px;
    }

    .btn-wide {
        width: 100%;
    }

    .partial-header {
        @extend %silva-header;
        margin-bottom: 0;
    }

    .user-form-label {
        width: 135px; // old: 225px
        float: left;
    }

    .user-form-right-label {
        width: 200px; // old: 225px
        float: left;
    }

    .form-group {
        margin: 0 0.5em;

        &:first-of-type {
            margin-top: 0.5em;
        }

        & + .form-group:last-of-type {
            margin-bottom: 0.5em;
        }

        .right-input {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .text-left {
        text-align: left;
    }
    //Bit of a hacky way to get the checkboxes and labels to line up
    .centerWithLineheight {
        line-height: 15px;
    }

    .office-section {
        position: relative;

        .checkbox-wrapper {
            width: 5%;
            float: left;
            margin-left: 2px;
        }

        .checkbox-selections {
            padding-top: 3%;
            border-top: 1px solid $gray;
            width: 100%;
            height: 100px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }
        //Disable ui-interactions with disabled checkboxes
        input[disabled],
        .checkbox-wrapper.disabled {
            pointer-events: none;
        }
    }

    .office-section .form-group {
        margin-bottom: 0.5em;
    }

    .office-select-wrapper, .purchase-area-select-wrapper, .language-select-wrapper {
        float: right;
    }

    .user-infotext-textarea {
        height: initial;
        width: 96.3%;
        resize: vertical;
    }

    .user-activation {
        border-top-color: rgb(209, 209, 209);
        border-top-style: solid;
        border-top-width: 1px;
        height: 76px;

        .col-xs-12.control-label {
            padding-top: 16px;
        }

        .col-xs-11.control-label {
            padding-top: 5px;
        } 

        .material-switch-container {
            .material-switch-content {
                .material-switch-label::after {
                    height: 24px;
                    width: 24px;
                    background-image: none;
                    background-size: 0;
                }

                .material-switch-label::before {
                    height: 16px;
                    width: 40px;
                    background-image: none;
                    background-size: 0;
                }
            }
        }
    }
}

.save-info-form .save-user-row {
    padding: 0.5em;
    overflow: hidden;

    button {
        width: 200px;
        height: 50px;
    }
}
//When the info-region-section break into seperate rows
@media (max-width: 926px) {
    .user-info {
        .info-region-section.info-region-section {
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid $darkgray;
        }

        .break {
            &-first {
                order: 1;
            }

            &-second {
                order: 2;
            }

            &-third {
                order: 3;
            }

            &-fourth {
                order: 4;
            }

            &-fifth {
                order: 5;
            }

            &-sixth {
                order: 6;
            }
            &-seventh {
                order: 6;
            }
        }

        .remove-user-row {
            order: 7;
        }
    }
}

@media (max-width: 500px) {
    .user-info .info-region-section {
        min-width: 300px;
    }

    .user-info .form-group {
        margin-top: 0.5em;
    }

    .user-info div[class^="col-xs-"],
    .user-info .wide-input {
        width: 100%;
    }
    .user-info .btn-wide {
        width: 100%;
    }

    .user-info .office-select-wrapper, .user-info .purchase-area-select-wrapper {
        float: left;
    }
}
