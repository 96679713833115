﻿@import '../../shared/sass/_variables.scss';

$tab-border-width: 1px;

.measurement-document-container {
    padding-bottom: 8em;
    //padding
    .pt-5 {
        padding-top: 5px !important;
    }

    .pt-10 {
        padding-top: 10px !important
    }

    .w-10 {
        width: 10em;
    }

    .display-none {
        display: none;
    }

    .hidden {
        visibility: collapse;
    }

    .main-container {
        padding: 0px 27px 0px 27px;
    }

    .form-group {
        padding: 0px;
    }

    .partial-header {
        font-size: 12px;
        padding-left: 5px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .tab-wrapper {
        .tabicon {
            padding-right: 2px;
        }
    }

    .tabs-wrapper div[ng-transclude] .tabs-content {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        background-color: $white;
    }

    .no-right-border {
        border-right: 0px !important;
    }

    .base {
        padding: 0px 15px;
    }


    .checkbox-cell {
        text-align: center;

        .ag-selection-checkbox {
            padding-right: 0px !important;
            padding-top: 4px !important;
        }
    }

    .cell-aligned-left {
        .ag-header-group-cell-label {
            text-align: left;
            padding-left: 4px !important;
        }
    }

    .sp-grid .ag-body {
        background-color: $white;
    }

    .md-buttons {
        clear: both;
        margin-left: 0px;

        button {
            margin: 15px 15PX 15px 0px;
        }
    }

    .tab-container {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .basic-detail-container {

        .basic-detail {

            .internal-note {
                min-height: 155px;
                margin: 0px;
                resize: none;
            }

            ::-webkit-input-placeholder {
                font-style: italic;
            }

            :-moz-placeholder {
                font-style: italic;
            }

            ::-moz-placeholder {
                font-style: italic;
            }

            :-ms-input-placeholder {
                font-style: italic;
            }

            .dropDownMinWidth350px .btn-group.open .dropdown-menu {
                display: block;
                padding: 5px;
            }

            .dropDownMinWidth350px .container-row .left-panel .btn {
                height: 27px;
                padding: 0.2em;
                border-color: #616161;
            }

            .dropDownMinWidth350px .dropdown-toggle {
                width: 27px !important;
                padding: 0.2em !important;
            }

            .dropDownMinWidth350px .custom-button {
                text-align: left;
                width: calc(100% - 26px) !important;
                padding: 0.2em !important;
            }

            .dropDownMinWidth350px .first-column {
                padding-left: 6px;
                padding-right: 10px;
            }

            .invalid-date {
                background-color: #FFD8D8;
            }
        }
    }

    .measurement-details-container {
    }

    .summary-container {
        .content {
            border: 0.75px solid #767876;
            /*border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;*/
            border-radius: 0.5em;
            margin: 8px;
            height: 290px;
        }


        .grid-edit-button {
            margin-left: 5px;
            margin-bottom: 3px;
            margin-right: 5px;
            border: none !important;
        }


        .single-row {
            margin-left: -15px;
            margin-right: -15px;
            height: auto;
        }

        .bottom-button-container {
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: 0.75px solid #767876;
            background: #d1d1d1 0% 0% no-repeat padding-box;
            /*border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;*/
        }

        .bottom-button-container-radius {
            margin-top: -0.8em;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .summary {
            padding-left: 1em;
            /*padding-bottom: 1em;*/
            padding-right: 1em;

            .black-header {
                background-color: black;
                color: white;
                padding: 5px;
                padding-left: 15px;
            }

            .width-half {
                width: 50%;
            }

            .partial {
                padding: 0;
                /*margin: 0.5em;*/
                border: 1px solid #616161;
                /*border-radius: 0.5em;*/
                background-color: #fff;
                /*height: 300px;*/
                .row {
                    margin-left: 0px;
                    margin-right: 0px;
                }

                .panel-header {
                    background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
                    font-weight: bold;
                    text-transform: uppercase;
                    border-radius: 0.5em 0.5em 0 0;
                    padding: 3px 10px;
                }
            }


            .centered {
                height: 150px;
                text-align: center;
                padding-top: 50%;
            }

            .inner-partial {
                padding: 0;
                margin: 8px;
                border: 1px solid #616161;
                border-radius: 0.5em;
                background-color: #fff;
                /*height: 380px;*/
            }

            .vertical-line {
                width: 30%;
                border-right: 1px solid #D1D1D1;
                height: 321px;
            }

            .verticle-line-separator {
                border-right: solid 1px #616161;
            }

            .document-partial {
                /*height: 435px !important;*/
            }

            .document-action-grid {
                height: 304px;
            }

            .inner-partial-width {
                width: 33.33%;
                /*height: 347px;*/
                border-right: solid 1px #616161;
            }

            .document-block-height {
                height: 170px;
            }

            .partial-header {
                /*background-color: #d1d1d1;*/
                text-transform: uppercase;
                font-family: "Myriad Pro Semibold";
                padding: 0.5em;
                /*border-radius: 0.5em 0.5em 0 0;
                margin-bottom: 1px;*/
            }

            .icon {
                height: 17px;
                width: 18px;
                line-height: 30px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;

                &.icon-printer {
                    background-image: url(../../content/images/svg/print.svg);

                    &:hover,
                    &:focus {
                        background-image: url(../../content/images/svg/print-green.svg);
                    }
                }

                &.icon-settings {
                    width: 25px;
                    //height: 16px;
                    background-image: url(../../content/images/svg/settings-cog.svg);

                    &:hover,
                    &:focus {
                        background-image: url(../../content/images/svg/settings-cog-green.svg);
                    }
                }
            }

            .width-30 {
                width: 30%
            }

            .width-70 {
                width: 70%
            }

            .print-document-margin {
                margin-left: 20px;
                margin-top: 60px;
            }

            .termsAndCondition .ag-body-viewport {
                overflow-x: hidden !important;
                height: 100%;
            }

            .print-document-print-section {
                height: 50%;
                padding-top: 10%;
                padding-right: 0px;
            }
        }

        .lang-dropdown {
            width: 100%;
            background-color: $trimble_white;

            .custom-drop-down .dropdown-menu .custom-table .table-row:not(.table-header):hover {
                background-color: $trimble_very_light_grey !important;
                color: $trimble_grey !important;
            }

            .custom-drop-down .dropdown-menu > div {
                height: 100%;
                overflow-y: auto;
            }

            .custom-drop-down .dropdown-menu .custom-table .table-row .table-cell {
                height: 35px;
            }

            .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }

            .btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            .dropdown-menu {
                border-radius: 0px;
            }

            .custom-drop-down .dropdown-menu .custom-table .table-row .second--from-two-columns {
                margin-top: 2px;
            }
        }


        .esign-recipient {
            width: 70%;
            margin-left: 30px;
        }
    }

    .font-bold {
        font-weight: bold;
    }

    .p-15 {
        padding: 15px;
    }

    .p-0 {
        padding: 0px;
    }

    .pt-15 {
        padding-top: 15px;
    }

    .pb-15 {
        padding-bottom: 15px;
    }

    .mt-5 {
        margin-top: 5px;
    }

    .pl-0 {
        padding-left: 0px;
    }

    .pr-0 {
        padding-right: 0px;
    }

    .pr-10 {
        padding-right: 10px;
    }

    .pl-10 {
        padding-left: 10px;
    }

    .border-left {
        border-left: 0.5px solid $gray;
    }

    .border-bottom {
        border-bottom: 0.5px solid $gray;
    }
    /*.tabs-content{
        padding:0 !important;
    }*/
    input.ng-invalid, .custom-filter input:invalid, select.ng-invalid, input.invalid, select.invalid, .ag-cell.invalid {
        border: 1px solid $red;
    }

    .main-container {
        padding: 0px 27px 0px 27px;
    }

    .tab missing-compulsory-info {
        position: absolute;
        top: 1px;
    }
}
