.trade-area-price-components-and-services {
    .min-width {
        min-width: 130px;
    }

    .top-margin {
        margin-top: 5px;
        margin-bottom: 0px !important;
    }

    .top-section {
        height: 45px;
    }

    .date-padding {
        width: 47%;
        padding-right: 4px;
    }

    .work-site-view .fine-border {
        border: 1px solid #ccc;
    }

    .left-padding {
        padding-left: 13px;
    }

    .align-right {
        padding-left: 6px !important;
    }

    .text-align-vertically {
        padding-bottom: 5px !important;
    }

    .height-s {
        height: 27px !important;
    }

    .display-flex {
        display: flex;
    }

    .date-padding.date-custom-width {
        width: 161px;
    }

    .input-border {
        border: 1px solid #616161 !important;
    }

    .date-width-100 {
        width: 100%;
    }

    .pull-left-l {
        right: 13px;
    }


    .sp-grid-container {
        .grid-container-footer {
            .btn.btn-silva {
                flex-basis: 14%;
            }
        }

        .no-border-rigth {
            border-right: 0 !important;
        }

        .ag-root-wrapper .ag-root-wrapper-body .ag-root .ag-header .ag-header-row .ag-header-group-cell {
            display: block;
            padding-left: 6px;
        }
    }

    .combine-harvesting-and-forwarding-to-logging {
        padding-left: 0;

        label {
            padding-top: 8px;
            margin-bottom: 8px;
        }
    }
}
