@import '../../../../shared//sass/_variables.scss';

.measurement-discrepancy {
    .list-view-settings-row {
        .advanced-filtering-dropdown .dropdown-content {
            height: 278px;

            .dropdown-table {
                tr {
                    display: flex;
                    flex-direction: row;

                    td {
                        display: flex;
                        width: 50%;

                        &:nth-child(2) {
                            flex-direction: column;
                        }
                    }

                }

                .dropdown-date-input {
                    width: 161px;
                }
            }

        }
    }

    input[type=checkbox],
    input[type=radio] {
        margin-top: 0;
    }

    .measurement-discrepancy-table {
        margin: 0.5em 0 0;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;

        .ag-menu {
            top: 49px !important;
        }

        .checkbox-cell {
            img {
                cursor: pointer;
            }
        }

        .icon-locked-status,
        .icon-active-status {
            width: 19px;
            margin-right: 7px;
        }
    }
}