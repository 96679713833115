@import '../../shared/sass/_variables.scss';

.products {
    $cell-height: 27px;

    .quality_disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }

    .grid-edit-modal {
        top: 30%;
    }

    .left-align {
        text-align: left;
    }

    .ag-cell {
        padding-top: 5px;
        padding-bottom: 5px;

        &.checkbox-cell {
            text-align: center;
        }
    }

    .product-grid-modal {
        .grid-edit-modal .header-row .the-header {
            width: 100%;
        }
    }


    .panel-header.grid-header-styling {
        border-bottom: none;
        padding: 4px;
        height: 25px;
        font-family: "Myriad Pro", Verdana;
        font-weight: 700;
        color: black;
        background: $trimble-gradient-grey-header;

        &.modal-header {
            border-radius: 3px 3px 0px 0px;
            border: 1px solid $half_trimble_brand_grey;

            @media screen and (max-width: 1600px) {
                border: 1px solid $half_trimble_brand_grey;
            }
        }
    }


    .harvesting-products-grid-modal {
        width: calc(100% - 60px);

        .grid-edit-modal .header-row .the-header {
            padding: 0;
            width: 100%;
        }

        .grid-edit-modal .body-row .the-body {
            padding: 0;
            width: 100%;
        }

        .grid-edit-modal .body-row {
            height: 33px;
        }

        .grid-edit-modal .header-row .the-header .header-column {
            border-right: 1px solid $half_trimble_brand_grey !important;
            border-top: 1px solid $half_trimble_brand_grey;
            font-size: 12px;

            &.is-energy-header {
                background-image: url(../../content/images/svg/FlameGridIcon_Black.svg);
                background-repeat: no-repeat;
                background-size: 10px;
                background-position-y: 4px;
                background-position-x: 9px;
            }

            &.is-electricity-header {
                background-image: url(../../content/images/svg/LightningGridIcon_Black.svg);
                background-repeat: no-repeat;
                background-size: 10px;
                background-position-y: 6px;
                background-position-x: 9px;
            }
        }

        .grid-edit-modal .body-row .the-body .body-column {
            border-right-color: $half_trimble_brand_grey;
        }

        the-header, the-body, header-second-row, body-second-row, header-third-row, body-third-row, header-fourth-row, body-fourth-row {
            border-left: 1px solid $half_trimble_brand_grey;
            border-bottom: 1px solid $half_trimble_brand_grey;

            @media screen and (max-width: 1600px) {
                border-left: 1px solid $half_trimble_brand_grey;
                border-right: 1px solid $half_trimble_brand_grey;
                border-bottom: 1px solid $half_trimble_brand_grey;
            }
        }

        the-header, header-second-row, header-third-row, header-fourth-row {
            background: $trimble-gradient-grey-header;
        }

        .header-column {
            .change-information-header {
                height: 24px;
                padding-right: 1px;
                padding-top: 4px;
                width: 36px;
            }
        }

        .body-column {
            padding-bottom: 4.5px;
            padding-top: 1.5px;

            .change-information-body {
                height: 19px;
                width: 38px;
                cursor: pointer;

                &.icon-disabled {
                    opacity: 0.3;
                    cursor: no-drop;
                    pointer-events: none;
                }
            }

            &.trimble-remove.icon-disabled {
                opacity: 0.3;
                cursor: no-drop;
                pointer-events: none;
            }

            &.who-modified-information {
                cursor: default;

                img {
                    cursor: default;
                }
            }
        }

        .dropdown-menu .drop-down-table .first-column {
            width: 25%;
            padding-left: 4px
        }

        .border-bottom {
            padding-left: 17px;
            padding-bottom: 17px;
            padding-right: 16px;
            padding-top: 16px;
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: textfield;
            margin: 0;
        }

        .body-row .the-body .body-column {
            .form-control {
                border: 1px solid #707070;
                border-radius: 4px;
                color: #000000;
            }

            .simpleDropDown {
                border: 1px solid #707070;
                border-radius: 4px;

                @media screen and (max-width: 1300px) {
                    border: 1px solid #707070;
                }
            }

            .product-quality {
                .custom-button {
                    width: 85% !important;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    text-align: left;
                    margin-top: 1px;
                }

                .btn.dropdown-toggle {
                    width: 13%;
                    margin-top: 1px;
                    margin-left: -2px;
                    margin-right: 0;

                    @media screen and (max-width: 1300px) {
                        border: 1px solid $field-border-color;
                    }
                }

                .silva-search input {
                    width: 79% !important;
                }
            }

            .custom-drop-down {
                .custom-button {
                    border: 1px solid #707070;
                    border-radius: 4px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    text-align: left;
                    width: 85% !important;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    text-align: left;
                    margin-top: 1px;

                    @media screen and (max-width: 1300px) {
                        border: 1px solid #707070;
                    }
                }

                .btn.dropdown-toggle {
                    border: 1px solid #707070;
                    border-radius: 4px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-left-style: none;
                    width: 13%;
                    margin-top: 1px;
                    margin-left: -2px;
                    margin-right: 0;

                    @media screen and (max-width: 1300px) {
                        border: 1px solid #707070;
                    }
                }

                .silva-search input {
                    width: 79% !important;
                }
            }
        }

        .simpleDropDown {
            font-size: 14px;
        }

        .edit-down-options[disabled="disabled"] {
            button,
            button[disabled="disabled"]:hover {
                border: 1px solid #616161;
                background-color: #eee;
            }
        }

        .remove-header {
            cursor: default;
        }
    }

    .product-quality-dropdown {
        margin-left: 2px;
        margin-right: 2px;
    }

    .form-control {
        font-size: 12px;
    }

    .ag-theme-fresh .ag-ltr .ag-cell-no-focus {
        border-left: none !important;
    }

    .ag-theme-fresh .ag-cell-no-focus {
        border-top: none !important;
    }

    .products-save {
        margin-bottom: 1em;
    }

    .error-cell {
        background-color: #f2dede;
    }

    .radio-button-container {
        margin-left: 10px;
        padding-top: 3px;
    }

    .settings-item {
        margin-top: 0px;
    }

    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
        padding-left: 15px;
    }

    input[type="radio"] + label {
        color: inherit;
        padding-left: 5px;
        padding-right: 15px;
        text-transform: uppercase;
    }

    input[type="radio"]:checked {
        + label {
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .tunnus-validation {
        .field-validation-tooltip {
            width: 120px !important;
            left: 0px !important;
        }
    }

    .grid-edit-modal .body-row .the-body .body-column label {
        border-color: transparent;
    }

    .product-modal-label {
        top: -7px;
    }

    .ag-cell {
        select:disabled {
            background-color: #d1d1d1;
        }

        .simpleDropDown {
            border-color: #D1D1D1;
            border-radius: 4px;
            background-color: white;
            color: black;
            width: 100%;
        }

        .drop-down-from-div {
            font-size: 14px;
            text-align: left;
            line-height: $cell-height;
            overflow: hidden;
            text-overflow: ellipsis;
            border: 1px solid $darkgray;
            border-radius: 4px;
            margin-top: 1px;
            height: 24px;
            padding-left: 3px;
        }

        input,
        input[type="number"],
        input[type="text"],
        select {
            color: inherit;
            background-color: inherit;
        }
    }

    .ag-theme-fresh .ag-header-cell-text {
        white-space: normal;
        padding-left: 0;
        font-size: 12px;

        .ag-root {
        }
    }

    .sp-grid {
        .ag-row-selected,
        .ag-row:hover {
            input {
            }

            .simpleDropDown,
            .ag-cell:not(.disabled) {
                select {
                    color: black;
                    background-color: white;
                }
            }
        }

        .ag-header-cell-text {
            text-transform: uppercase;
        }

        .is-energy-header {
            background-image: url(../../content/images/svg/FlameGridIcon_Black.svg);
            background-repeat: no-repeat;
            background-size: 10px;
            background-position: center;

            .ag-header-cell-text {
                color: transparent;
            }
        }

        .is-electricity-header {
            background-image: url(../../content/images/svg/LightningGridIcon_Black.svg);
            background-repeat: no-repeat;
            background-size: 10px;
            background-position: center;

            .ag-header-cell-text {
                color: transparent;
            }
        }

        .ag-filter {
            margin-left: 0;
            background-color: $white;

            @extend %silva-box;

            .ag-filter-filter {
                border-radius: 5px;
                border: 1px solid $darkgray;
                width: 96%;
                outline: 0;
                margin-left: 0.5em;
                margin-right: 0.5em;
            }

            .ag-filter-select {
                margin-left: 0.5em;
                margin-right: 0.5em;
            }
        }
    }

    .sales-grid {
        height: 50vh;
        // .ag-header-container, .ag-pinned-left-header {
        //     .ag-header-row:first-child {
        //         height: 26px !important;
        //     }
        // }

        .grey-cell-style {
            border-bottom: 1px solid black;
            padding-left: 6px;
            padding-top: 5px;
        }

        .ag-header-select-all {
            padding-left: 13px;
        }

        .tunnus-text {
            font-size: 14px;
        }

        .ag-root {
            .ag-header-container {
                .ag-header-row {
                    .ag-header-cell {
                        text-align: left;
                        font-weight: bold;
                        text-indent: 5px;
                        text-transform: none;

                        .cell-wrapper {
                            width: 100%;
                            height: 26px;
                            padding-top: 4px;
                        }

                        .black-bottom-border {
                            border-bottom: 1px solid rgb(97,97,97);
                        }

                        .black-right-border {
                            border-right: 1px solid rgb(97,97,97);
                        }

                        .width-one-fifth {
                            width: 20%;
                            float: left;
                        }

                        .width-one-third {
                            width: 33.33%;
                            float: left;
                        }

                        .clear-both {
                            clear: both;
                        }
                    }
                }
            }
            //ag-header-container
            .ag-body-container {
                .ag-row {
                    border-bottom: 1px solid black;

                    .ag-cell {
                        padding: 0px;

                        .cell-wrapper {
                            width: 100%;
                            height: 27px;
                            background-color: inherit;

                            input {
                                border: none;
                                width: 100%;
                                font-size: 14px;
                                background-color: inherit;
                            }

                            select {
                                height: $cell-height;
                            }

                            padding: 0px 3px 0px 3px;
                        }

                        .light-grey-bottom-border {
                            border-bottom: 1px solid #D1D1D1;
                        }

                        .light-grey-right-border {
                            border-right: 1px solid #D1D1D1;
                        }

                        .width-one-fifth {
                            width: 20%;
                            float: left;
                        }

                        .width-one-third {
                            width: 33.33%;
                            float: left;
                        }

                        .width-two-fifth {
                            width: 40%;
                            float: left;
                        }

                        .width-four-fifth {
                            width: 20%;
                            float: left;
                        }

                        .second-cell {
                            width: calc(20% - 6px);
                            float: left;
                            margin-left: 1px;
                            margin-right: 3px;
                        }

                        .third-cell {
                            width: calc(40% - 6px);
                            float: left;
                            margin-left: 4px;
                            margin-right: 3px;
                        }

                        .fourth-cell {
                            width: calc(20% - 6px);
                            float: left;
                            margin-left: 4px;
                            margin-right: 3px;
                        }

                        .width-two-fifth-right {
                            width: 39.2%;
                            float: right;
                        }

                        .clear-both {
                            clear: both;
                        }

                        .checkbox-wrapper {
                            padding-top: 6px;
                            text-align: center;
                        }

                        .label-wrapper {
                            font-size: 14px;
                            font-weight: normal;
                            margin-top: 6px;
                        }

                        .select-wrapper {
                            border-radius: 3px;
                            margin-top: 1px;
                            margin-bottom: 1px;
                        }

                        .editable-sales-cell {
                            height: 90%;
                            margin-left: 0;
                            padding-left: 0;
                            border: none;
                            width: 100%;
                            font-size: 14px;
                            background-color: inherit;
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            line-height: $cell-height;
                        }

                        .margin-right {
                            margin-right: 2px;
                        }

                        .button-extra-small {
                            width: 100px;
                            height: 23px;
                            padding-top: 2px;
                            margin-top: 3px;
                            margin-right: 3px;
                        }
                    }
                }
            }
        }
    }

    .forest-reproductive-material-grid {
        .product-option-grid {
            height: 612px;

            #ProductSalesGrid,
            #ProductsGrid {
                height: inherit !important;
            }
        }
    }
}
