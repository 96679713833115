﻿@import '../../../../shared/sass/_variables.scss';

.forest-plan-markings {
    height: 100%;

    .map-workingSitesDrawEditor &.panel .content .content-element .tool-options label {
        padding: 7px;
    }

    .map-panel {
        &.no-border {
            border: none;
        }
    }

    .content-label {
        margin-bottom: 6px !important;
    }

    .point-label {
        max-width: 200px;
    }

    .line-label {
        max-width: 160px;
    }

    .content-label:hover {
        color: #ddd !important;
    }

    .text-margin-options {
        margin-bottom: 0.0em !important;
    }

    .text-example-container {
        margin-top: 0.5em !important;
    }

    .area-text {
        margin-bottom: 0em !important;
    }

    .marking-element {
        margin-bottom: 0em !important;
    }

    .colors-marking-element {
        margin-bottom: 0.4em !important;
    }

    .marking-group {
        padding: 0px;
        text-decoration: none !important;
    }

    .fp-panel-heading {
        padding: 0px !important;
        text-decoration: none !important;
        line-height: 25px !important;
        border-radius: 0;
        padding: 0;

        .panel-title {
            color: #333;
            font-family: $font-semibold;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 300 !important;
        }
    }

    a:link, a:visited {
        text-decoration: none;
    }

    .active-fp {
        background: black;
        color: white;
        flex-grow: 1;
        text-decoration: none !important;

        .glyphicon-play {
            color: $trimble_white;
        }

        .point-mark {
            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_PointIcon_Selected.svg');
        }

        .line-mark {
            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_LineIcon_Selected.svg');
        }

        .area-mark {
            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_AreaIcon_Selected.svg');
        }

        .text-mark {
            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_TextIcon_Selected.svg');
        }
    }

    .inactive {
        background: white;
        color: #333;
        text-decoration: none !important;

        .glyphicon-play {
            color: $trimble_black;
        }

        .content-element-icon {

            &.point-mark {
                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_PointIcon.svg');
            }

            &.line-mark {
                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_LineIcon.svg');
            }

            &.area-mark {
                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_AreaIcon.svg');
            }

            &.text-mark {
                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_TextIcon.svg');
            }
        }
    }

    .marking-tool-list-item {
        padding-left: 0.5em !important;
        padding-right: 0.5em !important;
    }

    .marking-tool {
        border: 0px !important;
        border-bottom: 1px solid $gray !important;
    }

    .draw-editor-tool-type {
        max-height: 2.5em;

        &:hover {
            .point-mark {
                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_PointIcon_Selected.svg');
            }

            .line-mark {
                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_LineIcon_Selected.svg');
            }

            .area-mark {
                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_AreaIcon_Selected.svg');
            }

            .text-mark {
                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_TextIcon_Selected.svg');
            }
        }
    }

    &.main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .content-padding {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }

    .arrow-container {
        width: 0px;
    }

    .active-element {
        flex-grow: 1;
    }

    .tools-item {
        height: 100%;
    }

    .rotate-open {
        transform: rotate(90deg);
        color: $white !important;
    }

    .default-colors {
        margin-bottom: 0px;
        margin-top: 0px;
        padding-top: 0px;
        height: 20px;
        overflow: hidden;
        width: 100%;

        table {
            width: 51.5%;
            font-weight: bold;
            margin: 0px;
            overflow: hidden;
            height: 20px;
            margin-top: 0px;
            padding-top: 0px;
            margin-left: auto;
            margin-right: 0;

            td {
                .color-container {
                    display: flex;
                    align-items: center;
                    margin-right: 0.5em;
                    height: 1em;
                    overflow: hidden;
                    margin-top: 0px;
                    padding-top: 0px;
                    cursor: pointer;
                }

                .color {
                    border: 1px solid white;
                    height: 2em;
                    width: 1em;
                    display: inline-block;
                    margin-right: 0.5em;
                    margin-top: 0px;
                    padding-top: 0px;
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                    cursor: pointer;

                    &.color-1 {
                        background-color: #000;
                    }

                    &.color-2 {
                        background-color: #7e017f;
                    }

                    &.color-3 {
                        background-color: #1c7691;
                    }

                    &.color-4 {
                        background-color: #5fbfc3;
                    }

                    &.color-5 {
                        background-color: #53a927;
                    }

                    &.color-6 {
                        background-color: #f3d615;
                    }

                    &.color-7 {
                        background-color: #ff9400;
                    }

                    &.color-8 {
                        background-color: #d3011c;
                    }
                }
            }
        }
    }
}
