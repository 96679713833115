@import '../../shared/sass/variables';

.landing-container {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .create-account {
        cursor: not-allowed;
        text-decoration: none;
    }

    .cusor-not-allowed {
        cursor: not-allowed;
    }
}

.header {
    position: absolute;
    padding-top: 20px;
    margin-bottom: 1em;
    width: 100%;
    z-index: 9999; /* this needs to be shown over every other element */
}

.header nav a {
    display: inline-block;
    color: $white;
    font-weight: bold;
    padding: 0 1em;
    margin-top: 0.5em;
    border-right: 1px solid white;
    text-shadow: 0px 0px 10px $black;
    cursor: pointer;
    line-height: 14px;
}

.header nav a:last-child {
    border: 0;
}

.header nav a:hover {
    color: $white;
}

.login-form .form-group {
    margin-bottom: .5em;
}

/* Override some carousel styling to match theme */
.carousel-indicators {
    bottom: 0px;
    margin-bottom: 0;
    height: 36px;
}

.carousel-indicators li {
    box-shadow: 0px 0px 20px $darkgray;
    width: 16px;
    height: 16px;
    margin: 5px;
    margin-bottom: 0;
}

.carousel-indicators li.active {
    width: 16px;
    height: 16px;
    margin: 5px;
    margin-bottom: 0;
}

.carousel-caption {
    right: initial;
    left: initial;
    width: 100%;
    border-top: 1px solid $white;
    padding-top: 0;
    bottom: 0;
    height: 160px;
}

.carousel-control {
    opacity: 1;
    background-image: none !important;

    .carousel-icon {
        height: 70px;
        width: 70px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
    }

    .carousel-icon-previous {
        background-image: url(../content/images/svg/Carousel-Left.svg);

        &:hover {
            background-image: url(../content/images/svg/Carousel-Left-MouseOver.svg);
        }
    }

    .carousel-icon-next {
        background-image: url(../content/images/svg/Carousel-Right.svg);

        &:hover {
            background-image: url(../content/images/svg/Carousel-Right-MouseOver.svg);
        }
    }
}

.carousel-control {
    margin: auto;
    height: 70px;
    top: 60% !important;
    text-shadow: 0px 0px 10px $darkgray;
    width: auto;
}

.carousel-inner .carousel-image {
    @extend %bg-image;
    height: 40vh;
    max-height: 450px;
}

.carousel-inner h1 {
    font-size: 80px;
    padding-top: 40px;
    margin: 0;
    text-shadow: 0px 0px 10px $darkgray;
}

/* Because of the way images are handled by bootstraps carousel, the best way to ensure images are same sized and do not get distorted is to instead show images as div backgrounds rather than img elements in responsive layout */
#carousel-item-1 {
    background-image: url("../content/images/carousel/Carousel-1.jpg");
    background-position: center center;
}

#carousel-item-2 {
    background-image: url("../content/images/carousel/Carousel-2.jpg");
    background-position: center center;
}

#carousel-item-3 {
    background-image: url("../content/images/carousel/Carousel-3.jpg");
    background-position: center bottom;
}

#carousel-item-4 {
    background-image: url("../content/images/carousel/Carousel-4.jpg");
    background-position: center 87%;
}

#carousel-item-5 {
    background-image: url("../content/images/carousel/Carousel-5.jpg");
    background-position: center center;
}

#carousel-item-6 {
    background-image: url("../content/images/carousel/Carousel-6.jpg");
    background-position: center top;
}

.btn-silva {
    @include btn-silva($trimble_white, $trimble_blue_light);
}

.btn-silva:disabled, .btn-silva:disabled.hover {
    color: $trimble_white !important;
    background-color: $trimble_button_disabled !important;
    border: 1px solid rgba($trimble_blue_light, 0);
}

.btn-silva:focus {
    @include btn-silva($trimble_white, $trimble_blue_light);
    outline: 0;
}

.btn-silva:hover {
    @include btn-silva($trimble_white, $trimble_blue_mid);
}

.btn-silva-large {
    @include btn-silva($trimble_white, $trimble_blue_light);
    font-size:14px;
}

.btn-silva-large:focus {
    @include btn-silva($trimble_white, $trimble_blue_light);
    font-size: 14px;
    outline: 0;
}

.btn-silva-large:hover {
    @include btn-silva($trimble_white, $trimble_blue_mid);
    font-size: 14px;
}

small .btn-silva {
    font-size: 90%;
    font-weight: normal;
    padding: 6px 8px;
}

.link-block a {
    color: $black;
}

.link-block a .glyphicon {
    color: $trimble_tf_product_green;
}

.text-center.header-text {
    font-weight: bold;
}

.header-text {
    font-size: 20px;
}

.small-header {
    font-size: 13px;
    font-weight: bold;
}

/* Partials */

/* Partial row and container to set all partials same height, enforce only on devices that have enough width */
.partial-container:empty {
    display: none;
}

.partial p {
    text-align: justify;
}

.partial p.text-center {
    text-align: center;
}

@media (min-width: 971px) {
    .partial-row {
        display: flex;
    }

    .landing-page.partial-row {
        margin-top: 2em;
    }

    /* Change col functionality to ensure proper sizing inside flex based on container size */
    .partial-container .col-lg-4 {
        width: 323.33333333px;
    }

    .partial-container .col-lg-8 {
        width: 646.66666666px;
    }

    .partial-container .col-xs-12 {
        width: 970px;
    }

    .partial-container.border {
        border-right: 1px solid $gray;
    }
}

@media (min-width: 1201px) {
    .partial-container .col-lg-4 {
        width: 390px;
    }

    .partial-container .col-lg-8 {
        width: 780px;
    }

    .partial-container .col-xs-12 {
        width: 1170px;
    }
}

.partial-header p {
    color: $trimble_blue_mid;
    font-weight: bold;
    font-family: $font-semibold;
    text-align: center;
    margin: 1em 0;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 22px;
}

.partial-header p.left-aligned {
    text-align: left;
}

.partial-header {
    .lock-icon {
        width: 80px;
    }
}

.full-width {
    width: 100%;
}

.partial-group-button {
    width: 40%;
}

.button-row {
    margin-top: 2em;
}

.twin-button-left {
    padding-right: 5px;
    padding-left: 0;
}

.twin-button-right {
    padding-left: 5px;
    padding-right: 0;
}

.partial {
    padding: 0 3em;
}

.green-text {
    color: $trimble_blue_mid;
}

.icon-flipped {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
}

/* links that are displayed as buttons need to be properly styled to work like buttons */
a[role=button] {
    color: white;
}

a[role=button] .glyphicon {
    color: white;
}

/* popover style */
.popover {
    color: $white;
    background-color: $blue;
    border: 1px solid $blue;
}

.popover.right {
    border-right-color: $blue;
}

.popover.top > .arrow:after {
    border-top-color: $blue;
}

.popover.left > .arrow:after {
    border-left-color: $blue;
}

.popover.bottom > .arrow:after {
    border-bottom-color: $blue;
}

.support-request {
    border-right: 1px solid $trimble_grey_light;
    padding-right: 19px;

    .form-group {
        margin-right: 0;

        .form-control {
            border-radius: 0;
            margin-right: 0;
        }
    }

    .partial-header {
        padding-left: 102px;
    }

    input.ng-invalid.ng-pristine {
        background-color: $white;
    }

    input, textarea {
        &::-webkit-input-placeholder {
            font-style: italic;
        }
    }

    textarea.ng-invalid.ng-pristine {
        background-color: $white;
    }

    textarea.ng-invalid {
        background-color: $lightred;
    }

    .form-horizontal {
        padding-left: 95px;

        .form-group {
            margin-left: 0;
        }
    }

    .col-sm-12,
    .col-sm-10,
    .col-sm-9 {
        padding-left: 0;
        padding-right: 0;
    }

    .form-group {
        margin-bottom: 0.25em;
        position: relative;

        &.message-area {

            .col-sm-12 {
                padding-left: 0;
            }

            textarea.form-control {
                height: auto;
                margin-top: 0;
            }
        }

        &.captcha-area {
            display: inline-block;
        }

        &.attach-button {
            margin: 0.5em 0;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            margin-left: 35px;
            margin-top: 15px;

            .attach {
                background-image: url('../../../content/images/svg/PaperClip_ButtonSymbol_White.svg');

                &:hover {
                    background-image: url('../../../content/images/svg/PaperClip_ButtonSymbol_White.svg');
                }
            }
        }

        &.button-row {
            width: 47%;

            .partial-group-btn-landing {
                width: 48%;
            }

            .partial-group-btn-from-other {
                width: auto;
            }
        }

        &.notes-area {
            padding-right: 2.5em;
            text-align: right;
            margin-top: 10px;
        }

        .mandatory-indicator {
            padding: 0;
            position: absolute;
            top: 0;
            right: 2em;
        }

        .lime {
            color: $trimble_tf_product_green;
        }
    }

    .attach {
        background-image: url('../../../content/images/svg/PaperClip_ButtonSymbol_White.svg');

        &:hover {
            background-image: url('../../../content/images/svg/PaperClip_ButtonSymbol_White.svg');
        }
    }


    .button-row {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.support-request-information {
    padding-left: 19px;
}

/* In small devices set some rules for partial stacking */
@media (max-width: 970px) {
    .partial {
        margin-bottom: 2em;
        margin-top: 1em;
    }

    .partial-container.border,
    .partial.border {
        border-bottom: 1px solid $gray;
    }
}

@media (max-width: 540px) {
    .carousel-control {
        display: none;
    }
}

.no-padding{
    padding-left: 0px;
    padding-right: 0px;
}

.no-margin-bottom{
    margin-bottom: 0px;
}

.no-padding-left{
    padding-left: 0px;
}
.margin-top{
    margin-top: 1em;
}
.padding-right{
    padding-right: 6px;
}
.button-attach{
    height: 34px;
}
.button-width{
    width: 123px;
}
.img-attach{
    color: -webkit-link;
    height: 21px;
}

.file-attach{
    margin-bottom: 15px;
}

.linkClass{
    color:#337ab7 !important;
}

.max-width{
    width: 100%;
}

/* === Password strength meter == */ 
.strength-meter {
    position: relative;
    height: 3px;
    background: #DDD;
    margin: 10px auto 10px;
    border-radius: 3px;

    &:before, &:after {
        content: '';
        height: inherit;
        background: transparent;
        display: block;
        border-color: #FFF;
        border-style: solid;
        border-width: 0 5px 0 5px;
        position: absolute;
        width: 80px;
        z-index: 10;
    }

    &:before {
        left: 70px;
    }

    &:after {
        right: 70px;
    }
}

.strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill[data-strength='0'] {
    background: darkred;
    width: 20%;
}

.strength-meter-fill[data-strength='1'] {
    background: orangered;
    width: 40%;
}

.strength-meter-fill[data-strength='2'] {
    background: orange;
    width: 60%;
}

.strength-meter-fill[data-strength='3'] {
    background: yellowgreen;
    width: 80%;
}

.strength-meter-fill[data-strength='4'] {
    background: green;
    width: 100%;
}

/* === Password count == */ 

.password-danger {
    background-color: #616161;
}

.password-count {
    float: right;
    position: relative;
    bottom: 21px;
    right: 10px;
}

@import './support/supportRequest/supportRequest.scss';
@import './support/supportRequestStatus/supportRequestStatus.scss';