﻿@import '../../../shared/sass/_variables.scss';

.payment-receiver-modal {
    .ag-cell {
        text-overflow: ellipsis;

        select:disabled {
            background-color: #d1d1d1;
        }

        .simpleDropDown {
            border-color: #D1D1D1;
            width: 100%;
            color: black;
            border-radius: 3px;
        }

        input, select, input[type="text"], input[type="number"] {
            color: inherit;
            background-color: inherit;
            height: inherit;
        }
    }

    .modal-grid {
        padding: 9px;
    }

    .sp-grid {
        height: 103px;
        border-radius: 0px;

        .ag-root {
            border-radius: 3px;
            border: 0.75px solid #B0AFB0 !important;
        }

        .ag-header {
            background: #d3d3d3;
            font-family: $font-semibold;
            text-transform: uppercase;

            .ag-header-cell {
                display: table-cell;
                border-right: 1px solid $white !important;
                height: 100%;
                vertical-align: top;
                padding-left: 2px;

                .ag-header-cell-label {
                    padding-top: 6px;
                    word-break: break-word;
                }
            }

            .ag-header-row {
                background: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;
                border-radius: 3px 3px 0px 0px;
                height: 30px;
            }
        }

        .ag-row-selected, .ag-row:hover {
            input, select, .simpleDropDown {
                color: black;
                background-color: $white;
                height: inherit;
                padding: 0px;
                padding-left: 10px;
                font-size: 12px;
            }

            background-color: white !important;
        }

        .ag-row .ag-cell {
            select, input {
                padding: 0px;
                padding-left: 10px;
                font-size: 12px;
            }
        }

        .ag-row {
            height: 30px;
        }

        .ag-body .ag-body-container .ag-row:hover .ag-cell {
            background-color: $white !important;
        }
    }

    input.no-spin::-webkit-outer-spin-button,
    input.no-spin::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
