.harvest-area-clone-modal {

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .separator {
        margin-top: 0;
        border-color: lightgray;
    }

    .no-bottom-margin {
         margin-bottom: 0;
    }

    .user-responsibility-grid {
       .modal-grid {
           height: 300px;
        }

        .common-grid {
            margin-left: 14px;
            margin-right: 14px;
            margin-bottom: 14px;
        }

        .search-box {
            width: 570px;
        }

        .common-grid-selection-header-cell {
            padding-left: 4px;
        }

        .checkbox-center {
           padding-left: 4px;
        }
    }

    .panel-heading{
        text-align: center;
    }
}
