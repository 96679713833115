.delivery-scheduling-clear-modal {

    .modal-header {
        span {
            font-weight: bold;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .modal-body {

        padding-left: 15px;
        padding-top: 25px;

        span {
            font-weight: bold;
        }

        .row {
            margin: 0;
        }

        .multiselection-drop-down {
            line-height: 30px;
            width: 100%;

            .titleBox {
                width: 100%;
            }
            
            .checkboxes  {
                width: 90%;

                .select-all-label {
                    line-height: 35px;
                }

                .checkboxesList .checkboxLabel{
                    line-height: 35px;
                }
            }

            .multiselectionSearch {
                height: 30px;
                margin-bottom: 5px;

                .multiselectionSearchInput  {
                    width: 80%;
                }
            }
        }

        p.selectedMonths {
            margin: 0;
            padding-top: 10px;
        }
    }

    .btn-container {
        padding: 1em;
        padding-top: 0;
        
		.flat-btn {
			width: 40%;
		}
	}

    hr {
        padding: 0;
        margin: 0;
    }

}