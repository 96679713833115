.edit-assortments-packeges-modal {
    .modal-dialog {
        width: 46%;
        top: 14%;
    }

    .modal-body {
        padding: 0;
    }

    .sp-grid {
        border: 1px solid $half_trimble_brand_grey;
        overflow: hidden;
        border-radius: 3px 3px 3px 3px;

        .ag-header {
            background: $trimble-gradient-grey-header;
        }

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 3px 3px 3px 3px;
        }

        .default-quality {
            pointer-events: none;
        }
    }

    #editeQualityPackages {
        height: 333px;

        .default-quality {
            pointer-events: none;
        }

        .highlight-data {
            background-color: $lightred;
            color: $black;
        }

        .ag-row-selected {
            .highlight-data {
                background-color: $black;
                color: $white;
            }
        }

        .hidden_header {
            visibility: hidden;
        }        
    }

    #contractDetails {
        height: 91px;
        
        .ag-body-viewport {
            overflow-y: hidden !important;
        }
    }

    .first-row {
        height: 45px;
        background-color: $gray;

        .first-row-title {
            padding-left: 15px;
            padding-top: 15px;
        }
    }

    .second-row, .contract-details {
        padding-top: 15px;
        padding-bottom: 15px;
    }

}