@import '../../../shared/sass/_variables.scss';

.service-providers {
    margin-bottom: 4em;

    sp-common-grid-header {
        text-transform: uppercase;
    }

    .panel-footer a.btn.btn-silva {
        color: white;
    }

    .notes-icon {
        cursor: pointer;
    }

    .prints-selection .dropdown-toggle {
        height: 34px;
        margin-top: 1px;
        width: 56px;
    }

    .common-grid-toolbar > button {
        height: 34px;
        margin-top: 3.5px;
        width: 56px;
    }

    .common-grid .common-grid-toolbar button.btn-default {
        width: 56px;
    }

    .grid-heading {
        color: $sp-panel-font-color;
        font-size: $sp-panel-heading-font-size;
        font-weight: bold;
        padding-left: 5px;
        padding-top: 3px;
        text-transform: uppercase;
        background-color: #d3d3d3;
        height: 25px;
        border-left: 1px solid;
        border-top: 1px solid;
        border-right: 1px solid;
        border-color: $sp-panel-border-color;
        border-top-left-radius: $sp-panel-border-radius;
        border-top-right-radius: $sp-panel-border-radius;
    }

    .grid-footer {
        @extend %silva-box;
        overflow: hidden;
        margin: 0;
        padding: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: none;
        background-color: lightgray;
        height: 25px;

        .row-count-label {
            margin-top: 3px;
            margin-left: 5px;
            margin-bottom: 0px;
        }
    }

    #service-providers-contracts {
        margin-top: 20px;
    }

    .service-provider-contracts-grid {
        border: 1px solid;
        border-color: $sp-panel-border-color;
        height: 28.5vh;
    }

    .pointer {
        cursor: pointer;
    }

    .service-providers-navigation-tool {
        padding: 0;

        li.navigation-button-description {
            display: inline-block;
            width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;
            text-align: right;

            &.navigation-button-description-next {
                text-align: left;
            }
        }
    }

    .common-grid-settings-row {
        margin-bottom: 1em;
    }

    #service-provider-grid {
        .panel {
            margin-bottom: 1em;
        }

        .common-grid-grid {

            .text-center input {
                text-align: center;
            }

            .text-center input[type='checkbox'] {
                margin: 0 auto;
            }
        }
    }
    // Make service provider grid smaller when info panel is open
    .show-service-provider-info #service-provider-grid .common-grid-grid {
        height: 200px;
    }

    sp-search-box {
        width: 275px;
    }

    sp-common-grid-toolbar {
        .shown-own-providers {
            margin-left: 10px;
            margin-top: 5px;

            label {
                font-size: 14px;
                font-weight: bold;
            }
        }
    }

    .service-provider-info {
        border: 1px solid #616161;
        border-radius: 0.5em;
        background-color: #ffffff;
        overflow: hidden;
        margin-top: 1em;

        .hidden-section {
            visibility: hidden;
            margin-right: 0 !important;
        }
    }

    .partial-header {
        background-color: #d1d1d1;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        padding: 0.5em;
        border-radius: 0.5em 0.5em 0 0;
        margin-bottom: 0;
    }

    .info-region-section {
        width: 50%;

        &.contractor-section {
            // this is to align the border with the right hand section to prevent border from disappearing when right hand section is hidden
            // and to prevent dual border when both are visible
            border-right: 1px solid $darkgray;
            margin-right: -0.2em;
        }
    }

    .service-provider-notification {
        font-style: italic;
        margin-top: 5px;
        display: block;
    }

    .service-provider-form-label {
        margin-top: 5px;
        margin-bottom: 0;
    }

    .input-row {
        margin: 0.5em;

        [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
            top: 0;
        }

        [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
            top: 3px;
        }
    }

    .panel-footer {
        .btn.pull-right:last-child {
            margin-right: .5em;
        }
    }

    .selected-t-laji, .selected-product {
        display: flex;
        width: 100%;
        border: 1px solid $darkgray;
        border-radius: 4px;
        align-items: center;
        height: 27px; // this is the same as other form elements in the view
        padding-left: 0.5em;

        .t-laji-string, .product-string {
            flex-grow: 1;
            font-size: 14px;
        }

        .down-arrow {
            padding-right: 0.5em;

            span {
                transform: rotate(90deg);
                color: $darkgray;
                font-size: 80%;
            }
        }
    }

    .t-laji-dropdown, .product-dropdown {
        display: flex;
        flex-direction: column;
        max-height: 150px;
        overflow: hidden;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        position: absolute;
        z-index: 810; // position above everything else, but under alerts
        width: 100%;
        bottom: 35px;
        background-color: $white;

        .search-bar {
            display: flex;
            margin: 0.5em;
            min-height: 2em;

            span {
                border: 1px solid $darkgray;
                border-top-left-radius: 0.5em;
                border-bottom-left-radius: 0.5em;
                border-right: 0;
                top: 0;
                padding-left: 0.5em;
                display: flex;
                align-items: center;
                height: 27px;
            }

            input {
                margin-left: 0;
                border: 1px solid $darkgray;
                border-radius: 0.5em;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: 0;
                height: 27px;
                padding: 0.2em;
                flex-grow: 1;
            }
        }

        .result-rows {
            overflow: auto;

            table {
                width: 100%;

                thead {
                    background-color: $lightgray;

                    th {
                        padding: 0.2em 0.5em;
                    }
                }

                tr {
                    &:hover {
                        background-color: $black;
                        color: $white;
                        cursor: pointer;
                    }

                    td {
                        white-space: nowrap;
                        padding: 0.2em 0.5em;
                    }
                }
            }
        }
    }

    .product-dropdown {
        bottom: 26px;
    }
    /* Modal styles */
    .ostoreskontra-modal {
        .modal-header {
            div {
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        .modal-body {
            padding: 0;
            padding-top: 0.5em;
            padding-bottom: 0.5em;

            .row {
                padding-left: 1em;
                padding-right: 1em;
                margin: 0;

                .checkbox-flex {
                    display: flex;
                    margin-top: 4px;

                    [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
                        top: 3px;
                    }
                }

                label {
                    &.text-align-right {
                        text-align: right;
                        padding-right: 0.2em;
                    }
                }

                textarea {
                    margin: 0;
                    height: 4em;
                    resize: vertical;
                }

                &.line-space {
                    margin-bottom: 0.5em;
                }

                .space-left {
                    padding-left: 0.5em;
                }
            }

            .content-splitter {
                margin-top: 0.5em;
                padding-top: 0.5em;
                border-top: 1px solid $gray;
                width: 100%;
            }

            [sp-bank-account] > div {
                display: flex;
            }
        }

        .modal-footer {
            button:first-child {
                float: left;
            }
        }
    }

    .favorite-button-margin-left {
        margin-left: 0.5em;
    }

    .btn-silva {
        &:hover:not([disabled]) {
            @include btn-silva($white, $blue);
        }
    }

    .glyphicon-form {
        font-size: 20px;
        color: $trimble_tf_product_green;
        line-height: 26px;
        text-align: center;
        vertical-align: middle;
        border: 0px;
        border-left: 1px solid black;
        position: absolute;
        right: 2px;
        padding-left: 4px;
        padding-right: 4px;

        &:hover:not([disabled]) {
            color: $blue;
        }

        &[disabled] {
            color: $gray;
        }
    }

    .glyphicon-form:hover {
        color: $gray;
    }

    .radio-button {
        input {
            margin-top: 8px;
        }

        label {
            position: absolute;
            top: 6px;
            margin-left: 0.5em;
        }
    }

    input[type="date"].ng-empty {
        color: transparent;
    }

    .input-row [type="checkbox"]:not(:checked) + label:before, .input-row [type="checkbox"]:checked + label:before {
        top: 2px !important;
    }

    .multiselection-drop-down {
        line-height: 27px;
        width: 100%;
        border-radius: 0px;
        height: 27px;

        .titleBox {
            width: 100%;
            border-radius: 5px;
            height: 27px;
            border: 1px solid $darkgray;

            .titleBoxText {
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .remove-triger-buttons {
                height: 27px;
                margin-top: -2px;
            }
        }

        .checkboxes {
            width: 100%;
            border-radius: 10px;
            z-index: 3;
            border: 1px solid $darkgray;

            .select-all-label {
                line-height: 20px;
            }

            .checkboxesList .checkboxLabel {
                line-height: 20px;
            }
        }

        .multiselectionSearch {
            height: 30px;
            margin-bottom: 5px;
            margin-left: 5px;
            border: 1px solid $darkgray;

            .multiselectionSearchInput {
                width: 88.5%;
            }
        }
    }
}
