@import '../../../shared/sass/_variables.scss';

.payment-setting {
    /* background-color: $lighter-grey-color;
    padding: 30px;
    min-height: calc(100vh - 193px);*/    

    .icon-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
    
    [sp-alerts] .sp-alert .content .uppercase, [sp-relogin] .sp-alert .content .uppercase, [unsaved-alerts] .sp-alert .content .uppercase {
        text-transform: none !important;
    }

    .content .button-container {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .sp-grid .ag-floating-bottom {
        border-top: 1px solid #616161;
        border-bottom: 1px solid #616161;
        box-shadow: 0 0 0 1px white, 0 0 0 2px #d1d1d1;
    }

    .btn-height {
        height: 45px !important;
    }

    .padl {
        padding-left: 10px;
    }

    .checkbox-cell {
        padding-top: 4px !important;
        padding-right: 4px !important;
    }

    .mar-t {
        margin-top: 5px
    }

    .content .grids .grid-border .sp-grid .ag-root.ag-root {
        border-radius: 0em !important;
    }

    .ag-theme-fresh .ag-ltr .ag-selection-checkbox {
        padding-left: 2px;
    }


    .accounting-drop-section {
        /*width: 20%;*/
    }

    .accounting-toggle-year {
        width: 70%;
        margin-left: 30px;

        .material-switch-container .material-switch-content > input[type="checkbox"]:checked + label::after {
            top: 3px !important;
        }

        .material-switch-container .material-switch-content > input[type="checkbox"]:not(:checked) + label::after {
            top: 3px !important;
        }
    }

    .date-invalid {
        background-color: #FFD8D8 !important;
        color: black !important;
    }


    .target-button-container {
        border-top: 0.75px solid #B0AFB0;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .btn-back {
        background-color: #e8e8e8;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 20px;
        margin: 5px;
    }

    .switch-marr {
        /*margin-right: 10px;*/
    }

    .col-xs-1 {
        width: 65px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 20px;
        left: 2px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #3AAE2C;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .slider-span {
        margin: 5px;
    }

    .margin-left-right-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .margin-left-right-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .margin-left-auto {
        margin-left: auto;
    }

    .no-padding {
        padding: 0px;
    }

    .visibility-hidden {
        visibility: hidden;
        cursor: not-allowed;
    }

    .disabled {
        cursor: not-allowed;
    }


    .selection-section {
        display: flex;
        margin-bottom: 15px;

        .display-period-text {
            margin-top: 5px;
        }

        .dash {
            margin-left: 12px;
            margin-right: 12px;
        }


        .selection-section-content {
            padding-right: 0px;
            width: 75%;

            .display-period-content {
                /*margin-left: 10px;
               margin-right: 10px;*/
                padding-left: 30px;

                .display-period {
                    margin-left: 10px;
                    margin-right: 10px;
                    width: 150px;
                }
            }

            .dates-container {
                margin-left: 30px;
                margin-right: 30px;

                .dates {
                    width: 150px;
                }
            }

            .status-indicator {
                /*margin-left: 5px;*/
                margin-top: 7px;

                .filled-square {
                    height: 15px;
                    width: 15px;
                    background-color: #3AAE2C;
                    /*border-radius: 50%;*/
                    opacity: 0.65;
                    display: inline-block;
                    margin-right: 10px;
                }

                .hollow-circle {
                    border: 1px solid #009AD9;
                    border-radius: 50%;
                    width: 15px;
                    height: 15px;
                    z-index: 86;
                }

                .today-text {
                    margin-right: 12px;
                    color: #005F9E;
                    font-weight: bold;
                }


                div.square {
                    border: solid 1px #009AD9;
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                }
            }
        }

        .display-flex {
            display: flex;
        }

        .height-s {
            height: 30px;
        }

        .specification-option {
            /*margin-left: 10px;
                margin-right: 10px;*/
            width: 290px;
        }
    }

    .content {
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #B0AFB0;
        border-radius: 3px;

        .panel-header {
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            border-radius: 0 0 0 0;
            padding: 5px 4px;
            height: 25px;
            border-bottom: 1px solid #808080;
        }

        .current-month {
            border: solid 1.5px #009ad9;
            padding-left: 7px;
            padding-bottom: 7px;
            border-radius: 5px;
        }

        .display-flex-wrap {
            display: flex;
            flex-wrap: wrap;

            .uib-datepicker .uib-daypicker {
                .uib-left {
                    display: none;
                }

                .uib-right {
                    display: none;
                }

                .uib-title {
                    border-color: white;
                }

                .uib-weeks .uib-day .btn:disabled {
                    /*font-size: 14px;*/
                    font-weight: normal;
                    color: gray !important;
                    background-color: #fff;
                }

                .uib-weeks .uib-day .btn:enabled {
                    /*font-size: 14px;
                   font-weight: bold;*/
                    color: black !important;
                }

                .uib-weeks .uib-day:hover {
                    /*font-size: 14px;
                   font-weight: bold;*/
                    color: deeppink !important;
                }

                .uib-weeks .uib-day .btn-default {
                    border-color: white;
                }

                .uib-weeks .h6 {
                    background-color: $lightgray;
                    width: 22px;
                    /*padding-left: 2px;*/
                    padding-right: 4px;
                }
            }
        }

        .selected-date {
            .btn {
                /*height: 36px !important;*/
                background-color: #3AAE2C !important;
                /*opacity: 0.65;*/
                padding: 5px;
                /*border-radius: 50% !important;*/
                display: inline-block !important;
            }

            .btn:hover {
                /*height: 36px;*/
                background-color: $orange !important;
                color: black;
                /*border-radius: 50%;*/
                display: inline-block;
            }
        }

        .selected-date:hover {
            .btn:hover {
                /*height: 36px;*/
                background-color: $orange !important;
                color: black;
                /*border-radius: 50%;*/
                display: inline-block;
            }

            .active:hover {
                /*height: 36px;*/
                background-color: $orange;
                color: black;
                /*border-radius: 50%;*/
                display: inline-block;
            }

            .active {
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }

        .btn.active {
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .non-selected-date {
            .btn {
                /*height: 36px;*/
                background-color: $white;
                color: black;
                /*border-radius: 50%;*/
                display: inline-block;
            }
        }

        .non-selected-date:hover {
            .btn:hover {
                /*height: 36px;*/
                background-color: #3AAE2C;
                color: black;
                opacity: 0.65;
                /*border-radius: 50%;*/
                display: inline-block;
            }
        }

        .calendar-container {
            padding: 20px;

            .box-sizing-unset {
                box-sizing: unset;
            }

            .month-title {
                font-size: 15px;
                line-height: 1.5;
            }

            .align-center {
                text-align: center;
            }

            .calendar {
                margin: 15px;
            }

            .text-muted {
                color: transparent;
                display: none;
            }

            .today-date {
                .text-info {
                    text-align: center;
                    letter-spacing: 0px;
                    color: #005F9E;
                    font-weight: bold;
                    opacity: 1;
                }

                .btn {
                    /*height: 36px !important;*/
                    /* background-color: #009AD9;
                    color: black;*/
                    color: #009AD9;
                    font-weight: bold;
                    /*border-radius: 50% !important;*/
                    display: inline-block !important;
                }
            }
        }

        .accountperiod-container {
            .grids .grid-border .ag-cell .col-xs-1 {
                width: 55px;
            }


            .grid-header-checkbox-label {
                left: -4px;
            }

            .accounting-grid {
                height: 525px !important;
            }

            .date-container {
                height: 22px;
                width: 100%;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                background-image: none;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
        }
    }

    .payment-deduction-content {
        /*background-color: white;*/
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #B0AFB0;
        border-radius: 0.5em 0.5em 0 0;

        .panel-header {
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 0.5em 0.5em 0 0;
            padding: 3px 10px;
        }
        /*  .sp-grid .ag-root.ag-root {
            border-radius: 0.5em !important;
        }*/
    }

    .payment-setting-button-container {
        padding-top: 30px;
        border-top: 0.75px solid #B0AFB0;

        button {
            width: 150px;
        }
    }

    .payment-border-radius {
        border-radius: 4px;
    }
}

@import './paymentSettingsTab/paymentSettingsTab.scss';
