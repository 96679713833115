﻿@import '../../../shared/sass/_variables.scss';
@import '../../../shared/sass/sp-panel.scss';

@media print {

    header,
    .footer {
        display: none;
        overflow: visible;
    }
}

.harvestingareasmatrix {
    .list-view-settings-row {
        .advanced-filtering-options {
            .advanced-filtering-dropdown {
                .dropdown-content {
                    width: 870px;
                    height: 360px;

                    .dropdown-table {
                        .table-cell {
                            .multiselection-drop-down .checkboxes {
                                width: 200px;
                            }
                        }
                    }

                    .dropdown-right-table {
                        width: 64%;
                        overflow-y: hidden;

                        td {
                            width: 50%;
                        }


                        .right-table-cell {
                            margin-left: 15px;
                            margin-top: 10px;
                            margin-bottom: 5px;
                        }
                    }

                    .dropdown-left-table {
                        width: 36%;

                        tr {
                            display: flex;
                            height: 25%;
                        }

                        .left-table-cell {
                            height: 83px;
                            margin-left: 10px;

                            .dropdown-date-label {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }

    .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle),
    .rounded-corners {
        border-radius: 5px;
    }

    .spinner {
        margin-top: 100px;
    }

    .form-control {
        height: 25px;
        padding-top: 2px;
    }

    .filter-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0;
    }
}