﻿@import '../../../shared/sass/_variables.scss';

.info-box {

	  .info-box-container {
        margin: 0.5em 1em !important;
        padding: 0.5em;
		border-radius: 5px;
		border: 2px solid $gray;
		border-bottom: none;
		color: #fff;
		position: relative;

		&.green {
			background-color: $trimble_tf_product_green;
		}

		&.blue {
			background-color: $blue;
		}

		&.turquise {
			background-color: $turquise;
		}

		&.white {
			background-color: $white;
			color: $black;
			border-bottom: 1px solid $gray;
		}
		&.gray{
			background-color:$darkgray;
		} 

		.count {
			margin: 7px;
			font-size: 30px;
			font-weight: bold;
		}

		.comment {
			margin: 5px 0 0 0;
			font-size: 10px;
		}

		.line {
			width:1px;
			position: absolute;
			left: 50%;
			top: 0;
			bottom: 0;
			background: linear-gradient(to right, 
	                              transparent 0%, 
	                              transparent calc(50% - 0.81px), 
	                              $gray calc(50% - 0.8px), 
	                              $gray calc(50% + 0.8px), 
	                              transparent calc(50% + 0.81px), 
	                              transparent 100%); 
			}
    }
}
