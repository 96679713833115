.manual-measurement-modal {
    .input-wrapper {
        display: flex;
        flex-direction: row;

        .row {
            display: flex;
            flex-direction: row;
        }

        .form-group {
            padding: 5px !important;
            width: 40%;

            label {
                margin-right: 5px;
            }
        }

        .info-container {
            width: 80%;
        }
    }
    
    .modal-dialog {
        width: 550px;
    }

    .measurement-message-container {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .manual-measurement-grid {
        margin-top: 5px;
        border-radius: 5px;
        border: 1px solid #616161;
        overflow: hidden;

        .form-control {
            height: 100%;
            padding: 0;
        }

        .number-input {
            height: 23px;
        }
    }

    .small {
        height: 20px;
    }

    .grid-container {
        margin: 5px !important;
    }

}