﻿
@import "../../../shared/sass/_variables.scss";

.accountancy-validation-modal {
    .modal-dialog {
        top: 25%;
        width: 40%;
    }

    .padding-t-b{
        padding:20px 0px;
    }
    
    .bold{
        font-weight: bold;
    }
}
