﻿@import '../../../shared/sass/_variables.scss';

.change-buyer-code-modal-window {
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .execute-button {
        margin-left: 5px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .changeBuyerCode-panel {
        padding: 10px;

        .changeBuyerCode-details {
            padding: 10px;

            .changeBuyerCode-details-row {
                line-height: 27px;

                .form-control {
                    color: $darkgray;
                }

                label {
                    width: 40%;
                    padding-left: 5px;
                    margin-bottom: 0px;
                }

                input, select {
                    display: inline-block;
                    width: 77%;
                    height: 27px;
                }

                textarea {
                    padding: 12px;
                }
            }
        }
    }

    .form-group {
        padding: 0;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .sp-grid {

        .grid-check-box {
            top: -9px;
        }

        .ag-header-cell {
            text-align: center;
            border-right: none;
        }

        .main-header {
            text-transform: uppercase;
            width: 570px !important;
        }
    }

    .ag-header-container {
        border-right: none;
        border-bottom: none;
    }

    .change-buyer-code-drop-down {
		display: inline-block;
        width: 320px;

        .dropdown-menu{
            min-width:450px;

            input[type="search"] {
                width: calc(100% - 10px);
            }
        }

        .btn{
            text-align: left;
        }
	}
}
