﻿@import '../../../shared/sass/_variables.scss';

.manage-buyer-compensation-dialog-window-class {
    .modal-dialog {
        top: 10%;
        width: 800px;
        height: 462px;
    }
}

.manage-buyer-compensation-base-containers {
    .error {
        color: red;
    } 
    
    .p-20 {
        padding: 20px;
    }

    .margin-right-15 {
        margin-right: 15px;
    }

    .horizontal-line {
        border-top: 0.5px solid #B0AFB0;
        margin-top: 20px;
    }

    .balance {
        margin-left: 20px;
        margin-right: 35px;
        margin-top: 20px;

        .balance-text {
            padding-left: 0px;
        }
    }

    .cell-vertical-center {
        padding-top: 3px;
    }

    .ag-cell-inline-editing {
        color: black !important;
    }

    .ag-cell-edit-input {
        color: black !important;
    }

    .grid-border {
        .cell-date-container {
            width: 100%;
            border: 0px;
            color: black !important;
            height: 24px;
            margin-top: 1px;
        }

        .checkbox-cell {
            text-align: center;
            padding-left: 5px;
            padding-top: 5px;
        }

        .cell-text-container {
            width: 100%;
            border: 0px;
            color: black !important;
            height: 24px;
        }

        .padding-bottom {
            padding-bottom: 2px;
        }

        .ag-cell-inline-editing {
            color: black;
        }

        .ag-cell {
            padding-top: 3px;
        }

        .grid-container {
            margin-left: 35px;
            margin-right: 35px;
            margin-top: 20px;
            margin-bottom: 20px;
            border: 1px solid grey;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);

            .sp-grid .ag-floating-bottom-container .ag-cell {
                background-color: #d1d1d1;
                padding-top: 5px;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    left: 4px;
                }
            }

            .select-all-wrapper {
                left: 5px;
                top: -2px;
            }

            .sp-grid .ag-root.ag-root {
                border-radius: 4px !important;
            }

            .buyer-grid {
                height: 450px;

                .black-exclaimation {
                    @extend .background-proprieties;
                    background-size: 6px;
                    background-image: url(../content/images/svg/CriticalNotificationSymbol_Black.svg);
                }

                .sp-grid .ag-body {
                    background-color: white;
                }

                .ag-body-viewport {
                    overflow-y: auto !important;
                    overflow-x: auto !important;
                    background-color: white;
                }
            }

            .add-grid {
                height: 350px;

                .sp-grid .ag-body {
                    background-color: white;

                    .ag-header-cell-label {
                        padding-top: 3px !important;
                    }
                }

                .ag-body-viewport {
                    overflow-y: auto !important;
                }
            }
        }

        .grid-header-checkbox-label {
            margin: 0;
            left: -4px;
            top: 2px;
        }

        .ag-floating-bottom-container {
            font-size: 12px;
            font-weight: bold;
        }

        .ag-cell {
            [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                top: 5px !important;
            }

            [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                top: 2px !important;
            }
        }

        .cell-align-center {
            text-align: center;
        }


        .sp-grid {
            .cell-aligned-left {
                .ag-header-group-cell-label {
                    text-align: left;
                    padding-left: 4px;
                }
            }
        }

        .button-container {
            padding: 5px;
            background-color: #e8e8e8;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;


            .btn {
                width: 16%;
                margin: 4px;
                height: 30px !important;
                padding: 0px;
            }

            .update-btn {
                width: 296px;
            }
        }

        .button-container-grid {
            padding: 10px;
            background-color: #e8e8e8;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;


            .button-with-options {
                width: 100%;
                display: flex;

                .add-button {
                    border-right: none;
                    width: calc(100% - 2.4em);
                }

                .drop-down-table:hover {
                    background-color: #eee;
                }

                .dropdown-content {
                    position: absolute;
                    z-index: 9999;
                    width: calc(100% - 2.2em);
                    background: $white 0% 0% no-repeat padding-box;
                    border: 0.5px solid $half_trimble_brand_grey;
                    background: $white 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 0.5px solid $half_trimble_brand_grey;
                    opacity: 1;
                    top: 30px;
                }

                .arrow-button {
                    border-left: none;
                }
            }
        }
    }
}
