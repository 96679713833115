﻿@import '../../../shared/sass/_variables.scss';

.forest-xml-import {
    width: 100%;
    padding-bottom: 30px;


    .silva-header {
        @extend %silva-header;
        background-color: inherit;
        padding: 0.5em 0;
        margin: 0;
    }

    .sub-header {
        padding-bottom: 0.5em;
    }

    .create-new-forestplan-text {
        margin-top: 9px;
        display: block;
    }

    .button-extra-small {
        width: 178px !important;
        height: 28px;
        padding: 0px !important;
        text-align: center;
    }

    .sp-grid .ag-floating-bottom {
        box-shadow: none;
    }

    .sp-grid {
        .ag-overlay-panel {
            .no-rows-bubble {
                margin-left: unset;
                text-align: center;
                width: auto;
                display: inline-block;
            }
        }
    }

    .panel-footer {
        border-top: 0px;
    }
}

.new-forest-plan-modal {

    .modal-dialog {
        width: auto
    }

    .modal-header {
        padding: 1em;
        border: 0;
        border-bottom: 1px solid $lightgray;
        text-transform: uppercase;
        font-weight: bold;

        div {
            display: inline-block;
        }
    }

    .modal-body {
        padding: 1em;
        border: 0;

        .no-padding {
            padding: 0px;
        }

        .forest-plan-type {
            padding-top: 5px;
            padding-left: 3px;
            padding-right: 0px;

            .forest-plan-type-label {
                width: 15%;
            }

            .forest-plan-dropdown {
                width: 85%;
            }
        }
    }

    .forest-plan-id {
        display: flex;
        margin-bottom: 0.5em;

        .forest-plan-id-container {
            .arrow-container {
                .forest-plan-position {
                    top: 0;
                    left: 3px;
                    width: 171px;
                }
            }

            .forest-plan-input-fields {
                padding-left: 0;

                label {
                    padding-left: 2px;
                }
            }

            width: 181px;
        }

        .get-new-id-button {
            flex-grow: 1;
            display: flex;
            align-items: flex-end;

            button {
                width: 100%;
            }
        }
    }

    .other-forest-plan-information {
        padding: 0;
        display: flex;
        flex-direction: column;

        .year-and-name {
            display: flex;
            margin: 0.5em 0;

            .year {
                margin-right: 0.5em;
            }

            .name {
                flex-grow: 1;
            }
        }

        label {
            display: block;
            width: 100%;
        }

        input {
            height: 27px;
            padding: 0.2em;
        }
    }

    .not-visible {
        visibility: hidden;
    }

    .helper-box {
        padding: 0.5em 1em;
        border-top: 1px solid $lightgray;
        border-bottom: 1px solid $lightgray;

        span {
            font-style: italic;
            display: block;
        }
    }

    .modal-footer {
        display: flex;
        padding: 0.5em;
        border: 0;

        button {
            flex-grow: 1;
        }
    }
}