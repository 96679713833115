@import "./areasBase/areaDetails.scss";
@import "./harvestingAreas/harvestingAreaDetails.scss";
@import "./tradeAreas/tradeAreaDetails.scss";
@import "./harvestingAreas/harvestingAreaStorageAmounts/harvestingAreaStorageAmounts.scss";
@import "./harvestingAreas/harvestingAreasMatrix.scss";
@import "./harvestingAreas/harvestAreaCloneModal.scss";
@import "./inventoryStatuses/inventoryStatuses.scss";
@import "./inventoryStatuses/storageAmounts.scss";
@import "./workSiteList/workSiteList.scss";
@import "./inventoryStatuses/transferStorageQuantities.scss";
@import "./inventoryStatuses/transportReceipts.scss";
@import "./inventoryStatuses/inventoryStatusesReleaseToTransport.scss";
@import "./inventoryStatuses/releaseToTransportModal.scss";
@import "./inventoryStatuses/removeResourceLinkingModal.scss";
@import "./inventoryStatuses/SAreleaseToTransportModal.scss";
@import "./inventoryStatuses/harvestingResourcesForSelectedHarvestAreaModal.scss";
@import "./inventoryStatuses/setStartAndEndDateModal.scss";
@import "./harvestingAreas/harvestingAreaStorageAmounts/assortmentsGroupsModal.scss";
@import "./harvestingAreas/harvestingAreaStorageAmounts/manualMeasurement/manualMeasurementModal.scss";
@import "./areasBase/manualReception/manualReceptionModal.scss";
@import "./measurementCertificateList/measurementCertificateList.scss";
@import "./harvestingAreas/harvestingAreaChangeStatus.scss";

.workingsites {
    font-family: "Myriad Pro", Verdana;

    .common-grid .common-grid-toolbar {
        margin-bottom: 15px;
    }

    .filter-container {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .filter-checkbox-margin {
        margin-bottom: 10px;
    }

    .ag-set-filter-list {
        width: 280px;
    }

    .ag-virtual-list-container {
        width: 280px;
    }

    input[id*="header-checkbox"] {
        visibility: hidden;
    }

    .sp-grid-settings {
        display: inline-flex;
        position: relative;
        z-index: 10; // prevent sp-grid-settings element from going under the table
    }

    .ag-theme-fresh .ag-group-cell-entire-row {
        background-color: white;
    }

    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before {
        box-shadow: none;
    }

    .row .font-grey-background {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }

    .link-block {
        font-family: $font-primary;
        font-size: 12px;

        .inner {
            box-shadow: 0 0 3px $half_trimble_brand_grey, 0 0 3px $half_trimble_brand_grey;
            height: 250px;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            background-color: $trimble_white;
        }

        .large-nav-button {
            cursor: pointer;
            background-color: $trimble_white;
            padding-right: 0px !important;
            padding-left: 0px !important;


            &:hover {
                background-color: $trimble_white;
                color: $trimble_black;

                .topic {
                    color: $trimble_tf_product_green;
                }

                .icon {
                    border-color: $trimble_grey_light;

                    &-harvestingareas {
                        background-image: url("/content/images/svg/WORKINGSITEButtons_2_HarvestingPlotList_MouseOver.svg");
                    }

                    &-harvestingareasmatrix {
                        background-image: url("/content/images/svg/WORKINGSITEButtons_3_HarvestingPlotMatrixList_MouseOver.svg");
                    }

                    &-workingsites {
                        background-image: url("/content/images/svg/WORKINGSITEButtons_1_WorkingsiteList_MouseOver.svg");
                    }

                    &-inventorystatuses {
                        background-image: url("/content/images/svg/WORKINGSITEButtons_4_HarvestAndTransportManagement_MouseOver.svg");
                    }

                    &-harvesting-area-timing {
                        background-image: url("/content/images/svg/WORKINGSITEButtons_8_Timeline_MouseOver.svg");
                    }

                    &-measurement-certificates {
                        background-image: url("/content/images/svg/WORKINGSITEButtons_9_MeasurementCertificates_MouseOver.svg");
                    }

                    &-delivery-service {
                        background-image: url("/content/images/svg/WORKINGSITEButtons_Deliveries_MouseOver.svg");
                    }

                    &-vismaSign {
                        background-image: url("/content/images/svg/ADMINButtons_10_eSign_MouseOver.svg");
                    }
                }
            }                

            a:hover,
            a:active {
                text-decoration: none;
            }

            .icon {
                text-align: center;
                border-bottom: 01px solid $trimble_grey_light;

                div {
                    height: 50px;
                    margin: 0.5em 0;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }

                &-harvestingareas {
                    background-image: url("/content/images/svg/WORKINGSITEButtons_2_HarvestingPlotList.svg");
                }

                &-harvestingareasmatrix {
                    background-image: url("/content/images/svg/WORKINGSITEButtons_3_HarvestingPlotMatrixList.svg");
                }

                &-workingsites {
                    background-image: url("/content/images/svg/WORKINGSITEButtons_1_WorkingsiteList.svg");
                }

                &-inventorystatuses {
                    background-image: url("/content/images/svg/WORKINGSITEButtons_4_HarvestAndTransportManagement.svg");
                }


                &-harvesting-area-timing {
                    background-image: url("/content/images/svg/WORKINGSITEButtons_8_Timeline.svg");
                }

                &-measurement-certificates {
                    background-image: url("/content/images/svg/WORKINGSITEButtons_9_MeasurementCertificates.svg");
                }

                &-delivery-service {
                    background-image: url("/content/images/svg/WORKINGSITEButtons_Deliveries.svg");
                }

                &-vismaSign {
                    background-image: url("/content/images/svg/ADMINButtons_10_eSign.svg");
                }
            }

            .topic {
                color: $trimble_black;
                margin: 0;
                margin-bottom: -0.5em;
                margin-top: 2em;
                padding: 0;
                font-family: $font-semibold;
                text-transform: uppercase;
                font-size: 12px;
            }

            .last-topic {
                margin-bottom: 0.5em;
                margin-top: 0em;
            }

            p {
                text-align: center;
                padding: 0.5em;
            }
        }
    }

    textarea {
        resize: none;
    }

    .bottom-buttons {
        margin: 1em 0 0 0;

        .btn {
            padding: 1em 2em 1em 2em;
            margin: 15px 15px 15px 0px;
        }

        .btn.pull-right {
            margin-right: 0;
            margin-left: .5em;
        }
    }


    .margin-botton-7 {
        .tab-content {
            margin-bottom: 8em;
        }
    }

    .date-top-padding {
        padding-top: 4px !important;
    }

    .worksitenumber-top-padding {
        padding-top: 4px !important;
    }

    .hagrid {

        .common-grid-toolbar {
            button {
                margin-left: 0;
            }

            .btn {
                padding: 7px 12px;
            }
        }

        .ha-favourite-btn {
            margin-left: 6px;
        }

        .rearrange {
            width: 0px;
            height: 0px;

            .btn-group {
                left: -120px;

                .btn-default {
                    padding-top: 7px;
                    padding-bottom: 7px;
                    margin-right: -2px;
                    height: 35px;
                }
            }
        }

        .ag-row-group {
            span:first-child {
                // crude grouping row adjustment
                display: inline-block;
                margin-left: 2px;
                margin-top: 3px;

                span:first-child {
                    margin: 0;
                }
            }
        }

        .common-grid-grid {
            .custom-filter {
                input {
                    border-radius: 3px;
                    border: 1px #616161 solid !important;
                    margin-top: 3px;
                    padding: 1px 5px;
                }
            }
        }
    }

    .harvesting-areas-status-list {
        max-height: 400px;
        overflow-y: auto;
    }

    .sp-common-grid-toolbar-item {
        margin-right: 127px;
    }

    .dropdownContentReducedHeight {
        width: 600px;
        height: 280px;
    }

    .invalid-date {
        background-color: #FFD8D8;
    }

    sp-common-grid-toolbar {
        .sp-grid-settings {
            z-index: 0;
        }
    }

    .popover {
        left: -7px;
    }

    #advanced-filtering-options {
        margin-top: 1px;
    }

    #haGrid favourite-grid-configs {
        margin-left: auto;
        margin-right: 0;

        .icon-button:hover .saved-lists-dropdown {
            display: none;
        }
    }

    .advancedFilteringOptionsCheckbox {
        &:hover {
            cursor: default;
        }
    }

    .advancedFilteringOptionsTitle {
        margin-left: -5px;

        &:hover {
            cursor: pointer;
        }
    }

    .triangle-icon {
        margin-left: 5px;
        margin-bottom: 2px;

        &:hover {
            cursor: pointer;
        }
    }

    .list-view-settings-row {
        margin-bottom: 0;

        .advanced-filtering-options {
            .advanced-filtering-dropdown {
                .dropdown-content {
                    $table-height: 421px;
                    width: 626px;
                    height: $table-height;
                }
            }
        }

        .favorite-radio-inline {
            input[type=radio] {
                margin-bottom: 2px;
                margin-right: 5px;
            }
        }
    }
}

.hagrid {
    // TODO: potential problem at this level
    // these are to position the element under the separate settings bar to align the settings bar with column selection button
    position: relative;

    input {
        cursor: pointer;
    }

    .ag-theme-fresh .ag-group-cell-entire-row {
        background-color: #aaa;
        padding: 4px;
    }

    .common-grid .common-grid-toolbar {
        height: 35px;
    }
}

.wood-sales-calculations-modal {
    .panel-sp-grid {
        margin-bottom: 1em;
    }

    .common-grid .common-grid-grid {
       // height: 350px !important;
    }
}

.open-storages-on-map-modal {

    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        top: 0;
    }

    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
        top: 0px;
    }
}

//TODO:START move in some global style , moved from harvestingAreaDetails.scss

.no-padding-right {
    padding-right: 0px;
}

.fixed-dropdown-height {
    height: 30px;
}

#formGroupPurchaseArea:hover,
#startArea:hover {
    background-color: #e8e8e8 !important;
}

.rightPanelExpanded {
    margin-left: 0 !important;
}

.flex-container {
    display: flex;
    flex-direction: row;
}

.flex-btn {
    flex: 1;
    margin-left: 3px;
    margin-right: 3px;
}

.form-inline {
}

.heading-buttons {
    button {
        width: 100%;
        margin-bottom: 5px;
    }
}

hr {
    color: black;
    margin-bottom: 10px;
    margin-top: 10px;
}

.map {
    @extend %silva-box;
    margin: 0;
    /*TODO Szilagyi don't know why this was defined, still needs investigation. I commented it because it breaks other map tools*/
    /*height: 665px;*/
}

.nav-container {
    border-bottom: none;

    a:focus {
        color: $black !important;
    }
}

.tab-nav {
    margin: 0;
    padding: 0;
    width: 100%;
    border-bottom: none;

    .nav-item {
        margin-left: 3px;
        margin-right: 3px;
        border-top-left-radius: 6px;
        padding: 0.2em 0 0.2em 0;
        border-top-right-radius: 6px;
        border-color: $darkgray;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            background: black;
            color: white;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.tab-content {
    padding: 1em;
    border: 1px solid #808080;
    border-radius: .5em;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: white;

    .common-grid {
        .ag-header {
            .ag-header-cell[col-id="kuljetusKielto"] {
                &.ag-header-cell-sorted-none {
                    .ag-filter-icon {
                        margin-left: 23px;
                    }
                }

                .ag-header-cell-label {
                    .ag-sort-ascending-icon {
                        margin-left: 23px;
                    }

                    .ag-sort-descending-icon {
                        margin-left: 23px;
                    }

                    .transportban-header {
                        margin-top: -19px;
                        margin-right: 10px;
                        width: 25px;
                        height: 18px;
                        background-position-x: 4px;
                    }
                }
            }
        }

        .button-extra-small {
            padding: 0px 0px 0px 0px;
        }
    }
}

ui-view {
    & > .panel-body {
        background-color: $white;
        border: 1px solid $darkgray;
        border-top: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 1em;
    }

    .panel {
        margin: 0;
    }
}


.button-extra-small {
    width: 96px;
    height: 20px;
}

.storage-amount {
    .button-extra-small {
        width: 190px;
        height: 20px;
    }
}

$transport-ban-height: 26px;

.workingsite-storage-places {
    .sp-grid .ag-root.ag-root {
        border-radius: 0 !important;
    }

    .ag-theme-fresh .ag-ltr .ag-group-expanded {
        padding-right: 11px;
    }

    sp-common-grid-footer {
        display: flex;
        flex-wrap: nowrap;
    }

    .common-grid {
        .ag-floating-bottom {
            border-top: 1px solid $trimble_brand_grey;
        }

        .panel-footer {
            padding: 10px;
            background-color: $gray;
            border-top: 1px solid $trimble_brand_grey;

            button {
                margin-right: 10px;
            }
        }

        .common-grid-grid {
            .ag-header-container {
                .ag-header-row {
                    background: $grid-header-background;
                }

                .ag-header-cell[col-id="kuljetusKielto"] {
                    .ag-header-cell-text {
                        height: $transport-ban-height;
                        position: relative;
                        top: -18px;

                        .transportban-header {
                            height: $transport-ban-height;
                        }
                    }
                }
            }

            .ag-row-selected,
            .ag-row-hover {
                .storage-special-input {
                    background-color: $black;
                }
            }

            .storage-special-input {
                width: 80px;
            }

            .with-background {
                background: $white;
            }

            .limit-invalid {
                background-color: $lightred;
                width: 80px;
            }
        }
    }
}

.ag-body-viewport-wrapper {
    .no-transport-season-or-geometry .ag-selection-checkbox span {
        width: 15px;
        border-top: 423px;
        filter: invert(20%);
        pointer-events: none;
    }

    .ag-row-group .no-transport-season-or-geometry {
        .ag-selection-checkbox span {
            filter: invert(20%);
            pointer-events: none;
        }
    }
}

//TODO:END move in some global style
