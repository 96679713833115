﻿@import '../../shared/sass/_variables.scss';

$sp-panel-border-radius: 5px;

.multiselection-grid-wrapper {
    width: 200px;

    .sp-grid .ag-root.ag-root {
        border-radius: $sp-panel-border-radius;
    }

    hr {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .titleBox {
        border: 1px solid $gray;
        border-radius: $sp-panel-border-radius;
        width: 200px;

        .titleBoxText {
            margin-left: 5px;

            &:hover {
                cursor: pointer;
            }
        }

        .titleBoxDropDownIcon {
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 12px;
            float: right;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .remove-button {
        width: 18px !important;
    }

    .multiselection-content {
        border: 1px solid $gray;
        border-radius: $sp-panel-border-radius;
        height: 250px;
        width: 500px;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        z-index: 1;
        background-color: white;

        .gridHeader {
            display: block;
            height: 40px;

            .multiselection-transcluded {
                margin: 5px 0 0 13px;
                padding: 0;
                height: 35px;
            }

            .multiselectionSearch {
                border: 1px solid $gray;
                border-radius: $sp-panel-border-radius;
                height: 35px;
                margin-top: 5px;
                margin-left: 5px;
                padding: 0;

                .multiselectionSearchIcon {
                    margin-left: 5px;
                }

                .multiselectionSearchInput {
                    width: 85%;
                    height: 25px;
                    margin-left: 5px;
                    border: none;
                }

                input:focus {
                    outline: none;
                }

                .glyphicon-remove {
                    top: 2.3px;
                    margin-left: 0;
                }
            }
        }

        .multiselection-grid {
            height: 190px;
            border-color: #808080;
            border: 1px solid #808080;
            border-radius: $sp-panel-border-radius;
            margin: 5px;

            .ag-header-row {
                .select-all-wrapper {
                    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
                        top: 18px;
                        left: -4px;
                    }

                    [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
                        top: 14px;
                        left: -4px;
                    }
                }
            }
        }

        .ag-selection-checkbox {
            margin-left: 2px;
        }
    }

    #richList {
        height: 100px;
    }
}
