%capital-letters {
	text-transform: uppercase;
}

%bg-image {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

%silva-box {
	padding: 0;
	margin: 0.5em;
	border: 1px solid $darkgray;
	border-radius: 0.5em;
	background-color: $white;
}

%silva-header {
	background-color: $gray;
	text-transform: uppercase;
	font-family: $font-semibold;
	padding: 0.5em;
	border-radius: 0.5em 0.5em 0 0;
	margin-bottom: 1px;
	&.no-border-radius {
		border-radius: 0;
	}
	&.subheader {
		font-style: italic;
		background-color: $lightblue;
		font-size: 0.7em;
	}
}

%flex {
	display: flex;
}

%flex-wrap {
	flex-wrap: wrap;
}

%selected-row {
	background: black;
	color: white;
}

%silva-search {
	border: 1px solid $gray;
	border-radius: 5px;
	overflow: hidden;
	padding: 0 5px;
	overflow: hidden;
	input {
		margin-left: 2px;
		width: calc(100% - 36px);
		border: 0;
		outline: 0;
		&:placeholder-shown {
			font-style: italic;
		}
	}
	.glyphicon-remove {
		cursor: pointer;
		&:hover {
			color: $trimble_tf_product_green;
		}
	}
}