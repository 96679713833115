﻿@import '../../../shared/sass/_variables.scss';
@import '../forestCompartment/forestCompartmentPrintModal.scss';
@import '../forestCompartment/forestCompartmentWriteXmlModal.scss';
@import '../components/xmlImportValidationErrorsModal.scss';


$bottom-height: 320px; //the height for forestBase-bottom

.forestBase {

    .group-stand-cb {
        top: -8px;
    }

    .group-stand {
        margin-top: 7px;
    }

    .forestBase-top {
        height: 505px;
        margin-bottom: 9px;
        margin-right: -20px;
        margin-top: 5px;

        .no-margin-left-right .col-xs-4 > div.settings-item.icon-button {
            margin-left: 0px;
            padding-left: 0px;
        }

        .forestBase-left-panel {
            float: left;
            width: 60%;
            padding-right: 15px;
            padding-top: 6px;
            padding-left: 7px;
            display: flex;
            flex-direction: column;
            transition: width 200ms linear;

            &.sidebar-hidden {
                width: 0%;
                overflow-x: hidden;
                padding-right: 0px;
            }

            .forestBase-controls {
                margin-top: -5px;
                margin-bottom: 2px;

                .grid-selector {
                    padding-right: 10px;
                    width: 26%;
                }

                .table-settings-button {
                    margin-left: 18px;
                }

                .settings-button-rounded {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    padding-left: 9px;
                    padding-right: 7px;
                }

                .export-button-rounded {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                .uppercase {
                    text-transform: uppercase
                }

                .dropDownListRow {
                    float: left;
                    margin-left: 4px;

                    :hover {
                        cursor: pointer;
                    }
                }

                .dropdown-menu-wide {
                    min-width: 180px;
                    margin-left: 0px;
                }

                .dropdown-menu>li>a {
                    padding: 3px 12px;
                }

                .tableSearchFilter{
                    padding-top: 0px;
                }

                .view-option-select {
                    .caret {
                        margin-top: 5px;
                    }

                    button {
                        min-width: 0px;
                    }

                    span {
                        padding-top: 2px;
                    }
                }

                .row {
                    .grid-selector {
                        margin-right: 0px;
                        padding-left: 5px;
                        padding-right: 10px;
                    }

                    .option-btns {
                        text-align: right !important;
                        width: 31%;
                    }

                    .group-by-check {
                        padding-left: 10px;
                    }
                }
            }
        }

        .forestBase-right-panel {
            flex-grow: 1;
            margin-left: 1em;
            flex-grow: 1;
            width: 97.5%;

            &.sidebar-hidden {
                margin-left: 0;
            }

            .map {
                @extend %silva-box;
                height: 505px;
                margin: -5px;
            }
        }
        //menu and search above the table
        //dropdown
        .center {
            margin-top: 6px;
            margin-left: 5px;
        }

        .dropdown-menu-position {
            margin-left: 13px;
        }

        .dropdown-export-menu-position {
            margin-left: -1px;

            :hover {
                cursor: pointer;
            }
        }

        .dropdown-menu-cursor {
            li:hover {
                background-color: #f5f5f5;
            }

            a:active,
            a:hover,
            a:link,
            a:visited {
                text-decoration: none;
            }
        }

        .menu-items-position {
            padding-left: 0px;

            :hover {
                cursor: pointer;
            }
        }
        //print button
        .icon-button {
            //Override bootstrap's styles on icon-buttons
            > .exportButton {
                height: 2.3em;
                width: 3.1em;
                line-height: 30px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                background-size: auto;
                background-color: initial;

                &:hover,
                &:focus {
                    background-image: url(../../content/images/svg/Export_LimeGreen.svg);
                    background-color: initial;
                }

                background-image: url(../../content/images/svg/Export_Grey.svg);
            }

            > .printButton {
                height: 2.3em;
                width: 3.1em;
                line-height: 30px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                vertical-align: middle;
                background-size: auto;

                &:hover,
                &:focus {
                    background-image: url(../../content/images/svg/print-green.svg);
                }

                background-image: url(../../content/images/svg/print.svg);
            }

            > .moreButton {
                height: 2.3em;
                width: 3.1em;
                line-height: 30px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                background-size: auto;

                &:hover,
                &:focus {
                    background-image: url(../../content/images/svg/More_LimeGreen.svg);
                }

                background-image: url(../../content/images/svg/More_Grey.svg);
            }

            > .btn {
                &:hover,
                &:focus {
                    background-color: $trimble_white;
                }

                background-color: $trimble_white;
            }

            &.open {
                .icon-printer {
                    margin-left: 2px;
                    margin-right: 2px;
                    background-image: url(../../content/images/svg/print-green.svg);
                }

                .icon-favorite {
                    background-image: url(../../content/images/svg/favorite-green.svg);
                }
            }
        }

        .icon {
            height: 17px;
            width: 18px;
            line-height: 30px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;

            &.icon-settings {
                width: 25px;
                background-image: url(../../content/images/svg/GearWheel_Grey.svg);
            }

            &.icon-favorite {
                background-image: url(../../content/images/svg/favorite.svg);

                &:hover,
                &:focus {
                    background-image: url(../../content/images/svg/favorite-green.svg);
                }
            }

            &.icon-testRun {
                width: 25px;
                background-image: url(../../content/images/svg/TestRunIcon_Grey.svg);
            }

            &.icon-writeXml {
                width: 25px;
                background-image: url('../../content/images/svg/WriteXmlIcon_Grey.svg');
            }

            &.icon-exportToTrestima {
                width: 25px;
                background-image: url('../../content/images/svg/ExportToTrestima_Grey.svg');
            }
        }
    }

    .forestBase-bottom {
        clear: both;
        height: $bottom-height;

        .forestBase-growthAreaChart {
            float: left;
            width: 36%;
            height: 100%;
            margin-left: -50px;
            margin-right: -20px;
        }

        .forestBase-treeSpeciesChart {
            float: left;
            width: 42%;
            height: 100%;
            margin-right: 24px;

            .theTreeSpeciesChart {
                width: 100%;
                height: 100%;
            }

            .treeSpeciesChart-switch {
                position: relative;
                top: 20px - $bottom-height; //place the swicth 20px lower than the top of the forestBase-bottom
                left: 50px;

                div {
                    float: left;
                    padding: 0px 5px 0px 5px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -o-user-select: none;
                    -ms-user-select: none;
                }

                .switch-active {
                    background-color: black;
                    color: white;
                    cursor: default;
                }

                .switch-inactive {
                    background-color: white;
                    color: black;
                    cursor: pointer;
                }
            }
        }

        .forestBase-profitabilityTable {
            float: left;
            width: 25%;
            font-family: Verdana;
            background-color: $gray;
            font-size: 12px;
            padding-right: 15px;
            position: relative;

            .profitability-header {
                margin-top: 36px;
                margin-bottom: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .profitability-table {
                width: 100%;

                tr.hakkuutulot {
                    :first-child {
                        color: $turquise;
                    }
                }

                tr.metsanhoitokustannukset {
                    :first-child {
                        color: #FF9400;
                    }
                }

                tr.uudistamiskustannukset {
                    :first-child {
                        color: #616161;
                    }
                }

                tr {
                    td {
                        font-weight: bold;
                    }

                    :last-child {
                        text-align: right;
                    }
                }
            }

            hr {
                border-color: #616161;
            }

            .second-table {
                tr {
                    :first-child {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .padding-left-right-bottom {
        margin-bottom: 11px;
    }

    .no-margin-left-right {
        margin-left: 0px;
        margin-right: 0px;
    }

    .no-margin-left-right-bottom {
        margin-left: 0px;
        margin-right: 0px;
    }

    .custom-margin-bottom {
        margin-bottom: 13px;
    }

    .button-width {
        width: 100%;

        &:hover {
            background-color: white;
        }
    }

    .no-left-padding {
        padding-left: 0px;
    }

    .custom-right-padding {
        margin-right: 0px;
    }

    .custom-right-margin {
        margin-right: -5px;
    }

    .custom-left-padding {
        padding-left: 19px;
    }

    .settings-item {
        margin-left: 0px;
    }

    .left-button {
        margin-left: 20px;
    }

    .middle-button {
        padding-left: 0px;
        padding-right: 0px;
    }

    .right-button {
        padding-right: 0px;
        padding-left: 0px;
    }

    .no-padding-right {
        padding-right: 0px;
    }

    .no-padding-left-right {
        padding-left: 0px;
        padding-right: 0px;
    }

    .no-margin-padding {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .ag-body-viewport-wrapper {
        font-size: 14px;
    }

    .sp-grid {
        height: 410px;

        #centerRow {
            border-radius: 0.5em;
            border: 1px solid #616161;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 0;

            #centerRow {
                border: 0;
            }
        }

        .ag-row {
            :last-child {
                border-right: 0;
            }
        }
    }

    .customers-tablefoot-actionbar {
        padding: 0.5em 0;

        button {
            margin: 0 0 0 0.5em;
        }

        :last-child {
            margin-right: 0.5em;
        }
    }

    .sp-grid .ag-floating-bottom {
        box-shadow: none;
    }

    .ag-floating-bottom {
        border-bottom: 1px solid #616161;
    }

    .row-selected {
        background-color: black !important;
        color: white;
    }

    .row-deselected {
        background-color: inherit !important;
        color: inherit;
    }

    .element-table-cell {
        display: table-cell;
    }

    .ag-row-group-contracted {
        height: 23px !important;
    }

    .compartment-info-kuviot-cell {
        border-left: 1px solid #d1d1d1 !important;
    }

    .custom-drop-down-arow {
        margin-top: 2px;
    }

    .btn.dropdown-toggle {
        padding: 3px 12px;
    }

    .forestBase {
        border: 1px solid $darkgray;
        border-bottom: 0;
        border-radius: 0.5em;
        background-color: $lightgray;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        overflow: hidden;        
    }
}
