@import '../../../../shared/sass/_variables.scss';

.measurement-docs {
    $font-family: "Myriad Pro", Verdana;
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    $form-control-height: 24px;
    font-family: $font-family;
    font-size: 14px;
    margin-top: 5px;

    .ag-theme-fresh .ag-cell-no-focus {
        border: none;
    }

    .field-invalid{
        color:black !important;
    }
    .field-disabled {
        background-color: #efefef !important;
        cursor: not-allowed;
    }

    .ag-cell {
        overflow: visible;

        input, select, input[type="date"], input[type="text"], input[type="number"] {
            color: inherit;
            background-color: inherit;
        }

        select {
            color: black;
            background-color: white;
        }

        .editable-text {

            input:focus {
                color: black;
                background: white;
                text-align: right;
            }


            select:hover {
                color: white;
            }
        }

        .ag-row-selected, .ag-row:hover {
            .ag-cell-focus {
                background-color: white;
                color: black;
            }

            input {
                color: white !important;
            }

            select, .simpleDropDown {
                color: black;
                background-color: white;
            }
        }

        input {
            color: black;
            text-align: right;
            width: 100%;
            border-style: none;
        }
    }

    input {
        color: black;
        border: none;
    }

    #hakk {
        height: 20px;
        padding: 0;
    }

    .panel {
        margin-bottom: .5em;

        .panel-heading {
            font-family: $font-semibold;
            font-size: 14px;
        }
    }

    .custom-input {
        width: 100%;
        height: inherit;
        text-align: right;
    }

    .custom-input-grey {
        width: 100%;
        height: inherit;
        background-color: rgb(239,239,239);
        text-align: left;
    }

    .ag-row-focus {
        input {
            width: 100%;
            height: inherit;
            background-color: black;
            color: white;
        }
    }

    .ag-theme-fresh {
        line-height: inherit;
    }

    .alignHeaderRight {
        text-align: right;
    }

    .checkbox-cell {
        border: none;
        text-align: center;
    }

    .ag-row .ag-selection-checkbox {
        position: static;
        margin-left: 3px;
    }

    .ag-row {
        border: none;
    }

    .sp-form-divider {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: -1.2em;
        margin-right: -1.1em;
        border-bottom: 1px solid #d1d1d1;
    }

    .sp-form-divider-vertical {
        border-right: 1px solid #d1d1d1;
    }

    .sp-form-checkbox {
        [type="checkbox"]:checked + label:before,
        [type="checkbox"]:not(:checked) + label:before {
            top: 0;
        }

        [type="checkbox"]:checked + label:after,
        [type="checkbox"]:not(:checked) + label:after {
            top: 2px;
        }
    }

    textarea.sp-form-textarea {
        margin-top: 0;
        resize: none;
    }

    .row-eq-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .sp-form-col {
        padding-top: 1em !important;
        padding-bottom: 1em !important;
    }

    .no-padding-left {
        padding-left: 0px;
    }

    .no-padding-right {
        padding-right: 0px;
    }

    .ag-header-container {
        border-right: none;
        border-bottom: none;
        border-top: 1px solid grey;
        text-align: right;
    }

    .sp-grid .ag-root.ag-root {
        border-radius: 0;
    }

    .customers-tablefoot-actionbar {
        background-color: #d1d1d1;
        padding: 0.5em 0.5em;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        margin: 0;
        border: 0;
        border-top: 1px solid grey;
    }


    &.bottom-buttons {
        overflow: hidden;
        margin: 0.6em 0em 0.6em 0em;
    }

    .form-group {
        label {
            font-family: $font-semibold;
            font-size: 13px;
            margin-top: 3px;
        }

        input {
            border: 1px solid #616161;
        }
    }

    .input-label {
        font-family: $font-semibold;
        font-size: 13px;
    }

    #contract-completed-checkbox {
        margin-top: -15px;
    }

    #contract-completed-label {
        margin-top: 8px;
    }

    #slashStyle {
        padding-top: 5px;
        font-size: 20px;
    }
}
