﻿@import '../../../shared/sass/_variables.scss';

.forest-payments {
    animation: fade-in 250ms linear;

    &:not(.container) {
        animation: fade-in 250ms linear;
    }

    [sp-alerts] .sp-alert {
        .margin-top-none {
            margin-top: 0em !important;
        }
    }
}


.measurement-discrepancy, .ledger-summary, .ledger-balance, .forest-payments, .contract-ledger-balance {
    .list-view-settings-row {
        .left-settings .view-option-select {
            width: 278px;
        }
        
        .advanced-filtering-dropdown .dropdown-content {
            height: 410px;
            width: 788px;

            .dropdown-table {
                .dropdown-date-input {
                    width: 333px;
                }

                .multiselection-drop-down .checkboxes {
                    width: 301px;
                }
            }

        }
    }
}

@import "./measurementCertificateDiscrepancy/measurementCertificateDiscrepancy.scss";
@import "./contractLevelLedger/contractLevelLedger.scss";
@import "./forestPayments/forestPayments.scss";
@import "./ledgerBalance/ledgerBalance.scss";
@import "./ledgerSummary/ledgerSummary.scss";
@import "./annualAnnouncement/annualAnnouncement.scss";
