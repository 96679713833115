﻿.trade-area-details {

    #averageStemVolume {
        width: 140px;
    }

    .accordion-content {
        .min-width {
            min-width: 140px;
        }

        .custom-drop-down .dropdown-menu > div {
            max-height: 200px;
            height: auto;
        }

        .margin-right-15 {
            margin-right: 15px;
        }

        .margin-right-5 {
            margin-right: 5px;
        }

        .edit-button {
            width: 24px !important;
            height: 24px !important;
            border-radius: 0.3em;
            border-color: #009AD9;
        }

        .pen-icon {
            background-size: 100% 100%;
        }

        .dropDown400px {
            position: relative;
            width: calc(100% - 24rem);

            .dropdown-menu {

                .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12,
                .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5,
                .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9,
                .col-md-1, .col-md-10, .col-md-11, .col-md-12,
                .col-md-2, .col-md-3, .col-md-4, .col-md-5,
                .col-md-6, .col-md-7, .col-md-8, .col-md-9,
                .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12,
                .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5,
                .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9,
                .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12,
                .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5,
                .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
                    min-height: 18px;
                    padding-right: 0px;
                    padding-left: 5px;
                }

                .table-row:not(.table-header):hover {
                    .col-xs-4:not(.grey-background) {
                        background-color: dodgerblue !important;
                    }

                    .col-xs-3:not(.grey-background) {
                        background-color: dodgerblue !important;
                    }

                    .col-xs-2:not(.grey-background) {
                        background-color: dodgerblue !important;
                    }

                    .col-xs-1:not(.grey-background) {
                        background-color: dodgerblue !important;
                    }
                }
            }

            .btn {
                height: 27px;
                padding-top: 3px;
                padding: 3px 8px;

                span {
                    float: left;
                }
            }

            button[disabled], button.btn[disabled] {
                border: 1px solid #616161;
            }

            background-color: white;
        }

        @media screen and (max-width: 1250px) {
            .form-group.row {
                & .min-width-label.form-control-label {
                    min-width: 90px;
                }
            }
        }

        @media screen and (max-width: 1300px) {
            .two-column-inputs {
                display: grid;
            }

            .form-group.row {
                & .min-width-label.form-control-label {
                    min-width: 105px;
                }
            }
        }

        button[disabled], button.btn[disabled] {
            &:hover {
                background-color: $lightgray;
            }
        }
    }

    .purchase-area-group {
        display: flex;
        align-items: center;
        padding: 0;

        label {
            white-space: nowrap;
            margin-right: 10px;
        }

        select {
            padding: 4px 12px 6px 2px;
            border: 1px solid #ccc;
        }
    }

    .bottom-buttons {
        display: flex;
        align-items: center;
        margin: 0px;

        .btn {
            flex-basis: 33.33%;
            padding: 1em;
            margin: 15px 0.5em 15px 0px;
        }

        .btn.pull-right {
            margin-left: 0px;
        }
    }

    .margin-botton-7 {
        .tab-content {
            margin-bottom: 8em;
        }
    }

    .bubbly-info {
        position: absolute;
        margin-left: 68%;
        width: 250px;
        text-align: center;
        color: #fff;
        font-family: arial;
        margin-top: -129px;
        border-radius: 35px;
        height: 44px;
        font-size: 12px;
        padding-top: 13px;
        background: #363545;
    }

    .bubbly-info:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid #363545;
        border-top: 10px solid #363545;
        border-bottom: 10px solid transparent;
        right: 18px;
        bottom: -20px;
    }

    .additional-info {
        .panel-collapse {
            height: 440px !important;
        }

        .accordion-content {
            margin-top: 0px;
        }

        & .panel-collapse.collapse.in {
            height: 440px !important;
        }
    }

    .additional-info-content {
        padding-bottom: 0px;
        margin-bottom: 15px;
    }

    .container-row .left-panel .margin-top-fix {
        margin-top: 2px;
    }

    .container-row .left-panel .btn-energy {
        height: 27px;
        padding: 3px 6px;
        border-color: transparent;
    }

    .container-row .left-panel form {
        height: 100%;

        .editing-details-section {
            .form-group {
                label {
                    flex-basis: 28%;
                    min-width: 19%;
                }

                input[type="text"] {
                    flex-basis: 37%;
                    margin-right: 10px;
                }
            }
        }

        .lohko-selection {
            .form-control-label {
                flex-basis: 28%
            }

            input[type="text"], select {
                flex-basis: 37%;
            }
        }

        .working-sites-area-details-form {
            .col-md-7 {
                width: 56%;

                .form-control-label {
                    flex-basis: 51%;
                }
            }

            .col-md-5 {
                width: 44%;

                .form-control-label {
                    flex-basis: 36%;
                }
            }
        }

        .harvest-date-section {
            .col-md-7 {
                width: 55.7%;

                .form-control-label {
                    flex-basis: 65%;
                }
            }
        }

        .estimated-other-costs-section {
            .form-control-label {
                flex-basis: 28%
            }

            input[type="text"] {
                flex-basis: 37%;
            }
        }
    }

    .terrain-planning-done {
        padding-top: 4px;
    }

    .multiline-label {
        white-space: unset !important;
    }

    .font-grey-background {
        margin-top: 0px !important;
    }

    .panel {
        height: 500px;

        &.map-workingSitesDrawEditor {
            height: inherit;
        }
    }

    uib-accordion {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .container-row {
        height: 586px;

        .left-panel {
            .flex-row-container {
                margin-bottom: 0px;
            }

            .panel-margin {
                margin: 15px 15px 6px 0;
            }

            .pad-checkbox {
                padding-left: 10px !important;
                padding-top: 5px !important;
            }

            form uib-accordion .panel.general-information-tab .panel-collapse {
                height: 440px !important
            }

            form uib-accordion .panel-collapse .panel-body {
                padding: 0 0 0 .5em;
            }
        }

        .right-panel {
            margin-left: 0px;

            .harvestingarea-navigation {
                height: 48px;
            }

            .placeholder-container .placeholder-wrapper {
                max-height: 538px;
            }
        }
    }

    .tab-nav .nav-container {
        border-bottom: none;
    }

    .ag-theme-fresh .ag-ltr .ag-group-expanded, .ag-theme-fresh .ag-ltr .ag-group-contracted {
        padding: 0px 10px;
    }

    .margin-right-10 {
        margin-right: 10px;
    }

    .common-grid {
        .ag-row-group.ag-full-width-row {
            padding-top: 4px !important;
        }

        .sp-grid {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
    }
}

@import './tradeAreaAssortments/tradeAreaAssortments.scss';
@import './tradeAreaAssortments/editAssortmentPrices/editAssortmentPrices.scss';
@import './tradeAreaAssortments/editAssortmentPrices/editQualityRatePrice.scss';
@import './tradeAreaAssortments/editAssortmentPrices/editAssortmentsGroup.scss';
@import './tradeAreaAssortments/editAssortmentPrices/editAssortmentGroupPrice.scss';
@import './tradeAreaAssortments/editAssortmentPrices/reasonDetails.scss';
@import './tradeAreaAssortments/assortmentsPackges/assortmentsPackges.scss';
@import './energyTradeArea/createEnergyTradeArea/createEnergyTradeArea.scss';
@import './tradeAreaAssortments/priceMatrixModal/priceMatrixModal.scss';
@import './tradeAreaAssortments/contractPackageSelectionModal/contractPackageSelection.scss';
@import './tradeAreaAssortments/editProductExceptionList/editProductExceptionList.scss';
@import './tradeAreaAssortments/editProductExceptionList/addNewProduct.scss';
@import './tradeAreaPriceComponentsAndServices/tradeAreaPriceComponentsAndServices.scss';
@import './tradeAreaPriceComponentsAndServices/addOrEditServices/addOrEditServices.scss';
@import './tradeAreaPriceComponentsAndServices/addOrEdditPrices/selectAssortmentsForPriceComp.scss';
@import './tradeAreaPriceComponentsAndServices/addOrEdditPrices/selectPriceComponentsForTradeArea.scss';
@import './tradeAreaPriceComponentsAndServices/addOrEdditPrices/selectPriceComponentsTradeAreaAssortments.scss';
@import './tradeAreaAssortments/pricePeriodModal/pricePeriodModal.scss';
