﻿@import '../../../shared/sass/_variables.scss';

.working-sites-area-details {

    .field-width {
        width: 60% !important;
    }

    .responsible-person {
        display: inline-flex !important;
    }

    .grid-button>span>div {
        width: 100%;
    }

    .grid-button>span>div>button {
        width: 100%;
    }

    .panel-height {
        height: 100%;
        overflow-x: hidden;
    }

    .padding-right {
        padding-right: 5px;
    }

    .right-panel {
        flex-grow: 1;
        margin-left: 1em;
        position: relative;

        .placeholder-container {
            .placeholder-wrapper {
                background-image: url(../../content/images/svg/map/DisabledMapIcon.svg);
                background-repeat: no-repeat;
                max-height: 558px;
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: lightgray;
                border-radius: 5px;
                border: 1px solid gray;
                text-align: center;
                vertical-align: central;
            }

            .load-button-container {
                width: 100%;
                height: 50px;
                position: absolute;
                text-align: center;
                vertical-align: central;
                top: 50%;

                .load-button {}
            }
        }

        .worksite-navigation {
            height: 42px;
            display: block;
            position: relative;
            top: -7.5px;

            ul {
                padding: 4.5px 0;
            }
        }

        .map {
            @extend %silva-box;
            height: calc(100% - 42px);
            margin: 0;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .full-width {
        width: 100%;
    }

    .container-row {
        display: flex;
        margin-bottom: 1em;
        height: 592px;

        .left-panel {
            width: 33%;
            height: 592px;
            display: flex;
            flex-direction: column;
            transition: width 200ms linear;
            overflow-x: hidden;
            overflow-y: hidden;

            &.sidebar-hidden {
                width: 0%;
                overflow-x: hidden;
            }

            .flex-row-container {
                display: flex;
                margin-bottom: 0.5em;

                div:not(:last-child) {
                    margin-right: 0.5em;
                }

                .icon-button {
                    height: 34px;
                    padding-left: 0;

                    button {
                        height: 34px;
                        vertical-align: middle;
                        width: auto;
                        padding: 6px 12px;
                    }

                    .icon {
                        height: 17px;
                        width: 18px;
                        line-height: 30px;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 5px;
                    }

                    .icon-printer {
                        background-image: url('../../../content/images/svg/print.svg');
                    }
                }

                .responsible-person {
                    height: 34px;
                    display: flex;
                    margin-right: 0.3em;

                    .contact-icon {
                        height: 32px;
                        width: 40px;
                        margin-right: 2px;
                    }

                    div {
                        flex-grow: 1;
                    }

                    .form-control {
                        border: 1px solid #ccc;
                        margin-left: -8px;
                        padding-left: 2px;
                        padding-top: 4px;
                    }
                }

                .service-and-member-information {
                    label {
                        font-size: 12px;
                        color: #008752;
                        margin-bottom: 0;
                        margin-left: -15px;
                    }
                }
            }

            .panel {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin: 0;

                .harvest-area {
                    float: left;
                }

                .general-information {
                    padding: 0;
                }

                .left-margin-fix {
                    margin-left: 50px;

                    @media screen and (max-width: 1300px) {
                        margin-left: 0;
                    }
                }

                .col-sm-8.general-information {
                    margin-right: 27px;
                }

                .col-sm-3.general-information {
                    width: 26%;
                }
            }

            .panel-heading {
                background-color: $gray;
                text-transform: uppercase;
            }

            a:not(.dropdown-menu-item) {
                text-decoration: underline;
            }

            .heigth-240 {
                height: 240px;
            }

            .dropdown-menu {
                right: 0;

                &.working-sites-area-details-list {
                    height: auto;
                    max-height: 400px
                }

                &.status-list {
                    height: auto;
                    max-height: 375px;
                }

                a {
                    text-overflow: ellipsis;
                    overflow-x: hidden;
                }

                &.main-group.height-auto {
                    height: auto;
                }

                &.contractors-dropdown {
                    height: 180px;
                }
            }

            .dropdown-menu.smaller-height {
                max-height: 90px;
            }

            .dropdown-menu.panel-height {
                max-height: 175px;
            }

            .working-sites-area-details-form {
                padding: .5em 0em;

                .row .font-grey-background {
                    margin-left: -15px !important;
                    margin-right: -15px !important;
                }

                .row {
                    margin-left: 0px !important;
                    margin-right: 0px !important;
                }

                .col {
                    padding: 0;
                }

                .btn.dropdown-toggle span {
                    max-width: 128px;
                    overflow: hidden;
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .harvest-start {
                    width: auto;
                    text-align: initial;
                }

                .min-width {
                    min-width: 140px;
                }

                .ready-to-harvest {
                    width: 100%;

                    .checkbox-label.ready-to-harvest {
                        width: auto;
                        float: left;
                    }

                    .who-modified-icon {
                        height: 16px;
                        width: 16px;
                        margin-top: -8px;
                    }
                }

                .row .margin-top-seven {
                    margin-top: 7px;
                }

                .row .custom-height {
                    height: 41px;
                }

                .checkboxes-left-part {
                    @media screen and (max-width: 1800px) {
                        width: 36%;
                    }

                    @media screen and (max-width: 1600px) {
                        width: 45%;
                    }

                    @media screen and (max-width: 1300px) {
                        width: 53%;
                    }
                }

                @media screen and (max-width:1250px) {
                    .two-column-inputs {
                        display: grid;
                    }

                    .min-width-label {
                        min-width: 90px;
                    }
                }
            }

            .harvesting-area-checkboxes {
                padding: 1em 0em 0em;
                padding-top: 9px;

                @media screen and (max-width:1300px) {
                    &.wood-force {
                        display: grid;
                    }
                }

                &.wood-force {
                    width: 540px;
                }

                &.no-padding {
                    padding-right: 0px;
                    padding-left: 0px;
                }
            }

            .btn:not(.not-transparent) {
                height: 27px;
                padding: 3px 6px;
                border-color: $darkgray;

                &:focus {
                    background: transparent;
                    border-color: #66afe9;
                }

                &:hover {
                    background: transparent;
                }
            }

            .btn-energy {

                &:focus,
                &:hover {
                    background: #005F9E !important;
                }
            }

            form,
            .form-like {
                display: flex;
                flex-direction: column;

                .delimited-section {
                    padding: .5em;
                    padding-right: 7px;                    
                    margin-left: -7px;
                    border-bottom: 1px solid $gray;                    
                    padding-left: 7px;
                }                

                .lohko-selection {

                    .dropdown,
                    label {
                        padding: 0;
                    }

                    label {
                        width: 20%;
                        min-width: auto;
                        margin-right: 0.3em; // fix for larger container than in other combinations
                    }
                }

                .editing-details-section {
                    .form-group {
                        padding: 0 0 0 0.5em;

                        label {
                            flex-basis: 19.3%;
                            min-width: 19%;
                        }

                        input[type="text"] {
                            flex-basis: 50%;
                            margin-right: 10px;
                        }

                        input[type="date"] {
                            flex-basis: 20.7%;
                        }
                    }
                }

                .harvest-date-section {


                    .row {
                        margin-left: 0;
                        margin-right: 0;
                    }

                    .col {
                        padding: 0;
                    }

                    .form-group {
                        padding: 0 0 0 0.5em;

                        label {
                            flex-basis: 40%;
                        }

                        input[type="date"] {
                            flex-basis: 60%;
                        }
                    }
                }

                uib-accordion {
                    display: flex;
                    flex-direction: column;
                    border-radius: 3px;

                    .accordion-toggle {
                        outline: none;

                        .rotate-open {
                            transform: rotate(90deg);
                        }
                    }

                    .panel-default>.panel-heading {
                        background: $white;
                        color: $black;
                        border-color: $white;
                        border-radius: 0;
                        font-size: 1em;
                        padding: .5em;
                        min-height: initial;

                        a,
                        i.glyphicon {
                            color: currentColor;
                            text-decoration: none;
                        }

                        .header-span {
                            margin-left: 0.5em;
                        }

                        .panel-title {
                            font-weight: bold;
                            font-size: 1em;
                        }
                    }

                    .panel {
                        border: 0;
                        border-bottom: 1px solid $gray;

                        &:first-child {
                            height: 100% !important;
                        }

                        &.panel-open>.panel-heading,
                        &>.panel-heading:hover {
                            background-color: $black;
                            color: $white;
                        }

                        .max-height-fix {
                            border: none;
                        }

                        &.general-information-tab {
                            border-bottom: 0px;

                            .panel-collapse {
                                overflow-y: hidden;
                                overflow-x: hidden;
                            }

                            @media screen and (max-width: 1300px) {
                                .panel-collapse {
                                    overflow-y: auto;
                                }
                            }
                        }
                    }

                    .panel-collapse {
                        .panel-body {
                            padding: .5em 0 0 .5em;
                        }
                    }

                    .panel-group {
                        margin: 0;
                        display: flex;
                        flex-direction: column;

                        .panel-collapse.in {
                            overflow-y: clip;

                            .main-work-code {
                                width: 40%;
                            }
                        }

                        .general-information-accordion {

                            &.general-information-accordion-close {
                                height: auto;
                            }
                        }

                        .wood-force-accordion {
                            &.wood-force-accordion-close {
                                height: auto;
                            }

                            .wood-force.max-height-fix {
                                height: 100%;
                            }
                        }
                    }
                }

                .form-group {
                    display: flex;
                    align-items: center;

                    &.harvesting-area-checkbox {
                        display: inline-block;

                        [type="checkbox"]:checked+label:before,
                        [type="checkbox"]:not(:checked)+label:before {
                            top: -2px;
                        }

                        /* checked mark aspect */
                        [type="checkbox"]:checked+label:after,
                        [type="checkbox"]:not(:checked)+label:after {
                            top: -2px;
                        }

                        #isWorkingSiteNew {
                            height: 18px;
                        }

                        #isWorkingSiteReady {
                            height: 18px
                        }

                        &.continued-to-harvest {
                            display: block;
                            width: fit-content;
                        }
                    }

                    label {
                        width: 40%;
                        min-width: 25%;
                        margin-right: .5em;
                        float: none;
                        overflow: hidden;
                        display: inline-block;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        &.checkbox-label {
                            width: 100%;
                            overflow: visible;
                        }

                        &.no-white-space {
                            white-space: unset;
                        }

                        &.width-18 {
                            width: 18%;
                        }

                        &.min-width-18 {
                            min-width: 18%;
                        }
                    }

                    input,
                    textarea,
                    [uib-dropdown],
                    select {
                        flex-grow: 1;
                        float: none;
                    }

                    .hakuttapa-section {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 100%;
                    }
                }

                .controls {
                    margin-bottom: 0.5em;
                }
            }

            form {
                flex-grow: 1;
            }

            .button-row {
                align-self: flex-end;
                padding: 0.5em;
                background-color: $lightgray;
                display: flex;
                width: 100%;
                margin-top: 1em;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                min-height: 4em;
                margin: 0;

                button {
                    flex-grow: 1;
                    margin-right: 0.5em;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .trade-areas-dropdown {
                width: 58%;
            }
        }

        .right-panel {
            display: flex;
            width: 66%;
            -ms-flex-direction: column;
            flex-direction: column;
            padding: 0;

            &.sidebar-hidden {
                width: 100%;
                overflow-x: hidden;
            }

            .harvestingarea-navigation {
                height: 43px;
                display: block;

                ul {
                    padding: 4.5px 0;
                }
            }

            .map {
                height: calc(100% - 43px); // navi
            }
        }

        #formGroupStatus {
            color: #333;
        }

        #formGroupStatus[disabled] {
            background-color: #eee;
        }
    }

    .harvesting-area-plots {
        .common-grid {
            .common-grid-grid {
                button {
                    width: auto;
                }
            }

            .panel-sp-grid .panel-footer .flex-container {
                display: block;
            }
        }

        .common-grid .sp-grid .ag-root .ag-header {
            background: linear-gradient(rgba(0.075, 0.075, 0.075, 0.150), #d3d3d3);
        }

        .ag-root.ag-root {
            border-radius: 0 0 0 0; //Override ag-grid default
        }
    }

    .bottom-buttons {
        margin: 1em 0;

        .btn {
            padding: 1em 2em 1em 2em;
            margin-right: .5em;
        }

        .btn.pull-right {
            margin-right: 0;
            margin-left: .5em;
        }
    }

    .dropDownMinWidth350px {
        width: 100%;

        .first-column {
            padding-left: 6px;
            width: 25%;
        }

        .second-column {
            width: 60%;
        }

        .background-color {
            background-color: #d3d3d3;
        }

        .dropdown-toggle {
            width: 27px !important;
        }

        .custom-button {
            text-align: left;
            width: calc(100% - 26px) !important;
        }

        .dropdown-menu {
            width: 350px;
            margin-top: 0px;
            z-index: 1000;
            display: none;
            padding: 0;
            box-shadow: none;
        }

        .silva-search {
            display: none;
        }

        .drop-down-table {
            position: relative;
            display: none;
        }

        .btn-group.open {
            .dropdown-menu {
                display: block;
                padding: 5px;
            }

            .silva-search {
                display: block;
            }

            .drop-down-table {
                display: block;
            }
        }
    }

    .form-control-label {
        &.general-information {
            margin-bottom: 0;
        }

        &.general-information-label {
            width: 19.3% !important;
            min-width: 19% !important;
        }
    }

    textarea {
        &.vertical-resizable {
            resize: vertical;
            min-height: 94px;
        }
    }

    .additional-info-content {
        padding-bottom: 28px;
        padding-left: 7px;

        &.chipping {
            margin-bottom: 7px;
        }

        label {
            text-transform: uppercase;
        }
    }

    .font-grey-background {
        margin-top: -15px;
        height: 30px;
        background-color: #d1d1d1;
        padding-top: 7px;
        padding-bottom: 10px;
        padding-left: 13px;
        padding-right: 10px;
        margin-bottom: 14px;
    }

    .header-accordion p {
        padding-left: 9px;
    }

    .general-information-tab {
        .panel-body {
            padding-top: 0 !important;
        }
    }

    .container-row .left-panel form uib-accordion {
        .wood-force {
            &.max-height-fix {
                .panel-collapse.in {
                    overflow-x: hidden;
                }
            }

            &.panel-collapse.collapse.in {
                overflow-y: inherit;
            }
        }

        .wood-force-accordion {
            .accordion-content.additional-info {
                margin-top: 11px;
                border-bottom: 0px solid #d1d1d1;

                .additional-info-content {
                    padding-right: 7px;
                }

                .font-grey-background {
                    border-left: $grid-border;
                    border-right: $grid-border;
                }
            }
        }
    }

    .container-row .left-panel form uib-accordion .hide-from-production {
        .wood-force {
            &.max-height-fix {
                height: 100% !important;
            }
        }
    }

    .additional-info {

        &.panel-open {
            border: none !important;
        }

        & .panel-collapse.collapse.in {
            & .panel-body {
                border-top: none;
            }
        }

        .panel-collapse {
            overflow-y: hidden;
            overflow-x: hidden;
        }

        .accordion-content {
            margin-top: 8px;
        }
    }

    .wood-force .glyphicon-remove {
        top: -33px;
        left: -4px;
    }

    .border-bottom-none {
        border-bottom: none !important;
    }

    .transportban-header {
        background-position-y: 2px;
        background-position-x: 29px;
    }

    .common-grid .sp-grid .ag-root .ag-header {
        background: linear-gradient(rgba(0.075, 0.075, 0.075, 0.150), #d3d3d3);
    }

    .ag-group-expanded {
        padding-left: 7px;
    }

    .viol-details-form {
        padding-bottom: 0px !important;
        
        .empty-row {
            height: 27px;
        }

        .margin-top-8px {
            margin-top: 8px;
        }

        .padding-top-26 {
            padding-top: 26px;
        }

        .lld-down-options {
            width: 82%;

            .drop-down-table {
                .first-column {
                    width: 25%;
                }

                .second-column {
                    width: 73%;
                }
            }

            .custom-button {
                text-align: left;
            }
        }
    }    

    .update-to-viol {
        padding: 0.5em;
        flex-grow: 1;
    }

    .integration-label {
        padding: 0.5em 0;
        text-transform: uppercase;
        font-weight: 700;
        text-wrap: nowrap;
    }

    .integration-row {
        margin: 0.5em 0;
        //background-color: #eee;
    }

    .viol-integration-error {
        display: flex !important;

        .viol-error-text {
            flex-grow: 1;
        }
    }    

    .viol-error-icon {
        flex-grow: 0;
        float: right;
        margin-left: 5px;
        margin-top: 2px;
    }

    .viol-integration-error-wrapper {
        padding: 5px 5px 10px 5px;
    }

    .logistic-information-receiver {
        margin-top: 20px;
        padding-bottom: 10px;
        border-top: 0.75px solid $trimble_brand_grey;
        background: $gray 0% 0% no-repeat padding-box;
        padding-left: 20px;

        button {
            margin-top: 10px;
        }
    }
    .harvest-area-viol3 {
        fieldset {
            margin-top: -7px;        
            .delimited-section {
                padding-top: 0 !important;
            }    
        }
    }
}