.edit-assortment {
    .modal-dialog {
        top: 23%;
        width: 800px;
    }

    .custom-button {
        text-align: left;
        padding-top: 3px;
        height: 27px;
    }

    .drop-down-table {
        padding: 5px;
    }

    .dropdown-toggle {
        padding-top: 3px;
        height: 27px;
    }

    #formAssortmentDeliveryContract {
        width: 100%;

        &[disabled="disabled"] {
            border: 1px solid #616161;
            background-color: #eee;
        }
    }
    
    .assortment-code-required {
        .custom-button , .dropdown-toggle {
            background-color: #FFD8D8
        }
    } 
    
    .edit-assortment-down-options[disabled="disabled"] {
        button,
        button[disabled="disabled"]:hover {
            border: 1px solid #616161;
            background-color: #eee;
        }
    }

    .table-cell.first-column {
        width: 100px;
    }

    .delivery-contract-header {
        background-color: #d3d3d3;
    }

    .delivery-contract-section {
        .dropdown-menu {
            width: 771px !important;
            height: 200px;

            .drop-down-table {
                height: 155px;
            }

            .table-row .table-cell {
                &.delivery-contract {
                    width: 275px;
                    padding-top: 3px;
                    padding-left: 5px;
                }

                &.contract-ready-column {
                    width: 112px;
                    margin-top: -9px;
                }

                &.additional-information-column {
                    width: 148px;
                    padding-top: 3px;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }

                &.start-column {
                    width: 100px;
                    padding-top: 3px;
                }

                &.end-column {
                    width: 100px;
                    padding-top: 3px;
                }
            }

            .custom-table .table-body .delivery-contract-header {
                .table-cell {
                    padding-top: 3px;

                    &.delivery-contract {
                        width: 275px;
                        padding-left: 5px;
                    }

                    &.contract-ready-column {
                        width: 112px;
                        padding-top: 7px;
                    }

                    &.additional-information-column {
                        width: 148px;
                    }

                    &.start-column {
                        width: 100px;
                    }

                    &.end-column {
                        width: 100px;
                    }
                }
            }
        }

        .contract-is-invalid {
            .custom-drop-down {
                .custom-button,
                .dropdown-toggle {
                    background-color: #FFD8D8
                }
            }
        }
    }
}