.harvesting-area-resources {

    .sp-grid-container {
        margin: 0;
    }

    .ag-filter {
        // column title filter modal
        margin-left: 0;
        background-color: $white !important;
        @extend %silva-box;

        .ag-filter-filter {
            border-radius: 5px;
            border: 1px solid $darkgray !important;
            width: 96%;
            outline: none;
            padding: 0 0.5em;
        }

        .ag-filter-checkbox {
            margin-right: 5px;
            display: inline-block !important;
        }

        .ag-filter-select {
            width: 110px;
            margin: 4px 4px 0;
        }

        .ag-set-filter-list {
            width: 100%;

            .ag-virtual-list-viewport {
                overflow-x: hidden;
            }
        }
    }

    .ag-theme-fresh .ag-menu {
        width: 200px !important;
    }


    .notes-icon,
    .lock-open-icon,
    .lock-closed-icon {
        cursor: pointer;
        display: block;
        margin: auto;
    }

    .ag-row-hover {
        .lock-open-icon {
            @extend .lock-open-white-icon;
        }

        .lock-closed-icon {
            @extend .lock-closed-white-icon;
        }
    }

    .ag-floating-bottom-viewport{
        border-top: 1px solid #616161;
        
        .ag-row{
            background-color: $gray !important;
        }
    }

    .ag-cell input:disabled{
        background-color: transparent !important;
    }
    .resources-grid {
        height: 449px;

        .master-header {
            text-transform: uppercase;
        }

        .center-title {
            text-align: center;
        }

        select:disabled {
            background-color: $disabled-input-color;
            pointer-events: all;
        }


        .date-format {
            width: 100%;
        }

        .ag-body-viewport {
            overflow-x: auto;
        }

        .fixed-checkbox-container {
            label {
                margin-bottom: 0;
                height: 18px;
                width: 18px;
                padding-left: 18px;

                &:before {
                    top: 0;
                }

                &:after {
                    top: 0px;
                }
            }

            &.published {
                display: inline;
                padding-right: 0.2em;
            }

            &.workdone {
                position: relative;

                label {
                    position: absolute;
                    left: calc(50% - 10px);
                }
            }
        }

        .ag-floating-bottom-container {
            .ag-cell:first-child {
                padding-left: 0.5em;
                width: 200px !important; // for the first cell with "total rows"
            }

            .ag-cell {
                border-right: none;
            }
        }
    }
    // endof resources-grid
}
