﻿@import '../../../../shared/sass/_variables.scss';

.add-payment-deduction {
    .search-bar {
        border: 0.75px solid $trimble_brand_grey;
        border-radius: 0.2em;
        height: 2em;
        line-height: 2em;
        overflow: hidden;
        padding: 0 0.4em;

        input {
            border: 0;
            height: 2em;
            outline: 0;
            padding-left: 0.2em;
            width: 94%;
        }

        span.glyphicon-remove {
            margin-right: 0px;
        }

        .glyphicon-remove {
            top: 6px;
        }
    }

    .p-15 {
        padding: 15px;
    }

    .pt-0 {
        padding-top: 0px;
    }

    .grid-container {

        .sp-grid {
            max-height: 310px;
            overflow-y: auto;

            .grid-check-box {
                top: -8px;
            }

            .ag-root.ag-root {
                border-radius: 0.2em;
                border: 0.75px solid $trimble_brand_grey !important;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    top: -8px;
                }
            }

            .sp-grid .ag-root .ag-header .ag-header-row .ag-header-cell {
                border-right: 0.75px solid $trimble_brand_grey !important;
            }
        }

        .select-all-wrapper {
            left: 3px;
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }
    }
}
