﻿@import '../../../../shared/sass/_variables.scss';

$tab-border-width: 1px;

.accounting-management {
    .accounting-management-tab {
        background-color: $white;
        border-radius: 0 0 0.5em 0.5em;

        .tab-container {
            background-color: $trimble_very_light_grey;
            top: 0;

            .tab {
                margin: 0;

                &.selected {
                    border-bottom: none;
                }

                &:first-child {
                    margin-right: 11px;
                }
            }
        }

        .tabs-content {
            border-radius: 0 0 0.5em 0.5em;
            margin: 0;
        }
    }

    .accounting-management-footer {
        border-radius: 3px 3px 0px 0px;
        background: #F3F3F7 0% 0% no-repeat padding-box;
        height: 64px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px
    }

    .accounting-management-button {
        margin: 15px;
        margin-right: 0px;
    }

    .selection-section {
        display: flex;
        margin-bottom: 15px;

        .material-switch-container {
            margin-left: 5px;
        }

        .material-switch-label {
            top: -7px;
        }

        .material-switch-content {
            > input[type="checkbox"]:checked + label::after {
                top: 4px;
            }

            > input[type="checkbox"]:not(:checked) + label::after {
                top: 3px;
            }
        }

        .material-switch-container {
            width: 50px;
            float: left;
            padding-top: 5px;
        }

        .active-text {
            /*width: 8%;*/
            margin-right: 10px;
            margin-left: 15px;
            margin-top: 5px;
        }

        .status-text {
            width: 8%;
            margin-left: 5px;
            margin-top: 5px;
        }
    }

    .border-radius {
        border-radius: 4px;
    }

    .height-s {
        height: 30px;
    }

    .specification-option {
        width: 290px;
    }

    .text-input-field {
        height: 30px;
        border: 0.5px solid #B0AFB0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        width: 100%;
        border-radius: 5px;
    }

    .text-input-field-grid {
        height: 25px;
    }

    .padding-right-20 {
        padding-right: 20px;
    }

    .width-150 {
        width: 150px;
    }
}
