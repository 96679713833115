.harvesting-plots {
    .border-radius-bottom {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .buttons-section {
        margin-bottom: 14px;

        .footer-section {
            height: 50px;
            margin: 0px;
            border: 1px solid $darkgray;
            border-top: 0;
            border-radius: 0.5em;
            background-color: #e8e8e8;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            overflow: hidden;
            padding: 0.5em 0.5em;
        }

        .medium {
            width: 170px;
        }

        .large {
            width: 16%;
            height: 38px;
            margin-bottom: 14px;
        }

        .xlarge {
            width: 30%;
            height: 38px;
        }

        .spacing-grid-buttons {
            margin-left: 1%;
        }

        .spacing-bottom-buttons {
            margin-left: 1%;
        }

        .btn {
            padding-left: 1%;
            padding-right: 1%;
        }
    }
}