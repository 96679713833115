﻿@import '../../../shared/sass/_variables.scss';

.buyers-compensation-list-view {
    margin-top: 0.5em;
    animation: fade-in 250ms linear;

    .list-view-settings-row {
        .advanced-filtering-dropdown .dropdown-content {
            width: 788px;

            .dropdown-table {
                .table-cell {
                    .multiselection-drop-down .checkboxes {
                        width: 301px;
                    }

                    .dropdown-date-input {
                        width: 161px;
                    }
                }
            }

        }
    }

    &:not(.container) {
        animation: fade-in 250ms linear;
    }

    .buyers-compensation-list-table {
        margin: 0.5em 0 0;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;
        height: 50vh;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .buyers-compensation-list-table {
        .checkbox-cell {

            // checkbox is rendered as an image
            img {
                cursor: pointer;
            }
        }

        .ag-header {
            // for some reason the whole header is jumping up after select-all-click
            position: initial;
        }

        //Hide checkbox-cells in group-rows, couldn't find a way to do this easily programmatically
        .ag-row-group .checkbox-cell {
            display: none;
        }

        .ag-row .ag-selection-checkbox {
            position: static;
            margin-left: 6px;
        }

        .unselect-control {
            padding-left: 6px;
            padding-top: 3px;

            [type="checkbox"]:not(:checked)+label,
            [type="checkbox"]:checked+label {
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                top: -10px;
            }
        }

        .ag-group-contracted .ag-group-expanded {
            margin-left: 1px;
        }

        .ag-group-value {
            display: inline-flex;
        }

        .ag-icon-expanded,
        .ag-icon-contracted {
            background-color: white;
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-selected {
                    [type="date"] {
                        color: white;
                    }
                }
            }
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-hover {
                    [type="date"] {
                        color: white;
                    }
                }
            }
        }

        .ag-header-group-cell {
            text-transform: uppercase;
        }

        .ag-cell input:disabled.date-format {
            background-color: transparent;
        }

        .status-padding {
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 83%;
        }

        .ag-body .ag-row.ag-row-hover .ag-cell .icon.icon-user.user-image-button {
            margin-right: 5px;
            background-image: url(../../../content/images/svg/User_Menu.svg);
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-selected {
                    .icon.icon-user.user-image-button {
                        margin-right: 5px;
                        background-image: url(../../../content/images/svg/User_Menu.svg);
                    }
                }
            }
        }

        .no-padding {
            padding: 0px;
        }
    }

    .buyer-compensation-tablefoot-actionbar {
        border: 1px solid $darkgray;
        border-top: 0;
        border-radius: 0.5em;
        background-color: $lightgray;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;

        button {
            margin-right: 0.5em;

            &.no-margin {
                margin-right: 0;
            }
        }
    }

    .btn-compensation-euro {
        margin-left: 5px;
    }

    .no-rows-bubble {
        margin-left: 36.5%;
        padding: 10px;
        background-color: white;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
        text-align: left;
        width: 162px;
    }
}