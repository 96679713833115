
.tab-nav {
    .nav-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        font-size: 1.05em;
        font-family: $font-semibold;

        .current {
            background-color: white;
            position: relative;
        }
        border-bottom: 1px solid dimgray;
    }

    .nav-item {
        width: 100%;
        flex: 1;
        margin-left: 3px;
        margin-right: 3px;
        border-top-left-radius: 6px;
        padding: 0.2rem 0.5rem 0.0rem 0.5rem;

        border-top-right-radius: 6px;
        border-top: 1px solid dimgray;
        border-left: 1px solid dimgray;
        border-right: 1px solid dimgray;
        background-color: $gray; 
        text-align: center;
    }
}