$form-control-height: 27px;

.admin, .customer-info {
    //If this is not set the usertable causes a weird overflow bug where the page grows the amount of scrollable table space
    overflow: hidden;
    margin-bottom: 30px;

    .margin-top-10 {
        margin-top: 10px !important;
    }

    .margin-top-2 {
        margin-top: 2px !important;
    }

    .margin-bottom-10 {
        margin-bottom: 10px;
    }

    .checkboxLabel {
        max-width: 15px;
    }

    .padding-top-5 {
        padding-top: 5px;
    }

    .margin-right-5 {
        margin-right: 5px;
    }
    //Make the flex-item wrap when about to go below the specified min-width
    .flex-wrap-on-width {
        min-width: 550px;

        .partial-content {
            margin: 10px 10px 10px 10px;

            .accordion-partial {
                @extend .accordion-panel;
                @extend .accordion-panel-open;
                @extend .accordion-panel-hover;

                .panel-group .panel {
                    margin-bottom: -6px;
                }

                .panel-body {
                    padding: 0;
                    border-top: 0;
                }

                #main-office {
                    border-radius: 0.5em 0.5em 0 0;
                    border-top: 1px solid $darkgray;
                    border-left: 1px solid $darkgray;
                    border-right: 1px solid $darkgray;

                    .panel-heading {
                        border-radius: 0.5em 0.5em 0 0;
                        height: 35px;
                    }
                }

                #regional-offices {
                    border-radius: 0;
                    border-top: 1px solid $darkgray;
                    border-left: 1px solid $darkgray;
                    border-right: 1px solid $darkgray;
                    border-bottom: 1px solid $darkgray;

                    .panel-body {
                        border-top: 0px;
                    }

                    .panel-heading {
                        border-top-right-radius: 0px;
                        border-top-left-radius: 0px;
                        height: 30px;
                    }

                    .collapse.in, .collapse {
                        overflow: auto;
                    }

                    #offices {
                        border-radius: 0;
                        border-left: 0;
                        @extend .accordion-panel;
                        @extend .accordion-panel-open;
                        @extend .accordion-panel-hover;

                        .panel-heading {
                            border-radius: 0;
                            padding-left: 20px;

                            .panel-title {
                                i.glyphicon.glyphicon-remove {
                                    font-size: 14px;
                                }
                            }
                        }

                        .panel-group .panel {
                            border-radius: 0;
                            /*padding-left: 20px;*/
                            border-right: 0;
                            border-left: 0;

                            .panel-body {
                                /*border-top: 0px;*/
                                .content {
                                    .form-group {
                                        .control-label {
                                            padding-left: 20px;
                                        }
                                    }
                                }
                            }
                        }

                        .collapse.in, .collapse {
                            height: 300px !important;
                            overflow: hidden;
                        }
                    }
                }

                .rotateDown {
                    transform: rotate(90deg);
                    margin: 5px 5px 0px -5px;
                }
            }

            .accordion-panel {
                .panel-heading {
                    background-color: $white;
                    cursor: pointer;

                    .panel-title {
                        color: $black;
                        background-color: $white;
                        text-transform: uppercase;

                        a[role=button] {
                            color: $black;
                        }

                        a[role=button]:hover {
                            color: $black;
                            text-decoration: none;
                        }

                        a:focus {
                            outline: none;
                            text-decoration: none;
                        }

                        .glyphicon {
                            color: $black;
                            padding-right: 10px;
                        }
                    }
                }
            }

            .accordion-panel-hover {
                .panel-heading:hover {
                    background-color: $black;
                    color: $white;

                    .panel-title {
                        color: $white;
                        background-color: $black;

                        a[role=button] {
                            color: $white;
                        }

                        a[role=button]:hover {
                            color: $white;
                            text-decoration: none;
                        }

                        .glyphicon {
                            color: $white;
                        }
                    }
                }
            }

            .accordion-panel-open {
                .panel-open {
                    .panel-heading {
                        background-color: $black;

                        .panel-title {
                            color: $white;
                            background-color: $black;

                            a[role=button] {
                                color: $white;
                            }

                            a[role=button]:hover {
                                color: $white;
                            }

                            .glyphicon {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        .add-office {
            position: absolute;
            bottom: 10px;
            width: 96.5%;
            background-color: $gray;
            padding: 10px;
            border: 1px solid $darkgray;
            border-radius: 0 0 0.5em 0.5em;
        }
        /*.regional-offices-content {
            .display-regional {
                display: block !important;
            }
        }*/
    }

    .left-panel {
        height: 800px;
    }

    .right-panel {
        height: 100%;

        .admin-saveform {
            margin-top: 76px;

            .adminform-save {
                margin-top: 220%;
                width: 200%;
                height: 50px;
                margin-right: 5px;
            }
        }
    }

    .form-postal-info-group {
        .col:first-child > input {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        .col:last-child > input {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }

        .postal-code-area {
            margin-top: 0px;
        }
    }

    .partial {
        @extend %silva-box;
        flex: 1;

        .partial-footer {
            padding: 0.5em;
            //Make the floated buttons still stretch the footer-element around them
            overflow: hidden;

            .btn a {
                color: inherit;
            }
        }
        /* change the default behavior of checkbox elements to better fit the space they're in */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
            top: 0;
        }

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
            top: 0px;
        }

        .content {
            padding: 0.5em;
            padding-left: 0;
            position: relative;
        }
        /* MHY Contact partial */
        .content.expandable {
            transition: height 0.5s ease;
            overflow: hidden;
            height: 0;
            padding: 0;

            .form-group:last-of-type {
                margin-bottom: 0.5em;
            }
        }

        .content.expandable.active {
            padding-top: 0.5em;
        }

        .content.expandable.inner {
            overflow: auto;
        }

        .partial-footer {
            background-color: $gray;
        }

        .theme-section {
            border-top: 1px solid $black;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
        }

        .inner {
            .partial-header.expandable {
                background-color: $white;
                color: $black;
                border-radius: 0;
            }

            .partial-footer {
                color: $black;
                border-radius: 0 0 0.5em 0.5em;
                width: 104.8%;
                bottom: 0;
                margin-bottom: 0px;

                button {
                    width: 100%;
                }
            }
        }

        .display-flex {
            display: flex;
        }

        .form-group {
            margin: 0;
            margin-bottom: 0.5em;
            padding: 0 0.5em;

            div:not(.popover-content) {
                padding: 0;
            }

            .control-label {
                padding-right: 0;
            }

            .control-label:first-child {
                padding-left: 0;
            }

            .form-control {
                padding: 0.2em;
                height: $form-control-height;
            }

            #mhy-main-office-phone-number, #mhy-main-office-fax {
                width: 150px;
            }
        }

        .form-group.border {
            padding-bottom: 0.5em;
            margin-bottom: 0.5em;
            border-bottom: 1px solid $gray;
        }

        .padd-left {
            padding-left: 0;
        }

        .padd-top {
            padding-top: 0;
        }

        .form-group.no-margin {
            margin: 0;
        }

        .partial-header {
            @extend %silva-header;
        }

        .partial-header-white-on-black {
            @extend %silva-header;
            color: $white;
            background-color: $black;
            padding: 0.5em;
        }

        .partial-header.expandable {
            background-color: $white;
            color: $black;
            border-radius: 0.5em 0.5em 0 0;
            cursor: pointer;
            position: relative;
            border-top: 1px solid $black;


            &.area-office-header {
                padding: 0;

                &:hover {
                    background-color: current-color;
                }
            }

            .glyphicon {
                transition: transform 100ms linear;
            }

            .sp-accordion-inner-header {
                width: calc(100% - 32px);
                height: 100%;
                padding: 0.5em;
            }
        }

        .remove-areaoffice-icon {
            font-size: 22px;
            line-height: 22px;
            width: 32px;
            text-align: center;
            position: absolute;
            padding: 5px;
            height: 100%;
            right: 0;
            top: 0;
            transition: transform 100ms linear;
        }

        .rotateDown {
            transform: rotate(90deg);
        }

        .invalid-areaoffice-name,
        .sp-areaoffice-name.duplicatename {
            color: $red;
        }

        .sp-areaoffice-name.duplicatename {
            border-color: $red;
        }

        .sp-accordion-add-area-office {
            cursor: pointer;
            margin-bottom: 4px;
            padding-bottom: 12px;
            border-radius: 0 0 0.5em 0.5em;

            &.disabled {
                cursor: initial;
                opacity: 0.5;
                cursor: not-allowed;

                &:hover {
                    color: initial;
                    background-color: $gray;
                }
            }
        }

        .partial-header.expandable.closed {
            border-radius: 0 0 0.5em 0.5em;
        }

        .partial-header.expandable.open {
            border-radius: 0;
        }

        .partial-header.expandable:not(.area-office-header):hover,
        .sp-accordion-inner-header:hover,
        .sp-accordion-add-area-office:hover {
            background-color: $black;
            color: $white;
        }

        .remove-areaoffice-icon:hover {
            color: $blue;
        }
        //Default status is "ok"
        .user-status-dot {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $gray;
            display: inline-block;
        }

        .admin-icon {
            font-size: 20px;

            &.enabled {
                color: $orange;
            }

            &.disabled {
                color: $lightgray;
            }
        }

        .center-text {
            text-align: center;
        }

        .upload-privacy-notice {
            display: inline-block;
            width: 114%;
            vertical-align: middle;
            margin-bottom: -11px;

            .btn-upload {
                max-width: 150px;
                min-width: 115px;
            }

            .file-upload-directive {
                width: 90%;

                .input-group {
                    .input-group-btn {
                        button.btn[disabled] {
                            border: 1px solid $darkgray !important;
                        }
                    }
                }
            }

            .col-xs-7 {
                width: 73%;
                padding-left: 0;
            }

            .col-xs-5 {
                width: 25%;
            }
        }
    }

    .no-border {
        border: none;
    }
    /* MHY custom logos partial */
    .partial.logos {
        display: flex;
        flex-direction: column;
        min-height: 310px;

        .partial-header {
            margin-bottom: 0px;
        }

        footer.logos-footer {
            border-bottom-right-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
            border-top: 1px solid $darkgray;

            .form-group {
                margin-bottom: 0.25em;
                margin-top: 0.25em;
            }

            .file-upload-directive {
                .input-group {
                    .input-group-btn {
                        button.btn[disabled] {
                            border: 1px solid $darkgray !important;
                        }
                    }

                    .field-invalid {
                        background-color: $lightred !important;
                    }
                }

                .popover {
                    width: 245px;
                    left: 0px !important;
                }

                .popover.bottom > .arrow {
                    margin-left: -70px;
                }
            }

            .flex-column:first-of-type {
                .partial-footer {
                    border-bottom-left-radius: 0.5em;
                }
            }

            .flex-column:last-of-type {
                .partial-footer {
                    border-bottom-right-radius: 0.5em;
                }
            }
        }

        .logo-upload {
            display: flex;
            padding: 0;
            flex-direction: column;

            label {
                margin: 0;
            }

            .logo-image {
                min-height: 82px;

                img {
                    max-width: 227px;
                    max-height: 58px;
                    margin-right: 0.5em;
                    margin-left: 0.5em;
                    margin-top: 0.9em;
                }
            }

            .logo-header {
                background-color: $black;
                color: $white;
                padding: 0.5em;
                text-align: left;
                height: 2.2em;
            }

            .partial-footer {
                border: 0;
                padding: 0.5em;
                background-color: $gray;
                overflow: visible;
            }

            .flex-column {
                border-top: 1px solid $gray;

                &:first-child .logo-wrapper {
                    border-right: 1px solid $gray;
                }

                .custom-logo {
                    .logo-height {
                        margin-left: -5%;
                        margin-top: 40px;

                        i.glyphicon.glyphicon-arrow-up {
                            top: -20px;
                        }

                        div {
                            transform: rotate(270deg);
                            transform-origin: 6px 8px 0;
                        }

                        i.glyphicon.glyphicon-arrow-down {
                            top: -2px;
                        }
                    }

                    .logo-image {
                        margin-left: -30px;

                        .logo-width {
                            text-align: center;
                            margin-right: -15px;
                            margin-top: 5px;

                            i .glyphicon.glyphicon-arrow-right {
                                margin-right: -35px;
                            }
                        }

                        .image-holder {
                            img {
                                margin-top: 0px;
                                margin-left: 2px;
                            }
                        }
                    }
                }
            }
        }

        .generic-theme {
            min-height: 252px;
        }
    }

    .row.admin-bottomrow {
        margin: 0 0 20px 0;
        align-items: center;
        /* override the >:not(.partial) - rule... hackety hack. */
        div.infosecurity-footer.infosecurity-footer {
            padding: 0.5em;
            overflow: hidden;
            min-width: 285px;

            .infosecurity-wrapper {
                border: 1px solid $darkgray;
                border-radius: 0.5em;
                overflow: hidden;
                width: 73%;
                min-width: 540px;
                background: #d1d1d1;
            }

            .fileinput-label {
                width: 165px;
                height: 100%;
                margin-bottom: 0;
                padding: 0 10px;
                text-transform: uppercase;
                white-space: nowrap;
            }

            .infosec-upload {
                display: inline-block;
                width: calc(100% - 170px);
                padding: 1em;
                padding-right: 0;
                vertical-align: middle;
                background-color: $white;

                .btn-upload {
                    max-width: 150px;
                    min-width: 115px;
                }

                .file-upload-directive {
                    width: 90%;
                }

                .col-xs-7 {
                    width: 66.6666667%;
                    padding-left: 0;
                }

                .col-xs-5 {
                    width: 25%;
                }
            }
        }
    }

    .row {
        padding: 0;
        margin: 0;

        &.modal-row-with-margins {
            margin-left: 20px;
            margin-right: 20px;
        }

        .modal-bottom-buttons {
            margin-top: 20px !important;
        }

        > div:not(.partial) {
            padding: 0;
            margin: 0;
        }

        > div.modal-message {
            margin: 20px;
        }
    }

    .admin-settings {
        .icon-eye-fill {
            width: 45px;
            height: 27px;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            margin-left: 335px;
            background-image: url(../content/images/svg/icon_eye_fill.svg);
        }

        .icon-eye-fill-off {
            width: 45px;
            height: 27px;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            margin-left: 335px;
            background-image: url(../content/images/svg/icon_eye_fill_off.svg);
        }

        .form-group {
            overflow: hidden;
            margin: 5px 0;

            &.no-margin {
                margin: 0;
            }

            & + .form-group {
                margin-top: 0;
                margin-bottom: 0;
            }
            //Override bootstrap default
            .radio-inline {
                margin-left: 0;
            }

            .form-control {
                height: 27px;
            }
        }

        .admin-input-label {
            float: left;
            width: 175px;
        }

        .database-location-wrapper {
            .databaselocation-url,
            label {
                width: 33%;
                min-width: 115px;
            }
        }

        .silvametsavara-credentials-wrapper {
            margin: 1.5em 0;
        }

        .sticker-prints {
            width: 100%;
            padding: 0.5em;
        }

        .left-part-border {
            border-left: 1px solid $darkgray;
        }

        .right-part-border {
            border-right: 1px solid $darkgray;
        }

        #no-radius-right {
            border-top-right-radius: 0;
        }

        #no-radius-left {
            border-top-left-radius: 0;
        }

        .settings-grid {
            .row {
                .partial-header {
                    padding: 7px;
                }

                .special-tab {
                    height: 82px;
                }
            }


            .inline-group {
                .form-group {
                    margin-bottom: 0px;
                }
            }
        }

        .partial-header-without-text {
            height: 31.6px; //TODO : need to hardcode the height on the same value as the .partial-header height
        }

        .second-tab-height {
            height: 96px; //TODO: need to calculate to occupy the same height as the section from the left of it
        }

        .bottom-section {
            padding-right: 8px;
        }

        .flex-grow-2 .settings-grid .checkboxLabel {
            position: initial;
        }
    }

    .settings-item {
        width: 50%;
        margin-left: 8px;
        background-color: $trimble_white;
    }

    @import 'membership.scss';
}
// some new sp-accordion styles in admin view, separated from rest so it doesn't accidentally break customer-info
.admin {
    form {
        .partial {
            sp-accordion {
                .content.expandable.inner {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-left: 25px;

                    sp-accordion {
                        display: flex;
                        flex-direction: column;

                        .accordion {
                            display: flex;
                            flex-direction: column;

                            .active {
                                flex-grow: 1;
                                overflow-y: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

.percentage-sign {
    font-size: 1.2em;
    text-align: center;
    height: 27px;
    line-height: 27px;
    font-weight: bold;
}

@import 'admin-style.mobile.scss';
@import 'adminFirstPage-style.scss';
@import 'harvesting/harvesting.scss';
@import 'cubic/cubicAdmin.scss';
@import 'mobileApp/mobileAppAdmin.scss';
@import 'buyersCompensation/buyersCompensation.scss';
