﻿@import '../../shared/sass/_variables.scss';

.forestEquipment {
    .forestEquipment-settings-row {
        width: 100%;
        height: 52px;

        .forestEquipment-search {
            float:left;
            line-height: 28px;
            height: 30px;
            margin: 0.5em 0.5em 0 0; 
            background-color: $trimble_white;
        }

        .forestEquipment-views {
          float:left;
          padding-top: 10px;
          padding-left:10px;

          input[type="radio"]+label {
              color: inherit;
              padding-left: 5px;
              padding-right: 15px;
			        vertical-align: top;
          }
          input[type=radio]:focus {
            outline: none;
          }

      		.label-alignment {
      			padding-top: 1px;
      		}
        }
    }
}