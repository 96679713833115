﻿.release-to-transport-modal-window {

    .modal-dialog {
        width: 800px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .separator {
        margin-top: 0;
        border-color: lightgray;
    }

    .no-bottom-margin {
         margin-bottom: 0;
    }

    .modal-grid {
        padding-left: 14px;
        padding-right: 14px;
        margin-bottom: 14px;

        .search-box {
            width: 600px;
        }

        .external-filter {
            margin-left: 20px;
        }

        .label-uppercase {
            text-transform: uppercase;
            color: black;
        }

        .gray-background {
            background-color: $disabled-background-color;
        }

        .red-background {
            background-color:  $lightred;
        }
    }

    .panel-heading {
        height: 29px;
    }
    
    .setDate {
        margin-right: 7px;
    }

    #release-to-transport-modal-grid-resource-chain{
        .ag-theme-fresh {
            &.common-grid-grid{
                height: 190px;
            }
        } 
        
        #agHeaderCellLabel{
            visibility: hidden;
        }
    }

    #release-to-transport-modal-grid-resource-chain-details{
        .ag-theme-fresh {
            &.common-grid-grid{
                height: 190px;
            }
        }  
    }

    .radio-group {
        margin: 0.5em 1em -0.5em 1em;
        margin-bottom: 7px;
        label {
            margin-right: 1em;
            vertical-align: middle;
        }

        input {
            margin-right: 0.3em;
        }
    }

    .start-date{
        margin: 0.5em 1em -0.5em 1em;
        margin-bottom: 18px;

        input{
            border-radius: 4px;
            border: 1px solid #616161;
            padding-left: 0.3em;
            width: 135px;
            margin-left: 5px;
        }
    }
}
