@import "./harvestingAreaPlots/selectPlotsModal.scss";
@import "./harvestingAreaStorageAmounts/harvestingAreaStorageAmounts.scss";
@import "./harvestingAreaResources/harvestingAreaResources.scss";
@import "./harvestingAreaMeasurement/harvestingAreaMeasurement.scss";
@import "./harvestingAreaMeasurement/harvesterFileDetailsModal.scss";
@import "./harvestingAreaStorageAmounts/harvestingAreaStorageAmountsReceiptsModal.scss";
@import "./harvestAreaPlanning/harvestAreaPlanning.scss";
@import "./harvestAreaPlanning/bucking.scss";
@import "./harvestingAreaMeasurement/harvesterFileDetailsPrintModal/harvesterFileDetailsPrintModal.scss";
@import "./harvestingAreaStorageAmounts/settingHarvestStartDateModel.scss";

.harvesting-area-details {
    .common-grid .common-grid-grid {
        border-radius: 0px;
    }

    .accordion-content {

        .custom-drop-down .dropdown-menu > div {
            max-height: 200px;
            height: auto;
        }

        .dropDown450px {
            flex-grow: 1;

            .btn-group {
                .dropdown-menu {
                    display: none;
                }
            }

            .btn-group.open {
                .dropdown-menu {
                    display: block;
                    padding: 5px;
                }
            }

            .dropdown-menu {
                width: 100%;

                .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12,
                .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5,
                .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9,
                .col-md-1, .col-md-10, .col-md-11, .col-md-12,
                .col-md-2, .col-md-3, .col-md-4, .col-md-5,
                .col-md-6, .col-md-7, .col-md-8, .col-md-9,
                .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12,
                .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5,
                .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9,
                .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12,
                .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5,
                .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
                    min-height: 18px;
                    padding-right: 5px;
                    padding-left: 5px;
                }

                .table-row:not(.table-header):hover {
                    .col-xs-4:not(.grey-background) {
                        background-color: dodgerblue !important;
                    }

                    .col-xs-3:not(.grey-background) {
                        background-color: dodgerblue !important;
                    }

                    .col-xs-2:not(.grey-background) {
                        background-color: dodgerblue !important;
                    }

                    .col-xs-1:not(.grey-background) {
                        background-color: dodgerblue !important;
                    }
                }
            }

            .btn {
                height: 27px;
                padding-top: 3px;
                padding: 3px 8px;

                &:hover {
                    background: none;
                }

                span {
                    float: left;
                }
            }

            button[disabled], button.btn[disabled] {
                border: 1px solid #616161;
            }

            background-color: white;
        }

        .no-animate {
            -webkit-transition: none !important;
            transition: none !important;
        }
    }

    .nav-container {
        .missing-compulsory-info-container {
            margin-top: -25px;
            margin-left: 20px;
        }
    }

    .margin-botton-7 {
        .tab-content {
            margin-bottom: 8em;
        }
    }

    .bottom-buttons {
        margin: 1em 0 0 0;

        .btn {
            padding: 1em 2em 1em 2em;
            margin: 15px 15px 15px 0px;
        }
    }

    .tab-nav .nav-item {
        a:focus {
            color: $black !important;
        }
    }
}
