﻿.icon {
  &-betaTag {
    background-image: url("/content/images/svg/BetaTagBlue.svg");
    background-repeat: no-repeat;

    &:hover {
      background-image: url("/content/images/svg/BetaTagBlue_MouseOver.svg");
      background-repeat: no-repeat;
    }

    width: 38px;
    height: 27px;

    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    border: none !important;
    border-radius: 0 !important;
  }
}
