﻿@import '../../../../shared/sass/_variables.scss';

.payments-modal {
    .modal-dialog {
        top: 15%;
        width: 1000px;
        height: 709px;
    }

    .hide-header {
        opacity: 0;
    }

    .margin-right-10 {
        margin-right: 10px;
    }

    .margin-top-15 {
        margin-top: 15px;
    }

    .grid-container {
        border: 0.75px solid $trimble_brand_grey;
        border-radius: 3px;

        .sp-grid {
            height: 300px;

            .grid-check-box {
                top: -8px;
            }

            .ag-root.ag-root {
                border-radius: 3px;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    top: -8px;
                }
            }

            .grid-header-checkbox-label {
                margin-bottom: 12px;
            }

            .ag-selection-checkbox {
                display: none;
            }

            .ag-body-viewport {
                overflow-x: hidden !important;
            }

            .cell-header-right {
                .ag-header-cell-text {
                    float: right !important;
                }
            }
        }

        .grid-small {
            height: 100px;

            .ag-root .ag-header .ag-header-row .ag-header-cell {
                border-right: 0px;
            }

            .ag-scrolls {
                height: 100px !important;
            }

            .ag-body {
                position: relative !important;
                top: auto !important;
                height: 100px !important;
            }

            .ag-header {
                position: relative !important;
            }


            & > div,
            .ag-body-viewport-wrapper,
            .ag-body-viewport {
                height: 100px !important;
                overflow: hidden !important;
            }
        }

        .ag-theme-fresh .ag-ltr .ag-header-group-cell-label {
            padding-left: 5px;
        }

        .sp-grid .ag-body .ag-body-container .ag-row:hover .ag-cell {
            img.invert {
                filter: invert(1);
            }
        }

        .sp-grid .ag-floating-bottom-container .ag-cell {
            background-color: $gray;
        }

        img.image-center {
            padding-top: 3px;
        }

        img:hover {
            filter: brightness(1);
        }

        .select-all-wrapper {
            left: 3px;
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }

        .mc-link {
            color: #009AD9;
        }

        .cell-header-right {
            .ag-header-cell-text {
                float: right !important;
            }
        }

        .cell-header-center {
            text-align: center;
        }

        .cell-header-group-left {
            .ag-header-group-text {
                float: left !important;
            }

            border-right: 0px;
        }


        .button-container-grid {
            padding: 10px;
            background-color: $lightgray;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .pen-icon {
            border-style: none;
        }

        .auto-renew-icon {
            background-color: $trimble_blue_light;
            margin-right: 5px;
            margin-top: -2px;
        }

        .black {
            background-color: $trimble_black !important;
        }

        .auto-renew-icon:hover:not(.black) {
            background-color: #005F9E;
        }


        .disabled-pen-icon:hover:not(.black) {
            background-color: #ddd;
            cursor: not-allowed;
        }

        .disabled-pen-icon {
            background-color: #ddd;
            cursor: not-allowed;
        }
    }
}
