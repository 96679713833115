@import "../../../../../../shared/sass/_variables.scss";

.edit-investment-settings-modal {
    .uppercase {
        text-transform: uppercase;
    }

    .modal-dialog {
        top: 15%;
    }

    .user-column {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 10px;
    }

    .multiselection-drop-down {
        .titleBox {
            width: 200px;
            height: 34px;
            overflow: hidden;
            border: 1px solid black;

            .titleBoxText {
                width: 75%;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 27px;
                padding: 6px 12px;
            }
        }

        .remove-triger-buttons .active-advanced-filter-modal {
            padding-top: 6px;
        }

        .remove-triger-buttons .remove-button .glyphicon.glyphicon-remove {
            top: 9px;
        }
    }

    .multiselection-drop-down .checkboxes {
        .margin-top-fix {
            margin-top: 8px;
        }

        hr {
            margin-bottom: 8px;
        }

        .multiselectionSearch {
            height: 29px;
        }
    }

    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        top: 0;
    }


    .edit-investment-modal-body {
        display: flex;
        flex-direction: row;
    }

    .grouped-items {
        width: 25%;
        margin-right: 10px;
    }

    .grid-container {
        margin: 15px 0px 0px;
        border: 1px solid $sp-panel-border-color;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom: none;

        .sp-grid {
            height: 150px;

            .grid-check-box {
                top: -8px;
            }

            .ag-root.ag-root {
                border-radius: 0px;
            }

            .cell-header-right {
                .ag-header-cell-text {
                    float: right !important;
                }
            }
        }

        .select-all-wrapper {
            left: 3px;
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }

        .ag-theme-fresh .ag-ltr .ag-selection-checkbox {
            padding-left: 3px;
            float: left;
        }

        .ag-theme-fresh .ag-header-select-all {
            padding-left: 6px;
        }

        .no-case {
            .ag-header-cell-label,
            .ag-header-group-cell-label {
                text-transform: none !important;
            }
        }
    }

    .grid-buttons {
        background: #d1d1d1 0% 0% no-repeat padding-box;
        border: 1px solid $sp-panel-border-color;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 10px;
        margin: 0px 0px 10px 0px;
        border-top: none;
    }

    .contract_base_text {
        padding-top: 15px;
        font-weight: bold;
    }
}
