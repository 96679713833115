﻿@import "../../../../shared/sass/_variables.scss";
.supervising-timber-trade {
    $font-family: "Myriad Pro", Verdana;
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    $form-control-height: 27px;
    font-family: $font-family;
    font-size: 12px;
    margin-top: 5px;

    .panel {
        margin-bottom: .5em;
        border: 1px solid gray;
        border-radius: 5px;
    }

    .panel-heading {
        background-color: black;
        color: white;
        text-transform: uppercase;
        border-bottom: 0px solid black;
        font-weight: bold;
        font-size: 12px;
        padding: 5px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .panel-heading-inside {
        background-color: black;
        color: white;
        text-transform: uppercase;
        border-bottom: 0px solid black;
        font-weight: bold;
        font-size: 12px;
        padding: 5px 15px 5px 15px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

	.panel-heading-inside a {
		color:white;
        text-decoration: underline;
	}
	
    .panel-heading-round-left {
        background-color: black;
        color: white;
        text-transform: uppercase;
        border-bottom: 0px solid black;
        font-weight: bold;
        font-size: 12px;
        padding: 5px;
        border-top-right-radius: 0px;
        border-top-left-radius: 4px;
    }

    .panel-heading-round-right {
        background-color: black;
        color: white;
        text-transform: uppercase;
        border-bottom: 0px solid black;
        font-weight: bold;
        font-size: 12px;
        padding: 5px;
        border-top-right-radius: 4px;
        border-top-left-radius: 0px;
    }


    .row-eq-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .sp-form-col {
        padding-top: 1em !important;
        padding-bottom: 1em !important;
    }

    .sp-form-divider {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: -1.2em;
        margin-right: -1.3em;
        border-bottom: 1px solid #d1d1d1;
    }

    .sp-form-divider-vertical {
        border-right: 1px solid #d1d1d1;
    }

    .form-horizontal {
        padding: 0 15px;
    }

    .content {
        padding-top: 0.5em !important;
        padding-bottom: 0.5em !important;
    }

    .content-textarea {
        height: 98%;
        flex-direction: column;
        justify-content: center;
        padding-top: 0.5em !important;
        padding-bottom: 0.5em !important;
    }

    .fieldvalidationextended div {
        height: 92%;
    }

    .align-left {
        padding-left: 5px;
    }

    .align-right {
        padding-right: 5px;
    }

    .align-with-textarea {
        padding-left: 9px;
    }

    .container-row {
        margin-bottom: 1em;
    }
	
    .border {
        border: 1px solid rgb(97,97,97);
        border-radius: 5px;
        background-color: white;
        padding-left: 15px;
        padding-right: 15px;
    }

    .supervising-timbertrate-notes {
        height: auto;
        margin: 0;
        resize: none;
        height: 96%;
    }

    .form-control-empty {
        height: $form-control-height;
    }

    .border-right {
        border-right: 1px solid rgb(97,97,97);
    }

    .section-header-left {
        color: white;
        background: black;
        text-transform: uppercase;
        font-family: "Myriad Pro";
        font-weight: bold;
    }

    .section-header-right {
        color: white;
        background: black;
        text-transform: uppercase;
        font-family: "Myriad Pro";
    }

    .section-header {
        color: white;
        background: black;
        font-family: "Myriad Pro";
    }

    .section-header-no-caps {
        color: white;
        background: black;
        font-family: "Myriad Pro";
    }

    div .section-bottom-buttons {
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;

        .btn {
            width: 18.5%;
            margin-right: 1em;
            padding-top: 10px;
            padding-bottom: 10px;

            &:nth-child(3) {
                margin-right: 0;
            }
        }
    }

    .rounded-header-left {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 5px;
    }

    .rounded-header-right {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 5px;
    }

    .flex {
        display: flex;
    }

    .form-control {
        padding: 0.2em;
        height: $form-control-height;
    }

    .info-text-control {
        padding: 0.2em;
        height: $form-control-height;
        font-style: italic;
        color: rgb(97,97,97);
    }

    .no-padding-left {
        padding-left: 0;
    }

    .color-background {
        color: white;
    }

    .no-lr-padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    .arrowDisabled {
        color: #eee;
        vertical-align: bottom;
    }

    .arrowEnabled {
        color: $trimble_tf_product_green;
        vertical-align: bottom;
    }

    .bottom-margin {
        margin-bottom: 5px;
    }

    .fix-bootstrap-col11-percent-radius {
        background: black;
        border-radius: 0 3px 0 0;
    }

    .fix-bootstrap-col11-percent {
        background: black;
    }
}
