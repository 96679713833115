.select-picker-drop-down {
    width: 100%;

    .dropdown.bootstrap-select {
        width: 100% !important;
        display: block;

        .btn.dropdown-toggle.btn-default {
            border: 1px solid $darkgray;
            border-radius: 4px;
            color: $black;
            background-color: $white;
            width: 100%;
            height: calc(100% - 2px);
            padding: 0 4px 0 4px;
            margin: 0;
            min-height: 21px;
        }

        .filter-option {
            border-radius: 4px;
            color: $black;
            background-color: $white;
            width: 100%;
            height: calc(100% - 2px);
            padding: 0 4px 0 4px;
            margin: 0;
            font-size: 12px;

        }

        .bs-searchbox {
            padding-left: 5px;
            padding-right: 5px;

            input {
                height: 32px;
            }
        }

        &.invalid {

            .btn.dropdown-toggle,
            .filter-option,
            .filter-option-inner-inner,
            .caret {
                background-color: $lightred !important;
            }
        }
    }
}