﻿@import '../../shared/sass/_variables.scss';
@import '../../directives/multiselectionDropDown/multiselectionDropDown.scss';

.multiselectiontable-drop-down {
    width: 200px;

    .titleBox {
        text-align: left;
        letter-spacing: 0px;
        color: $trimble_brand_grey;
        opacity: 1;

        &.multiSelectDisabled {
            background: $trimble_grey;
            background: #E5E5E5 0% 0% no-repeat padding-box;
            border: 0.5px solid #707070;
            opacity: 1;
        }

        .topmargin {
            margin-top: 0px;
        }
    }

    .checkboxes {
        .checkboxesList {
            height: 25px;
            width: auto;
        }

        .background-hover {
            background: #F3F3F7 0% 0% no-repeat padding-box;
        }

        .col-hearder-text {
            color: $trimble_grey;
            font: normal normal 600 13px/17px "Myriad Pro", "Verdana";
            letter-spacing: 0px;
            opacity: 1;
            text-transform: uppercase;
        }

        .col-text {
            text-align: left;
            font: normal normal normal 13px/17px "Myriad Pro", "Verdana";
            letter-spacing: 0px;
            color: $trimble_grey;
            opacity: 1;
        }

        .col-header {
            width: 35.333333%;
        }

        .col-header-id {
            width: 18.8%;
        }

        .grey-background {
            background-color: #d1d1d1;
        }
    }
}
