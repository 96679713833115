.map-wrapper {
    &.prints {
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        min-width: 3000px;
        min-height: 3000px;
        overflow: hidden;
        -webkit-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        transform: scale(1) translateY(-50%) translateX(-50%);
        -webkit-transform: scale(1) translateY(-50%) translateX(-50%);
        -moz-transform: scale(1) translateY(-50%) translateX(-50%);
        -ms-transform: scale(1) translateY(-50%) translateX(-50%);
        -o-transform: scale(1) translateY(-50%) translateX(-50%);
    }

    &.prints-high-quality {
        min-width: 4500px !important;
        min-height: 4000px !important;
    }

    &.prints-low-quality {
        min-width: 2000px !important;
        min-height: 2000px !important;
    }
}

.print-preview {
    .print-scroll-wrapper {
        height: 100%;
        overflow: hidden;
    }

    .print-scroll-content {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }

    .content {
        height: calc(100% - 146px);
    }

    &.harvest-area-mode {
        max-height: 548px !important;
        height: 100%;
    }

    .settings-row {
        margin: 0px;
        padding: 0px;
        line-height: 50px !important;
        vertical-align: central;
        margin-bottom: auto;
        margin-top: auto;
    }

    .settings-row-last {
        padding-bottom: 50px;
    }

    .favorite-radio-inline {
        position: relative;
        display: inline-block;
        padding-left: 8px;
        margin-top: 6px;
        margin-bottom: 4px;
        margin-right: 5px;
        font-weight: 400;
        vertical-align: middle;
        cursor: pointer;
    }

    .saved-lists-dropdown {
        margin-top: 0px;
        padding-top: 8px;
        max-width: 300px;
        min-width: 250px;
        top: 40px;
        padding-bottom: 0px;
        margin-bottom: 0px;

        > li > a {
            position: relative;
            white-space: initial;
            cursor: initial;
        }

        .favoritelist-name {
            width: calc(100% - 40px);
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            vertical-align: middle;
            margin-top: 0px;
            position: absolute;
            font-style: normal;
            height: 34px;
            padding-top: 6px;
            vertical-align: middle;
        }

        .favoritelist-remove {
            position: absolute;
            right: 0;
            top: -1px;
            bottom: 0;
            margin: auto;
            margin-right: 5px;
            height: 17px;
            font-size: 12px;
            color: inherit;
            width: 15px;
            cursor: pointer;
            background-image: url(/content/images/svg/map/TrashCan.svg);
            background-repeat: no-repeat;

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/map/TrashCan_MouseOver.svg);
            }
        }

        .favoritelist-warning {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            margin-right: 25px;
            height: 16px;
            font-size: 12px;
            color: inherit;
            width: 18px;
            cursor: pointer;
            background-image: url(/content/images/svg/map/GearWheel_Black.svg);
            background-repeat: no-repeat;

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/map/GearWheel_Blue.svg);
            }
        }

        .favoritelist-savechanged {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            margin-right: 25px;
            height: 16px;
            font-size: 12px;
            color: inherit;
            width: 18px;
            cursor: pointer;
            background-image: url(/content/images/svg/map/Diskette_Black.svg);
            background-repeat: no-repeat;

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/map/Diskette_Blue.svg);
            }
        }

        &.site-expanded {
            left: -22px; /* adjust favorites toolbar to fit to the viewport */
        }
    }

    .favorites-close-button {
        margin-top: 8px;
        margin-right: 1px;
    }

    .favorite-empty {
        margin-left: 0px;
        margin-top: -38px;
        padding-bottom: 50px;
    }

    .dropdown-emptytext {
        text-transform: uppercase;
        font-size: 0.8em;
        margin: 0.5em;
        margin-top: 0px;
        margin-left: 1.7em;
        font-weight: normal;
        opacity: 0.4;
        text-align: center;
    }

    .favorites-guide {
        font-size: small;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        margin-top: -1px;
        line-height: 18px;
    }

    .favorites-header-text {
        width: 260px;
    }

    .favorites-header {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: -7px;
        padding-top: 0px;
        top: 0px;
        font-style: normal;
        line-height: 2.39em;
    }

    .popup-footer-wrapper {
        position: absolute;
        bottom: 0px;
        width: 100%;
        border-top: 1px solid #616161;
        font-style: normal;
    }

    .popup-footer {
    }

    .favorite-list-button {
        margin: 0 auto;
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        display: block;
        width: 100%;
    }

    .layer-favorites-button {
        background-color: lightgray;
        padding-bottom: 8px;
        padding-top: 8px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 7px;
        padding-right: 7px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .dropdown-menu {
        left: 66px;
        width: 300px;
        top: -48px;
        border-color: #616161 !important;
        min-height: 195px;
        overflow: hidden;
        border-radius: 7px;
    }

    .dropdown-menu > li > a {
        padding-left: 5px !important;
    }

    .custom-drop-down-arow {
        margin-left: 27px;
        margin-right: 0px;
        padding-right: 0px;
        transform: rotate(-90deg) translateY(1px);
    }

    .custom-drop-down-arow2 {
        transform: rotate(90deg) translateY(1px) !important;
    }

    .favorite-button-border {
        position: absolute;
        margin-top: -1px;
        margin-left: 21px;
        border-left: solid 1px gray;
        height: 31px;
        width: 1px;
    }

    .settings-wrapper {
        background-color: white !important;
        border: 1px solid gray !important;
        width: 55px;
        height: 32px;
        padding: 0px;
        margin: 0px;
        line-height: 30px;
    }

    .settings-button {
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        height: 32px;
        width: 55px;
        margin: 0px;
        padding: 0px;
        background-image: url(/content/images/svg/favorite.svg);
        background-size: 31px 19px;
        background-position: left;

        &:hover,
        &:focus,
        &:active {
            background-image: url(/content/images/svg/favorite-green.svg);
        }
    }

    .settings-button-active {
        background-image: url(/content/images/svg/favorite-green.svg) !important;
    }

    .icon {
        height: 17px;
        width: 18px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    .icon-favorite {
        background-image: url(/content/images/svg/favorite.svg);

        &:hover,
        &:focus {
            background-image: url(/content/images/svg/favorite-green.svg);
        }
    }

    .icon-button {
        //Override bootstrap's styles on icon-buttons
        > .btn {
            &:hover,
            &:focus {
                background-color: initial;

                .icon-settings {
                    background-image: url(/content/images/svg/settings-cog-green.svg);
                }
            }

            background-color: initial;
        }

        &.open {
            .icon-printer {
                background-image: url(/content/images/svg/print-green.svg);
            }

            .icon-favorite {
                background-image: url(/content/images/svg/favorite-green.svg);
            }
        }
    }

    .combo-topic-outer-wrapper {
        display: block;
    }

    .combo-topic-wrapper {
        width: 280px;
        display: flex;
        margin-top: 0px;
        align-items: stretch;
        flex-grow: 1;
    }

    .left-topic {
        float: left;
        width: 220px;
    }

    .right-topic {
        float: right;
        width: 61px;
        height: 40px;
    }

    .combo-header-wrapper {
        margin-top: 0px;
        flex-direction: column;
    }

    .left-header {
        float: left;
        width: 100px;
    }

    .favorite-info-placeholder {
        display: inline-block;
        position: relative;
        top: 7px;
        margin-right: 5px;
        width: 17px;
        height: 17px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_WhiteBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_BlackBackground.svg);
        }
    }

    .arrow-info-placeholder {
        position: relative;
        top: 0px;
        width: 20px;
        height: 18px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_WhiteBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_BlackBackground.svg);
        }
    }

    .right-header {
        margin-top: -2px;
        padding-left: 5px;
        width: 21px;
        height: 18px;
        float: right;
    }

    .control-label-second {
        margin-top: 5px;
        padding-top: 5px;
    }

    &.map-panel {
        @extend %silva-box;
        height: calc(100% - 7px);
        max-height: 670px;
        position: absolute;
        left: .2em;
        top: .2em;
        margin: 0;
        width: $sp-print-preview-control-width;
        z-index: $sp-map-toolbar-z-index;

        > .content {
            .map-panel-row {
                display: -ms-flexbox;
                display: flex;
                align-items: stretch;
                margin-top: 0;
                border-top: 1px solid #d1d1d1;

                &:first-child {
                    border-top: none;
                }
            }

            .map-panel-column {
                padding: 1em;
                border-left: 1px solid $gray;

                &:first-of-type {
                    border-left: 0;
                }
            }
        }

        .control-label {
            margin-top: 0px;
        }

        .in-use-row {
            margin-top: 10px;
            margin-left: 14px;
            margin-bottom: 3px;
            font-size: 14px;
            max-width: 230px;
            text-overflow: ellipsis;
            height: 21px;
            white-space: nowrap;
            overflow: hidden;
        }

        .printpanel-savechanged {
            position: absolute;
            right: 0;
            top: 106px;
            left: 26px;
            margin: auto;
            margin-right: 14px;
            height: 16px;
            font-size: 12px;
            color: inherit;
            width: 18px;
            cursor: pointer;
            background-image: url(/content/images/svg/map/Diskette_Black.svg);
            background-repeat: no-repeat;

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/map/Diskette_Blue.svg);
            }
        }

        .second-control-label {
            margin-top: 0px;
            padding-top: 45px;
        }

        .map-panel-footer {
            border-top: 1px solid $darkgray !important;
            background: $gray;
            overflow: hidden;
            justify-content: center;
            padding: .5em;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;

            .btn {
                padding: 6px 0 6px 0;
            }
        }
    }

    .checkbox-inline,
    .radio-inline {
        position: relative;
        display: inline-block;
        padding-left: .7em;
        margin-bottom: 0;
        font-weight: 400;
        vertical-align: middle;
        cursor: pointer;
    }

    .close-button {
        margin-top: 2px;
    }

    .checkbox-group {
        label {
            display: block;
            margin: 0;
        }

        [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
            top: 0;
        }

        [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
            top: 0px;
        }
    }

    &.header-wrapper {
        vertical-align: middle;
        padding: .5em;
        align-content: center;
        border-bottom: 2px solid $gray;
    }

    &.guide {
        vertical-align: middle;
        padding: 0px;
        align-content: center;
        padding: 1.0em;
        background-color: $gray;
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
    }

    &.set-defaults-arrow {
        border: 1px solid black;
        padding: .3em;
        border-radius: .3em;
    }

    &.opacity-slider-wrapper {
        position: relative;
        //override default slider styling:
        > .slider {
            width: 160px;
        }

        .slider-selection {
            background: linear-gradient(to right, white, gray);
        }

        .slider-track {
            height: 25px !important; //override the default height in bootstrap-slider
        }

        .slider-handle.custom {
            &:before {
                content: none;
            }

            background: no-repeat url('/content/images/svg/map/SliderHandle.svg');
            background-size: contain;
            height: 30px;
            margin-left: -5px; //adjust the handler position to the end of the slider
        }

        .tooltip-inner {
            background: $blue;
            color: white;
        }

        .tooltip-arrow {
            border-top-color: $blue;
        }
    }

    &.opacity-input {
        margin-top: 5px;
        position: absolute;
    }

    &.arrow-open-panel {
        position: absolute;
        right: .2em;
        top: .3em;
    }
}