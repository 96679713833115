.payments-and-receiver-tab-grid {
    input[type="date"]:after {
        padding-right: 20px;
    }

    .bankAccount-input-group .wrapper {
        padding: 0px;
    }

    .payment-receivers-wrapper {
        margin-bottom: 15px;
    }

    .manage-payments {
        margin-left: 15px;
    }

    .width-100 {
        width: 100%;
    }

    .button-container-grid {
        padding: 10px;
        background-color: #e8e8e8;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .payment-receiver-input {
        width: 100%;
        height: 25px;
        color: black;
        border-radius: 4px;
        border-width: 1px;
    }

    @media all and (min-width: 1200px) {
        .min-width-211 {
            min-width: 195px;
        }
    }

    .withholding-percent {
        margin-left: 0px;
        width: 100%;
    }

    .puukauppa-subtitle-padding {
        padding-top: 7px;
        padding-bottom: 10px;
        padding-left: 13px;
        padding-right: 10px;
    }

    .form-group {
        label {
            &.min-width-120 {
                min-width: 120px;
            }
        }
    }

    .radio-button-input {
        height: 15px;
        margin-bottom: 5px;
        margin-top: 0px;
        box-shadow: 0 0 0 0;
    }

    .no-padding-right {
        padding-right: 0px;
    }

    .no-padding-left {
        padding-left: 0px;
    }

    .padding-right-4px {
        padding-right: 4px;
    }

    .persons-remove-column {
        margin: -2px auto;
        margin-left: 5px;
        background-position: unset;
    }

    .persons-remove-header {
        margin-top: 0px;
    }

    .min-width-110 {
        min-width: 89px;
    }

    .customers-grid {
        height: 75px !important;

        & .ag-body-viewport {
            overflow-y: hidden !important;
        }
    }

    .add-payment-receivers {
        margin-left: 15px;
        margin-right: 15px;
        width: 100%;
    }

    #PaymentDoneForPersonInput {
        width: 15px;
        margin-left: 30px;
        margin-right: 10px;
    }

    #PaymentDoneForCustomerInput {
        width: 15px;
        margin-left: -27px;
        margin-right: 10px;
    }
    
    #PaymentPersonsLabel {
        padding: 0px;
    }

    #ConfirmUpdatePersonsInformation {
        margin-right: 15px !important;
        width: 100% !important;
    }

    #registrationDateInputContainer,
    #withholdingInputContainer,
    #advancePaymentPercentageContainer {
        float: right;
        padding-right: 13px;
    }

    .grid-edit-modal {
        width: calc(100% - 3.2%);
        margin-left: -0.8%;

        .invalid-percent {
            background-color: #FFD8D8 !important;
            color: black !important;
        }

        .border-bottom {
            padding: 15px;
            border-bottom: 0;
        }

        .no-border-right {
            border-right: 0;
        }

        .tooltip {
            margin-top: 0px !important;
        }

        .header-row {
            border-radius: 3px 3px 0px 0px;
            background: $grid-header-background;
            background-color: $trimble_white;

            .the-header {
                width: 100%;
                border: 1px solid #808080;
                padding-left: 0.5em;
                padding-right: 0;

                &.second-header {
                    border-radius: 0;
                    margin-top: 8px;
                    border-top: $grid-border;
                }

                &.group-header {
                    border-bottom: 0;
                    border-radius: 3px 3px 0px 0px;

                    .no-right-border {
                        border-right: none;
                    }
                }

                .header-column {
                    border-right: $grid-border;

                    &.float-left {
                        float: left;
                    }

                    &.height-25 {
                        height: 25px;
                    }

                    &.width-2 {
                        width: 2%;
                    }

                    &.width-3 {
                        width: 3%;
                    }

                    &.width-4 {
                        width: 4%;
                    }

                    &.width-5 {
                        width: 5%;
                    }

                    &.width-6 {
                        width: 6%;
                    }

                    &.width-7 {
                        width: 7%;
                    }

                    &.width-8 {
                        width: 8%;
                    }

                    &.width-10 {
                        width: 10%;
                    }

                    &.width-12 {
                        width: 12%;
                    }

                    &.width-14 {
                        width: 14%;
                    }

                    &.width-17 {
                        width: 17%;
                    }

                    &.width-21 {
                        width: 21%;
                    }

                    &.width-24 {
                        width: 24%;
                    }

                    &.width-39 {
                        width: 39%;
                    }

                    &.measurement-header {
                        border-bottom: $grid-border;
                        text-align: center;
                    }
                }
            }
        }

        .body-row {
            height: 39px;

            .second-body {
                width: 100%;
                border-radius: 0px 0px 3px 3px;
            }

            .simpleDropDown {
                border: 1px solid $field-border-color;
                border-radius: 2px;
            }

            .the-body {
                padding-left: 0.5em;
                padding-right: 0;
                border-bottom: $grid-border;
                border-left: $grid-border;
                border-right: $grid-border;
            }

            .body-column {
                border-right: 1px solid $gray;
                padding-bottom: 3.5px;
                padding-top: 2.5px;

                .simpleDropDown {
                    border: 1px solid $darkgray;
                    border-radius: 4px;
                    font-size: 14px;
                    height: 27px;
                    padding: 3px 6px;
                }

                .form-control {
                    color: $trimble_black;
                    border: 1px solid $darkgray;
                    border-radius: 4px;
                    font-size: 14px;
                    height: 27px;
                    padding: 3px 6px;
                }

                &.width-2 {
                    width: 2%;
                }

                &.width-3 {
                    width: 3%;
                }

                &.width-4 {
                    width: 4%;
                }

                &.width-5 {
                    width: 5%;
                }

                &.width-6 {
                    width: 6%;
                }

                &.width-7 {
                    width: 7%;
                }

                &.width-8 {
                    width: 8%;
                }

                &.width-10 {
                    width: 10%;
                }

                &.width-12 {
                    width: 12%;
                }

                &.width-17 {
                    width: 17%;
                }

                &.width-21 {
                    width: 21%;
                }

                &.width-14 {
                    width: 14%;
                }

                &.width-39 {
                    width: 39%;
                }
            }
        }
    }
}
