@import '../../../../shared/sass/_variables.scss';

.grey-background {
    background-color: $lightgray ;
}

.group-date {
    input {
        background-color: $gray !important;
        color: $black !important;
    }
}

.tab-content {
    .toggle-show-latest {
        width: 70%;
        padding-top: 20px;
        padding-bottom: 20px;

        .material-switch-container .material-switch-content > input[type="checkbox"]:checked + label::after {
            top: 3px !important;
        }

        .material-switch-container .material-switch-content > input[type="checkbox"]:not(:checked) + label::after {
            top: 3px !important;
        }

        .padl {
            padding-left: 11px;
            margin-top: -0.5em;
        }
    }

    .toggle-status {
        margin-left: 12em;
        display: flex;
        margin-top: -1.55em;
    }

    .padding-left-10 {
        padding-left: 10px;
    }
}

.harvesting-area-measurement {
    .sp-grid-container {
        margin: 0;
        background-color: none;

        .lowercase {
            .ag-header-cell-label,
            .ag-header-group-cell-label {
                text-transform: lowercase !important;
            }
        }

        .ag-header-group-cell-label {
            text-align: left;
            text-transform: uppercase;
        }

        .display-flex {
            display: flex;
        }

        .display-contents {
            display: contents;
        }

        .info2-padding {
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 83%;
        }

        .pen-icon {
            margin-left: 3px;
        }
    }

    .harvester-file .ag-cell {
        [type="checkbox"]:checked + label:before,
        [type="checkbox"]:not(:checked) + label:before {
            box-shadow: none;
            margin-left: -30px;
        }

        [type="checkbox"]:checked + label:after {
            margin-left: -30px;
        }
    }

    .measurement-border {
        margin-bottom: 20px;
    }

    .manual-measurement-border {
        margin-top: 20px;
    }

    .hr-border-style {
        border-bottom: 0.75px solid $half_trimble_brand_grey;
        width: 101.55%;
        margin-left: -14px;
        margin-top: 0px;
    }

    .custom-chekcbox {
        margin-top: -7px;
    }

    .center-checkbox {
        margin-left: 30px;
    }

    .notes-icon {
        width: 21px;
        height: 21px;
    }
    //TODO: this is mostly duplicated and can be refactored
    .measurement-grid {
        height: 300px;

        .ag-cell {
            border-bottom: 1px solid #d1d1d1;
        }

        .master-header {
            text-transform: uppercase;
        }

        .center-title {
            text-align: center;
        }

        .align-right { // for all cells
            text-align: right;
        }

        .ag-row-group {
            padding: 0;
        }

        .ag-row .grouping-row { // crappy alingment for grouping column
            span {
                display: inline-block;
                line-height: 1em;
                padding: 2px 3px;
            }

            .count {
                width: 40px;
            }

            .y-pvm {
                width: 85px;
                margin-left: 96px;
            }

            .maara {
                width: 60px;
                margin-left: 349px;
            }

            .netto {
                width: 60px;
                margin-left: 89px;
            }

            .brutto {
                width: 70px;
                margin-left: 40px;
            }

            .luettu {
                width: 85px;
                margin-left: 91px;
            }
        }

        .select-all-wrapper {
            top: -5px;
        }

        .ag-row.ag-row-selected { // some trickery to get the selected row stay black
            .ag-cell {
                background-color: $black !important;
            }
        }

        .ag-floating-bottom-container {
            padding-left: 5px;
            .ag-row {
                background-color: $gray !important;
            }

            .ag-cell {
                background-color: transparent !important; // override disabled-cell color
                border-right: none;
                //  border-top:1px solid black;
                color: black !important;
            }

            .ag-cell:first-child {
                padding-left: 0.5em;
                width: 200px !important; // for the first cell with "total rows"
            }
        }
    }
    // endof measurement-grid
}
