﻿@import '../../../../../shared/sass/_variables.scss';
@import "app/components/customers/components/gridModalWindow";

.add-billing-info-modal {
    .modal-dialog {
        width: 350px;
    }

    .div-textarea {
        height: 250px;
        padding: 15px;
    }

    .bill-info-input {
        height: 100%;
        margin: 0;
    }

    .title-bar {
        font-family: $font-semibold;
    }
}

.product-row-modal {
    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0px;
        height: 100%;

        .modal-content {
            position: absolute;
            top: 20px;
            min-width: 900px;
        }
    }
}

.forest-management-product {

    input[type=date]::-webkit-clear-button {
        display: none; /* Hide the button */
        -webkit-appearance: none; /* turn off default browser styling */
    }

    input[type=date]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
    }

    .edit-product-front {
        z-index: 1050;
    }

    .edit-product-behind {
        z-index: 1;
    }

    .modal-backdrop {
        z-index: 1029;
        opacity: 0.5
    }

    .padded-cell {
        padding-left: 35px;
    }

    .edit-product-div {
        position: fixed;
        top: 50%;
        z-index: 1030;
        background-color: white;
        border: 1px solid black;
        margin-left: -0.85em;
        transform: translate(0%, -50%);
        max-height: calc(100% - 100px);
        width: 96.8%;

        @media (max-width: 1000px) {
            margin-left: -8em;
        }

        @media screen and (min-width: 2000px) {
            width: 97.1%;

            .product-body-row .product-column select.small-dropdown {
                width: 81%;

                &.width-37 {
                    width: 38%;
                }
            }
        }

        @media screen and (min-width: 2400px) {
            width: 97.4%;

            .product-body-row .product-column select.small-dropdown {
                width: 84%;

                &.width-37 {
                    width: 39%;
                }
            }
        }

        @media screen and (min-width: 2536px) {
            width: 97.6%;

            .product-body-row .product-column select.small-dropdown {
                width: 85%;

                &.width-37 {
                    width: 39%;
                }
            }
        }

        @media screen and (min-width: 2853px) {
            width: 97.9%;

            .product-body-row .product-column select.small-dropdown {
                width: 86%;

                &.width-37 {
                    width: 40%;
                }
            }
        }

        .work-site-view .custom-drop-down button {
            text-align: left;
            padding-left: 4px;
        }

        .product-body-row .product-column select.sovittu-dropdown {
            padding-bottom: 0px;
            padding-right: 0;
            padding-top: 0px !important;
            width: 30%;
            display: inline;
        }

        .date-drop-down {
            padding-top: 4px;
            padding-left: 4px;
        }

        .impl-date {
            width: 67% !important;
        }

        .button {
            width: 1.5em;
            height: 1.5em;
            display: inline-block;
            background-position: center bottom;
            background-size: contain;
            background-repeat: no-repeat;
            vertical-align: middle;
        }

        ul.buttons, li {
            display: inline-block;
        }

        ul.buttons {
            padding: 0;
            text-align: center;
            padding-left: 500px;
            margin-top: 10px;
            margin-bottom: 5px;
        }

        %disabledNavigation {
            opacity: 0.4;
            pointer-events: none;
        }

        &.disabled {
            li {
                @extend %disabledNavigation;
            }
        }

        li.disabled {
            @extend %disabledNavigation;
        }

        .button-back {
            background-image: url(../content/images/svg/Step-backward.svg);
        }

        .button-next {
            background-image: url(../content/images/svg/Step-forward.svg);
        }

        &:not(.disabled) {
            .button-back:not(.disabled):hover, .button-back:active {
                background-image: url(../content/images/svg/Step-backward-MouseOver.svg);
                cursor: pointer;
            }

            .button-next:not(.disabled):hover, .button-next:active {
                background-image: url(../content/images/svg/Step-forward-MouseOver.svg);
                cursor: pointer;
            }
        }

        .title-bar {
            > div:not(.clearfix) {
                padding: 1em;
            }

            .header {
                font-family: $font-semibold;
                margin: 0;
                text-transform: uppercase;
                width: auto;
                display: inline;
                z-index: 0;
                height: 3em;
            }

            .glyphicon-remove:hover {
                color: $blue;
                cursor: pointer;
            }
        }

        .date-format {
            border: none !important;
            background-color: inherit;
        }

        .border-bottom {
            border-bottom: 1px solid $darkgray;
        }

        .modal-step {
            background-color: $blue;
            color: white;
            border-radius: 0px 5px 5px 0px;
            padding: 5px 15px 5px 5px;
            box-sizing: border-box;
            display: inline-block;
            font-family: "Myriad Pro", Verdana;
            font-size: 14px;
            height: 23px;
            line-height: 18px;
            list-style-type: none;
            padding-right: 7px;
            padding-top: 3px;
            text-align: right;
            width: 34px;
            margin-top: 2px;
        }

        .product-header-row {
            background: #d3d3d3;
            font-family: $font-semibold;
            text-transform: uppercase;
            width: 100%;
        }

        .product-header-row > div:not(:last-child) {
            height: 31px;
            border-right: 1px solid white;
            vertical-align: middle;

            .kmr-column {
                margin-left: 3px;
            }
        }

        .product-header-row div label {
            padding-top: 6px;
        }

        .product-row {
            height: 31px;
        }

        .product-body-row > div:not(:last-child) {
            border-right: 1px solid $gray;
        }

        .product-body-row > div {
            height: 27px;
            vertical-align: middle;
            padding-top: 2px;

            .kmr-column {
                padding-top: 0px;
            }

            label, .bill-info-div {
                padding-top: 5px;
            }

            .margin-align {
                margin-right: 3px;
            }

            select {
                &.simpleDropDown {
                    padding-top: 0px !important;
                    line-height: 27px;
                } 

                &.small-dropdown {
                    width: 80%;
                    margin-right: 3px;

                    &.width-37 {
                        width: 37%;
                    }
                }
            }

            input {
                padding-top: 2px;
                height: 27px !important;
                line-height: 27px;
                width: calc(100% - 3px);
                border-radius: 4px;

                &.small-dropdown {
                    width: 80%;
                    margin-right: 3px;

                    &.width-37 {
                        width: 37%;
                    }
                }
            }

            .special-input {
                width: 96%;
            }
        }

        .truncate-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .product-column-header {
            display: inline-block;
            border-right: 1px solid $white;
            height: 100%;
        }

        .product-column {
            display: inline-block;

            .invoice-info {
                color: white;
                height: 26px;
            }

            .notes-icon {
                vertical-align: middle;
                width: calc(100% - 3px);
            }
        }

        .delimiter-gray {
            border-right: 1px solid #d1d1d1;
        }

        .delimiter-white {
            border-right: 1px solid #ffffff;
        }

        .limit-text {
            width: 99%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .width-8 {
            width: 8%;
        }

        .width-9 {
            width: 9%;
        }

        .width-10 {
            width: 10%;
        }

        .width-11 {
            width: 11%;
        }

        .width-12 {
            width: 12%;
        }

        .width-12p5 {
            width: 12.5%;
        }

        .width-13 {
            width: 13%;
        }

        .width-13p3 {
            width: 13.3%;
        }

        .width-14 {
            width: 14%;
        }

        .width-15 {
            width: 15%;
        }

        .width-20 {
            width: 20%;
        }

        .width-20p5 {
            width: 20.5%;
        }

        .width-21 {
            width: 21%;
        }

        .width-21p5 {
            width: 21.5%;
        }

        .width-22 {
            width: 22%;
        }

        .width-34 {
            width: 34%;
        }

        .width-35 {
            width: 35%;
        }

        .width-48 {
            width: 48%;
        }

        .width-49 {
            width: 49%;
        }

        .width-50 {
            width: 50%;
        }

        .width-100 {
            width: 100%;
        }

        .width-10-extra {
            width: 10.3%;
        }

        .width-10-extra-krm {
            width: 10.6%;
        }

        .width-10-extra-krm-sp {
            width: 10.01%;
        }

        .width-13-extra {
            width: 13.1%;
        }

        .width-14-extra {
            width: 14.9%;
        }

        .width-19-extra {
            width: 19.2%;
        }

        ;

        .width-23-extra {
            width: 23.12%;
        }

        .width-23-extra-krm {
            width: 23.1%;
        }

        .width-37-extra {
            width: 37.3%;
        }

        .first-column-kmr {
            padding-left: 33px;
        }

        .info-font {
            font-weight: 400;
            font-size: 14px;
            color: $trimble_black;
        }

        .dropDownMinWidth550px {

            .dropdown-menu {
                width: 650px !important;
            }

            .btn {
                height: 27px;
                padding-top: 3px;
            }
        }

        .reproduction-material-div {
            padding-left: inherit !important;

            .dropdown-menu {
                width: 408px !important;
            }

            .first--from-two-columns {
                width: 30% !important;
            }

            .second--from-two-columns {
                width: 60% !important;
            }

            .custom-button {
                padding-left: 5px;
            }
        }

        .input-edit {
            height: 25px;
            width: calc(100% - 3px);
            border-radius: 4px;
            border: 1px solid #616161;
            padding-top: 4px !important;
            padding-left: 4px;
        }

        .no-padding-top {
            padding-top: 0px;
        }

        .alv-validation {
            .field-validation-tooltip {
                top: 75px !important;
            }
        }

        .icon-remove-product {
            height: 28px;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url("../content/images/svg/TrashCan.svg");
            padding: 10px;

            &:hover {
                background-image: url("../content/images/svg/TrashCan_MouseOver.svg");
            }
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .no-right-border {
            border-right: none !important;
        }
    }

    .grid-container-forest-management {
        border: $grid-border;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background: $grid-header-background;
    }

    .margin-5 {
        margin: 5px;
    }

    .margin-left-5 {
        margin-left: 5px;
    }

    .margin-left-3 {
        margin-left: 3px;
    }

    .product-tabel {
        background-color: #d3d3d3;
    }

    .table-cell {
        padding-top: 4px !important;

        .id-column {
            width: 10%;
            padding-left: 6px;
        }
    }

    #firstGrid {
        margin: 0px;

        .close-button {
            padding: 10px
        }
    }

    #secondGrid {
        margin-top: 10px;
    }

    #showRetimeOptions {
        margin: 0px;
        border-top: $grid-border;
    }

    .inner-options-grid {
        margin: 0px -5px 0px -10px;
    }

    .btn-change-date-plus {
        width: 20%;
    }

    .btn-change-date-minus {
        width: 20%;
    }

    #status-1 {
        margin-bottom: 5px;
        margin-top: 5px;
        margin-right: -5px;
        width: 225px;
    }

    #status-2 {
        margin-bottom: 5px;
        margin-top: 5px;
        width: 216px;
    }

    #leftGroup {
        border-right: 1px solid grey;
    }

    .upper-left-btns-group {
        margin-top: 5px;
    }

    .upper-right-btns-group {
        margin-top: 5px;
        margin-right: -20%;
    }

    rightGroup {
        border-left: 1px solid grey;
    }

    .simpleDropDown {
        width: calc(100% - 3px);
    }

    select:disabled {
        background-color: #eee;
        color: black;
    }

    input:disabled {
        background-color: #eee;
        color: black;
    }
}
