﻿.unit-rate-adjustment-modal {

    .modal-dialog {
        top: 25%;
    }

    .margin-15 {
        margin: 15px;
    }

    .pt-10 {
        padding-top: 5px;
    }
}
