/**
* Contains shared styles for the control panels
*/

.map-panel {
	&.panel {//the container panel
		@extend %silva-box;
		position: absolute;
		margin: .2em;
		width: $sp-map-panel-width;
		z-index: $sp-map-toolbar-z-index;
	}

	&.header-wrapper {
		vertical-align: middle;
		padding: 1em;
		border-bottom: 2px solid $gray;
		>span {
			margin-left: .5em;
		}
	}

	&.content {
		margin: .4em;
		position: relative;
	}

	&.close-button {
		margin: .4em;
	}

}

// The zoom buttons have absolute positioning, so we need to
// move the out of the way when any of the panels is open
.map-wrapper {
    //List here any of the "tool classes" that show a panel
    &.layers, &.themeMaps, &.prints, &.measure, &.drawEditor, &.workingSitesMarking, &.legend, &.pickMulti, &.workingSitesDrawEditor, &.gpsRoutes {
        .ol-zoom.ol-unselectable.ol-control {
            left: $sp-map-panel-width + 10px;
        }
    }
}