﻿@import '../../../shared/sass/_variables.scss';

.forestProperty {
    .list-view-settings-row {
        .advanced-filtering-options .advanced-filtering-dropdown .dropdown-content {
            width: 759px;

            .dropdown-table .table-cell .multiselection-drop-down .checkboxes {
                width: 288px;
            }
        }
    }

    .object-align-vertical {
        vertical-align: middle
    }

    margin-bottom: 0.5em;

    .button-width {
        width: 100%;
    }


    .forestProperty-list-content {
        overflow: hidden;
        margin-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        .custom-filter {
            margin-left: 5px !important;
            margin-right: 5px !important;
            margin-bottom: 5px !important;
        }

        .forestProperty-list-grid {
            height: 50vh;
        }

        .grid-border {
            border-left: 1px solid #616161;
            border-right: 1px solid #616161;
            border-top: 1px solid #616161;
            border-top-right-radius: 0.5em;
            border-top-left-radius: 0.5em;
        }

        .select-all-wrapper {
            left: 7px;
            top: -6px;
            position: absolute;
        }
    }

    .forestProperty-list-footer {
        .customers-tablefoot-actionbar {
            border-top: 1px solid $trimble_grey;
        }
    }

    .refine-search-dropdown-directive {
        height: 129px;

        .col-no-padding.col-padding-right-5 {
            height: 40% !important;
        }

        .col-padding-right-5.border-bottom {
            height: 56% !important;
        }

        .settings-item.multiselect-margins-refine-search {
            margin-top: 8px !important;
        }

        .clearfix {
            height: inherit;

            .flex {
                &.row-no-margin {
                    height: 127px;
                }
            }

            .col-no-padding-left.col-no-padding-right {
                height: 133px;
            }
        }
    }
}