.timber-trade-accordion {
    .authorizedToSendKaato-label {
        float: right;
        width: 96%;
        padding-top: 4px;
    }

    .authorizedToSendKaato-input {
        float: left;
    }

    .align-mlainls {
        padding-right: 0px;
        bottom: 9px;
    }

    .mLainLS-label {
        float: right;
        width: 91%;
        padding-top: 4px;
    }

    .mLainLS-input {
        float: left;
        width: 9%;
    }

    @media screen and (max-width: 1600px) {
        .mLainLS-label {
            width: 82%;
            padding-top: 4px;
        }

        .mLainLS-input {
            width: 15%;
        }

    }

    .tarjousten-text-format {
        text-align: right;
        padding-right: 0px;
        right: 20px;
    }

    .tarjousten-format {
        border-radius: 4px;
        width: 130px;
        font-size: 12px;
        margin-left: 6px;
    }

    .align-withholding-from-net {
        padding-right: 0px;
        bottom: 4px;
    }

    .withholding-from-net-label {
        float: right;
        width: 91%;
        padding-top: 4px;
    }
}

.work-site-view {
    .purchase-date {
        width: 97%;

        @media screen and (max-width: 1600px) {
            width: 95%;
        }
    }
}
