@import '../../shared/sass/_variables.scss';

.admin-first-page {
    $font-family: "Myriad Pro", Verdana;
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: $font-primary;
    font-size: 12px;

    .partial {
        padding: 0;
        border-radius: 0.5em;
        display: flex;


        > div {
            width: 100% !important;
        }
        /* IMPORTANT TO NOTICE: Partials take up all of the width assigned to them so be careful of the context you use them in */
        width: 100% !important;

        :first-child {
            margin-left: 0;
        }

        :last-child {
            margin-right: 0;
        }
        /* partial background and text color combinations */
        .blue {
            background-color: $blue;
            color: $white;
        }

        .lime {
            background-color: $trimble_tf_product_green;
            color: $white;
        }
        /* link block */
        .link-block {

            .inner {
                box-shadow: 0 0 3px $half_trimble_brand_grey, 0 0 3px $half_trimble_brand_grey;
                height: 250px;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                background-color: $trimble_white;
            }

            .large-nav-button {
                cursor: pointer;
                background-color: $trimble_white;
                padding-right: 0px !important;
                padding-left: 0px !important;


                &:hover {
                    background-color: $trimble_white;
                    color: $trimble_black;

                    .topic {
                        color: $trimble_tf_product_green;
                    }

                    .icon {
                        border-color: $trimble_grey_light;

                        &-organizationInformation {
                            background-image: url("/content/images/svg/ADMINButton_1_OrganizationInformation_MouseOver.svg");
                        }

                        &-userAccounts {
                            background-image: url("/content/images/svg/ADMINButton_2_UserAccounts_MouseOver.svg");
                        }

                        &-generalInformation {
                            background-image: url("/content/images/svg/ADMINButton_3_GeneralInformationAndRegisters_MouseOver.svg");
                        }

                        &-adminSettings {
                            background-image: url("/content/images/svg/ADMINButton_4_AdminSettings_MouseOver.svg");
                        }

                        &-harvestingServices {
                            background-image: url("/content/images/svg/ADMINButton_5_HarvestingServices_MouseOver.svg");
                        }

                        &-userMembership {
                            background-image: url("/content/images/svg/ADMINButton_6_Membership_MouseOver.svg");
                        }

                        &-cubicAdmin {
                            background-image: url("/content/images/svg/ADMINButton_7_Kuutio_MouseOver.svg");
                        }

                        &-vismaSign {
                            background-image: url("/content/images/svg/ADMINButtons_10_eSign_MouseOver.svg");
                        }

                        &-mobileApp {
                            background-image: url("/content/images/svg/ADMINButton_8_MobileApplication_MouseOver.svg");
                        }

                        &-paymentSetting {
                            background-image: url("/content/images/svg/ADMINButtons_10_ForestPayments_MouseOver.svg");
                        }

                        &-attachments {
                            background-image: url("/content/images/svg/ADMINButtons_11_Attachments_MouseOver.svg");
                        }

                        &-forestPayments {
                            background-image: url("/content/images/svg/ADMINButtons_11_ForestPayments_MouseOver.svg");
                        }

                        &-buyersCompensation {
                            background-image: url("/content/images/svg/ADMINButtons_11_BuyersCompensation_MouseOver.svg");
                        }

                        &-priceComponent {
                            background-image: url("/content/images/svg/ADMINButtons_PriceComponents_MouseOver.svg");
                        }
                    }
                }

                a:hover, a:active {
                    text-decoration: none;
                }

                .icon {
                    text-align: center;
                    border-bottom: 01px solid $trimble_grey_light;

                    div {
                        height: 50px;
                        margin: 0.5em 0;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    &-organizationInformation {
                        background-image: url("/content/images/svg/ADMINButton_1_OrganizationInformation.svg");
                    }

                    &-userAccounts {
                        background-image: url("/content/images/svg/ADMINButton_2_UserAccounts.svg");
                    }

                    &-generalInformation {
                        background-image: url("/content/images/svg/ADMINButton_3_GeneralInformationAndRegisters.svg");
                    }

                    &-adminSettings {
                        background-image: url("/content/images/svg/ADMINButton_4_AdminSettings.svg");
                    }

                    &-harvestingServices {
                        background-image: url("/content/images/svg/ADMINButton_5_HarvestingServices.svg");
                    }

                    &-userMembership {
                        background-image: url("/content/images/svg/ADMINButton_6_Membership.svg");
                    }


                    &-cubicAdmin {
                        background-image: url("/content/images/svg/ADMINButton_7_Kuutio.svg");
                    }

                    &-vismaSign {
                        background-image: url("/content/images/svg/ADMINButtons_10_eSign.svg");
                    }

                    &-mobileApp {
                        background-image: url("/content/images/svg/ADMINButton_8_MobileApplication.svg");
                    }

                    &-paymentSetting {
                        background-image: url("/content/images/svg/ADMINButtons_10_ForestPayments.svg");
                    }

                    &-attachments {
                        background-image: url("/content/images/svg/ADMINButtons_11_Attachments.svg");
                    }

                    &-forestPayments {
                        background-image: url("/content/images/svg/ADMINButtons_11_ForestPayments.svg");
                    }

                    &-buyersCompensation {
                        background-image: url("/content/images/svg/ADMINButtons_11_BuyersCompensation.svg");
                    }

                    &-priceComponent {
                        background-image: url("/content/images/svg/ADMINButtons_PriceComponents.svg");
                    }
                }

                .topic {
                    color: $trimble_black;
                    margin: 0;
                    margin-bottom: -0.5em;
                    margin-top: 2em;
                    padding: 0;
                    font-family: $font-semibold;
                    text-transform: uppercase;
                    font-size: 12px;
                }

                .last-topic {
                    margin-bottom: 0.5em;
                    margin-top: 0em;
                }

                p {
                    text-align: center;
                    padding: 0.5em;
                    padding-left: 2em;
                    padding-right: 2em;
                }
            }
        }
    }
}
