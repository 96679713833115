﻿@import '../../../shared/sass/_variables.scss';

.rate-adjustment-modal {
    .modal-grid-header-cell {
        padding-top: 2px;
    }

    .modal-grid-cell {
        padding-top: 6px !important;
    }

    .checkbox-cell {
        padding-left: 2px !important;
        padding-top: 5px !important;
    }

    .detailsGrid {
        height: 53px;
    }

    .additionalRateGrid {
        height: 185px;
        border-bottom-width: 0px !important;
    }

    .tablefoot-actionbar {
        border: 1px solid #616161;
        border-top: 0;
        border-radius: 0.5em;
        background-color: #e8e8e8;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;
    }


    .row {
        display: flex;
        margin-right: 0px;
        margin-left: 0px;
    }

    .orange {
        color: #FF8B00;
    }

    .blue {
        color: #43B5E3;
    }


    textarea.ng-invalid,
    textarea.invalid {
        background-color: $lightred !important;
        color: black !important;
    }

    .adjustment-section {
        margin-top: 10px;

        .width-half {
            width: 50%;
        }

        .semi-bold-label {
            font-family: "Myriad Pro Semibold";
        }

        .left-border {
            border-left: 1px solid #B0AFB0;
        }

        .partial {
            padding: 0;
            border-bottom: 0px;
            border-right: 0px;
            background-color: #fff;
            height: 312px;
        }

        .partial-header {
            background-color: #d1d1d1;
            text-transform: uppercase;
            font-family: "Myriad Pro Semibold";
            padding: 0.5em;
            padding-left: 1.1em;
        }

        .partial-content {
            padding: 1.6em;
        }

        .height-s {
            height: 27px;
        }

        .internal-note {
            min-height: 166px;
            resize: none;
            margin-top: 10px;
        }

        .dropDownMinWidth350px .btn-group.open .dropdown-menu {
            display: block;
            padding: 5px;
        }

        .dropDownMinWidth350px .container-row .left-panel .btn {
            height: 27px;
            padding: 3px 6px;
            border-color: #616161;
        }

        .dropDownMinWidth350px .dropdown-toggle {
            width: 27px !important;
        }

        .dropDownMinWidth350px .custom-button {
            text-align: left;
            width: calc(100% - 26px) !important;
        }

        .dropDownMinWidth350px .first-column {
            padding-left: 6px;
            width: 25%;
        }
    }

    .font-bold {
        font-weight: bold;
    }

    .p-15 {
        padding: 15px;
    }

    .p-0 {
        padding: 0px;
    }

    .pt-15 {
        padding-top: 15px;
    }

    .pt-5 {
        padding-top: 5px;
    }

    .pb-15 {
        padding-bottom: 15px;
    }

    .pb-3 {
        padding-bottom: 3px;
    }

    .pb-2 {
        padding-bottom: 2px;
    }

    .mt-5 {
        margin-top: 5px;
    }

    .pl-0 {
        padding-left: 0px;
    }

    .ml-8 {
        margin-left: 7px;
    }

    .mr-8 {
        margin-right: 7px;
    }

    .pr-0 {
        padding-right: 0px;
    }

    .border-left {
        border-left: 0.5px solid $gray;
    }

    .border-bottom {
        border-bottom: 0.5px solid $gray;
    }

    .form-control {
        padding: 5px !important;
    }

    .height-25 {
        height: 25px;
    }
}
