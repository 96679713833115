//@import './_variables.scss';
.container-full-screen {
    width: 100%;
    padding: 0;
}

.harvesting {

    &.hidden-xs {
        margin-top: 12px;
    }
    /* Disable extra bottom padding from panels */
    .panel {
        margin-bottom: .5em;
    }
    /*
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }*/
    a:active,
    a:hover,
    a:link,
    a:visited {
        text-decoration: none;
    }

    .btn-silva {
        transition: 0.1s;
    }

    .factory-icon {
        background-position: 15% center;
        height: 20px;
        width: 38px;
        background-size: 15px 15px;
        background-repeat: no-repeat;
        text-align: right;
        color: white;
        padding-right: 15%;
        padding-top: 5%;
        vertical-align: middle;
    }

    .navigation-buttons {
        .button {
            background-color: initial;

            &:hover {
                background-color: initial;
            }
        }
    }

    .button {
        background-color: $gray;
        border: none;
        color: black;
        display: inline-block;
        font-family: $font-semibold;
        font-size: 12px;
        height: 30px;
        margin-bottom: 5px;
        margin-left: 5px;
        padding: 8px 0 12px;
        text-align: center;
        text-decoration: none;
        transition: 0.1s;
        width: 140px;

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: $trimble_tf_product_green;
            color: white;
        }
    }

    .button-settings {
        transition: 0.1s;
    }

    .button-small {
        font-weight: normal;
        height: 25px;
        margin: 5px;
        padding-top: 4px;
        width: 120px;
    }

    .buttons-group-border {
        background-color: #D1D1D1;
        padding: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top: 0;
        width: 100%;
    }

    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before {
        box-shadow: none;
    }

    .form-checkbox {
        [type="checkbox"]:checked + label:before,
        [type="checkbox"]:not(:checked) + label:before {
            top: 0;
        }

        [type="checkbox"]:checked + label:after,
        [type="checkbox"]:not(:checked) + label:after {
            top: 3px;
        }
    }

    .form-divider {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $gray;
    }

    .glyphicon {
        margin-left: 5px;
    }

    .partial {
        border: 1px solid $darkgray;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .partial-header-double-height {
        height: 50px;
        margin-bottom: 5px !important;
    }

    .row-equal {
        display: inline-flex;
    }

    .row-padding {
        padding: 2px 5px;
    }

    .search {
        display: inline-block;
        height: 34px;
        line-height: 34px;
        margin: 0.5em 0.5em 0;
        padding: 0;
        vertical-align: middle;
        width: calc(100% - 1em);
    }

    .navigation-buttons {
        padding-left: 0;
        margin-bottom: 0.5em;

        .navigation-button-description {
            width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;

            &-prev {
                text-align: right;
            }

            &-next {
                text-align: left;
            }
        }
    }

    .navigation-buttons {
        padding-left: 0;

        .navigation-button-description {
            width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;

            &-prev {
                text-align: right;
            }

            &-next {
                text-align: left;
            }
        }
    }

    .sp-form-divider {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: -1.0em;
        margin-right: -1.0em;
        border-bottom: 1px solid $gray;
    }

    .panel-sp > .panel-body {
        padding: 0px !important;
    }

    .sp-form-divider-vertical {
        border-right: 1px solid $gray;
    }

    .sp-form-checkbox {
        [type="checkbox"]:checked + label:before,
        [type="checkbox"]:not(:checked) + label:before {
            top: 0;
        }

        [type="checkbox"]:checked + label:after,
        [type="checkbox"]:not(:checked) + label:after {
            top: 0;
        }
    }

    textarea.sp-form-textarea {
        height: initial !important;
        margin-top: 0;
    }
    /*
      When applies to .row, this makes bootstrap columns all same height.
      However, applying this class brakes the responsivenes of the row.
  */
    .row-eq-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .sp-form-col {
        padding: 1.0em !important;
    }

    .tab-nav {
        margin: 0;
        padding: 0;
        width: 100%;
        border-bottom: none;

        .nav-item {
            margin-left: 3px;
            margin-right: 3px;
            border-top-left-radius: 6px;
            padding: 0.2em 0 0.2em 0;
            border-top-right-radius: 6px;
            border-color: $darkgray;
            text-decoration: none;

            &:hover {
                background: black;
                color: white;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &.tab-content {
        box-sizing: border-box;
        padding: 1em;
        border-radius: .5em;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: $white;
        border: 1px solid $darkgray;
        border-top: 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        /*margin-bottom: 1em;*/
        #agText .header-wrapper {
            top: 4px;
            left: 4px;
            position: absolute;
        }
    }

    &.ledger-accounts.tab-content {
        padding: .5em;
    }

    &.consigmnent-contract .ag-row,
    &.consigmnent-contract .ag-row input {
        cursor: pointer;
    }

    &.bottom-buttons {
        overflow: hidden;
        margin-top: 30px;
        margin-bottom: 30px;

        .btn {
            padding: 1em 2em 1em 2em;
        }
    }

    .common-grid-toolbar {
        margin-bottom: 1em !important;
    }

    .sp-search-box-container {
        display: inline-block;
        width: 100%;
    }

    .common-grid-settings-row {
        display: inline-block;
        margin-bottom: 1em;
    }

    .common-grid .panel-sp-grid {
        margin-bottom: 0;
    }

    .common-grid .panel-sp-grid {
        margin-bottom: 0;
    }

    .content-row-wrapper {
        display: flex;
        flex-direction: row;

        &.delivery-location {
            height: 601px;

            .grid-column-wrapper {
                width: 70%;

                &.sidebar-hidden {
                    width: 0%;
                }
            }

            .map-column-wrapper {
                width: 30%;

                &.sidebar-hidden {
                    width: 100%;
                }
            }
        }
    }

    .map-column-wrapper {
        transition: width 200ms linear;
        flex-grow: 1;
        width: 50%;

        .sp-map.map-container {
            height: 100%;
        }
    }

    .grid-column-wrapper {
        transition: width 200ms linear;
        margin-right: 1em;
        width: 50%;
        -ms-flex-positive: 1;
        flex-grow: 1;
        /* overflow: hidden; */
        display: flex;
        flex-direction: column;

        &.sidebar-hidden {
            flex-grow: 0;
            overflow: hidden;
            width: 0%;
            margin: 0;
        }
    }
    // TODO: fix this below, do not add to global level, it breaks stuff sitewide
    .consigmnent-contract input[id*="header-checkbox"] {
        //visibility: hidden;
        .common-grid [type="checkbox"]:not(:checked) + label:after,
        .common-grid [type="checkbox"]:checked + label:after {
            top: 2px;
            left: 6px;
        }
    }

    .button-extra-small {
        width: 100px !important;
        text-align: center;
        line-height: 1.3em;
    }

    .modal-grid #centerRow, #centerRow {
        border-radius: 0px !important;
        border: none;
        background-color: $lightgray;
    }

    .follow-up-container {
        padding: 1em 0.5em 0 0.5em;

        button {
            width: 100%;
        }
    }

    .common-grid {
        .shared-header {
            width: 100% !important;
            border-right: 0;
        }
    }

    .delivery-location {
        .button-extra-small {
            width: 80px !important;
        }

        .harvesting.grid-column-wrapper {
            .common-grid-grid {
                height: 556px;
            }
        }

        .sp-search-box-container {
            margin-right: 7px;
        }

        .margin-left {
            margin-left: 5px;
        }

        .sp-grid .ag-body-container {
            .ag-row .ag-cell input.delivery-point-type {
                width: 100%;
                background-color: transparent;
            }


            .ag-row-selected .ag-cell.disabled-cell {
                background-color: black;
            }
        }

        .ag-cell[col-id="active"] {
            .flex-container label {
                left: 27px;
            }
        }
        .ag-header-cell[col-id="kuljetusKielto"] {
            #agMenu {
                margin-left: 0px;
            }

            #agHeaderCellLabel {
                padding-right: 0px;

                #agText {
                    margin-left: 16px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }

        .ag-header-cell.ag-header-cell-filtered[col-id="kuljetusKielto"] {
            #agMenu {
                margin-left: 0px;
            }

            #agHeaderCellLabel #agText {
                margin-left: 3px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
        
        .ag-cell[col-id="kuljetusKielto"] {
            .flex-container label {
                left: 17px;
            }
        }
        
        .ag-header-cell[col-id="isMeasurementData"] {
            #agMenu {
                margin-left: 0px;
            }

            #agHeaderCellLabel {
                padding-right: 0px;

                #agText {
                    margin-left: 16px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }

        .ag-header-cell.ag-header-cell-filtered[col-id="isMeasurementData"] {
            #agMenu {
                margin-left: 0px;
            }

            #agHeaderCellLabel #agText {
                margin-left: 3px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        .ag-cell[col-id="isMeasurementData"] {
            .flex-container label {
                left: 17px;
            }
        }

        .ag-header-cell[col-id="portStockCalculation"] {
            #agMenu {
                margin-left: 0px;
            }

            #agHeaderCellLabel {
                padding-right: 0px;

                #agText {
                    margin-left: 16px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }

        .ag-header-cell.ag-header-cell-filtered[col-id="portStockCalculation"] {
            #agMenu {
                margin-left: 0px;
            }

            #agHeaderCellLabel #agText {
                margin-left: 3px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        .ag-cell[col-id="portStockCalculation"] {
            .flex-container label {
                left: 17px;
            }
        }

        .ag-header-cell[col-id="editDeliveryDestination"] {
            padding-left: 1px;
        }

        .ag-cell[col-id="editDeliveryDestination"] {
            padding-left: 1px;
        }

        .ag-header-cell[col-id="mapButton"] {
            .ag-header-cell-label {
                text-align: center;
            }
        }
    }
}

.who-modified-icon {
    content: url("../../../../content/images/svg/WhoModifiedIcon_black.svg");
    transform: translateY(30%);
}

.ag-row-level-0 {
    &.ag-row-hover {
        .ag-cell-focus .who-modified-icon {
            content: url("../../../../content/images/svg/WhoModifiedIcon_white.svg");
        }

        .ag-cell-no-focus .who-modified-icon {
            content: url("../../../../content/images/svg/WhoModifiedIcon_white.svg");
        }
    }
    &.ag-row-selected {
        .ag-cell-no-focus .who-modified-icon {
            content: url("../../../../content/images/svg/WhoModifiedIcon_white.svg");
        }

        .ag-cell-focus .who-modified-icon {
            content: url("../../../../content/images/svg/WhoModifiedIcon_white.svg");
        }

        &.ag-row-focus {
            .who-modified-icon {
                content: url("../../../../content/images/svg/WhoModifiedIcon_white.svg");
            }
        }
    }
    &.ag-row-focus {
        .who-modified-icon {
            content: url("../../../../content/images/svg/WhoModifiedIcon_black.svg");
        }
    }
}

@import './bankAccounts/bankAccounts.scss';
@import './tempStorage/tempStorage.scss';
@import './consignmentContract/consignmentContractFollowUp.scss';
@import './consignmentContract/deliverySchedulingModal/deliverySchedulingClearModal/deliverySchedulingClearModal.scss';
@import './consignmentContract/deliverySchedulingModal/deliverySchedulingModal.scss';