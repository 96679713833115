﻿@import '../../../../shared/sass/_variables.scss';

.investment-modal {
    .modal-dialog {
        top: 20%;
        width: 70%;
    }

    form {
        padding: 0px 15px;
    }

    .padded-row {
        padding: 10px 0px;
    }

    .padding-top-bottom {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .border-right {
        border-right: 0.75px solid #B0AFB0;
    }

    .additional-info {
        min-height: 143px;
        margin-top: 0em !important;
        resize: none;
    }

    label.form-control {
        border: none;
        box-shadow: none;
        padding: 6px 0px;
        font-weight: 400;
    }

    .icon {
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
    }

    .icon.icon-printer {
        background-image: url(../../../../../content/images/svg/print_white_18dp.svg);
        height: 50px;
        width: 45px;
        background-color: transparent;
    }

    .print-button {
        padding: 0 5px;
        height: 50px;
        border-radius: 0.3em;
    }


    .status-locked {
        float: left;
        background-image: url(../../../../content/images/svg/lock_grey.svg);
        /*background-size: 15px 15px;*/
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .status-open {
        float: left;
        background-image: url(../../../../content/images/svg/lock_open.svg);
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .status-cancelled {
        float: left;
        background-image: url(../../../../content/images/svg/cancel.svg);
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .status-paid {
        float: left;
        background-image: url(../../../../content/images/svg/event_available.svg);
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .status-extended {
        float: left;
        background-image: url(../../../../content/images/svg/event_extended.svg);
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .margin-right-10 {
        margin-right: 10px;
    }

    .additional-information {
        background-color: $gray;
        font-style: italic;
        padding: 1em;
    }

    .margin-top-3 {
        margin-top: 3px;
    }

    .auto-renew-icon {
        background-color: $trimble_blue_light;
        margin: 0;
    }

    .auto-renew-icon:hover:not(.black) {
        background-color: #005F9E;
    }

    .pad-right-0 {
        padding-right: 0px;
    }

    .pad-top-8 {
        padding-top: 8px;
    }

    .invalid {
        background-color: $lightred;
    }

    .valid {
        background-color: $white;
    }

    .disable-refresh{
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }
}
