﻿@import '../../../../shared/sass/_variables.scss';

$tab-border-width: 1px;

.contract-ledger-balance {
    .list-view-settings-row .advanced-filtering-dropdown .dropdown-content {
        height: 410px;
    }

    &.print-preview {

        .contract-ledger-table,
        .contract-ledger-tablefoot-actionbar,
        .settings-row-top {
            width: 842px;
            margin: 0 auto;

            .ag-body-viewport {
                overflow-x: hidden;
            }
        }
    }

    .contract-ledger-table.sp-grid {
        #centerRow {
            border-top-left-radius: 0.5em !important;
            border-top-right-radius: 0.5em !important;
        }

        .ag-cell {
            top: 1px;
        }

        .align-checkbox-center {
            padding-right: 1px !important;
            padding-left: 0px !important;
            padding-top: 1px !important;
            padding-bottom: 0px !important;
        }

        .align-header-checkbox-center {
            .ag-header-select-all {
                margin-left: 3px;
            }
        }

        .ag-cell input:disabled.date-format {
            background-color: transparent;
        }

        .grid-check-box {
            top: -8px;
        }

        .select-all-wrapper {
            left: 3px;
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }

        .ag-theme-fresh .ag-ltr .ag-selection-checkbox {
            padding-left: 3px;
            float: left;
        }

        .ag-theme-fresh .ag-header-select-all {
            margin-left: 8px;
        }

        .ag-body .ag-body-viewport-wrapper .ag-body-viewport .ag-body-container .ag-row-group,
        .ag-body .ag-pinned-left-cols-viewport-wrapper .ag-pinned-left-cols-viewport .ag-pinned-left-cols-container .ag-row-group {
            display: flex;
            justify-content: left;
            align-items: center;
            background-color: $gray;
            font-size: 12px;
            padding: 0 0 0 4px;
        }

        .ag-body {
            .ag-row-group.ag-row-hover {
                .ag-cell {
                    background-color: transparent !important;
                    color: $black !important;
                }
            }
        }
    }

    .ag-row.ag-row-group.ag-row-hover {
        background-color: $gray !important;
        color: $black !important;
    }

    .contract-ledger-table {
        margin: 0.5em 0 0;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;
        height: 50vh;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .ag-menu {
            top: 49px !important;
        }

        .ag-header {
            // for some reason the whole header is jumping up after select-all-click
            position: initial;
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-selected {
                    color: inherit;
                    background-color: inherit !important;

                    .ag-cell {
                        color: white;
                        background-color: black;
                    }

                    [type="date"] {
                        color: white;
                    }
                }

                .ag-row-hover {
                    [type="date"] {
                        color: white;
                    }
                }
            }
        }

        .icon-width {
            background-size: 15px 15px;
            height: 15px;
            width: 15px;
        }

        .status-padding {
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 83%;
            text-align: left;
        }

        .ag-icon-expanded,
        .ag-icon-contracted {
            background-color: white !important;
        }
    }

    .contract-ledger-tablefoot-actionbar {
        border: 1px solid $darkgray;
        border-top: 0;
        border-radius: 0.5em;
        background-color: $lightgray;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;

        .button-option {
            width: 22em;
            padding-left: 20px;

            .add-button {
                width: 16em;
            }

            .option {
                width: 19em;
            }

            button {
                margin-right: 0px;
                width: auto;
                margin-top: 1.3em;

                &.no-margin {
                    margin-right: 0;
                }
            }
        }

        button {
            &.no-margin {
                margin-right: 0;
            }
        }
    }

    .selection-section-content {
        padding-right: 0px;
        width: 75%;

        .display-period-content {
            /*margin-left: 10px;
               margin-right: 10px;*/
            padding-left: 30px;

            .display-period {
                margin-left: 10px;
                margin-right: 10px;
                width: 150px;
            }
        }

        .dates-container {
            margin-left: 30px;
            margin-right: 30px;

            .dates {
                width: 150px;
            }
        }

        .status-indicator {
            /*margin-left: 5px;*/
            margin-top: 7px;

            .filled-square {
                height: 15px;
                width: 15px;
                background-color: #3AAE2C;
                /*border-radius: 50%;*/
                opacity: 0.65;
                display: inline-block;
                margin-right: 10px;
            }

            .hollow-circle {
                border: 1px solid #009AD9;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                z-index: 86;
            }

            .today-text {
                margin-right: 12px;
                color: #005F9E;
                font-weight: bold;
            }


            div.square {
                border: solid 1px #009AD9;
                width: 15px;
                height: 15px;
                margin-right: 10px;
            }
        }
    }

    .text-input-field {
        height: 30px;
        border: 0.5px solid #B0AFB0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        width: 100%;
        border-radius: 5px;
    }

    .text-input-field-grid {
        height: 25px;
    }
}