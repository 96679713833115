﻿@import '../../../shared/sass/_variables.scss';

.forest-assets-form {
    height: 100%;
}

header.hideNavigation + .forest-assets .main-panel-wrapper {
    height: calc(100% - 218px);
}

header.hideNavigation {
    + .main-view-container {
        .main-panel-wrapper {
            height: calc(100% - 158px);
        }
    }
}

.forest-assets {

    .main-panel-wrapper {
        position: absolute;
        height: calc(100% - 118px);
    }

    &.forest-assets-form {
        height: 100%;
    }

    .main-panel {
        width: 1150px;
        height: 100%;
        margin: 0 auto;
    }

    .left-panel {
        height: 100%;
        float: left;
        width: 50%;
        padding-right: 5px;
        margin-top: 0.5em;

        .controls {
            margin-bottom: 1em;
        }
    }

    .right-panel {
        height: 100%;
        margin: 0;
        float: left;
        width: 50%;

        .map {
            @extend %silva-box;
            height: calc(100% + 97px); // compensating map viewport height with static search field height
        }
    }

    .rightPanelExpanded {
        margin-left: 0em !important;
        width: 100% !important;
    }

    .center {
        margin-top: 6px;
        margin-left: 5px;
    }

    .dropdown-menu-position {
        cursor: pointer;
        margin-left: 53px;
    }

    .menu-items-position {
        padding-left: 0px;
    }


    .padding-left-right {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 5px;
    }

    .no-margin-left-right {
        margin-left: 0px;
        margin-right: 0px;
    }

    .button-width {
        width: 100%;
    }

    .no-left-padding {
        padding-left: 0px;
    }

    .ag-body-viewport-wrapper {
        font-size: 14px;
    }
    /* the grid */
    .forest-assets-grid-wrapper {
        height: 100%;
        border: 1px solid #616161;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        overflow:hidden;
    }

    .forest-assets-grid {
        width: calc(100% + 1px);
        height: 100%; // setting retarded viewport height for "responsive" height
        //@extend %no-selected-row-highlight; // no row highlighting
        .ag-header-no-right-border {
            border-right: 0 !important;
        }

        .ag-body-viewport {
            overflow-x: hidden;
        }


        .ag-selection-checkbox {
            cursor: pointer;
        }

        .ag-cell-focus {
            border-top: none;
            border-bottom: none;
            border-left: none;
            outline: none;
        }

        .ag-cell {
            cursor: pointer;

            .ag-selection-checkbox {
                left: 5px;
                margin-left: 6px;
            }
        }

        .ag-header {
            .ag-selection-checkbox {
                left: 6px;
            }
        }

        .ag-row {
            :last-child {
                border-right: 0;
            }
        }
    }

    .sp-grid .ag-floating-bottom {
        box-shadow: none;
    }
    /*
	.row-selected {
		background-color: black !important; 
		color: white; 
	}

	.row-deselected{
		background-color: inherit !important; 
		color: inherit;
	}
*/
    .element-table-cell {
        display: table-cell;
    }

    .grid-footer {
        .button-copy-selected {
            width: 100%;
        }
    }
}
