﻿@import '../../../../shared/sass/_variables.scss';

$tab-border-width: 1px;

.ledger-balance {

    .list-view-settings-row .advanced-filtering-dropdown .dropdown-content {
        height: 48.2rem !important
    }


    &.print-preview {

        .ledger-balance-table,
        .ledger-balance-tablefoot-actionbar,
        .settings-row-top {
            width: 842px;
            margin: 0 auto;

            .ag-body-viewport {
                overflow-x: hidden;
            }
        }
    }

    .ledger-balance-table.sp-grid {
        #centerRow {
            border-top-left-radius: 0.5em !important;
            border-top-right-radius: 0.5em !important;
        }

        .select-all-wrapper {
            left: 4px;
            top: -6px;
            position: absolute;
        }

        .ag-cell {
            top: 1px;
        }

        .ag-cell input:disabled.date-format {
            background-color: transparent;
        }

        .check-icon {
            content: url(../../content/images/svg/check.svg);
            vertical-align: top;
            margin-left: 5px;
            margin-top: -5px;
        }

        .align-right {
            text-align: right !important;
        }

        .align-left {
            text-align: left !important;
        }

        .ag-body .ag-body-viewport-wrapper .ag-body-viewport .ag-body-container .ag-row-group {
            display: flex;
            justify-content: left;
            align-items: center;
            background-color: $gray;
            font-size: 12px;
            padding: 0 0 0 4px;
        }

        .ag-body {
            .ag-row-group.ag-row-hover {
                .ag-cell {
                    background-color: transparent !important;
                    color: $black !important;
                }
            }
        }
    }

    .ag-row.ag-row-group.ag-row-hover {
        background-color: $gray !important;
        color: $black !important;
    }

    .ledger-balance-table {
        margin: 0.5em 0 0;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;
        height: 50vh;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .ag-menu {
            top: 49px !important;
        }

        .checkbox-cell {
            // checkbox is rendered as an image
            img {
                cursor: pointer;
            }
        }

        .ag-header {
            // for some reason the whole header is jumping up after select-all-click
            position: initial;
        }
        //Hide checkbox-cells in group-rows, couldn't find a way to do this easily programmatically
        .ag-row-group .checkbox-cell {
            display: none;
        }

        .ag-row .ag-selection-checkbox {
            position: static;
            margin-left: 3px;
        }

        .unselect-control {
            padding-left: 6px;
            padding-top: 3px;

            [type="checkbox"]:not(:checked) + label,
            [type="checkbox"]:checked + label {
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                top: -10px;
            }
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-selected {
                    color: inherit;
                    background-color: inherit !important;

                    .ag-cell {
                        color: white;
                        background-color: black;
                    }

                    [type="date"] {
                        color: white;
                    }
                }

                .ag-row-hover {
                    [type="date"] {
                        color: white;
                    }
                }
            }
        }

        .icon-width {
            background-size: 15px 15px;
            height: 15px;
            width: 15px;
        }

        .status-padding {
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 83%;
            text-align: left;
        }

        .center-check-box {
            display: flex;
            justify-content: center;
        }

        .ag-group-contracted .ag-group-expanded {
            margin-left: 1px;
        }

        .ag-group-value {
            display: inline-flex;
        }

        .ag-icon-expanded,
        .ag-icon-contracted {
            background-color: white !important;
        }

        .ag-row-group-contracted,
        .ag-row-group-expanded {
            .ag-row-hover {
                background-color: transparent;
            }
        }
    }

    .ledger-balance-tablefoot-actionbar {
        border: 1px solid $darkgray;
        border-top: 0;
        border-radius: 0.5em;
        background-color: $lightgray;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;

        .button-option {
            width: 22em;
            padding-left: 20px;

            .add-button {
                width: 16em;
            }

            .option {
                width: 19em;
            }

            button {
                margin-right: 0px;
                width: auto;
                margin-top: 0.5em;

                &.no-margin {
                    margin-right: 0;
                }
            }
        }

        button {
            margin-right: 0.5em;
            width: 20em;
            margin-top: 0.5em;

            &.no-margin {
                margin-right: 0;
            }
        }
    }

    .ledger-balance-button {
        margin: 15px;
        margin-right: 0px;
    }


    .text-input-field {
        height: 30px;
        border: 0.5px solid #B0AFB0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        width: 100%;
        border-radius: 5px;
    }

    .text-input-field-grid {
        height: 25px;
    }

    .margin-top-neg-5 {
        margin-top: -5px;
    }

    .padding-right-20 {
        padding-right: 20px;
    }

    .footer-summary {
        margin-left: -26px;
        width: 17%;
    }

    .padding-left-4em {
        padding-left: 4em;
    }

    .text-align-right {
        text-align: right;
    }

    .width-150 {
        width: 150px;
    }
}