﻿.post-offer-dialog {
    z-index: 2000 !important;
    width: 600px !important;

    .formatted-message-container {
        margin-left: 20px;

        .transform-none {
            text-transform: none !important;
            padding: 15px 15px 0px 15px;
        }
    }

    .text-bold {
        font-size: initial;
    }

    .post-offer-response-message-container {
        margin-left: 20px;
        max-height: 500px;
        overflow: auto;

        .transform-none {
            text-transform: none !important;
        }

        .type {
            display: inline-block;
            margin-right: 0px;
            margin-left: 0px;
            margin-top: 15px;
        }

        .msg {
            margin-right: 0px;
            margin-left: 0px;
        }
    }
}

.post-offer-dialog-btn-class {
    margin: 0px !important;
    margin-top: 35px !important;

    .margin-bottom-15 {
        margin-bottom: 15px !important;
    }
}

.trade-contract-summary-container {

    .ag-theme-fresh .ag-header-cell,
    .ag-theme-fresh .ag-header-group-cell {
        line-height: 30px;
    }

    .ag-floating-bottom-viewport .ag-cell {
        padding: 0 4px 0 4px !important;
    }

    .top-right-txt {
        text-align: end;
        padding-right: 22px;

        .verical-line {
            padding: 0 10px 0 10px;
        }
    }

    padding-bottom: 8em;
    /*padding: 30px;*/
    .trade-contract-summary-content {
        background-color: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        border: 0.75px solid #B0AFB0;
        border-radius: 3px 3px 3px 3px;
        height: 100%;
    }

    .trade-contract-header {
        height: 30px;
        padding: 6px 0px 8px 6px;
        background: transparent linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3) 0% 0% no-repeat padding-box;
        text-transform: uppercase;
        font-weight: 700;
        padding-left: 15px;
        border-bottom: 0.75px solid #B0AFB0;
    }

    .trade-contract-black-header {
        height: 30px;
        padding: 6px 0px 8px 6px;
        text-transform: uppercase;
        background: #000000 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        font-weight: 700;
        padding-left: 15px;

        .print-button {
            padding: 0 5px;
            height: 27px;
            border-radius: 0.3em;
            margin-top: -4px;
        }

        .attachment-icon-white {
            width: 18px;
            line-height: 30px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            vertical-align: middle;
            background-color: transparent;
        }

        .attachment-icon-mesage-badge {
            position: absolute;
            top: 0px;
            left: 134px;
        }
    }

    .fields-content {
        padding: 15px;
        display: inline-block;
        /*border-right: 1px solid #B0AFB0;*/
        /*height: 150px;*/
    }

    .first-section {
        /*height: 210px;*/
        /*border-bottom: 1px solid #B0AFB0;*/
        .height-440px {
            height: 440px;
        }

        .height-510px {
            height: 500px;
        }

        .height-505px {
            height: 505px;
        }

        .fields-content {
            .row {
                padding: 2px 0px;
            }

            .icon {
                line-height: 30px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                vertical-align: middle;
            }

            .icon.icon-printer {
                background-image: url(../../../../../content/images/svg/print_white_18dp.svg);
                height: 50px;
                width: 45px;
                background-color: transparent;
            }

            .icon.icon-esign {
                background-image: url(../../../../../content/images/svg/eSign_icon.svg);
                height: 50px;
                width: 45px;
                background-color: transparent;
            }

            .input-icons i {
                position: absolute;
                margin-top: 5px;
            }

            .input-icon-edit {
                background-image: url(../../../../../content/images/svg/edit-down.svg);
                background-color: transparent;
                padding: 10px 0px;
                min-width: 35px;
                text-align: center;
            }

            .input-field {
                padding-left: 35px;
            }

            .print-button {
                padding: 0 5px;
                border-radius: 0.3em;
            }

            .lang-dropdown {
                width: 100%;
                background-color: $trimble_white;

                .custom-drop-down .dropdown-menu .custom-table .table-row:not(.table-header):hover {
                    background-color: $trimble_very_light_grey !important;
                    color: $trimble_grey !important;
                }

                .custom-drop-down .dropdown-menu > div {
                    height: 100%;
                    overflow-y: auto;
                }

                .custom-drop-down .dropdown-menu .custom-table .table-row .table-cell {
                    height: 30px;
                    padding-top: 5px;
                }

                .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    height: 30px;
                }

                .btn-group > .btn:last-child:not(:first-child),
                .btn-group > .dropdown-toggle:not(:first-child) {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    height: 30px;
                }

                .dropdown-menu {
                    border-radius: 0px;
                }

                .custom-drop-down .dropdown-menu .custom-table .table-row .second--from-two-columns {
                    margin-top: 2px;
                }

                .custom-drop-down button {
                    img {
                        float: left !important;
                    }

                    span {
                        margin-right: 5em !important;
                    }
                }
            }
        }

        .file-attachments {
            .attachment-grid-container {
                .attachmentGrid {
                    height: 167px;
                }
            }
        }

        .signature-details {
            height: 167px;
            border: 1px solid #616161;
            border-radius: 0.5em;
        }

        .kuutio-section {
            padding: 15px;

            .row {
                padding: 2px 0px;
            }

            .labels {
                padding-top: 6px;
            }

            &.is-swedish-organization {
                height: 278px;
            }
        }

        .print-silvi {
            padding-left: 0px;

            .print-button {
                padding: 0 5px;
                height: 30px;
                border-radius: 0.3em;
            }

            .btn {
                .icon.icon-printer {
                    background-image: url(../../content/images/svg/print_white_18dp.svg);
                    height: 25px;
                    width: 25px;
                    background-color: transparent;
                }
            }

            .btn:hover {
                .icon.icon-printer:hover {
                    background-image: url(../../content/images/svg/print_white_18dp.svg);
                    height: 25px;
                    width: 25px;
                    background-color: #005F9E;
                }
            }

            .button-border {
                background-color: white;
                border: 1px solid darkgray;
                border-radius: 5px;
            }

            .icon {
                height: 17px;
                width: 18px;
                line-height: 30px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    .versioning-information-header {
        background-size: 6px;
        background-image: url(../../../../../content/images/svg/CriticalNotificationSymbol_Black.svg);
        background-repeat: no-repeat;
        margin-top: 4px;
        margin-left: 7px;
        height: 22px;
    }

    .versioning-information-col {
        background-size: 6px;
        background-image: url(../../../../../content/images/svg/CriticalNotificationSymbol_Orange.svg);
        height: 22px;
        margin-left: 5px;
        background-repeat: no-repeat;
    }

    .trade-contract-input-field {
        height: 30px;
        border: 1px solid #616161 !important;
        width: 100%;
        border-radius: 5px;
        padding-left: 6px;
    }

    .published-label {
        border: none;
        box-shadow: none;
    }

    .dash-alignment {
        margin-left: 13px;
    }

    .season-radio-button {
        top: -3px;
    }

    .date-picker-width {
        width: 45%;
    }

    .check-box-label {
        padding-top: 8px;
    }

    .radio-inline {
        margin-left: 15px;
    }

    .second-section {
        padding: 15px;
        height: 100%;
        display: inline-block;
        width: 100%;

        .summaryGrid {
            border: 1px solid #616161;
        }
    }

    .third-section {
        padding: 15px;
        height: 100%;
        display: inline-block;
        width: 100%;

        .assortmentGrid {
            border: 1px solid #616161;
        }

        .ag-floating-bottom {
            height: 45px !important;
            min-height: 45px !important;

            .ag-floating-bottom-container {
                .ag-row {
                    height: 22px !important;

                    &.ag-row-odd {
                        transform: translateY(22px) !important;
                    }
                }
            }
        }
    }

    .cell-aligned-center {
        text-align: center;
    }

    .additiona-info-textarea {
        padding: 30px 15px 15px 15px;
    }

    .fixed-textarea {
        resize: none;
    }

    .margin-bottom-7 {
        margin-bottom: 7em;
    }

    .work-site-buttons {
        clear: both;

        .col-xs-2.work-site-button-left {
            padding-left: 0px;
            padding-right: 15px;
        }

        button {
            width: 100%;
            padding: 10px;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .col-xs-2 {
            padding: 0px;
        }
    }

    .invalid-date {
        background-color: #FFD8D8;
    }

    .pad-top-8px {
        padding-top: 8px;
    }

    .pad-bottom-10px {
        padding-bottom: 10px;
    }

    .pad-right-25px {
        padding-right: 25px;
    }

    .pad-17px-0px {
        padding: 17px 0px;
    }

    .border-left {
        border-left: 1px solid #B0AFB0;
    }

    .border-right {
        border-right: 1px solid #B0AFB0;
    }

    .border-bottom {
        border-bottom: 1px solid #B0AFB0;
    }

    ::-webkit-input-placeholder {
        font-style: italic;
    }

    .map {
        @extend %silva-box;
        height: 100%;
        width: calc(100% - 60px);
        margin: 0;
        overflow: hidden;
        position: absolute;
        opacity: 0;
        pointer-events: none;
    }

    .margin-right-15 {
        margin-right: 15px;
    }

    .width-100 {
        width: 100%;
    }

    .padding-top-15 {
        padding-top: 15px;
    }

    .padding-top-13 {
        padding-top: 13px;
    }

    .pad-top-5 {
        padding-top: 5em;
    }

    .padding-top-6 {
        padding-top: 6px;
    }
}
