﻿@import '../../shared/sass/_variables.scss';

.multiselection-drop-down {
    width: 200px;

    .titleBox {
        border: 1px solid $gray;
        border-radius: $sp-panel-border-radius;
        width: 200px;

        .titleBoxText {
            margin-left: 5px;
            display: inline-block;

            &:hover {
                cursor: pointer;
            }
        }

        .titleBoxDropDownIcon {
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 12px;
            float: right;

            &:hover {
                cursor: pointer;
            }
        }

        &.multiSelectDisabled {
            pointer-events: none;
            opacity: 0.5;
        }

        &.required {
            background-color: #FFD8D8 !important;
        }
    }
    
    .checkboxes {
        border: 1px solid $gray;
        border-radius: $sp-panel-border-radius;
        height: 320px;
        width: 200px;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        z-index: 1;
        background-color: white;

        .checkboxesList {
            height: 25px;
            width: 180px;

            .checkboxLabel {
                margin-left: 5px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

         .select-all-label {
             margin-left: 5px;
             margin-bottom: 0px;
         }

        .margin-top-fix {
            margin-top: 0px;
        }

        .item-margin-top-fix {
            margin-top: 10px;
        }

        hr {
            border-color: $gray;
            margin-bottom: 5px;
            margin-top: 5px;
        }

        .margin-top {
            margin-top: 8px;
        }

        .margin-top-2 {
            margin-top: 2px;
        }

        .multiselectionSearch {
            border: 1px solid $gray;
            border-radius: $sp-panel-border-radius;
            height: 35px;
            margin-left: 5px;
            margin-right: 5px;
            margin-top: 5px;

            .multiselectionSearchIcon {
                margin-left: 5px;
            }

            .multiselectionSearchInput {
                width: 70%;
                height: 25px;
                margin-left: 5px;
                border: none !important;
            }

            input:focus {
                outline: none;
            }

            .glyphicon-remove {
                top: 2.3px;
            }
        }
    }
}
