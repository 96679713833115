﻿.default-bucking {
    .starting-area {
        margin-left: 25px;
        width: 85px;
        padding-top: 6px;
    }

    .use-multiple {
        margin-left: 25px;
        padding-top: 6px;

        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:before {
            top: 0;
        }

        [type="checkbox"]:checked + label:after {
            top: 0px;
        }
    }

    .select-option-label, .checkbox-label {
        font-size: 12px;
        font-weight: 600;
        height: 28px;
        padding-top: 6px;
        font-family: "Myriad Pro Semibold";
    }

    .select-option-dropDown {
        .active-advanced-filter-modal {
            margin-left: 0px;
        }
    }

    .selected-definition, .view-option-select {
        width: 100%;
    }

    .btn.btn-default.dropdown-toggle {
        width: 100%;
        height: 30px;
        padding: 3px 6px;
        border: 1px solid #B0AFB0;

        @media screen and (max-width: 1300px) {
            border: 1px solid #B0AFB0;
        }
    }

    .dropdown-menu {
        width: 100%;
    }

    .starting-area-select {
        border-color: #ccc;
    }

    .bucking-grid-input {
        width: 100%;
        height: 25px;
        color: black;
        text-align: right;
        border-radius: 4px;
        border-width: 1px;
        padding-right: 5px;
        padding-top: 4px;
    }

    .bucking-grid-select {
        width: 100%;
        height: 25px;
        color: black;
        padding-left: 0px;
        padding-top: 3px;
    }

    .pt-6 {
        padding-top: 6px;
    }


    .common-button-container {
        padding-top: 30px;
        margin-right: 0px;
        padding-bottom: 30px;

        .save-button {
            width: 12em;
            height: 45px;
            margin-left: 8.5em;
            margin-top: -1em;
        }
    }

    .pad-5 {
        padding: 5px;
    }

    .group-row {
        display: inline-flex;
        position: absolute;
        top: 5px;
    }

    .bucking-grids {
        .grid-container {
            margin: 15px 0px 0px 0px;
            border: 1px solid grey;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);

            .sp-grid .ag-floating-bottom-container .ag-cell {
                background-color: #d1d1d1;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    left: 4px;
                }
            }

            .bukcing-checkbox-cell {
                padding-left: 7px;
                padding-top: 4px;
            }

            .select-all-wrapper {
                left: 7px;
                top: -3px;
            }

            .sp-grid .ag-root.ag-root {
                border-radius: 4px !important;
            }

            .residual-sp-grid {
                height: 155px;
            }

            .default-buckin-sp-grid {
                height: 28em;
            }

            .msmt-grid {
                height: 450px;

                .black-exclaimation {
                    @extend .background-proprieties;
                    background-size: 6px;
                    background-image: url(../content/images/svg/CriticalNotificationSymbol_Black.svg);
                }

                .sp-grid .ag-body {
                    background-color: white;
                }

                .ag-body-viewport {
                    overflow-y: auto !important;
                    overflow-x: auto !important;
                    background-color: white;
                }
            }

            .add-grid {
                height: 350px;

                .sp-grid .ag-body {
                    background-color: white;

                    .ag-header-cell-label {
                        padding-top: 3px !important;
                    }
                }

                .ag-body-viewport {
                    overflow-y: auto !important;
                }
            }

            .delete-button {
                width: 12em;
                margin-left: 9em;
            }
        }



        .ag-theme-fresh .ag-header-cell-label {
            padding: 6px 2px;
        }

        .grid-header-checkbox-label {
            margin: 0;
            left: -4px;
            top: 2px;
        }

        .ag-floating-bottom-container {
            font-size: 12px;
            font-weight: bold;
        }

        .ag-cell {
            [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                top: 5px !important;
            }

            [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                top: 2px !important;
            }
        }

        .cell-align-center {
            text-align: center;
        }

        .sp-grid {
            .cell-aligned-left {
                .ag-header-group-cell-label {
                    text-align: left;
                    padding-left: 4px;
                }
            }
        }

        .button-container {
            padding: 5px;
            background-color: #e8e8e8;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;


            .btn {
                width: 16%;
                margin: 4px;
                height: 30px !important;
                padding: 0px;
            }

            .update-btn {
                width: 296px;
            }
        }

        .button-container-grid {
            padding: 10px;
            background-color: #e8e8e8;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
}
