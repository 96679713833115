﻿@import '../../../../../shared/sass/_variables.scss';

.contract-package-modal {
    .modal-dialog {
        width: 90%;
    }

    .modal-trimble-header {
        height: 7vh;
        text-align: center;
    }

    .modal-trimble-title-second-row {
        line-height: 0px;
        user-select: none;
    }

    .modal-trimble-close {
        margin-top: -3vh;
    }
    
    .contract-package-table {
        margin: 0 auto;

        .ag-body-viewport {
            overflow-x: auto;
        }
    }

    .contract-package-table {
        margin: 0.5em 0 0;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;
        height: 54vh;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .advanced-filtering-dropdown .dropdown-content {
        width: 40vh;

        .dropdown-table {
            .table-cell {
                .dropdown-date-input {
                    width: 161px;
                }

                .multiselection-drop-down .checkboxes {
                    width: 301px;
                }
            }
        }

        .buttons-wrapper {
            width: 22% !important;
        }
    }
}
