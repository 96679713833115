.customer-info .distributionAndNotes {

	display: flex;

	.section {
		width: 50%;
		display: flex;
		flex-flow: column;
		justify-content: stretch;
		margin: 0 0.5em 0 0.5em;

		.partial {
			flex: 1;
		}
	}

  .memo-notes {
    min-height: 306px;
  }
  
  .info-notes {
    min-height: 200px;
  }
    
  textarea {
      border: 0px;
      width: 100%;
      margin: 0px;
      padding: 0.5em;
      outline: 0;
      resize: none;
      maxlength: 4000;
  }
  
  .border-top {
    border-top: 1px solid $gray;
  }

}