.forest-use-declaration-header {
    .attention-icon {
        content: url(../../content/images/svg/attention.svg);
        vertical-align: top;
        margin-left: 5px;
        margin-top: -3px;
    }

    .attention-red-icon {
        content: url(../../content/images/svg/icon_error.svg);
        vertical-align: top;
        margin-left: 5px;
        margin-top: -3px;
    }

    .check-icon {
        content: url(../../content/images/svg/check.svg);
        vertical-align: top;
        margin-left: 5px;
        margin-top: -5px;
    }
}
