.cfb-costs-title {
    display: inline-block;
}

.cfb-costs-header {
    width: 100%;
    text-align: center;
}

.cfb-costs-modal {
    &.modal {
        &.in {
            .modal-dialog {
                width: calc(100% - 60px);
                transform: translate(0,0) translateY(-50%);
                top: 50%;
                margin-top: 0px;
            }
        }
    }

    &.modal {
        &.fade {
            .modal-dialog {
                width: calc(100% - 60px);
            }
        }
    }

    & input.ag-filter-filter,
    & select.ag-filter-select {
        border-radius: 5px;
        border: 1px solid #616161;
        outline: 0;
    }

    & input[disabled]:hover {
        color: white;
        background-color: black !important;
    }
}

#cfb-costs-select-all {
    left: 5px;
    top: -5px;
}

.checkbox-cell.cfb-checkbox-cell > .ag-cell-wrapper > .ag-selection-checkbox {
    padding-left: 4px;
}

.cfb-costs-header.left {
    width: 100%;
    text-align: left;
}

.sp-grid.modal-grid.cfb-costs-grid {
    height: 425px;
}

.sp-grid.modal-grid.sales-row-grid {
    height: 50px;
}

.cfb-costs-grid input,
.sales-row-grid input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.dateInput {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
}

.cfb-costs-grid, .sales-row-grid {
    & div.ag-body-viewport-wrapper {
        background-color: white;
    }
}

.sales-row-grid {
    & div > div.ag-body > div.ag-body-viewport-wrapper > div.ag-body-viewport {
        height: initial;
    }
}

.cfb-costs-grid, .sales-row-grid {
    .ag-row-hover {
        input[disabled] {
            color: white;
            background-color: transparent !important;
        }
    }
}

.cfb-costs-grid, .sales-row-grid {
    & .ag-row:not(.ag-row-selected):not(.ag-row-hover) .ag-cell input:disabled, .ag-row:not(.ag-row-selected):not(.ag-row-hover) .ag-cell.disabled-cell:not(.ag-group-cell),
    & .ag-cell > input:disabled,
    .non-editable-cell {
        border: none;
        background-color: inherit;
        width: 100%;
        height: 100%;
    }
}



.cfb-costs-grid {
    .ag-floating-bottom {
        .ag-floating-bottom-viewport .ag-floating-bottom-container {
            & .ag-row:not(.ag-row-selected):not(.ag-row-hover) .ag-cell input:disabled, .ag-row:not(.ag-row-selected):not(.ag-row-hover) .ag-cell.disabled-cell:not(.ag-group-cell), & .ag-cell > input:disabled, .non-editable-cell {
                color: black !important;
                background-color: transparent;
                border: none;
            }

            & input[disabled]:hover {
                color: black !important;
                background-color: transparent;
                border: none;
            }

            .ag-row-hover {
                input[disabled] {
                    color: black !important;
                    background-color: transparent;
                    border: none;
                }
            }
        }
    }
}


.cfb-costs-grid, .sales-row-grid {
    .ag-row-selected input:disabled {
        color: white;
        background-color: black !important;
    }
}



.ag-cell.fixed-height {
    top: 0px;
    height: 25px;
}

.sp-grid.sales-row-grid, .sp-grid.modal-grid.cfb-costs-grid {
    & .right-align, & .right-align.ag-header-cell {
        text-align: right;
    }
}

.dateInput::-webkit-inner-spin-button,
.dateInput::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
