﻿@import '../../../../shared/sass/_variables.scss';

.reason-maintenance {
    padding-bottom: 30px;

    .button-container {
        background-color: $lightgray;
    }

    .content .button-container {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    .button-container .btn {
        width: 15%;
        margin: 10px;
    }

    .ag-theme-fresh .ag-floating-bottom .ag-row,
    .ag-theme-fresh .ag-floating-top .ag-row {
        background-color: $gray;
    }

    .ag-header-cell-label {
        padding-top: 4px !important;
    }

    .grids .ag-floating-bottom-container .ag-row .ag-cell {
        padding-top: 4px;
    }

    .reason-list-grid {
        border-bottom: 1px solid $darkgray;
    }

    .wrap-user {
        top: 1px;
    }

    .grid-edit-modal .body-row .the-body .body-column label {
        border-color: transparent;
        width: 1px;
    }

    .grid-edit-modal .body-row .the-body .body-column button,
    .grid-edit-modal .body-row .the-body .body-column input,
    .grid-edit-modal .body-row .the-body .body-column select,
    .grid-edit-modal .body-row .the-body .body-column label {
        height: auto !important;
    }

    .grid-edit-modal .body-row .the-body .body-column {
        border-right: 1px solid $gray;
    }

    .grid-edit-modal .header-row .the-header .header-column {
        border-right: 1px solid $gray;
    }

    .grid-edit-modal {
        width: 96.8%;

        .col-12 .body-row {
            border-bottom: 1px solid #808080;
            border-left: 1px solid #808080;
            border-right: 1px solid #808080;
        }

        .col-12 .header-row:nth-child(2) {
            border-bottom: 1px solid #808080;
            border-left: 1px solid #808080;
            border-right: 1px solid #808080;
            border-top: 1px solid #808080;
        }

        .body-row {
            height: auto;
        }

        .border-bottom {
            padding: 15px;
            border-bottom: 0;
        }

        .header-row {
            background: transparent linear-gradient(180deg, var(---f3f3f7-very-light-grey) 0%, var(---ffffff-white) 0%, var(---e2e2e7-sum-row) 100%) 0% 0% no-repeat padding-box;
            background: $trimble-gradient-grey-header;
            border-radius: 3px 3px 0px 0px;
            opacity: 1;
        }

        .material-switch-container .material-switch-content>input[type="checkbox"]:checked+label::after {
            background: $trimble_tf_product_green;
            left: 20px;
        }

        .material-switch-container .material-switch-content>input[type="checkbox"]:checked+label::before {
            background: $trimble_tf_product_green;
            border: 1px solid $trimble_tf_product_green;
            opacity: 0.5;
        }

        .material-switch-container .material-switch-content>label::after {
            left: 0px;
        }
    }

    .grid-edit-modal .the-header {
        width: 100%;
    }

    .grid-edit-modal .header-row .the-header {
        padding: 0 !important;
    }

    .grid-edit-modal .body-row .the-body {
        width: 100%;
        padding: 0 !important;
    }

    .used-in-width {
        width: 30%;
    }

    .name-width {
        width: 30%;
    }

    .details-width {
        width: 30%;
    }

    .active-width {
        width: 8%;
    }

    .user-info-width {
        width: 2%;
    }

    .switch-padl {
        padding-left: 2px;
    }

    .switch-marr {
        margin-right: 10px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 20px;
        margin: 5px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 20px;
        left: 2px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: $trimble_tf_product_green;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .slider-span {
        margin: 5px;
    }

    .margin-left-right-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .no-padding {
        padding: 0px;
    }

    .visibility-hidden {
        visibility: hidden;
        cursor: not-allowed;
    }

 

    .selection-section {
        display: flex;
        margin-bottom: 15px;

        .display-period-text {
            margin-top: 5px;
        }

        .selection-section-content {
            padding-right: 0px;
            width: 75%;
        }

        .display-flex {
            display: flex;
        }

        .height-s {
            height: 30px;
        }

        .specification-option {
            width: 275px;
            text-transform: uppercase;
            border: 1px solid $gray;
            border-radius: 4px;
        }
    }

    .content .grids .grid-border .sp-grid .ag-root.ag-root {
        border-radius: 0em !important;
    }

    .content {
        background-color: $white;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid $half_trimble_brand_grey;
        border-radius: 3px;

        .panel-header {
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            border-radius: 0 0 0 0;
            padding: 4px 4px;
            height: 25px;
            border-bottom: 1px solid #808080;
        }

        .current-month {
            border: solid 1.5px $trimble_blue_light;
            padding-left: 7px;
            padding-bottom: 7px;
            border-radius: 5px;
        }

        .display-flex-wrap {
            display: flex;
            flex-wrap: wrap;
        }

        .btn.active {
            -webkit-box-shadow: none;
            box-shadow: none;
        }


    }
}