@import '../../../shared/sass/_variables.scss';

.main-page {

    .container {
        padding: 0;
    }

    .message-block-container {
        margin: 0.5em 1em !important;
        padding: 0.5em;
        background-color: $trimble_tf_product_green;
        color: $white;
    }

    .link-block-container {
        padding: 0;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .row { //override Bootstrap's row default settings for maring
        margin: 0px;
        padding-left: 11px;
        padding-right: 11px;
    }
    /* PARTIALS */
    .partial {
        padding: 0;
        border-radius: 0.5em;
        display: inline;


        :first-child {
            margin-left: 0;
        }

        :last-child {
            margin-right: 0;
        }
        /* message block */
        .message-block {
            border-radius: 0.5em;

            .topic {
                padding-bottom: 1em;
                text-transform: uppercase;
                border-bottom: 1px solid $white;
            }

            p {
                padding: 1em 1em 0 1em;

                :last-child {
                    padding: 1em;
                }
            }
        }
        /* link block */
        .link-block {
            font-family: $font-primary;
            font-size: 12px;

            .inner {
                box-shadow: 0 0 3px $half_trimble_brand_grey, 0 0 3px $half_trimble_brand_grey;
                height: 250px;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                background-color: $trimble_white;
            }

            .large-nav-button {
                cursor: pointer;
                background-color: $trimble_white;
                padding-right: 0px !important;
                padding-left: 0px !important;


                &:hover {
                    background-color: $trimble_white;
                    color: $trimble_black;

                    .topic {
                        color: $trimble_tf_product_green;
                    }

                    .icon {
                        border-color: $trimble_grey_light;

                        &-mainpage {
                            background-image: url("/content/images/svg/MainMenu_Large_Home_Selected.svg");
                        }

                        &-workingsites {
                            background-image: url("/content/images/svg/MainMenu_Large_Worksites_Selected.svg");
                        }

                        &-customers {
                            background-image: url("/content/images/svg/MainMenu_Large_Customers_Selected.svg");
                        }

                        &-forestProperty {
                            background-image: url("/content/images/svg/MainMenu_Large_Forestdata_Selected.svg");
                        }

                        &-company {
                            background-image: url("/content/images/svg/MainMenu_Large_Company_Selected.svg");
                        }

                        &-settings {
                            background-image: url("/content/images/svg/Navi-Settings-White.svg");
                        }

                        &-admin {
                            background-image: url("/content/images/svg/MainMenu_Large_Admin_Selected.svg");
                        }

                        &-lock {
                            background-image: url("/content/images/svg/Lock-White.svg");
                        }
                    }
                }

                a:hover, a:active {
                    text-decoration: none;
                }

                .icon {
                    text-align: center;
                    border-bottom: 01px solid $trimble_grey_light;

                    div {
                        height: 50px;
                        margin: 0.5em 0;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    &-mainpage {
                        background-image: url("/content/images/svg/MainMenu_Large_Home.svg");
                    }

                    &-workingsites {
                        background-image: url("/content/images/svg/MainMenu_Large_Worksites.svg");
                    }

                    &-customers {
                        background-image: url("/content/images/svg/MainMenu_Large_Customers.svg");
                    }

                    &-forestProperty {
                        background-image: url("/content/images/svg/MainMenu_Large_Forestdata.svg");
                    }

                    &-company {
                        background-image: url("/content/images/svg/MainMenu_Large_Company.svg");
                    }

                    &-settings {
                        background-image: url("/content/images/svg/Navi-Settings-Selected.svg");
                    }

                    &-admin {
                        background-image: url("/content/images/svg/MainMenu_Large_Admin.svg");
                    }

                    &-lock {
                        background-image: url("/content/images/svg/Lock.svg");
                    }
                }

                .topic {
                    color: $trimble_black;
                    margin: 0;
                    margin-bottom: -0.5em;
                    margin-top: 2em;
                    padding: 0;
                    font-family: $font-semibold;
                    text-transform: uppercase;
                    font-size: 14px;
                }

                .last-topic {
                    margin-bottom: 0.5em;
                    margin-top: 0em;
                }

                p {
                    text-align: center;
                    padding: 0.5em;
                    padding-left: 2em;
                    padding-right: 2em;
                }

                .admin-text {
                    padding: 0;
                    padding-top: 0.5em;
                }
            }
        }
        /* link block */
    }

    .message-list-modal {
        border: 1px solid $gray;
        border-radius: 0.5em;
        background-color: $trimble_white;
        padding: 0;

        .title-bar {

            .header-left {
                margin-left: 15px;
            }

            .header-right {
                margin-right: 10px;
                margin-top: 0px;

                .search-area {
                    margin-right: 0px;
                }

                .search-bar {
                    input {
                        width: 470px !important;
                    }

                    span.glyphicon-remove {
                        top: 6px;
                    }
                }

                .close-button {
                    margin-right: 5px;
                    top: 6px;
                    margin-left: 12px;
                }
            }
        }
    }
}


