.duplicate-customer-dialog-window-class {
    .modal-dialog {
        height: 300px;
    }

    .bold-text {
        font-weight: bold;;
    }

    .grid-container {
        margin-left: 0;
        margin-right: 0;

        .modal-grid {
            height: 200px;
        }
    }
}