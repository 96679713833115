.add-or-edit-delivery-destination {
    .modal-dialog {
        width: 97.2%;
        margin-top: 203px;

        .content .basic-details {
            padding: 0px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }

        .margin-left {
            margin-left: 10px;
        }
    }
}