﻿@import '../shared/sass/variables';

%no-padding-no-margin {
    padding: 0;
    margin: 0;
}
%flex-container-base {       
    align-items: center;
    width: 100%;
    height: 100%;    
    @extend %no-padding-no-margin;
}
%flex-container {
    @extend %flex-container-base;    
    display: flex; 
    justify-content: center;    
}

.one-row-account {
    .bankAccount-input-group {
        flex-direction: row;

        div:first-child {
            width: 40%;
        }

        div:last-child {
            width: 60%;
        }

        .wrapper {
            display: block;

            .form-control.bic {
                border-radius: 0 4px 4px 0;
            }

            .form-control.iban {
                border-radius: 4px 0 0 4px;
            }
        }
    }
}

.bankAccount-input-group {
    @extend %flex-container;
    flex-direction: column;    

    &.same-row {
        flex-direction: row;
    }

    .wrapper {
        @extend %flex-container;                

        input, select {
            height: 100%;
        }
    }

    .divider {
        width: 100%;
        height: initial !important;
        border-top: $grid-lighter-border;
    }

    select {
        option {
            padding: 0;
        }
    }
}

.bank-account-same-row {
    padding: 0 !important;

    .bankAccount-input-group {
        flex-direction: row; 
    }    
}

.ag-row-hover , .ag-row-selected , .ag-row-focus {
    .ag-cell {
        .bankAccount-input-group {
            input, select, textarea {
                background-color: white;;
                color: black;
            }       
        }
    }
}