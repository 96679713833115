﻿@import '../../../shared/sass/_variables.scss';

.modal-extra-big-lg {
    max-width: 1650px;
    width: 1650px;

    .modal-content {
        width: 100%;
    }
}

.pad-t-8 {
    padding-top: 8px;
}

.search-width-element {
    width: 100%;
}

.row-container {
    display: flex;
    margin-left: 0%;
}

.image-disabled {
    color: #FFFFFF;
    cursor: default;
    opacity: 0.5;
}

.harvest-area-link {
    a {
        color: #337ab7 !important;
    }
}

.harvest-area-link:hover {
    a {
        color: #337ab7 !important;
        text-decoration: underline !important;
    }
}

.ag-row-no-focus {
    a {
        color: #337ab7 !important;
    }

    a:hover {
        text-decoration: underline !important;
    }
}

.search-width-container {
    width: 24%;
}

.group-by-source-element {
    width: 50%;
    margin-left: 15px
}

.certificate-total {
    margin-left: auto;
    margin-right: 15px;
    padding-top: 8px;

    .certificate-total-number {
        font-weight: bold;
    }
}

.active-text {
    top: 5px;
    left: 15px;
}

.color-orange {
    color: $orange;
}

.color-red {
    color: $red;
}

.switch-padl {
    padding-left: 2px;

    .material-switch-container .material-switch-content > input[type="checkbox"]:checked + label::after {
        top: 4px !important;
    }

    .material-switch-container .material-switch-content > input[type="checkbox"]:not(:checked) + label::after {
        top: 4px !important;
    }
}

.measurement-grids {
    .btn-calculate-warning {
        background-color: $orange;
        border: 1px solid $orange;
    }

    .padding-top-7 {
        padding-top: 7px;
    }

    .bottom-grid-row {
        top: 30px;
        height: 30px;
        font-size: 12px;
        font-weight: bold;
    }

    .orange {
        color: #FF8B00;
    }

    .blue {
        color: #43B5E3;
    }

    .pt-2 {
        padding-top: 2px;
    }

    .pen-fieldset {
        margin-top: -4px;
        margin-right: 2px;
    }

    .ag-header-cell-label {
        padding-top: 6px !important;
    }

    .ag-header-cell-menu-button {
        padding-top: 5px;
    }

    .ag-header-group-cell-label {
        padding-top: 6px !important;
    }

    .ag-cell {
        padding-top: 6px;
    }

    .ag-floating-bottom-container .ag-row .ag-cell {
        padding-top: 6px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0;
    }

    .grid-border {
        .padding-bottom {
            padding-bottom: 2px;
        }

        .grid-container {
            margin: 15px 0px 0px 0px;
            border: 1px solid grey;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);

            .sp-grid .ag-floating-bottom-container .ag-cell {
                background-color: #d1d1d1;
                border-right: 1px solid $half_trimble_brand_grey;
                border-bottom: 1px solid $darkgray;
            }

            .ag-header-cell {
                border-right: 1px solid $half_trimble_brand_grey !important;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    left: 4px;
                }
            }

            .select-all-wrapper {
                left: 5px;
                top: -2px;
            }

            .sp-grid .ag-root.ag-root {
                border-radius: 4px !important;
            }

            .ag-body-container {
                .ag-selection-checkbox {
                    .ag-icon {
                        margin-top: 5px;
                    }
                }
            }

            .black {
                margin-top: 6px;
            }

            .msmt-grid {
                height: 450px;

                .black-exclaimation {
                    @extend .background-proprieties;
                    background-size: 6px;
                    background-image: url(../content/images/svg/CriticalNotificationSymbol_Black.svg);
                }

                .sp-grid .ag-body {
                    background-color: white;
                }

                .ag-body-viewport {
                    overflow-y: auto !important;
                    overflow-x: auto !important;
                    background-color: white;
                }
            }

            .add-grid {
                height: 350px;

                .sp-grid .ag-body {
                    background-color: white;

                    .ag-header-cell-label {
                        padding-top: 3px !important;
                    }
                }

                .ag-body-viewport {
                    overflow-y: auto !important;
                }
            }
        }

        .grid-header-checkbox-label {
            margin: 0;
            left: -4px;
            top: 2px;
        }

        .ag-floating-bottom-container {
            font-size: 12px;
            font-weight: bold;
        }

        .ag-cell {
            [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                top: 5px !important;
            }

            [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                top: 2px !important;
            }
        }

        .cell-align-center {
            text-align: center;
        }

        .sp-grid {
            .cell-aligned-left {
                .ag-header-group-cell-label {
                    text-align: left;
                    padding-left: 4px;
                }
            }
        }

        .button-container {
            padding: 5px;
            background-color: #e8e8e8;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;


            .btn {
                width: 16%;
                margin: 4px;
                height: 30px !important;
                padding: 0px;
            }

            .update-btn {
                width: 296px;
            }
        }

        .button-container-grid {
            padding: 10px;
            background-color: #e8e8e8;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;


            .button-with-options {
                width: 100%;
                display: flex;

                .add-button {
                    border-right: none;
                    width: calc(100% - 2.4em);
                }

                .drop-down-table:hover {
                    background-color: #eee;
                }

                .dropdown-content {
                    position: absolute;
                    z-index: 9999;
                    width: calc(100% - 1.1em);
                    background: $white 0% 0% no-repeat padding-box;
                    border: 0.5px solid $half_trimble_brand_grey;
                    background: $white 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 0.5px solid $half_trimble_brand_grey;
                    opacity: 1;
                    top: 30px;
                }

                .option {
                    width: 100% !important;
                }

                .arrow-button {
                    border-left: none;
                }
            }
        }

        .update-option-button {
            .dropdown-content {
                width: calc(100% - 2.1em) !important;
            }
        }
    }

    .seven-cols {
        @media (min-width: 768px) {
            .col-md-1,
            .col-sm-1,
            .col-lg-1 {
                width: 100%;
                *width: 100%;
            }
        }

        @media (min-width: 992px) {
            .col-md-1,
            .col-sm-1,
            .col-lg-1 {
                width: 14.285714285714285714285714285714%;
                *width: 14.285714285714285714285714285714%;
            }
        }
        /**
 *  The following is not really needed in this case
 *  Only to demonstrate the usage of @media for large screens
 */
        @media (min-width: 1200px) {
            .col-md-1,
            .col-sm-1,
            .col-lg-1 {
                width: 14.285714285714285714285714285714%;
                *width: 14.285714285714285714285714285714%;
            }
        }
    }
}

.modal-big-lg {
    width: 1310px !important;
}

.border-right {
    border-right: 0.5px solid $half_trimble_brand_grey;
}

.calculate-validation.popover.top > .arrow:after {
    border-top-color: $orange;
}

.calculate-validation {
    background-color: $orange;
    border: solid 1px $orange;
    z-index: 1020 !important;
    cursor: pointer;

    .popover-inner {
        .popover-content {
            padding: 0px 10px;
            font-size: 17px;
        }
    }
}

.search-padding {
    padding: 0px 15px 0px 0px;
}