﻿@import '../../../../../shared/sass/_variables.scss';

$font-size: 12px;
$tab-margin: 15px;
$outer-padding: 14px;

.worksite-tabs {
    .storage-trade-harvest-divider {
        border-top-color: $darkgray;
        margin-left: -$outer-padding;
        margin-right: -$outer-padding;
        margin-top: 0;
        margin-bottom: 0;
    }

    .storages-tab {
        font-size: $font-size;
        padding-bottom: $tab-margin;

        .tab-content {
            border: none;
            padding: 0;
        }

        sp-common-grid {
            height: 500px;

            .ag-theme-fresh {

                .ag-header {
                    font-size: $font-size !important; //TODO: remove the important from the .worksite-tabs , then it can be removed also from here
                }

                select {
                    height: 23px !important;
                    padding: 0 0 0 4px !important; //TODO: remove the important from the .worksite-tabs, then it can be removed also from here
                }
            }
        }

        .grid-button>span>div {
            width: 100%;

            >button {
                width: 100%;
            }
        }

        .button-extra-small {
            padding: 0px 0px 0px 0px;
        }
    }

    .trade-harvest-areas-tab {
        font-size: $font-size;
        border: 1px solid $trimble_brand_grey;
        border-radius: 0.3em;
        margin-top: 15px;

        .trade-areas-grid {
            height: 325px;

            .ag-floating-bottom .ag-row {
                background-color: $gray !important;
            }

            .ag-floating-bottom-viewport {
                background-color: $gray !important;
                border-top: 1px solid $trimble_brand_grey
            }

            .ag-floating-bottom-container {
                font-size: 12px;
                font-weight: bold;
            }

            .ag-header {
                font-size: $font-size !important; //TODO: remove the important from the .worksite-tabs , then it can be removed also from here
            }

            .grid-checkbox {
                top: -8px;
            }

            .ag-header-group-cell-with-group {
                text-transform: uppercase;

                .ag-header-group-cell-label {
                    text-align: left;
                }
            }

            .checkbox-cell {
                text-align: center;
                left: 2px !important;
            }
        }

        .button-group {
            padding: 5px;
            border-top: 1px solid $trimble_brand_grey;
            background-color: $gray;
            display: flex;
            flex-wrap: nowrap;

            button {
                min-width: 200px;
                margin: 5px;
            }
        }
    }
}