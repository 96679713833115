﻿@import '../../../../shared/sass/_variables.scss';

.timber-sale-plan {
    $font-family: "Myriad Pro", Verdana;
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: $font-family;
    font-size: 12px;
    padding-right: 0px;
    margin-top: 0px;

    .navigation-buttons {
        padding-left: 0;
        padding-top: 0;

        .navigation-button-description {
            width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;
            font-size: 14px;
            line-height: 18px;

            &-prev {
                text-align: right;
            }

            &-next {
                text-align: left;
            }
        }
    }

    .grid-button {
        padding: 1px 6px;
    }

    .disabled-cb {
        pointer-events: none;
    }

    .panel-header {
        background: $gray;

        .panel-label {
            font-family: "Myriad Pro Semibold";
            text-transform: uppercase;
        }

        .panel-information {
            font-family: "Myriad Pro";
        }

        a {
            display: inline-block;
            max-width: 100%;
            margin-bottom: 5px;
            font-weight: 700;
        }
    }

    .white-on-black-header {
        background-color: black;
        color: white;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";

        .ag-header-group-cell-label {
            text-align: left;
        }
    }

    .rounded-header {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .left-padding {
        padding: 0px;
    }

    .left-text {
        text-align: left;
    }

    .border {
        border: 1px solid rgb(97,97,97);
        border-radius: 5px;
    }

    .header-padding {
        padding-left: 5px;
        padding-top: 3px;
    }

    .label-margin {
        margin-bottom: 0px;
    }

    .hidden-item {
        visibility: hidden;
    }

    .form-group {
        //margin-top: 7px;
        padding: 0px;
    }

    .dropdown-menu {
        font-family: "Myriad Pro", Verdana;
        font-size: 12px;
        color: black;

        > div {
            overflow-y: hidden;
            height: auto;
        }
    }

    .dropdown-menu-position {
        width: 100% !important;
        cursor: pointer;
    }

    .hand-cursor {
        cursor: pointer;
        text-decoration: underline;
    }

    .form-label {
        font-weight: bold;
        margin-top: 3px;
    }

    .hr-margin {
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    .sp-grid .ag-root {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        font-size: 12px;
    }

    .navigation-grid {
        margin: 0px;
        border: none !important;
        user-select: none !important;
        height: 385px;

        .ag-floating-bottom-viewport {
            background: $gray;

            .ag-floating-bottom-container {
                .ag-row {
                    .ag-cell {
                        background-color: $gray;
                    }
                }

                :first-child .ag-cell {
                    overflow: visible;
                }
            }
        }        

        .ag-body-viewport-wrapper {
            border-bottom: 1px solid $darkgray;
        }
    }

    .button-hidden {
        visibility: hidden;
    }

    .grey-bg {
        background-color: $gray;
    }

    .grey-table-first-column {
        border-right: 1px solid white;
        border-bottom: 1px solid white;
        padding-left: 5px;
    }

    .table-header {
        border-right: 1px solid white;
        padding-left: 5px;
    }

    .upper-case {
        text-transform: uppercase;
    }

    .table-element {
        padding-left: 5px;
        border-right: 1px solid $gray;
        border-bottom: 1px solid $gray;
    }

    .table-bottom-element {
        padding-left: 5px;
        border-bottom: 1px solid $gray;
    }

    .cuttings-table {
        width: 100%;
        height: 105px;
    }

    .bottom-row {
        padding-left: 5px;
        border-right: 1px solid $gray;
    }

    .comments-container {
        margin: 0px;
        padding: 0px;
        background-color: white;
    }

    .comments-padding {
        padding: 8px;
    }

    .no-lr-padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    .no-padding {
        padding: 0px;
    }

    .align-cb-label {
        padding-top: 8px;
    }

    .cb-input-size {
        height: 18px;
    }

    .no-top-margin {
        margin-top: 0px;
    }

    .field-validation-tooltip {
        margin-top: 30px;
    }

    .content {
        [class^="col"] {
            padding-left: 5px;
            padding-right: 5px;
        }

        .same-row {
            padding-left: 10px;
            padding-right: 10px;
        }

        input[type=radio] {
            color: red !important;
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    .editable-text { //moved one level up, to be applied to all myh grids
        textarea:focus, input:focus {
            color: black;
            text-align: right;
        }

        textarea:hover, input:hover {
            color: black;
            text-align: right;
        }

        input, select, textarea {
            color: black;
            text-align: right;
        }

        input {
            border-style: none;
            width: 100%;
            //height: 19px !important;
        }
    }

    .header-right {
        text-align: right !important;
        padding-right: 3px;
    }

    .header-center {
        text-align: center !important;
    }

    .right-grey {
        background-color: #f6f6f6;
        text-align: right;
        font-size: 12px;
    }

    .white-bg {
        background-color: white;
    }

    .grid-input {
        text-align: right !important;
        border: 0;
        width: 100%;
        padding-right: 5px;
    }

    .ag-theme-fresh .ag-cell {
        padding-top: 5px;
    }

    .button-padding {
        padding-top: 2px !important;
    }

    .ag-theme-fresh .ag-cell-no-focus {
        border-right: none;
    }

    .align-left {
        text-align: left;
    }

    .no-right-border {
        border-right: none;
    }

    .font-size {
        font-size: 12px;
    }

    .gray-right-border {
        border-right: 1px solid $gray;
    }

    .gray-left-border {
        border-left: 1px solid $gray;
    }

    .row-under-grid {
        background-color: white;
        height: 130px;
    }

    .height-100-percent {
        height: 100%;
    }

    .no-margin {
        margin: 0;
    }

    .padding-left-0p5em {
        padding-left: 0.5em;
    }

    .padding-left-5px {
        padding-left: 5px;
    }

    .margin-bottom-15px {
        margin-bottom: 15px;
    }

    .margin-bottom-7px {
        margin-bottom: 7px;
    }

    .pricing-methods-group {
        clear: left;
    }

    .pricing-methods {

        label {
            position: relative;
            top: 2px;
        }
    }

    .right-side-panel {
        padding-bottom: 10px;
    }

    .second-group {
        margin-top: 5px;
    }

    .form-control-textarea {
        margin-top: 0px;
        padding: 4px;
        min-height: 80px;
        resize: vertical
    }

    #published {
        position: initial;
        margin: 0;
        box-shadow: none;
    }

    .published-status {
        width: 19px;
        height: 11px;
        margin-right: 105px;
    }

    .padding-bottom-10px {
        padding-bottom: 10px;
        width: 106%;
    }

    .hr-margin-bottom {
        margin-top: 5px;
        margin-bottom: 5px;
        width: 575px;
        margin-left: -15px;
    }

    .margin-top-4 {
        margin-top: 4px
    }

    .date-padding {
        width: 47%;
    }

    .dash-mark {
        padding-left: 10px;
        padding-top: 3px;
    }
}