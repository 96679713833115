﻿@import '../../shared/sass/_variables.scss';

.language-selection {
    .lang-dropdown {
        width: 160px;
        background-color: $trimble_white;

        .custom-drop-down .dropdown-menu .custom-table .table-row:not(.table-header):hover {
            background-color: $trimble_very_light_grey !important;
            color: $trimble_grey !important;
        }

        .custom-drop-down .dropdown-menu > div {
            height: 100%;
            overflow-y: auto;
        }

        .custom-drop-down .dropdown-menu .custom-table .table-row .table-cell {
            height: 35px;
        }

        .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }

        .btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .dropdown-menu {
            border-radius: 0px;
        }

        .custom-drop-down .dropdown-menu .custom-table .table-row .second--from-two-columns {
            margin-top: 2px;
        }
    }
}
