@import './manangingHaravesting.scss';


.inventory-statuses-release-to-transport {
    &.inventory-statuses {

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        .list-view-settings-row {
            margin-bottom: 0;

            .advanced-filtering-dropdown .dropdown-content {
                height: 499px;
            }

        }

        .dropdown-content {
            .setting-item-margin-right {
                margin-right: 7px;
            }
            [col-id=resourceLinked] {
                padding: 0;
            }
            .tooltips {
                height: 27px;
                width: inherit;
                cursor: pointer;
            }
            .kone-logo {
                padding: 3px;
            }
            .ag-row-level-0 {
                &.ag-row-hover {
                    .ag-cell-focus .tooltips .kone-logo {
                        content: url(../../content/images/svg/Harvester_Blue_fill.svg)
                    }
                    .ag-cell-no-focus .tooltips .kone-logo {
                        content: url(../../content/images/svg/Harvester_Blue_fill.svg)
                    }
                }
                &.ag-row-selected {
                    .ag-cell-focus .tooltips .kone-logo {
                        content: url(../../content/images/svg/Harvester_Blue_fill.svg)
                    }
                    .ag-cell-no-focus .tooltips .kone-logo {
                        content: url(../../content/images/svg/Harvester_Blue_fill.svg)
                    }
                }
            }
            .ag-row-level-1 {
                &.ag-row-hover {
                    .ag-cell-focus .tooltips .kone-logo {
                        content: url(../../content/images/svg/Harvester_Blue_fill.svg)
                    }
                    .ag-cell-no-focus .tooltips .kone-logo {
                        content: url(../../content/images/svg/Harvester_Blue_fill.svg)
                    }
                }
            }
            .inventory-grid {
                .common-grid-grid {
                    .custom-filter {
                        input {
                            border-radius: 3px;
                            border: 1px #616161 solid !important;
                            margin-top: 3px;
                            padding: 1px 5px;
                        }
                    }
                }
                .sp-common-grid-toolbar-item {
                    padding-top: 0;
                    display: flex;

                    .filter-item {
                        margin: 0 2px 0 2px;
                    }
                }

                .ag-header-group-cell-label {
                    border: 0;
                    text-transform: uppercase;
                    padding-left: 4px !important;
                }

                .ag-row:not(.ag-row-group) {
                    cursor: pointer;

                    input {
                        cursor: pointer;
                    }
                }

                .ag-row:not(.ag-row-selected):not(.ag-row-hover).ag-row-level-1 {

                    .ag-cell input:disabled,
                    .ag-cell.disabled-cell:not(.ag-group-cell) {
                        background-color: $white;
                    }
                }

                input.date-format {
                    background-color: inherit !important;
                }

                .no-padding {
                    span {
                        padding: 0px;
                    }
                }

                .ag-cell {
                    input[type="text"] {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    input[type="number"] {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        text-align: right;
                        margin-right: 2px;
                        width: 100%;
                    }
                }

                .ag-group-cell {
                    .ag-selection-checkbox {
                        display: unset;
                    }
                }

                .ag-group-child-count {
                    display: none;
                }

                .text-center {
                    width: 100%;
                    text-align: center;
                }

                .image-center {
                    height: 20px;
                    margin-top: -5px;
                    width: 100%;
                    text-align: center;
                }

                .checkbox-margins {
                    margin-left: -1px;
                    margin-top: 2px;
                }

                .ag-row-group {
                    background-color: $lightgray;
                }
            }
            .advanced-filtering-dropdown {
                .dropdown-content {
                    .resources-grid-heading {
                        color: $sp-panel-font-color;
                        font-size: $sp-panel-heading-font-size;
                        font-weight: bold;
                        padding-left: 5px;
                        padding-top: 3px;
                        text-transform: uppercase;
                        background-color: #d3d3d3;
                        height: 25px;
                        margin-left: 15px;
                        margin-right: 15px;
                        margin-top: 8px;
                        border: 1px solid;
                        border-color: $sp-panel-border-color;
                        border-top-left-radius: $sp-panel-border-radius;
                        border-top-right-radius: $sp-panel-border-radius;
                    }

                    .resources-grid {
                        height: 175px;
                        margin-left: 15px;
                        margin-right: 15px;
                        border-left: 1px solid;
                        border-right: 1px solid;
                        border-bottom: 1px solid;
                        border-color: $sp-panel-border-color;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;

                        .ag-selection-checkbox {
                            margin-left: 2px;
                        }
                    }
                    .invalid-date {
                        background-color: #FFD8D8;
                    }
                }

                .dropdownContentReducedHeight {
                    height: 300px;
                }

                .has-extra-row {
                    height: 395px !important;
                }
            }
        }

        &.container {
            .saved-lists-dropdown {
                position: absolute;
                top: 100%;
                z-index: 1000;
                padding: 5px 0;
                margin: 2px 0 0;
                font-size: 14px;
                text-align: left;
                list-style: none;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid rgba(0, 0, 0, .15);
                border-radius: 4px;
                box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
                left: 0;
            }
        }

        .saved-lists-dropdown {
            left: -106%;
            top: 100%;
        }
    }

    .ag-body-viewport-wrapper .no-transport-season-or-geometry .ag-selection-checkbox img {
        width: 15px;
        border-top: 423px;
        filter: invert(20%);
        pointer-events: none;
    }

    .common-grid .common-grid-grid [type="checkbox"]:not(:checked)+label,
    .common-grid .common-grid-grid [type="checkbox"]:checked+label {
        right: -2px;
    }

    .multiselection-grid {
        height: 190px !important;
    }
}