@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes slide-up-hide {
	0% {
		opacity: 1;

	}

	100% {
		opacity: 0.1;
		transform: translate3d(0, -100%, 0);
	}
}

@keyframes slide-down {
	0% {
		opacity: 0.1;
		transform: translate3d(0, -100%, 0);

	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes blinker {
    25% {
        opacity: 0;
    }
}