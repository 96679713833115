@import '../../../shared/sass/_variables.scss';

.top-right-minified {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $trimble_very_light_grey;

    > .row {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: calc(100% - 1px); // fix scrollbar popping over border
        background-color: $trimble_very_light_grey;

        > .width-half {
            width: calc(50% - 1em);

            &:first-of-type {
                margin-right: 0.5em;
                margin-left: 0px;
            }

            &:nth-of-type(2) {
                margin-right: 0px;
            }
        }

        .cancel-filled{
            height: 19px;
            width: 19px;
        }
    }

    .bottom-buttons {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top: 1px solid $darkgray;
        padding: 0.5em;
        background: $lightgray;
        position: absolute;
        bottom: 0;
        width: 100%;

        > button {
            margin-right: 0.5em;
        }
    }

    input[type="date"]::-webkit-calendar-picker-indicator,
    input[type="date"]::-webkit-inner-spin-button {
        display: none;
    }

    .no-left-border-no-padding {
        border-left: none !important;
        padding: 0 !important;
    }

    .date-field {
        height: 20px;
        width: 110px;
        border: none;
        background-color: white;
        padding: 5px 0 0 0;
    }

    .big-cell {
        width: 30%;
        text-align: left;
        min-height: 1px;
    }

    .big-cell-header {
        width: 37%;
        text-align: left;
        min-height: 1px;
    }

    .owner-cell {
        width: 20%;
        text-align: right;
        min-height: 1px;
    }

    .owner-cell-header {
        width: 20%;
        text-align: center;
        min-height: 1px;
    }

    .icon-cell {
        width: 15%;
        text-align: left;
        float: left;
    }

    .small-cell {
        width: 10%;
        text-align: right;
        float: left;
    }

    .ownership {
        width: 133px;
        text-align: left;
    }

    .padding-left-5 {
        padding-left: 5px;
    }
}

.border-wrapper {
    margin-bottom: 1em !important;
}

.customers-properties-container {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;

    .display-flex {
        display: flex;

        .margin-left-auto {
            margin-top: 0px;
        }
    }

    .partial-header {
        max-height: 32px;
        padding-top: 1px;
    }

    .scrollable-container-estates {
        max-height: 256px;
        overflow: auto;
        width: calc(100% - 1px); // fix scrollbar popping over border
    }

    .scrollable-container-persons {
        max-height: 256px;
        overflow: auto;
        width: calc(100% - 1px); // fix scrollbar popping over border
    }

    > .real-estates-footer {
        bottom: 50px;
        padding: 0 0.5em;
        position: absolute;
        text-align: right;
        width: 100%;
    }

    &.is-timber-trade {
        .scrollable-container-estates, .scrollable-container-persons {
            max-height: 354px;
        }
    }
}

.no-top-margin {
    margin-top: 0 !important;
}

.customers-properties-row {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 0;

    > div {
        border-left: 1px solid $gray;
        padding: 4px;
        flex: 1;

        &:first-of-type {
            border-left: 0px;
            padding-left: 0.5em;
        }

        &:last-of-type {
            padding-right: 0.5em;
        }

        &.row-separator {
            max-width: 15px;
            text-align: center;
        }
    }

    &.filler {
        flex: 1;
    }

    &:hover:not(.filler),
    &.selected {
        @extend %selected-row;
    }
}

.customers-properties-row-updated {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    > div {
        border-left: 1px solid $gray;
        padding: 4px;

        &:first-of-type {
            border-left: 0px;
            padding-left: 0.5em;
        }

        &:last-of-type {
            padding-right: 1em;
        }

        &.row-separator {
            max-width: 15px;
            text-align: center;
        }
    }

    &.filler {
        flex: 1;
    }

    &:hover:not(.filler),
    &.selected {
        @extend %selected-row;
        img {
            filter: invert(1);
        }
    }

    .big-cell {
        width: 45%;
    }

    .small-cell {
        width: 10%;
        text-align: right;
    }
}
