﻿.storages-index-map-container {
    width: 100%;
}
.storages-index-map {
    .topic-row {
        text-align:center;
        height:30px;
        width:30px;
        display:table;
        border-radius: 0px 0px 0px 0px; 
        margin-bottom:0px;
        opacity:1.0;
    }

    .bubble-topic-text {
        text-align:center;
        margin:3px;
        min-width:70px;
        white-space:nowrap;
        display:inline;
        float:left;
        overflow:hidden;
    }

    .highlighted-topic-row {
        text-align:center;
        height:30px;
        width:40px;
        display:table;         
        margin-bottom:0px;
        opacity:1.0;
    }

    .highlighted-topic-image {
        display:table-cell;
        vertical-align:middle;
        height:30px;
        width:40px;
        padding-left:4px;
        padding-right:4px;
        margin-bottom:1px;
    }

    .highlighted-topic-text {
        text-align:center;
        margin:3px;
        margin-left: 10px;
        min-width:120px;
        white-space:nowrap;
        display:inline;
        float:none;
        overflow:hidden;
    }

    .bubble-content-text {
        text-align:left;
        margin-left:5px;
        margin-right:5px;
        margin-top:0px;
        margin-bottom:0px;
        min-width:60px;
        font-size:small
    }
}