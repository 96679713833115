﻿@import '../../shared/sass/_variables.scss';

.forestry-work-chains {

    input[type="radio"] {
        box-shadow: none;
    }

    .line-height {
        line-height: 20px;
    }

    .selected-definition-label {
        line-height: 12px;
        margin-top: 0.2em;
    }

    .radio-group {
        margin-top: 5px;
    }

    .radio-col {
        margin-top: 2px;
    }

    .right-align {
        text-align: right;
        font-size: 12px;
        background-color: initial;
    }

    .navigation-grid {
        height: 629px;
    }

    .timber-info-grid {
        height: 420px;
    }

    .buttons-group {
        background-color: #D1D1D1;
        padding: 10px;
    }

    .timberBuyer-panel {
        border: 1px solid #616161;
        border-radius: 0.5em;
        overflow: hidden;
    }

    .content {
        padding: 10px;
        padding-left: 0;

        [class^="col"] {
            padding-left: 0px;
            padding-right: 0px;
        }

        .same-row {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    input[type=radio] {
        margin-top: 0px;
        margin-bottom: 0px;
        height: initial;
        box-shadow: none;
    }

    .combo-padding {
        padding-left: 5px;
        float: right;
    }

    .float-left {
        text-align: left;
    }

    .right-panel {
        margin-left: 5px;

        .panel-header {
            background: $grid-header-background;
            text-transform: uppercase;
            font-family: "Myriad Pro Semibold";
            border-radius: 0.5em 0.5em 0 0;
            padding: 3px 5px;
        }
    }

    .right-detail-panel {
        .content {
            padding: 6px 2px;

            input {
                display: inline-block;
                width: initial;
                padding-right: 5px;
                vertical-align: middle;
            }
        }
    }

    .white-on-black-header {
        background-color: black;
        color: white;
        text-transform: none;
        font-family: "Myriad Pro Semibold";
        padding: 0.5em;
        width: 100%;
        margin-bottom: 0px;
    }

    .no-margin-bottom {
        margin-bottom: 0px !important;
    }

    .navigation-buttons {
        display: inline-block;

        span {
            vertical-align: middle;
        }

        .button {
            color: rgb(209,209,209);
        }

        .glyphicon {
            font-size: 16px;
        }

        [disabled] {
        }

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-top: 8px;
    }

    .valittu-select {
        width: 100%;
    }

    .ag-cell {
        //overflow: visible;
        select:disabled {
            background-color: #d1d1d1;
        }

        .simpleDropDown {
            border-color: #D1D1D1;
            width: 500px;
            color: black;
            border-radius: 3px;            
            margin-right: 10px;
        }

        .dropdown-right-align {
            width: 40%;
            float: right;
        }


        input, select, input[type="text"], input[type="number"] {
            color: inherit;
            background-color: inherit;
        }
    }

    .padding-zero {
        padding: 0px !important;
    }

    .form-horizontal {
        .left-detail-panel {
            .content {
                border-right: 1px solid #D1D1D1;
            }
        }

        .right-detail-panel {
            .form-group {
                padding-top: 3px;
                padding-bottom: 3px;

                .control-label {
                    padding-top: 0px;
                }
            }
        }
    }    

    .mandatory-field {
        color: $trimble_tf_product_green;
    }

    .dropDownMinWidth250px {
        .dropdown-menu {
            min-width: 250px;
        }
    }
}
