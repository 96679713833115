@import '../../../../shared/sass/_variables.scss';

.add-wood-assortments-modal-window {
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .execute-button {
        margin-left: 5px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .search-bar {
        border: 1px solid $gray;
        border-radius: 0.5em;
        height: 2em;
        line-height: 2em;
        overflow: hidden;
        padding: 0 0.4em;
        margin: 1em;

        input {
            border: 0;
            height: 2em;
            outline: 0;
            padding-left: 0.2em;
            width: 95%;
        }
    }

    .grid-container {
        margin: 1em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            border-top-left-radius: 0em;
            border-top-right-radius: 0em;
            height: 300px;

            #centerRow {
                border-top-left-radius: 0em !important;
                border-top-right-radius: 0em !important;
                border-bottom-left-radius: 0.5em !important;
                border-bottom-right-radius: 0.5em !important;
            }

            .ag-header-group-cell-label {
                text-transform: uppercase;
            }

            .no-ellipsis {
                text-overflow: unset;
            }

            .ag-bl-center-row {
                border-bottom-left-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
            }
        }
    }

    .form-group {
        padding: 0;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .sp-grid {

        .grid-check-box {
            top: -9px;
        }

        .ag-header-cell {
            //text-align: center;
            border-right: none;
        }
    }

    .ag-header-container {
        border-right: none;
        border-bottom: none;
    }

    .align-left {
        text-align: left !important;
    }

    .no-right-border {
        border-right: none !important;
    }

    .modal-top-round-border {
        border: 1px solid rgb(97,97,97);
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow: hidden;
    }

    .modal-header-padding {
        padding-top: 3px;
        width: 100%;
        text-align: center;
    }

    .modal-panel-header {
        background: $grid-header-background;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;        
    }
}
