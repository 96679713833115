
@media(max-width: $media-mobile){

	.container {
		padding: 0px;
	}

	.mobile-hide {
		display:none !important;
	}


}

@media(min-width: $media-mobile){

	.mobile-show {
		display: none !important;
	}

}