.map-measure {
	.header-wrapper {
		border-bottom: 1px solid $darkgray;
	}
	
	.content {
		margin: 0;
		line-height: 1.3em;
	}
	
	.row {
		display: flex;
		flex-flow: row;
		border-bottom: 1px solid $gray;
		margin: 0;
		&.last-content-row {
			border-bottom: none;
		}
	}
	
	.block {
		padding: .5em 1em;
	}

	.col {
		&:not(:first-child) {
			padding-left: .5em;
		}
		
		&.wrapper {
			display: flex;
			flex: 1;
			border-left: 1px solid $gray;
			&:first-of-type {
				border-left: 0;
			}
		}
	}
	
	.icon {
		width: 1.3em;
	}

	.copy-icon-wrap {
		flex: 1;
		margin-right: 0.1em;
		height: 1em;
		align-self: flex-end;
	}
	
	.copy-icon {
		width: .8em;
		margin-bottom: .2em;
	}

	.map-measure.bottom-buttons {
		border-top: 1px solid $darkgray !important;
		background: $gray;
		overflow: hidden;
		justify-content: center;
		padding: .2em;
		border-bottom-left-radius: 0.5em;
		border-bottom-right-radius: 0.5em;
		margin: 0;
		>.wrapper {
			flex: 1;
			justify-content: center;
			align-items: center;
			padding: .5em;
			border-left-color: transparent;//Dont show border between buttons
			>.btn {//Make the buttons a bit smaller
				padding-top: .4em;
				padding-bottom: .4em;
			}
		}
	}

}