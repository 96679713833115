// refactored this sass file 25.4. as part of splitting the panel to two tabs
// In the future the panel should be refactored further by splitting all the various tools to separate
// directives.
@import '../../../shared/sass/_variables.scss';

.large-dataset-notification {
    position: absolute;
    min-width: 300px;
    height: 40px;
    background-color: white;
    border: 1px solid $darkgray;
    border-radius: 0.5em;
    left: 50%;
    opacity: 0.9;
    line-height: 38px;
    z-index: 1000;
    margin-top: 0.5em;
    text-align: center;
}

.map-drawEditor {
    .large-dataset-notification {
        position: fixed;
        min-width: 300px;
        height: 40px;
        background-color: white;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        left: calc(50% - 150px);
        opacity: 0.6;
        line-height: 38px;
    }

    .large-dataset-text {
        text-align: center;
        width: 100%;
        height: 100%;
    }

    .working-sites-marking {
        display: flex;
        flex-grow: 1;
        min-width: 300px;
        height: 100%;
    }

    .info-img {
        width: 17px;
        height: 17px;
        margin-right: 6px;
    }

    .exclusion-check {
        margin-bottom: 2px !important;
        font-weight: bold;
    }

    .exclusion-check-label {
        font-weight: bold;
    }

    .exclusion-options {
        padding-left: 15px;
    }

    .exclusioncontent {
        display: flex;
        flex-direction: column;
        flex-basis: 35px;
        flex-grow: 1;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-top: 0px;
        width: 300px;

        .bottom-buttons {
            border-top: 1px solid $darkgray !important;
            background: $gray;
            overflow: hidden;
            justify-content: center;
            padding: .2em;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;

            > .wrapper {
                flex: 1;
                justify-content: center;
                align-items: center;
                padding: .5em;
                border-left-color: transparent;

                > .btn {
                    padding-top: .4em;
                    padding-bottom: .4em;
                    width: 273px;
                }
            }
        }

        .col {
            width: 80px;
            height: 20px;

            &.wrapper {
                display: flex;
                flex: 1;
                border-left: 1px solid $gray;
                padding-left: 8px;
                padding-right: 8px;

                &:first-of-type {
                    border-left: 0;
                }
            }
        }

        .block {
            padding: .5em 1em;
        }

        .row {
            display: flex;
            flex-flow: row;
            margin: 0px;
            padding: 0px;
            height: 10px;

            &.last-content-row {
                border-bottom: none;
            }
        }

        .col-xl-2 {
            padding-right: 0px;
            padding-left: 6px;
            width: 180px;
        }

        .colx-xs-2 {
            padding-right: 0px;
            padding-left: 6px;
            padding-top: 8px;
            width: 90px;
        }

        .colx-xs-1 {
            padding-right: 0px;
            padding-left: 6px;
            padding-top: 10px;
            margin-left: 10px;
            width: 20px;
        }

        .format-data {
            width: 100%;
            background-color: inherit;
            border: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .align-right {
            text-align: right;
            margin-left: -4px
        }

        .table-header {
            background: $gray;
            justify-content: center;
            padding: .2em;
            padding-top: 10px;
            padding-bottom: 10px;
            height: 50px;

            img {
                height: 2.1em;
                padding-right: 3px;
                cursor: pointer;
            }

            .align-totals {
                padding-left: 8px;
                padding-top: 6px;
            }
        }

        .warning-message {
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 5px;

            span {
                font-style: italic;
                display: block;
            }
        }

        .selected-compartments {
            padding-top: 0px;
        }

        .header-padding {
            padding-left: 10px;
            padding-right: 10px;
        }

        .header-padding-s {
            padding-left: 0px;
            padding-right: 10px;
        }

        .cell-padding {
            padding-left: 10px;
            padding-right: 10px;
        }

        .cell-padding-s {
            padding-left: 0px;
            padding-right: 10px;
        }

        .no-padding {
            padding-left: 0px;
            padding-right: 0px;
        }

        .remove-padding {
            padding-right: 10px;
        }

        .truncate {
            text-overflow: ellipsis;
            width: 130px;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 200;
        }

        .kha-font {
            font-weight: 200 !important;
        }

        .scrollable-area {
            overflow: auto;
            overflow-y: hidden !important;
        }
    }


    &.panel {
        .forestplan-selection-section {
            padding: .5em;
            font-weight: 700;
            border-top: 1px solid #d1d1d1;
            // Styling for the text column
            .col:first-child {
                padding-top: .5em;
                text-align: left;
            }

            .col {
                text-align: center;
                padding: 0 .25em;
            }
        }

        .form-control {
            height: 27px;
            padding: 0.2em;
        }

        textarea.form-control {
            height: auto;
        }
        // override the default styling
        top: 0.2em;
        left: 0.2em;
        bottom: 0.2em;
        margin: 0;
        display: flex;
        flex-direction: column;
        flex-basis: 35px; // this is the minimum height of an element
        border: 0;
        background-color: transparent;
        overflow-y: auto;
        overflow-x: hidden;
        pointer-events: all;
        min-height: 100px;
        //styles for the panel header tabs
        .multiple-panel-headers {
            pointer-events: all;
            display: flex;
            min-height: 35px;
            max-height: 35px;
            height: 35px;
            z-index: 10;

            .tab-wrapper {
                pointer-events: all;
                line-height: 35px; // set line-height to fill the space of the tab
                border: 1px solid $darkgray;
                border-bottom: 1px solid white;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                background-color: white;
                margin-left: 0.25em;
                padding-left: 2.5em;
                padding-right: 1em;
                vertical-align: middle;
                position: relative;
                cursor: pointer;

                &:not(.active) {
                    background-color: $lightgray;
                    border-bottom: 1px solid $darkgray;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    flex-grow: 1; //the last tab will fill the empty space
                }

                .icon {
                    height: 21px;
                }

                img {
                    position: absolute;
                    max-width: 1.5em;
                    left: 1em;
                    top: 0.5em;
                }
            }
        }

        .interrupt-element-spacing {
            margin-bottom: 0px;
        }
        // styles for the interrupt element right after the tab block
        .interrupt-element {
            pointer-events: all;
            position: relative;
            top: -1px; //move up one pixel, under the tabs, to create a continuous border between the tabs
            min-height: 35px;
            max-height: 35px;
            height: 35px;
            display: flex;
            width: 100%;

            .interrupt-block {
                background-color: white;
                padding-left: 2.5em;
                border: 0;
                border: 1px solid $darkgray;
                border-bottom: 0;
                border-right: 0;
                margin: 0;
                z-index: 1;
                position: relative;
                height: 35px;
                line-height: 35px;
                vertical-align: middle;
                flex-grow: 1;

                &.hide-guides-block {
                    padding-left: 0.5em;
                    border-left: 0;
                    text-align: right;

                    [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
                        padding-left: 15px;
                    }

                    &:hover {
                        background-color: $white;
                        color: $black;
                    }
                }

                .end-drawing {
                    border: 1px solid $gray;
                    padding: 4px;
                    border-radius: 5px;
                    position: absolute;
                    left: 0.5em;
                    top: calc(50% - 12px);
                    height: 24px;
                }

                &:hover {
                    background-color: $black;
                    color: $white;
                    border-color: $black;

                    .end-drawing {
                        color: $white;
                        background-color: $black;
                    }
                }
            }

            .close-button {
                height: 100%;
                padding: 0 0.5em;
                line-height: 14px;
                display: flex;
                align-items: center;
                z-index: 1;
                background-color: $white;
                border: 1px solid $darkgray;
                border-bottom: 0;
                border-left: 0;
            }
        }

        .content-markings {
            pointer-events: all;
            flex-grow: 1; // content fills the rest of the container
            margin: 0;
            overflow: hidden;
            background-color: white;
            border: 1px solid $darkgray;
            border-top: 0;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-bottom: 0px;
        }
        // general styles for content, everything else should be contained to content block
        .content {
            pointer-events: all;
            flex-grow: 1; // content fills the rest of the container
            margin: 0;
            overflow: hidden;
            background-color: white;
            border: 1px solid $darkgray;
            border-top: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            top: -1px;
            position: relative;
            display: flex;
            flex-direction: column;

            .conditional-border-bottom {
                border-bottom: 1px solid $gray;
            }
            // general structure indicates that content is followed by a number of content-elements
            .content-element {
                display: block;
                margin: 0;
                padding: 0;
                border-top: 1px solid $gray;
                display: flex;
                flex-direction: column;
                min-height: 2.5em;

                &.entry-content {
                    border-top: none;
                }
                // styles for the tool selections
                .draw-editor-tool-type {
                    width: 100%;
                    padding: 0.5em;
                    margin: 0;
                    min-height: 2.5em;
                    max-height: 2.5em;
                    text-transform: uppercase;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    .type-text {
                        flex-grow: 1;
                        position: relative;
                        top: 1px;
                        left: 10px;
                    }

                    input[type="radio"] {
                        display: none;
                    }

                    &:hover {
                        background-color: $black;
                        color: $white;

                        .glyphicon-play {
                            color: $white;
                        }
                        // white icons on hover on content-element-icons
                        .content-element-icon {
                            &.add-new-stand {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_AddNewStandIcon_Selected.svg');
                            }

                            &.change-stand-number-position {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_ChangeStandNumberPositionIcon_Selected.svg');
                            }

                            &.enlarge-stand {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_EnlargeStandIcon_Selected.svg');
                            }

                            &.divide-stand {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_DivideStandIcon_Selected.svg');
                            }

                            &.combine-stand {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_CombineStandsIcon_Selected.svg');
                            }

                            &.remove-area {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_RemoveAreaIcon_Selected.svg');
                            }

                            &.remove-stand {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_DeleteStandIcon_Selected.svg');
                            }

                            &.exclusion-content {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_AdditionalAreaLikeMapObjectIcon_Selected.svg');
                            }

                            &.edit-stand-borders {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_EditStandBordersIcon_Selected.svg');
                            }

                            &.photo-icon {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_PhotoIcon_Selected.svg');
                            }
                        }
                    }

                    .content-element-icon {
                        position: relative;
                        width: 24px; // this is to align the images with the close button which is 24 x 24 pixels, sorry for absolute :/
                        height: 1.5em;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        margin: 0;

                        &.add-new-stand {
                            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_AddNewStandIcon.svg');
                        }

                        &.change-stand-number-position {
                            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_ChangeStandNumberPositionIcon.svg');
                        }

                        &.enlarge-stand {
                            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_EnlargeStandIcon.svg');
                        }

                        &.divide-stand {
                            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_DivideStandIcon.svg');
                        }

                        &.combine-stand {
                            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_CombineStandsIcon.svg');
                        }

                        &.remove-area {
                            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_RemoveAreaIcon.svg');
                        }

                        &.remove-stand {
                            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_DeleteStandIcon.svg');
                        }

                        &.exclusion-content {
                            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_AdditionalAreaLikeMapObjectIcon.svg');
                        }

                        &.edit-stand-borders {
                            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_EditStandBordersIcon.svg');
                        }

                        &.photo-icon {
                            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_PhotoIcon.svg');
                        }
                    }

                    .glyphicon-play {
                        color: $black;
                    }
                }

                .inside-list {
                    overflow-y: auto;
                    overflow-x: hidden;
                    flex-grow: 1;
                }

                &:hover {
                    border-color: $black;
                }

                &.active {
                    flex-grow: 1;
                    border-bottom: 0;
                    border-color: $black;
                    // the selected one is black;
                    .draw-editor-tool-type {
                        background-color: $black;
                        color: $white;
                        // turn the play of the selected one 90 degrees
                        .glyphicon-play {
                            transform: rotate(90deg);
                            color: $white !important;
                        }
                        // white icons on .active on content-element-icons
                        .content-element-icon {
                            &.add-new-stand {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_AddNewStandIcon_Selected.svg');
                            }

                            &.change-stand-number-position {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_ChangeStandNumberPositionIcon_Selected.svg');
                            }

                            &.enlarge-stand {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_EnlargeStandIcon_Selected.svg');
                            }

                            &.divide-stand {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_DivideStandIcon_Selected.svg');
                            }

                            &.combine-stand {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_CombineStandsIcon_Selected.svg');
                            }

                            &.remove-area {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_RemoveAreaIcon_Selected.svg');
                            }

                            &.remove-stand {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_DeleteStandIcon_Selected.svg');
                            }

                            &.exclusion-content {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_AdditionalAreaLikeMapObjectIcon_Selected.svg');
                            }

                            &.edit-stand-borders {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_Stand_EditStandBordersIcon_Selected.svg');
                            }

                            &.point-icon {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_PointIcon_Selected.svg');
                            }

                            &.line-icon {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_LineIcon_Selected.svg');
                            }

                            &.text-icon {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_TextIcon_Selected.svg');
                            }

                            &.photo-icon {
                                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_PhotoIcon_Selected.svg');
                            }
                        }
                    }

                    div:not(.inside-list) {
                        margin-bottom: 1em;

                        &:first-child {
                            margin-top: 0.5em;

                            &.help-topic {
                                margin-top: 0;
                            }

                            &.help-topic-black {
                                margin-top: 0;
                                margin-bottom: 0;
                            }
                        }
                    }

                    textarea {
                        margin-top: 0;
                    }
                }
                // since all of the shown content is inside content elements label or content divs
                // we can apply universal padding here
                & > div, & > form {
                    padding: 0 0.5em;
                }
                // since text-form contains an example container that doesn't follow universal padding:
                & > form.text-form {
                    padding: 0;

                    > div {
                        padding: 0 0.5em;

                        &.text-example-container {
                            padding: 0;
                        }
                    }
                }
                // different buttons
                .dual-button {
                    display: flex;
                    align-items: center;

                    .btn {
                        flex-grow: 1;

                        &:first-child {
                            margin-right: 0.5em;
                        }
                    }
                }

                .single-button {
                    width: 100%;
                    display: block;
                }

                .single-button:not(:last-child) {
                    margin-bottom: 0.5em;
                }

                .btn {
                    white-space: normal;
                }

                .btn.btn-silva[disabled] {
                    color: $white;
                    border-color: $gray;
                }
                // tool options (lists of radio buttons basically)
                .tool-options {
                    input[type="radio"] {
                        margin-top: 0;
                        flex-basis: 2em;
                    }

                    .drawing-selection-text {
                        margin-left: 0.5em;
                        margin-bottom: 0.5em;
                        display: inline-block;
                    }

                    label {
                        display: block;
                        display: flex;
                        align-items: center;

                        .logo {
                            margin-right: 0.5em;
                            width: 4em;
                            position: relative;
                        }

                        .arealogo {
                            margin-right: 0em !important;
                            margin-left: 1em;
                            padding-left: 0em;
                            width: 100% !important;
                            height: 40px !important;
                            line-height: 35px;
                            position: relative;
                        }

                        div.logo {
                            display: flex;
                            align-items: center;
                            height: 1em;
                            overflow: hidden;
                            margin: 0;
                            margin-right: 0.5em;

                            .free-line {
                                width: 100%;
                                height: 0;
                                margin: 0;
                                padding: 0;
                                display: inline;
                            }

                            .free-area {
                                width: 270px;
                                height: 30px;
                                margin: 0;
                                padding: 0;
                                display: inline;
                                text-align: center;
                            }
                        }
                    }
                }

                .text-options {
                    // fix for checkbox positioning in form
                    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
                        top: 0;
                    }

                    [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
                        top: 3px;
                    }

                    label {
                        text-transform: uppercase;
                    }

                    div {
                        display: flex;
                        width: 100%;
                        margin: 0;
                        align-items: center;

                        label, input, select {
                            flex-grow: 1;
                            flex-basis: 0;
                        }
                    }

                    .text-example {
                        padding: 0.5em;
                        background-color: $lightgray;
                        border: 1px solid $gray;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;
                    }
                }


                .add-photograph-button {
                    text-transform: uppercase;

                    &:hover {
                        cursor: pointer;

                        .glyphicon-plus:not(.disabled) {
                            background-color: $black;
                            color: $white;
                            border-color: $black;
                        }
                    }

                    .glyphicon-plus {
                        width: 24px;
                        height: 24px;
                        border: 1px solid $darkgray;
                        border-radius: 5px;
                        padding: 3px 5px;
                    }

                    .disabled {
                        .glyphicon-plus {
                            background-color: $lightgray;
                            color: $darkgray;
                            border-color: $gray;
                        }
                    }
                }

                .photo-list {
                    div {
                        display: flex;
                        margin-left: -0.25em;
                        margin-right: -0.25em;

                        img {
                            width: 50%;
                            padding: 0.25em;
                            max-height: 150px;
                        }
                    }
                }

                .photo-helper-text {
                    font-size: 85%;
                    font-style: italic;
                    color: $darkgray;
                }
            }

            .marking-tool-buttons {
                display: flex;

                button {
                    text-align: center;
                    flex-grow: 1;
                    margin-right: 0.5em;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .panel-footer {
                background-color: $lightgray;
                padding: 0.5em;
                border-top: 1px solid $gray;
                order: 3;

                button {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .content.feature {
            .content-element {
                .inside-list {
                    padding: 0;

                    &.entry-content {
                        padding: 7px;
                    }
                    // related to workflow numbering and help dialogues
                    .help-topic {
                        background-color: $gray;
                        color: $white;
                        padding: 0.5em;
                        padding-left: 2em;
                        margin: 0;
                        margin-bottom: 0.5em;
                    }

                    .help-topic-black {
                        background-color: $gray;
                        color: $black;
                        font-weight: bold;
                        padding: 0.5em;
                        padding-left: 0.8em;
                        margin: 0;
                        margin-bottom: 0.5em;
                    }

                    .clear-exclamation-targets {
                        cursor: pointer;
                    }

                    .help {
                        display: flex;
                        flex-direction: column;
                        color: $gray;
                        padding-left: 2em;
                        padding-right: 0.5em;
                        min-height: 3em;
                        position: relative;
                        border-left: 0.3em solid $gray;
                        margin-top: 0.5em;

                        &.single-item {
                            flex-direction: row;
                            align-items: center;
                        }

                        .help-order-number {
                            color: $white;
                            position: absolute;
                            left: -0.3em; // correction for border
                            height: 100%;
                            width: 1em;
                            height: 1em;
                            text-align: center;
                            border-top: 1em solid transparent;
                            border-bottom: 1em solid transparent;
                            border-left: 1.6em solid $gray;
                            top: calc(50% - 1.5em); // scale to middle of the container
                            span {
                                position: absolute;
                                left: -1.3em;
                                top: -0.55em;
                            }
                        }

                        & > div:last-child {
                            margin: 0;
                        }

                        label {
                            padding-left: 0.5em;
                        }

                        .feature-number {
                            .feature-number-fields {
                                display: flex;
                                align-items: flex-end;
                                padding: 0;
                                margin-bottom: 0.5em;

                                .feature-number-fields-separator {
                                    width: 1em;
                                    text-align: center;
                                    margin: 0;
                                }

                                input[disabled], select[disabled] {
                                    background-color: $white;
                                    border: 2px solid $gray;
                                    color: $gray;
                                }

                                .stand-number {
                                    margin: 0;
                                }
                            }

                            label {
                                padding-left: 0;
                            }
                        }
                    }

                    .help.active {
                        color: $black;

                        .help-order-number {
                            border-left-color: $blue;
                        }
                    }

                    .input-footnote {
                        font-size: 85%;
                        font-style: italic;
                    }

                    .bottomrow {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-top: 1px solid $darkgray !important;
                        background: $lightgray;
                        overflow: hidden;
                        justify-content: center;
                        padding: .8em;
                        margin-bottom: 0px;
                        border-bottom-left-radius: 0.5em;
                        border-bottom-right-radius: 0.5em;

                        > .btn {
                            padding-top: .1em;
                            padding-bottom: .1em;
                        }
                    }

                    .exclusion-select {
                        border-top: 1px solid $gray;
                        padding-left: 0.5em;
                    }

                    .flex-button-row {
                        display: flex;
                        width: 100%;

                        button {
                            flex-grow: 1;

                            &:not(:last-child) {
                                margin-right: 0.25em;
                            }

                            &:not(:first-child) {
                                margin-left: 0.25em;
                            }
                        }
                    }

                    .copy-feature-selection {
                        display: flex;
                        align-items: center;
                        margin: 0;

                        .copy-feature-label {
                            text-transform: none;
                        }

                        &:last-child {
                            margin-bottom: 1em;
                        }

                        label, input {
                            margin: 0;
                        }

                        &.margin-bottom {
                            margin-bottom: 1em;
                        }
                    }
                    // fix for checkbox positioning in form
                    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
                        top: 0;
                    }

                    [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
                        top: 3px;
                    }

                    .checkbox {
                        display: flex;
                        align-items: center;

                        label:last-child {
                            margin-left: 0.5em;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    &.shrinking-panel {
        bottom: auto;
        max-height: 100%;
    }
}