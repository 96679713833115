.inventory-statuses {
    .list-view-settings-row {
        margin-bottom: 0;

        .left-settings {
            .advanced-filtering-dropdown {
                .dropdown-content {
                    width: 630px;
                    //height: 386px;

                    .multiselection-drop-down .checkboxes {
                        width: 232px;
                    }
                }

                .has-extra-row {
                    height: 395px;
                }
            }

            .view-option-btn .btn {
                width: auto;
            }
        }
    }
}