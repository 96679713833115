﻿@import '../../../../shared/sass/_variables.scss';

.manage-attachment-dialog-window {
    .modal-dialog {
        top: 15%;
        width: 1000px;
        height: 660px;
    }
}

.attachment-delete-dialog-btn-class {
    padding-right: 0px !important;
    padding-left: 0px !important;

    .btn:first-child {
        margin-right: 52% !important;
    }

    .btn:last-child {
        margin-left: 0px !important;
    }
}

.manage-attachment-dialog {
    .modal-dialog {
        top: 15%;
        width: 1000px;
        height: 660px;
    }

    .modal-trimble-title {
        margin-left: 20px;
    }

    .attachment-type {
        background-color: #E2E2E7;
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .attachment-button-container {
        height: 65px;
    }


    .manage-attachment-dialog-content {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;

        .attachment-search-bar {
            border: 1px solid $gray;
            border-radius: 0.5em;
            height: 2em;
            line-height: 2em;
            overflow: hidden;
            margin-top: 20px;
            margin-bottom: 20px;

            input {
                border: 0;
                height: 2em;
                outline: 0;
                padding-left: 0.3em;
                width: calc(100% - 50px);
            }

            span.glyphicon-remove {
                margin-right: 0px;
                margin-left: 5px;
            }

            span.glyphicon {
                margin-right: 0px;
                margin-left: 5px;
            }
        }


        .margin-right-15 {
            margin-right: 15px
        }

        .attachment-checkbox-cell {
            padding-left: 7px;
            padding-top: 4px;
        }

        .attachment-cell-align-center {
            padding-top: 5px;
        }

        .attachment-preview-cell {
            padding-right: 2px !important;
            padding-top: 0px;
            text-align: center;
        }

        .attachment-preview-header-cell {
            padding-top: 0px;
            text-align: center;
        }

        .attachment-grids {
            .grid-container {
                margin: 15px 0px 0px 0px;
                border: 1px solid grey;
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);

                .sp-grid .ag-floating-bottom-container .ag-cell {
                    background-color: #d1d1d1;
                    padding-top: 6px;
                }

                .ag-header-cell .select-all-checkbox {
                    .select-all-checkbox {
                        left: 4px;
                    }
                }

                .select-all-wrapper {
                    left: 5px;
                    top: -3px;
                }

                .sp-grid .ag-root.ag-root {
                    border-radius: 4px !important;
                }


                .delete-button {
                    width: 12em;
                    margin-left: 9em;
                }
                .ag-header-icon {
                    margin-top:6px;
                }
            }



            .ag-theme-fresh .ag-header-cell-label {
                padding: 6px 2px;
            }

            .grid-header-checkbox-label {
                margin: 0;
                left: -4px;
                top: 2px;
            }

            .ag-floating-bottom-container {
                font-size: 12px;
                font-weight: bold;
            }

            .ag-cell {
                [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                    top: 5px !important;
                }

                [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                    top: 2px !important;
                }
            }

            .cell-align-center {
                text-align: center;
            }
            
            .sp-grid {
                .cell-aligned-left {
                    .ag-header-group-cell-label {
                        text-align: left;
                        padding-left: 4px;
                    }
                }
            }

            .button-container {
                padding: 5px;
                background-color: #e8e8e8;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;


                .btn {
                    width: 16%;
                    margin: 4px;
                    height: 30px !important;
                    padding: 0px;
                }

                .update-btn {
                    width: 296px;
                }
            }

            .button-container-grid {
                padding: 10px;
                background-color: #e8e8e8;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }
    }
}
