﻿@import '../../../shared/sass/_variables.scss';

.payment-date-compnent {

    .margin-top-15 {
        margin-top: 15px;
    }

    .payment-date-container {
        text-align: center;
        margin: 15px 0px;

        .content {
            background-color: white;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #B0AFB0;
            border-radius: 3px;
            display: inline-block;

            .panel-header {
                background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
                border-radius: 0 0 0 0;
                padding: 5px 4px;
                height: 25px;
                border-bottom: 1px solid #808080;
            }

            .current-month {
                border: solid 1.5px #009ad9;
                padding-left: 7px;
                padding-bottom: 7px;
                border-radius: 5px;
            }

            .display-flex-wrap {
                display: flex;
                flex-wrap: wrap;

                .uib-datepicker .uib-daypicker {

                    .uib-title {
                        border-color: white;
                    }

                    .uib-weeks .uib-day .btn:disabled {
                        /*font-size: 14px;*/
                        font-weight: normal;
                        color: gray !important;
                        background-color: #fff;
                    }

                    .uib-weeks .uib-day {
                        span.text-info {
                            font-weight: bold;
                            color: $trimble_blue_mid;
                        }

                        .btn:enabled {
                            /*font-size: 14px;
                   font-weight: bold;*/
                            color: $trimble_black;
                            background-color: #3AAE2C;
                        }

                        .btn:enabled:hover {
                            color: $trimble_black;
                            background-color: #ff9400;
                        }
                    }

                    .uib-weeks .uib-day:hover {
                        /*font-size: 14px;
                   font-weight: bold;*/
                        color: deeppink !important;
                    }

                    .uib-weeks .uib-day .btn-default {
                        border-color: white;
                    }

                    .uib-weeks .h6 {
                        background-color: $lightgray;
                        width: 22px;
                        /*padding-left: 2px;*/
                        padding-right: 4px;
                    }
                }
            }

            .btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
                color: #fff;
                background-color: #31b0d5 !important;
                border-color: #269abc;
                border: 1px solid $trimble_black !important;
            }



            .selected-date {
                .btn {
                    /*height: 36px !important;*/
                    background-color: #3AAE2C !important;
                    /*opacity: 0.65;*/
                    padding: 5px;
                    /*border-radius: 50% !important;*/
                    display: inline-block !important;
                }

                .btn:hover {
                    /*height: 36px;*/
                    background-color: $orange !important;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }
            }

            .selected-date:hover {
                .btn:hover {
                    /*height: 36px;*/
                    background-color: $orange !important;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }

                .active:hover {
                    /*height: 36px;*/
                    background-color: $orange;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }

                .active {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }
            }

            .btn.active {
                -webkit-box-shadow: none;
                box-shadow: none;
            }

            .non-selected-date {
                .btn {
                    /*height: 36px;*/
                    background-color: $white;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }
            }

            .non-selected-date:hover {
                .btn:hover {
                    /*height: 36px;*/
                    background-color: #3AAE2C;
                    color: $trimble_black;
                    opacity: 0.65;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }
            }

            .selected button {
                color: #fff;
                background-color: #31b0d5 !important;
                border-color: #269abc;
                border: 1px solid $trimble_black !important;
            }

            .calendar-container {
                padding: 20px;

                .box-sizing-unset {
                    box-sizing: unset;
                }

                .month-title {
                    font-size: 15px;
                    line-height: 1.5;
                }

                .align-center {
                    text-align: center;
                }

                .calendar {
                    margin: 15px;
                }

                .text-muted {
                    color: transparent;
                    display: none;
                }

                .today-date {
                    .text-info {
                        text-align: center;
                        letter-spacing: 0px;
                        color: #005F9E;
                        font-weight: bold;
                        opacity: 1;
                    }

                    .btn {
                        /*height: 36px !important;*/
                        /* background-color: #009AD9;
                    color: black;*/
                        color: #009AD9;
                        font-weight: bold;
                        /*border-radius: 50% !important;*/
                        display: inline-block !important;
                    }
                }
            }

            .accountperiod-container {

                .grids .grid-border .ag-cell [type="checkbox"]:not(:checked) + label::after, .grids .grid-border .ag-cell [type="checkbox"]:checked + label::after {
                    top: 0px !important;
                    width: 20px;
                    height: 20px;
                }

                .grids .grid-border .ag-cell .col-xs-1 {
                    width: 55px;
                }

                .grids .grid-border .ag-cell [type="checkbox"]:not(:checked) + label::before, .grids .grid-border .ag-cell [type="checkbox"]:checked + label::before {
                    top: 2px !important;
                }

                .grid-header-checkbox-label {
                    left: -4px;
                }

                .material-switch-container .material-switch-content > input[type="checkbox"]:checked + label::after {
                    background: #3AAE2C;
                    left: 20px;
                }

                .material-switch-container .material-switch-content > input[type="checkbox"]:checked + label::before {
                    background: #3AAE2C;
                    border: 1px solid #3AAE2C;
                    opacity: 0.5;
                }

                .material-switch-container .material-switch-content > label::after {
                    left: 0px;
                }

                .accounting-grid {
                    height: 525px !important;
                }

                .date-container {
                    height: 22px;
                    width: 100%;
                    padding: 6px 12px;
                    font-size: 14px;
                    line-height: 1.42857143;
                    color: #555;
                    background-color: #fff;
                    background-image: none;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                }
            }
        }


        .content2 {
            background-color: white;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #B0AFB0;
            border-radius: 3px;
            display: inline-block;

            .panel-header {
                background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
                border-radius: 0 0 0 0;
                padding: 5px 4px;
                height: 25px;
                border-bottom: 1px solid #808080;
            }

            .current-month {
                border: solid 1.5px #009ad9;
                padding-left: 7px;
                padding-bottom: 7px;
                border-radius: 5px;
            }

            .display-flex-wrap {
                display: flex;
                flex-wrap: wrap;

                .uib-datepicker .uib-daypicker {

                    .uib-title {
                        border-color: white;
                    }

                    .uib-weeks .uib-day .btn:disabled {
                        /*font-size: 14px;*/
                        font-weight: normal;
                        color: gray !important;
                        background-color: #fff;
                    }

                    .uib-weeks .uib-day {
                        span.text-info {
                            font-weight: bold;
                            color: $trimble_blue_mid;
                        }

                        .btn:enabled {
                            /*font-size: 14px;
                   font-weight: bold;*/
                            color: $trimble_black;
                            background-color: $trimble_white;
                        }

                        .btn:enabled:hover {
                            color: $trimble_black;
                            background-color: #ff9400;
                        }
                    }

                    .uib-weeks .uib-day:hover {
                        /*font-size: 14px;
                   font-weight: bold;*/
                        color: deeppink !important;
                    }

                    .uib-weeks .uib-day .btn-default {
                        border-color: white;
                    }

                    .uib-weeks .h6 {
                        background-color: $lightgray;
                        width: 22px;
                        /*padding-left: 2px;*/
                        padding-right: 4px;
                    }
                }
            }

            .btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
                color: #fff;
                background-color: #31b0d5 !important;
                border-color: #269abc;
                border: 1px solid $trimble_black !important;
            }



            .selected-date {
                .btn {
                    /*height: 36px !important;*/
                    background-color: $trimble_white !important;
                    /*opacity: 0.65;*/
                    padding: 5px;
                    /*border-radius: 50% !important;*/
                    display: inline-block !important;
                }

                .btn:hover {
                    /*height: 36px;*/
                    background-color: $orange !important;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }
            }

            .selected-date:hover {
                .btn:hover {
                    /*height: 36px;*/
                    background-color: $orange !important;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }

                .active:hover {
                    /*height: 36px;*/
                    background-color: $orange;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }

                .active {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }
            }

            .btn.active {
                -webkit-box-shadow: none;
                box-shadow: none;
            }

            .non-selected-date {
                .btn {
                    /*height: 36px;*/
                    background-color: $white;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }
            }

            .non-selected-date:hover {
                .btn:hover {
                    /*height: 36px;*/
                    background-color: $trimble_white;
                    color: $trimble_black;
                    opacity: 0.65;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }
            }

            .selected button {
                color: #fff;
                background-color: #31b0d5 !important;
                border-color: #269abc;
                border: 1px solid $trimble_black !important;
            }

            .calendar-container {
                padding: 20px;

                .box-sizing-unset {
                    box-sizing: unset;
                }

                .month-title {
                    font-size: 15px;
                    line-height: 1.5;
                }

                .align-center {
                    text-align: center;
                }

                .calendar {
                    margin: 15px;
                }

                .text-muted {
                    color: transparent;
                    display: none;
                }

                .today-date {
                    .text-info {
                        text-align: center;
                        letter-spacing: 0px;
                        color: #005F9E;
                        font-weight: bold;
                        opacity: 1;
                    }

                    .btn {
                        /*height: 36px !important;*/
                        /* background-color: #009AD9;
                    color: black;*/
                        color: #009AD9;
                        font-weight: bold;
                        /*border-radius: 50% !important;*/
                        display: inline-block !important;
                    }
                }
            }
        }

        .display-inline-flex {
            background-color: white;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #B0AFB0;
            border-radius: 3px;
            display: inline-flex;

            .panel-header {
                background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
                border-radius: 0 0 0 0;
                padding: 5px 4px;
                height: 25px;
                border-bottom: 1px solid #808080;
            }

            .current-month {
                border: solid 1.5px #009ad9;
                padding-left: 7px;
                padding-bottom: 7px;
                border-radius: 5px;
            }


            .uib-datepicker-popup {

                .uib-title {
                    border-color: white;
                }

                .uib-weeks .uib-day .btn:disabled {
                    /*font-size: 14px;*/
                    font-weight: normal;
                    color: gray !important;
                    background-color: #fff !important;
                    border: none !important;
                }

                .uib-weeks .uib-day {
                    span.text-info {
                        font-weight: bold;
                        color: $trimble_blue_mid !important;
                    }

                    .btn:enabled {
                        /*font-size: 14px;
                   font-weight: bold;*/
                        color: $trimble_black;
                        background-color: #3AAE2C;
                    }

                    .btn:enabled:hover {
                        color: $trimble_black;
                        background-color: #ff9400;
                    }
                }

                .uib-weeks .uib-day:hover {
                    /*font-size: 14px;
                   font-weight: bold;*/
                    color: deeppink !important;
                }

                .uib-weeks .uib-day .btn-default {
                    border-color: white;
                }

                .uib-weeks .h6 {
                    background-color: $lightgray;
                    width: 22px;
                    /*padding-left: 2px;*/
                    padding-right: 4px;
                }
            }

            .btn-default.active {
                color: #fff;
                background-color: #3AAE2C !important;
            }

            .btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
                color: #fff;
                background-color: #31b0d5 !important;
                border-color: #269abc;
                border: 1px solid $trimble_black !important;
            }



            .selected-date {
                .btn {
                    /*height: 36px !important;*/
                    background-color: #3AAE2C !important;
                    /*opacity: 0.65;*/
                    padding: 5px;
                    /*border-radius: 50% !important;*/
                    display: inline-block !important;
                }

                .btn:hover {
                    /*height: 36px;*/
                    background-color: $orange !important;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }
            }

            .selected-date:hover {
                .btn:hover {
                    /*height: 36px;*/
                    background-color: $orange !important;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }

                .active:hover {
                    /*height: 36px;*/
                    background-color: $orange;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }

                .active {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }
            }

            .btn.active {
                -webkit-box-shadow: none;
                box-shadow: none;
            }

            .non-selected-date {
                .btn {
                    /*height: 36px;*/
                    background-color: $white;
                    color: $trimble_black;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }
            }

            .non-selected-date:hover {
                .btn:hover {
                    /*height: 36px;*/
                    background-color: #3AAE2C !important;
                    color: $trimble_black;
                    opacity: 0.65;
                    /*border-radius: 50%;*/
                    display: inline-block;
                }
            }

            .selected button {
                color: #fff;
                background-color: #31b0d5 !important;
                border-color: #269abc;
                border: 1px solid $trimble_black !important;
            }

            .calendar-container {
                padding: 20px;

                .box-sizing-unset {
                    box-sizing: unset;
                }

                .month-title {
                    font-size: 15px;
                    line-height: 1.5;
                }

                .align-center {
                    text-align: center;
                }

                .calendar {
                    margin: 15px;
                }

                .text-muted {
                    color: transparent;
                    display: none;
                }

                .today-date {
                    .text-info {
                        text-align: center;
                        letter-spacing: 0px;
                        color: #005F9E;
                        font-weight: bold;
                        opacity: 1;
                    }

                    .btn {
                        /*height: 36px !important;*/
                        /* background-color: #009AD9;
                    color: black;*/
                        color: #009AD9;
                        font-weight: bold;
                        /*border-radius: 50% !important;*/
                        display: inline-block !important;
                    }
                }
            }

            .accountperiod-container {

                .grids .grid-border .ag-cell [type="checkbox"]:not(:checked) + label::after, .grids .grid-border .ag-cell [type="checkbox"]:checked + label::after {
                    top: 0px !important;
                    width: 20px;
                    height: 20px;
                }

                .grids .grid-border .ag-cell .col-xs-1 {
                    width: 55px;
                }

                .grids .grid-border .ag-cell [type="checkbox"]:not(:checked) + label::before, .grids .grid-border .ag-cell [type="checkbox"]:checked + label::before {
                    top: 2px !important;
                }

                .grid-header-checkbox-label {
                    left: -4px;
                }

                .material-switch-container .material-switch-content > input[type="checkbox"]:checked + label::after {
                    background: #3AAE2C;
                    left: 20px;
                }

                .material-switch-container .material-switch-content > input[type="checkbox"]:checked + label::before {
                    background: #3AAE2C;
                    border: 1px solid #3AAE2C;
                    opacity: 0.5;
                }

                .material-switch-container .material-switch-content > label::after {
                    left: 0px;
                }

                .accounting-grid {
                    height: 525px !important;
                }

                .date-container {
                    height: 22px;
                    width: 100%;
                    padding: 6px 12px;
                    font-size: 14px;
                    line-height: 1.42857143;
                    color: #555;
                    background-color: #fff;
                    background-image: none;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                }
            }
        }
    }

    .visibility-hidden {
        cursor: not-allowed;
    }

    .background-color-white {
        background-color: white !important;
        cursor: not-allowed;
    }

    .display-flex {
        display: flex;
    }
}

.payment-date-header {
    background-color: $gray;

    .status-indicator {
        padding: 15px;

        .filled-green-square {
            height: 15px;
            width: 15px;
            background-color: #3AAE2C;
            /*border-radius: 50%;*/
            opacity: 0.65;
            display: inline-block;
            margin-right: 10px;
        }

        .filled-white-square {
            height: 15px;
            width: 15px;
            background-color: $white;
            /*border-radius: 50%;*/
            opacity: 0.65;
            display: inline-block;
            margin-right: 10px;
        }

        .filled-blue-square {
            height: 15px;
            width: 15px;
            background-color: #31b0d5;
            /*border-radius: 50%;*/
            opacity: 0.65;
            display: inline-block;
            margin-right: 10px;
            border: 1px solid $trimble_black;
        }

        .hollow-circle {
            border: 1px solid #009AD9;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            z-index: 86;
        }

        .special-text {
            display: flex;
            padding: 0px;
            font-size: 11px;
            left: 5em;
        }

        .today-text {
            margin: 0 15px;
            color: #005F9E;
            font-weight: bold;
            font-size: 14px;
        }


        div.square {
            border: solid 1px #009AD9;
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
    }
}
