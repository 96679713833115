.timber-trader-contract-accordion {
    .buttons-group {
        padding-top: 10px;
        padding-bottom: 10px;
        background: #d1d1d1 0% 0% no-repeat padding-box;
    }

    .supplier-group {
        color: $darkgray;

        .custom-button {
            height: 27px;
            padding: 3px 6px;
            border-color: #616161;
            width: calc(100% - 24px);
        }

        .dropdown-toggle {
            height: 27px;
            padding: 3px 6px;
            border-color: #616161;
            width: 25px;

            .custom-drop-down-arow {
                padding-left: 3px;
            }
        }

        .dropdown-menu {
            .glyphicon-remove {
                position: absolute;
                margin-top: 4px;
            }

            .drop-down-table {
                overflow-y: auto;

                .table-cell {
                    padding-left: 5px;
                    display: inline-flex;
                    align-items: center;
                }
            }
        }
    }

    .radio-required {
        background: #F4D1D3 0% 0% no-repeat padding-box;
        margin-right: 60px;
    }

    .text-disabled {
        color: grey;
    }

    .text-active {
        color: #333;
    }

    .radio-label {
        padding-left: 5px !important;
    }

    .mt-15 {
        margin-top: 15px;
    }

    .no-left-padding {
        padding-left: 0px;
    }

    .left-margin {
        margin-left: 13px;
    }

    .display-flex {
        display: flex;
    }

    .date-width-100 {
        width: 100%;
    }

    .worksite-edit {
        .date-padding.date-custom-width {
            width: 168px;
        }
    }

    .del-Schedule-btn {
        padding-left: 10px;
    }

    .certificate-wrapper {
        margin-bottom: 15px;
    }

    .double-date-container {
        .date {
            width: 45%;
        }

        .dash-mark-dates {
            width: 10%;
            text-align: center;
            padding-right: 9px;
            padding-left: 9px;
            padding-top: 3px;
        }

        .date-padding.date-custom-width {
            width: 100%;
        }
    }

    .date-padding.date-custom-width {
        width: 161px;
    }

    div.date-picker {
        padding: 0;
    }

    .content-wrapper {
        padding: 15px 0 15px 0;
    }

    .font-grey-background {
        margin-top: 0;
    }

    .bottom-padding-allowed {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 15px !important;
    }

    .group {
        display: flex;
    }

    .weekpicker-input {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 0 !important;
    }

    .weekpicker-btn {
        background-color: white;
        border: 1px solid #616161;
        border-left: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 27px;
        padding: 9px 4px 2px 7px;

        .calendar-icon {
            cursor: pointer;
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            background: url("/content/images/svg/calendar_today_black.svg") no-repeat;
            padding: 6px 7px 6px 11px;
            background-size: 16px
        }
    }

    .wood-origin {
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before,
        [type="checkbox"]:not(:checked) + label::after,
        [type="checkbox"]:checked + label::after {
            top: 2px;
        }
    }

    .pad-right-0 {
        padding-right: 0px;
    }

    .pad-left-0 {
        padding-left: 0px;
    }

    .date-invalid {
        background-color: $lightred;
    }
}
