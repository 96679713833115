$height: 300px;

suggested-operation-chart {
    display: block;    
    width: 100%;   
    padding: 10px; 

    .suggestedOperationChart {
        display: block;
        height: $height;
        width: 100%;
        overflow: hidden;
    }

    .title {
        text-transform: uppercase;
        font-size: 13px;
        font-family: Verdana;        
    }
}