﻿@import '../../../../../shared/sass/_variables.scss';

.harvester-file-details-print-modal-window {
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .tab-container {
        position: relative;
        top: 4px;
    }

    .tabs-wrapper {
        margin: 20px;
    }

    .col-xs-6 {
        width: 53%;
    }

    .modal-dialog {
        width: 800px;
        margin-top: 150px;

        .modal-trimble-button-container {
            margin-top: 0;
        }
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .instruction-div {
        background: #E2E2E7 0% 0% no-repeat padding-box;
        opacity: 1;
        top: 305px;
        left: 560px;
        width: 800px;
        height: 45px;
    }

    .instruction-text {
        top: 319px;
        left: 580px;
        height: 17px;
        text-align: left;
        letter-spacing: 0px;
        color: $trimble_grey;
        opacity: 1;
        margin-top: 1em;
    }

    .hr-border {
        border-bottom: 0.75px solid $half_trimble_brand_grey;
    }

    .padding-all {
        padding: 40px;
    }

    .print-options {
        min-height: 100px;

        .print-options-header {
            text-align: left;
            font: normal normal bold 12px/17px "Myriad Pro", "Verdana";
            letter-spacing: 0px;
            color: $trimble_grey;
            opacity: 1;
        }

        .print-options-content {
            padding-top: 20px;
        }

        .print-options-content-header {
            text-align: left;
            font: normal normal 600 12px/17px "Myriad Pro", "Verdana";
            letter-spacing: 0px;
            color: $trimble_grey;
            opacity: 1;
        }

        .col-2 {
            width: 20%;
        }

        .checkbox-top {
            [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
                top: 0px !important;
            }

            [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
                top: 0px !important;
            }
        }

        .print-options-content-text {
            text-align: left;
            font: normal normal normal 12px/17px "Myriad Pro", "Verdana";
            letter-spacing: 0px;
            color: $trimble_grey;
            opacity: 1;
            margin-left: 0.3em;
        }

        .print-drop-position-control {
            margin-bottom: 0;

            .multiselectiontable-drop-down {
                line-height: 30px;
                width: 100%;
                border-radius: 0px;

                .titleBox {
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid $darkgray;
                    line-height: 18px;

                    .titleBoxText {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 93%;
                        color: #555;
                    }

                    .topmargin {
                        margin-top: 5px;
                    }
                }

                .col-hearder-text {
                    color: $trimble_grey;
                    font: normal normal 600 12px/17px "Myriad Pro", "Verdana";
                    letter-spacing: 0px;
                    opacity: 1;
                    text-transform: uppercase;
                }

                .col-text {
                    text-align: left;
                    font: normal normal normal 12px/17px "Myriad Pro", "Verdana";
                    letter-spacing: 0px;
                    color: $trimble_grey;
                    opacity: 1;
                }

                .checkboxes {
                    width: 95.5%;
                    height: 14em;
                    border-radius: 0px;

                    .select-all-label {
                        line-height: 30px;
                    }

                    .checkboxesList .checkboxLabel {
                        line-height: 29px;
                    }

                    .checkboxesList {
                        height: 34px;
                    }
                }
            }
        }

        .additional-setting-drop-position-control {
            margin-bottom: 0;

            .multiselectiontable-drop-down {
                line-height: 30px;
                width: 100%;
                border-radius: 0px;

                .titleBox {
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid $darkgray;
                    line-height: 18px;

                    .titleBoxText {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 93%;
                        color: #555;
                    }

                    .topmargin {
                        margin-top: 5px;
                    }
                }

                .col-hearder-text {
                    color: $trimble_grey;
                    font: normal normal 600 12px/17px "Myriad Pro", "Verdana";
                    letter-spacing: 0px;
                    opacity: 1;
                    text-transform: uppercase;
                }

                .col-text {
                    text-align: left;
                    font: normal normal normal 12px/17px "Myriad Pro", "Verdana";
                    letter-spacing: 0px;
                    color: $trimble_grey;
                    opacity: 1;
                }

                .checkboxes {
                    width: 95.5%;
                    height: 6em;
                    border-radius: 0px;

                    .select-all-label {
                        line-height: 30px;
                    }

                    .checkboxesList .checkboxLabel {
                        line-height: 29px;
                    }

                    .checkboxesList {
                        height: 34px;
                    }
                }
            }
        }
        /*        .col-xs-2 {
            width: 18%;
        }*/
    }

    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
        top: 5px !important;
    }

    [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
        top: 5px !important;
    }
}