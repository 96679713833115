/* general styles/components globally used in ag-grids / common-grids */
.sp-grid {
  .ag-overlay-panel {
    .no-rows-bubble {
        margin-left: 41.5%;
        padding: 10px;
        background-color: white;
        border: 1px solid $gray;
        border-radius: 5px;
        text-align: left;
        width: 193px;
    
        p {
            margin: 0;
        }
    }
  }
}