//Hacky way to correctly show the buttons
header.hideNavigation + .main-view-container .customers {
    min-height: calc(100vh + 1em);
    margin-top: 1em;

    .customer-bottom {
        padding-bottom: 30px;
    }
}

.width-100pp {
    width: 100%;
}

.customers, .customer-info {
    margin-bottom: 0px !important;

    .flex {
        display: flex;
    }

    .partial-border {
        border-radius: 3px !important;
    }

    .partial-header-border {
        border-radius: 3px 3px 0 0 !important;
    }

    .no-left-margin {
        margin-left: 0px !important;
    }

    .no-right-margin {
        margin-right: 0px !important;
    }

    .customers-properties-row {
        height: auto;
    }

    .custom-button {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .custom-drop-down .dropdown-menu input[type="search"] {
        margin-left: 36px;
        margin-top: 4px;
    }

    .drop-down-table {
        overflow-y: auto !important;
        height: 14.5em !important;
    }

    .custom-table {
        .table-header {
            background-color: $lightgray;
            display: none !important;

            .table-cell {
                margin-top: 3px;
                margin-bottom: 0px;
                margin-left: 8px;
            }
        }

        .table-row {
            line-height: 26px;
        }
    }

    .scrollable-container-estates {
        height: 100%;
    }

    .upper-part {
        margin: 0 0.2em;
    }

    .settings-item {

        &.view-option-select {
            .btn {
                background-color: white;
            }
        }

        &.offline-selection {
            .offline-icon {
                background-image: url('../content/images/svg/OfflineDropDownSymbol.svg');

                &:hover {
                    background-image: url('../content/images/svg/OfflineDropDownSymbol-MouseOver.svg');
                }
            }

            &.open {
                .offline-icon {
                    background-image: url('../content/images/svg/OfflineDropDownSymbol-MouseOver.svg');
                }
            }

            .offline-information {
                font-size: 0.8em;
                line-height: initial;
            }

            ul {
                label {
                    line-height: initial;
                    display: block;
                    font-weight: initial;
                    font-size: 13px;
                    position: relative;
                    padding-left: 1.2em;

                    input {
                        margin-right: 0.5em;
                        position: absolute;
                        left: 0;
                        margin-top: 0;
                    }
                }

                button {
                    margin: 0 0.5em;
                    width: calc(100% - 1em);
                }

                div {
                    margin-top: 0.5em;
                    margin-bottom: 0.5em;


                    .divider {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .left-margin-div {
        margin-left: 0.5em !important;
    }

    .navigation-buttons {
        padding-left: 0;

        .navigation-button-description {
            width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;

            &-prev {
                text-align: right;
            }

            &-next {
                text-align: left;
            }
        }
    }

    .partial {
        margin: 0.5em 0px 0.5em 0px;

        &.top-left {
            flex: inherit;
            overflow: visible;
            margin: 15px !important;
            margin-left: 0px !important;
        }

        &.top-right {
            display: flex;
            background-color: $trimble_white;
            margin: 15px !important;
            margin-right: 0px !important;
            margin-left: 0px !important;
        }

        &.bottom {
            width: 100%;
        }

        .content { // for customer/yleistiedot top section
            .content:first-child {
                padding-top: 0 !important; // Override admin.css
                .control-label {
                    padding-top: 0;
                }
            }
        }

        .text-right {
            .control-label {
                margin-right: 0.5em;
            }
        }

        .inner-header {
            width: calc(100% + 30px); //15px larger in each side to override padding in container
            margin-left: -15px;
            border-radius: 0 !important;
        }

        .form-group {
            margin-bottom: 0px !important; //Override stylings made in admin-style.scss
            padding: 0px !important; //Override stylings made in admin-style.scss

            .bottom-buttons {
                padding: 0.5em !important; //override .admin .partial .form-group div,
                background: $lightgray;
            }

            .input-group {
                width: 100%;

                .dropdown-menu {
                    overflow-y: auto;
                    height: 272px;
                }

                input.form-control {
                    &.postal-code {
                        width: 5em;
                        margin: 0px;
                    }

                    &.city {
                        margin: 0px;
                        width: calc(100% - 5em);
                    }

                    &.fsc-number {
                        margin: 0px;
                        width: 12em;
                    }

                    &.fsc-name {
                        margin: 0px;
                        width: calc(100% - 12em);
                    }
                }
            }

            .label-padding-left {
                padding-left: 0.5em !important; // because admin-styles has customer-info class defined at the top
            }

            .padded-container .partial-header {
                padding: 0.5em; // because admin-styles has customer-info class defined at the top
            }
        }

        .form-group.no-padding {
            padding: 0 !important; // because admin-styles has customer-info class defined at the top
        }

        .form-group.no-input-margin input,
        .form-group.no-input-margin select,
        .form-group.no-input-margin .input-group {
            margin: 0; // because admin-styles has customer-info class defined at the top
        }
    }

    .silva-header-whitebg {
        @extend %silva-header;
        background-color: white;

        span {
            position: relative;
            top: 5px;
        }
    }


    .space-before {
        margin-top: 10px !important;
    }

    .space-after {
        margin-bottom: 10px !important;
    }

    .space-right {
        padding-right: 0.2em !important;
    }

    .light-gray {
        background: $lightgray;
    }

    .full-width {
        width: 100%;
    }

    .flex-center {
        align-self: center;
    }

    .flex-group {
        justify-content: space-between;
    }

    .left-border {
        border-left-color: darkgray;
        border-left-width: 1px;
        border-left-style: solid;
    }

    .area-gray {
        background: $lightgray;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 0.5em;

        &.info-container {
            flex-flow: column;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0px;

            > div {
                width: 100%;
                padding: 1em;
                border-top: 1px solid $gray;
                font-family: $font-semibold;
                flex-grow: 1;
                display: flex;

                &:not(.align-topleft) {
                    text-align: center;
                    justify-content: center;
                    align-items: center;

                    * {
                        flex: 1;
                    }
                }

                &:first-of-type {
                    border-top: 0;
                    text-transform: uppercase;
                }

                &.top:first-child {
                    align-self: flex-start;
                    flex-grow: 0;
                }

                &.bottom {
                    align-self: flex-end;
                    flex-grow: 0;
                }
            }
        }
    }

    .wrap {
        margin: 0px 6px 6px 6px !important;
        width: calc(100% - 1em);
    }

    .content {
        padding: 0.5em !important; // admin-styles
    }

    .management-widths {
        .col-xs-2 {
            width: 16.3333%;
        }

        .col-lg-10 {
            width: 83.6666%
        }
    }

    hr {
        margin: 0.5em 0px 0.5em -0.5em;
        width: calc(100%);
        margin-right: 0px;
        padding-right: 12px;
        margin-left: -6px;
    }

    label + label {
        display: initial;
    }

    .lower-part .tabs-content {
        min-height: 455px;
    }

    .lower-part .no-margin {
        margin: 0px;
    }

    .tab-container .tab {
        border-radius: 3px 3px 0 0 !important;
    }

    .lower-part {
        margin: -3px !important;
    }

    .tabs-wrapper .tabs-content {
        border-radius: 0 0 3px 3px !important;
    }

    .rounded-border-top-left {
        border-radius: 3px 0 0 0 !important;
    }

    .rounded-border-top-right {
        border-radius: 0 3px 0 0 !important;
    }

    .partial.overview {
        width: 100%;
        background: none;
        border: 0px;
        padding-bottom: 7em;

        .tabs-content {
            background: white;
        }
    }

    .partial-header ~ .partial-header {
        border-radius: 0px;
    }

    .glyphicon-envelope {
        font-size: 20px;
        color: $trimble_tf_product_green;
        margin-top: -1px;
        width: 15%;
        line-height: 26px;
        text-align: center;
        vertical-align: middle;
        border: 0px;
        border-left: 1px solid black;
    }

    .glyphicon-envelope:hover {
        color: $gray;
    }
    // disable email-link (this should be on global level actually)
    a[disabled] {
        pointer-events: none;
    }

    .form-control.no-padding {
        padding-top: 0px !important;
    }

    .email-input {
        width: 100%;
        padding: 0px !important;

        input {
            width: 85%;
            border: 0px;
            height: 100%;
            padding-left: 0.2em;

            &:not(.ng-invalid) {
                background: none;
            }

            &.ng-invalid {
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
            }
        }
    }

    .invalid-field {
        background-color: $lightred;
    }

    .input-upper {
        text-transform: uppercase;
    }

    .partial.wrapper {
        border: 0px;
    }

    .border-wrapper {
        border: 1px solid #616161;
        border-radius: 0.5em;
        background-color: $trimble_white;
    }

    .customer-basic-info {
        display: inline-block;

        .partial-header {
            margin: 0;
            padding: 0.5em;
        }

        .partial {
            border: 1px solid $gray;
            border-radius: 3px !important;

            .partial {
                border: none;
                border-left: 1px solid $gray;
                border-radius: 0px;
            }
        }

        .border-right {
            border-right: 1px solid $gray;
            border-left: 0;
            padding: 0;
        }

        .title-left-corner { // can be cleaned a bit
            padding: 0;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0;
            border: 1px solid $gray;
            border-bottom: 0;
            border-left: 0;
            border-top: 0;
        }

        .no-border-right {
            border-right: none;
        }

        .border-left-dark {
            border-left: 1px solid $gray;
        }

        .title-right-corner { // can be cleaned a bit
            padding: 0;
            border-top-right-radius: 0.5em;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0.5em;
            border: 0;
            border-right: 0;
        }

        .title-no-corners {
            border: none !important;
            border-radius: 0px !important;
            padding: 0;
        }

        .content-customership {
            padding-right: 0.75em !important;
        }

        .billing-radio-group {
            label {
                font-weight: 400;
                padding: 0 0.25em;
                vertical-align: middle;
            }
        }

        .voting-margin {
            margin-right: -0.5em !important;
        }

        .border-bottom-dark {
            border-bottom: 1px solid $gray;
        }

        .agreement-control-align {
            margin-left: 0.55em;
        }

        .increased-z-index {
            z-index: 1;
        }

        .row-one-height-150 {
            height: 150px;
        }

        .row-one-height-95 {
            height: 95px;
        }

        .row-two-hright {
            height: 122px;
        }
        .bottom-margin {
            margin-bottom: 27px;
        }
        .increased-bottom-margin {
            margin-bottom: 54px;
        }
    }

    .info-rows { //The rows in minified member info
        flex-flow: column;
        padding: 0px;
        flex-wrap: wrap;

        .info-row {
            border-top: 1px solid $gray;

            &:first-of-type {
                border-top: 0px;
            }

            div {
                width: 100%;
                flex-wrap: wrap;
                border-left: 1px solid $gray;
                font-family: $font-semibold;
                margin: 0px;
                white-space: nowrap;
                padding: 0.2em 0.5em;

                &:first-child {
                    border-left: 0px;
                }
            }
        }
    }

    .site-tab {

        .search-bar {
            border: 1px solid #d1d1d1;
            border-radius: 0.5em;
            height: 2em;
            line-height: 2em;
            overflow: hidden;
            padding: 0 0.4em;
            margin: 0.5em 0.5em 1em 0.5em;
            width: 25%;

            input {
                border: 0;
                height: 2em;
                outline: 0;
                padding-left: 0.2em;
                width: 91%;
            }

            .glyphicon-remove {
                top: 2px;
            }
        }

        .grid-and-buttons {
            border: 1px solid $black;
            border-radius: 0.5em;
            margin: 0.5em;

            .grid-container {
                margin: 0;

                .site-grid {
                    height: 348px;

                    #centerRow {
                        border-top-right-radius: 0.5em !important;
                        border-top-left-radius: 0.5em !important;
                    }

                    .ag-floating-bottom {
                        box-shadow: none !important;
                    }
                    /* we want the cubic meter unit to be in lowercase */
                    div[col-id=kertyma] {
                        text-transform: none;
                    }

                    .ag-body-viewport {
                        .notes-icon {
                            cursor: pointer;
                        }

                        .ag-header-cell-label,
                        .ag-cell {
                            padding-left: 3px;
                        }
                    }

                    .ag-header-icon {
                        margin-top: 6px;
                    }
                }
            }
        }

        .display-flex-root{
            display: flow-root;
        }
    }

    .customer-info customer-contact-info {
        .custom-drop-down {
            .dropdown-toggle {
                padding-top: 3px;
                padding-bottom: 3px;
            }

            .dropdown-menu {
                margin-top: 0em;
                width: 14.5em;
                height: 18em;
                border-color: $darkgray;
            }

            .silva-search {
                padding-left: 10px !important;
                margin-top: 5px;
                border-color: $darkgray;

                .glyphicon {
                    top: 39px;
                    left: 17px;
                }

                .glyphicon-remove {
                    left: 14px !important;
                    top: -32.2px;
                }
            }
        }
    }

    .external-id {
        cursor: pointer;
    }

    .invalid-field {
        background-color: $lightred;
    }
    // endof site-tab
}

.required_field {
    background-color: #FFD8D8 !important;
}

.customer-btn {
    margin: 15px 0px;
}

.customer-bottom-button {
    padding: 1em 2em 1em 2em;
}


.distribution-scroll-list {
    overflow: auto;
    max-height: 100px;

    table {
        width: 100%;
    }

    td {
        border: 1px solid $gray;
        padding: 0.2em;
    }

    .distribution {
        border-radius: 0.2em;
        border: 1px solid black;
    }

    .count {
        text-align: center;
    }

    .name {
        padding: 0 0.5em 0 0.5em !important; // thank you admin.css
    }
}

.custom-drop-down .dropdown-menu .custom-table .table-row:not(.table-header):hover {
    background-color: dodgerblue !important;
    color: white !important;
}

//Override angular's default styling for typeahead in member info partial
.joined-member-typeahead + .dropdown-menu {
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
}

//Typeaehead fields don't correctly show disabled styles
.joined-member-typeahead[disabled] {
    background-color: #eee !important;
}

.joined-member-link {
    display: inline-block;
    padding-top: 7px;
    text-decoration: underline;
    cursor: pointer;
}

.distribution-table {
    .selecteddistribution div {
        @extend %selected-row;
    }
}

.no-border-radius {
    border-radius: 0 0 0 0;
}

@import './standBasicInfoModalWindow.scss';
@import './components/gridModalWindow.scss';
@import './components/customerHistory.scss';
@import './components/distributionsAndNotes.scss';
@import './components/topRightMinified-style.scss';
@import './components/personalData-style.scss';
@import './realEstates-style.scss';
@import './customersTableStyles.scss';
@import './forestryContract.scss';
@import './kuutioCallForOffer.scss';
@import './selectCustomerModal.scss';
@import './components/forestInformationTab.scss';
@import './components/moveRealEstatesToCustomerModal.scss';
@import './components/customers/forestCommon/forestBase.scss';
@import './components/forestAssets.scss';
@import './components/forestPlanComparison.scss';
@import './components/forestXmlImport.scss';
@import './components/editForestPlanRealEstateLinkModal.scss';
@import './components/workSite/workSite.scss';
@import './components/timberSaleContract.scss';
@import './components/powerOfAuthorize/powerOfAuthorize.scss';
@import './components/lisaaKuviotietojaModal/lisaaKuviotietojaModal.scss';
@import './components/servicesTab/servicesTab.scss';
@import './components/plotView/plot.scss';
@import './components/workSite/duplicateCustomerModal/duplicateCustomerModal.scss';
