.bucking-modal {
    .modal-dialog {
        width: 63%;
        top: 5%;
    }

    .sp-grid {
        height: 517px;
        border: 1px solid $half_trimble_brand_grey;
        overflow: hidden;
        border-radius: 3px 3px 3px 3px;

        .ag-header {
            background: $trimble-gradient-grey-header;

            .ag-header-group-cell.no-top-bottom {
                border-bottom: 0px;
            }

            .ag-header-cell.special-height-header-90 .ag-header-cell-text {
                padding-top: 33px;
            }

            .ag-header-group-text,
            .ag-header-cell-text {
                padding-top: 3px;
            }
        }

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 3px 3px 3px 3px;
        }

        .ag-group-value {
            margin-left: 1%;
        }

        .default-radio-button {
            pointer-events: none;
        }

        .ag-body {
            .ag-group-expanded,
            .ag-group-contracted {
                margin-right: 30px;
            }

            .ag-group-value,
            .ag-group-child-count {
                font-family: $font-semibold;
            }

            .ag-icon-contracted {
                margin-right: 0;
                padding-left: 25px;
            }

            .ag-icon-expanded {
                margin-right: 0;
                padding-left: 25px;
                margin-right: 6px;
            }

            .ag-row-group-indent-0 {
                margin-top: 4px;
            }

            .ag-group-child-count {
                font-size: 13px;
            }

            .ag-icon-contracted,
            .ag-icon-expanded {
                position: absolute;
            }
        }
    }
}