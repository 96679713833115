﻿@import './_variables.scss';

.table-search {
    border: 1px solid $gray;
    border-radius: 5px;
    overflow: hidden;
    padding: 0 5px;
    overflow: hidden;
    width: 275px;
    background-color: white;

    input {
        margin-left: 2px;
        width: calc(100% - 36px);
        border: 0;
        outline: 0;

        &:placeholder-shown {
            font-style: italic;
        }
    }

    .glyphicon-remove {
        cursor: pointer;

        &:hover {
            color: $trimble_tf_product_green;
        }
    }
}

.settings-item {
    vertical-align: middle;
    display: inline-block;
    line-height: 30px;
    height: 30px;
}

.icon-expand-button {
    background-color: $trimble_white;

    &:hover {
        background-color: $trimble_white;
    }
}

.sp-grid-settings {
    margin-bottom: .5em;

    .sp-grid-settings-item {
        vertical-align: middle;
        display: inline-block;
        line-height: 34px;
        height: 34px;
        margin: 0.5em 0.5em 0 0;
    }
}

button {
    &.flat {
        width: 57px;
        height: 27px;
    }

    &.icon-button {
        background-position: center center;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
    }

    &.settings {
        background-size: 25px;
        background-image: url(../../content/images/svg/settings-cog.svg);
        background-color: white;

        &:hover:not([disabled]),
        &:focus:not([disabled]) {
            background-image: url(../../content/images/svg/settings-cog-green.svg);
            background-color: white;
        }

        &[disabled] {
            border: none !important;
        }
    }
}

.factory-icon {
    background-color: $trimble_tf_product_green;
    border-color: $trimble_tf_product_green;
    background-image: url(../../content/images/svg/EndPoint.svg);
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;

    &.disabled {
        background-color: $disabled-input-color;
        cursor: not-allowed;
        pointer-events: none;
    }

    &:hover:not(.disabled) {
        background-color: $blue;
    }
}

.pen-icon {
    background-color: $trimble_blue_light;
    background-image: url(../../content/images/svg/map/toolbar/MapToolBar_DrawEditor.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;

    &.black {
        background-color: $black;
        color: $trimble_white;
        cursor: default;
    }

    &.disabled {
        background-color: $trimble_blue_light;
        color: $trimble_white;
        cursor: default;
        opacity: 0.5;
    }

    &:hover:not(.black) {
        background-color: $trimble_blue_mid;
        color: $trimble_white;
    }
}

.graph-icon {
    background-color: $trimble_blue_light;
    background-image: url(../../content/images/svg/show_chart.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;

    &.black {
        background-color: $black;
        color: $trimble_white;
        cursor: default;
    }

    &.disabled {
        background-color: $trimble_blue_light;
        color: $trimble_white;
        cursor: default;
        opacity: 0.5;
    }

    &:hover:not(.black) {
        background-color: $trimble_blue_mid;
        color: $trimble_white;
    }
}

.edit-icon {
    background-color: $trimble_blue_light;
    background-image: url(../../content/images/svg/create-24px.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
}

.edit-icon-header {
    background-image: url(../../content/images/svg/create-header-24px.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
}

.error-icon {
    background-image: url(../../../content/images/svg/icon_error.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
}

.inform-icon {
    background-image: url(../../../content/images/svg/icon_info.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
}

.display-flex {
    display: flex;
}

.customer-button-container {
    margin-left: auto;
    margin-top: 4px;
    margin-right: 14px;
}

.margin-left-auto {
    margin-left: auto;
    margin-top: 4px;
}

.attachment-icon-mesage-badge {
    width: 15px;
    height: 14px;
    margin-top: -3px;
    margin-left: -30px;
    padding-right: 0;
    padding-top: 1px;
    padding-left: 0px;
    background-color: $orange;
    user-select: none;
    cursor: pointer;
}

.attachment-container {
    display: flex;
    margin-right: 20px;
}

.attachment-icon-white {
    background-image: url(../../../content/images/svg/Attachment_ButtonIcon_White_14x18px.svg);
    color: $trimble_white;
    background-size: 24px 24px;
    border-radius: 0.3em;
    cursor: pointer;
    display: block;
    height: 24px;
    width: 24px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-color: $trimble_blue_light;
}


.attachment-icon-white:hover {
    background-color: $trimble_blue_mid;
}

.attachment-icon-black {
    background-image: url(../../../content/images/svg/Attachment_GridColumnIcon_Black_16x20px.svg);
    color: $trimble_white;
    background-size: 18px 18px;
    border-radius: 0.3em;
    cursor: pointer;
    display: block;
    height: 18px;
    width: 18px;
}

.inform-icon-black {
    background-image: url(../../../content/images/svg/iTool_Round_Black.svg);
    color: $trimble_white;
    background-size: 24px 24px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 24px;
    width: 24px;
}

.inform-icon-black-outline {
    background-image: url(../../../content/images/svg/iTool_Round_Black_outline.svg);
    color: $trimble_white;
    background-size: 24px 24px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 24px;
    width: 24px;
}

.inform-icon-grey-outline {
    background-image: url(../../../content/images/svg/iTool_Round_Grey_outline.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
}

.warn-icon {
    background-image: url(../../../content/images/svg/icon_warning.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
}

.auto-renew-icon {
    background-image: url(../../../content/images/svg/autorenew_white.svg);
    background-color: $trimble_blue_light;
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
    border: none;
    &.black {
        background-color: $black;
        color: $trimble_white;
        cursor: default;
    }

    &.disabled {
        background-color: $trimble_blue_light;
        color: $trimble_white;
        cursor: default;
        opacity: 0.5;
        border: none !important;
    }

    &:hover:not(.black) {
        background-color: $trimble_blue_mid;
        color: $trimble_white;
        border: none;
    }
}

.priority-high-icon {
    background-image: url(../../../content/images/svg/priority_high_white.svg);
    color: $orange;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 20px;
    width: 15px;
}

.icon-active-status {
    width: 25px;
    background-image: url(../../../../content/images/svg/lock_open.svg);
    /*background-color: $trimble_blue_light;*/
    /*color: $trimble_white;*/
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
}

.icon-draft-status {
    width: 25px;
    background-image: url(../../../../content/images/svg/notes_grey.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 1px;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
}

.icon-locked-status {
    width: 25px;
    background-image: url(../../../../content/images/svg/lock_grey.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
}

.icon-delete-status {
    width: 25px;
    background-image: url(../../../../content/images/svg/highlight_off_red.svg);
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
}

.icon-calculator-black {
    background-image: url(../../../content/images/svg/calculate_black.svg);
    background-size: 20px 20px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 20px;
    width: 20px;
}

.icon-calculator-blue {
    background-image: url(../../../content/images/svg/calculate_blue.svg);
    color: $trimble_blue;
    background-size: 20px 20px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 20px;
    width: 20px;
}

.icon-contract-extraction-black {
    background-image: url(../../../content/images/svg/ContractExtension_icon_Black.svg);
    background-size: 20px 20px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 20px;
    width: 20px;
}

.icon-contract-extraction-blue {
    background-image: url(../../../content/images/svg/ContractExtension_ButtonIcon_Blue_Default.svg);
    background-size: 20px 20px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 20px;
    width: 20px;

    &.disabled {
        cursor: default;
        opacity: 0.5;
    }

    &:hover:not(.black) {
        background-image: url(../../../content/images/svg/ContractExtension_ButtonIcon_DarkBlue_Hover.svg);
    }
}
.notification-icon {
    background-image: url(../../../content/images/svg/CriticalNotificationSymbol_Black.svg);
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px 12px;
    height: 12px;
    opacity: 1;
    width: 12px;
    display: inline-block;
}
.check_circle {
    background-image: url(../../../content/images/svg/check_circle.svg);
    color: $trimble_tf_product_green;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 19px 19px;
    height: 19px;
    opacity: 1;
    width: 19px;
    display: inline-block;
}

.passive {
    background-image: url(../../../content/images/svg/do_not_disturb.svg);
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 19px 19px;
    height: 19px;
    opacity: 1;
    width: 19px;
    display: inline-block;
}

.ban {
    background-image: url(../../../content/images/svg/block.svg);
    color: $trimble_forestry_orange;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 19px 19px;
    height: 19px;
    opacity: 1;
    width: 19px;
    display: inline-block;
}

.cancel {
    background-image: url(../../../content/images/svg/cancel_filled.svg);
    color: $trimble_black;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 19px 19px;
    height: 19px;
    opacity: 1;
    width: 19px;
    display: inline-block;
}

.viol-undefined-status-icon {
    background-image: url(../../../content/images/svg/VIOL_status_undefined.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
}

.viol-success-status-icon {
    background-image: url(../../../content/images/svg/VIOL_status_success.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
}

.viol-in-progress-status-icon {
    background-image: url(../../../content/images/svg/VIOL_status_inProgress.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
}

.viol-failed-status-icon {
    background-image: url(../../../content/images/svg/VIOL_status_failed.svg);
    color: $trimble_white;
    background-size: 19px 19px;
    border-radius: 0.3em;
    cursor: pointer;
    margin: auto;
    display: block;
    height: 19px;
    width: 19px;
}