﻿.machine-additional-info-modal-window {

	.title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .machine-type {
        background-color: lightgray;
        height: 3em;
        padding-top: 1em;
        padding-left: 1em;

        .machine-type-label {
            text-transform: uppercase;
        }
    }

    .timber-truck-image {
        margin-top: -1.5em;
        margin-right: 1em;
    }

    .chipping-truck-image {
        margin-top: -0.3em;
        margin-right: 1em;
    }

    .table-width {
        width: 100%;
    }

    .margin-top {
        margin-top: 1em;
    }

    .border-right {
        border-right: 1px solid lightgray;
    }

    .border-bottom {
        border-bottom: 1px solid lightgray;
    }

    .border-collapse {
      border-collapse: inherit;
    }

    .cell-width-quarter {
        width: 25%;
    }

    .cell-width-half {
        width: 50%;
    }

    .cell-width-three-quarters {
        width: 75%;
    }

    .cell-width-7percent {
        width: 7%;
    }

    .cell-width-18percent {
        width: 18%;
    }
    
    .padding-left {
        padding-left: 1em;
    }

    .padding-right {
        padding-right: 1em;
    }

    .padding-bottom {
        padding-bottom: 0.5em;
    }

    .padding-top {
        padding-top: 0.5em;
    }

    .label-margin-bottom {
        margin-bottom: 0;
    }

    .text-editor {
        border: 1px solid;
        border-radius: 4px;
        border-color: #616161;
        height: 24px;
        padding-left: 9px;
        padding-top: 4px;
    }

    .text-editor-full {
        @extend .text-editor;
        width: 90%;
    }

    .text-editor-short {
        @extend .text-editor;
        width: 80%;
    }

    .dropdown-editor {
        height: 24px;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 5px;
        width: 90%;
    }

    .radio-button-align {
        vertical-align: middle;
        margin-top: -2px;
    }

    .radio-button-margin-left {
        margin-left: 2em;
    }

    hr {
        clear: both;
        border-color: $gray;
    }

    .additional-info-text {
        resize: none;
        border: 1px solid;
        border-radius: 4px;
        border-color: #616161;
        margin-top: 0;
        width: 100%;
    }

    .truck-load-headertable {
        background-color: lightgray;
        height: 2em;
        padding-top: 0.4em;
        margin-top: 0.5em;
    }

    .truck-load-number {
        background-color: $blue;
        width: 2em;
        color: white;
        padding-left: 1.2em;
        border-top-right-radius: 0.3em;
        border-bottom-right-radius: 0.3em;
    }

    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        top: 0;
    }
            
    [type="checkbox"]:not(:checked) + label:after,
	[type="checkbox"]:checked + label:after {
	    top: 0px;
    }

    .form-group {
        padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
		padding-bottom: 5px;

		.padded-container {
			padding: 0 !important;
		}
    }
}
