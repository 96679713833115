@import '../../../shared/sass/_variables.scss';

.button-with-options {
    float: left;

    &:hover {
        .btn-silva {
            background: $trimble_blue_mid;
            border: 1px solid $trimble_blue_mid;
        }
    }

    .add-button {
        width: 220px;
    }

    .dropdown-content {
        position: absolute;
        z-index: 9999;
        border-left: 1px solid $gray;
        border-right: 1px solid $gray;
        border-bottom: 1px solid $gray;
    }

    .option {
        background-color: white;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        font-size: 14px;
        width: 262px;
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;

        &:hover {
            background-color: #eee;
            border-top: 1px solid $gray;
            border-bottom: 1px solid $gray;

            &:disabled {
                background-color: white;
                border-top: 1px solid $white;
                border-bottom: 1px solid $white;
            }
        }
    }

    .disabled-option {
        color: $gray;
        cursor: not-allowed;
        pointer-events: none;
    }

    &.open-options-up {
        position: absolute;
        display: inline-block;
            
        .dropdown-content {
            bottom: 34px;
        }
    }
}
