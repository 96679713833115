﻿@import '../../../../../shared/sass/_variables.scss';

.view-energy-trade-area-modal {
    .modal-dialog {
        width: 25%;
        top: 28%;

        .modal-body {
            padding: 0;
        }
    }

    .padding-top-8 {
        padding-top: 8px;
    }

    .padding-25 {
        padding: 25px;
    }

    .wood-type {
        padding: 10px 40px;
    }
}
