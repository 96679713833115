﻿@import '../../../../shared/sass/_variables.scss';

$controlHeight: 27px;
$border-radius: 4px;

.services-tab {
    font-size: 14px;

    input, .quota-token {
        border-radius: $border-radius;
        border: 1px solid $darkgray;
    }

    .right-border-radius {
        border-bottom-right-radius: $border-radius !important;
        border-top-right-radius: $border-radius !important;
    }

    .no-right-border-radius {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .border-radius {
        border-radius: $border-radius !important;
    }

    hr {
        margin-left: -14px;
        width: 102.5%;
    }

    .services-tab-options {
        label {
            text-transform: uppercase;
            margin-right: 5px;

            input {
                position: relative;
                margin: 0px 5px 0px 0px;
                top: 2px;
            }
        }
    }

    .services-upper-controls {
        width: 100%;
        line-height: 30px;
        display: table;

        * {
            float: left;
            display: table-cell;
            vertical-align: middle;
        }

        input , .quota-token {
            padding: 0.2em;
            height: 27px;
        }

        input[disabled], .quota-token {
            background: $disabled-button-background-color;
        }

        .quota-token {
            span{
                position:relative;
                bottom: 5px;
            }
        }

        label {
            margin: 0px 5px 0px 5px;
        }

        :first-child {
            margin-left: 0px;
        }

        :last-child {
            margin-right: 0px;
            clear: right;
        }

        .name-input {
            width: 180px;
        }

        .date-input {
            width: 120px;
        }

        button {
            padding: 0px 2px 1px 2px;
            border-top: 1px solid $darkgray;
            border-right: 1px solid $darkgray;
            border-bottom: 1px solid $darkgray;
            border-radius: 0;

            img {
                padding: 2px;
            }

            img[disabled] {
                cursor: not-allowed;
            }
        }

        input.btn[disabled], button[disabled], button.btn[disabled], button.disabled, input.btn.disabled {
            border-top: 1px solid $darkgray;
            border-right: 1px solid $darkgray;
            border-bottom: 1px solid $darkgray;
        }

        .badge-container {
            position: relative;
            width: 0;
            height: 0;

            .badge {
                position: absolute;
                top: -12px;
                right: -12px;
                padding: 3px;
                margin-left: 15%;
                margin-bottom: 100%;
                margin-top: -100%;
                background-color: $blue;
                border-radius: 50% 50% 50% 0;                
                width: 17px;
                height: 17px;                                
            }            
        }

        .no-border {
            border: none !important;
        }
    }

    .forest-plans-grid-container {
        border-radius: 0.5em;
        border: 1px solid $darkgray;
        overflow: hidden;
        margin-top: 10px;        

        .forest-plans-grid {
            height: 330px;

            .ag-header {
                .ag-pinned-left-header {
                    .ag-header-row {
                        width: 100% !important;

                        .ag-header-group-cell-with-group {
                            width: 100% !important;

                            .ag-header-group-cell-label {
                                float: left;
                                padding-left: 5px;

                                .ag-header-group-text {
                                    text-transform: uppercase;
                                }
                            }
                        }

                        .wrap-on-two-cells {
                            border-right: none;
                            width: 150px !important;
                        }

                        .ag-header-group-cell {
                            border-right: none;
                        }
                    }
                }

                .ag-header-viewport {
                    .ag-header-container {

                        .ag-header-group-text {
                            display: none;
                        }

                        .ag-header-group-cell-with-group {
                            border-right: none;
                        }

                        .border-right-none {
                            border-right: none;
                        }
                    }
                }
            }

            .ag-row-odd {
                background-color: $white;
            }

            .ag-body {
                .ag-cell {
                    padding: 3px;
                }

                .ag-row-group {
                     background: $gray;
                }

                img {
                    vertical-align: middle;
                }

                .ag-selection-checkbox {
                    padding-left: 5px;
                }

                .checkbox-cell {
                    border-right: 1px solid $gray;
                }
            }
        }

        .services-tab-button-group {
            padding: 5px;
            border-top: 1px solid $darkgray;
            background-color: $lightgray;

            button {
                min-width: 200px;
                margin: 5px;
            }
        }
    }
}