﻿@import '../../../../../app/shared/sass/_variables.scss';

.link-trade-area-assortments-modal {
    .modal-dialog {
        width: 80%;
    }

    .additional-information {
        background-color: $gray;
        font-style: italic;
        padding: 1em;
    }

    .padding-top-15 {
        padding-top: 15px;
    }

    .grid-container {
        margin-bottom: 15px;
        border: 1px solid $sp-panel-border-color;
        border-radius: 5px;

        .sp-grid {
            height: 450px;
            border-radius: 5px;

            .ag-root.ag-root {
                border-radius: 5px;
            }

            .ag-body-viewport {
                overflow-x: hidden !important;
            }
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }
    }
}
