﻿.buyer-compensation-by-euro-dialog-window-class {
    .modal-dialog {
        top: 10%;
        width: 75em;
        height: 462px;
    }
}

.margin-top-3 {
    margin-top: 3px;
}

.report-problem {
    color: red;
    background-image: url(../../../../content/images/svg/report_problem_white.svg);
}

.grid-footer-row {
    background-color: #E2E2E7;
    border-top: 0.5px solid #B0AFB0;
    padding-top: 5px;
    height: 30px;
}

.right-margin-150 {
    margin-right: 150px;
}

.padding-left-0 {
    padding-left: 0px;
}
