﻿.filter-counties-field {
    select {
        width: 120px;
        height: 30px;
        border-radius:4px;
    }

    select:disabled{
       background-color: rgb(235, 235, 228);
       cursor:not-allowed;
    }
            
    .row-no-margin {
        margin-right: 0;
        margin-left: 0;
        height: 100%;
    }

    .full-height {
        height: 100%;
    }

    .container-directive {
        float: left;
        width: 100%;
    }

    .directive-margin {
        margin: 0em 0.5em 0.5em 0.5em;
    }

    .filter-kunta-form {
        .group-settings.settings-item {
            margin-top: 0;
            margin-bottom: 2px;
        }
    }
}
