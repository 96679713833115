.delivery-scheduling-modal {

	$color-modal-blue: #92afd2;
	$color-modal-dark-blue: #0e77aa;
	$color-modal-green: rgba(61, 88, 33, 1);
	$color-modal-orange: #ff9400;
	$color-modal-light-gray: #e8e8e8;
	$color-modal-dark-gray: #808080;

	.blue-cell {
		font-weight: bold;
		color: $color-modal-dark-blue;
	}

	.orange-cell {
		font-weight: bold;
		color: $color-modal-orange;
	}

	.header-container {
		margin: 0;
		border-bottom: 1px solid $color-modal-dark-gray;

		.top-header-container {
			display: flex;
			font-family: $font-semibold;
			margin: 0;

			.navigation-container {
				margin: auto;
				text-align: center;
				padding-left: 0px;

				.navigation {
					margin: auto;
					justify-content: space-between;
					list-style-type: none;
					padding: 0;
	
					.content {
						cursor: default;
						user-select: none;
					}
	
					.navigation-button {
						margin: 0;
						padding: 0;
						height: 1.5em;
						width: 1.5em;
						background-position: center bottom;
						background-size: contain;
						background-repeat: no-repeat;
						cursor: pointer;
						
						&.disabled {
							opacity: 0.4;
							pointer-events: none;
						}
		
						&.step-to-first {
							background-image: url('../../../../../../content/images/svg/Step-to-first.svg');
							&:hover {
								background-image: url('../../../../../../content/images/svg/Step-to-first-MouseOver.svg');
							}
						}
						&.step-to-last {
							background-image: url('../../../../../../content/images/svg/Step-to-last.svg');
							&:hover {
								background-image: url('../../../../../../content/images/svg/Step-to-last-MouseOver.svg');
							}
						}
						&.step-forward {
							background-image: url('../../../../../../content/images/svg/Step-forward.svg');
							&:hover {
								background-image: url('../../../../../../content/images/svg/Step-forward-MouseOver.svg');
							}
						}
						&.step-backward {
							background-image: url('../../../../../../content/images/svg/Step-backward.svg');
							&:hover {
								background-image: url('../../../../../../content/images/svg/Step-backward-MouseOver.svg');
							}
						}
					}
				}
	
			}
	
			.glyphicon-remove:hover {
				color: $color-modal-dark-blue;
				cursor: pointer;
			}
			
			.close-button {
				padding: 1em;
			}
		}
	
		.middle-header-container {
			margin: 0;
			background-color: $color-modal-light-gray;
			height: 50px;
			font-size: 12px;

			> div {
				border: 1px solid white;
				padding: 5px;
			}

			label {
				text-transform: uppercase;
			}

			.middle-header-item {
				text-align: left;
				flex-direction: column;
				
				&:first-child  {
					padding-left: 15px;
				}

				div {
					height: 17px;
				}

				input {
					border: 0;
					background: transparent;
				}

				.middle-header-item-split {
					padding: 0;
				}
			}
		}

		.bottom-header-container {
			margin: 0;
			margin-bottom: 10px;
			margin-top: 10px;
	
			.date-picker-container {
				margin-left: 15px;
				margin-right: 2px;
			}
	
			.month-container {
				padding: 0;
	
				.month-container-group {
					margin: auto;

					div {
						height: 27px;
						width: 18%;
						margin-bottom: 8px;
						margin-right: 10px;
						line-height: 27px;
						padding: 0;
					}
				}
			}

			.weekend-checkboxes-container {
				padding: 0;
				
				.weekend-column-saturday-label {
					text-align: right;
					padding-right: 10px;
				}

				div {
					padding: 0;
				}

				[type="checkbox"]:not(:checked) + label,
				[type="checkbox"]:checked + label {
					text-align: right;
					padding-right: 10px;

					&::before {
						top: 0;
					}
	
					&::after {
						top: 3px;
					}
				}

				
			}

		}
	}

	.calendar-container {
		overflow-y: scroll;
		height: 30vw;
		border-bottom: 1px solid $color-modal-dark-gray;

		.spinner-container {
			width: 100%;
			height: inherit;
			position: absolute;
			margin: 0;
			overflow: hidden;

			.spinner-background {
				background-color: gray;
				opacity: 0.1;
				width: 100%;
				height: inherit;
			}
		}

		.loader-container {
			width: 100%;
			height: 100%;	
		}

		.calendar-month {
			.calendar-header {
				display: flex;
				justify-content: space-around;
				text-align: center;
				margin-top: 15px;

				div {
					padding: 0;
				}

				.calendar-header-title {
					font-size: 2em;
					line-height: 2em;
					border-bottom: 0px;
					background-color: $color-modal-blue;
					text-align: center;
					color: white;
					font-weight: bold;
					margin-left: 3px;
				}

				.calendar-header-amount {
					> div {
						padding-right: 2em;
					}
					text-align: left;
				}
			}

			.calendar-body {
				text-align: center;
				.row {
					width: 100%;
					display: flex;
					margin: 0;
				}

				.calendar-body-week {
					text-align: left;
					line-height: 1vw;
					font-size: 1em;
					font-weight: bold;
					display: flex;
					justify-content: center;
					align-content: center;
					flex-direction: column;
					padding-top: 0px;
					padding-right: 15px;
					padding-bottom: 0px;
					padding-left: 15px;
				}

				.calendar-body-top {
					.calendar-body-day {
						background-color: black;
						text-align: center;
						border: 1px solid white;
						color: white;
						padding: 0;
						height: 2em;
						line-height: 2em;
						padding-left: 10px;
						padding-right: 10px
					}
				}
				
				.calendar-body-bottom {
					input {
						border: 0;
						text-align: center;
						width: 100%;
						font-size: 12px;
						margin: auto;
						border: 1px black solid;
						border-radius: 5px;
						
						&:disabled {
							background-color: lightgray;
						}

						&:focus {
							outline-width: 0;
						}
					}

					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					.calendar-body-week {
						span {
							padding-top: 5px; 
							font-size: 16px;
						}
					}

					.calendar-body-cell {
						border-bottom: 1px solid $color-modal-dark-gray;
						border-right: 1px solid $color-modal-dark-gray;
						height: 60px;
						padding: 0;
						text-align: right;
						line-height: 1vw;
						font-size: 1vw;
						color: black;
						cursor: default;
						padding-left: 10px;
						padding-right: 10px;

						&:nth-child(2) {
							border-left: 1px solid $color-modal-dark-gray;
						}

						span {
							padding-right: 10px;
							line-height: 30px;
							color: $color-modal-blue;
							font-weight: bold;
						}

						&.disabled {
							background-color: $color-modal-light-gray;
							cursor: default;
						}
					}
				}
			}
		}
	}

	.btn-container {
		padding: 1em;
		border-bottom: 1px solid black;

		.flat-btn {
			width: 12em;
		}
	}
}