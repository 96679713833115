﻿.summary-tab {

    .padding-top-8{
        padding-top: 8px;
    }

    .no-border-bottom {
        border-bottom: 0px;
    }

    .border-left-top-radius {
        border-top-left-radius: 5px;
    }

    .border-right-top-radius {
        border-top-right-radius: 5px;
    }

    .border-left-bottom-radius {
        border-bottom-left-radius: 5px;
    }

    .border-right-bottom-radius {
        border-bottom-right-radius: 5px;
    }

    .height-55 {
        height: 55px;
    }

    .m-10 {
        margin: 10px;
    }

    .grid-container {
        border: 1px solid $sp-panel-border-color;
        border-radius: 5px;

        .h-grid-155 {
            height: 155px !important;
        }

        .sp-grid {
            .ag-root.ag-root {
                border-radius: 0px;
            }

            .ag-header-cell-label {
                padding-top: 3px !important;
            }

            .grid-check-box {
                top: -8px;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    top: -8px;
                }
            }

            .grid-header-checkbox-label {
                margin-bottom: 12px;
            }
            /*.ag-selection-checkbox {
                display: none;
            }*/
            .ag-body-viewport {
                overflow-x: hidden !important;
                border-radius: 5px;
            }

            .cell-header-right {
                .ag-header-cell-text {
                    float: right !important;
                }
            }
        }

        .ag-row .checkbox-cell {
            padding-top: 5px;
        }

        .select-all-wrapper {
            left: 3px;
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }

        .selection-change {
            background-color: red;
            color: white;
        }

        .padding-top-5 {
            padding-top: 5px !important;
        }

        .padding-top-6 {
            padding-top: 6px;
        }
    }

    .file-attachments {
        .attachment-grid-container {
            .attachmentGrid {
                height: 156px;
                border: 0px solid #616161;
            }

            .sp-grid .grid-check-box {
                top: 0px;
            }

            .select-all-wrapper {
                left: 12px !important;
                padding-top: 2px;
            }
        }
    }

    .padding-0 {
        padding: 0px !important;
    }

    .margin-0 {
        margin: 0px !important;
    }

    .margin-10 {
        margin: 10px !important;
    }

    .ht-300 {
        height: 300px !important;
    }

    .ht-355 {
        height: 355px;
    }

    .ht-330 {
        height: 330px !important;
    }

    .ht-440 {
        height: 440px;
    }

    .ht-332 {
        height: 332px !important;
    }

    .ht-235 {
        height: 235px !important;
    }

    .border-left {
        border-left: 0.75px solid $half_trimble_brand_grey;
    }

    .border-right {
        border-right: 0.75px solid $half_trimble_brand_grey;
    }

    .icon-container {
        text-align: center;
        margin: 46px 30px;

        &.small {
            margin: 23px 30px;

            &.no-margin-top {
                margin-top: 0px;
            }
        }
    }



    .print-button {
        padding: 0 5px;
        height: 67px;
        border-radius: 0.3em;
        width: 71px;
    }

    .print-button-margin {
        margin-bottom: 0px;
        margin-top: 55px;
    }

    .icon {
        height: 75px;
        width: 75px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        background-color: #009AD9;

        &.icon-printer {
            background-image: url(../../content/images/svg/material-print-white-36dp.svg);
            height: 50px;
            width: 45px;
            background-color: transparent;
            margin-left: 4px;
        }

        &.icon-esign , &.icon-esign-green , &.icon-esign-orange {            
            height: 50px;
            width: 45px;           
            margin-left: 4px;
        }
    }

    .print-container {
        width: -webkit-fill-available;
        border-radius: 5px;
        border: 1px solid #808080;

        .print-sign-disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }

    .print-header {
        padding: 0.5em;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-left: 6px;
        font-family: "Myriad Pro", Verdana;
        font-size: 12px;
        font-weight: 700;
        background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
        border-bottom: 1px solid #808080;
        color: #000;
    }

    .float-right {
        float: right !important;
    }

    .float-left {
        float: left !important;
    }

    .base {
        padding: 0px 15px;
    }

    .icone-invoice {
        height: 50px;
        width: 45px;
        background-color: transparent;
        margin-right: 0;
    }
}
