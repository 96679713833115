.modal-open {
    overflow: hidden;
}

.worksite-edit {
    height: 100%;
    border: 1px solid #616161;
    border-radius: 0.5em;
    background-color: #ffffff;

    .full-screen-close-glyphicon {
        right: 8px;
    }

    .timber-trade-header {
        display: flex;

        .header-title {
            width: 40%;
        }

        .header-info {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            padding-right: 10px;
            font-weight: normal !important;
        }
    }

    textarea {
        height: 100px !important;
        overflow: auto;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .buttons-group {
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 0.75px solid $trimble_brand_grey;
        background: $gray 0% 0% no-repeat padding-box;
        border-radius: 0 0 6px 6px;
        display: flex;

        .btn-wrapper {
            display: flex;
            width: 50%;
        }

        .save-btn {
            justify-content: end;
            margin-right: 10px;
        }
    }

    .measurement-height {
        height: 564px;
    }

    .product-height {
        height: 450px;
    }

    .contract {
        font-size: 14px;
        padding-right: 0;

        .popover {
            z-index: 1000;
        }

        .row {
            margin-right: 0;
            margin-left: 0;
        }

        .form-control {
            height: 27px;
        }

        .col-xs-5,
        .col-xs-12 {
            padding-right: 0;
            padding-left: 0;
        }

        .sp-grid {
            height: 250px;
            margin-bottom: 10px;
        }

        div#borderLayout_eRootPanel {
            height: 250px;
        }

        .ag-body-viewport {
            overflow-x: hidden !important;
            overflow-y: auto;
        }

        .grid-border {
            padding-right: 15px;
        }
    }

    .panels-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
    }

    .panels {
        display: flex;
        flex-direction: column;
        border-right: 1px solid grey;
        border-bottom: 1px solid gray;
    }

    .no-bottom-border {
        border-bottom: none;
    }

    @media (max-width: 1920px) and (min-width: 1900px) {
        .panels:nth-child(3),
        .panels:nth-child(6) {
            border-right: 0;
        }
    }

    @media (max-width: 2508px) and (min-width: 1900px) {
        .panels:nth-child(4),
        .panels:nth-child(5),
        .panels:nth-child(6) {
            border-bottom: none;
        }
    }

    @media (max-width: 3128px) and (min-width: 2516px), (max-width:1896px) and (min-width: 1292px)  {
        .panels:nth-child(5),
        .panels:nth-child(6) {
            border-bottom: none;
        }
    }

    @media (max-width:3740px) and (min-width: 3132px), (max-width:1288px) {
        .panels:nth-child(6) {
            border-bottom: none;
        }
    }

    @media (min-width: 3744px) {
        .panels {
            border-bottom: none;
        }
    }

    &-header {
        width: 100%;
        background-color: #d1d1d1;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        padding: 0.5em;
        border-radius: 0.5em 0.5em 0 0;
        padding-left: 15px;
    }

    .grid-footer-buttons {
        margin: 0px;
        padding: 10px;
        border-top: 1px solid $trimble_brand_grey;

        .button-right-margin {
            margin-right: 10px;
        }
    }

    .smaller {
        height: 1357px;
    }

    &-panel {
        background-color: #ffffff;
        height: 1408px;
        width: 612.5px;

        &-title {
            background-color: #000000;
            border-bottom: none;
            border-radius: 0;
            color: #ffffff;
            font-family: "Myriad Pro Semibold";
            font-size: 14px;
            text-transform: uppercase;
            height: 30px;
            display: flex;
            align-items: center;
            padding-left: 15px;
        }

        &-tab-wrapper {
            padding: 15px;
            height: 100%;
        }
    }
}

.top-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.contract-term {
    .grid-container {
        height: 305px !important;
        margin: 15px 0px 0px 0px;
        border: 1px solid grey;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
    }

    .contract-terms-phrases-modal .grid-border .button-container-grid {
        padding: 10px;
        background-color: #e8e8e8;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    [type="checkbox"]:not(:checked)+label:before,
    [type="checkbox"]:checked+label:before {
        left: -4px;
    }
}