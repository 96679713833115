﻿@import './manangingHaravesting.scss';

.inventory-statuses {
    margin-top: 0.5em;

    .list-view-settings-row {
        .view-option-select .btn {
            width: 300px;
        }
        .advanced-filtering-dropdown {
            .dropdown-content {
                height: 386px;
            }

            .has-extra-row {
                height: 395px;
            }

        }

    }

    .no-rows-bubble {
        margin-left: 36.5%;
        padding: 10px;
        background-color: white;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
        text-align: left;
        width: 162px;
    }

    sp-common-grid-header {
        text-transform: uppercase;
    }

    // for inventoryStatuses.html, storageAmounts.html, inventoryStatusesReleaseToTransport.html
    .sp-grid {
        .ag-group-cell {
            outline: none;
        }

        .three-char-window .flex-container {
            width: calc(3ch + 2px);
        }

        .four-char-window .flex-container {
            width: calc(4ch + 2px);
        }

        .custom-filter {
            input {
                border-radius: 3px;
                border: 1px #616161 solid !important;
                margin-top: 3px;
                padding: 1px 5px;
            }
        }
    }

    // spesifics for the actual inventoryStatuses grid (page)
    #inventory-statuses-grid {
        .refine-search-directive {
            padding-right: inherit;
            padding-left: inherit;

            label {

                // align for all checkboxes
                &::before {
                    top: 7px;
                }
            }

            .refine-search-dropdown-directive {
                top: 34px;

                .search-button {
                    button {
                        margin-left: 0;
                    }
                }
            }
        }

        .not-selectable-row-from-group {
            display: none;
        }
    }


    .icon-expand-button {
        width: 34px;
        height: 35px;
        padding-top: 6px;

        &:hover,
        &:focus {
            background-color: initial;
            color: $trimble_tf_product_green;
        }

        .icon-expand {
            display: flex;
            align-content: center;
            justify-content: center;
            font-size: 17px;
        }
    }

    .favourit-grid-configs {
        margin-left: 4px;
    }


    #inventory-statuses-grid {
        .panel-sp-grid {
            width: inherit;
        }

    }

    &.container {
        .saved-lists-dropdown {
            position: absolute;
            top: 100%;
            z-index: 1000;
            padding: 5px 0;
            margin: 2px 0 0;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, .15);
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            left: 0;
        }

        #inventory-statuses-grid {
            .panel-sp-grid {
                width: 1138px;
            }

            .common-grid-toolbar {
                width: 1138px;
            }
        }

        .icon-expand-button {
            padding-top: 6px;
        }
    }

    .saved-lists-dropdown-storage .favorite-radio-inline input {
        margin: 0;
    }


    .ag-icon-expanded,
    .ag-icon-contracted {
        background-color: white;
    }

    .span-float-right {
        span {
            float: right;
        }
    }

    .ag-cell {
        .ag-selection-checkbox {
            display: inline-block;
        }
    }

    .common-grid-checkbox {
        .ag-selection-checkbox {
            display: none;
        }
    }

    .sp-grid .ag-body .ag-body-container .ag-row:hover .ag-cell,
    .sp-grid .ag-body .ag-body-container .ag-row-selected .ag-cell {
        color: white;
    }
}