@import "app/shared/sass/_placeholders";
@import "app/shared/sass/_variables.scss";

.forest-information-tab {
    min-height: calc(485px - 2em);
    display: flex;
    flex-direction: column;

    .forest-information-options {
        height: 18px;
        margin-bottom: 1em;

        .no-padding {
            padding: 0px;
        }

        .search-label {
            margin-top: 3px;
        }

        .search-area {
            @extend %silva-search;

            .search-bar {
                .glyphicon {
                    top: 3px;
                }

                .glyphicon-remove {
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }

    label {
        margin-right: 1em;
    }

    .forest-assets-grid {
        .ag-cell:focus, input:focus {
            outline: none;
        }

        .cell-date input {
            left: 80px;
            position: absolute;
        }

        .mv-suost-cell {
            select {
                pointer-events: none;
            }
        }
    }

    .sp-grid {
        border: 1px solid $darkgray;
        border-top-left-radius: 0.3em;
        border-top-right-radius: 0.3em;
        height: 376px;
        overflow-x: hidden;

        .ag-floating-bottom {
            display: none;
        }

        .ag-header-select-all {
            width: 25px;
            padding: 0px;
            padding-top: 5px;
            text-align: center !important;
        }

        .ag-selection-checkbox {
            padding: 0px !important;
        }

        .cell-date input {
            position: absolute;
        }

        .ag-header-row {
            .ag-header-group-cell {

                &:last-child {
                    border-right: 0;
                }

                .ag-header-group-cell-label {
                    padding-left: 4px;
                    text-align: left;
                }
            }

            .ag-header-cell {
                &:last-child {
                    border-right: 0 !important;
                }
            }                    
        }

        .ag-body-viewport {
            overflow-x: hidden;
        }

        .ag-row {
            .ag-cell {
                &:last-child {
                    border-right: 0 !important;
                }                
            }
            
            &.active-row {
                .ag-cell {
                    &.active {
                        background-color: #3AAE2C;
                        color: white;
                    }
                }
            }
        }

        
        .ag-row-hover, .ag-row-selected, .ag-row-focus {                
            .ag-cell {                        
                color: white !important;
                background-color: black !important;                        
            }                                                                                                                                                 
        }
        

        .grid-user-input {
            text-align: center;

            label {
                margin-right: 0;
                width: 18px;
                display: inline-block;
                padding-left: 0;
            }
        }

        .cell-aligned-right {
            text-align: right;
            padding-right: 0.3em;

            &.forest-plan-type-cell {
                padding-left: 5px;
                text-align: left;
            }

            &.forest-plan-type-header {
                padding-left: 2px;
                text-align: left;
            }
        }

        .cell-aligned-center {
            text-align: center;

            .ag-header-cell-text {
                padding-left: 0;
            }
        }

        .upper-case {
            text-transform: uppercase !important;
        }
    }
}
