﻿@import '../../shared/sass/_variables.scss';

.membership {
    $panel-padding: 5px;
    $cell-height: 25px;
    $same-left-margin: 30px;
    font-family: $font-family;
    font-size: $default-font-size;

    .memberhips-left-panel {
        border: 1px solid #616161;
        border-radius: 0.5em;
        overflow: hidden;
        float: left;
        width: 45%;

        .membership-grid {
            height: 335px;

            .ag-root {
                border: none;

                .grid-check-box {
                    top: -9px;
                    left: 2px;
                }

                .grid-check-box-selection {
                    top: -4px;
                }

                .clear-both {
                    clear: both;
                }

                .ag-header-container {
                    .ag-header-row {
                        .upper-left {
                            width: 40%;
                            float: left;
                        }

                        .upper-right {
                            width: 60%;
                            float: right;
                        }

                        .lower-left {
                            width: 80%;
                            float: left;
                        }

                        .lower-right {
                            width: 20%;
                            float: right;
                        }

                        .ag-header-cell {
                            text-align: left;
                            text-transform: uppercase;
                            font-weight: bold;
                            text-indent: 5px;

                            .cell-wrapper {
                                padding-top: 4px; //move the headers a bit down => center the headers vertically
                                width: 100%;
                                height: 26px;
                            }

                            .gray-bottom-border {
                                border-bottom: 1px solid gray;
                            }

                            .gray-right-border {
                                border-right: 1px solid gray;
                            }

                            .center-content {
                                text-align: center;
                                text-indent: 0px;
                            }
                        }
                    }

                    .left-padding-zero {
                        .ag-header-cell-label {
                            padding-left: 0px;

                            .ag-header-cell-text {
                                padding-left: 0px; //Every cell should have similar left margin (correct the ones with vertical lines)
                            }
                        }
                    }

                    .no-rigt-border {
                        border-right: none !important;
                    }
                }

                .ag-body-container {
                    .WhiteLockOpen, .WhiteLockClosed {
                        display: none;
                    }

                    .ag-row {
                        border-bottom: 1px solid black;

                        .upper-left {
                            width: 40%;
                            float: left;
                            background-color: $lighter-grey-color;
                        }

                        .upper-middle {
                            width: 40%;
                            float: left;
                            background-color: $lighter-grey-color;
                        }

                        .upper-right {
                            width: 20%;
                            float: left;
                            background-color: white;
                        }

                        .lower-left {
                            width: 80%;
                            float: left;
                            background-color: $lighter-grey-color;
                        }

                        .lower-right {
                            width: 20%;
                            float: left;
                            background-color: white;
                        }

                        .ag-cell {
                            border: none;
                            border-right: 1px solid $gray;
                            padding: 0px;

                            *[disabled="disabled"] {
                                cursor: not-allowed;
                            }

                            [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
                                padding-left: 20px; //overwrite a general setting
                            }

                            [type="checkbox"]:disabled + label {
                                cursor: not-allowed;
                            }

                            [sp-bank-account] select {
                                margin-top: 6px;
                                padding: 2px 0px 0px 0px;
                            }

                            .cell-wrapper {
                                width: 100%;
                                height: $cell-height;
                                background-color: inherit; //inherit the background color
                                padding: 4px 0px 0px 3px;

                                input {
                                    border: none;
                                    width: 100%;
                                    font-size: 14px;
                                    background-color: inherit;
                                }

                                select {
                                    height: 27px;
                                }

                                .overflow-ellipsis {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }

                            .center-horizontally {
                                padding-left: 35px;
                            }

                            .center-vertically {
                                text-align: center;
                                text-indent: 0px;
                                padding-left: 0px;
                            }

                            .light-grey-bottom-border {
                                border-bottom: 1px solid $gray;
                            }

                            .light-grey-right-border {
                                border-right: 1px solid $gray;
                            }

                            .checkbox-wrapper {
                                padding-top: 8px;
                            }

                            .icon-column {
                                padding-left: 10px;
                            }
                        }
                    }

                    .ag-row-selected, .ag-row:hover {
                        .ag-cell, input:not(.ng-invalid) {
                            background-color: black;
                            color: white;
                            overflow: visible;

                            input.ng-invalid {
                                background-color: #FFD8D8;
                                color: black;
                            }

                            *[disabled="disabled"] {
                                background-color: black;
                                cursor: not-allowed;
                                color: white;
                            }

                            select {
                                background-color: white;
                                color: black;
                            }

                            .upper-left, .upper-middle, .lower-left, .upper-right, .lower-right {
                                background-color: black;
                            }
                        }

                        .LockOpen, .LockClosed {
                            display: none;
                        }

                        .WhiteLockOpen, .WhiteLockClosed {
                            display: block;
                        }
                    }

                    .no-rigt-border {
                        border-right: none !important;
                    }
                }
            }
        }
    }

    .membership-right-panel {
        border: 1px solid #616161;
        border-radius: 0.5em;
        float: right;
        overflow: hidden;
        width: 54%;

        .panel-header {
            background-color: lightgray;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 0.5em 0.5em 0 0;
            padding: 3px 10px;
        }

        .top-left-margin-default {
            margin-left: 0.5em;
            margin-top: 0.5em;
        }

        .top-left-right-margin-default {
            margin-left: 0.5em;
            margin-right: 0.5em;
            margin-top: 0.5em;
        }

        .top-part-height {
            height: 335px;
            background-color: white;
        }

        .control-label {
            margin-bottom: -0.5em;
            font-size: 14px;
            background-color: inherit;
        }

        .payment-area-radio-group {
            label {
                vertical-align: top;
            }
        }
    }

    .membership-button-group {
        padding: 5px;
        border-top: 1px solid black;
        background-color: $gray;

        button {
            margin: 5px;
            min-width: 200px;
        }
    }

    .membership-main-button-group {
        button {
            min-width: 200px;
            padding: 10px;
            margin: 20px 13px 20px 0px;
        }
    }

    .membership-processing-margins {
        padding-left: 20px;
        padding-right: 20px;
    }

    .execute-button {
        margin-left: 5px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .membership-row {
        margin-bottom: 20px;
    }
}

.remove-button {
    margin-left: 10px;
}

.full-width {
    width: 100%;
}
