﻿@import '../../shared/sass/_variables.scss';

$bolierplateTextsSelectorGridHeight: 654px;
$panel-margin: 3px;

.boiler-texts-container {
    /*height: $bolierplateTextsSelectorGridHeight;*/

    .ag-theme-fresh .ag-menu {
        width: 260px;
    }

    .active-text {
        top: 5px;
        left: 10px;
        display: inline-flex;
    }

    .glyphicon-search {
        left: 5px;
    }

    .bolierplateTexts-search {
        .glyphicon {
            top: 0px;
        }
    }

    .padding-left-10 {
        padding-left: 10px;
    }

    .padding-left-15 {
        padding-left: 15px;
    }

    .material-switch-label {
        top: -7px;
    }

    .right-pannel {
        height: 710px;
    }

    .material-switch-content {
        > input[type="checkbox"]:checked + label::after {
            top: 4px;
        }

        > input[type="checkbox"]:not(:checked) + label::after {
            top: 3px;
        }
    }

    .material-switch-container {
        /*width: 50px;*/
        float: left;
        top: 5px;
        display: inline-block;
    }

    .bolierplateTexts-search {
        line-height: 30px;
        height: 30px;
        margin: 0 0px 13px 0;
        width: 20%;
    }

    .bolierplateTexts-selection-grid {
        height: $bolierplateTextsSelectorGridHeight;
        box-shadow: 0px 3px 6px #00000029;
        // .sp-grid .ag-root.ag-root {
        //     border-radius: 3px 3px 0px 0px;
        // }
        // .ag-header-cell {
        //     display: table-cell;
        //     height: 100%;
        //     vertical-align: top;
        //     padding-left: 2px;
        // }
        // .ag-scrolls {
        //     /*.ag-header-row {
        //         background: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;*/
        //     /*border-radius: 3px 3px 0px 0px;*/
        //     /*height: 30px !important;*/
        //     /*}*/
        // }
        // .ag-cell {
        //     font-size: $default-font-size;
        //     text-overflow: ellipsis;
        //     padding: 6px;
        //     padding-left: 3px;
        // }
        // .grid-check-box-selection {
        //     top: -9px;
        // }
        // .ag-header-group-cell-label {
        //     text-align: left;
        //     padding: 6px !important;
        //     padding-left: 5px !important;
        // }
        // .center-column {
        //     .ag-header-cell-text {
        //         display: inline-block;
        //         width: 100%;
        //         text-align: center;
        //     }
        // }
        // .ag-header-group-cell-label {
        //     text-align: left !important;
        //     left: 8px !important;
        // }
        // .ag-theme-fresh .ag-header-cell-text {
        //     padding-left: 8px;
        // }
        // .ag-header-cell {
        //     padding: 2px !important;
        //     padding-left: 0px !important;
        // }
        // .ag-floating-bottom-container .ag-cell {
        //     background: #D1D1D1;
        // }
        // .ag-root .ag-header {
        //     background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
        // }
    }

    .header-upper-part {
        text-align: left;
        text-transform: uppercase;
    }

    .boiler-texts-container-content {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 0.75px solid #B0AFB0;
        border-radius: 3px 3px 3px 3px;

        .titleBoxText {
            width: 70.5em;
        }

        .selection-grid-footer {
            padding: 5px;

            .pull-right {
                margin-right: 38px;
            }
        }

        .standard-text-header {
            height: 30px;
            padding: 6px 0px 8px 6px;
            background: transparent linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3) 0% 0% no-repeat padding-box;
            text-transform: uppercase;
            font-weight: 700;
            padding-left: 5px;
            border-bottom: 0.75px solid #B0AFB0;
        }

        .standard-text-header-black {
            height: 30px;
            padding: 6px 0px 8px 6px;
            text-transform: uppercase;
            background: #000000 0% 0% no-repeat padding-box;
            color: #FFFFFF;
            font-weight: 700;
            padding-left: 5px;
        }

        .standard-text-custom-width-label {
            width: 22%;
        }

        .standard-text-custom-width-control {
            width: 78%;

            .multiselection-drop-down .checkboxes .checkboxesList {
                width: 100%;
            }
        }

        .fields-content {
            padding: 30px;
            display: inline-block;
            width: 100%;
        }

        .boiler-text-input-field {
            height: 30px;
            border: 0.5px solid #B0AFB0;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            width: 100%;
            border-radius: 5px;
        }

        .vertical-line {
            border-left: 0.5px solid #B0AFB0;
            height: 120px;
            position: absolute;
            left: 10%;
            margin-left: -3px;
            top: 0;
        }

        .check-box-group {
            left: 50px;
        }

        .top-15 {
            top: 15px;
        }

        .check-box-label {
            padding-top: 8px;
        }

        .fill-textarea {
            height: 125px;
        }

        .text-textarea {
            height: 100% !important;
            width: 100%;
            margin: 0px;
            border: 0.75px solid #B0AFB0;
            resize: none;
        }

        .boiler-text-footer {
            border-radius: 0px 0px 3px 3px;
            background: #e8e8e8 0% 0% no-repeat padding-box;
            height: 54px;
        }

        .boiler-plate-button {
            padding: 10px;
        }
        /*.boiler-plate-button button {
            height: 45px;
        }*/

        .multiselection-drop-down {
            line-height: 30px;
            width: 100%;
            border-radius: 0px;

            .titleBox {
                width: 100%;
                border-radius: 5px;
            }

            .checkboxes {
                width: 100%;
                border-radius: 0px;

                .select-all-label {
                    line-height: 30px;
                }

                .checkboxesList .checkboxLabel {
                    line-height: 35px;
                }
            }

            .multiselectionSearch {
                height: 30px;
                margin-bottom: 5px;

                .multiselectionSearchInput {
                    width: 95%;
                }
            }
        }

        .right-panel-footer {
            height: 30px;
            background: #D1D1D1;
            padding: 6px;
        }

        .text-textarea {
            padding: 0px;
            border: none;
            box-shadow: none;
        }

        .text-textarea:focus {
            -webkit-box-shadow: none;
        }
    }

    .container-padding {
        padding-left: 15px;
    }
}
