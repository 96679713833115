/*
Generic modal class for overriding Bootstrap modal styling
Example, that produces a grid with header and footer.

<div class="modal sp-modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="close-button pull-right glyphicon glyphicon-remove" ng-click="ctrl.cancel()"></div>
        <h4 class="modal-title">Modal title</h4>
      </div>
      <div class="modal-body">
        <p>One fine body&hellip;</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
*/
$sp-modal-padding: 1em;

.sp-modal {
  .modal-header {
    padding: $sp-modal-padding;
    border-bottom: 1px solid $gray;
    text-transform: uppercase;
    font-weight: bold;

    .close-button {
      cursor: pointer;
    }

    .modal-title {
      font-size: 1em;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .modal-body {
    padding: $sp-modal-padding;
    border: 0;

    input[type="date"].special-width-date {
      width: 71%;
    }
  }

  .modal-footer {
    padding: $sp-modal-padding / 2;
  }
}