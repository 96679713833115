@import '../../../../shared/sass/_variables.scss';

.transport-markings {
    &.main-item {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .tool-type {
        width: 100%;
        padding: 0.5em;
        padding-left: 2.5em;
        margin: 0;
        min-height: 2.7em;
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;
        overflow: hidden;

        .type-text {
            flex-grow: 1;
            position: relative;
            top: 1px;
            left: -14px;
        }

        input[type="radio"] {
            display: none;
        }

        &:hover {
            background-color: $black;
            color: $white;

            .glyphicon-play {
                color: $white;
            }
            // white icons on hover on content-element-icons
            .content-element-icon {
                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_TransportIcon_Selected.svg');
            }
        }

        .content-element-icon {
            position: relative;
            left: -25px;
            width: 24px; // this is to align the images with the close button which is 24 x 24 pixels, sorry for absolute :/
            height: 1.5em;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: 0 !important;
            background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_TransportIcon.svg');
        }

        .glyphicon-play {
            color: $black;
            right: 0.5em;
        }
    }

    &.active {
        flex-grow: 1;
        border-bottom: 0;
        border-color: $black;
        // the selected one is black;
        .tool-type {
            background-color: $black;
            color: $white;
            // turn the play of the selected one 90 degrees
            .glyphicon-play {
                transform: rotate(90deg);
                color: $white;
            }
            // white icons on .active on content-element-icons
            .content-element-icon {
                background-image: url('/content/images/svg/map/DrawPanel/DrawPanel_MapSymbols_TransportIcon_Selected.svg');
            }
        }
    }

    .tool-scrollarea {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .tool-content {
        .current-selection {
            border-bottom: 1px solid $gray;

            .selection-container {
                margin-left: 0.5em;
                margin-right: 0.5em;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                min-height: 3em;
                display: flex;
                align-items: center;
                pointer-events: all;
                cursor: pointer;
                position: relative;

                &.make-selection {
                    text-transform: uppercase;

                    .selection-text {
                        font-weight: normal;
                    }
                }

                .selection-text {
                    flex-grow: 1;
                    font-weight: bold;
                }

                input[type=radio] {
                    width: 2em;
                }

                img {
                    height: 2em;
                    width: 2em;

                    &.additional-green-bubble {
                        position: absolute;
                        left: 3.1em;
                        top: 0.5em;
                        height: 0.8em;
                        width: 0.8em;
                    }
                }

                .open-accordion-arrow {
                    top: -1px;
                }
            }

            &.current-selection-open {
                .selection-container {
                    .open-accordion-arrow {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .selections {
            border-bottom: 1px solid $gray;
            padding-top: 0.5em;
            padding-bottom: 0.5em;

            label {
                margin-left: 0.5em;
                margin-right: 0.5em;
                min-height: 2em;
                display: flex;
                width: 100%;
                align-items: center;
                position: relative;

                input[type=radio] {
                    width: 2em;
                }

                img {
                    height: 2em;
                    width: 2em;

                    &.additional-green-bubble {
                        position: absolute;
                        left: 3.1em;
                        top: 0em;
                        height: 0.8em;
                        width: 0.8em;
                    }
                }
            }
        }

        .form-row {
            margin: 0.5em;
            margin-bottom: 0;

            &.checkbox {
                display: flex;
                align-items: center;

                label:last-child {
                    padding-left: 0;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                [type="checkbox"]:checked + label:before,
                [type="checkbox"]:not(:checked) + label:before {
                    top: -2px;
                }
                /* checked mark aspect */
                [type="checkbox"]:checked + label:after,
                [type="checkbox"]:not(:checked) + label:after {
                    top: 1px;
                    left: 2px;
                }
            }

            &:not(.checkbox) {
                label {
                    width: 100%;
                    margin-bottom: 0.5em;
                }
            }

            .control-label {
                text-transform: uppercase;
            }
        }

        .helper-text {
            text-transform: uppercase;
            margin: 0.5em;
            margin-bottom: 0;
            font-weight: 700;
        }

        .button-row {
            display: flex;
            margin: 0.5em;

            button {
                flex-grow: 1;

                &:first-child:not(:only-child) {
                    margin-right: 0.5em;
                }

                &:last-child:not(:only-child) {
                    margin-left: 0.5em;
                }
            }
        }
    }

    &.modal {
        .modal-dialog {
        }

        .modal-header {
            display: flex;

            div:first-child {
                flex-grow: 1;
            }
        }

        .modal-footer {
            .footer-dual-button {
                display: flex;
                margin-bottom: 1em;

                button {
                    flex-grow: 1;

                    &:first-child {
                        margin-right: 0.5em;
                    }

                    &:last-child {
                        margin-left: 0.5em;
                    }
                }
            }

            .footer-full-button {

                button {
                    width: 100%;
                }
            }
        }
    }
}

.select-unassigned-storage-places-modal {
    .grid-container {
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        margin: 0.5em;

        .sp-grid .ag-root {
            border-radius: 0.5em;
        }
    }
}