.work-site-add-receivers-modal-window {
     [type="checkbox"]:not(:checked) + label:before, 
     [type="checkbox"]:checked + label:before {
        top: 9px;
    }

    .ag-row .checkbox-cell,
    .ag-row .ag-cell[col-id="selection"],
    .ag-row .common-grid-checkbox,
    .ag-row .ag-cell[col-id="$selected"] {
        padding-left: 2px !important;
        padding-top: 3px !important;
   }

    [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
        top: 9px;
        left: 0px;
    }

    div#addReceiverModalSelectAll {
        left: 3px;
    }
    #confirmUpdatePersonsInformation {
        width: 100%;
    }
    #AddReceiversGrid {
        height: 275px;
    }
    .grid-container {
        border: 0.75px solid #767876;
        border-radius: 3px;
    }
    #modal_label {
        margin-left: 20px;
        font-style: italic;
        font-weight: normal;
        margin-top: 5px;
    }
    .display-flex {
        display: flex;
    }
    .vertical-align {
        vertical-align: sub;
    }
    .modal-content {
        .title-bar {
            >div:not(.clearfix) {
                padding: 1em;
            }
            border-bottom: solid 1px $gray;
            .header {
                font-family: $font-semibold;
                margin: 0;
                position: relative;
                text-transform: uppercase;
                width: auto;
            }
            .glyphicon-remove:hover {
                color: $blue;
                cursor: pointer;
            }
        }
        .modal-body {
            padding: 0px 15px 0px 15px;
            .search-bar {
                border: 1px solid $gray;
                border-radius: 0.5em;
                height: 2em;
                line-height: 2em;
                overflow: hidden;
                padding: 0 0.4em;
                margin: 1.5em 0;
                input {
                    border: 0;
                    height: 2em;
                    outline: 0;
                    padding-left: 0.2em;
                    width: 92%;
                    &:placeholder-shown {
                        font-style: italic;
                    }
                }
                span.glyphicon-remove {
                    margin-left: 39px;
                }
            }
            .payment-receiver-search {
                width: 445px;
            }
        }
        .modal-footer {
            .btn {
                width: 100%;
            }
        }
    }
    .search-bar {
        border: 1px solid $gray;
        border-radius: 0.5em;
        height: 2em;
        line-height: 2em;
        overflow: hidden;
        padding: 0 0.4em;
        width: 35%;
        input {
            border: 0;
            height: 2em;
            outline: 0;
            padding-left: 0.2em;
            width: 88%;
        }
        span.glyphicon-remove {
            margin-left: 39px;
            margin-top: 5px;
        }
        span.glyphicon-search {
            margin-top: 5px;
            padding-right: 3px;
        }
    }
    .margin-bottom-15 {
        margin-bottom: 15px;
    }
}