﻿.select-date-modal {
    .modal-dialog {
        top: 20%;
        width: 25%;
    }

    .no-padding {
        padding: 0px;
    }
}
