@import '../sass/variables';
@import '../sass/sp-panel.scss';

.list-view-settings-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;

    .expand-table-button {
        &:hover span {
            color: $trimble_tf_product_green;
        }

        span {
            color: $darkgray;
            font-size: 17px;
            vertical-align: middle;
        }

        .btn {
            width: 34px;
            padding: 6px;
        }
    }

    .silva-search.settings-item {
        line-height: inherit;
        display: block;
    }

    .left-settings {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 50%;

        .view-option-select {
            height: 34px;
            width: 190px;
            text-align: start;
            text-transform: uppercase;
            margin-right: 7px;
            border: 1px solid #d1d1d1;
            border-radius: 5px;
        }

        .view-option-btn {

            .btn {
                height: 34px;
                width: 190px;
                text-align: start;
                text-transform: uppercase;
                margin-right: 7px;
                border: 1px solid #d1d1d1;
                border-radius: 5px;
            }

        }

        .dropdown-menu-right {
            left: 0;
        }

        .search-setting {
            padding: 5px;
            width: 190px;
            font-size: 14px;
            height: 34px;
            border: 1px solid $gray;
            border-radius: $sp-panel-border-radius;
            background-color: $trimble_white;
            display: flex;
            align-items: center;
            margin-right: 7px;

            input:focus {
                outline: none;
            }

            input,
            .searchInput {
                height: 25px;
                width: 140px;
                border: none;
                margin: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:placeholder-shown {
                    font-style: italic;
                }
            }
        }

        .silva-search.silva-search-common {
            width: 190px;
        }

        .checkbox-setting {
            margin-right: 7px;

            [type="checkbox"]:not(:checked)+label:before,
            [type="checkbox"]:checked+label:before,
            [type="checkbox"]:not(:checked)+label:after,
            [type="checkbox"]:checked+label:after {
                top: 0;
            }

            label {
                margin-bottom: 0;
            }
        }

        .advanced-filtering-dropdown {
            .dropdown-content {
                .dropdown-table {

                    .team-filter,
                    .purchasearea-filter,
                    .area-filter {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .dropdown {
                            width: 93%;

                            .multiselection-drop-down {
                                width: 100%;

                                .multiselectionSearchInput {
                                    width: 80%;
                                }

                                .titleBox {
                                    width: 100%;
                                }
                            }

                        }
                    }

                    .area-filter {
                        .dropdown {
                            width: 100%;
                        }

                        .multiselection-drop-down .checkboxes {
                            width: 332px !important;

                        }
                    }

                    .team-filter {
                        .multiselection-drop-down .checkboxes {
                            width: 304px !important;
                            ;
                        }
                    }

                    .purchasearea-filter {
                        margin-left: 35px;

                        .multiselection-drop-down .checkboxes {
                            width: 272px !important;

                        }
                    }
                }
            }
        }

        .silva-search-common {
            padding: 5px;
            width: 160px;
            font-size: 14px;
            height: 34px;
            border: 1px solid $gray;
            border-radius: $sp-panel-border-radius;
            background-color: $trimble_white;
            display: flex;
            align-items: center;
            margin-right: 7px;

            input:focus {
                outline: none;
            }
        }

        .investment-filter-component,
        .measurement-certificate-filter {
            .advanced-filtering-dropdown {
                .dropdown-content {
                    .dropdown-table {
                        .table-cell {
                            .dropdown-date-input {
                                width: 175px;
                            }
                        }
                    }
                }
            }
        }

    }

    .right-settings {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 50%;

        .icon-button {

            //Override bootstrap's styles on icon-buttons
            >.btn {
                border-color: #ccc;

                &:hover,
                &:focus {
                    background-color: $trimble_white;

                    .icon-settings {
                        background-image: url(../../content/images/svg/settings-cog-green.svg);
                    }

                    .icon-favorite {
                        background-image: url(../../content/images/svg/favorite-green.svg);
                    }
                }

                background-color: $trimble_white;
            }

            &.open {
                .icon-printer {
                    background-image: url(../../content/images/svg/print-green.svg);
                }
            }
        }

        .icon {
            height: 17px;
            width: 18px;
            line-height: 30px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            vertical-align: middle;

            &.icon-printer {
                background-image: url(../../content/images/svg/print.svg);

                &:hover,
                &:focus {
                    background-image: url(../../content/images/svg/print-green.svg);
                }
            }

            &.icon-settings {
                width: 25px;
                background-image: url(../../content/images/svg/settings-cog.svg);

                &:hover,
                &:focus {
                    background-image: url(../../content/images/svg/settings-cog-green.svg);
                }
            }
        }
    }

    .dropdown-toggle {
        margin-right: 7px;
    }
}