﻿.measurement-document-selection-modal {
    .spinner-container {
        top: 50%;
        left: 50%;

        .spinner-background {
            background-color: gray;
            opacity: 0.1;
            width: 100%;
            height: inherit;
        }
    }

    .additional-information {
        background-color: $gray;
        font-style: italic;
        padding: 1em;
    }

    .font-bold {
        font-weight: bold;
    }

    .p-15 {
        padding: 15px;
    }


    .p-0 {
        padding: 0px;
    }

    .pt-15 {
        padding-top: 15px;
    }

    .mt-5 {
        margin-top: 5px;
    }

    .pl-0 {
        padding-left: 0px;
    }

    .pr-0 {
        padding-right: 0px;
    }

    .border-left {
        border-left: 0.5px solid $gray;
    }

    .border-bottom {
        border-bottom: 0.5px solid $gray;
    }

    .grid-container {
        margin-bottom: 15px;
        border: 1px solid $sp-panel-border-color;
        border-radius: 0.5em;

        .sp-grid {

            .grid-check-box {
                top: -8px;
            }

            .ag-root.ag-root {
                border-radius: 0.5em;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    top: -8px;
                }
            }

            .grid-header-checkbox-label {
                margin-bottom: 12px;
            }

            .ag-selection-checkbox {
                display: none;
            }

            .ag-body-viewport {
                overflow-x: hidden !important;
            }

            .cell-header-right {
                .ag-header-cell-text {
                    float: right !important;
                }
            }
        }

        .select-all-wrapper {
            left: 3px;
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }

        .selection-change {
            background-color: red;
            color: white;
        }
    }

    .radio-text {
        bottom: 2px;
        position: relative;
        padding-left: 2px;
    }

    .pb-5 {
        padding-bottom: 5px;
    }
}

.float-right {
    float: right !important;
}

.float-left {
    float: left !important;
}

.confirm-text-transform-none {
    text-transform: none !important;
}
