@import '../../../shared/sass/_variables.scss';

.material-switch-container {
    .material-switch-content-content > input[type="checkbox"] {
        display: none;
    }

    .material-switch-content > label {
        cursor: pointer;
        height: 0px;
        position: relative;
        width: 40px;
    }

    .material-switch-content > label::before {
        background: $gray;
        border-radius: 8px;
        content: '';
        height: 16px;
        margin-top: -8px;
        position: absolute;
        opacity: 0.6;
        transition: all 0.4s ease-in-out;
        width: 40px;
    }

    .material-switch-content > label::after {
        background: rgb(255, 255, 255);
        border-radius: 16px;
        content: '';
        height: 24px;
        left: -4px;
        margin-top: -8px;
        position: absolute;
        top: -4px;
        transition: all 0.3s ease-in-out;
        width: 24px;
    }

    .material-switch-content > input[type="checkbox"]:checked + label::before {
        background: $trimble_tf_product_green;
        opacity: 0.5;
    }

    .material-switch-content > input[type="checkbox"]:checked + label::after {
        background: $trimble_tf_product_green;
        left: 20px;
        top: -4px;
    }

    .material-switch-content > input[type="checkbox"]:not(:checked) + label::after {
        background: $gray;
        height: 24px;
        width: 24px;
        transform: scale(1);
        opacity: 1;
        top: -4px;
    }
}
