﻿@import '../../shared/sass/_variables.scss';
@import 'forestPropertyList/forestPropertyList.scss';
@import '../../components/customers/forestCommon/forestBase.scss';

.forestProperty {
    $font-family: "Myriad Pro", Verdana;
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: $font-family;

    .grid-header-styling {
        font-weight: bold;
        font-size: 12px !important;
    }

    .customer-bottom-buttons {
        margin: 1em 0.2em 1em 0.2em;
    }

    .row { //override Bootstrap's row default settings for maring
        margin: 0px;
    }

    .settings-item {
        vertical-align: middle;
        display: inline-block;
        line-height: 34px;
        height: 34px;
    }

    .view-option-select {
        cursor: pointer;

        button {
            line-height: 20px;
            min-width: 217px;
            text-align: left;
            padding-left: 10px;
        }
    }

    .drop-down-with-checkboxes {
        .btn {
            background-color: white !important;
            border: 1px solid #d1d1d1 !important;
        }

        .checkbox-wrapper * {
            z-index: 100;
        }

        .dropdown-toggle {
            width: 24px;
        }

        .dropdown-menu {
            font-family: "Myriad Pro", Verdana;
            font-size: 12px;
            color: black;

            > div {
                overflow-y: hidden;
                height: auto;
            }
        }

        tr {
            height: 40px;
        }

        .replacing-checkbox {
            top: -5px;
        }

        td:first-child {
            width: 17%;
        }

        td:last-child {
            width: 83%;
        }
    }

    .custom-drop-down {

        .btn {
            padding-top: 2px;
            padding-bottom: 2px;
            background-color: white;
        }

        button:disabled {
            border: 1px solid darkgray;
            background-color: #D1D1D1;
            cursor: not-allowed;
        }

        .dropdown-menu {
            color: black;
        }
    }

    .dropDownMinWidth250px {
        .dropdown-menu {
            min-width: 250px;
        }
    }

    .right-align {
        text-align: right;
        font-size: 12px;
        background-color: white;
    }

    .left-align {
        input {
            text-align: left !important;
        }
    }

    .editable-text { //moved one level up, to be applied to all myh grids
        textarea:focus, input:focus {
            color: black;
            text-align: right;
        }

        input, select, textarea {
            color: black;
            text-align: right;
        }

        input {
            border-style: none;
            width: 100%;
            height: 19px !important;
        }
    }

    .grid-input {
        text-align: left;
    }

    .ag-theme-fresh {
        .ag-root {
            font-family: $font-family;
        }
    }

    .left-text {
        width: calc(100% - 85px);
    }

    .header-padding {
        padding-left: 5px;
        padding-top: 3px;
    }

    .height-fixed {
        height: 22px !important;
    }

    .menu-position {
        padding-left: 0px;
    }

    .width-fixed {
        width: 83px;
    }


    .white-on-black-header {
        background-color: black;
        color: white;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        padding: 0.2em 0.5em 0.1em 0.5em;
        width: 100%;
        margin-bottom: 0px;

        .ag-header-group-cell-label {
            text-align: left;
        }
    }

    .content {
        [class^="col"] {
            padding-left: 5px;
            padding-right: 5px;
        }

        .same-row {
            padding-left: 10px;
            padding-right: 10px;
        }

        input[type=radio] {
            color: red !important;
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    .panel-header {
        background-color: #d1d1d1;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        border-radius: 0.5em 0.5em 0 0;
    }

    .border {
        border: 1px solid rgb(97,97,97);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .border-right {
        border-right: 1px solid rgb(97,97,97);
    }

    .border-left {
        border-left: 1px solid rgb(97,97,97);
    }

    .border-top {
        border-top: 1px solid rgb(97,97,97);
    }

    .border-bottom {
        border-bottom: 1px solid rgb(97,97,97);
    }

    .no-border-radius {
        border-radius: 0 !important;
    }

    .bottom-bottons {
        margin-top: 10px;

        .btn-silva {
            padding: 10px;
            min-width: 150px;
        }
    }

    input[type='text'] {
        padding: 0.2em;
        height: 27px;
    }

    .partial {
        padding: 0;
        border-radius: 0.5em;
        display: flex;


        > div {
            width: 100%;
        }


        :first-child {
            margin-left: 0;
        }

        :last-child {
            margin-right: 0;
        }
        /* partial background and text color combinations */
        .blue {
            background-color: $blue;
            color: $white;
        }

        .lime {
            background-color: $trimble_tf_product_green;
            color: $white;
        }
        /* link block */
        .link-block {
            font-family: $font-primary;
            font-size: 12px;

            .inner {
                box-shadow: 0 0 3px $half_trimble_brand_grey, 0 0 3px $half_trimble_brand_grey;
                height: 250px;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                background-color: $trimble_white;
            }

            .large-nav-button {
                cursor: pointer;
                background-color: $trimble_white;
                padding-right: 0px !important;
                padding-left: 0px !important;


                &:hover {
                    background-color: $trimble_white;
                    color: $trimble_black;

                    .topic {
                        color: $trimble_tf_product_green;
                    }

                    .icon {
                        border-color: $trimble_grey_light;

                        &-forestPlans {
                            background-image: url("/content/images/svg/FORESTPROPERTYButtons_1_ForestPlanList_MouseOver.svg");
                        }

                        &-statusRating {
                            background-image: url("/content/images/svg/FORESTPROPERTYButtons_2_ValuationOfForestProperty_MouseOver.svg");
                        }

                        &-forestResources {
                            background-image: url("/content/images/svg/FORESTPROPERTYButtons_3_ForestAssetsList_MouseOver.svg");
                        }
                    }
                }

                a:hover, a:active {
                    text-decoration: none;
                }

                .icon {
                    text-align: center;
                    border-bottom: 01px solid $trimble_grey_light;

                    div {
                        height: 50px;
                        margin: 0.5em 0;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    &-forestPlans {
                        background-image: url("/content/images/svg/FORESTPROPERTYButtons_1_ForestPlanList.svg");
                    }

                    &-statusRating {
                        background-image: url("/content/images/svg/FORESTPROPERTYButtons_2_ValuationOfForestProperty.svg");
                    }

                    &-forestResources {
                        background-image: url("/content/images/svg/FORESTPROPERTYButtons_3_ForestAssetsList.svg");
                    }
                }

                .topic {
                    color: $trimble_black;
                    margin: 0;
                    margin-bottom: -0.5em;
                    margin-top: 2em;
                    padding: 0;
                    font-family: $font-semibold;
                    text-transform: uppercase;
                    font-size: 12px;
                }

                .last-topic {
                    margin-bottom: 0.5em;
                    margin-top: 0em;
                }

                p {
                    text-align: center;
                    padding: 0.5em;
                    padding-left: 2em;
                    padding-right: 2em;
                }
            }
        }
    }

    .vertical-align-item {
        vertical-align: middle;
    }

    .view-option-select button {
        min-width: 217px;
    }
}
