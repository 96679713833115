﻿.timber-sale-contract {
	padding-left: 0px;
	width: 95%;
	padding-right: 15px;
	padding-left: 15px;

	.timber-sale-contract-container {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0px;
		padding-left: 0px;
	}

	.timber-sale-contract-button-group {
        button {
            width: 170px;
			height: 40px;
            padding: 10px;
            margin: 20px 0px 0px 0px;
        }
    }

	.timber-sale-contract-panel {
        border: 1px solid #616161;
        border-radius: 0.5em;
        overflow: hidden;
		height: 730px;
		.panel-header {
			background-color: #d1d1d1;
			text-transform: uppercase;
			font-family: "Myriad Pro Semibold";
			border-radius: 0.5em 0.5em 0 0;
		}
	}

	label{
		font-weight: 400;
	}

	input, select {
		padding-top: 5px;
		padding-bottom: 3px;
		padding-left: 3px;
		padding-right:3px;
	}

	.no-padding {
		padding-right: 0px;
		padding-left: 0px;
	}
	.pull-bottom {
		top: 5px;
	}
	.pull-top {
		bottom: 2px;
	}

	.padding-bottom-s {
		padding-bottom: 7px;
	}
	.padding-top-s {
		padding-top: 7px !important;
	}

	.margin-top-fix { 
		margin-top: -5px;
	}
	.margin-s {
		margin-left: 15px;
	}
	.margin-top-m{
		margin-top: 10px;
	}
	.margin-top-s{
		margin-top: 5px;
	}
	.sakuu-left-margin {
		margin-left: 27px;
	}

	.authorizedToSendKaato-checkbox{
		line-height: 35px;
	}

	//bottom section
	.radio-group {
		padding-left: 30px !important;

		label {
			font-weight: 400;
			padding: 0 0.25em;
			vertical-align: middle;
		}
	}

	.partial-header {
		text-transform: uppercase;
		padding: 0.5em;
		margin-bottom: 0px;
	}

	.second-header {
		background-color: #fff;
		padding-bottom: 10px;
	}

	.border-right {
		border-right: 1px solid #616161;
	}

	.second-partial-header {
		padding: 0.5em;
		margin-bottom: 1px;
	}

	.white-on-black-header {
		background-color: black;
		color: white;
		text-transform: uppercase;
		font-family: "Myriad Pro Semibold";
		width: 100%;
		height: 27px;
		padding: 0em;
		margin-bottom: 0px;
	}
	.black-on-grey-header {
		background-color: #d1d1d1;
		color: black;
		text-transform: uppercase;
		font-family: "Myriad Pro Semibold";
		width: 100%;
		height: 27px;
		padding: 0em;
		margin-bottom: 0px;
	}

	.pull-right-xs{
		right: 1px;
	}

	.height-fixed {
		height: 27px !important;
	}

	.fine-border {
		border: 1px solid #d1d1d1;
	}

	.no-border {
		border: 0px;
		margin-top: 4px;
	}

	.text-align-right {
		text-align: right;
	}

	.form-group {
		margin-top: 7px;
	}

	input[type="radio"] {
		box-shadow: none;
	}

	input[type=radio] {
		margin-top: 0px;
		margin-bottom: 0px;
		height: initial;
		box-shadow: none;
	}

	.float-left {
		text-align: left;
	}

	.right-detail-panel {
		.content {
			padding: 6px 2px;

			input {
				display: inline-block;
				width: initial;
				padding-right: 5px;
				vertical-align: middle;
			}
		}
	}

	.timbers-sale-contract-right-panel {
		height: 670px; //Need to set the tab's height manually, otherwise the border's height is not touching the upper and lower parts
	}

	.timbers-sale-contract-right-panel {
		.timbers-sale-contract-right-upper-part > div {
			margin-left: 0px;
		}

		.subtitle {
			margin-top: 10px;
		}

		.form-group {
			padding: 10px 0px 10px 0px;
		}

		label[for='ennakko']:before {
			top: 0px;
		}

		label[for='ennakko']:after {
			top: 0px;
		}

		.cb-section {
			.adjust-position {
				position: relative;
				top: 8px;
			}
		}

		.adjust-top-margin {
			margin-top: 10px;
		}

		//error messages
		.pmVrk {
			.field-validation-tooltip {
				top: 24px !important;
				left: 13px !important;
			}
		}
		.pmTili {
			.field-validation-tooltip {
				top: 24px !important;
				left: 50px !important;
			}
		}
		.muu {
			.field-validation-tooltip {
				top: 315px !important;
			}
		}
		.varTapaKu1{
			.field-validation-tooltip{
				 top: 390px !important;
				 left: 380px !important;
			}
		}
		.varTapaKu2{
			.field-validation-tooltip{
				 top: 415px !important;
				 left: 380px !important;
			}
		}
		.muu2 {
			.field-validation-tooltip {
				top: 410px !important;
			}
		}
	}
}
