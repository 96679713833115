@import './manangingHaravesting.scss';

.transport-receipts {
    margin-top: 0.5rem;

    .list-view-settings-row {
        .advanced-filtering-dropdown {
            .dropdown-content {
                height: 395px;
            }
            .has-extra-row {
                height: 386px;
            }
        }
    }
  
    .icon-expand-button {
        width: 34px;
        height: 34px;
        &:hover,
        &:focus {
            background-color: initial;
            color: $trimble_tf_product_green;
        }
        .icon-expand {
            display: flex;
            align-content: center;
            justify-content: center;
        }
    }
    .glyphicon {
        margin-left: -2px;
        margin-top: 2px;
    }

    &.container {
        .saved-lists-dropdown {
            position: absolute;
            top: 100%;
            z-index: 1000;
            padding: 5px 0;
            margin: 2px 0 0;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, .15);
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            left: -126px;
        }
    }
    .transport-receipts-grid {
        .custom-filter {
            input {
                border-radius: 3px;
                border: 1px #616161 solid !important;
                margin-top: 3px;
                padding: 1px 5px;
            }
        }
        .hide-cell {
            background-color: #e8e8e8;
            input[type] {
                z-index: -1;
            }
        }
        .panel-sp-grid {
            .panel-heading {
                text-transform: uppercase; // TODO: move to all grids
            }
            .disabled-pen-icon:hover:not(.black) {
                background-color: #ddd;
            }
            .three-char-window .flex-container {
                width: calc(3ch + 2px);
            }
            .four-char-window .flex-container {
                width: calc(4ch + 2px);
            }
        }
        .panel-footer {
            button:first-child {
                margin-right: 0.5em;
            }
        }
        // filtering styles

    }
    &.view-expanded {
        width: 100%;
    }
    .saved-lists-dropdown-storage .favorite-radio-inline input {
        margin: 0;
    }
}


/**
 *   pop-up modal (is rendered at top level)
 */

.bundle-modal-window .modal-dialog {
    width: 600px;
    .basic-modal-window {
        height: 230px;
        .main-content {
            .amounts-container,
            .bottom-container {
                height: 20px;
                margin: 10px 0;
                position: relative;
                .arrow-container {
                    position: absolute;
                    height: 20px;
                    .first-arrow {
                        position: absolute;
                        width: 143px;
                        left: 85px;
                    }
                    .second-arrow {
                        position: absolute;
                        width: 286px;
                        left: 261px;
                    }
                    /*.third-arrow {
            position: absolute;
            width: 55px;
            left: 240px;
          }*/
                    .first-grey-arrow {
                        position: absolute;
                        width: 142px;
                        left: 85px;
                    }
                    .second-grey-arrow {
                        position: absolute;
                        width: 288px;
                        left: 262px;
                    }
                    .second-grey-arrow-c1 {
                        position: absolute;
                        width: 72px;
                        left: 262px;
                    }
                    .second-grey-arrow-c2 {
                        position: absolute;
                        width: 72px;
                        left: 334px;
                    }
                    .second-grey-arrow-c3 {
                        position: absolute;
                        width: 72px;
                        left: 406px;
                    }
                    .second-grey-arrow-c4 {
                        position: absolute;
                        width: 72px;
                        left: 478px;
                    }
                    .first-compartment-grey-arrow-1 {
                        position: absolute;
                        width: 71px;
                        left: 85px;
                    }
                    .first-compartment-grey-arrow-2 {
                        position: absolute;
                        width: 71px;
                        left: 156px;
                    }
                    .arrow-text-wrapper {
                        text-align: center;
                        position: absolute;
                        width: 100%;
                        color: $black;
                        &.gray {
                            color: $lightgray;
                        }
                        .arrow-text {
                            background: $white;
                            padding: 0 4px;
                            display: inline-block;
                        }
                    }
                    .arrows {
                        margin-top: 9px;
                        border-top: 1px solid $black;
                        height: 100%;
                        &.gray {
                            border-top: 1px solid $lightgray;
                        }
                        .arrow-box-left {
                            position: absolute;
                            display: inline-block;
                            height: inherit;
                            border-left: 1px solid $black;
                            &.gray {
                                top: -1px;
                                border-left: 1px solid $lightgray;
                                &:after {
                                    top: -10px;
                                    border-bottom-color: $lightgray;
                                    border-top-color: transparent;
                                }
                            }
                            &:after {
                                top: 100%;
                                border: solid transparent;
                                content: "";
                                position: absolute;
                                border-top-color: $black;
                                border-width: 5px;
                                left: -5px;
                            }
                        }
                        .arrow-box-right {
                            position: absolute;
                            display: inline-block;
                            height: inherit;
                            right: 0;
                            border-right: 1px solid $black;
                            &.gray {
                                top: -1px;
                                border-right: 1px solid $lightgray;
                                &:after {
                                    top: -10px;
                                    border-bottom-color: $lightgray;
                                    border-top-color: transparent;
                                }
                            }
                            &:after {
                                top: 100%;
                                border: solid transparent;
                                content: "";
                                position: absolute;
                                border-top-color: $black;
                                border-width: 5px;
                                left: calc(100% - 5px);
                            }
                        }
                    }
                }
                .unit {
                    text-align: right;
                    margin-right: 1em;
                }
            }
            .truck-container {
                margin: 0 31px 0 22px;
                .truck {
                    background-repeat: no-repeat;
                    display: inline-block;
                    height: 75px;
                    /*&.one {
            width: 129px;
            &.off {
              background-image: url(../../content/images/svg/Truck_LoadCompartment_1_OFF.svg);
            }
            &.on {
              background-image: url(../../content/images/svg/Truck_LoadCompartment_1_ON.svg);
            }
          }
          
          &.two {
            width: 75px;
            &.off {
              background-image: url(../../content/images/svg/Truck_LoadCompartment_2_OFF.svg);
            }
            &.on {
              background-image: url(../../content/images/svg/Truck_LoadCompartment_2_ON.svg);
            }
          }
          
          &.three {
            width: 72px;
            &.off {
              background-image: url(../../content/images/svg/Truck_LoadCompartment_3_OFF.svg);
            }
            &.on {
              background-image: url(../../content/images/svg/Truck_LoadCompartment_3_ON.svg);
            }
          }*/
                    &.large {
                        width: 530px;
                        background-image: url(../../content/images/svg/Truck_Large.svg);
                    }
                }
                .b11 {
                    position: absolute;
                    left: 85px;
                    top: 90px;
                    width: 72px;
                    text-align: center;
                }
                .b12 {
                    position: absolute;
                    left: 157px;
                    top: 90px;
                    width: 72px;
                    text-align: center;
                }
                .b21 {
                    position: absolute;
                    left: 261px;
                    top: 90px;
                    width: 72px;
                    text-align: center;
                }
                .b22 {
                    position: absolute;
                    left: 333px;
                    top: 90px;
                    width: 72px;
                    text-align: center;
                }
                .b23 {
                    position: absolute;
                    left: 405px;
                    top: 90px;
                    width: 72px;
                    text-align: center;
                }
                .b24 {
                    position: absolute;
                    left: 477px;
                    top: 90px;
                    width: 72px;
                    text-align: center;
                }
            }
            .bottom-container {
                height: 20px;
                margin: 10px 0;
                position: relative;
            }
        }
    }
}