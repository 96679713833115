﻿.generic-information-tool {
    max-width: 600px;

    .vertically-aligned {
        line-height: 21px;
    }

    .header-symbol {
        margin-right: 7px;
        margin-top: -2px;
        font-weight: bold;
    }

    .header-icon-container {
        height: 1.5em;
        width: 1.5em;
        text-align: center;
        margin-right: 0.5em;
        display: inline-block;

        img {
            max-height: 1.5em;
            max-width: 1.5em;
            margin-right: 0;
            display: inline;
        }

        .img-placeholder {
            height: 1.5em;
            width: 1.5em;
            margin-right: 0;
            display: inline;
        }
    }

    .header-icon {
        max-height: 1.5em;
        width: 1.5em;
        margin-right: 0.5em;
    }

    &.generic-information-container {
        position: absolute;
        background-color: rgba(0,0,0,0.75);
        z-index: 799;
        padding: 0;
        border-radius: 0.5em;
        border: 0;
    }

    &.generic-information-container:after {
        /* add a triangle to the bottom of it */
        border-left: 5px dashed transparent;
        border-right: 5px dashed transparent;
        margin-left: -6px;
        width: 0;
        height: 0;
        position: absolute;
        content: '';
    }

    &.generic-information-container.arrow-bottom:after {
        border-top: 20px solid rgba(0,0,0,0.75);
        border-bottom: 0;
        bottom: -20px;
    }

    &.generic-information-container.arrow-top:after {
        /* add a triangle to the bottom of it */
        border-bottom: 20px solid rgba(0,0,0,0.75);
        border-top: 0;
        top: -20px;
    }

    &.generic-information-container.arrow-right:after {
        right: 20px;
    }

    &.generic-information-container.arrow-left:after {
        left: 20px;
    }

    .panel-header {
        background: transparent !important;
        padding: 0.25em 0.5em;
        color: $trimble_tf_product_green;
        text-transform: uppercase;
        line-height: 1.5em;

        &:last-child {
            border-bottom: 0;
        }

        &.no-targets-found {
            border-bottom: 0;
        }
    }


    .close-button {
        color: $white;
        margin-left: 0.5em;
        top: 3px;
        cursor: pointer;
    }

    .panel-options {
        .panel-option {
            padding: 0.25em 0.5em;
            border-top: 1px solid $white;
            text-transform: uppercase;
            cursor: pointer;

            &:hover {
                background-color: $trimble_tf_product_green;
            }
        }
    }

    #endangered-species-panel {
        width: 500px;
    }

    .sub-header {
        padding-top: 0.5em;
        padding-bottom: 0.3em;
        padding-left: 0.5em;
        border-top: 1px solid $white;
        text-transform: uppercase;
    }

    .sub-plain-header {
        padding-top: 0.5em;
        padding-bottom: 0.3em;
        padding-left: 0.5em !important;
        text-transform: uppercase;
    }

    .selected-target-content {
        .content-header {
            padding: 0.5em;
            border-bottom: 1px solid $white;
            margin-bottom: 0.5em;
        }

        .content-body {
            table {
                width: 100%;

                thead {
                    border-bottom: 1px solid $white;

                    .source-text {
                        color: $trimble_tf_product_green;
                        white-space: nowrap;
                    }

                    th {
                        padding-top: 0.5em;
                        vertical-align: top;
                        font-weight: normal;

                        div {
                            overflow-wrap: break-word;
                            word-wrap: break-word;
                        }

                        .header-container {
                            display: flex;

                            .header-column-left {
                                margin-right: .5em;
                            }

                            .header-twin-row {
                                display: flex;
                                flex-direction: column;
                                flex-grow: 1;
                            }
                        }

                        &:first-child {
                            padding-left: 0.5em;
                        }

                        &:last-child {
                            padding-right: 0.5em;
                        }

                        .copy-to-clipboard-icon {
                            height: 1em;
                            margin: 0 !important;
                            position: relative;
                            top: 2px;
                            width: 1em;
                            cursor: pointer;
                        }

                        .header-icon {
                            float: left;
                            margin-right: 1em;
                        }

                        .header-upper-row {
                            .content-type {
                                text-transform: uppercase;
                                font-weight: bold;
                                color: $white;
                                margin-right: 0.5em;
                                line-height: 22px;
                            }

                            .area-topic {
                                text-transform: none !important;
                            }
                        }

                        .white-anchor {
                            color: $white;
                            text-decoration: underline;
                        }
                    }

                    tr:last-child {
                        th {
                            padding-bottom: 0.5em;
                        }
                    }
                }

                tr {
                    td {
                        padding-right: 0.5em;
                        overflow-wrap: break-word;
                        word-wrap: break-word;

                        &:first-child {
                            padding-left: 0.5em;
                        }

                        &:last-child {
                            padding-right: 0.5em;
                        }

                        .footer-flex-container {
                            display: inline-flex;
                            flex-direction: column;
                            height: 100%;
                            width: 100%;
                        }

                        .additional-info-box {
                            max-height: 7.5em;
                            min-width: 100px;
                            max-width: 300px;
                            overflow-y: auto;
                            border: 1px solid $white;
                            border-radius: 0.5em;
                            padding: 0.5em;
                            width: 100%;
                            color: $white;
                            flex-grow: 1;
                        }

                        .white-anchor {
                            color: $white;
                            text-decoration: underline;
                        }
                    }

                    &:first-child {
                        td {
                            padding-top: 0.5em;
                        }
                    }

                    &:last-child {
                        td {
                            padding-bottom: 0.5em;
                        }
                    }
                }

                tfoot {

                    tr {
                        &:first-child {
                            td {
                                vertical-align: top;
                                padding: 0.5em;
                                padding-bottom: 0em;
                                border-top: 1px solid $white;
                            }
                        }

                        &:last-child {
                            td {
                                padding-bottom: 0.5em;
                            }
                        }

                        td {
                            p {
                                margin-bottom: 0.5em;
                            }

                            &:not(:first-child) {
                                padding-left: 0;
                            }
                        }
                    }

                    a {
                        color: $trimble_tf_product_green;
                        text-decoration: underline;
                    }
                }
            }

            .content-topic {
                text-transform: uppercase;
                color: $trimble_tf_product_green;
                vertical-align: top;
            }

            .additional-information-text {
                white-space: pre;
            }
        }

        .content-footer {
            margin-top: 0.5em;
            border-top: 1px solid $white;
            padding: 0.5em;

            p {
                color: $trimble_tf_product_green;
                padding: 0;
                margin: 0;
            }
        }
    }
    /*
	.generic-information-table {
		table-layout: fixed;

		thead {
			th {
				padding: 0.5em 0;

				&:first-child {
					padding-left: 0.5em;
				}

				&:last-child {
					padding-right: 0.5em;
				}
			}

			tr {
				border-bottom: 1px solid $white;
			}
		}

		tbody {
			border-bottom: 1px solid $gray;
			tr:first-child {
				td {
					padding-top: 0.5em;
				}
			}

			tr:last-child {
				td {
					padding-bottom: 0.5em;
				}
			}
			tr {
				td:first-child {
					padding-right: 0.25em;
				}
				td:last-child {
					padding-left: 0.25em;
				}
			}
			td {
				&.table-header {
					color: $trimble_tf_product_green;
					text-transform: uppercase;
				}
			}

			td:first-child {
				padding-left: 0.5em;
			}

			td:last-child {
				padding-right: 0.5em;
			}
		}

		tfoot {
			div {
				padding: 0 0.5em;

				&:first-child {
					padding-top: 0.5em;
				}

				&:last-child {
					padding-bottom: 0.5em;
				}

				&.footer-header {
					color: $trimble_tf_product_green;
					text-transform: uppercase;
				}
			}
		}

	}
	*/
    color: $white;
}
