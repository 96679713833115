.default-assortment-groups {
    $header-color: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;
    padding-left: 0px;
    font-size: 14px;

    .default-assortment-groups-panels {
        border-radius: 0.5em 0.5em 0.5em 0.5em;
        border: 1px solid $darkgray;
        overflow: hidden;
    }

    .default-assortment-groups-right-panel {
        margin-left: 15px;
    }

    .buttons-group {
        background-color: $lightgray;
        padding: 10px;
        border-top: 1px solid $darkgray;
        border-radius: 0 0 0.5em 0.5em;
    }

    .dropdown-toggle.selected-definition {
        padding-left: 5px;
        padding-right: 8px;
    }

    #navigationAssortmentGroupsGrid {
        height: 619px;

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 0;
        }

        .ag-floating-bottom-container .ag-row .ag-cell {
            background-color: #d1d1d1;
        }

        .ag-floating-bottom {
            border-top: 1px solid $darkgray;
        }
    }

    #navigationAssortmentGrid {
        height: 412px;

        @media screen and (max-width: 1800px) {
            height: 397px;
        }

        @media screen and (max-width: 1600px) {
            height: 395px;
        }

        @media screen and (max-width: 1300px) {
            height: 378px;
        }
    }

    .selected-definition, .view-option-select {
        width: 100%;
    }

    .general-information-label {
        padding-top: 5px;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .content {
        margin-top: 28px;
        margin-bottom: 13px;
        margin-left: 16px;
        margin-right: 16px;
    }

    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        top: 0;
    }

    [type="checkbox"]:checked + label:after {
        top: 0px;
    }

    .btn.btn-default.dropdown-toggle {
        width: 100%;
        height: 30px;
        padding: 3px 6px;
        border: 1px solid #B0AFB0;

        @media screen and (max-width: 1300px) {
            border: 1px solid #B0AFB0;
        }
    }

    .dropdown-menu {
        width: 100%;
    }

    .top-panel {
        border-bottom: 1px solid $darkgray;
        background-color: #fff;

        input[type='text'] {
            height: 30px;
            border: 1px solid #B0AFB0;
        }

        .switch-label {
            padding-top: 4px;
        }
    }

    .select-all-wrapper {
        top: 3px;
        left: 3px;
    }

    .panel-header.grid-header-styling {
        border-bottom: 1px solid #808080;
        padding: 4px;
        height: 25px;
        font-family: "Myriad Pro", Verdana;
        font-weight: 700;
        color: black;
        background: #e2e2e2;
    }

    .material-switch-container {
        margin-top: 6px;
    }

    .ag-body .pen-icon {
        background-color: #009AD9;
    }

    .full-content {
        width: 100%;
    }

    .select-option-label {
        font-size: 12px;
        font-weight: 600;
        height: 28px;
        padding-top: 6px;
        font-family: "Myriad Pro Semibold";
    }

    @media screen and (max-width: 1800px) {
        .select-option-label {
            padding-top: 0;
            width: 27%;
        }

        .select-option-dropDown {
            width: 72%;
        }
    }


    @media screen and (max-width: 1600px) {
        .select-option-label {
            padding-top: 0;
            width: 28%;
            margin-right: 0px;
        }

        .select-option-dropDown {
            width: 72%;
        }
    }

    @media screen and (max-width: 1300px) {
        .select-option-label {
            padding-top: 0;
            width: 28%;
            margin-top: -2px;
            margin-right: 0px;
        }
    }

    .navigation-section {
        height: 32px;
        margin-bottom: 13px;

        .dropdown-menu {
            margin-top: 0px;
            position: sticky;

            @media screen and (max-width: 1300px) {
                width: 218px;
            }
        }
    }

    .left-side-content {
        width: 79%;
        float: left;

        .left-side-label {
            width: 12%;
            float: left;
        }

        .left-input {
            float: left;

            &.group-name {
                width: 85%;
            }

            &.starting-area {
                width: 35%;
                margin-right: 8.4%;
            }

            &.used-in-label {
                width: 5.2%;
                margin-right: 15px;
            }

            &.used-in {
                width: 35%;
            }
        }

        @media screen and (max-width: 1600px) {
            .left-input {
                float: left;

                &.used-in-label {
                    width: 7%;
                    margin-right: 15px;
                }

                &.used-in {
                    width: 32.8%;
                }
            }
        }

        @media screen and (max-width: 1300px) {
            .left-input {
                float: left;

                &.used-in-label {
                    width: 7%;
                    margin-right: 15px;
                }

                &.used-in {
                    width: 32.3%;
                }
            }
        }
    }

    .right-side-content {
        width: 21%;
        float: left;

        .right-side-label {
            width: 24%;
            float: left;
            margin-right: 15px;
        }

        .right-input {
            float: left;

            &.shortened {
                width: 69%;
            }

            &.control-label {
                width: 17%;
                float: left;
                margin-right: 15px;
                padding-top: 0;
            }
        }

        @media screen and (max-width: 1600px) {
            .right-side-label {
                width: 29%;
                float: left;
                margin-right: 15px;
            }

            .right-input {
                float: left;

                &.shortened {
                    width: 63%;
                }

                &.control-label {
                    width: 17%;
                    float: left;
                    margin-right: 15px;
                    padding-top: 0;
                }
            }
        }

        @media screen and (max-width: 1300px) {
            .right-side-label {
                width: 32%;
                float: left;
                margin-right: 15px;
            }

            .right-input {
                float: left;

                &.shortened {
                    width: 58%;
                }

                &.control-label {
                    width: 17%;
                    float: left;
                    margin-right: 23px;
                    padding-top: 0;
                    margin-left: 1px;
                }
            }
        }
    }

    .select-option-dropDown {
        .active-advanced-filter-modal {
            margin-left: 0px;
        }
    }
}
