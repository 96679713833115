﻿.add-edit-assortment-modal {
    .add-edit-assortment-modal-content {
        height: 321px;

        .assortment-code {
            border-top: solid 0.75px #b0afb0;
            border-bottom: solid 0.75px #b0afb0;
            padding: 20px;
        }

        .pad-5 {
            padding: 5px;
        }

        .assortment-group {
            padding: 20px;

            .assortment-group-element {
                padding-right: 30px
            }
        }

        .content-rows {
            margin-top: 20px;
        }

        .ml--10 {
            margin-left: -10px;
        }

        .button-container {
            padding: 15px;
            padding-right: 5px;
            padding-left: 5px
        }

        .select-option-label.required::after {
            content: "*";
        }
    }
}
