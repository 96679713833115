﻿@import '../../shared/sass/_variables.scss';

.help-menu-directive {
    font-family: $font-primary;
    font-size: 12px;
    color: $trimble_grey;
    text-align: left;
    $text-padding: 19px;

    .icon-help {
        margin-right: 0px;
        background-image: url(../content/images/svg/Help_Icon.svg);
    }

    .icon-help:hover {
        cursor: pointer;
        background-color: $trimble_dk_blue;
    }

    .menu-active {
        background-color: $trimble_dk_blue;
    }

    .menu-normal {
        background-color: none;
    }

    .help-menu {
        position: absolute;
        z-index: 1000;
        top: 38px;
        width: 270px;
        background: $trimble_white 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $trimble_header_shadow_color;
        opacity: 1;
        right: 70px;

        .divider {
            border-top: 1px solid $half_trimble_brand_grey;
            margin-bottom: 1px;
            margin-top: 1px;
        }

        .text-content {
            padding-left: $text-padding;
            padding-right: $text-padding;
            user-select: none;
            cursor: pointer;
            line-height: 35px;
            vertical-align: middle;
            text-transform: uppercase;
        }

        .column-margin {
            margin-left: 1em;
        }

        .bottom-info {
            margin-top: 10px;
            line-height: 20px;
            margin-bottom: 10px;
            cursor: unset;
        }

        .text-button:hover {
            background-color: $trimble_very_light_grey;
        }
    }

    .image-button {
        width: 40px;
        height: 30px;
        cursor: pointer;
    }

    .image-button:hover {
        background-color: $trimble_dk_blue;
    }
}
