﻿@import '../../../../shared/sass/_variables.scss';

.estimate-timber-sale-plan {
    $font-family: "Myriad Pro", Verdana;
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: $font-family;
    font-size: 14px;
    padding-right: 15px;
    margin-top: 5px;

    .container-row {
        display: flex;
        margin-bottom: 1em;
        background-color: white;
    }

    .work-site-buttons {
        clear: both;
        padding: 0px;
        margin: 0px;
        margin-top: -9px;

        button {
            padding: 10px;
            margin: 20px 0px;
        }
    }

    .left-panel {
        width: 35%;
        border-right: none !important;
    }

    .right-panel {
        width: 65%;
        margin-left: 0em;
    }

    .panel-header {
        background: #d3d3d3;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        position: relative;
    }

    .rounded-header {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-left: 0.5em;
    }

    .header-padding {
        margin-bottom: 0px;
        padding: 0.4em;
    }

    .button-padding {
        padding: 0.2em;
    }

    .no-padding {
        padding: 0px !important;
    }

    .no-margin {
        margin: 0px;
    }

    .button-list-margin {
        margin-top: -10px;
    }

    .border {
        border: 1px solid rgb(97,97,97);
    }

    .white-on-black-header {
        background-color: black;
        color: white;
        text-transform: uppercase;
        padding-left: 0.5em;
    }

    .small-padding {
        padding: 0.7em;

        .col-xs-5, .col-xs-2 {
            padding: 0px;
        }
    }

    .align-left {
        text-align: left !important;
        text-transform: none !important;
    }

    .fine-border {
        border: 1px solid #ccc;
    }

    .height-s {
        height: 25px !important;
    }

    .max-width {
        width: 100%;
    }

    .center-align {
        text-align: center;
    }

    .date-format {
        border-radius: 5px !important;
    }

    .center {
        margin-top: 6px;
    }

    .cuttings-table {
        width: 100%;
        height: 105px;

        .grey-table-first-column {
            border-right: 1px solid white;
            border-bottom: 1px solid white;
            padding-left: 10px;
        }

        .table-header {
            border-right: 1px solid white;
            padding-left: 5px;
        }

        .upper-case {
            text-transform: uppercase;
        }

        .table-element {
            padding-left: 5px;
            border-right: 1px solid #D1D1D1;
            border-bottom: 1px solid #D1D1D1;
        }

        .table-bottom-element {
            padding-left: 5px;
            border-bottom: 1px solid #D1D1D1;
        }

        .bottom-row {
            padding-left: 5px;
            border-right: 1px solid #D1D1D1;
        }

        .cutting-input {
            padding-top: 4px;
            border: none;
            width: 100% !important;
        }
    }

    .grey-bg {
        background-color: #D1D1D1;
    }

    .radio-group-row {
        height: 2.4em;
        padding-top: 0.6em;

        .radio-inline {
            margin-top: -3px;
        }

        .label-bottom-margin {
            margin-bottom: 2.5px;
        }
    }

    .left-padding {
        padding-left: 5px;
    }

    .no-left-padding {
        padding-left: 0px;
    }

    .timber-grid {
        border: 1px solid grey;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .assortment-grid {
        height: 416px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .panel-grid-bottom {
        background: #d3d3d3;
    }

    .left-right-border {
        border-left: 1px solid grey;
        border-right: 1px solid grey;
    }

    .align-cb-label {
        padding-top: 10px;
    }

    .checkbox-cell {
        border: none;
        text-align: center;
    }

    .round-bottom-border {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .no-margin {
        margin-bottom: 0px;
    }

    .accordion-tab {
        border: 1px solid black;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .panel-heading {
            background-color: black;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            color: $trimble_white;

            .panel-title {
                font-size: 14px;
                font-weight: 700;
                padding-left: 6px;
            }
        }

        .panel-body {
            background-color: #D1D1D1;
            padding-left: 6px;
            border: 0px;
        }

        .points-list {
            margin-bottom: 0px;
            -webkit-margin-before: 0;
            -webkit-margin-after: 0;
            -webkit-margin-start: 0px;
            -webkit-margin-end: 0px;
            -webkit-padding-start: 6px;
            list-style-type: none;
        }

        .accordion-toggle {
            text-decoration: none;
        }
    }
}
