$form-control-height: 27px;

/**
* Note: this is pretty much the same as .admin .partial (admin-style.scss)
* but renamed here for backwards compatibility
*/
.partial-box {
	@extend %silva-box;
	padding: 0px;
	flex: 1;
}

.partial-box-header {
	@extend %silva-header;

	~.partial-box-header,
	&.no-radius {
		border-radius: initial;
		margin-top: 1em;
	}
	&.no-margin {
    margin: 0 !important;
  }
  &.no-padding {
    padding: 0 !important;
  }
}

.form-group {

	margin: 0;
	padding: 0 0.5em;

  label.control-label {
    //line-height: $form-control-height;
    //margin: 0;
  }

	.control-label {
		padding-right: 0;
	}

	.control-label:first-child {
		padding-left: 0;
	}

	.form-control {
		padding: 0.2em;
		height: $form-control-height;
	}
}

.form-group.border {
	padding-bottom: 0.5em;
	margin-bottom: 0.5em;
	border-bottom: 1px solid $gray;
}

.input-group {
	margin: 0px;

	.form-control {
		width: initial;
	}
	//These are fine with the following fixed values, as the length of the input should always be the same
	.form-control.iban {
		width: 150px;
	}
	.form-control.bic {
		width: 100px;
	}
}

.space-around {
	margin: 1em;
}

input.ng-invalid, select.ng-invalid, 
input.invalid, select.invalid,
.ag-cell.invalid {
	background-color: $lightred !important;
	color: black !important;
}

.no-dropdown-borders {
  background-color: inherit;
  border: none;
  appearance:none;
  -moz-appearance:none;
  -webkit-appearance: none;
}

/* small custom dropdown button */
.tiny-dropdown {
  cursor: pointer;
  width: 20px;
  border-radius: 4px;
  outline: none;
}

.tiny-dropdown-custom {
  cursor: pointer;
  display: inline-block;
  width: 27px;
  height: 27px;
  border: 1px solid $darkgray;
  border-radius: 4px;
  vertical-align: bottom;
  
  .sp-grid & {
    width: 18px;
    height: 18px;
    
    .icon {
      top: 0;
      left: 0;
    }
  }
  
  .icon {
    font-size: 10px;
    transform: rotate(90deg);
    top: 5px;
    left: 8px;
  }
}