﻿@import "app/components/customers/components/gridModalWindow";

.work-force-cost-modal {
	z-index: 99000 !important;

	.modal-dialog {
		width: 850px;
	}

	.title-bar {
		@extend .basic-modal-window, .title-bar;
		border-bottom: solid 1px $gray;
	}

	.search-area {
		@extend .basic-modal-window, .search-area;

		.search-bar {
			.searchWorkForceCostFilter {
				width: 97%;
			}
		}
	}

	.modal-body {
		padding: 0;

		.grid-container {
			@extend .basic-modal-window, .grid-container;

			.modal-grid {
				width: 820px;
			}

			.grid-check-box {
				top: -8px;
			}
		}
	}

	.modal-grid, .ag-theme-fresh, .sp-grid {
		.text-align-right {
			text-align: right;
		}
	}
}
