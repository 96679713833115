$padding-left: 5px;

.filter-default-factory-prices-modal {
    .modal-body {                
        .modal-row {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: baseline;
            gap: 20px;                            

            label {
                flex-basis: 20%;
            }

            .edit-down-options {
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;            
    
                .custom-drop-down {
                    width: 100%;
                    z-index: unset !important;
    
                    .custom-button {
                        display: flex;
                        justify-content: flex-start;
                        padding-left: $padding-left;
                    }
    
                    .dropdown-menu {
                        .custom-table {
                            .table-body {
                                padding-left: $padding-left;
                            }
                        }
                    }                                            
                }
            }
        }        

        .padding-top {
            padding-top:15px;
        }
    }

    .modal-trimble-information-header-title {
        font-size: 14px;
    }
}