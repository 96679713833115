﻿@import '../../../../shared/sass/_variables.scss';

.general-costing {
    .margin-5 {
        margin: 5px -10px;
    }

    .custom-filter {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .selected-definition, .view-option-select, .dropdown-menu {
        width: 100%;
    }

    .select-option-label {
        font-size: 12px;
        font-weight: 600;
        height: 28px;
        padding-top: 6px;
        font-family: "Myriad Pro Semibold";
    }

    .navigation-section {
        height: 32px;

        .dropdown-menu {
            margin-top: 0px;
            position: sticky;

            @media screen and (max-width: 1300px) {
                width: 218px;
            }
        }
    }

    .dropdown-toggle {
        width: 100%;
        height: 30px;
        padding: 3px 6px;
        border: 1px solid #B0AFB0;
    }

    .unit-rate-grid {
        height: 610px;
    }

    .content {
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid grey;
        border-radius: 3px;
        margin-top: 15px;

        .panel-header {
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 0 0 0 0;
            padding: 6px 3px;
            border-bottom: 1px solid #808080;
            height: 30px;
        }

        .text_ellipse {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }


    .customers-tablefoot-actionbar {
        border: 1px solid grey;
        border-top: 0;
        border-radius: 0.5em;
        background-color: $lightgray;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;
        height: 50px;

        button {
            margin-right: 0.5em;

            &.no-margin {
                margin-right: 0;
            }
        }
    }

    .panel-header.grid-header-styling {
        border-bottom: 1px solid #808080;
        padding: 4px;
        height: 25px;
        font-family: "Myriad Pro", Verdana;
        font-weight: 700;
        color: black;
        background: #e2e2e2;

        &.modal-header {
            border-radius: 3px 3px 0px 0px;
            border: 1px solid $half_trimble_brand_grey;
            padding-left: 10px;

            @media screen and (max-width: 1600px) {
                border: 1px solid $half_trimble_brand_grey;
            }
        }
    }

    .search-section {
        margin-bottom: 15px;

        .silva-search {
            height: 30px;
            border: 1px solid $half_trimble_brand_grey;
            border-radius: 4px;

            @media screen and (max-width: 1300px) {
                border: 1px solid $half_trimble_brand_grey
            }
        }
    }

    .buttons-group {
        background-color: $lightgray;
        padding: 10px;
        border-top: 1px solid #616161;
        border-radius: 0 0 0.5em 0.5em;

        .special-width {
            width: 420px;
        }
    }

    .grid-edit-modal {
        the-header, the-body {
            width: 100%;
            padding-right: 0;
            border-left: 1px solid $half_trimble_brand_grey;
            border-bottom: 1px solid $half_trimble_brand_grey;
            padding-left: 0.5em;

            @media screen and (max-width: 1600px) {
                border-left: 1px solid $half_trimble_brand_grey;
                border-right: 1px solid $half_trimble_brand_grey;
                border-bottom: 1px solid $half_trimble_brand_grey;
            }
        }

        .header-row {
            the-header .trimble-remove.remove-header {
                border-right: 1px solid #d1d1d1;
            }
        }

        the-header {
            background: #e2e2e2;

            .header-column label {
                height: 21px;
                padding-top: 2px;
                font-family: $font-primary, $font-secondary;
                font-weight: 700;
                color: $black;
                margin-top: 1px;
            }
        }

        the-body {
            border-radius: 0px 0px 3px 3px;
        }

        the-title {
            .panel-header {
                height: 26px;
            }
        }

        width: calc(100% - 3.2%);

        .header-column {
            .change-information-header {
                height: 16px;
                width: 16px;
                margin-left: 8px;
                margin-top: 6px;

                @media screen and (max-width: 1800px) {
                    margin-left: 6px;
                }

                @media screen and (max-width: 1600px) {
                    margin-left: 4px;
                }

                @media screen and (max-width: 1300px) {
                    margin-left: 2px;
                    margin-top: 16px;
                }
            }
        }

        .body-column {
            padding-bottom: 4.5px;
            padding-top: 1.5px;

            .change-information-body {
                height: 16px;
                width: 28px;
                margin-left: 3px;
                margin-top: 0px;
                cursor: pointer;

                @media screen and (max-width: 1800px) {
                    width: 27px;
                }

                @media screen and (max-width: 1600px) {
                    width: 24px;
                    margin-left: 2px;
                }

                @media screen and (max-width: 1300px) {
                    width: 20px;
                }

                &.icon-disabled {
                    opacity: 0.3;
                    cursor: no-drop;
                    pointer-events: none;
                }
            }

            &.trimble-remove.icon-disabled {
                opacity: 0.3;
                cursor: no-drop;
                pointer-events: none;
            }

            &.who-modified-information {
                cursor: default;

                img {
                    cursor: default;
                }
            }
        }

        .header-column,
        .body-column {
            &.width-11 {
                width: 11%;
                border-right: 1px solid #d1d1d1;
            }

            &.width-12 {
                width: 12%;
                border-right: 1px solid #d1d1d1;

                .custom-button {
                    max-width: 195px;
                }

                @media screen and (max-width: 1800px) {
                    .custom-button {
                        max-width: 191px;
                    }
                }

                @media screen and (max-width: 1600px) {
                    .custom-button {
                        max-width: 166px;
                    }
                }

                @media screen and (max-width: 1300px) {
                    .custom-button {
                        max-width: 135px;
                    }

                    .btn-group .custom-button {
                        border: 1px solid #707070;
                    }
                }

                @media screen and (min-width: 2000px) {
                    .custom-button {
                        max-width: 220px;
                    }
                }

                @media screen and (min-width: 2400px) {
                    .custom-button {
                        max-width: none;
                    }
                }
            }

            &.width-13 {
                width: 13%;
                border-right: 1px solid #d1d1d1;

                @media screen and (max-width: 1800px) {
                    .custom-button {
                        max-width: 191px;
                    }
                }

                @media screen and (max-width: 1600px) {
                    .custom-button {
                        max-width: 157px;
                    }
                }

                @media screen and (max-width: 1300px) {
                    .custom-button {
                        max-width: 135px;
                    }
                }
            }

            &.width-14 {
                width: 14%;
                border-right: 1px solid #d1d1d1;
            }

            &.width-4 {
                width: 4%;
                border-right: 1px solid #d1d1d1;
            }

            &.width-5 {
                width: 5%;
                border-right: 1px solid #d1d1d1;
            }

            &.width-6 {
                width: 6%;
                border-right: 1px solid #d1d1d1;
            }

            &.width-7 {
                width: 7%;
                border-right: 1px solid #d1d1d1;
            }

            &.width-2 {
                width: 2%;
                border-right: 1px solid #d1d1d1;
            }
        }

        .dropdown-menu .drop-down-table .first-column {
            width: 25%;
            padding-left: 4px
        }

        .border-bottom {
            padding-left: 17px;
            padding-bottom: 17px;
            padding-right: 16px;
            padding-top: 16px;
            border-bottom: 0;
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: textfield;
            margin: 0;
        }

        .body-row .the-body .body-column {
            .form-control {
                border: 1px solid #707070;
                border-radius: 2px;
                color: #000000;
            }

            .simpleDropDown {
                border: 1px solid #707070;
                border-radius: 2px;

                @media screen and (max-width: 1300px) {
                    border: 1px solid #707070;
                }
            }

            .custom-drop-down {
                .custom-button {
                    border: 1px solid #707070;
                    border-radius: 2px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    text-align: left;
                    width: 85% !important;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    text-align: left;
                    margin-top: 1px;

                    @media screen and (max-width: 1300px) {
                        border: 1px solid #707070;
                    }
                }

                .btn.dropdown-toggle {
                    border: 1px solid #707070;
                    border-radius: 2px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-left-style: none;
                    width: 13%;
                    margin-top: 1px;
                    margin-left: -2px;
                    margin-right: 0;

                    @media screen and (max-width: 1300px) {
                        border: 1px solid #707070;
                    }
                }

                .silva-search input {
                    width: 79% !important;
                }
            }
        }

        .simpleDropDown {
            font-size: 14px;
        }

        .edit-down-options[disabled="disabled"] {
            button,
            button[disabled="disabled"]:hover {
                border: 1px solid #616161;
                background-color: #eee;
            }
        }

        .remove-header {
            cursor: default;
        }
    }

    .simpleDropDown[disabled="disabled"] {
        background-color: #eee;
    }

    .select-option-dropDown {
        margin-right: 15px;

        .active-advanced-filter-modal {
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 1300px) {
        .select-option-dropDown {
            margin-right: 30px;
        }
    }
}
