.map-layers {
    .checkbox-container {
        line-height: 10px;
        margin-top: -5px;
        position: relative;
    }

    .sublist-checkboxmarker {
        z-index: 9999;
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        opacity: 0.0;
    }



    .sublayer-radio {
        margin-top: -2px;
        width: 14px;
        margin-right: 10px;
    }

    &.layers-panel-with-draweditor {
        margin-left: 305px !important;
    }

    .layers-scroll-wrapper {
        overflow-y: auto;
    }

    .settings-row {
        margin: 0px;
        padding: 0px;
        line-height: 50px !important;
        vertical-align: central;
        margin-bottom: auto;
        margin-top: auto;
    }

    .settings-row-first {
    }

    .button-wrapper {
        border-bottom: 1px solid $gray;
        width: 300px;
    }

    .restore-defaults {
        width: 208px;
    }

    .show-favorites {
        width: 30px;
    }

    .layer-favorites-button {
        margin-bottom: 8px;
    }

    .settings-item {
        vertical-align: middle;
        display: inline-block;
        line-height: 44px;
        height: 44px;
        min-width: 60px;
        margin: auto 0.5em 0 0;
        margin-left: 0.8em;
        right: 0px;
        padding: 0px;

        ul {
            > li {
                &.graybk {
                    background-color: $lightgray;
                }
            }

            label {
                line-height: initial;
                display: block;
                font-weight: initial;
                font-size: 13px;
                position: relative;
                padding-left: 1.2em;

                input {
                    margin-right: 0.5em;
                    position: absolute;
                    left: 0;
                    margin-top: 0;
                }
            }

            button {
                margin: 0 0.5em;
                width: calc(100% - 1em);
            }

            div {
                .divider {
                    margin: 0;
                }
            }
        }
    }

    .dropdown-toggle {
        margin-top: 0.4em;
        margin-bottom: 0em;
    }

    .dropdown-subheader {
        text-transform: uppercase;
        font-size: 0.8em;
        margin: 0.5em;
        margin-left: 1.7em;
        font-weight: bold;
        margin-bottom: 0.6em;
    }

    .dropdown-emptytext {
        text-transform: uppercase;
        font-size: 0.8em;
        margin: 0.5em;
        margin-left: 1.7em;
        font-weight: normal;
        opacity: 0.4;
        text-align: center;
    }

    .saved-lists-dropdown-for-map {
        margin-top: 0px;
        padding-top: 8px;
        max-width: 260px;
        min-width: 250px;
        top: 40px;

        > li > a {
            position: relative;
            white-space: initial;
            cursor: initial;
        }

        .favoritelist-name {
            max-width: calc(100% - 50px);
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            vertical-align: middle;
            margin-top: 10px;
            position: absolute;
        }

        .favoritelist-remove {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 12px;
            font-size: 12px;
            color: inherit;
            width: 20px;
            cursor: pointer;
        }

        .favoritelist-warning {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            margin-right: 25px;
            height: 14px;
            font-size: 12px;
            color: inherit;
            width: 17px;
            cursor: pointer;
            background-image: url(/content/images/svg/map/Warning_icon.svg);
        }

        &.site-expanded {
            left: -22px; /* adjust favorites toolbar to fit to the viewport */
        }
    }

    .dropdown-menu > li > a {
        height: 45px;
    }

    ul.dropdown-menu {
        max-height: 330px;
        overflow-y: auto;
        width: 320px;

        .divider {
            margin: 0;
        }
    }

    .icon {
        height: 17px;
        width: 18px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;

        &.icon-printer {
            background-image: url(/content/images/svg/print.svg);

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/print-green.svg);
            }
        }

        &.icon-settings {
            width: 25px;
            //height: 16px;
            background-image: url(/content/images/svg/settings-cog.svg);

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/settings-cog-green.svg);
            }
        }

        &.icon-favorite {
            background-image: url(/content/images/svg/favorite.svg);

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/favorite-green.svg);
            }
        }
    }

    .icon-button {
        //Override bootstrap's styles on icon-buttons
        > .btn {
            &:hover,
            &:focus {
                background-color: initial;

                .icon-settings {
                    background-image: url(/content/images/svg/settings-cog-green.svg);
                }
            }

            background-color: initial;
        }

        &.open {
            .icon-printer {
                background-image: url(/content/images/svg/print-green.svg);
            }

            .icon-favorite {
                background-image: url(/content/images/svg/favorite-green.svg);
            }
        }
    }

    .generic-warning {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        margin-left: 5px;
        margin-right: 5px;
        height: 14px;
        font-size: 12px;
        color: inherit;
        width: 17px;
        cursor: pointer;
        background-image: url(/content/images/svg/map/Warning_icon.svg);
    }

    &.panel { //Override the default styling in map-control-panels.scss to fill the entire height
        top: .2em;
        left: .2em;
        bottom: .2em;
        margin: 0;
        display: flex;
        flex-direction: column;

        .header-wrapper {
            padding: 1em 0.5em;
            text-transform: uppercase;

            .logo {
                height: 1.5em;
                width: 2em;
            }
        }

        .header-override {
            position: relative !important;
            left: 0px !important;
            top: 0px !important;
        }
    }

    &.set-defaults-arrow {
        border: 1px solid black;
        padding: .3em;
        border-radius: .3em;
    }

    &.rollback-changes {
        padding: 0.5em;
        min-height: calc(28px + 1em);

        .arrow {
            width: 3em;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
        }

        &:hover {
            background-color: $black;
            color: $white;

            .glyphicon {
                color: $white;
                border-color: $white;
            }
        }
    }

    .content {
        height: calc(100% - 55px);
        display: flex;
        flex-direction: column;
        flex-basis: 35px;
        flex-grow: 1;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;

        .layer-list-container {
            flex-grow: 1;
            overflow-y: auto !important;
            overflow-x: hidden;
            height: 100%;

            .layer-list {
                padding: .2em 0 2px 0;

                > li {
                    list-style: none;
                    padding-top: 0.5em;
                    padding-bottom: 0.5em;
                    position: relative;

                    &:last-of-type {
                        margin-bottom: 0em;
                    }

                    &.gray {
                        padding-top: 10px;
                        background-color: $lightgray;
                    }

                    &.graybk {
                        background-color: $lightgray;
                    }

                    .slider-row {
                        display: flex;
                        flex-basis: 25px;
                        margin-left: 1em;

                        .arrow-container {
                            position: relative;
                            height: 20px;
                            width: 2em;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        .slider-checkbox {
                            width: 2.5em;
                            display: flex;

                            [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
                                top: 4px;
                            }

                            [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
                                top: 4px;
                            }
                        }

                        input {
                            max-width: 3.5em;
                        }
                    }

                    .sublayer-container-row {
                        display: flex;
                        flex-basis: 25px;
                        margin-left: 1em;

                        .sublayer-title-container {
                            min-width: 150px;
                            margin-right: 0.5em;
                        }

                        .arrow-container {
                            margin-top: -0.6em;
                            position: relative;
                            height: 20px;
                            width: 2em;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        input {
                            max-width: 3.5em;
                        }
                    }

                    .subsublayer-container {
                        margin-top: 0.5em;
                        margin-left: -200px;
                    }

                    .subsublayer-container-row {
                        display: flex;
                        flex-basis: 25px;
                        margin-left: 1em;

                        .arrow-container {
                            position: relative;
                            height: 20px;
                            width: 2em;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        input {
                            max-width: 3.5em;
                        }
                    }

                    > label {
                        top: -10px;
                        margin-left: .3em;

                        &.sublist-checkbox {
                            top: -15px;
                            margin-left: .5em;
                            z-index: 999;
                        }
                    }

                    > .layer-list {
                        padding-left: 2em;
                        margin: .6em -0.5em 0 -0.5em;
                        border-left: 1px solid black;
                        border-right: 1px solid black;
                        background: $lightgray;

                        .layer-title {
                            left: 25px;
                        }
                    }

                    &.dndPlaceholder {
                        background: $lightgray;
                        padding: 0px;
                        min-height: 40px;
                    }

                    .sublist {
                        padding-left: 3em;
                        margin-top: 0.4em;
                        border: 0;
                        background: $lightgray;
                        // fix for aligning checkboxes properly in the sublist.
                        [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
                            top: 0px;
                        }

                        [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
                            top: 0px;
                        }

                        .sublist-item {
                            padding-top: 1px;
                            padding-bottom: 1px;

                            &:not(:first-child) {
                                margin-top: 0;
                            }

                            &:first-child {
                                margin-top: 0.5em;
                            }

                            &:last-child {
                                padding-bottom: 0.5em;
                            }
                        }

                        .group {
                            text-transform: uppercase;
                            font-weight: bold;
                        }

                        .sublist-item-plain {
                            height: 20px;
                        }

                        .subsublist-item {
                            height: 20px;

                            &:not(:first-child) {
                                padding-left: 0em;
                                margin-top: 0;
                            }

                            &:last-child {
                                padding-bottom: 1em;
                            }
                        }
                    }
                }
            }
        }
    }

    &.slider-drag-and-drop-button {
        width: 1em;
        border: 1px solid $gray;
        border-right: 0;
        height: 25px; // for some reason slider being 24 pixels high still requires this to be 25px to align properly. 24px would happen by default...
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("/content/images/svg/UpAndDownBlackArrow.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-color: white;
        background-size: 50%;

        &:active {
            background-color: $black;
            background-image: url("/content/images/svg/UpAndDownWhiteArrow.svg");
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .tooltip-inner {
        background: $blue !important;
        color: white;
    }

    .tooltip {
        display: none;
    }

    .top-level-tooltip {
        display: block;
        padding-left: 0px;
        padding-top: 0px;
        position: fixed;
        user-select: none;
    }

    .top-level-tooltip-main.top {
        margin-top: -22px; //	Set the toolbit a bit lower than default
        margin-left: -33px; //	Set the toolbit a bit lower than default
    }

    .top-level-tooltip-inner {
        background: $blue;
        color: white;
    }

    .top-level-tooltip-arrow {
        //border-top-color: $blue;
        display: none;
    }

    &.opacity-slider-wrapper {
        position: relative;
        flex-grow: 1;
        //override default slider styling:
        > .slider {
            width: 100%;
        }

        .slider-selection {
            background: linear-gradient(to right, white, gray);
        }

        .slider-track {
            height: 25px !important; //override the default height in bootstrap-slider
            border: 1px solid $gray;
            border-radius: 0;
            box-shadow: none;
            margin-top: 0 !important;
            top: 0 !important;

            .slider-track-low, .slider-selection, .slider-track-high {
                border-radius: 0;
                box-shadow: none;
            }
        }

        .slider-handle.custom {
            &:before {
                content: none;
            }

            background: no-repeat url('/content/images/svg/map/SliderHandle.svg');
            background-size: contain;
            height: 30px;
            margin-left: -5px; //adjust the handler position to the end of the slider
        }

        .tooltip-main.top {
            margin-top: -26px; //	Set the toolbit a bit lower than default
            display: none;
        }

        .tooltip-inner {
            background: $blue;
            color: white;
        }

        .tooltip-arrow {
            //border-top-color: $blue;
            display: none;
        }
    }

    .top-level-tooltip-main {
        display: none;
    }

    &.layer-title {
        pointer-events: none; //Ignore clicks to this element (click through to the element under it)
        position: absolute;
        z-index: 800;
        left: 0.5em;
        right: 0.5em;
        font-size: 90%;
        top: 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 90%;
        overflow: hidden;
    }

    &.sublayer-title {
        padding-top: 0px;
        white-space: nowrap;
        text-overflow: ellipsis;
        //max-width: 150px;
        overflow: hidden;
        display: block;
        font-size: 90%;
    }

    &.subsublayer-title {
        padding-top: 0px;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 150px;
        overflow: hidden;
        font-size: 90%;
    }

    &.opacity-input {
        position: relative;
    }

    &.arrow-open-panel {
        transition: transform 100ms linear;
        position: relative;
        top: 4px;
        // to make the arrow stay in place when transroming
        height: 1.1em;
        width: 1.1em;

        &.open {
            transform: rotate(90deg);
        }
    }

    &.arrow-open-sub-panel {
        transition: transform 100ms linear;
        position: relative;
        top: 11px;
        // to make the arrow stay in place when transroming
        height: 1.1em;
        width: 1.1em;

        &.open {
            transform: rotate(90deg);
        }
    }

    &.arrow-placeholder {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 10px;
    }

    &.arrow-warning-placeholder {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 5px;
        width: 17px;
        height: 14px;
        cursor: pointer;
        background-image: url(/content/images/svg/map/Warning_icon.svg);
    }

    &.arrow-info-placeholder-unselected-orto {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 0px;
        width: 20px;
        height: 70px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_WhiteBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_OrangeBackground.svg);
        }
    }

    &.arrow-info-placeholder-disabled {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 0px;
        width: 16px;
        height: 70px;
        cursor: not-allowed;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_GreyBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_GreyBackground.svg);
        }
    }

    &.arrow-info-placeholder-unselected-other {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 0px;
        width: 16px;
        height: 70px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_WhiteBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_LimeGreenBackground.svg);
        }
    }

    &.arrow-info-placeholder-selected-orto {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 0px;
        width: 20px;
        height: 70px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_BlackBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_OrangeBackground.svg);
        }
    }

    &.arrow-info-placeholder-selected-other {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 4px;
        width: 15px;
        height: 70px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_BlackBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_LimeGreenBackground.svg);
        }
    }

    &.arrow-sublayer-warning-placeholder {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 7px;
        width: 17px;
        height: 14px;
        cursor: pointer;
        background-image: url(/content/images/svg/map/Warning_icon.svg);
    }

    &.arrow-subsublayer-warning-placeholder {
        width: 1.1em;
        height: 1.1em;
        position: relative;
        top: 2px;
        left: 5px;
        width: 17px;
        height: 14px;
        cursor: pointer;
        background-image: url(/content/images/svg/map/Warning_icon.svg);
    }

    .show-favorites-list {
        margin-top: 6px;
    }
}
