﻿@import '../../../shared/sass/_variables.scss';


.message-list-modal {

    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    padding-bottom: 1px;

    .title-bar {

        .header-left {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
            display: inline-flex;
            margin-left: 15px;
            line-height: 46px;
            vertical-align: middle;

            div:first-child {
                padding-right: 20px;
            }

            div:last-child {
                color: $trimble_blue_light;
                text-transform: capitalize;
            }
        }

        .header-right {
            margin-top: 0px;
            margin-right: 0px;

            .glyphicon-remove:hover {
                color: $blue;
                cursor: pointer;
            }

            .search-area {
                display: inline-block;
                margin-top: 9px;
                margin-right: 20px;

                .search-bar {
                    display: flex;
                    flex-grow: 6;
                    border: 1px solid $gray;
                    border-radius: 0.5em;
                    height: 2em;
                    line-height: 2em;
                    overflow: hidden;
                    padding: 0 0.4em;
                    background-color: $trimble_white;
                    color: $trimble_black;

                    span.glyphicon-search,
                    span.glyphicon-remove {
                        top: 6px;
                    }

                    input {
                        border: 0;
                        height: 2em;
                        outline: 0;
                        padding-left: 0.2em;
                        width: 360px;
                    }
                }

                .filtering-bar {
                    align-self: flex-end;
                    display: flex;
                    flex-basis: 5em;
                    flex-grow: 1;

                    label {
                        &:before {
                            top: 0;
                        }

                        &:after {
                            top: 4px;
                        }
                    }
                }
            }
        }
    }

    .middle-part {
        margin: 15px;
        border-radius: 0.5em;
        border: 1px solid #616161;

        .grid-container {
            .background-proprieties {
                background-size: 7px;
                background-position: center center;
                background-repeat: no-repeat;
            }

            .priority-message-col {
                @extend .background-proprieties;
                background-image: url(../content/images/svg/CriticalNotificationSymbol_Orange.svg);
            }

            .priority-message-header {
                @extend .background-proprieties;
                background-size: 6px;
                background-image: url(../content/images/svg/CriticalNotificationSymbol_Black.svg);
            }

            .unread-message {
                color: $trimble_blue_light;
            }

            .sp-grid {
                height: 275px;
            }
        }

        .panel-footer {
            border-top: none;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            padding-top: 15px;
            padding-bottom: 15px;

            .button-margin {
                margin-top: -3px;
            } 
            
            .height-options {
                margin-top: 5px;
                display: inline-flex;

                .fix-line-height {
                    line-height: 17px;
                }

                .radio-button-align {
                    position: absolute;
                    margin-top: 2px;
                }

                .label-align {
                    margin-left: 17px;
                }

                span {
                    padding-right: 10px;
                    font-weight: 700;
                }

                label {
                    input[type=radio] {
                        vertical-align: sub;
                    }
                }
            }
        }
    }


    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .border-top {
        border-top: 1px solid $gray;
    }
}
