﻿@import '../../shared/sass/_variables.scss';

.executor {
    $disabled-background-color: #eee;
    $gray: #D1D1D1;
    $cell-height: 31px;
    $same-left-margin: 3px;

    input[type='text'] {
        padding-left: $same-left-margin;
    }

    .lightgrey-cell {
        background-color: #efefef;
    }

    .executors-grid {
        height: 550px;

    }

    button {        
        min-width: 200px;
    }
}