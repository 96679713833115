@import "components/workingSites/harvestingAreas/harvestingAreaStorageAmounts/SavingMeasurementsDoc";

@font-face {
    font-family: 'Myriad Pro';
    src: url("../content/fonts/MyriadPro-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'Myriad Pro Semibold';
    src: url("../content/fonts/MyriadPro-Semibold.otf") format("opentype");
}

/* BASE - variable file along with mixins and placeholders */
@import 'shared/sass/_variables.scss';
@import 'shared/sass/_mixins.scss';
@import 'shared/sass/_placeholders.scss';
@import 'shared/sass/_animations.scss';

/* Common custom styles*/
@import 'container-styles.scss';
@import 'agGridGenericStyle.scss';

/* Common styles */
@import 'app/shared/sass/checkbox.scss';
@import 'app/shared/sass/flex-table.scss';
@import 'app/shared/sass/forms.scss';
@import 'app/shared/sass/mobile.scss';
@import 'app/shared/sass/controls.scss';
@import 'app/shared/sass/sp-accordion.scss';
@import 'app/shared/sass/ag-grid-common.scss';
@import 'app/shared/sass/list-view-settings-row.scss';

/* Bootstrap customizations */
@import 'app/shared/sass/sp-panel.scss';
@import 'app/shared/sass/sp-modal.scss';

/* COMPONENTS - component based scss files */
@import 'app/components/setSelectpicker/selectPicker.scss';
@import 'app/components/landingPage/landing-page-style.scss';
@import 'app/components/main/style/main-style.scss';
@import 'app/components/customers/components/weekpicker/weekpicker.scss';
@import 'app/components/main/style/materialSwitchComponent.scss';
@import 'app/components/main/style/infoBoxComponent.scss';
@import 'app/components/main/style/displayInfoComponent.scss';
@import 'app/components/customers/customer-style.scss';
@import 'app/components/mhy/mhy-style.scss';
@import 'app/components/forestProperty/forestProperty-style.scss';
@import 'app/components/admin/admin-style.scss';
@import 'app/components/mhy/components/copyWoodAssortmentsModal';
@import 'app/components/mhy/components/productInfoModal.scss';
@import 'app/components/customers/components/customerRealEstatesResponsible';
@import 'app/components/customers/components/changeWorksitesOwnershipModal';
@import 'app/components/customers/components/workSite/supervisingTimberTrade.scss';
@import 'app/directives/reloginDirective-style.scss';
@import 'app/directives/navigation/navigation.scss';
@import 'app/components/settings/settings.scss';
@import 'app/shared/partials/fileUploadDirective.scss';
@import 'app/directives/alerts/alertDirective.scss';
@import 'app/directives/languageSelection/languageSelection.scss';
@import 'app/directives/loader/loaderDirective.scss';
@import 'app/directives/user/userDirective.scss';
@import 'app/directives/tabs/tabsDirective.scss';
@import 'app/components/upkeep/upkeep.scss';
@import 'app/components/help/help.scss';
@import 'app/directives/navigationButtons/navigationButtons.scss';
@import 'app/directives/map/mapDirective-style.scss';
@import 'app/directives/searchDropDown/searchDropDown.scss';
@import 'app/directives/productQuality/productQualityDirective.scss';
@import 'app/directives/multiselectionDropDown/multiselectionDropDown.scss';
@import 'app/directives/multiselectionTableDropDown/multiselectionTableDropDown.scss';
@import 'app/directives/multiselectionGrid/multiselectionGrid.scss';
@import 'app/directives/betaTagDirective/betaTagDirective.scss';
@import 'app/components/common/common.scss';
@import 'app/components/workingSites/workingSites.scss';
@import 'app/components/common/selectActiveForestPlanForRealEstateModal.scss';
@import 'app/components/common/changeStandIdsForSelectedForestPlansModal.scss';
@import 'app/components/common/selectRealEstateModal.scss';
@import 'app/components/common/createWorkSiteModal.scss';
@import 'app/components/common/textEditModal.scss';
@import 'app/components/common/addWorkSiteCompartmentModal.scss';
@import 'app/components/common/selectDateModal.scss';
@import 'app/components/customers/components/workSite/selectTimberTradeModal.scss';
@import 'app/components/workingSites/harvestingAreas/harvestingAreaStorageAmounts/SavingMeasurementsDoc.scss';
@import 'app/components/workingSites/inventoryStatuses/storagesIndexMap.scss';
@import 'app/components/mhy/components/changeBuyerCodeModal.scss';
@import 'app/directives/selectedDefinition/selectedDefinition.scss';
@import 'app/directives/filterDirectives/filterDateField/filterDateField.scss';
@import 'app/directives/filterDirectives/refineSearch/refineSearch.scss';
@import 'app/directives/filterDirectives/filterTimberTradeCountiesField/filterTimberTradeCountiesField.scss';
@import 'app/directives/filterDirectives/filterByForestPlanId/filterByForestPlanId.scss';
@import 'app/directives/filterDirectives/filterStringField/filterStringField.scss';
@import 'app/components/common/messageAlerts/alertWindowTemplate.scss';
@import 'app/components/common/messageAlerts/messageListModal.scss';
@import 'app/directives/favouriteGridConfigs/favouriteGridConfigs.scss';
@import 'app/components/common/addNameInList/addNameInListModal.scss';
@import 'app/directives/userMenu/userMenuDirective.scss';
@import 'app/directives/helpMenu/helpMenuDirective.scss';
@import 'app/components/customers/components/servicesTab/mobileApplicationActivationModal.scss';
@import 'app/directives/gridEditModal/gridEditModal.scss';
@import 'app/directives/filtering/gridSettingStorageDirective.scss';
@import 'app/directives/filtering/gridSearchDirective.scss';
@import 'app/directives/filtering/filterItemDirective.scss';
@import 'app/directives/customerInfoGuidelines/customerGuidelineInfoDirective.scss';
@import 'app/directives/bankAccountDirective.scss';
@import 'app/directives/swedishBankAccountDirective/svBankAccountDirective.scss';

@import "components/measurementDocument/measurementCertificateStyles.scss";
@import "app/components/common/paymentDate/paymentDateCalender.scss";
@import 'app/components/measurementDocument/measurementDocument.scss';
@import "app/components/measurementDocument/summary/summaryTab.scss";
@import 'app/components/measurementDocument/measurementDataSelection/measurementDataSelectionModal.scss';
@import 'app/components/measurementDocument/measurementDetails/measurementDetailsTab.scss';
@import 'app/components/measurementDocument/measurementDetails/newMeasurementAssortment.scss';
@import 'app/components/measurementDocument/measurementDetails/transferVolume.scss';
@import 'app/components/measurementDocument/measurementDetails/measurementAdjustmentModal.scss';
@import 'app/components/measurementDocument/measurementDetails/rateSummaryAdjustmentModal.scss';
@import 'app/components/measurementDocument/measurementDetails/editMeasurementDetailsGroupModal/editMeasurementDetailsGroupModal.scss';
@import 'app/components/customers/components/workSite/tabs/forestryCenterTab.scss';
@import 'app/components/measurementDocument/measurementDetails/ForestCertificationModal.scss';
@import 'app/components/measurementDocument/basicDetails/changeStatusOrTypeModal.scss';
@import "components/measurementDocument/basicDetails/BasicDetailsTab.scss";
@import 'app/components/measurementDocument/payment/paymentTab.scss';
@import 'app/components/measurementDocument/summary/editContractTermsModal.scss';
@import 'app/components/measurementDocument/summary/addContractTermsModal.scss';
@import 'app/components/admin/paymentSettings/paymentSettings.scss';
@import 'app/components/admin/generalData/reasonMaintenance/reasonMaintenance.scss';
@import 'app/components/admin/generalData/generalDataMaintenance/generalDataMaintenance.scss';
@import 'app/components/measurementDocument/payment/addEditPaymentReceiversModal.scss';
@import 'app/components/mhy/timberTradeAndHarvestSpecifications/unitRateAssortmentGroups/unitRateAssortmentGroups.scss';
@import 'app/components/mhy/timberTradeAndHarvestSpecifications/unitRateAssortmentGroups/editTimberTradeUnitRate/editTimberTradeUnitRate.scss';
@import 'app/components/admin/attachments/attachments.scss';
@import 'app/components/customers/components/workSite/tabs/components/createTradeAreaFromStandsModal.scss';
@import 'app/components/customers/components/workSite/contractTermsAndPhrases/contractTermsAndPhrases.scss';
@import 'app/components/customers/components/workSite/contractTermsAndPhrases/addContractTermsAndPhrasesModal/addContractTermsAndPhrasesModal.scss';
@import 'app/components/customers/components/workSite/approveContractDateCheckModal.scss';
@import 'app/components/customers/components/workSite/changeContractStatusModal.scss';
@import 'app/components/customers/components/workSite/changeContractStatusAdditionalInfoModal.scss';
@import 'app/components/customers/components/workSite/tradeContractSummary.scss';
@import "components/customers/components/workSite/addPaymentsModal.scss";
@import "components/customers/components/workSite/upsertAdvancePaymentModal.scss";
@import "components/customers/components/workSite/upsertManualPaymentOrCorrection.scss";
@import "app/components/measurementDocument/Payment/addEditInvestmentModal/addEditInvestmentModal.scss";
@import "app/components/measurementDocument/payment/addPaymentDeductions/addPaymentDeductionsModal.scss";
@import "app/components/measurementDocument/payment/editPaymentDeduction/editPaymentDeductionModal.scss";
@import "components/customers/components/workSite/investments/manageInvestmentsModal.scss";
@import "app/components/workingSites/harvestingAreas/readyToHarvest/readyToHarvestModal.scss";
@import "app/components/workingSites/harvestingAreas/storagePileCards/storagePileCardsModal.scss";

@import "app/components/workingSites/harvestingAreas/logisticInformationReceiver/logisticInformationReceiver.scss";

//This file is strictly for testing purposes and will be removed later
@import 'app/components/pdfPrintTest/pdfTest.scss';
@import "app/components/measurementDocument/MixedAssortmentsResolveConflictModal/MixedAssortmentsResolveConflictModal.scss";
@import "app/components/mhy/timberTradeAndHarvestSpecifications/defaultBucking/defaultBucking.scss";
@import "app/components/mhy/timberTradeAndHarvestSpecifications/defaultBucking/addEditAssortmentModal.scss";
@import "app/components/mhy/timberTradeAndHarvestSpecifications/deliveryDestinations/deliveryDestinations.scss";
@import 'app/components/customers/components/attachment/manageAttachment.scss';
@import 'app/components/customers/components/attachment/attachFile.scss';
@import 'app/components/workingSites/tradeAreas/tradeAreaAssortments/harvestingCost/harvestingCost.scss';
@import "app/components/workingSites/tradeAreas/tradeAreaAssortments/harvestingCost/harvestingAndHaulingCost";

@import 'app/components/customers/components/attachment/fileAttachmentList.scss';
@import 'app/components/customers/components/signatureDetails/signatureDetails.scss';
@import "app/components/mhy/timberTradeAndHarvestSpecifications/deliveryDestinations/addOrEditDeliveryDestination.scss";
@import "app/components/mhy/timberTradeAndHarvestSpecifications/deliveryDestinations/compensationChainAssortment.scss";
@import "app/components/mhy/timberTradeAndHarvestSpecifications/deliveryDestinations/addEditCompensationChainAssortment.scss";
@import "app/components/mhy/timberTradeAndHarvestSpecifications/deliveryDestinations/tabs/basicDetails/deliveryDestinationBasicDetails.scss";
@import "app/components/mhy/timberTradeAndHarvestSpecifications/deliveryDestinations/tabs/assortments/deliveryDestinationAssortments.scss";
@import "components/mhy/timberTradeAndHarvestSpecifications/generalCosts/generalCosts.scss";
@import "components/mhy/timberTradeAndHarvestSpecifications/generalCosts/addEditGeneralCost/addEditGeneralCostsModal.scss";
@import 'app/components/admin/paymentSettings/paymentDeductions/paymentDeductions.scss';
@import 'app/components/measurementDocument/measurementDetails/adjustmentModal/adjustmentModal.scss';
@import 'app/components/workingSites/inventoryStatuses/releaseToTransportIndexMap.scss';
@import '../app/components/admin/buyersCompensation/manageBuyerCompensation.scss';
@import '../app/components/admin/buyersCompensation/addCompensationEuro.scss';
@import '../app/components/measurementDocument/accontancyModal/accountancyModal.scss';
@import '../app/components/customers/components/workSite/deliverySchedule/DeliveryScheduleModal.scss';
@import '../app/components/measurementDocument/accountancyValidationModal/accountancyValidationModal.scss';
@import 'app/components/admin/paymentSettings/accountingManagement/accountingManagement.scss';
@import 'app/components/admin/paymentSettings/accountingManagement/accountingGeneralRules/accountingGeneralRules.scss';
@import 'app/components/admin/paymentSettings/accountingManagement/accountingAssortments/accountingAssortments.scss';
@import '../app/components/customers/components/workSite/addWoodassortmentsModal.scss';
@import '../app/components/admin/paymentSettings/accountingManagement/addEditForceValueModal/addEditForceValueModal.scss';
@import '../app/components/measurementDocument/measurementDetails/editTradeAreaIdAssortment/editTradeAreaIdAssortment.scss';
@import "../app/components/measurementDocument//measurementDataSelection/addTradeAreaIdAssortment/addTradeAreaIdAssortment.scss";
@import "../app/components/workingSites/workSiteList/gridScopes/reviewAndApprove/reviewAndApproveModal.scss";
@import "../app/components/mhy/timberTradeAndHarvestSpecifications/defaultFactoryPrices/filterDefaultFactoryPricesModal.scss";
@import "../app/components/measurementDocument/measurementDetails/calculateMatrixRateModal/calculateMatrixRateModal.scss";
@import "../app/components/admin/forestPayments/forestPaymentsMain.scss";
@import "../app//components/measurementDocument/measurementDetails/unitRateAdjustmentModal/unitRateAdjustmentModal.scss";
@import "../app/components/workingSites/tradeAreas/tradeAreaAssortments/harvestingCost/getCostFromCfbModal/getCostFromCfbModal.scss";
@import "../app/components/workingSites/workSiteList/metryFileModalDialogue/createMetryFileModal.scss";
@import "../app/components/workingSites/workSiteList/timberBuyerReportModal/timberBuyerReportModal.scss";
@import "../app/components/workingSites/workSiteList/harvestedAmountsFileDialog/harvestedAmountsFileModal.scss";
@import "../app/components/measurementDocument/measurementDetails/calculateForestCertificationModal/calculateForestCertificationModal.scss";
@import "../app/components/admin/forestPayments/forestPayments/updateForestPayment/updateForestPaymentModal.scss";
@import "../app/components/measurementDocument/measurementDataSelection/linkTradeAreaAssortmentModal/linkTradeAreaAssortmentModal.scss";
@import "../app/components/mhy/timberTradeAndHarvestSpecifications/timberTradeAndHarvestSpecifications.scss";
@import "../app/components/workingSites/workSiteList/lukePurchasedTimberModalDialogue/purchasedTimberFileModal.scss";
@import "../app/components/workingSites/tradeAreas/energyTradeArea/viewEnergyTradeAreaDetails/viewEnergyTradeAreaDetails.scss";
@import "../app/components/admin/forestPayments/annualAnnouncement/annualAnnouncementModal.scss";
@import "../app/components/admin/forestPayments/forestPayments/editPaymentDate/editPaymentDateModal.scss";
@import "../app/components/admin/forestPayments/ledgerBalance/ledgerBalanceIntervalModal/ledgerBalanceIntervalModal.scss";
@import "../app/components/admin/paymentSettings/paymentSettingsTab/organizationSetting/editOrganizationSetting/editOrganizationSettingModal.scss";
@import "app/components/customers/components/realEstateTabs/components/standInventoryChart.scss";
@import "app/components/customers/components/realEstateTabs/components/suggestedOperationChart.scss";
@import "app/directives/charts/chartLegend.scss";
@import "app/directives/eSign/eSignComponent.scss";
@import "../app/components/measurementDocument/measurementDetails/assortmentChangeModal/assortmentChangeModal.scss";

@import "../app/components/measurementDocument/payment/dividePayment/dividePaymentModal.scss";
/* Some global css */
html {
    height: 100%;
}

body {
    font-family: $font-primary, $font-secondary;
    line-height: 18px;
}

a {
    cursor: pointer;
}

.main-view-container {
    position: relative;
    //The main-view container is always at least 100% of screen-height minus footer-height
    min-height: calc(100vh - 195px); //header and footer height. TODO refactor container height calculation when removing footer, as it will no longer be needed
    //overflow: hidden;
    background-color: $trimble_very_light_grey;
}

header.hideNavigation ~ .main-view-container {
    //When navigation hidden, remove it from the calculation for minimun height
    min-height: calc(100vh - 65px); //remove remaining header, footer and show/hide button height
    display: flex;
    flex-direction: column;
}

.main-view-container:after {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 202px;
    background: $gray;
    content: "";
    z-index: -1;
}

/* some general form formatting things */
.form-group {

    .padded-container {
        padding: 0.5em !important; // because admin-styles has customer-info class defined at the top
    }

    .border-top-dark {
        border-top: 1px solid $darkgray;
    }

    .border-right {
        border-right: 1px solid $gray;
    }

    &.border-bottom,
    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .border-right-white {
        border-right: 1px solid $white;
    }

    .orangefield {
        background-color: $orange;
        color: white;
    }

    .orangetext {
        color: $orange;
    }
}

div.date-picker {
    padding: 0 4px 0 0;
}

.hide-date-picker {

    &::-webkit-calendar-picker-indicator,
    &::-webkit-inner-spin-button {
        display: none;
    }
}

input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 1;
    display: block;
    background: url(../../content/images/svg/calendar_today_black.svg) no-repeat;
    width: 18px;
    height: 18px;
    border-width: thin;

    &:disabled {
        display: none;
    }
}

.datepicker-sp .btn:disabled {
    font-size: 14px;
    font-weight: normal;
    color: gray !important;
    background-color: #fff;
}

.datepicker-sp .btn:enabled {
    font-size: 14px;
    font-weight: bold;
    color: black !important;
}

.hide-date-field {
    width: 86px;
    border: none;
    line-height: 1em;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.no-border {
    border: none !important;
}

.margin-bottom {
    margin-bottom: 10px !important;
}

.padded-container {
    padding: 0.5em;
}

.border-right-dark {
    border-right: 1px solid $darkgray;
}

.border-left-dark {
    border-left: 1px solid $darkgray;
}

.border-bottom-dark {
    border-bottom: 1px solid $darkgray;
}

.cursor-pointer {
    cursor: pointer;

    input {
        cursor: pointer;
    }
}

.control-label.required::after {
    content: "*";
    color: $trimble_tf_product_green;
}

.rounded-border-top-left {
    border-radius: 0.5em 0 0 0 !important;
}

.rounded-border-top-right {
    border-radius: 0 0.5em 0 0 !important;
}

.rounded-border-bottom-right {
    border-radius: 0 0 0.5em 0 !important;
}

.rounded-border-bottom-left {
    border-radius: 0 0 0 0.5em !important;
}

.rounded-border-bottom {
    border-radius: 0 0 0.5em 0.5em !important;
}

.horizontal-separator {
    border-top: 1px solid $gray;
    margin: 0.5em 0;
}

.text-uppercase {
    text-transform: uppercase;
}

.flat-borders {
    border-radius: 0 !important;
}

.form-control {
    border: 1px solid $darkgray;
}

.form-horizontal .control-label {
    text-align: left;
}

textarea {
    margin-top: 1em;
}

input.btn[disabled],
button[disabled],
button.btn[disabled],
button.disabled,
input.btn.disabled {
    background-color: $lightgray;
    opacity: 1 !important;
    border: 1px solid rgba($trimble_blue_light, 0);

    &:hover {
        background-color: $gray;
    }
}

/* Input fields */
.date-format {
    border: none;
    color: inherit;
    background-color: inherit;
}

.disabled-button {
    background-color: $lightgray;
    pointer-events: none;
    cursor: default;
}


/* General table styles */
.silva-table {
    background-color: $white;

    thead {
        background-color: $gray;
        text-transform: uppercase;
        border-radius: 0.5em 0.5em 0 0;

        th {
            font-weight: normal;
            padding: 0.2em 0.5em;
        }

        th:first-child {
            border-radius: 0.5em 0 0 0;
        }

        th:last-child {
            border-radius: 0 0.5em 0 0;
        }
    }

    td {
        border-right: 1px solid $gray;
        padding: 0 0.2em;
    }

    td:last-child {
        border: 0;
    }

    tr:first-child {
        td {
            padding-top: 0.5em;
            padding: 0 0.2em;
        }
    }

    tbody tr:hover,
    tbody tr.active {
        background: black;
    }
}

.silva-search {
    @extend %silva-search;
    background-color: $trimble_white;
}

.grey-cell-style {
    background-color: rgb(239, 239, 239);
}

/* General flex-classes */

.flex {
    @extend %flex;
}

.flex-wrap {
    @extend %flex-wrap;
}

.flex-grow-0 {
    flex: 0;
}

.flex-grow-1 {
    @include flex-grow(1);
}

.flex-grow-2 {
    @include flex-grow(2);
}

.double-border-top {
    width: 100%;
    height: 3px;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
}

.sp-alert.info {
    z-index: 99999 !important;
}

.mainViewContainerAfter {
    position: absolute;
    left: 0;
    height: 40%;
    width: 100%;
    background-color: $gray;
}

/* General icons, buttons etc */
.notes-icon,
.info-icon,
.lock-open-icon,
.lock-closed-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
}

.notes-icon {
    background-image: url(../../content/images/svg/note.svg);
}

.info-icon {
    background-image: url(../../content/images/svg/information.svg);
}

.trimble-remove {
    content: url(../content/images/svg/Delete_Entity.svg);
    height: 20px;
    width: 20px;
}

.icon-user {
    background-image: url(../../../content/images/svg/User_Menu_black.svg);

    &:hover {
        cursor: pointer;
    }
}

.icon-user-grey {
    background-image: url(../../../content/images/svg/User_Menu_gray.svg);
}

.user-image-button {
    width: 25px;
    height: 20px;
}

.lock-open-icon {
    background-image: url(../../content/images/svg/OfflineLockOpen.svg);
}

.lock-closed-icon {
    background-image: url(../../content/images/svg/OfflineLockClosed.svg);
}

.lock-open-white-icon {
    background-image: url(../../content/images/svg/OfflineLockWhiteOpen.svg);
}

.lock-closed-white-icon {
    background-image: url(../../content/images/svg/OfflineLockWhiteClosed.svg);
}

.favorite-list-button {
    display: block;
}

.glyphicon-remove:hover {
    color: $blue;
    cursor: pointer;
}

.transportban-header {
    background-image: url(../../content/images/svg/map/MapTag_AdditionalBubble_ForbiddenDriwingDirection.svg);
    background-repeat: no-repeat;
    background-size: 18px;
    background-position-y: 0px;
    background-position-x: 5px;
}

/* hide "no rows" bulletin in grid contains no rows */
span.ag-overlay-no-rows-center {
    display: none;
}

.simpleDropDown {
    border-color: $gray;
    width: 100%;
    color: black;
    background-color: white;
    border-radius: 3px;
    -webkit-appearance: none;
    background-image: url("../../content/images/svg/material-expand_more_black.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 20px;
    padding-right: 20px !important;

    &[disabled] {
        background-color: $disabled-background-color;
    }
}

.mandatory-field {
    color: $trimble_tf_product_green;
}

.grid-footer {
    background-color: $lightgray;
    border: 1px solid $darkgray;
    border-top: 0;
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    padding: 0.5em;
}

%no-selected-row-highlight {
    .ag-body-container {
        .ag-row-selected {
            &:hover {
                .ag-cell {
                    color: #fff;
                    background-color: #000;
                }
            }

            &.ag-row-even {
                background-color: #fff !important;
            }

            &.ag-row-odd {
                background-color: #f6f6f6 !important;
            }

            .ag-cell {
                color: inherit;
                background-color: inherit;
            }
        }
    }
}

input.field-invalid,
textarea.field-invalid {

    &:not(:disabled),
    select:not(:disabled) {
        background-color: $lightred !important;
    }

    &:not(:disabled) {
        background-color: $lightred !important;
    }
}

.field-invalid {

    input:not(:disabled),
    select:not(:disabled),
    textarea:not(:disabled) {
        background-color: $lightred !important;
    }

    .custom-drop-down {
        button:not(:disabled) {
            background-color: $lightred !important;
        }
    }
}

.field-invalid {
    .custom-drop-down {

        input:not(:disabled),
        select:not(:disabled),
        textarea:not(:disabled) {
            background-color: initial !important;
        }
    }
}

.field-validation-tooltip {
    font-size: 14px;

    .tooltip-inner {
        padding: 9px 14px;
        background-color: $blue;
        border: 1px solid $blue;
        color: white;
        opacity: 1;
    }

    .tooltip-arrow {
        border-bottom-color: $blue !important;
    }
}

.field-validation-tooltip.tooltip.in {
    opacity: 1;
}

uib-accordion {
    .panel-group {
        //panel opened and closed buttons should work with CSS only
        i.glyphicon-chevron-right {
            display: block;
        }

        i.glyphicon-chevron-down {
            display: none;
        }

        .panel-open {
            i.glyphicon-chevron-right {
                display: none;
            }

            i.glyphicon-chevron-down {
                display: block;
            }
        }
    }
}

.validation-cell {
    input {
        background-color: inherit;
        border: none;
        width: 100%;
    }
}

/* arrow container */
.arrow-container {
    position: relative;
    height: 20px;

    &.arrow-container-height-2x {
        top: -20px;
    }

    .registry-position {
        position: absolute;
        height: 21px;
        left: calc(38.5% + 3px);
        width: calc(41.25% - 6px);
    }

    .forest-plan-position {
        position: absolute;
        left: 9px;
        height: 21px;
        top: 21px;
        width: 47.5%;

        .mapIsExpanded & {
            left: 8px;
        }
    }

    .arrows {
        margin-top: 9px;
        border-top: 1px solid $green;
        height: 100%;
    }

    .arrow-box-left {
        position: relative;
        display: inline-block;
        height: inherit;
        border-left: 1px solid $green;

        &.registry {
            width: 45px;
        }

        &.forest-plan {
            width: 28px;

            .mapIsExpanded & {
                width: 25px;
            }
        }

        &:after {
            top: 100%;
            border: solid transparent;
            content: "";
            position: absolute;
            border-top-color: $green;
            border-width: 5px;
            left: -5px;
        }
    }

    .arrow-text-wrapper {
        text-align: center;
        position: absolute;
        width: 100%;
        display: inline-block;
        vertical-align: top;
        color: $green;
    }

    .arrow-text {
        background: white;
        padding: 0 4px;
        display: inline-block;
    }

    .arrow-box-right {
        position: relative;
        display: inline-block;
        height: inherit;
        border-right: 1px solid $green;
        right: 0;
        position: absolute;

        &.registry {
            width: 45px;
        }

        &.forest-plan {
            width: 28px;

            .mapIsExpanded & {
                width: 25px;
            }
        }

        &:after {
            top: 100%;
            border: solid transparent;
            content: "";
            position: absolute;
            border-top-color: $green;
            border-width: 5px;
            left: calc(100% - 5px);
        }
    }
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.lowercase {
    text-transform: lowercase;
}

.dropdown-toggle {
    padding-right: 0 !important;
}


.multiselection-drop-down {

    .remove-triger-buttons {
        display: flex;
        float: right;
    }
}

.custom-drop-down-arow {
    &:hover {
        cursor: pointer;
    }
}

.remove-triger-buttons {
    width: 36px;
    height: 32px;
    margin-top: 0;
    margin-right: 0;
    float: right;

    .remove-button {
        height: inherit;
        width: 18px;
        float: left;

        .glyphicon.glyphicon-remove {
            top: 3px;
        }
    }

    .active-advanced-filter-modal {
        height: inherit;
        width: 18px;
        float: right;
    }
}

.ag-row {

    .checkbox-cell,
    .ag-cell[col-id="selection"],
    .common-grid-checkbox,
    .ag-cell[col-id="$selected"] {
        text-overflow: unset; //this will ovveride the text-overflow: ellipsis setting from the ag-cell class put by ag-grid as default for checkbox-cell ( default selection cheboxes )
    }

    .ag-cell {
        .date-input-for-grid {
            background: none !important;
            border: 0 !important;
        }
    }
}

//all modals common style
.modal-trimble-header {
    background: linear-gradient(90deg, $trimble_gradient_blue_start 0%, $trimble_gradient_blue_end 100%);
    height: 45px;
    color: $trimble_white;
    vertical-align: middle;
}

.modal-trimble-close {
    background-image: url(../content/images/svg/Modal_Close.svg);
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
}

.modal-trimble-close:hover {
    cursor: pointer;
    background-color: $trimble_dk_blue;
}

.modal-trimble-title {
    line-height: 45px;
    vertical-align: middle;
    margin-left: 15px;
    text-transform: uppercase;
    font-family: $font-primary;
    font-size: 14px;
    user-select: none;
}

.modal-content {
    border-radius: 0px !important;
    border: none !important;
}

.modal-trimble-button-container {
    border-top: 0.75px solid $half_trimble_brand_grey;
    padding: 15px;
}

.modal-trimble-content {
    padding: 15px;
}

.modal-trimble-subtitle {
    padding-left: 15px;
    margin-top: 10px;
}

.modal-trimble-multiple-button-spacing {
    margin-left: 15px;
}

.editing-modal-trimble-multiple-button-spacing {
    margin-left: 3px;
}

.modal-trimble-information-header {
    height: 30px;
    padding-top: 6px;
    background-color: $trimble_grey_light;
}

.modal-trimble-information-header-title {
    margin-left: 15px;
    height: 17px;
    text-align: left;
    font-style: italic;
    font-size: 12px;
    letter-spacing: 0px;
    color: $trimble_grey;
    opacity: 1;
}

.login-modal-content {
    margin: 15px;
}

.modal-dialog {
    margin-top: 75px;
}

.modal-backdrop {
    background-color: $trimble_grey;
}

.modal-backdrop.in {
    opacity: 0.2;
}

//buttons used with small space and large text, where we elliminate the default left and right padding
.full-width-button {
    padding-left: 0px;
    padding-right: 0px;
}

.text-transform-none {
    .uppercase {
        text-transform: none !important;
    }
}

//background correction for transparent elements
.white-bg {
    background-color: $trimble_white;
}

.white-bg-hover {
    &:hover {
        background-color: $trimble_white;
    }
}

/*link colors are black by default, unless overridden*/
a {
    color: $trimble_black;

    &:hover {
        color: $trimble_black;
        cursor: pointer;
    }
}

.ag-set-filter-list {
    label {
        padding-left: 0;
    }

    .ag-filter-checkbox {
        margin-right: 5px;
        display: inline-block;
    }
}

.ag-filter-header-container {
    label {
        padding-left: 0;
    }

    .ag-filter-checkbox {
        margin-right: 0;
        display: inline-block;
    }
}

input[type=date]::-webkit-datetime-edit-day-field:focus,
input[type=date]::-webkit-datetime-edit-month-field:focus,
input[type=date]::-webkit-datetime-edit-year-field:focus {
    background-color: $trimble_blue_light;
    color: $trimble_white;
    outline: none;
}

.flex-header {
    display: flex;

    .pull-right {
        @include flex(row, flex-end, center);
        word-break: break-word;
        width: 40%;
    }

    .pull-left {
        @include flex(row, flex-start, center);
        word-break: break-word;
        width: 60%;
    }
}

.sp-grid {


    .ag-header-select-all-centered {
        text-align: center;

        .ag-header-select-all {
            float: none;
        }
    }
}

.ag-filter {
    padding: 0;
    margin: 0.5em;
    border: 1px solid $darkgray;
    border-radius: 0.5em;
    background-color: $white;

    .ag-filter-body {
        padding: 4px;
    }

    input[type='text'].ag-filter-filter {
        border-radius: 5px;
        border: 1px solid $darkgray;
        width: 100%;
        outline: 0;
        padding: 0 0.5em;
        height: 21px;
    }
}

.ag-icon-checkbox-unchecked {
    content: '';
    width: 18px !important;
    height: 18px !important;
    border-radius: 2px;
    background-color: $trimble_white !important;
    background-image: url(../../content/images/svg/Checkbox_default_enabled_unselected_18x18px.svg) !important;
    background-size: 18px !important;
}

.ag-icon-checkbox-checked {
    width: 18px !important;
    height: 18px !important;
    background-image: url(../../content/images/svg/Checkbox_default_enabled_selected_18x18px.svg) !important;
    background-size: 18px !important;
}

.ag-icon-checkbox-indeterminate {
    width: 18px !important;
    height: 18px !important;
    background-image: url(../../content/images/svg/Checkbox_default_enabled_indeterminate_18x18px.svg) !important;
    background-size: 18px !important;
}

.ag-icon-checkbox-checked-disabled {
    width: 18px !important;
    height: 18px !important;
    background-image: url(../../content/images/svg/Checkbox_default_disabled_selected_18x18px.svg) !important;
    background-size: 18px !important;
}

.ag-icon-checkbox-unchecked-disabled {
    width: 18px !important;
    height: 18px !important;
    background-image: url(../../content/images/svg/Checkbox_default_disabled_unselected_18x18px.svg) !important;
    background-size: 18px !important;
}

.ag-icon-checkbox-indeterminate-disabled {
    width: 18px !important;
    height: 18px !important;
    background-image: url(../../content/images/svg/Checkbox_default_disabled_indeterminate_18x18px.svg) !important;
    background-size: 18px !important;
}

.ag-theme-fresh .ag-header-cell-label .ag-header-icon {
    margin-top: 0px !important;
}

.ag-icon-menu {
    height: 15px !important;
}

*[y-tunnus] {
    display: flex;
    height: 100%;
}

main {
    padding-bottom: 4px;
}

.sticky-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #F3F3F7;
    text-align: center;
    padding: 15px 30px;
    z-index: 1000;
}


select {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bs-container.dropdown.bootstrap-select {
    .dropdown-menu.inner {
        li {
            font-size: 12px;
        }

        .dropdown-item {
            padding-left: 5px !important;
        }

        li.selected.active {
            .dropdown-item {
                background-color: $white;
                color: $black;
            }
        }
    }

    .glyphicon.glyphicon-search {
        position: absolute;
        pointer-events: none;
        padding-top: 17px;
        padding-left: 10px;
    }

    .glyphicon-remove {
        position: absolute;
        left: 85%;
        top: 18px;
    }

    .bs-searchbox {
        input {
            padding-left: 33px;
            padding-top: 7px;
        }
    }
}

.margin-top-6 {
    margin-top: 6px;
}

.normal-weight-font {
    font-weight: normal;
    text-transform: initial;
    font-size: 13px;
    font-family: 'Myriad Pro';
}

.capitalize {
    text-transform: capitalize;
}

.icon-printer {
    height: 22px;
    width: 22px;

    &.black {
        background-image: url(../../content/images/svg/print_black_24dp.svg);
    }

    &.blue {
        background-color: #009AD9;
        background-image: url(../../content/images/svg/print_white_24dp.svg);
        background-size: 19px 19px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 19px;
        width: 19px;
    }
}

.icone-invoice {
    background-image: url(../../content/images/svg/invoice.svg);
}

.icon-email {
    background-image: url(../../content/images/svg/email.svg);
}