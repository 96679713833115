﻿@import '../../../shared/sass/_variables.scss';

.cubicAdmin {
    .cubicAdmin-panel {
        .cubicAdmin-rounded-corners {
            border: 1px solid $darkgray;
            border-radius: 0.5em;
            overflow: hidden;

            .cubic-row {
                overflow: auto;
            }

            .cubic-column {
                float: left;
                width: 50%;
            }

            .panel-header {
                min-height: 25px;
                padding: 3px 5px;
                background-color: $gray;
                text-transform: uppercase;
                font-family: "Myriad Pro Semibold";
                border-radius: 0.5em 0.5em 0 0;

                .cubic-column {
                    padding-left: 7px;
                }
            }

            .panel-body {
                padding: 0px;

                .form-group {
                    padding: 10px;
                }

                .interface-keys {
                    background-color: $lightgray;
                    padding: 5px 0px;

                    .form-group {
                        padding: 5px 10px;
                    }                    
                }

                .user-roles {
                    padding: 10px;

                    .form-group {
                        padding: 0px;
                    }

                    .extra-vertical-padding {
                        padding: 7px 0px;
                    }
                }
            }
        }

        .cubicAdmin-bottom-buttons {
            button {
                min-width: 200px;
                padding: 15px 25px;
                margin: 10px 0px;
            }

            .right-margin {
                margin-right: 10px;
            }
        }
    }

    .uppercase {
        text-transform: uppercase;
    }

    .border-right {
        border-right: 1px solid $darkgray;
    }

    .border-bottom {
        border-bottom: 1px solid $darkgray;
    }

    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
        top: 0;
    }

    [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
        top: 0px;
    }

    .width-half {
        width: 50%;
    }

    .no-padding {
        padding: 0px;
    }    

    .simpleDropDown:disabled {
        background-color: $disabled-button-background-color;
    }

    [type="checkbox"]:disabled + label {
        color: initial;
    }
}
