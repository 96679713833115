﻿@import '../../../../shared/sass/_variables.scss';

$dropdown-search-button-width: 13%;

.forest-payments {
    &.print-preview {

        .payments-table,
        .payments-tablefoot-actionbar,
        .settings-row-top {
            width: 842px;
            margin: 0 auto;

            .ag-body-viewport {
                overflow-x: hidden;
            }
        }
    }

    .payments-table {
        margin: 0.5em 0 0;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;
        height: 50vh;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .ag-menu {
            top: 49px !important;
        }
    }

    .payment-status-locked {
        float: left;
        /*    background-color: #363545;*/
        background-image: url(../../../../content/images/svg/lock_grey.svg);
        /*    color: #FFFFFF;*/
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .payment-status-open {
        float: left;
        /*background-color: #009AD9;*/
        background-image: url(../../../../content/images/svg/lock_open.svg);
        /*color: #FFFFFF;*/
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .payment-status-cancelled {
        float: left;
        /*background-color: #C81922;*/
        background-image: url(../../../../content/images/svg/cancel.svg);
        /*color: #FFFFFF;*/
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }

    .payment-status-paid {
        float: left;
        /*background-color: #3AAE2C;*/
        background-image: url(../../../../content/images/svg/event_available.svg);
        /* color: #FFFFFF;*/
        background-size: 15px 15px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 15px;
        width: 15px;
    }
    //Ag-grid overrides
    .payments-table.sp-grid {
        .check-icon {
            content: url(../../content/images/svg/check.svg);
            vertical-align: top;
            margin-left: 5px;
            margin-top: -5px;
        }

        .error-icon {
            margin-left: 5px;
        }

        .cfbDate {
            display: contents;
        }
    }

    .align-right {
        text-align: right !important;
    }

    .align-left {
        text-align: left !important;
    }

    .header-upper-part {
        .ag-header-group-cell-label {
            padding-left: 5px !important;

            .ag-header-group-text {
                float: left;
                text-transform: uppercase;
            }
        }
    }

    .pad-right-0 {
        padding-right: 0px;
    }

    .payments-table {
        .checkbox-cell {
            // checkbox is rendered as an image
            img {
                cursor: pointer;
            }
        }

        .ag-header {
            // for some reason the whole header is jumping up after select-all-click
            position: initial;
        }
        //Hide checkbox-cells in group-rows, couldn't find a way to do this easily programmatically
        .ag-row-group .checkbox-cell {
            display: none;
        }

        .ag-row .ag-selection-checkbox {
            position: static;
            margin-left: 3px;
        }

        .unselect-control {
            padding-left: 6px;
            padding-top: 3px;

            [type="checkbox"]:not(:checked) + label,
            [type="checkbox"]:checked + label {
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                top: -10px;
            }
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-selected {
                    color: inherit;
                    background-color: inherit !important;

                    .ag-cell {
                        color: white;
                        background-color: black;
                    }

                    [type="date"] {
                        color: white;
                    }
                }

                .ag-row-hover {
                    [type="date"] {
                        color: white;
                    }
                }
            }
        }

        .versioning-information {
            background-size: 6px;
            background-image: url(../../../../content/images/svg/CriticalNotificationSymbol_Black.svg);
            background-repeat: no-repeat;
            margin-top: 4px;
            margin-left: 7px;
            height: 22px;
        }

        .versioning-information-col {
            background-size: 6px;
            background-image: url(../../../../content/images/svg/CriticalNotificationSymbol_Black.svg);
            background-repeat: no-repeat;
            margin-top: 4px;
            margin-left: 4px;
            height: 22px;
        }

        .ag-body .ag-row.ag-row-hover .ag-cell .versioning-information-col {
            background-size: 6px;
            background-repeat: no-repeat;
            margin-top: 4px;
            margin-left: 4px;
            height: 22px;
            background-image: url(../../../../content/images/svg/CriticalNotificationSymbol_White.svg);
        }

        .icon-width {
            background-size: 15px 15px;
            height: 15px;
            width: 15px;
        }

        .status-padding {
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 83%;
            text-align: left;
        }

        .icon-calculator-size {
            background-size: 25px;
            height: 25px;
            width: 25px;
            margin-top: -3px;
        }

        .icon-calculator-header-size {
            background-size: 25px;
            height: 25px;
            width: 25px;
            margin-top: -1px;
        }

        .center-check-box {
            display: flex;
            justify-content: center;
        }
    }

    .list-view-settings-row {
        .advanced-filtering-dropdown .dropdown-content {
            height: 462px;


            .dropdown-table .dropdown-date-input {
                width: 161px;
            }
        }
    }


    .silva-header {
        @extend %silva-header;
        background: initial;
    }


    .filter-type {
        .radio-margin {
            margin-left: 3px;
            margin-right: 15px;
        }
    }

    .payments-tablefoot-actionbar {
        border: 1px solid $darkgray;
        border-top: 0;
        border-radius: 0.5em;
        background-color: $lightgray;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;

        button {
            margin-right: 0.5em;

            &.no-margin {
                margin-right: 0;
            }
        }

        .button-option {
            button {
                margin-right: 0px;

                &.no-margin {
                    margin-right: 0;
                }
            }

            .dropdown-content {
                bottom: 5.5em;
                border-top: 1px solid #d1d1d1;
            }
        }
    }

    .ag-theme-fresh {
        .ag-header-select-all {
            //center horizontally the select all button
            padding-left: 5px;
        }
    }

    .payment-border-radius {
        border-radius: 4px;
    }

    .margin-right-5 {
        margin-right: 5px;
    }

    .auto-renew-icon {
        background-color: $trimble_blue_light;
        margin-right: 5px;
        margin-top: -2px;
    }

    .black {
        background-color: $trimble_black !important;
    }

    .auto-renew-icon:hover:not(.black) {
        background-color: #005F9E;
    }

    .notification-icon {
        .ag-header-cell-text{
            padding-right: 24px;
        }
    }
}

.forest-payment-custom-info-dialog {
    z-index: 2000 !important;

    .formatted-message-container {
        margin-left: 20px;
    }

    .text-bold {
        font-size: initial;
    }

    .transform-none {
        text-transform: none !important;
        padding: 15px 15px 0px 15px;
    }
}

.forest-payment-custom-info-dialog-btn-class {
    margin: 0px !important;
    margin-top: 15px !important;

    .margin-bottom-15 {
        margin-bottom: 15px !important;
    }
}
.dialog-btn-class-center{
    button{
        margin-bottom: 15px !important;
    }
}