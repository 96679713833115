﻿@import '../../../shared/sass/_variables.scss';

.my-dialog {
    width: 530px !important;
    left: calc(50% - 265px);
    .modal-dialog {
        .modal-content {
            min-width: 500px !important;
            width: 530px !important;
        }
    }
}

.renumber-forest-asset-stands-modal-window {
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .execute-button {
        margin-left: 5px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .grid-container {
        margin: 1em;
        margin-top: 1.05em;
        margin-bottom: 0em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-radius: 0.5em;
            border-bottom-left-radius: 0em;
            border-bottom-right-radius: 0em;
            border-bottom: 0px;

            #centerRow {
                border-radius: 0.5em;
                border-bottom-left-radius: 0em;
                border-bottom-right-radius: 0em;
            }

            .ag-cell > div > input {
                width: 100%;
                height: 100%;
                text-align: left;
                padding-right: 0.5em;
                background-color: inherit;
                border: none;
                color: black;
            }

            .ag-row:hover .ag-cell {
                color: black !important;
                background-color: rgba(240, 240, 240, 1.0) !important;
            }

            .ag-cell-focus, .ag-cell-no-focus {
                border: none !important;
                border-right: 1px solid lightgray !important;
            }

            .ag-cell:focus {
                border: none !important;
                outline: none;
                border-right: 1px solid lightgray !important;
            }

            .ag-header-group-cell-label {
                text-transform: uppercase;
                margin-left: 4px;
                padding-left: 0px;
            }

            .master-cell-header, .ag-header-group-cell, .ag-row, .ag-body-container {
                width: 100% !important;
                border-right: 0;
            }
        }

        .forest-plans-grid {
            height: 275px;
            width: 500px;
        }

        .ag-scrolls {
            .ag-header-row {
                position: static;

                .ag-header-group-cell-with-group {
                    position: relative;
                }
            }
        }
    }

    .form-group {
        padding: 0;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .sp-grid {

        .grid-check-box {
            top: -9px;
        }

        .ag-header-cell {
            text-align: center;
            border-right: none;
        }

        .main-header {
            text-transform: uppercase;
            width: 270px !important;
        }

        .ag-row {
            .ag-row-disabled {
                background-color: $lightgray;
            }
        }

        .ag-header-group-cell-label, .ag-header-cell-label {
            text-align: left;
        }
    }

    .ag-header-container {
        border-right: none;
        border-bottom: none;
    }

    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
        left: 3px;
    }

    .footer-bar {
        width: calc(100% - 2em);
        height: 20px;
        background-color: lightgray;
        border: 1px solid black;
        margin-left: 1em;
        margin-right: 5em;
        margin-bottom: -1px;
    }

    .header-bar {
        width: calc(100%);
        margin-left: 0px;
        height: 75px;
        vertical-align: middle;
        padding: 0px;
        align-content: center;
        padding: 1.0em;
        background-color: $gray;
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
    }

    .grid-footer {
        .button-copy-selected {
            width: 100%;
            padding: 6px;
        }

        border-top: 1px solid gray;
        margin-left: 1em;
        margin-right: 1em;
        margin-bottom: 1em;
    }

    .bottom-button {
        width: 240px;
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .footer-summary {
        margin-top: 1px;
        padding-top: 0px;
    }

    .left-summary {
        margin-left: 80px;
    }

    .right-summary {
        margin-right: 160px;
    }

    .bottom-button-left {
        margin-left: 0.5em;
    }

    .bottom-button-right {
        margin-right: 0.5em;
    }
}
