﻿.stumpage-prices {			
		
		.print-only {
			position:absolute; 
			padding-top :10000px;
		}

		.settings-item {
			float: right;
			margin-top: 0px;
		}

		.selected-definition-label{
			line-height: 12px;
			margin-top:0.2em;
		}
		.radio-group { 
		    margin-top: 0.5em; 
		}

		.radio-col {
		    margin-top: 2px;
		}


		.header-div {
			margin-bottom: 0.5em; 
		}

		.navigation-grid {
			height: 371px !important;
		}
	
		.sp-grid .ag-floating-bottom {
			box-shadow: none;
		}

		.right-align {
			text-align: right;
			font-size: 12px;
			border-right: 1px solid #d1d1d1 !important;		
		}
				
		.left-align {
			text-align: left;
			font-size: 12px;
		}

		.white-cell {
			background-color: white;
			text-align: left;
			font-size: 12px;
		}
				
		.last-ui-header {
			border-right: none;
			font-size: 12px;
			text-transform: none !important;
		}

		.header-generic {
			font-size: 12px;
			text-transform: none !important;
		}
		
        .header-empty {
			border: none !important;
			background-color: white;  
			text-transform: none !important;
			color: green; 				
		}
	
		.content {
			[class^="col"] {
				padding-left: 0px;
				padding-right: 0px;
			}

			.same-row {
				padding-left: 10px;
				padding-right: 10px;
			}

			input[type=radio] {
				margin-top: 0px;
				margin-bottom: 0px;
				height: initial;
				box-shadow: none;
			}
		}

		.panel-header {
			background-color: #d1d1d1;
			text-transform: uppercase;
			font-family: "Myriad Pro Semibold";
			border-radius: 0.5em 0.5em 0 0;
		}

		.border {
			border: 1px solid rgb(97,97,97);
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

        .white-on-black-header {
			background-color: black !important;
			color: white;
			text-transform: uppercase;
			font-family: "Myriad Pro Semibold";	
            padding-left: 20px;
			margin-bottom: 0px;

			 .ag-header-group-cell-label {
                text-align: left;
                padding-top: 2px;
			}
		}
		
		.ag-header {
		    border-bottom: none !important;
		}

		.top-header {		   
			.ag-header-group-cell-label {			
				text-align: left;
			}
		}

		.right-border {
			border-right: 1px solid #d1d1d1 !important;	
			padding-right: 30px;	
		}

		.left-margin {
		   padding-left :30px !important;
		   border:none !important;
		}

		.fine-border {
			border: 1px solid #d1d1d1;
			float: right;
			margin-right: 30px !important;;
			text-align: right;
		}

       .sp-grid .ag-body-container .ag-row-hover .ag-cell, .sp-grid .ag-body-container .ag-row-selected .ag-cell {
            color: black !important; 
            background-color: white !important; 			
        }

		.sp-grid .ag-body-viewport-wrapper .ag-cell.ag-cell-no-focus.left-margin {
			border-right:none !important;
		}

		.ag-body-viewport{
			background-color: white;
		}

	
		.no-border {
			border: none !important;
		}

		.sp-grid .ag-body-viewport-wrapper .ag-cell.ag-cell-no-focus.no-border {
			border: none !important;
		}

		.margin-left {
			padding-left: 20px !important;

			.ag-header-cell-text,.ag-header-cell-label { 
				padding:0;
			}
		}

		.margin-right {
			padding-right: 20px !important;
			text-align:right;
		}

		.title-print {
			font-size: 25px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 20px;
		}

		.grid-for-print {
			.ag-floating-bottom {
				    display: none !important;
					position: relative;
			}
		}
	}