@import '../../../../shared/sass/_variables.scss';
.modal-lg {
    width: 1050px !important;
}

.select-plots-modal {
    .modal-dialog {
        width: 55.8%;
    }

    .red-background {
        background-color: $lightred !important;
    }

    .modal-body {
        .sp-grid {
            border-radius: 0.5em;
        }

        .common-grid {
            .common-grid-grid {
                height: 310px;

                .ag-body {
                    border-bottom-left-radius: 0.5em;
                    border-bottom-right-radius: 0.5em;
                }

                .ag-floating-bottom {
                    display: none;
                }
            }

            .panel-footer {
                display: none;
            }
        }

        .modal-explanation {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 15px;
            border-top: 1px solid $gray;
            margin-top: 15px;
            margin-right: -14px;
            margin-left: -14px;
            font-style: italic;
            font-size: 12px;
        }
    }

    .tab-nav .nav-container .current{
        color: $black;
    }
}