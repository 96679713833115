﻿@import '../../shared/sass/_variables.scss';

.forestEquipmentHandling {
    .contr-pad {
        padding-left: 38px;
    }

    .favorite-icon-button-position {
        margin-top: -45px;
    }

    .ag-theme-fresh .ag-header-select-all {
        padding-left: 4px;
    }

    .grid-heading {
        color: $sp-panel-font-color;
        font-size: $sp-panel-heading-font-size;
        font-weight: bold;
        padding-left: 5px;
        padding-top: 3px;
        text-transform: uppercase;
        background-color: #d3d3d3;
        height: 25px;
        border-left: 1px solid;
        border-top: 1px solid;
        border-right: 1px solid;
        border-color: $sp-panel-border-color;
        border-top-left-radius: $sp-panel-border-radius;
        border-top-right-radius: $sp-panel-border-radius;
    }

    .grid-footer {
        @extend %silva-box;
        overflow: hidden;
        margin: 0;
        padding: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-top: none;
        background-color: lightgray;
        height: 25px;

        .row-count-label {
            margin-top: 3px;
            margin-left: 5px;
            margin-bottom: 0px;
        }
    }

    .harvesting-companies-grid {
        border: 1px solid;
        border-color: $sp-panel-border-color;
        height: 28.5vh;

        .ag-menu {
            z-index: 2 !important;
        }

        .ag-filter {
            background-color: $white !important;
            @extend %silva-box;

            .ag-filter-filter {
                border-radius: 5px;
                border: 1px solid $darkgray !important;
                width: 110px;
                outline: none;
                margin-left: 0.5em;
                margin-right: 0.5em;
            }

            .ag-filter-select {
                width: 110px;
                margin-left: 0.5em;
                margin-right: 0.5em;
            }
        }
    }

    .navigation-buttons {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        padding-left: 0;
        position: relative;
        // sticky center
        li.navigation-button-description-prev {
            display: inline-block;
            width: calc(50% - 60px);
            float: left;

            span {
                float: right;
            }
        }

        ul.buttons {
            position: absolute;
            left: calc(50% - 50px);
            top: 0px;
            width: 100px;
        }

        li.navigation-button-description-next {
            display: inline-block;
            width: calc(50% - 60px);
            float: right;

            span {
                float: left;
            }
        }
    }

    [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
        padding-left: 20px;
    }

    .bottom-box {
        @extend %silva-box;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        background-color: white;
        height: 465px;
        margin-right: 0;
        margin-bottom: 10vh;
        margin-left: 0px;

        .logis-button {
            padding: 0px;
            padding-top: 1px;
            margin-top: 5px;
            margin-right: 10px;
        }

        .pane-heading {
            color: $sp-panel-font-color;
            font-size: $sp-panel-heading-font-size;
            font-weight: bold;
            padding-left: 5px;
            padding-top: 3px;
            text-transform: uppercase;
            background-color: #d3d3d3;
            height: 25px;
            border-color: $sp-panel-border-color;
        }

        .left-pane {
            width: 50%;
            float: left;
            height: 464px;

            .pane-heading {
                border-right: 1px solid;
                border-bottom: 1px solid;
            }

            .harvesting-company-machinery-grid {
                border: 1px solid;
                border-left: 0;
                border-top: 0;
                border-radius: 0;                
                height: 413px;

                .grey-cell-style {
                    padding-left: 3px;
                }
            }

            .bottom-grid-footer {
                @extend %silva-box;
                overflow: hidden;
                margin: 0;
                padding: 0;
                border-radius: 0;
                border: 0;
                border-right: 1px solid;
                border-color: $sp-panel-border-color;
                background-color: lightgray;
                height: 25px;

                .row-count-label {
                    margin-top: 3px;
                    margin-left: 5px;
                    margin-bottom: 0px;
                }
            }
        }

        .right-pane {
            width: 50%;
            float: right;
            height: 464px;

            [type="checkbox"]:not(:checked) + label:before,
            [type="checkbox"]:checked + label:before {
                top: 0;
            }

            [type="checkbox"]:not(:checked) + label:after,
            [type="checkbox"]:checked + label:after {
                top: 0px;
            }

            .right-pane-info-area {
                height: 438px;
                overflow-y: auto;

                #silvaLogisAccountInfo {
                    margin-left: 5px;
                }
            }

            .pane-heading {
                border-bottom: 1px solid;
            }

            hr {
                clear: both;
                border-color: $gray;
            }

            .hr-div {
                clear: both;
                padding-top: 1px;
            }

            .vertical-line {
                height: 34px;
                border-left: 1px solid $gray;
                padding-left: 10px;
            }

            .setting-title {
                padding-left: 10px;
                padding-top: 1px;
            }

            .resource-margin-top {
                margin-top: 8px;
            }

            .resource-type {
                vertical-align: middle;
            }

            .margin-left-radio-button {
                margin-left: 5px;
            }

            .margin-top-radio-button {
                margin-top: -2px;
            }

            .dropdown-selection {
                display: inline-block;
                font-size: 12px;
                height: 24px;
                padding-top: 3px;
                padding-bottom: 3px;
                padding-left: 5px;
            }

            .padding-right {
                padding-right: 10px;
            }

            .text-editor {
                border: 1px solid;
                border-radius: 4px;
                border-color: #616161;
                height: 24px;
                padding-left: 9px;
                padding-top: 4px;
            }

            .name-text-editor {
                width: 100%;
            }

            .slash-label {
                padding-left: 30px;
                font-size: 14px;
            }

            .property-button {
                height: 24px;
                width: 100%;
                font-size: 12px;
                padding: 3px 0px 3px 0px;
            }

            .add-peripheral-item-button {
                height: 24px;
                width: 24px;
                padding: 0;
                font-size: 22px;
                font-weight: bold;
                border: 1px solid;
                border-radius: 4px;
                border-color: #616161;
                background-color: white;
            }

            .peripheral-dropdown-selection {
                width: calc(100% - 25px);
            }

            .remove-peripheral-item {
                display: inline-block;
                float: right;

                .remove-peripheral-item-button {
                    height: 24px;
                    width: 24px;
                    padding: 0;
                    font-size: 22px;
                    font-weight: bold;
                    border: 1px solid;
                    border-radius: 4px;
                    border-color: #616161;
                    background-color: white;
                    vertical-align: middle;
                }
            }

            .additional-info-text {
                resize: none;
                border: 1px solid;
                border-radius: 4px;
                border-color: #616161;
                margin-top: 0;
                margin-bottom: 5px;
                width: 100%;
                overflow-x: hidden;
                overflow-y: auto;
            }

            .upper-option-row {
                div:first-of-type {
                    margin-right: -20px;
                }

                div:nth-of-type(2) {
                    margin-right: 5px;
                }

                div:nth-of-type(3) {
                    margin-right: 15px;
                }

                div:nth-of-type(4) {
                    padding-left: 10px;
                }
            }
        }

        .bottom-box-footer {
            @extend %silva-box;
            border: 1px solid;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 0;
            background-color: lightgray;
            margin: 0 -1px 0 -1px;
            height: 50px;
            padding: 7px;
            clear: both;
        }
    }
}
