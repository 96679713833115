@import "../../shared/sass/_variables.scss";

[sp-alerts],
[sp-relogin],
[unsaved-alerts] {
    .circliful {
        height: 200px;
        width: 400px;
        margin-top: 40px;
    }

    .alert-modal.modal-backdrop {
        z-index: 1051;
    }

    .sp-alert {
        background: $white;
        box-shadow: 0 0 5px $trimble_black;
        left: 25%;
        right: 25%;
        margin: 0 auto;
        position: fixed;
        top: 30%;
        $alert-width: 400px;
        width: $alert-width;
        min-height: 170px;

        &.fontForIE .content .alert-message-2 {
            font-weight: bold;
        }

        &.fontForIE .content .title-container {
            font-weight: bold;
        }

        &.fontForSafari .content .alert-message-2 {
            font-size: 1.2em;
        }

        &.fontForSafari .content .title-container {
            font-size: 1.2em;
        }

        &.empty {
            z-index: 2051;
        }

        &.success {
            z-index: 2052;
        }

        &.info {
            z-index: 2053;
        }

        &.warning {
            z-index: 2054;
        }

        &.error {
            z-index: 2055;
        }

        &.login {
            z-index: 2056;
            width: 300px;
        }

        &.tempFileAlert {
            z-index: 2057;
        }

        &.loading {
            z-index: 2058;
        }

        &.progress {
            z-index: 2058;
            height: 390px !important;
        }

        &.fud-xml-errors {
            width: auto;
            max-height: 570px;
            overflow: auto;
        }

        &.fud-erros {
            max-height: 570px;
            overflow: auto;
            width: 345px;
        }

        &.fud-accepted {
            width: 305px;
        }

        &.wood-selling-plans-modal2 {
            width: 480px;
        }

        .content {
            font-family: $font-semibold;
            height: 100%;
            position: relative;
            background-repeat: no-repeat;
            background-attachment: local;
            background-position: right 22px;
            min-height: 200px;

            &.unsaved-content {
                min-height: 180px;
            }

            &.success {
                background-image: url("../../content/images/svg/AlertIcon_Success.svg");
            }

            &.info {
                background-image: url("../../content/images/svg/AlertIcon_Question.svg");
            }

            &.warning {
                background-image: url("../../content/images/svg/AlertIcon_Notification.svg");
            }

            &.error {
                background-image: url("../../content/images/svg/AlertIcon_Error.svg");
            }

            &.tempFileAlert {
                background-image: url("../../content/images/svg/TempFile_AlertIcon.svg");
                background-position: right 15px top 60px;
            }

            &.loading {
                background-image: url("../../content/images/svg/AlertIcon_Downloading.svg");
                background-position: 259px 60px;
            }
            /* overriders for reloginDirective */
            .col-sm-12 {
                padding: 0;
                width: auto;
                float: none;

                .form-group {
                    margin-top: 0.25em;
                }

                .glyphicon-play {
                    color: $trimble_tf_product_green;
                }
                /* lock icon */
                img {
                    margin-top: 1em;
                }
                /* header */
                .header-text {
                    margin-top: 0.5em;
                }
                /* forgot my password link */
                a, input {
                    color: $black;
                    font-family: "Myriad Pro";
                }
                /* login button */
                button {
                    margin-bottom: 0.5em;
                }
            }

            .title-container {
                border-bottom: solid 1px $gray;
                font-size: 12px;
                text-transform: uppercase;
                padding-bottom: 1em;

                .close-button:hover {
                    color: $blue;
                    cursor: pointer;
                }

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }

            .alert-time-left {
                position: absolute;
                right: 15px;
                color: $darkgray;
                font-size: 1.2em;
                margin-top: 20px;
            }

            .alert-message {
                color: $trimble_grey;
                text-align: left;
                margin-left: 15px;
                margin-right: 35px;
                margin-top: 20px;
                white-space: pre-line;
                margin-bottom: 20px;
                min-height: 48px;
                font-size: 14px;
            }

            .alert-message-content {
                max-height: 350px;
                overflow-x: auto;
            }

            .unsaved-alert-message {
                color: $trimble_grey;
                text-align: left;
                white-space: pre-line;
                margin-bottom: 20px;
                margin-top: 5px;
                font-size: 14px;
            }

            .unsaved-content {
                padding-bottom: 0px;
            }

            .uppercase {
                text-transform: uppercase;
            }

            .alert-message-progress {
                color: $trimble_blue_mid;
                text-align: center;
                margin-left: 15px;
                margin-right: 15px;
                margin-top: 20px;
                white-space: pre-line;
                margin-bottom: 20px;
                font-size: 14px;
            }

            .alert-message-2 {
                color: $trimble_grey;
                text-align: left;
                margin-left: 15px;
                margin-right: 15px;
                white-space: pre-line;
                margin-top: 1em;
            }

            .alert-message-2-progress {
                color: $trimble_grey;
                text-align: center;
                margin-left: 15px;
                margin-right: 15px;
                white-space: pre-line;
                margin-top: 1em;
            }

            .alert-messages {
                color: $trimble_grey;
                text-align: left;
                margin-left: 15px;
                margin-right: 15px;
                white-space: pre-line;
                margin-top: 1em;
            }

            .radio-group-title {
                margin-left: 26.5px;
            }

            .button-group {
                text-align: center;
                margin-top: 15px;

                button {
                    display: inline-block;
                    margin: 0.25em;
                    min-width: 80px;
                }

                &.stacked {
                    margin: 1.5em 1em 1em 1em;
                }

                &.stacked button {
                    margin: 0.5em 0 0 0;
                    width: 100%;
                    display: block;
                }

                &.unsaved {
                    margin: 0px;
                }

                &.unsaved button {
                    margin: 0px 0px 15px 0px;
                    width: 100%;
                    display: block;
                }

                &.unsaved:last-of-type {
                    margin-bottom: 0px;
                }

                &.do-not-center-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    button {
                        margin: 0;
                    }
                }
            }

            .alert-chart {
                transform: translate(0px, -30px);
                max-height: 190px;
                text-align: center;
            }

            .circliful {
                max-width: 250px;
                max-height: 250px;
                position: relative;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 10px;
            }

            .alert-icon {
                background-repeat: no-repeat;
                margin: 25px auto;
                height: 61px;
                width: 61px;
            }

            .alert-input {
                .alert-form {
                    .form-group .radio:not(:first-child) {
                        margin-top: -7px;
                    }
                }

                textarea {
                    margin-left: 2px;
                    resize: none;
                    font-family: 'Myriad Pro';
                }
            }

            .text-transform-none {
                text-transform: none;
            }
        }

        .text-area {
            width: 100%;
        }

        .content-title {
            text-align: left;
            margin-bottom: 10px;
        }

        .center-text {
            text-align: left;
        }

        .color-black {
            color: black;
        }

        .normal-font {
            font-weight: normal;
            font-family: $font-primary;
        }

        .color-orange {
            color: $orange;
        }

        .margin-bottom-10px {
            margin-bottom: 10px;
        }

        .margin-top-10px {
            margin-top: 10px;
        }

        &.set-row-as-invoiced-error {
            width: 450px;
        }

        &.set-row-as-invoiced-confirmation-modal {
            width: 365px;
        }

        &.timber-trade-validation-error {
            width: 570px;

            .alert-message {
                text-align: left;
            }
        }
    }

    ::-webkit-scrollbar-track {
        background: none;
    }

    ::-webkit-scrollbar {
        width: 15px;
        height: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background: #ccc;
    }

    .overflow-y-auto {
        overflow-y: auto;
    }

    .bottom-15-percent {
        bottom: 15%;
    }

    .bottom-group-maring-14-px {
        margin-bottom: 14px;
    }
}



//TODO: More breakpoints
@media(max-width: $media-mobile) {
    [sp-alerts], [unsaved-alerts] {
        .alerts-container {
            width: 94%;
        }
    }
}
