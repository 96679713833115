.delivery-destination-assortments {
    padding: 25px;

    .link-msg-row {
        padding-bottom: 10px;

        .link-msg-container {
            float: left;
            margin-right: 15px;

            .material-switch-content > label::before {
                top: 0px
            }
        }
    }

    .div-toggle-message {
        width: 210px !important;
    }

    .div-toggle {
        padding-top: 8px;
        width: 50px !important;
    }

    .lbl-marl {
        padding-left: 50px;
    }

    .delivery-destination-assortments-grid {
        border-bottom-right-radius: 0.5em !important;
        border-bottom-left-radius: 0.5em !important;

        .ag-header-group-text {
            text-transform: uppercase;
        }
    }

    .no-margin-pen {
        margin-left: 0px;
        margin-top: 2px;
    }

    .no-margin-title-pen {
        margin-left: 5px;
        margin-right: 0px;
        margin-top: 4px;
    }

    .padding-icon {
        padding-left: 30px;
        color: #333333;
    }

    .padding-no-icon {
        color: #333333;
    }
}
