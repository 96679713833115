﻿@import '../../../../shared/sass/_variables.scss';

.upsert-manual-payment-or-correction {
    .modal-dialog {
        top: 22%;
        width: 675px;
        height: 494px;
    }

    .margin-top-5 {
        margin-top: 5px;
    }

    .margin-top-10 {
        margin-top: 10px;
    }

    .margin-top-15 {
        margin-top: 15px;
    }

    .margin-top-20 {
        margin-top: 20px;
    }

    .margin-right-10 {
        margin-right: 10px;
    }

    .margin-left-0 {
        margin-left: 0px;
    }

    .target-payment {
        height: 27px;

        .ng-invalid-required .custom-drop-down button:not(:disabled) {
            background-color: #FFD8D8 !important;
        }

        textarea {
            resize: none;
            max-height: 200px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    .notes > textarea {
        resize: none;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .dropDown30vw {
        width: 34.2em;

        .dropdown-menu {
            width: 34em;

            .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12,
            .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5,
            .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9,
            .col-md-1, .col-md-10, .col-md-11, .col-md-12,
            .col-md-2, .col-md-3, .col-md-4, .col-md-5,
            .col-md-6, .col-md-7, .col-md-8, .col-md-9,
            .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12,
            .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5,
            .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9,
            .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12,
            .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5,
            .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
                min-height: 18px;
            }

            .table-row:not(.table-header):hover {
                .col-xs-3:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-2:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-1:not(.grey-background) {
                    background-color: dodgerblue !important;
                }
            }
        }

        .btn {
            height: 27px;
            padding-top: 3px;
        }

        button[disabled], button.btn[disabled] {
            border: 1px solid #616161;
        }

        background-color: white;
    }

    .grey-background {
        background-color: #d1d1d1;
    }

    .upper-font {
        text-transform: uppercase;
    }

    .left-cell-padding {
        padding-left: 3px;
        padding-right: 0px;
    }

    .fine-border {
        border: 1px solid #616161;
    }

    .height-s {
        height: 27px !important;
    }

    .font-size-s {
        font-size: 12px;
    }

    .custom-drop-down .btn {
        text-align: left;
    }

    .editable-date-format {
        border-radius: 4px;
        font-size: 14px;
        padding-left: 5px;
        width: 28%;
    }

    .check-box-items {
        vertical-align: middle;
        display: inline-block;
        height: 20px;
    }

    .checkbox-padding {
        padding-left: 17px !important;
        bottom: 8px;
    }

    .margin-auto {
        margin: auto;
    }

    .form-control {
        padding: 0.2em;
        height: 27px;
    }

    .height-auto {
        height: auto;
    }

    .float-right {
        float: right !important;
    }

    .float-left {
        float: left !important;
    }
}
