/*
SilvaPro customizations to Bootstrap 3's panel.

Usage: just like Bootstrap-panel (http://getbootstrap.com/components/#panels).
This file adds more contextual classes. Use e.g. "panel-sp" or "panel-sp-grid" instead of "panel-default".

Example, that produces a grid with header and footer.

<div class="panel panel-sp-grid">
    <div class="panel-heading">Grid header</div>

    <div class="panel-body">
        <div class="ag-theme-fresh sp-grid" ag-grid="ctrl.gridOptions"></div>
    </div>

    <div class="panel-footer"><button>Paina tästä</button><div>
</div>


Code used here is based on official Bootstrap 3 sass port (https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_panels.scss)
 */

$sp-panel-border: $grid-border;
$sp-panel-border-radius: 5px;
$sp-panel-font-color: #333;
$sp-panel-border-color: #808080;
$sp-panel-footer-background-color: #e8e8e8;
$sp-panel-heading-padding: 5px;
$sp-panel-footer-padding: .5em;
$sp-panel-heading-background: $gray;
$sp-panel-grid-heading-background: $grid-header-background;
$sp-panel-body-padding: 15px;
$sp-panel-grid-body-padding: 0px;
$sp-panel-heading-font-size: 12px;

// Mixins
@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
   border-top-left-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
   border-bottom-left-radius: $radius;
}

@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border, $body-padding) {
  border-color: $border;
  & > .panel-body {
    padding: $body-padding;
  }

  & > .panel-heading {
    color: $heading-text-color;
    background: $heading-bg-color;
    border-color: $heading-border;

    + .panel-collapse > .panel-body {
      border-top-color: $border;
    }
    .badge {
      color: $heading-bg-color;
      background-color: $heading-text-color;
    }
  }
  & > .panel-footer {
    + .panel-collapse > .panel-body {
      border-bottom-color: $border;
    }
  }
}


// Base class
.panel {
  border: $sp-panel-border;
  border-radius: $sp-panel-border-radius;
}

// Panel contents
.panel-body-grid {
  padding: $sp-panel-grid-body-padding;
}

// Optional heading
.panel-heading {
  border-bottom: $sp-panel-border;
  font-weight: bold;
  font-size: $sp-panel-heading-font-size;
  padding: $sp-panel-heading-padding;
  @include border-top-radius(( $sp-panel-border-radius - 1));
}

// Optional footer (stays gray in every modifier class)
.panel-footer {
  padding: $sp-panel-footer-padding;
  background-color: $sp-panel-footer-background-color;
  border-top: $sp-panel-border;
  @include border-bottom-radius(( $sp-panel-border-radius - 1));
}

// Contextual variations
.panel-sp {
  @include panel-variant($sp-panel-border-color, $sp-panel-font-color, $sp-panel-heading-background, $sp-panel-border-color, $sp-panel-body-padding);
}

.panel-sp-grid {
  @include panel-variant($sp-panel-border-color, $sp-panel-font-color, $sp-panel-grid-heading-background, $sp-panel-border-color, $sp-panel-grid-body-padding);
}
