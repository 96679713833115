e-sign {
    div.e-sign {
        display: inline-block;                   
    }

    button {            
        &.esign-button {            //we need to make these quite specific , otherwise will be overriden by button.btn.disabled construct which also have important  
            &.disabled {
                background-color: $trimble_blue_light !important;            
            }
            &.esign {
                background-color: $trimble_blue_light !important; 

                &:hover:not(.disabled) {
                    background-color: $trimble_blue_mid !important;
    
                    .icon-esign {
                        background-color: $trimble_blue_mid !important;
                    }
                }
            }
            &.esign-orange {
                background-color: $trimble_orange !important;

                &:hover:not(.disabled) {
                    background-color: $trimble_orange_hover !important;
    
                    .icon.icon-esign-orange {
                        background-color: $trimble_orange_hover !important;
                    }            
                } 
            }     
                
            &.esign-green {
                background-color: $trimble_green !important;

                &:hover:not(.disabled) {
                    background-color: $trimble_green_hover !important;
    
                    .icon.icon-esign-green {
                        background-color: $trimble_green_hover !important;
                    }
                }
            }
            &:disabled {
                opacity: 0.65 !important;
            }
        }        
    }

    .icon {
        height: 50px;
        width: 45px;
        background-color: transparent;

        &.icon-esign {
            background-image: url(../../../content/images/svg/eSign_icon.svg);
            background-color: $trimble_blue_light !important;  
        }

        &.icon-esign-orange {
            background-image: url(../../../content/images/svg/eSign_icon_orange.svg);
            background-color: $trimble_orange !important;  
        }

        &.icon-esign-green {
            background-image: url(../../../content/images/svg/eSign_icon_green.svg);
            background-color: $trimble_green !important; 
        }

        &:disabled {
            opacity: 0.65 !important;
        }
    }    
}