.manual-reception-modal {
    .storage-info {
        width: 70%;
    }

    .prod-date {
        padding-left: 42px;
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .form-control {
            width: 129px;
            height: 25px;
            padding: 0;
            margin-right: 5px;
        }
    }

    .modal-dialog {
        width: 632px;
    }

    label,
    span,
    input {
        margin-right: 10px;
    }

    .top-info {
        margin-bottom: 15px;
        margin-left: 5px;

        .row {
            display: flex;
            margin: 5px;
        }

        .bold-txt {
            font-weight: 700
        }

        .storage-place-input {
            width: 25%;
            height: 25px;
        }
    }

    .measurement-reception-container {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .manual-reception-grid {
        margin-top: 5px;
        border-radius: 0.5em;
        border: 1px solid #616161;
        overflow: hidden;

        .form-control {
            height: 100%;
            padding: 0;
        }
    }

    .grid-container {
        margin: 5px !important;
    }

    [type="checkbox"]:not(:checked)+label, [type="checkbox"]:checked+label {        
        padding-left: 0;        
    }
    [type="checkbox"]:not(:checked)+label:before, [type="checkbox"]:checked+label:before, [type="checkbox"]:not(:checked)+label:after, 
        [type="checkbox"]:checked+label:after {
        top: 0;
    }
}