.payment-settings-tab {
    .content {
        height: 700px;

        .panel-header {
            margin-bottom: 10px;
        }
    }

    .pen-icon {
        margin: 0;
        margin-right: 40px;
        background-size: 22px 22px;
        height: 22px;
        width: 22px;
        border: none;
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;

        span,
        label {
            text-transform: uppercase;
            margin-right: 50px;
            font-weight: bold;
            font-size: 15px;
            margin-bottom: 0;
        }

        .group-item {
            margin-right: 5px;
        }
    }
}

@import './investmentSettings/editInvestmentSettings/editInvestmentSettings.scss';
@import './advancePaymentSetting/advancePaymentSetting.scss';
@import './advancePaymentSetting/editAdvancePaymentSettingModal/editAdvancePaymentSetting.scss';
@import './organizationSetting/editOrganizationSetting/editOrganizationSettingModal.scss';