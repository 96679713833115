﻿.delivery-destinations {
    .select-option-label {
        font-size: 12px;
        font-weight: 600;
        height: 28px;
        padding-top: 6px;
        font-family: "Myriad Pro Semibold";
    }

    .harvesting {
        padding: 0px;
        background-color: none !important;
        border: none;
    }

    .ag-cell .btn {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .select-all-wrapper {
        top: 3px;
        left: 4px;
    }

    .harvesting .content-row-wrapper.delivery-location {
        height: 695px !important;

        .ag-tool-panel-wrapper {
            width: 236px;
        }
    }

    .harvesting.grid-column-wrapper,
    .harvesting.map-column-wrapper {
        padding-top: 15px;
    }
}
