.trade-area-assortments {
    .ag-row {
        z-index: 0;

        .ag-cell {
            z-index: 0;
        }
    }

    .timber-assortments-grid {
        .sp-grid {
            height: 492px !important;
        }
    }

    .min-width {
        min-width: 130px;
    }

    .top-margin {
        margin-top: 5px;
        margin-bottom: 0px !important;
    }

    .date-padding {
        width: 47%;
        padding-right: 4px;
    }

    .work-site-view .fine-border {
        border: 1px solid #ccc;
    }

    .left-padding {
        padding-left: 13px;
    }

    .align-right {
        padding-left: 6px !important;
    }

    .text-align-vertically {
        padding-bottom: 5px !important;
    }

    .height-s {
        height: 27px !important;
    }

    .display-flex {
        display: flex;
    }

    .date-padding.date-custom-width {
        width: 161px;
    }

    .input-border {
        border: 1px solid #616161 !important;
    }

    .date-width-100 {
        width: 100%;
    }

    .pull-left-l {
        right: 13px;
    }

    #tradeAreaAssortmentsGrid {

        .checkbox-container {
            width: 100%;
            display: flex;
            justify-content: center;

            input {
                width: 100%;
            }
        }

        .select-all-wrapper {
            left: 2px;
            top: -5px;
        }

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 0;
        }

        .ag-row {
            z-index: 0;

            .ag-cell {
                z-index: 0;
            }
        }

        .ag-row.ag-row-focus {
            z-index: 1;
        }

        .ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-value.ag-cell-focus.cell-focus {
            background-color: white;
            z-index: 1;
        }

        .versioning-information-header {
            background-size: 6px;
            background-image: url(../content/images/svg/CriticalNotificationSymbol_Black.svg);
            background-repeat: no-repeat;
            margin-top: 4px;
            margin-left: 7px;
            height: 22px;
        }

        .versioning-information-col {
            background-size: 6px;
            background-image: url(../content/images/svg/CriticalNotificationSymbol_Orange.svg);
            height: 22px;
            width: 22px;
            margin-left: 17px;
            margin-top: 7px;
            background-repeat: no-repeat;
        }

        .text-format {
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .disabled-pen-icon:hover:not(.black) {
            background-color: #ddd;
            cursor: not-allowed;
        }

        .disabled-pen-icon {
            background-color: #ddd;
            cursor: not-allowed;
        }
    }

    .buttons-group {
        .button-left-margin {
            margin-left: 0.5em;
        }

        .display-inline-flex {
            display: inline-flex;
        }
        /*.edit-prices {
            margin-left: 0.5em;
        }*/
    }

    .panel-header {
        border-radius: 0.5em 0.5em 0 0;
        padding: 4px;
        height: 25px;
        font-family: "Myriad Pro", Verdana;
        font-weight: 700;
        color: $trimble_black;
        background: $grid-header-background;
        border-top: 1px solid $darkgray;
        border-left: 1px solid $darkgray;
        border-right: 1px solid $darkgray;
    }

    .button-icon {
        background-color: $trimble_blue_light;
        background-image: url(/content/images/svg/OutputListIcon_white.svg);
        background-size: 14px 19px;
        border-radius: 0.3em;
        cursor: pointer;
        margin: auto;
        display: block;
        height: 19px;
        width: 20px;
        background-repeat: no-repeat;
        background-position: right 3px center;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .input-element {
        input {
            border: 0;
            color: $trimble_black;
            width: 100%;
        }
    }

    .margin-right {
        margin-right: 10px;
    }

    .gif-card-icon-header {
        padding-left: 3px;
        pointer-events: none;
    }

    .icon-button {
        //Override bootstrap's styles on icon-buttons
        > .btn {

            &:hover,
            &:focus {
                background-color: $trimble_white;

                .icon-settings {
                    background-image: url(../../content/images/svg/settings-cog-green.svg);
                }
            }

            background-color: $trimble_white;
        }

        &.open {
            .icon-printer {
                background-image: url(../../content/images/svg/print-green.svg);
            }
        }
    }

    .icon {
        height: 17px;
        width: 18px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;

        &.icon-printer {
            background-image: url(../../content/images/svg/print.svg);

            &:hover,
            &:focus {
                background-image: url(../../content/images/svg/print-green.svg);
            }
        }

        &.icon-settings {
            width: 25px;
            //height: 16px;
            background-image: url(../../content/images/svg/settings-cog.svg);

            &:hover,
            &:focus {
                background-image: url(../../content/images/svg/settings-cog-green.svg);
            }
        }
    }

    .silva-search {
        height: 30px;
        border: 1px solid $half_trimble_brand_grey;
        border-radius: 4px;
    }

    .top-section {
        height: 45px;
    }

    .settings-item .btn-default {
        height: 30px;
    }

    .right-input {
        float: left;
        margin-top: 9px;

        &.control-label {
            width: 17%;
            float: left;
            margin-right: 9px;
            padding-top: 0;
        }
    }

    .left-side-label {
        float: left;
        width: 65%;
        margin-left: 6%;
        margin-top: 9px;

        .form-control-label {
            float: right;
            padding-right: 24px;
        }
    }

    .material-switch-content > label::before {
        margin-top: -15px;
    }

    .grid-edit-modal {
        width: calc(100% - 3.2%);
        margin-left: -0.8%;

        .switch-container {
            width: 18%;
            font-size: 14px;
        }

        .border-bottom {
            padding-left: 15px;
            padding-right: 15px;
            border-bottom: 0;
        }

        .no-border-right {
            border-right: 0;
        }

        .navigation-buttons {
            padding-left: 0;

            .simpleDropDown {
                border: 1px solid $darkgray;
                border-radius: 4px;
                font-size: 14px;
                height: 27px;
                padding: 3px 6px;
                width: 23%;
                margin-left: 2%;
                margin-right: 2%;
            }

            [disabled] {
                opacity: 0.4;
                pointer-events: none;
            }
        }

        .header-row {
            border-radius: 3px 3px 3px 3px;
            background: $grid-header-background;
            background-color: $trimble_white;

            .the-header {
                width: 100%;
                border: $grid-border;
                padding-left: 0.5em;
                padding-right: 0;
                border-radius: 3px 3px 3px 3px;

                &.second-header {
                    border-radius: 0;
                    margin-top: 8px;
                    border-top: $grid-border;
                }

                &.group-header {
                    border-bottom: 0;
                    border-radius: 3px 3px 0px 0px;
                }

                &.grid-header {
                    border-top: 0;
                    border-radius: 0px 0px 0px 0px;
                }

                .header-column {
                    border-right: $grid-border;

                    &.float-left {
                        float: left;
                    }

                    &.height-25 {
                        height: 25px;
                    }

                    &.width-2 {
                        width: 2%;
                    }

                    &.width-3 {
                        width: 3%;
                    }

                    &.width-4 {
                        width: 4%;
                    }

                    &.width-5 {
                        width: 5%;
                    }

                    &.width-6 {
                        width: 6%;
                    }

                    &.width-7 {
                        width: 7%;
                    }

                    &.width-8 {
                        width: 8%;
                    }

                    &.width-10 {
                        width: 10%;
                    }

                    &.width-12 {
                        width: 12%;
                    }

                    &.width-14 {
                        width: 14%;
                    }

                    &.width-17 {
                        width: 17%;
                    }

                    &.width-21 {
                        width: 21%;
                    }

                    &.width-24 {
                        width: 24%;
                    }

                    &.width-39 {
                        width: 39%;
                    }

                    &.measurement-header {
                        border-bottom: $grid-border;
                        text-align: center;
                    }
                }
            }
        }

        .body-row {

            .second-body {
                width: 100%;
                border-radius: 0px 0px 3px 3px;
            }

            .simpleDropDown {
                border: 1px solid $field-border-color;
                border-radius: 2px;
            }

            .the-body {
                padding-left: 0.5em;
                padding-right: 0;
                border-bottom: $grid-border;
                border-left: $grid-border;
                border-right: $grid-border;

                .body-column {
                    .edit-down-options {
                        .btn.dropdown-toggle {
                            width: 10%;
                            padding-left: 0px;
                            margin-left: 0px;
                            height: 27px;
                        }

                        .btn.custom-button {
                            text-align: left;
                            margin-right: 0;
                            height: 27px;
                        }

                        .drop-down-table {
                            padding-left: 5px;
                        }

                        .dropdown-menu {
                            input {
                                width: 80% !important;
                            }
                        }

                        .custom-drop-down {
                            .custom-button {
                                span {
                                    ng-container {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        text-wrap: wrap;
                                        word-break: break-all;
                                        white-space: wrap;
                                        overflow-wrap: break-word;
                                        word-wrap: break-word;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .body-column {
                border-right: 1px solid $gray;
                padding-bottom: 3.5px;
                padding-top: 2.5px;

                .simpleDropDown {
                    border: 1px solid $darkgray;
                    border-radius: 4px;
                    font-size: 14px;
                    height: 27px;
                    padding: 3px 6px;
                }

                .form-control {
                    color: $trimble_black;
                    border: 1px solid $darkgray;
                    border-radius: 4px;
                    font-size: 14px;
                    height: 27px;
                    padding: 3px 6px;
                }

                &.width-2 {
                    width: 2%;
                }

                &.width-3 {
                    width: 3%;
                }

                &.width-4 {
                    width: 4%;
                }

                &.width-5 {
                    width: 5%;
                }

                &.width-6 {
                    width: 6%;
                }

                &.width-7 {
                    width: 7%;
                }

                &.width-8 {
                    width: 8%;
                }

                &.width-10 {
                    width: 10%;
                }

                &.width-12 {
                    width: 12%;
                }

                &.width-17 {
                    width: 17%;
                }

                &.width-21 {
                    width: 21%;
                }

                &.width-14 {
                    width: 14%;
                }

                &.width-39 {
                    width: 39%;
                }

                .quality-package-name > div {
                    width: 100px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .quality-package-name-column:has(.field-invalid) {
                background-color: $lightred !important;
            }
        }

        .navigation-section-row {
            margin-bottom: 1%;
        }

        .button-next {
            margin-right: 10px;
        }

        .button-back {
            margin-left: 10px;
        }

        .body-second-row-container {
            height: 23px;
        }

        .empty-row {
            height: 18px;
        }
    }

    .padding-bottom-extra-columns {
        .grid-edit-modal {
            .border-bottom {
                padding-bottom: 23px;
            }

            .body-second-row-container {
                height: 29px;
            }
        }
    }

    .button-icon-row {
        .button-icon {
            margin-left: 7px;
            margin-top: 7px;
            position: absolute;
        }
    }



    @media screen and (max-width: 1800px) {
        .grid-edit-modal {
            width: calc(100% - 3.6%);

            .switch-container {
                width: 21%;
            }

            .header-column {
                .gif-card-icon-header {
                    padding-left: 2px;
                }
            }
        }

        .button-icon-row {
            .button-icon {
                margin-left: 5px;
            }
        }
    }

    @media screen and (max-width: 1600px) {
        .grid-edit-modal {
            width: calc(100% - 3.9%);
            margin-left: -15px;

            .switch-container {
                width: 23%;
            }

            .header-column {
                .gif-card-icon-header {
                    padding-left: 0px;
                }
            }
        }

        .button-icon-row {
            .button-icon {
                margin-left: 4px;
            }
        }
    }

    @media screen and (max-width: 1300px) {
        .grid-edit-modal {
            width: calc(100% - 4.8%);
            margin-left: -15px;

            .switch-container {
                width: 28%;
            }

            .header-column {
                .gif-card-icon-header {
                    padding-left: 0px;
                    width: 19px;
                }
            }
        }

        .button-icon-row {
            .button-icon {
                margin-left: 0px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .switch-container {
            width: 32%;
        }
    }
}

.info-dialog {
    z-index: 2000 !important;

    .formatted-message-container {
        margin-left: 20px;
    }

    .text-bold {
        font-size: initial;
    }

    .transform-none {
        text-transform: none !important;
        padding: 15px 15px 0px 15px;
    }
}
