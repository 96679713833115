﻿@import '../../../../../../shared/sass/_variables.scss';

.full-width-modal-window {
    .modal-dialog {
        width: 100% !important;
        padding-left: 30px;
        padding-right: 30px;
    }
}

.create-trade-area-from-stands-modal {

    .selection-segment {
        display: flex;
        align-items: flex-start;
        justify-content: left;
        width: 100%;
        border-bottom: 1px solid $darkgray;

        > div {
            margin: 30px;
        }

        .control-row {
            display: flex;
            align-items: center;
            justify-content: left;
            margin-bottom: 5px;

            label {
                margin: 0;
                font-weight: normal;
            }

            input {
                margin-right: 10px;
            }
        }

        .selection-header {
            margin-bottom: 10px;
            font-weight: bold;
        }

        .create-new-options {
            width: 20%;
            height: 125px;
            border-right: 1px solid $half_trimble_brand_grey;
        }

        .additional-settings {
            width: 70%;

            .div-selection-error {
                background-color: #F4D1D3;

                [type="checkbox"]:not(:checked) + label:before {
                    border: 1px solid #c81922 !important;
                }
            }

            .trade-selection {
                display: flex;
                align-items: center;
                justify-content: center;

                > div {
                    width: 50%;
                }

                [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
                    position: relative;
                    padding-left: 25px;
                    cursor: pointer;
                    margin: 0;
                }

                [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
                    top: 0;
                }

                [type="checkbox"]:checked + label:after {
                    top: 0px;
                }
            }

            .species-blocks {
                display: flex;
                align-items: center;
                justify-content: center;

                .species-block {
                    margin-top: 10px;
                    width: 50%;
                    padding-left: 26px;

                    .control-row {
                        label {
                            width: 50%;
                        }

                        span {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }

    .create-trade-area-grid-container {
        width: 100%;
        padding: 15px;

        .ag-selection-checkbox {
            padding-right: 0;
        }

        [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
            position: relative;
            padding-left: 25px;
            cursor: pointer;
            margin: 0;
        }

        [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
            top: 1px;
            left: 2px;
        }

        [type="checkbox"]:checked + label:after {
            top: 6px;
            left: 4px;
        }

        .ag-header-group-cell-label {
            text-transform: uppercase;
        }

        .create-trade-area-grid {
            height: 350px;
            width: 100%;
            border: 1px solid $darkgray;
            border-radius: 3px;
            overflow: hidden;

            .checkbox-container {
                width: 100%;
                display: flex;
                justify-content: center;

                input {
                    width: 100%;
                }
            }

            .biomass-and-stump-header {
                border-bottom: none;

                .ag-header-group-cell-label {
                    text-align: left;
                    padding-left: 5px;
                }
            }

            .ag-header-group-cell.additional-header.ag-header-group-cell-with-group {
                border-bottom: none;
            }

            .additional-header {
                .ag-header-group-cell-label {
                    text-align: left;
                    padding-left: 5px;
                }
            }

            .select-all-wrapper {
                [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
                    top: 8px;
                    left: 0px;
                }

                [type="checkbox"]:checked + label:after {
                    top: 8px;
                    left: 0px;
                }
            }

            .checkbox-cell {
                text-align: center;
            }
        }
    }

    .modal-trimble-button-container {
        .btn {
            padding: 6px 30px;
        }
    }
}
