@import '../../../app/shared/sass/_variables.scss';

.support + .breadcrumbs li {
    height: auto;
}

.help-header, .support-header {
    height: 130px;
}

.menutext {
    color: $trimble_white;
    font-family: $font-semibold;
    font-size: 16px;
    position: absolute;
    margin-left: 300px;
    margin-right: 300px;
    left: 0px;
    right: 0px;
    text-align: center;
    margin-top: 6px;
    user-select: none;
    letter-spacing: 0.6px;
}

.navi-down-arrow {
    background-image: url(../content/images/svg/Show_NavigationBar_Button.svg);
    filter: drop-shadow($trimble_header_icon_shadow_color 0px 4px 2px);
    height: 11px;
    cursor: pointer;
}

.navi-up-arrow {
    background-image: url(../content/images/svg/Hide_NavigationBar_Button.svg);
    filter: drop-shadow($trimble_header_icon_shadow_color 0px 4px 2px);
    height: 11px;
    cursor: pointer;
}

.show-hide-menu {
    width: 45px;
    /*this needs to be 15 px! it is the minimum distance of an object from the navigation header*/
    height: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4px;
}

.header-shadow {
    box-shadow: 0px 3px 4px $trimble_header_shadow_color;
}

.navigation-main-controls {
    font-size: 14px;
    height: 46px;
    color: $trimble_tf_product_green;
    text-align: center;
    padding: 15px 1em 5px;


    .controls {
        color: $lightgray;
        font-size: 18px;
        width: 100%;
        height: 100%;

        .icons {
            display: inline-block;
            vertical-align: top;

            span {
                margin: 0 5px;
            }

            .db-logo {
                height: 28px;
            }

            .icon {
                width: 40px;
                height: 30px;
                background-repeat: no-repeat;
                background-position: center center;
                display: inline-block;
                margin-left: 10px;
            }

            .icon-connection {
                margin-right: 0px;
                background-image: url(../content/images/svg/WebConnection3.svg);

                &.disconnected {
                    background-image: url(../content/images/svg/NoWebConnection3.svg);
                }
            }

            .icon-notification-messages {
                margin-right: 0px;
                background-image: url(../content/images/svg/Notifications_Bell.svg);
            }

            .icon-notification-messages:hover {
                cursor: pointer;
                background-color: $trimble_dk_blue;
            }

            .icon-mesage-badge {
                width: 15px;
                height: 14px;
                margin-top: -38px;
                margin-left: -23px;
                padding-right: 0;
                padding-top: 1px;
                padding-left: 0px;
                background-color: $orange;
                animation: blinker 1s linear 3;
                user-select: none;
                cursor: pointer;
            }

            .icon-navigation {
                margin-right: 20px;
                background-image: url(../content/images/svg/Navigation_Apps_Icon.svg);
            }

            .icon-navigation:hover {
                cursor: not-allowed;
                background-color: $trimble_dk_blue;
            }

            .icon-navigation-enable:hover {
                cursor: pointer !important;
            }
        }

        .environment {
            display: inline-block;
            font-family: $font-semibold;
            font-size: 16px;
            width: fit-content;
            float: left;
            margin: 6px 30px 0px 30px;
            color: $white;
            letter-spacing: 0.6px;
            user-select: none;
        }
    }
}

.mainpage_header {
    top: 0;
}

.mainpageplay {
    color: $gray;
    margin-top: 23px;
    left: -2px;
    top: 12px;
}

.headertext {
    color: $gray;
    left: 14px;
    top: 34px;
    position: absolute;
    margin: 0;
}

.blue-header {
    background: linear-gradient(90deg, $trimble_gradient_blue_start 0%, $trimble_gradient_blue_end 100%);
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 9px;
    padding-top: 8px;
}

.line {
    line-height: 0.1;
    text-align: center;
    position: relative;
    margin-bottom: 0px;
    z-index: 1;

    &.line-margin {
        margin-left: 70px;
    }

    &:before {
        content: "";
        position: absolute;
        height: 0px;
        border-bottom: 1px solid $gray;
        margin: 0 auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
    }

    .db-logo {
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-top: 1px;
    }
}

.persondetails {
    animation: fade-in 200ms linear;
    color: $black;
    text-align: center;
    font-size: 12px;
    margin-bottom: 0;
    font-family: $font-semibold;
    display: inline-block;
    line-height: 3px;
    vertical-align: top;
    background-color: $white;
    padding: 0 10px 5px 10px;
    margin-left: -3px;

    &.adjust-logo-padding {
        padding: 0 10px 5px 0;
    }
}

.trimble-plus {
    background-image: url(../content/images/svg/Trimble_PlusSigns_Grey_3rows.svg);
    background-repeat: no-repeat;
    background-position: right top;
}


@media (min-width: 768px) {
    .navbar-nav {
        display: flex;
        justify-content: center;
        float: none;
        margin: 0px;
        height: 100px;

        &.help-page {
            justify-content: left;
            height: 45px;
        }
    }

    .navbar-nav > li {
        flex-basis: auto;
        margin-left: 0px;
    }

    .navbar-nav > li:first-child {
        margin: 0;
    }

    .navbar-nav > li > a {
        padding: 0;
        text-align: center;

        .navi-button-text {
            padding-left: 15px;
            padding-right: 15px;
        }

        .button-image {
            width: 125px;
            height: 40px;
            display: inline-block;
            background-position: center bottom;
            background-size: contain;
            background-repeat: no-repeat;
            vertical-align: bottom;
        }

        .button-mainpage {
            background-image: url(../content/images/svg/Navi-MainPage2.svg);
        }

        .button-workingsites {
            background-image: url(../content/images/svg/Navi-Workingsite2.svg);
        }

        .button-customers {
            background-image: url(../content/images/svg/Navi-Customers2.svg);
        }

        .button-forestProperty {
            background-image: url(../content/images/svg/Navi-ForestProperty2.svg);
        }

        .button-operational-planning {
            background-image: url(../content/images/svg/MainMenu_Large_Operations.svg);
        }

        .button-company {
            background-image: url(../content/images/svg/Navi-Company2.svg);
        }

        .button-admin {
            background-image: url(../content/images/svg/Navi-Admin2.svg);
        }

        .button-help-page {
            background-image: url(../content/images/svg/HelpButton_Online_Help_Selected.svg);
        }

        .button-support-page {
            background-image: url(../content/images/svg/CustomerSupportForm_Symbol_LimeGreen.svg);
        }

        p {
            display: block;
            text-align: center;
            line-height: 37px;
            margin: 0px;
        }
    }

    .navbar-nav > li:hover {
        background-color: $trimble_very_light_grey;
        cursor: pointer;
    }

    .navbar-nav > li.active > a, .navbar-nav > li > a:active, .navbar-nav > li a.active {
        .button-mainpage:active, .button-mainpage {
            background-image: url(../content/images/svg/Navi-MainPage-Selected2.svg);
        }

        .button-workingsites:active, .button-workingsites {
            background-image: url(../content/images/svg/Navi-Workingsite-Selected2.svg);
        }

        .button-customers:active, .button-customers {
            background-image: url(../content/images/svg/Navi-Customers-Selected2.svg);
        }

        .button-forestProperty:active, .button-forestProperty {
            background-image: url(../content/images/svg/Navi-ForestProperty-Selected2.svg);
        }

        .button-company:active, .button-company {
            background-image: url(../content/images/svg/Navi-Company-Selected2.svg);
        }

        .button-admin:active, .button-admin {
            background-image: url(../content/images/svg/Navi-Admin-Selected2.svg);
        }
    }
}

.visible-xs-flex {
    display: none !important;
}

.nav > li > a:focus, .nav > li > a:hover {
    background-color: initial;
}

.navbar {
    margin: 0;
    border: 0;
    min-height: 0;
}

.navi {
    background-color: $trimble_white;
}

.navi .navbar li a {
    color: $trimble_grey;
    font-family: $font-semibold;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    cursor: pointer;
}

.navi .placeholder {
    height: 22px;
}

.navi .navbar li a:active, .navi .navbar li.active a {
    color: $trimble_tf_product_green;
    border-bottom: $trimble_tf_product_green solid;
}

.navi.small-navi-buttons {
    .placeholder {
        display: block;
        height: 12px;
    }

    .button-image {
        width: 40px;
        margin-left: 20px;
        vertical-align: middle;
        height: 20px;
    }

    .nav-elements {
        padding-bottom: 11px;
        padding-right: 30px;
    }

    p {
        display: inline-block;
        line-height: 20px;
        vertical-align: middle;
    }
}

/* Responsive features */
@media (max-width: 767px) {
    /* On small screens navi shouldn't have container with margin, instead a padding will be added to navbar-header and link elements */
    .visible-xs-flex {
        display: flex !important;
    }

    .menutext {
        display: none;
    }

    .controls .icons .icon-navigation.apps-icon {
        display: none;
    }

    .navi.container {
        margin: 0;
        padding: 0;
    }

    .navi {
        background: $trimble_white;

        .navbar-collapse {
            padding: 0;
        }

        .navbar-collapse.in {
            overflow-y: visible;
        }

        .navbar {
            margin-top: 20px;

            ul {
                margin: 0;
            }

            li {
                display: flex;
                height: 3em;
                align-items: center;

                label {
                    margin-left: calc(1em + 28px);
                    font-family: $font-semibold;
                    color: $trimble_tf_product_green;
                    font-size: 18px;
                    line-height: 36px;
                    text-transform: uppercase;
                }

                a {
                    margin-left: 1em;
                    position: relative;
                    font-size: 18px;
                    line-height: 36px;
                    height: 22px;
                    padding: 0;

                    .button-image {
                        width: 40px;
                        height: 22px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center left;
                        font-family: 'Glyphicons Halflings';
                        font-weight: 400;
                        font-style: normal;
                    }

                    p {
                        margin: 0;
                        position: absolute;
                        left: 28px;
                        bottom: 0;
                        line-height: 18px;
                    }
                }
            }

            button.navbar-toggle {
                background-color: transparent;
                float: none;
                margin: 0;
                padding: 0;

                .icon-bar {
                    background-color: $trimble_tf_product_green;
                }
            }

            .navbar-header {
                padding: 0.5em 1em;
                position: relative;

                .navbar-brand {
                    float: none;
                    color: $trimble_tf_product_green;
                    font-family: $font-semibold;
                    padding: 0 0.5em;
                    height: auto;
                }
            }

            .navbar-header:after {
                content: '';
                width: 0;
                height: 0;
                left: calc(50% - 5px);
                bottom: -10px;
                position: absolute;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid $white;
                z-index: 9999;
            }
        }
    }
}

.breadcrumbs {
    border-top: 0.75px solid $trimble_grey_light;
    margin-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0px 3px 4px $trimble_header_shadow_color;
    color: $trimble_grey;
    background-color: $trimble_white;
    font-family: $font-primary;
    height: 30px;

    .container {
        width: 100%;
        height: 32px;
    }
}

.breadcrumbs ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 30px;
}

.navbar-divider-vertical {
    height: 100%;
    border-left: 1px solid $lightgray;
    height: 37px;
    margin: 0 6px 0 10px;
    display: inline-block;
    vertical-align: top;
}

.breadcrumbs li {
    color: $trimble_grey;
    vertical-align: middle;
    display: inline-block;

    a {
        &.has-no-link {
            cursor: unset;

            &:hover span {
                text-decoration: none;
                color: $trimble_grey;
            }
        }

        color: inherit;
        font-family: $font-semibold;
        font-weight: 600;

        &:hover span {
            text-decoration: underline;
            color: $trimble_blue_mid;
        }
    }

    .toggle-navigation-icon {
        padding: 0;
        line-height: 38px;

        &:hover {
            color: $trimble_tf_product_green;
        }
    }

    .icon-properties {
        line-height: 38px;
        padding: 0;
        background-size: 25px;
        height: 25px;
        width: 25px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .icon-navigation-help {
        @extend .icon-properties;
        background-image: url(../content/images/svg/Help_button.svg);

        &:hover {
            cursor: pointer;
            background-image: url(../content/images/svg/Help_button_MouseOver.svg);
        }
    }

    .icon-navigation-message {
        @extend .icon-properties;
        margin-top: 6px;
        background-image: url(../content/images/svg/Notification_Bell_Grey.svg);

        &:hover {
            cursor: pointer;
            background-image: url(../content/images/svg/Notification_Bell_LimeGreen.svg);
        }

        .badge {
            padding: 3px;
            margin-left: 15%;
            margin-bottom: 100%;
            margin-top: -100%;
            background-color: $blue;
            border-radius: 50% 50% 50% 0;
            transform: rotate(-45deg);
            width: 17px;
            height: 17px;

            span {
                transform: rotate(45deg);
                margin-top: -80%;
                margin-left: -10%;
                font-size: 10px;
                user-select: none;
            }
        }

        .badge-animation {
            animation: blinker 1s linear 3;
        }
    }

    .icon-customer-support {
        @extend .icon-properties;
        background-image: url(../content/images/svg/CustomerSupport_ButtonSymbol_Grey.svg);

        &:hover {
            cursor: pointer;
            background-image: url(../content/images/svg/CustomerSupport_ButtonSymbol_LimeGreen.svg);
        }
    }
}

.breadcrumbs li {
    height: 30px;
}

.breadcrumbs li .glyphicon {
    font-size: 24px;
    color: $gray;
}

.breadcrumbs li span {
    line-height: 10px;
    display: inline-block;
    font-size: 12px;
    margin-top: 10px;
    //text-transform: capitalize;
}

.flags {
    display: inline-block;

    .flag {
        border-radius: 3px; // since images don't contain alpha radius
        cursor: pointer;
        height: 20px;
    }
    // maximized menu state
    .header-logo & {
        float: right;
        font-size: 18px; // for input element spacing
        margin-top: 17px;
        margin-right: 14px;
    }
    // login screen
    .carousel & {
        position: absolute;
        top: 16px;
        right: 14px;
    }
}

.navigation-directive {
    .silvadata-language-selection-container {
        display: inline-block;
        width: 70px;
        float: right;
        position: relative;
        top: 10px;

        .custom-drop-down {

            .custom-button {
                padding: 6px 6px;
                border: 1px solid $gray;
                border: none;
            }

            .dropdown-toggle {
                border: 1px solid $gray;
                border: none;
                padding-left: 4px;
            }

            .dropdown-menu {
                z-index: 1000;
                min-width: 170px;
                left: -102px;

                .drop-down-table {
                    overflow: hidden;
                    height: auto;
                }

                .custom-table {
                    color: $darkgray;

                    .first--from-two-columns {
                        width: 20%;
                    }

                    .second--from-two-columns {
                        width: 80%;
                    }

                    .table-row {
                        .table-cell {
                            line-height: 25px;
                        }
                    }

                    .table-row:not(.table-header):hover {
                        background-color: #F5F5F5;
                    }
                }

                .glyphicon-search {
                    top: 3px;
                }

                input[type="search"] {
                    position: relative;
                    left: 20px;
                    top: -33px;
                }

                .glyphicon-remove {
                    position: relative;
                    top: -65px;
                }
            }

            .btn-group.open {
                .dropdown-toggle {
                    box-shadow: none;
                    -webkit-box-shadow: none;
                }
            }
        }

        .flag-border {
            border: 1px solid #d1d1d1;
        }
    }

    .upper-silvadata-language-selection-container {
        top: -1px;
        right: -15px;
        z-index: 1000;
    }
}

.bread-crumb-arrow-right-grey-image {
    margin-left: 10px;
    margin-right: 4px;
}

.first-bread-crumb {
    margin-left: 15px;
}

.navigation-back {
    margin-right: 15px;
    color: $trimble_blue_mid;
}

.navigation-back:hover {
    text-decoration: underline;
}

.bread-crumb-arrow-left-grey-image {
    margin-right: 4px;
}

.link-bread-crumb {
    color: $trimble_blue_mid;
}

.link-bread-crumb:hover {
    text-decoration: underline;
}

.last-bread-crumb {
    color: $trimble_grey;
    font-family: $font-semibold;
    font-weight: 600;
}
