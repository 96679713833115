﻿.products-for-plot-modal {
	$grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 14px;

	.title-bar {
		> div:not(.clearfix) {
			padding: 1em;
		}
		.header {
			font-family: $font-semibold;
			margin: 0;
			position: relative;
			text-transform: uppercase;
			width: auto;
		}
		.glyphicon-remove:hover {
			color: $blue;
			cursor: pointer;
		}
	}

	.upper-part {
		margin-left: 14px;
		margin-top: 14px;
		margin-right: 14px;
		.search{
			border: none !important;
		}
		.glyphicon-remove{
			margin-top: 4px;
		}
	}

	.grid-container {
		margin: 1em;
		.modal-grid {
			border: 1px solid $darkgray;
			border-top-left-radius: 0.5em;
			border-top-right-radius: 0.5em;
			border-bottom-left-radius: 0.5em;
			border-bottom-right-radius: 0.5em;
			height: 319px;

			#centerRow {
				border-radius: 0.5em !important;
			}

			.ag-header-group-cell-label {
				text-transform: uppercase;
			}

			.master-cell-header, .ag-header-group-cell, .ag-row, .ag-body-container {
			}
		}
	}

	.grid {
		.grid-header-checkbox-label {
			margin: 0;
			left: -4px;
			top: 2px;
		}
		.ag-floating-bottom-container {
			font-size: 12px;
            font-weight: bold;
        }
		.ag-cell {
			[type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
				top: 5px !important;
			}
            [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
				top: 2px !important;
			}
		}		
		.date-format {
			width: 65px;
		}
	}

	/*buttons section*/
	.form-group {
		padding: 0;
		.buttons-padding{
			padding: 1em;
			.no-padding {
				padding: 0px;
			}
			.medium {
				width: 140px;
			}
			.large{
				width: 220px;
				margin-right: 10px;
			}
		}
	}

	.border-top {
		border-top: 1px solid $gray;
	}

	.border-bottom {
		border-bottom: 1px solid $gray;
	}

	.sp-grid {

		.grid-check-box {
			top: -9px;
		}

		.ag-header-cell {
		}
	}

	.ag-body-viewport .ag-body-container .ag-row-selected .ag-cell {
		color: white !important;
		background-color: black !important;
	}

	.modal-dialog {
		position: relative;
		width: 90% !important;
	}
}
