//Styles used in flexboxgroups like the adminpage's upload logo-fields

.flex-row, .flex-column {
	display: flex;
	justify-content: space-around;	
	align-items: stretch;
	flex: 1;
}
.flex-row {
	flex-flow: row wrap;	
}
.flex-column {
	flex-flow: column wrap;	
	> div{
		//width: 100%;
		height:100%;
		justify-content: stretch;	
		align-items: stretch;
	}
}

.flex-container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.flex-break {
	width:100% !important;
}


/* mobile */
@media (max-width: $media-mobile) {
	.mobile-flip {
		.flex-row {
			flex-direction: column;
		}
		.flex-column {
			flex-direction: row-reverse;
			>div{
				width: auto;
				height:100%;
				text-align: center;
			}
		}

		.flex-table-container {
			display: flex;
			flex-direction: column;
			align-items: stretch;
		}
	}
}