.contract-term-accordion {
    .buttons-group {
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 0.75px solid $trimble_brand_grey;
        background: $gray 0% 0% no-repeat padding-box;
    }

    .padding-top {
        padding-top: 7px;
    }

    .padding-contract-date {
        padding: 6px 3px;
    }
}