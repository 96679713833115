﻿.filter-date-field {
    input {
        width: 120px;
        height: 30px;
        border: 1px solid $gray;
        border-radius:4px;
        padding-left:1px;

        &:focus {
            outline: none;
        }
    }

    .row-no-margin {
        margin-right: 0;
        margin-left: 0;
        height: 100%;
    }

    .full-height {
        height: 100%;
    }

    .container-directive {
        float: left;
        width: 100%;
    }

    .directive-margin {
        margin: 0em 0.5em 0.5em 0.5em;
    }

    .filter-date-form {
        .group-settings.settings-item {
            margin-top: 0;
            margin-bottom: 2px;
        }
    }
}
