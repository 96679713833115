.harvesting-resources-for-selected-harvest-area-modal {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type="date"]::-webkit-calendar-picker-indicator,
    input[type="date"]::-webkit-inner-spin-button {
        display: none;
    }

    .modal-dialog {
        width: 860px;
    }

    .title-bar {
        >div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .separator {
        margin-top: 0;
        border-color: lightgray;
    }

    .no-bottom-margin {
        margin-bottom: 0;
    }

    .modal-grid {
        padding-left: 14px;
        padding-right: 14px;
        margin-bottom: 14px;

        .label-uppercase {
            text-transform: uppercase;
            color: black;
        }

        .ag-cell {
            background-color: $disabled-background-color;

            input[type="text"] {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            input[type="number"] {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                text-align: right;
                margin-right: 2px;
                width: 100%;
            }

            .default-pointer {
                cursor: default;
            }
        }

        .ag-row-group {
            padding-top: 5px !important;
            padding-left: 7px !important;
        }

        .selection-cell {
            background-color: unset;
        }

        .published-date {
            display: flex;
        }
    }

    .panel-heading {
        height: 29px;
    }
}