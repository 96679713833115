﻿@import '../../../../shared/sass/_variables.scss';

.add-trade-area-dialog-window-class {
    .modal-dialog {
        top: 20%;
        width: 39%;
        height: 600px;
    }

    .custom-drop-down .dropdown-menu > div {
        max-height: 200px;
        height: 100px;
    }

    .label-padding {
        padding-top: 5px;
    }

    .padding-20 {
        padding: 20px;
    }

    .padding-left-right-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .col-width-26 {
        width: 26%;
    }

    .dropDown400px {
        position: relative;
        width: 129%;

        .dropdown-menu {

            .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12,
            .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5,
            .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9,
            .col-md-1, .col-md-10, .col-md-11, .col-md-12,
            .col-md-2, .col-md-3, .col-md-4, .col-md-5,
            .col-md-6, .col-md-7, .col-md-8, .col-md-9,
            .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12,
            .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5,
            .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9,
            .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12,
            .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5,
            .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
                min-height: 18px;
                padding-right: 0px;
                padding-left: 5px;
            }

            .table-row:not(.table-header):hover {
                .col-xs-4:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-3:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-2:not(.grey-background) {
                    background-color: dodgerblue !important;
                }

                .col-xs-1:not(.grey-background) {
                    background-color: dodgerblue !important;
                }
            }
        }

        .btn {
            height: 27px;
            padding-top: 3px;

            span {
                float: left;
            }
        }

        button[disabled], button.btn[disabled] {
            border: 1px solid #616161;
        }

        background-color: white;
    }

    .tablefoot-actionbar {
        border-top: 0.75px solid $trimble_brand_grey;
        opacity: 1;
        overflow: hidden;
        padding: 15px;

        button {
            margin-right: 12px;

            &.no-margin {
                margin-right: 0;
            }
        }
    }
}
