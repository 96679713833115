﻿.add-product-modal {
    .modal-dialog {
        width: 30%;
        top: 28%;

        .modal-body {
            padding: 0;
        }
    }   

    .first-row {
        height: 45px;
        background-color: $gray;

        .product-name {
            height: auto;
            float: left;
            padding-left: 15px;
            padding-top: 15px;
            margin-right: 15px;
        }

        .assortment-label {
            float: left;
            font-weight: bold;
            margin-right: 5px;
        }
    }

    .second-row {
        padding: 15px;
    }
}
