﻿@import '../../shared/sass/_variables.scss';

.select-active-forest-plan-for-real-esate-modal-window {
    font-family: $font-family;
    font-size: $default-font-size;

    .execute-button {
        margin-left: 5px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .form-group {
        padding: 0;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .real-estate-list {
        padding: 10px;

        .real-estate-title {
            margin: 5px;
            margin-left: 0;
            font-weight: bold;
        }

        .real-estate {
            padding-left: 10px;

            .form-group {
                input[type=radio] {
                    display: inline-block;
                    width: initial;
                    padding-right: 5px;
                    vertical-align: middle;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    height: 14px;
                }

                label{

                }
            }
        }
    }
}
