﻿#measures-accordion-group {
	.panel-body {
		border: 0;
	}
}

.measures-tab {
	.measures-tab-button {
		padding: 0.5em;
	}

	.measures-tab-content {
		$border-dark-gray: 1px solid $darkgray;

		@mixin grid-header-top-separator {
			border-top: 1px solid white;
		}

		@mixin grid-header-right-separator {
			border-right: 1px solid white;
		}

		@mixin grid-content-right-separator {
			border-right: 1px solid $gray;
		}

		@mixin grid-content-top-separator {
			border-top: 1px solid $gray;
		}

		@mixin grid-columns-padding {
			padding-left: 5px;
			padding-right: 0px;
			padding-top: 3px;
			padding-bottom: 3px;
			display: flex;
			flex-direction: column;
		}

		.column-padding {
			@include grid-columns-padding;
		}

		.measures-header-separator-top-right {
			@include grid-header-top-separator;
			@include grid-header-right-separator;
			@include grid-columns-padding;
		}

		.measures-header-separator-top {
			@include grid-header-top-separator;
			@include grid-columns-padding;
		}

		.measures-grid-header {
			border-top: $border-dark-gray;
			background-color: $gray;
			font-family: $font-semibold;
		}

		.measures-content-separator-top-right {
			@include grid-content-top-separator;
			@include grid-content-right-separator;
			@include grid-columns-padding;
		}

		.measures-content-separator-top {
			@include grid-content-top-separator;
			@include grid-columns-padding;
		}

		.measures-content-separator-right {
			@include grid-content-right-separator;
			@include grid-columns-padding;
		}

		.measures-grid-content {
			background-color: $lightgray;
			font-family: $font-family;
		}

		.item-separator-bottom {
			border-bottom: $border-dark-gray;
		}

		.item-separator-top {
			@include grid-content-top-separator;
		}

		.row-eq-height {
			display: flex;
		}

		.timber-selling-plans {
			margin-top: 0.5em;
			border-bottom: $border-dark-gray;
		}

		.limit-text {
			width: 99%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.text-underline {
			text-decoration: underline;
		}

		.forest-works {
		}
	}
}
