﻿@import '../../../shared/sass/_variables.scss';

.silvalogis-account-info {
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;

    .no-left-right-padding {
        padding-left: 10px;
        padding-right: 10px;
    }

    .small-padding {
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .full-space-button {
        width: 100%;
    }


    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            margin: 0;
            position: relative;
            width: auto;
            padding-left: 15px;
            border-bottom: none;
            border-radius: 0;
            font-family: $font-semibold;
            font-size: 14px;
            text-transform: uppercase;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }

        .close-button {
            padding: 15px 15px 0px 0px;
        }
    }

    .details-area {
        background-color: $lightgray;
        height: 3.5em;

        .text-div {
            padding-top: 1em;
            padding-left: 15px;
        }
    }

    .bottom-buttons {
        button {
            margin-right: 15px;
        }

        button:last-of-type {
            margin-right: 0px;
        }
    }

    .border-top {
        border-top: 1px solid $gray;
    }


    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .modal-dialog {
        position: relative;
        width: 90% !important;
    }

    .body-area {
        padding: 15px 10px 15px 25px;

        #passwordLabel {
            margin-left: -15px;
            margin-right: 15px;
        }

        .mail-group {
            label {
                padding-top: 25px;
            }

            div {
                padding-top: 20px;
            }
        }

        .required-background {
            background-color: $lightred;
        }
    }

    .body-area-login {
        .form-group {
            margin-top: 5px;
        }

        .lock-icon {
            width: 80px;
        }
    }

    .btn-silva.col-md-6 {
        width: 47%;
    }
}

.silvalogis-account-change-password-modal-window {
    .modal-dialog {
        width: 300px;
    }
}
