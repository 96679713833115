﻿@import '../../../shared/sass/_variables.scss';

.xml-import-modal-window {
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;


    .float-left {
        text-align: left;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .tabs-wrapper {
        margin: 10px;
    }

    .first-col {
        width: 90%;
        padding-top: 10px;
    }

    .text-col {
        padding: 0.5em;
    }

    .xml-import-readonly-input-field {
        height: 400px;
        resize: none;
        margin-top: 0px;
    }

    .tabs-wrapper .tabs-content {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
    }

    .interval-col {
        width: 80px;
    }

    .year-input-margin {
        margin-bottom: 5px;
    }

    .left-padding {
        padding-left: 3px;
    }

    .label-padding {
        padding-top: 10px;
    }

    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
        top: 0px;
    }

    [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label:after {
        top: 2px;
    }


    .grid-container {

        .modal-grid {
            border: 1px solid $darkgray;
            border-radius: 0.5em;
            height: 300px;

            #centerRow {
                border-radius: 0.5em !important;
            }

            .ag-header-group-cell-label {
                text-transform: uppercase;
                text-align: left;
            }

            .master-cell-header, .ag-header-group-cell, .ag-row, .ag-body-container {
                width: 100% !important;
                border-right: 0;
            }
        }
    }

    .no-right-border {
        border-right: none;
    }

    .sp-grid {

        .grid-check-box {
            top: -9px;
        }

        .ag-header-cell {
            text-align: left;
            border-right: none;

            [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                top: 3px !important;
                left: 3px !important;
            }

            [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                top: 5px !important;
                left: 5px !important;
            }
        }

        .ag-cell {
            [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                top: 2px !important;
            }

            [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                top: 0px !important;
            }
        }

        .main-header {
            text-transform: uppercase;
            width: 570px !important;
        }
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .form-group {
        padding: 0;
    }

    .fixed-size {
        margin: 7px;
        width: 150px;
        height: 40px;
    }

    .no-right-border {
        border-right: none;
        padding-left: 3px;
        padding-top: 4px;
    }


    .header-generic {
        font-size: 12px;
        text-transform: none !important;
    }

    .last-col {
        border-right: none !important;
    }

    .ag-header-container {
        border-right: none;
        border-bottom: none;
    }
}