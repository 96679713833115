.setting-harvest-start-date {
    top: 216px;

    .modal-dialog {
        width: 400px;
    }

    .field-invalid {
        background-color: $lightred !important;
    }
}