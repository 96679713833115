.work-site-view {
    .header-background {
        background-color: #ccc;
    }

    .panel-heading.header-background {
        text-transform: uppercase;
    }

    .container-row {
        display: flex;
        margin-bottom: 22px;
        height: 700px;

        .left-panel {
            height: 100% !important;
            width: 33%;
            border-width: 0px;
            margin: 0px;

            .left-panel-height {
                height: 100%;

                .top-section {
                    height: 7.1%;
                    background-color: $trimble_very_light_grey;

                    .work-site-view {
                        .text-padding {
                            @media screen and (max-width: 1600px) {
                                left: 5px;
                            }

                            @media screen and (max-width: 1300px) {
                                left: 15px;
                            }
                        }
                    }
                }

                .bottom-section {
                    height: 94%;
                    background-color: $trimble_very_light_grey;
                }
            }

            .panel {
                height: 100%;
                display: flex;
                flex-direction: column;
                margin: 0;
                border-radius: 7px;
                overflow: hidden;
            }

            form {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                height: 100% !important;

                .lohko-selection {
                    padding: .5em;
                    border-bottom: 1px solid $gray;

                    .dropdown,
                    label {
                        padding: 0;
                    }
                }

                uib-accordion {
                    overflow: auto;
                    display: flex;
                    flex-direction: column;

                    .panel-default>.panel-heading {
                        background: $white;
                        color: $black;
                        border-color: $white;
                        border-radius: 0;
                        font-size: 1em;
                        padding: .5em;
                        min-height: initial;
                        background: transparent;

                        a,
                        i.glyphicon {
                            color: $black;
                            text-decoration: none;
                        }

                        .panel-title {
                            i.glyphicon {
                                padding-right: .5em;
                            }

                            font-weight: bold;
                            font-size: 1em;
                            text-transform: uppercase;
                        }
                    }

                    .panel {
                        border: 0;
                        border-bottom: 1px solid $gray;
                        min-height: 32px;

                        &:first-child {
                            border-top: 1px solid $gray;
                        }

                        &.panel-open>.panel-heading,
                        &>.panel-heading:hover {
                            background-color: $black;
                            color: $white;

                            .attention-icon {
                                content: url(../../content/images/svg/attention-white.svg) !important;
                            }

                            a,
                            i.glyphicon {
                                color: $white;
                            }
                        }
                    }

                    .panel-collapse {
                        .panel-body {
                            padding: 15px;
                        }
                    }

                    .panel-group {
                        /*height: 100%;*/
                        margin: 0;
                        display: flex;
                        flex-direction: column;

                        .panel-open {
                            height: 100% !important;

                            &.panel-default {
                                &.full-height {
                                    height: auto;
                                    max-height: none;
                                }
                            }
                        }

                        .panel-default {
                            height: 32px;
                            border-radius: 0px !important;
                            border-top: 0px;

                            &.full-height {
                                height: inherit;
                                max-height: 50px;
                            }
                        }

                        .panel-collapse.in {
                            overflow-y: auto;
                        }
                    }
                }

                .form-group {
                    display: flex;
                    align-items: center;
                    padding-left: 0px;

                    label {
                        width: 25%;
                        min-width: 25%;
                        margin-right: .5em;
                        float: none;
                        overflow: hidden;
                        display: inline-block;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        &.min-width-120 {
                            min-width: 120px;
                        }

                        &.checkbox-label {
                            width: 100%;
                            overflow: visible;
                        }
                    }

                    input,
                    textarea,
                    [uib-dropdown] {
                        flex-grow: 1;
                        float: none;
                    }
                }

                .controls {
                    margin-bottom: 0.5em;
                }
            }

            .payment-share {
                margin-top: 3px;

                .attention-icon {
                    content: url(../../content/images/svg/attention.svg);
                    vertical-align: top;
                    margin-left: 5px;
                    margin-top: -3px;
                }

                .message-info {
                    font-weight: normal;
                    text-transform: initial;
                    font-size: 13px;
                    font-family: $font-primary;
                }
            }
        }

        .checkboxes {

            [type="checkbox"]:not(:checked)+label:before,
            [type="checkbox"]:checked+label:before {
                top: 6px;
            }

            [type="checkbox"]:not(:checked)+label:after,
            [type="checkbox"]:checked+label:after {
                top: 6px;
            }
        }

        .rightPanelExpanded {
            margin-left: 0em !important;
        }

        .sp-map.map-container .hide-sidebar-button {
            padding-left: 42px;
        }

        .sp-map.map-container .hide-sidebar-button span {
            margin-left: -42px;
        }

        .no-divider-line {
            border-bottom: none !important;

            .panel-body {
                border-bottom: none;
            }
        }
    }
}

.work-site-accordion {
    .small-green-font {
        font-size: 12px;
        color: #008752;
        margin-bottom: 0px;
        margin-left: -15px;
    }

    .drop-down-position {
        height: 34px !important;
    }

    .edit-button {
        .pen-icon {
            height: 25px !important;
            width: 20px !important;
            background-color: transparent !important;
        }
    }

    .contact-icon {
        height: 32px;
        width: 40px;
    }

    .contacts-dd {
        width: calc(100% - 5em);
        display: inline;
    }

    .attachment-icon-mesage-badge {
        background-color: #ff9400;
        animation: blinker 1s linear 3;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
    }

    .attachment-badge {
        position: absolute;
        top: 13px;
        left: 108px;
    }

    .attachment-icon-white {
        height: 25px !important;
        background-color: transparent;
    }

    .workSite-buttons {
        vertical-align: middle;
        display: inline-block;
        line-height: 28px;
        height: 30px;

        .print-button {
            padding: 0 5px;
            height: 32px;
            border-radius: 0.3em;
        }

        .btn {
            .icon.icon-printer {
                background-image: url(../../content/images/svg/print_white_18dp.svg);
                height: 25px;
                width: 25px;
                background-color: transparent;
            }
        }

        .btn:hover {
            .icon.icon-printer:hover {
                background-image: url(../../content/images/svg/print_white_18dp.svg);
                height: 25px;
                width: 25px;
                background-color: #005F9E;
            }

            .icon.pen-icon:hover {
                height: 25px;
                width: 25px;
                background-color: #005F9E;
            }
        }

        .button-border {
            background-color: white;
            border: 1px solid darkgray;
            border-radius: 5px;
        }

        .icon {
            height: 17px;
            width: 18px;
            line-height: 30px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .underlined {
        text-decoration: underline !important;
        cursor: pointer !important;
    }

    .additional-header-info {
        margin-left: 5px;
    }
}