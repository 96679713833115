﻿
@import "../../../shared/sass/_variables.scss";

.accountancy-modal {
    .modal-dialog {
        top: 15%;
        width: 90%;
    }

    .grid-container {
        margin: 15px 0px 0px;
        border: 1px solid $sp-panel-border-color;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom: none;

        .sp-grid {

            .grid-check-box {
                top: -8px;
            }

            .ag-root.ag-root {
                border-radius: 0px;
            }

            .cell-header-right {
                .ag-header-cell-text {
                    float: right !important;
                }
            }
        }

        .select-all-wrapper {
            left: 3px;
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }

        .ag-theme-fresh .ag-ltr .ag-selection-checkbox {
            padding-left: 3px;
            float: left;
        }

        .ag-theme-fresh .ag-header-select-all {
            padding-left: 6px;
        }

        .no-case {
            .ag-header-cell-label,
            .ag-header-group-cell-label {
                text-transform: none !important;
            }
        }
    }

    .grid-buttons {
        background: #d1d1d1 0% 0% no-repeat padding-box;
        border: 1px solid $sp-panel-border-color;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 10px;
        margin: 0px 0px 10px 0px;
        border-top: none;
    }

    .grid-summary {
        background: #d1d1d1 0% 0% no-repeat padding-box;
        border: 1px solid $sp-panel-border-color;
        border-top: none;
        height: 25px;
        padding: 3px;
    }

    .margin-top-bottom-15px {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .height-25px {
        height: 25px !important;
    }

    .form-control {
        height: 20px;
        padding: 1px 0px;
    }

    .margin-right-10 {
        margin-right: 10px;
    }

    .acc-date-width {
        width: 98%;
    }

    .acc-date-width :hover {
        color: $black;
    }

    .pad-top-5 {
        padding-top: 5px;
    }

    .width-44-9-per {
        width: 44.9%;
    }

    .top-right-margin {
        top: 5px;
        right: 10px;
    }

    .summary-label-margin {
        text-align: right;
        left: 10px;
    }

    .summary-value-margin {
        text-align: left;
        right: 5px;
    }

    .top-3 {
        top: 3px;
    }
}
