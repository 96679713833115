﻿@import '../../../shared/sass/_variables.scss';

.measurement-certificates-list-view {
    margin-top: 0.5em;
    animation: fade-in 250ms linear;

    &:not(.container) {
        animation: fade-in 250ms linear;
    }

    .mc-list-table,
    .measurement-tablefoot-actionbar,
    .settings-row-top {
        margin: 0 auto;

        .ag-body-viewport {
            overflow-x: hidden;
        }
    }

    .mc-list-table {
        margin: 0.5em 0 0;
        border: 1px solid $darkgray;
        border-radius: 0.5em;
        background-color: $white;
        overflow: hidden;
        height: 50vh;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .mc-list-table {
        .status-padding {
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 83%;
        }

        .check-icon {
            content: url(../../content/images/svg/check.svg);
        }

        .versioning-information {
            background-size: 6px;
            background-image: url(../../../../content/images/svg/CriticalNotificationSymbol_Black.svg);
            background-repeat: no-repeat;
            margin-top: 4px;
            margin-left: 7px;
            height: 22px;
        }

        .ag-body {
            .ag-cell .versioning-information-col {
                background-size: 6px;
                background-repeat: no-repeat;
                margin-left: 5px;
                height: 22px;
                background-image: url(../../../../content/images/svg/CriticalNotificationSymbol_Black.svg);
            }

            .ag-row-hover,
            .ag-row-selected {
                .ag-cell .versioning-information-col {
                    background-image: url(../../../../content/images/svg/CriticalNotificationSymbol_White.svg);
                }
            }
        }


        .ag-body .ag-row.ag-row-hover .ag-cell .icon.icon-locked-status {
            background-image: url(../../../content/images/svg/lock_white.svg);
        }

        .ag-body-viewport {
            .ag-body-container {
                .ag-row-selected {
                    .icon.icon-locked-status {
                        background-image: url(../../../content/images/svg/lock_white.svg);
                    }
                }
            }
        }

        .icon-width {
            background-size: 15px 15px;
            height: 15px;
            width: 15px;
        }

        .icon-calculator-size {
            background-size: 26px;
            height: 26px;
            width: 26px;
        }
    }

    .measurement-tablefoot-actionbar {
        border: 1px solid $darkgray;
        border-top: 0;
        border-radius: 0.5em;
        background-color: $lightgray;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;

        button {
            margin-right: 0.5em;

            &.no-margin {
                margin-right: 0;
            }
        }
    }

    .no-rows-bubble {
        margin-left: 36.5%;
        padding: 10px;
        background-color: white;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
        text-align: left;
        width: 162px;
    }

    .ag-theme-fresh {
        .ag-header-select-all {
            //center horizontally the select all button
            padding-left: 7px;
        }
    }

    .list-view-settings-row {
        .advanced-filtering-dropdown .dropdown-content {
            width: 787px;
            height: 430px;

            .dropdown-table {
                .table-cell {
                    .dropdown-date-input {
                        width: 161px;
                    }

                    .multiselection-drop-down .checkboxes {
                        width: 301px;
                    }
                }
            }

        }
    }
}