%disabledNavigation {
  opacity: 0.4;
  pointer-events: none;
}

.navigation-buttons {
    text-align: center;
    margin: 0;
    padding-left: 1em;
    padding-top: 0.2em;
    animation: fade-in 500ms linear;
    text-transform: uppercase;
    font-size: 14px;

    a {
        text-decoration: none;
    }

    &.disabled {
        li {
            @extend %disabledNavigation;
        }
    }

    li.disabled {
        @extend %disabledNavigation;
    }

    ul.buttons, li {
        display: inline-block;
    }

    ul.buttons {
        padding: 0;
    }

    .navigation-button-description-prev {
        margin-right: 0.5em;
    }

    .navigation-button-description-next {
        margin-left: 0.5em;
    }

    .button {
        width: 1.5em;
        height: 1.5em;
        display: inline-block;
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
    }
    /* static buttons*/
    .button-to-start {
        background-image: url(../content/images/svg/Step-to-first.svg);
        cursor: pointer;
    }

    .button-back {
        background-image: url(../content/images/svg/Step-backward.svg);
        cursor: pointer;
        width: 1.35em;
        height: 1.35em;
    }

    .button-next {
        background-image: url(../content/images/svg/Step-forward.svg);
        cursor: pointer;
        width: 1.35em;
        height: 1.35em;
    }

    .button-to-end {
        background-image: url(../content/images/svg/Step-to-last.svg);
        cursor: pointer;
    }

    &:not(.disabled) {
        /* hover buttons */
        .button-to-start:not(.disabled):hover, .button-to-start:active {
            background-image: url(../content/images/svg/Step-to-first-MouseOver.svg);
        }

        .button-back:not(.disabled):hover, .button-back:active {
            background-image: url(../content/images/svg/Step-backward-MouseOver.svg);
        }

        .button-next:not(.disabled):hover, .button-next:active {
            background-image: url(../content/images/svg/Step-forward-MouseOver.svg);
        }

        .button-to-end:not(.disabled):hover, .button-to-end:active {
            background-image: url(../content/images/svg/Step-to-last-MouseOver.svg);
        }
    }
}