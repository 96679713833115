﻿@import '../../../shared/sass/_variables.scss';

$productChainSelectorGridHeight: 600px;
$productChainOtherThanGrid: 235px;
$panel-margin: 3px;

.productChain {
    width: 100%;

    form {
        width: 100%;
    }

    .productChain-left-panel {
        float: left;
        width: calc(33% - #{$panel-margin});
        margin-right: $panel-margin;
        border: 1px solid #616161;
        border-radius: 0.5em;
        overflow: hidden;

        .productChain-selection-grid {
            height: $productChainSelectorGridHeight;

            .ag-cell{
                font-size: 12px;
            }
        }

        .productChain-selection-grid-button-group {                     
            button {                
                min-width: 170px;
            }           
        }
    }

    .productChain-right-panel {
        float: left;
        width: calc(67% - #{$panel-margin});
        margin-left: $panel-margin;
        border: 1px solid #616161;
        border-radius: 0.5em;
        overflow: hidden;

        .productChain-child-grid {
            height: $productChainSelectorGridHeight - $productChainOtherThanGrid;

            .cell-input {
                width: 100%;
            }

        }

        .productChain-details {
            padding: 10px;

            .productChain-details-row {                

                .form-control{
                    color: $darkgray;
                }

                label {
                    width : 15%;
                    padding-left: 5px;
                }

                input, select{
                    display: inline-block;
                    width: 82%;                    
                    height: 27px;
                }
            }
        }

        .productChain-child-grid-button-group {            
            button {               
                min-width: 200px;
            }            
        }
    }

    .productChain-panel-button-group {
        padding: 5px;
        border-top: $grid-border;
        background-color: $gray;

        button {
            margin: 5px;           
        }

        :last-child {
            float: right;
        }
    }

    .productChain-main-button-group {
        button {
            min-width: 250px;
            padding: 10px;
            margin: 10px 0px;
        }
    }

    .header-upper-part {
        text-transform: uppercase;
    }

    .white-on-black-header{
        padding: 5px;
    }

    .panel-header{
        padding: 3px 5px;
    }

    .white-on-black-header {
        text-transform: none;
    }

    .rounded-corners {
        border-radius: 4px;
    }
}
