@import '../../../../shared/sass/_variables.scss';

header.hideNavigation + .main-view-container .worksite-stands.container {
    height: calc(100vh - 100px);
}

/*header.hideNavigation + .main-view-container .worksite-stands.container .left {
	height: calc(100% - 7.5em);
}*/

main.worksite-stands {
    height: calc(100vh - 230px);

    &.map-page {
        height: calc(100vh - 215px);
    }

    &.mapIsExpanded {
        height: calc(100vh - 70px) !important;
    }
}

.worksite-stands {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &.map-page {
        width: calc(100% + 30px);
    }

    .grid-button {
        padding: 1px 8px;
    }

    .plotbrowser {
        width: 422px !important;
    }

    .plotmap {
        margin-left: 7px !important;
    }

    .multi-item-container {
        &.full-screen {
            max-width: inherit !important;
        }
    }

    .plot-browser-margin {
        top: 68px !important;
        height: calc(100% - 6.30em) !important;
    }

    .sp-accordion .panel-group .panel .panel-body .accordion-grid .ag-body-viewport .ag-cell-edit-input {
        color: black !important;
    }

    .sp-accordion .panel-group .panel .panel-body .accordion-grid .ag-body-viewport .ag-cell-focus {
        color: black !important;
    }

    &.mapIsExpanded {
        .partial-box.absolute-map {
            height: calc(100% - 20px);
        }

        .partial-box.absolute-datapane {
            height: calc(100% - 7.3em);
        }

        .full-screen-stand-pane {
            height: calc(100% - 48px);
        }
    }

    .full-screen-stand-pane {
        height: calc(100% - 48px);
        padding: 0;
        border: 1px solid #616161;
        border-radius: 0.5em;
        background-color: #ffffff;
        min-width: 508px;

        @media (max-width: 1000px) {
            height: calc(100% - 6.5em);
        }

        @media (max-width: $media-mobile) {
            height: calc(100% - 7.5em);
        }

        .full-screen-stand-container {
            overflow-y: auto;
            width: 100%;
            height: calc(100% - 3em);

            form {
                height: 100%; // need to flow the height at each level
            }

            label.control-label {
                margin: 0.3em 0;
            }

            .form-group,
            .input-field {
                padding: 0;
            }

            .flex-container {
                align-items: stretch;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                height: calc(100% - 2em);
                max-height: 446px; // max-height of longest tab
            }

            .scroll-wrapper {
                overflow-y: auto;
                height: calc(100% - 2em);
                width: 100%;
                border: none;
                border-radius: 0;
            }

            .harvest-pane .scroll-wrapper {
                height: calc(100% - 1.7em);
            }

            .partial-box-header {
                margin: 0;
                padding: 0.2em;
                vertical-align: middle;
                width: 100%;
                display: inline-block;
            }

            .close-fullscreen {
                padding: 0.2em;
            }

            .pane-title {
                background-color: $black;
                border-bottom: none;
                border-radius: 0;
                color: $white;
                font-family: $font-semibold;
                font-size: 14px;
                text-transform: uppercase;
            }

            .pane {
                flex: 1;
                height: 100%;
                min-width: 415px;
                max-width: 825px;
                background-color: $white;
                overflow: hidden;
                position: relative;
            }

            .general-info-pane {
                .colon-separator {
                    padding-top: 1.4em;
                }
            }

            .special-features-pane {
                label.nature-label {
                    display: none;
                }

                [type="checkbox"] + label:before {
                    left: 1px;
                    top: 8px;
                }
            }

            .general-info-pane, .harvest-pane, .seedling-pane, .additional-info-pane {
                height: 85%;
            }

            .additional-info-pane {
                textarea {
                    height: 125px !important;
                }
            }
        }
    }

    .partial-box-header {
        padding: 0.2em;
        min-height: 2em;
        line-height: 1.6em;
    }

    .title-separator {
        border-top: 1px solid white !important;
        margin: 0 !important;
        padding: 0 0.2em;
    }

    .title-padding {
        padding: 0.2em;
    }

    .title-data-align {
        padding: 0 0.2em;
        text-align: right;
    }

    .title-data {
        font-size: 12px;
        text-transform: none;
        line-height: 1.9em;
    }

    .row-item {
        flex: 1;
        margin: 0;
        padding: 0;
        text-align: center;

        label {
            margin-bottom: 0;
        }
    }

    .colon-separator {
        margin: .1em 0;
        width: 1em;
        padding-top: 1.1em;
        font-size: 140%;
        text-align: center;
    }

    .bottom-buttons {
        background-color: $lightgray;
        padding: 0;
        border-top: 1px solid $darkgray;
        margin-top: -1px;
        min-height: 43px;

        .btn {
            margin: 0.3em;
        }
    }

    .cuttings {

        .grid-container {
            position: relative;

            .tiny-dropdown {
                height: 20px;
                padding-left: 0;
                position: absolute;
                right: 0;
                z-index: 1;
            }

            .header-left {
                text-align: left !important;
            }

            .cell-left {
                text-align: left !important;
            }

            .middle-text {
                text-align: center;
            }

            .editable-text { //moved one level up, to be applied to all myh grids
                textarea:focus, input:focus {
                    color: black;
                }

                textarea:hover, input:hover {
                    color: black;
                }

                input, select, textarea {
                    color: black;
                }

                input {
                    border-style: none;
                    width: 100%;
                }
            }

            .disabled_column {
                background-color: $lightgray;
            }

            .white_column {
                background-color: $white;
            }

            .woodAssortmentsGrid {
                border-bottom: 1px solid $lightgray;
                border-top: 1px solid $darkgray;
                height: 330px;

                .ag-root {
                    border-radius: 0;
                }

                .ag-cell {
                    overflow: visible;
                }

                .ag-floating-bottom {
                    box-shadow: none !important;
                    border-top: 1px solid $darkgray;
                }

                .ag-floating-bottom-full-width-container {
                    border-top: 1px solid $black;
                }

                .accordion-grid .ag-body-viewport .ag-cell-focus {
                    color: black !important;
                }

                .ag-floating-bottom-viewport {

                    .ag-floating-bottom-container {
                        width: 100% !important;

                        .ag-row {
                            background-color: #d3d3d3;
                        }

                        .ag-row:hover .ag-cell {
                            color: black !important;
                            background-color: #d3d3d3 !important;
                        }

                        :first-child .ag-cell {
                            overflow: visible;
                        }

                        .ag-cell {
                            border: none !important;
                            padding-right: 9px;
                            padding-top: 4px;
                            background-color: #d3d3d3 !important;
                        }
                    }
                }

                .ag-header-cell:first-child {
                    text-align: left;
                }

                .ag-body-viewport {
                    overflow-x: hidden !important;
                }

                .ag-theme-fresh .ag-cell-no-focus {
                    border-right: none !important;
                }
            }

            .cuttings-grid {
                border-bottom: 1px solid $darkgray;
                border-top: 1px solid $darkgray;
                height: 218px; /* to display 6 rows */
                .ag-root {
                    border-radius: 0;
                }

                .ag-cell {
                    overflow: visible;
                }

                .ag-floating-bottom {
                    box-shadow: none;
                }

                .accordion-grid .ag-body-viewport .ag-cell-focus {
                    color: black !important;
                }

                .ag-header-cell:first-child,
                .puulaji-cell {
                    text-align: left; // PUULAJI title and data cells
                }

                .tukki-cell, .kuitu-cell, .yhteensa-cell {
                    text-align: center;
                }

                .ag-body-viewport {
                    overflow-x: hidden !important;

                    .ag-row-hover,
                    .ag-row-inline-editing,
                    .ag-row-focus {
                        color: $black !important;
                    }
 }
            }

            .gray {
                border-bottom: 1px solid $gray;
                background: $lightgray;
                pointer-events: none;
                cursor: default;
            }

            .white {
                border-bottom: 1px solid $gray;
                background: white;
                cursor: default;
            }

            .validation-cell input {
                text-align: center;
            }
        }
    }
    // endof cuttings
    .notes.form-group {
        margin: 0.5em;
        padding: 0;

        .textarea-wrapper {
            border-radius: 4px;
            display: flex;
            height: auto;

            textarea {
                border: 0;
                height: auto;
                margin: 0;
                outline: none;
                resize: none;
                width: 100%;
            }
        }
    }
    /* main panes */
    .partial-box {
        &.left:not(.full-screen) {
            border: 0;
            transition: max-width 200ms linear;
            flex: 0 0 415px;
            overflow: hidden;
            max-width: 415px;
            display: flex;
            flex-direction: column;
            height: calc(100% - 60px);
            margin: 0px;

            &.sidebarHidden {
                max-width: 0px;
                margin-right: 0;
            }

            &.absolute-datapane {
                top: 40px;
                position: absolute;
            }

            .stand-pane {
                position: relative;
                overflow: hidden;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                width: 100%;

                uib-accordion {
                    height: 100%;
                }


                .sp-accordion {
                    .panel {
                        min-width: 415px;
                    }

                    .panel-open {
                        height: calc(100% - 195px)
                    }

                    .panel-open {
                        height: calc(100% - 195px)
                    }
                }

                .input-field {
                    padding: 0;
                }

                .special-features {
                    label.nature-label {
                        margin: 0;
                        display: none;
                    }

                    .checkbox-label {
                        top: -8px;
                        position: relative;
                        left: 6px;
                    }
                }
            }
            // endof stand-pane
        }
        // endof left
        &.right {
            flex-grow: 1;
            display: flex;
            padding: 0;
            border: 0;
            align-items: stretch;
            flex-direction: column;
        }

        &.absolute-map {
            height: 100%;
            margin: 0px;

            .map {
                height: 100%;
            }
        }

        .scroll-wrapper {
            display: flex;
            overflow: hidden;
            height: 100%;
            border: 1px solid $darkgray;
            border-radius: 0.5em;

            form {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;

                .content-container {
                    overflow-y: auto;
                }
            }
        }
    }

    .browser-container.sidebarHidden {
        display: none;
    }


    accordion-group-component {
        max-height: 53%;

        .accordion-group-container .panel-heading .header-accordion.control-label {
            border-top: 1px solid #d1d1d1;
            border-bottom: 0px !important;
        }
    }
    
    @media screen and (max-width: 1800px) {
        accordion-group-component {
            max-height: 45%
        }
    }

    @media screen and (max-width: 1600px) {
        accordion-group-component {
            max-height: 29%
        }
    }

    @media screen and (max-width: 1300px) {
        accordion-group-component {
            max-height: 20%
        }
    }
}
