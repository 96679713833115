﻿.forestry-center-tab {
    .upper-part {
        margin-left: -8px;
        margin-bottom: 12px;

        .col-xs-3.dropdown {
            width: 18%;
        }

        .col-xs-4.select-worksite-stand-radio-btns {
            width: 40%;
            padding-top: 5px;
        }

        .second-radion-btn {
            margin-left: 10px;
        }

        input {
            margin-top: -4px;
            vertical-align: middle;
        }

        .select-worksite-stand-radio-btns {
            padding-top: 6px;
            height: 26px;
        }
    }

    .float-r {
        float: right
    }

    .mr-2 {
        margin-right: 8px;
    }

    .display-flex {
        display: flex;
    }

    .display-none {
        display: none
    }


    .replacing_exisiting {
        display: flex;
        /*margin-left: 84%;*/
        padding-left: 12px;
    }

    .fud-erros {
        max-height: 570px;
        overflow: auto;
        width: 400px !important;
        padding: 10px;
    }

    .center-text {
        padding: 5px;
    }

    .content-title {
        margin-left: 10px;
    }

    .grids {
        .grid-border {
            border-left: 1px solid #616161;
            border-right: 1px solid #616161;
            border-top: 1px solid #616161;
            border-top-right-radius: 0.5em;
            border-top-left-radius: 0.5em;

            .grid-header-checkbox-label {
                margin: 0;
                left: -4px;
                top: 2px;
            }

            .ag-floating-bottom-container {
                font-size: 12px;
                font-weight: bold;
            }

            .ag-cell {
                [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                    top: 5px !important;
                }

                [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                    top: 2px !important;
                }
            }

            .sp-grid {
                .cell-aligned-left {
                    .ag-header-group-cell-label {
                        text-align: left;
                        padding-left: 4px;
                    }
                }
            }
        }
    }

    .forestry-center-tab-footer {
        .forestry-center-tab-grid-footer {
            margin: 0;
            border: 1px solid #616161;
            border-radius: 0.5em;
            background-color: #e8e8e8;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            overflow: hidden;
            padding: 0.5em 0.5em;

            [type="checkbox"] {
                margin-right: 7px;
            }
        }
    }
}

.kemera-erros {
    max-height: 570px;
    overflow: auto;
    width: 400px !important;

    .content-title {
        padding: 10px !important;
    }


    .kemera-content-title {
        padding-left: 10px;
    }

    .alert-message {
        padding-left: 10px;
        margin-top: 6px !important;
        margin-bottom: 0px !important;
        min-height: 0px !important;
        text-align: left;
    }
}
