﻿@import '../../../../../shared/sass/_variables.scss';

.review-approve-container {
    .additional-notes {
        margin-top: 15px;

        .notes {
            height: 70px;
            width: 100%;
            resize: none;
        }
    }

    .navigation-buttons {
        display: block;
        text-align: center;
        margin-right: 15px;
        padding-left: 1em;
        padding-top: 10px !important;
        animation: fade-in 500ms linear;
        text-transform: uppercase;
        font-size: 14px;

        .no-cursor {
            cursor: not-allowed;
        }

        .contract-info-left {
            margin-right: 0.5em;
        }

        .contract-info-right {
            margin-left: 0.5em;
        }

        a {
            text-decoration: none;
        }

        &.disabled {
            li {
                @extend %disabledNavigation;
            }
        }

        li.disabled {
            @extend %disabledNavigation;
        }

        ul.buttons, li {
            display: inline-block;
        }

        ul.buttons {
            padding: 0;
            margin-left: 15px;
            margin-right: 15px;
        }

        .navigation-button-description-prev {
            margin-right: 0.5em;
        }

        .navigation-button-description-next {
            margin-left: 0.5em;
        }

        .button {
            margin-top: 3px;
            width: 1.5em;
            height: 1.5em;
            display: inline-block;
            background-position: center bottom;
            background-size: contain;
            background-repeat: no-repeat;
            vertical-align: middle;
        }
        /* static buttons*/
        .button-to-start {
            background-image: url(../../../../../../content/images/svg/Step-to-first.svg);
            cursor: pointer;
        }

        .button-back {
            background-image: url(../../../../../../content/images/svg/Step-backward.svg);
            cursor: pointer;
            width: 1.35em;
            height: 1.35em;
        }

        .button-next {
            background-image: url(../../../../../../content/images/svg/Step-forward.svg);
            cursor: pointer;
            width: 1.35em;
            height: 1.35em;
        }

        .button-to-end {
            background-image: url(../../../../../../content/images/svg/Step-to-last.svg);
            cursor: pointer;
        }

        &:not(.disabled) {
            /* hover buttons */
            .button-to-start:not(.disabled):hover, .button-to-start:active {
                background-image: url(../../../../../../content/images/svg/Step-to-first-MouseOver.svg);
            }

            .button-back:not(.disabled):hover, .button-back:active {
                background-image: url(../../../../../../content/images/svg/Step-backward-MouseOver.svg);
            }

            .button-next:not(.disabled):hover, .button-next:active {
                background-image: url(../../../../../../content/images/svg/Step-forward-MouseOver.svg);
            }

            .button-to-end:not(.disabled):hover, .button-to-end:active {
                background-image: url(../../../../../../content/images/svg/Step-to-last-MouseOver.svg);
            }
        }
    }

    .modal-trimble-button-container {
        height: 65px;
    }

    .wood-title {
        margin-top: 10px;
    }

    .section {
        .section-content {
            padding: 15px;
        }

        .section-title {
            height: 30px;
            padding: 6px 0px 8px 6px;
            text-transform: uppercase;
            background: #000000 0% 0% no-repeat padding-box;
            color: #FFFFFF;
            font-weight: 700;
            padding-left: 15px;
        }

        input.form-control {
            height: 27px;
        }

        input.date-picker-width {
            width: 363px;
        }
    }

    .contract-start-end {
        display: flex;
    }

    .dash-alignment {
        margin-left: 12px;
        margin-right: 12px;
        margin-top: 4px;
    }

    .grid-border {
        .ag-icon-expanded, .ag-icon-contracted {
            background-color: white;
        }

        .selection-cell {
            padding-left: 6px;
            padding-top: 2px;
        }

        .deliveryPoint-cell:first-child {
            padding-left: 16px
        }

        .cell-date-container {
            width: 100%;
            border: 0px;
            color: black !important;
            height: 24px;
            margin-top: 1px;
        }

        .checkbox-cell {
            text-align: center;
            padding-left: 5px;
            padding-top: 5px;
        }

        .cell-text-container {
            width: 100%;
            border: 0px;
            color: black !important;
            height: 24px;
        }

        .padding-bottom {
            padding-bottom: 2px;
        }

        .ag-cell-inline-editing {
            color: black;
        }

        .ds-cell-with-input {
            padding-top: 0px !important;
        }

        .grid-container {
            border: 1px solid grey;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);

            .sp-grid {
                height: 170px;
            }

            .small-grid {
                height: 156px;
            }

            .taller-grid {
                height: 281px;
            }

            .sp-grid .ag-floating-bottom-container .ag-cell {
                background-color: #d1d1d1;
                /*padding-top: 5px;*/
            }

            .sp-grid .ag-body .ag-row-group {
                padding: 0px !important;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    left: 4px;
                }
            }

            .sp-grid .ag-header-cell {
                border-right: 1px solid darkgrey;
            }

            .select-all-wrapper {
                left: 7px;
                top: -4px;
                position: absolute;
            }

            .sp-grid .ag-root.ag-root {
                border-radius: 4px !important;
            }
        }

        .grid-header-checkbox-label {
            margin: 0;
            left: -4px;
            top: 2px;
        }

        .ag-floating-bottom-container {
            font-size: 12px;
            font-weight: bold;
        }

        .ag-cell {
            [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                top: 5px !important;
            }

            [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                top: 2px !important;
            }
        }

        .cell-align-center {
            text-align: center;
        }

        .vert-align-center {
            padding-top: 3px;
        }

        .sp-grid {
            .cell-aligned-left {
                .ag-header-group-cell-label {
                    text-align: left;
                    padding-left: 4px;
                }
            }
        }

        .button-container {
            padding: 5px;
            background-color: #e8e8e8;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            .btn {
                width: 16%;
                margin: 4px;
                height: 30px !important;
                padding: 0px;
            }

            .update-btn {
                width: 296px;
            }
        }

        .button-container-grid {
            padding: 10px;
            background-color: #e8e8e8;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;


            .button-with-options {
                width: 100%;
                display: flex;

                .add-button {
                    border-right: none;
                    width: calc(100% - 2.4em);
                }

                .drop-down-table:hover {
                    background-color: #eee;
                }

                .dropdown-content {
                    position: absolute;
                    z-index: 9999;
                    width: calc(100% - 2.2em);
                    background: $white 0% 0% no-repeat padding-box;
                    border: 0.5px solid $half_trimble_brand_grey;
                    background: $white 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 0.5px solid $half_trimble_brand_grey;
                    opacity: 1;
                    top: 30px;
                }

                .arrow-button {
                    border-left: none;
                }
            }
        }
    }

    .footer {
        padding: 15px;
        padding-left: 35%;
        display: flex;

        .contract-info {
            min-width: 93px;
            margin-top: 6px;
        }
    }
}
