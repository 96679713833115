.sp-accordion {
    display: flex;          /* needs this repetition */
    flex-direction: column; /* needs this repetition */
    overflow: hidden;
    flex-grow: 1;           /* needs this repetition */

    uib-accordion {
      display: flex;    /* needs this repetition */
      flex-grow: 1;     /* needs this repetition */
    }

    .border-right-white {
        border-right: 1px solid $white;
    }

    a[role=button] .glyphicon {
        color: #333;
        padding-left: 0.1em;
    }

    &.partial-box-header {
        margin-top: 0.5em;
        padding: 0.2em !important;
        vertical-align: middle;
        width: 100%;
        display: inline-block;
    }

    .form-group {
        padding: 0;
        vertical-align: top;
    }

    label.control-label {
        margin: 0.3em 0;
    }
    
    .no-padding {
        padding: 0;
    }
    
    /* override default uib-accordion styles */
    .panel-group {
      display: flex;          /* needs this repetition */
      flex-direction: column; /* needs this repetition */
      width: 100%;            /* needs this repetition */
      margin-bottom: 0;

        .panel {
            border: none !important;
            margin-top: 0px;
            
            &.panel-open {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
            }
            
            /* adjacent panel-title towards panel-open */
            &.panel-open + .panel {
              .panel-heading {
                border-top: 1px solid $gray;
              }
            }
            
            /* panel element scrollable */
            .panel-collapse.collapse.in {
                overflow-y: auto;
            }
            
            &.title-separator {
              padding: 0;
              margin: 0;
            }

            .panel-heading {
                border-radius: 0;
                padding: 0;

                &:hover {
                    background: $black !important;
                    color: $white !important;
                }

                .panel-title {
                    color: #333;
                    font-family: $font-semibold;
                    font-size: 14px;
                    text-transform: uppercase;

                    .accordion-toggle {
                        color: #333;
                        outline: none;
                        text-decoration: inherit;
                    }

                    .control-label {
                        background-color: $white;
                        border-bottom: 1px solid $gray;
                        padding: 0.2em;

                        &:hover {
                            background: $black !important;
                            color: $white !important;

                            .glyphicon {
                                color: $white;
                            }
                        }

                        &.open {
                            background-color: $black;
                            border-bottom: none;

                            .accordion-title {
                                color: $white;
                            }

                            .glyphicon {
                                color: $white;
                                transform: rotate(90deg);
                            }
                        }

                        .accordion-title {
                            margin-left: 0.2em;
                        }
                    }
                }
            }

            .panel-body {
                border-top: none;
                padding: 0;
                
                .padded-container {
                    padding: 0.5em;
                }
                
                .accordion-grid { /* basic grid without controls */
                    text-align: right;

                    .ag-root {
                        border-radius: 0;
                    }

                    .ag-header {
                        font-size: 12px;
                    }

                    .ag-header-cell {
                        text-align: center;
                    }

                    .ag-body-viewport {
                        overflow-x: hidden !important;

                        .ag-cell-value {
                            font-size: 12px;
                            padding-left: 4px;
                            padding-right: 4px;
                        }

                        .ag-cell-edit-input {
                            height: 100% !important;
                            width: 100% !important;
                        }

                        .ag-cell-focus,
                        .ag-cell-edit-input {
                            color: $trimble_tf_product_green !important;
                        }

                        .stand-dropdown {
                            color: initial;
                            width: 100%;
                            padding: initial;
                            padding-left: 7px;
                            max-height: 21px;
                            font-size: 12px;
                        }

                        .red-bg-cell {
                            background-color: $red;
                        }
                    }
                }
            }
        }
    }
    
}

.is-validDDLItem{
    background:white;
}

.not-validDDLItem{
    background:#FFD8D8;
}

