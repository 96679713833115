﻿@import '../../../../../shared/sass/_variables.scss';

.force-value-dialog-window-class {
    .modal-dialog {
        top: 20%;
        width: 454px;
        height: 493px;
    }

    .tablefoot-actionbar {
        border-top: 0.75px solid $trimble_brand_grey;
        opacity: 1;
        overflow: hidden;
        padding: 15px 15px;

        button {
            margin-right: 12px;

            &.no-margin {
                margin-right: 0;
            }
        }
    }

    .padding-20 {
        padding: 20px;
    }

    .padding-top-8 {
        padding-top: 8px;
    }

    .margin-top-19 {
        margin-top: 19px;
    }

    .margin-top-10 {
        margin-top: 10px;
    }

    .margin-left-neg-13 {
        margin-left: -13px;
    }

    .description {
        min-height: 80px;
        overflow-x: auto;
        margin-top: 0px;
    }
}
