﻿.update-forest-payment-modal {
    .modal-dialog {
        top: 20%;
        width: 80%;
    }

    .padding-top-15 {
        padding-top: 15px;
    }

    .grid-container {
        margin-bottom: 15px;
        border: 1px solid $sp-panel-border-color;
        border-radius: 5px;

        .sp-grid {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            height: 350px !important;

            .ag-root.ag-root {
                border-radius: 5px;
            }

            .ag-body-viewport {
                overflow-x: hidden !important;
            }
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }
    }

    .float-left {
        float: left;
    }

    .payment-date-container {
        margin-top: 0px;
    }

    .pad-left-30 {
        padding-left: 30px;
        margin-top: 6px;
    }

    .display-inline {
        display: inline-flex;
    }

    .edit-disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
    }
}
