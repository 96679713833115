﻿@import '../../../../shared/sass/_variables.scss';

.work-site-view {
    width: 100%;
    padding: 0px;

    .stand-select {
        border: none !important;
    }
    //correction caused by margins
    .worksite-tabs {
        width: calc(100% + 0.4em);
        margin-left: -0.25em;
    }

    .dash-mark {
        padding-left: 9px;
        padding-top: 3px;
    }

    .sidebar-hidden {
        max-width: 0px;
        margin-right: 0;
    }

    .padding-bottom {
        padding-bottom: 10px;
    }

    input {
        border: 1px solid $darkgray !important;
    }

    .no-border {
        border: none !important;
    }

    .orange-color {
        color: darkorange;
    }

    .black-color {
        color: black;
    }

    select {
        border: 1px solid $darkgray !important;
        height: 27px !important;
        padding-left: 2px !important;
        padding-top: 4px !important;
    }

    .text-padding {
        padding-left: 0px;
        left: -7px;
        line-height: normal;
    }

    .padding-right {
        padding-right: 5px;
    }

    .panel-header {
        background: $grid-header-background;
        text-transform: uppercase;
        font-family: "Myriad Pro Semibold";
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .partial-header {
        text-transform: uppercase;
        padding: 0.5em;
        margin-bottom: 1px;
    }

    .field-validation-tooltip {
        margin-top: 27px !important;
    }

    *:focus {
        outline: none !important;
    }

    .white {
        color: white;
    }

    .align-center {
        text-align: center;
    }

    .display-flex {
        display: flex;
    }

    .float-right {
        float: right;
    }

    .ag-row-level-0.ag-row-hover .trimble-remove {
        content: url("../../../../content/images/svg/Delete_Entity_White.svg");
        width: 25px;
        height: 22px;
        margin-top: -2px;
    }

    .cell-header-group-left {
        .ag-header-group-text {
            float: left !important;
        }
    }

    .left-padding-l {
        padding-left: 15px !important;
    }

    .custom-drop-down button {
        text-align: left;
        padding-left: 7px;
    }

    .wrapped-ellipsis-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .blank {
        height: 250px;
    }

    .checkbox-align {
        margin-left: 7px;
    }

    ::-webkit-input-placeholder {
        font-style: italic;
    }

    :-moz-placeholder {
        font-style: italic;
    }

    ::-moz-placeholder {
        font-style: italic;
    }

    :-ms-input-placeholder {
        font-style: italic;
    }

    .form-control.no-padding {
        padding-top: 0px !important;
        height: 27px !important;
        border: 1px solid #ccc;
    }

    .margin-top {
        margin-top: -7px !important;
    }


    .arrow-container-height-2x {
        top: -20px;
    }
    //grid
    .navigation-grid {
        height: 50vh;
        border: 1px solid rgb(97, 97, 97);
        border-top: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        user-select: none !important;
    }

    .common-grid .common-grid-grid {
        height: 332px;
    }

    .customers-joined-members-grid .ag-root,
    .customers-table.sp-grid .ag-root,
    .upkeep-grid .ag-root {
        font-size: 14px;
    }

    .work-site-lower-part {
        clear: both;
        padding-bottom: 8em;

        .grid-white-background {
            background-color: white;
        }
    }

    .work-site-buttons {
        clear: both;

        .work-site-button.work-site-button-left {
            padding-left: 7px;
        }

        button {
            width: 100%;
            padding: 10px;
            margin-top: 20px;
        }

        .work-site-button {
            padding: 0px;
            margin-bottom: 15px;
        }
    }

    .tabs-wrapper {
        ng-include {
            display: flex;
        }

        div[ng-transclude] {
            .tabs-content {
                min-height: 50px;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
                padding: 15px;
            }
        }
    }

    .text-uppercase {
        text-transform: uppercase;
    }

    .buttons-row {
        button {
            min-width: 14%;
        }
    }

    .hagrid {
        input {
            cursor: pointer;
        }

        .ag-theme-fresh .ag-group-cell-entire-row {
            background-color: #aaa;
            padding: 4px;
        }

        .sp-grid {
            height: calc(50vh + 35px);
        }
    }

    .position-absolute {
        .ag-header-cell-label {
            position: absolute;
        }
    }

    .no-border-right {
        border-right: none !important;
    }

    .work-site-wood-selling-plan,
    .work-site-forest-works {
        margin-left: -0.2em;
        margin-right: -0.2em;

        div.ag-cell:not(.checkbox-cell) {
            padding-top: 4px;
        }

        .ag-theme-fresh .ag-ltr .ag-group-expanded {
            padding-right: 10px;
        }

        .grid-container {
            margin: 5px 0px;
            border: 1px solid grey;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
        }

        .work-site-wood-selling-plan-grids {
            height: 175px;
        }

        .grid-footer-buttons {
            margin: 0px;
            padding: 10px;
            border-top: 1px solid $trimble_brand_grey;

            .button-right-margin {
                margin-right: 10px;
            }
        }

        .grid-height {
            height: 355px !important;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        #workSiteWoodSellingPlan .ag-body {

            [type="checkbox"]:not(:checked) + label:before,
            [type="checkbox"]:not(:checked) + label:after,
            [type="checkbox"]:checked + label:before {
                top: 0;
            }

            [type="checkbox"]:checked + label:after {
                top: 0px;
            }
        }
    }

    .work-site-right-panel {
        flex-grow: 1;
        margin-left: 1em;
        width: 66%;
        position: relative;

        .placeholder-container {
            .placeholder-wrapper {
                background-image: url(../../content/images/svg/map/DisabledMapIcon.svg);
                background-repeat: no-repeat;
                max-height: 655px;
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: lightgray;
                border-radius: 5px;
                border: 1px solid gray;
                text-align: center;
                vertical-align: central;
            }

            .load-button-container {
                width: 100%;
                height: 50px;
                position: absolute;
                text-align: center;
                vertical-align: central;
                top: 50%;
            }
        }

        .worksite-navigation {
            height: 49px;
            display: block;
            position: relative;
            top: -7.5px;

            ul {
                padding: 4.5px 0;
            }
        }

        .map {
            @extend %silva-box;
            height: calc(100% - 42px);
            margin: 0;
        }
    }

    [special-class="general-information-no-horizontal-scrollbar"] [role="tabpanel"].panel-collapse {
        @media screen and (max-width: 1300px) {
            overflow-x: hidden;
        }
    }

    .right-panel {
        flex-grow: 1;
        margin-left: 1em;

        .map {
            @extend %silva-box;
            height: 100%;
            margin: 0;
        }
    }

    .padding-m {
        padding: 7px 10px 10px 13px;
    }

    .padding-left-1 {
        padding-left: 1px;
    }

    .padding-xs {
        padding-left: 0px;
        padding-right: 0px;
    }

    .padding-s {
        padding-left: 7px;
        padding-right: 0px;
    }

    .left-padding {
        padding-left: 13px;
    }

    .right-padding {
        padding-right: 13px;
    }

    .padding-vertical-xs {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .horizontal-padding-s {
        padding-left: 7px;
        padding-right: 7px;
    }

    .checkbox-padding {
        padding-left: 17px !important;
        padding-top: 3px;
    }

    .date-padding {
        width: 47%;
        padding-right: 4px;
    }

    .margin-bottom-10 {
        margin-bottom: 10px;
    }

    .margin-bottom-15 {
        margin-bottom: 15px;
    }

    .margin-top-3 {
        margin-top: 3px;
    }

    .margin-top-4 {
        margin-top: 4px;
    }

    .margin-right-10 {
        margin-right: 10px;
    }

    .margin-right-15 {
        margin-right: 15px;
    }

    .no-margin-top {
        margin-top: 0px;
    }

    .top-margin {
        margin-top: 5px;
        margin-bottom: 0px !important;
    }

    .hr-margin {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .hr-margin-bottom {
        margin-top: 0px !important;
        margin-bottom: 7px !important;
    }

    .font-size-m {
        font-size: 14px;
    }

    .upper-font {
        text-transform: uppercase;
    }

    .fine-border {
        border: 1px solid #ccc;
    }

    .right-border-radius {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }

    .align-right {
        padding-left: 6px !important;
    }

    .text-align-vertically {
        padding-bottom: 5px !important;
    }

    .text-align-right {
        text-align: right;
    }

    .dd-align-right {
        padding-left: 2px !important;
    }

    .pull-left-m {
        right: 7px;
    }

    .pull-left-l {
        right: 13px;
    }

    .pull-up-m {
        bottom: 7px;
    }

    .height-l {
        min-height: 80px;
    }

    .height-s {
        height: 27px !important;
    }

    .font-grey-background {
        margin-top: -15px;
        height: 30px;
        background: #d1d1d1 0% 0% no-repeat padding-box;
    }

    .common-info {
        height: 25px;
        width: 25px;
        background-color: #337ab7;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: -4px;
        font-family: arial;
        border-radius: 4px;
        margin-right: 3px;
    }

    .common-info-title {
        color: #ffffff;
        text-align: center;
        padding-top: 3px;
        font-size: 17px;
        height: 24px;
    }

    .text-overflow {
        text-overflow: ellipsis;
    }

    &.row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .textarea-resize {
        resize: vertical !important;
    }

    .textarea {
        min-height: 90px;
        resize: vertical;
    }

    .date-format-editable {
        border-radius: 4px;
        font-size: 12px;
    }

    .date-format {
        border-radius: 4px;
        background-color: #d1d1d1;
        width: 130px;
    }

    .date-input-front-size {
        font-size: 14px;
    }

    .rounded-corner {
        border-radius: 4px;
    }

    .input-group {
        width: 100%;

        input.form-control {
            &.city {
                margin: 0px;
                width: calc(100% - 5em);
            }

            &.puukauppa-xs {
                width: 7em;
                margin: 0px;
            }

            &.puukauppa-s {
                margin: 0px;
                width: calc(100% - 7em);
            }
        }
    }

    .editable-date-format {
        border-radius: 4px;
        width: 130px;
        font-size: 12px;
        margin-left: 28px;
        padding-left: 12px;

        &[disabled] {
            cursor: not-allowed;
            background-color: $disabled-button-background-color;
        }
    }

    .registration-date {
        margin-left: 0;
        width: 100%;
    }

    .bottom-space {
        padding-bottom: 20px;
    }

    .contract-term-text-area {
        resize: none;
        margin-top: 0px;
    }

    .certificate-documents-and-settings-tab-grid,
    .payments-and-receiver-tab-grid {
        .grid-container {
            border: 0.75px solid $trimble_brand_grey;
            border-radius: 3px;
            width: auto;

            .sp-grid {
                height: 175px;

                .grid-check-box {
                    top: -8px;
                }

                .ag-root.ag-root {
                    border-radius: 3px;
                }

                .ag-header-cell .select-all-checkbox {
                    .select-all-checkbox {
                        top: -8px;
                    }
                }

                .grid-header-checkbox-label {
                    margin-bottom: 12px;
                }

                .ag-selection-checkbox {
                    display: none;
                }

                .ag-body-viewport {
                    overflow-x: hidden !important;
                }

                .cell-header-right {
                    .ag-header-cell-text {
                        float: right !important;
                    }
                }

                .ag-row-selected,
                .ag-row:hover {
                    input {
                        color: black !important;
                    }
                    
                    .date-input-for-grid {
                        color: white !important;
                    }
                }
            }

            .ag-theme-fresh .ag-ltr .ag-header-group-cell-label {
                padding-left: 5px;
            }

            .sp-grid .ag-body .ag-body-container .ag-row:hover .ag-cell {
                img.invert {
                    filter: invert(1);
                }
            }

            img.image-center {
                padding-top: 3px;
            }

            img:hover {
                filter: brightness(1);
            }

            .select-all-wrapper {
                left: 3px;
            }

            .ag-header-group-text {
                text-transform: uppercase;
            }

            .mc-link {
                color: #009AD9;
            }

            .cell-header-right {
                .ag-header-cell-text {
                    float: right !important;
                }
            }

            .cell-header-center {
                text-align: center;
            }

            .cell-header-group-left {
                .ag-header-group-text {
                    float: left !important;
                }
            }
        }

        .margin-top-0 {
            margin-top: 0px;
        }

        .padding-left-0 {
            padding-left: 0px;
        }

        .col-xs-6 {
            font-size: 12px;
        }

        .border-right {
            border-right: 0.75px solid $half_trimble_brand_grey;
        }

        .padding-top {
            padding-top: 7px;
        }

        .check-box-items {
            vertical-align: middle;
            display: inline-block;
            line-height: 34px;
            height: 20px;
        }

        .margin-top-10 {
            margin-top: 10px;
        }

        .margin-top-15 {
            margin-top: 15px;
        }

        .last-row {
            height: 38px;
            margin-bottom: -7px;
        }

        .buttons-group {
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: 0.75px solid $trimble_brand_grey;
            background: $gray 0% 0% no-repeat padding-box;
            border-bottom: 0.75px solid $trimble_brand_grey;
        }

        .margin-top-bottom {
            margin-top: 8px;
            margin-bottom: 15px;
        }

        .form-group {
            padding: 0em !important;
        }
    }

    .ag-theme-fresh {
        .ag-root {
            font-family: $font-family;
        }
    }

    .sp-grid {
        .ag-root {
            border-radius: 0px;
        }

        .ag-header-select-all-centered {
            padding-top: 3px !important;
        }
    }

    .sp-grid .ag-root .ag-header {
        font-size: 14px !important;
    }

    .sp-grid .ag-body .ag-row-group {
        padding-left: 10px !important;
        display: flex;
        align-items: center;

        &:hover {
            color: black !important;
        }
    }

    .iban {
        .iban {
            height: 27px;
            padding-left: 6px;
            border: 1px solid #ccc;
        }

        .bic {
            height: 27px;
            padding-left: 6px;
            border: 1px solid #ccc;
        }
    }

    .input-group {
        margin: 0px;

        .form-control {
            width: initial;
        }
        //These are fine with the following fixed values, as the length of the input should always be the same
        .form-control.iban {
            //width: 150px;
            height: 20px;
        }

        .form-control.bic {
            width: 100px;
        }
    }

    .print-silvi {
        .print-button {
            padding: 0 5px;
            height: 27px;
            border-radius: 0.3em;
        }

        .btn {
            .icon.icon-printer {
                background-image: url(../../content/images/svg/print_white_18dp.svg);
                height: 25px;
                width: 25px;
                background-color: transparent;
            }
        }

        .btn:hover {
            .icon.icon-printer:hover {
                background-image: url(../../content/images/svg/print_white_18dp.svg);
                height: 25px;
                width: 25px;
                background-color: #005F9E;
            }
        }

        .button-border {
            background-color: white;
            border: 1px solid darkgray;
            border-radius: 5px;
        }

        .icon {
            height: 17px;
            width: 18px;
            line-height: 30px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

@import './tabs/components/timberSaleModal';
@import './timberSalePlan';
@import '../timberSaleContract';
@import './workSiteSyncModal/workSiteSyncModal.scss';
@import './stands';
@import './timberSalePlanDivideModal';
@import './timberTrade';
@import './timberTradeSummaTotModal';
@import './workSitePrint/workSitePrint.scss';
@import './tabs/components/workForceCostModal';
@import 'tabs/forestManagementAndProductSalesTab.scss';
@import 'tabs/components/newProductRowModal.scss';
@import 'tabs/CFBCostsOfWorkSiteModal.scss';
@import 'tabs/forestryCenterTab.scss';
@import './estimateTimberSalePlan';
@import 'tabs/plotsTab.scss';
@import 'tabs/harvestingPlots.scss';
@import '../plotView/productsForPlotModal';
@import 'tabs/components/createSalesInvoiceModal.scss';
@import './workSiteAddPaymentReceiverModal/workSiteAddPaymentReceiverModal.scss';
@import 'tabs/storagesAndTradeHarvestAreasTab.scss';
@import './workSiteAccordionFullScreen/workSiteAccordionFullScreen.scss';
@import './workSiteAccordion/workSiteAccordion.scss';
@import './accordionTabs/generalInformationTab/generalInformationTab.scss';
@import './accordionTabs/timberTradeContract/timberTradeContractTab/timberTradeContractTab.scss';
@import './accordionTabs/paymentReceiversTab/paymentReceiversTab.scss';
@import './accordionTabs/forestUseDeclaration/forestUseDeclarationTab/forestUseDeclarationTab.scss';
@import './accordionTabs/forestUseDeclaration/forestUseDeclarationTabHeader/forestUseDeclarationTabHeader.scss';
@import './accordionTabs/productSalesContractTab/productSalesContractTab.scss';
@import './accordionTabs/kemeraTab/kemeraTab.scss';
@import './accordionTabs/contractTermsTab/contractTermsTab.scss';
@import './accordionTabs/invoicesTab/invoicesTab.scss';
@import './accordionTabs/measurementCertificateTab/measurementCertificateTab.scss';
@import './accordionTabs/timberTradeTab/timberTradeTab.scss';
@import './accordionTabs/productSalesTab/productSalesTab.scss';
