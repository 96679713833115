﻿
.transfer-volume-modal {
    .transfer-volume-tab {
        padding: 15px;
    }

    .tabs-wrapper div[ng-transclude] .tabs-content {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
    }

    uib-accordion {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;

        .panel-title {
            i.glyphicon {
                padding-right: .5em;
            }

            text-transform: uppercase;
        }

        .panel {
            &.panel-open > .panel-heading,
            & > .panel-heading:hover {
                background-color: $black;
                color: $white;
            }
        }

        .panel-collapse {
            .panel-body {
                padding-bottom: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        .panel-body {
            padding: 0px;
        }

        .panel-group {
            height: 100%;
            margin: 0;
            display: flex;
            flex-direction: column;

            .panel-default {
                height: 32px;
                border-radius: 0px !important;
            }

            .height-auto {
                height: auto !important;
            }
        }
    }

    .panel-header {
        //background: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;
        background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 3px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        padding: 3px 10px;
    }

    .grids-modal {
        .grid-transfer-amount-input {
            width: 100%;
            height: 25px;
            color: black;
        }

        .grid-border {
            .to-grid {
                margin-bottom: 0px;
                margin-top: 15px;
                border-top: solid gray 1px;
                border-bottom: solid gray 1px;
            }
            /* .grid-container {
           border: 1px solid grey;
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3)
        }
*/
            .grid-container {
                margin: 0px;
                border: 1px solid grey;
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                border-bottom-left-radius: 5px;
                background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3)
            }

            .sp-grid .ag-root.ag-root {
                border-radius: 0.5em;
            }

            .grid-header-checkbox-label {
                margin: 0;
                left: -4px;
                top: 2px;
            }

            .ag-floating-bottom-container {
                font-size: 12px;
                font-weight: bold;
            }
            /*.ag-cell {
                padding-top: 6px;
            }*/
            .ag-cell {
                [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                    top: 5px !important;
                }

                [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                    top: 2px !important;
                }
            }

            .transfer-amount-text {
                padding: 1px !important;
            }

            .ag-header-cell {
                padding: 2px !important;
                border-right: 1px solid darkgrey !important;
            }

            .sp-grid {
                .ag-header-cell-text, ag-header-cell-label {
                    padding-left: 5px;
                }

                .ag-floating-bottom {
                    border-top: none;
                }

                .ag-header-group-cell-with-group {
                    padding-top: 3px !important;
                }

                .cell-aligned-left {
                    .ag-header-group-cell-label {
                        text-align: left;
                        padding-left: 4px;
                        padding-top: 3px !important;
                    }
                }

                .cell-header-right {
                    .ag-header-cell-text {
                        float: right !important;
                    }
                }

                height: 210px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}

.m-10 {
    margin: 10px;
}



.content {
    margin-left: 0px;
    margin-right: 0px;
}

.measurement-ticket-grid-container {
    margin: 15px;
}

.measurement-record-buttons {
    border-top: 1px solid grey;
}

uib-accordion {
    *:focus {
        outline: none !important;
    }

    .panel-group .panel + .panel {
        margin-top: 0px;
    }

    .panel-default > .panel-heading {
        background-color: $black;
        color: $white;
        border-color: $white;
        border-radius: 0px;
        font-size: 1em;
        padding: .5em;
        min-height: initial;

        a, i.glyphicon {
            color: currentColor;
            text-decoration: none;
        }

        .panel-title {
            font-weight: bold;
            font-size: 1em;
        }
    }

    .panel-group {
        border-radius: 3px;
    }

    .panel {
        border: 0;
        min-height: 32px;
    }

    .panel-group {
        .panel-open {
            height: calc(100% - 125px);
        }

        .panel-collapse.in {
            overflow-y: auto;
        }
    }
}

.content {
    .p-15 {
        padding: 15px;
    }





    .m-15-i {
        margin: 15px !important;
    }


    .radio-cell-align {
        margin-left: 2px;
        margin-top: 5px;
    }

    .button-container {
        background: #d1d1d1 0% 0% no-repeat padding-box;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 10px;
        border-top: none;
        height: 60px;

        .btn {
            width: 15%;
            margin: 4px
        }

        .btn-undo {
            width: 30%;
            margin-left: 38%;
            padding-left: 7%;
            padding-right: 7%;
        }
    }

    .cell-aligned-center {
        text-align: center;

        .ag-header-cell-text {
            padding-left: 0;
        }
    }
}
