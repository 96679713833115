﻿// /* General SilvaPRO ag-grid styles (TODO: WHICH ARE ONLY LOADED FOR ".common-grid" (common-grid.scss)!!!) */

// @import '../../shared/sass/_variables.scss';
// /*
// [type="checkbox"]:not(:checked) + label:after,
// [type="checkbox"]:checked + label:after {
//     top: 2px;
// }
// */
// .navigation-grid {
//     height: 50vh;
//     border: 1px solid rgb(97, 97, 97);
//     border-top: none;
//     user-select: none !important;
// }

// .header-padding {
//     padding-left: 5px;
//     padding-top: 3px;
// }

// .panel-header {
//     background-color: rgb(209, 209, 209);
//     text-transform: uppercase;
//     font-family: "Myriad Pro Semibold";
//     border-bottom-left-radius: 0px;
//     border-bottom-right-radius: 0px;
//     border-top-left-radius: 5px;
//     border-top-right-radius: 5px;
// }

// .right-align {
//     text-align: right;
//     font-size: 12px;
//     background-color: white;
// }

// .ag-header-group-cell-label {
//     text-align: left;
//     border-top: 1px solid #616161;
// }

// .checkbox-margin {
//     top: 1px !important;
// }

// .common-grid-checkbox {
//   .ag-cell-value {
//     display: none; // refreshing ag-grid rows causes the cellValue to display textual value
//   }
// }

// .grey-cell-style {
//     background-color: rgb(239, 239, 239);
//     font-size: 12px;
// }

// .cell-align-left {
//     text-align: left;
// }

// .last-ui-header {
//     font-size: 12px;
//     text-transform: none !important;
//     text-align: right;
//     border-right: none;
//     background-color: rgb(209, 209, 209);
// }

// .right-aligned-header {
//     font-size: 12px;
//     text-transform: none !important;
//     text-align: right;
//     background-color: rgb(209, 209, 209);
// }

// .top-header {
//     text-anchor: start !important;
//     text-align: left !important;
//     font-size: 12px;
// }
// .panel-sp-grid {
//   [type="checkbox"]:not(:checked) + label:before,
//   [type="checkbox"]:checked + label:before {
//     content: '';
//     width: 18px;
//     height: 18px;
//     border-radius: 2px;
//     background-color: $trimble_white;
//     background-image: url(../../../content/images/svg/Checkbox_default_enabled_unselected_18x18px.svg);
//     background-size: 18px;
//     position: absolute;
//     left:0; 
//     top: 8px;
//   }

//   [type="checkbox"]:disabled:not(:checked) + label:before,
//   [type="checkbox"]:disabled:checked + label:before {
//     width: 18px ;
//     height: 18px;
//     background-image: url(../../../content/images/svg/Checkbox_default_disabled_unselected_18x18px.svg);
//     background-size: 18px ;
// }
// }

// .header-generic {
//     font-size: 12px;
//     background:$grid-header-background;
// }

// .right-align-static {
//     text-align: right;
//     font-size: 12px;
//     background-color: rgb(239, 239, 239);
// }

// .right-distance {
//     padding-right: 5px;
// }

// .grey-backgound {
//     background-color: rgb(97, 97, 97);
// }

// .header-right {
//     @extend .header-generic;
//     text-align: right;
//     padding-right: 2px;
// }

// .header-left {
//     @extend .header-generic;
//     text-align: left;
// }

// .no-right-border {
//     border-right: none;
//     padding-left: 3px;
//     padding-top: 4px;
// }

// input:not(:disabled), input[type="text"], input[type="number"] {
//     color: inherit;
//     background-color: inherit;
// }


// .sp-grid {
//   .ag-root {
//       .ag-header {
//           background: $grid-header-background;
//           background-color: $white; // grid header background color might not be white
//           font-family: $font-family;
//           font-size: 12px;
//           font-weight: bold;
//           line-height: 18px;
//       }
//   }

//   .ag-row-selected, .ag-row:hover {
//       input {
//           color: white !important;
//       }
//   }
// }
