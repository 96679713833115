﻿.refine-search-directive {
    // width: 14.3%;
    // max-width: 163.01px;

    .advanced-filtering-options {
        width: 190px;
        font-size: 14px;
        border: 1px solid $gray;
        border-radius: $sp-panel-border-radius;
        height: 34px;
        top: 0;
        background: white;

        .advanced-filtering-options-checkbox {
            margin-left: 5px;

            &:hover {
                cursor: default;
            }
        }
    }

    .advanced-filtering-options-title {
        &:hover {
            cursor: pointer;
        }
    }

    .triangle-icon {
        margin-left: 5px;
        margin-top: -12px;

        &:hover {
            cursor: pointer;
        }
    }

    .refine-search-dropdown-directive {
        overflow: visible;
        border: 1px solid $gray;
        border-radius: $sp-panel-border-radius;
        background: white;
        z-index: 1;
        top: 41px;

        .col-no-padding-left {
            padding-left: 0;
        }

        .col-no-padding-right {
            padding-right: 0;
        }

        .col-no-padding {
            padding-left: 0;
        }

        .col-no-border-right {
            border-right: 0;
        }

        .col-padding-right-5 {
            padding-right: 5px;
        }

        .settings-item.multiselect-margins-refine-search {
           // margin-top: 23px;
           // margin-left: 1.06em;

            .multiselection-drop-down {
                .checkboxes {
                    width: 236px;
                }
            }
        }

        .filter-date-field {
            height: 76px;
        }

        .filter-date-field input {
            width: 125px;
        }
    }

    .search {
        max-width: 190px;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
        margin-left: 0px;
    }

    .no-left-margin {
        margin-left: 0px;
    }

    .row-no-margin {
        margin-right: 0;
        margin-left: 0;
        height: 100%;
    }

    .row-border {
        border-right: 1px solid $gray;
    }

    .button-extended {
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .full-height {
        height: 100%;
    }

    .search-button {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
        width: 70px;
    }

    .container-directive {
        float: left;
        width: 100%;
    }

    .top-margin {
        margin-top: -5px;
    }

    .directive-margin {
        // margin: 0em 0.5em 0.5em 0.5em;
        margin-left: 1.06em;
    }

    .position-relative {
        position: relative;
    }

    .margin-left {
        margin-left: 5px;
    }

    .position {
        position: absolute;
        z-index: 2;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        -moz-box-shadow: 0 0 0 100px #fff inset;
        box-shadow: 0 0 0 100px #fff inset;       
    }
    
}
