.annual-announcement-print-modal {
    .modal-dialog {
        margin-top: 15%;

        .top-margin {
            margin-top: 10px;
        }

        .no-padding-right {
            padding-right: 0;
        }

        .annual-announcement-print-modal {
            padding: 0;
        }
    }
}