﻿@import '../../../../shared/sass/_variables.scss';

.timber-trade-summa-tot-modal {

    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .execute-button {
        margin-left: 5px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }

        .close-div {
            padding-left: 95% !important;
            ;
            position: absolute;
        }
    }

    .current-wood-assortment-row {
        background-color: #d3d3d3;
        margin: 0;
        font-family: "Myriad Pro Semibold";

        .col-xs-12 {
            > div {
                height: 3em;
                padding-top: 0.9em;
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
        }
    }

    .grid-container {
        margin: 1em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
            height: 300px;

            #centerRow {
                border-radius: 0.5em !important;
            }


            .align-left {
                text-align: left !important;
            }
        }

        .ag-header-group-cell-label {
            text-align: inherit !important;
        }

        .ag-cell {
            border-right: 1px solid darkgray;
        }

        .grid-input {
            text-align: right !important;
            border: 0;
            width: 100%;
            height: 100%;
        }

        .padding-right {
            padding-right: 3px;
        }

        .ag-body {
            .ag-row-odd {
                background-color: #fff;
            }

            .ag-row-hover {
                background-color: inherit;
            }
        }

        .ag-floating-bottom-full-width-container {
            border-top: 1px solid $darkgray;
        }

        .ag-floating-bottom-viewport {
            .ag-floating-bottom-container {
                width: 100% !important;

                :first-child .ag-cell {
                    overflow: visible;
                }

                .ag-row {
                    background-image: linear-gradient(rgba(0, 0, 0, 0.0745098), rgb(211, 211, 211));
                }

                .ag-cell {
                    border: none !important;
                    background-image: none;
                    padding-right: 3px;
                    padding-top: 4px;
                    font-size: 12px;
                    font-weight: bold;
                }
            }
        }

        .no-bottom-border {
            border-bottom: none;
        }

        .no-top-padding {
            .ag-header-cell-label {
                padding-top: 0;
            }
        }

        .padding-left {
            .ag-header-group-cell-label {
                padding-left: 4px !important;
            }
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .form-group {
        padding: 0;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }


    .ag-header-container {
        border-right: none;
        background: linear-gradient(rgba(0,0,0,0.075), #d3d3d3);
    }

    .modal-dialog {
        position: relative;
        width: 80% !important;
    }

    .editable-text {
        input, select, textarea {
            color: black;
        }
    }

    .radio-padding {
        padding-left: 0;
        padding-right: 5px;
    }

    .radio-inline {
        margin: -2px 0 0;
    }

    .trunks-container {
        margin: 15px;
        border: 1px solid #616161;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        height: 111px;

        .trunks-row {
            height: 27px;
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 0px;
            font-size: 14px;
            background-color: #f6f6f6;
            line-height: 2.4;
        }

        .radius-top {
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
        }

        .radius-bottom {
            border-bottom-left-radius: 0.5em;
            border-bottom-right-radius: 0.5em;
        }

        .title-row {
            background-image: linear-gradient(rgba(0, 0, 0, 0.0745098), rgb(211, 211, 211));
            font-weight: bold;
            font-size: 12px;
        }

        .input-truncks {
            border: 0px;
            width: 100%;
            height: 100%;
            text-align: right;
        }

        .cell-padding {
            padding-left: 3px;
            padding-right: 3px;
        }

        .border-header {
            border-right: 1px solid gray;
        }

        .border-row {
            height: 100%;
            border-right: 1px solid #d1d1d1 !important;
        }
    }

    .align-right {
        text-align: right !important;
        text-transform: none !important;
    }

    .no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
        -moz-appearance: textfield !important;
    }
}
