﻿@import '../../shared/sass/_variables.scss';

.display-table {
    display: table !important;
}

.favourit-grid-configs {
    .icon-button > .btn, .settings-item > .btn {
        background-color: $trimble_white;
    }

    .favorite-list-button {
        margin-right: 5px;
        margin-left: 5px;
        display: block;

        &:focus {
            outline: none;
        }
    }

    .icon {
        height: 17px;
        width: 18px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    .icon-favorite {
        background-image: url(../../../content/images/svg/favorite.svg);

        &:hover, &:focus, &.open {
            background-image: url(../../../content/images/svg/favorite-green.svg);
        }
    }

    .icon-info {
        background-image: url(../../../content/images/svg/iTool_Round_WhiteBackground.svg);
        margin-right: 0px;
        margin-top: -3px;

        &:hover {
            background-image: url(../../../content/images/svg/iTool_Round_BlackBackground.svg);
        }
    }

    .dropdown-menu {
        max-height: 300px;
        overflow-y: auto;

        .dropdown-subheader {
            white-space: nowrap;
            text-transform: uppercase;
            font-size: 0.8em;
            margin: 0.5em;
            font-weight: bold;
        }
    }

    .saved-lists-dropdown {
        max-width: 200px;

        > li > a {
            position: relative;
            white-space: initial;
            cursor: initial;
        }

        .favoritelist-name {
            max-width: calc(100% - 20px);
            cursor: pointer;
        }

        .favoritelist-remove {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 12px;
            font-size: 12px;
            color: inherit;
            width: 20px;
            cursor: pointer;
        }

        &.site-expanded {
            left: -22px;
        }
    }

    margin-top: 0px;
}
