.gps-tracking {
	&.panel {
		position: absolute;
		right: 3em;
		top: calc(250px + 0.7em); // position below index map
		background-color: $white;
		border-radius: 5px;
		border: 1px solid $darkgray;
		width: 300px;
		max-height: calc(100% - 250px - 0.8em);
		display: flex;
		flex-direction: column;
		overflow: hidden;

		.top-button-row {
			height: 3.4em;
			display: flex;
			align-items: center;

			.dual-button {
				display: flex;
				align-items: center;
				flex-grow: 1;
				margin: 0.2em;
				height: 3em;

				.gps-tracking-button {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-grow: 1;
					height: 3em;
					color: $darkgray;
					border: 1px solid $darkgray;
					background-color: $white;
					border-radius: 0;

					.locate-icon {
						height: 2em;
						width: 2em;
						background-image: url("/content/images/svg/map/MapTag.svg");
						background-size: contain;
						background-repeat: no-repeat;
					}
				}

				.close-button {
					cursor: pointer;
					margin: 0 0.5em;
				}
			}
		}

		.current-gps-location {
			background-color: $lightgray;
			border-bottom: 1px solid $gray;
			border-top: 1px solid $gray;
			padding: 1em 0.5em;
			text-transform: uppercase;
		}

		.saved-gps-features {
			display: flex;
			flex-direction: column;
			overflow: hidden;

			.header {
				position: relative;
				text-transform: uppercase;
				padding: 1em 0.5em;
				border-bottom: 1px solid $gray;

			}

			.list {
				overflow-y: auto;
				flex-grow: 1;
				margin: 0 0.5em;

				.item-row {
					display: flex;
					margin-bottom: 0.2em;

					.checkbox {
						display: flex;
						margin: 0;
						margin-right: 0.5em;
						align-items: center;

						[type="checkbox"]:not(:checked)+label:before, [type="checkbox"]:checked+label:before {
							top: 0;
						}

						[type="checkbox"]:not(:checked)+label:after, [type="checkbox"]:checked+label:after {
							top: 3px;
						}
					}

					.picture {
						width: 1em;
						height: 1em;
						margin-right: 0.5em;
						display: flex;
						align-items: center;
					}

					.date {
						display: flex;
						align-items: center;
						margin-right: 0.5em;
					}

					.name {
						flex-grow: 1;
						display: flex;
						align-items: center;
					}

					.delete-button {
						display: flex;
						align-items: center;
					}
				}
			}

			.operation-buttons {
				padding: 0.5em;
				border-top: 1px solid $gray;
				margin-top: 0.5em;
				display: flex;

				button {
					flex-grow: 1;

					&:first-child {
						margin-right: 0.25em;
					}

					&:last-child {
						margin-left: 0.25em;
					}
				}
			}

			.opener {
				display: flex;
				align-items: center;
				min-height: 2em;
				background-color: $lightgray;

				div {
					text-align: center;
					width: 100%;
				}

				.turn-up {
					transform: rotate(-90deg);
				}

				.turn-down {
					transform: rotate(90deg);
				}	
			}
		}
	}

	&.modal {
		.modal-dialog {
			width: 400px; // lets make the modal a tad bit smaller
		}

		.modal-header {
			display: flex;

			div:first-child {
				flex-grow: 1;
			}
		}

		.modal-footer {
			.footer-dual-button {
				display: flex;
				margin-bottom: 1em;

				button {
					flex-grow: 1;

					&:first-child {
						margin-right: 0.5em;
					}

					&:last-child {
						margin-left: 0.5em;
					}
				}
			}

			.footer-full-button {

				button {
					width: 100%;
				}
			}
		}

		&.gps-route-modal, &.gps-point-modal {
			.route-information, .point-information {
				display: flex;
				height: 2em;
			}

			.route-form, .point-form {
				ul {
					list-style-type: none;
					padding: 0;
					margin: 0;

					li > label {
						display: flex;
						align-items: center;
						margin-bottom: 0.5em;

                    	input[type="radio"] {
	                        margin-top: 0;
                        	flex-basis: 2em;
                    	}

						.logo {
                            margin-right: 0.5em;
                            width: 4em;
                            position: relative;
                        }

                        div.logo {
                            display: flex;
                            align-items: center;
                            height: 1em;
                            overflow: hidden;
                            margin: 0;
                            margin-right: 0.5em;

                            .free-line {
                                width: 100%;
                                height: 0;
                                margin: 0;
                                padding: 0;
                                display: inline;
                            }
                        }

                        .icon {
                        	margin-right: 0.5em;
                        }
					}

					ul { // the inner list
						margin-left: 2em;

						.custom-line-options {
							margin-left: 2em;

							div {
								display: flex;
								align-items: center;
								margin-bottom: 0.5em;

								label {
									margin-right: 1em;
									width: 50%;
								}

								select, input {
									flex-grow: 1;
								}
							}
						}
					}
				}

				.additional-information-block {
					display: flex;
					flex-direction: column;

					label {
						width: 100%;
					}

					textarea {
						height: 3em;
						margin-top: 0;
					}
				}
			}
		}

		&.gps-point-modal {
			.point-information {

			}
		}
	}
}