.forest-use-declaration-accordion {
    .flex-wrapper {
        display: flex;

        &-column {
            flex-direction: column;
        }

        &-row {
            flex-direction: row;
            align-items: center;

            select {
                width: 14em;
            }
        }

        label {
            margin-bottom: 0;
        }
    }

    .notification-of-forest-use {
        input {
            width: 100%
        }
    }

    .checkboxes {
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before,
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
            top: 6px;
        }
    }
}
