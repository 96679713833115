﻿@import '../../shared/sass/_variables.scss';

.grid-setting-storage {
    .icon-button > .btn, .settings-item > .btn {
        background-color: $trimble_white;
    }

    .favorite-list-button {
        display: block;

        &:focus {
            outline: none;
        }
    }

    .icon {
        height: 17px;
        width: 18px;
        line-height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    .icon-favorite {
        background-image: url(../../../content/images/svg/favorite.svg);
        background-color: $trimble_white;

        &:hover, &:focus, &.open {
            background-image: url(../../../content/images/svg/favorite-green.svg);
            background-color: $trimble_white;
        }
    }

    .icon-info {
        background-image: url(../../../content/images/svg/iTool_Round_WhiteBackground.svg);
        margin-right: 0px;
        margin-top: -3px;

        &:hover {
            background-image: url(../../../content/images/svg/iTool_Round_BlackBackground.svg);
        }
    }

    .dropdown-menu {
        width: 440px;
        border-color: $darkgray !important;
        min-height: 215px;
        border-radius: 7px;
        left: -380px;
        display: block;
        overflow: hidden;

        .dropdown-subheader {
            white-space: nowrap;
            text-transform: uppercase;
            font-size: 0.8em;
            margin: 0.5em;
            font-weight: bold;
        }

        .favorites-header-text {
            width: 260px;
        }

        .favorites-header {
            margin-left: 10px;
            margin-right: 5px;
            margin-top: -7px;
            padding-top: 0px;
            top: 0px;
            font-style: normal;
            line-height: 2.39em;

            .favorites-image {
                width: 17px;
                height: 17px;
                //padding-left: 5px;
            }
        }
    }

    .saved-lists-dropdown-storage {
        padding: 8px 0 0 0;

        > li > a {
            position: relative;
            white-space: initial;
            cursor: initial;
            padding: 3px 20px 3px 5px;
        }

        .favorite-radio-inline {
            padding-left: 8px;
        }

        .favorites-close-button {
            padding-right: 5px;
        }

        .select-filter-store-wrrapper {
            display: inline-block;
            width: calc(100% - 20px);
            cursor: pointer;

            label {
                vertical-align: middle;

                input, span {
                    vertical-align: middle;
                }
            }
        }

        .favoritelist-name {
            max-width: calc(100% - 20px);
            cursor: pointer;
        }

        .favoritelist-remove {
            position: absolute;
            right: 0;
            top: -1px;
            bottom: 0;
            margin: auto;
            margin-right: 10px;
            height: 17px;
            font-size: 12px;
            color: inherit;
            width: 15px;
            cursor: pointer;
            background-image: url(/content/images/svg/map/TrashCan.svg);
            background-repeat: no-repeat;

            &:hover,
            &:focus {
                background-image: url(/content/images/svg/map/TrashCan_MouseOver.svg);
            }
        }

        .favoritelist-warning, .favoritelist-savechanged {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            margin-right: 30px;
            height: 16px;
            font-size: 12px;
            color: inherit;
            width: 18px;
            cursor: pointer;
            background-repeat: no-repeat;
        }

        .favoritelist-warning {
            background-image: url(/content/images/svg/map/GearWheel_Black.svg);

            &:hover, &:focus {
                background-image: url(/content/images/svg/map/GearWheel_Blue.svg);
            }
        }

        .favoritelist-savechanged {
            background-image: url(/content/images/svg/map/Diskette_Black.svg);

            &:hover, &:focus {
                background-image: url(/content/images/svg/map/Diskette_Blue.svg);
            }
        }

        &.site-expanded {
            left: -22px;
        }

        &.guide {
            vertical-align: middle;
            padding: 0px;
            align-content: center;
            padding: 1.0em;
            background-color: $gray;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
        }

        .favorites-guide {
            font-size: small;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
            margin-top: -1px;
            line-height: 18px;
        }

        .popup-footer-wrapper {
            position: absolute;
            bottom: 0px;
            width: 100%;
            border-top: 1px solid #616161;
            font-style: normal;
            margin: 0;
            padding: 10px;

            .favorite-list-button {
                width: 48%;

                &:focus {
                    outline: none;
                }
            }

            :first-child {
                float: left;
                padding: 10px 20px 10px 10px;
            }

            :last-child {
                float: right;
                padding: 10px 20px 10px 10px;
            }
        }

        .settings-row {
            margin: 0px;
            padding: 0px;
            line-height: 50px !important;
            vertical-align: central;
            margin-bottom: auto;
            margin-top: auto;
        }

        .settings-row-last {
            padding-bottom: 50px;
        }

        .no-saved-favorites {
            text-align: center;
            text-transform: uppercase;
            font-style: italic;
            color: $gray;
        }

        .last-row {
            padding-bottom: 10px;
        }
    }

    margin-top: 0px;

    .favorite-info-placeholder {
        display: inline-block;
        position: relative;
        top: 7px;
        margin-right: 5px;
        width: 17px;
        height: 17px;
        cursor: pointer;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/content/images/svg/map/iTool_Round_WhiteBackground.svg);

        &:hover {
            background-image: url(/content/images/svg/map/iTool_Round_BlackBackground.svg);
        }
    }
}
