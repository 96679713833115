﻿.attachment-dialog-window-class {
    .modal-dialog {
        top: 28%;
        width: 800px;
        height: 462px;
    }
}

.attachment-error-dialog {
    z-index: 2000 !important;

    .formatted-message-container {
        font-family: "Myriad Pro", "Verdana";
        margin-left: 30px;
    }

    .text-bold {
        font-weight: 700;
    }

    .error {
        color: black;
    }

    .transform-none {
        text-transform: none !important;
    }

    .message-body{
        display:block;
    }
}

.attachment-error-dialog-btn-class {
    margin: 0px !important;
    margin-top: 15px !important;
}

.attach-file-dialog {
    .attachment-vertical-line {
        border-left: 0.5px solid #B0AFB0;
        height: 30px;
        position: absolute;
        left: 10%;
        margin-left: -3px;
        top: 0;
        padding-left: 15px;
    }

    .attach-file-content {
        padding: 40px;
        padding-right: 0px;
    }

    .file-upload-container {
        margin-bottom: 20px;
        display: flex;

        .file-upload-element {
            margin-left: 2.9em
        }
    }

    .notes-element {
        width: 103%;
        height: 5em;
        margin-top: 0px;
        resize: none;
        max-height: 300px;
        overflow-x: auto;
    }

    .file-type-container {
        margin-top: 10px;
    }

    .attachment-dialog-window-class1 {
        /*width: 530px !important;*/
        left: calc(50% - 265px);

        .modal-dialog {
            .modal-content {
                min-width: 500px !important;
                width: 800px !important;
            }
        }
    }

    .file-upload-directive input.form-control {
        height: 30px !important;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        border: 0.5px solid #B0AFB0 !important;
        width: 112% !important;
        margin-left: -4px;
    }

    .input-group-btn {
        visibility: hidden;
    }

    .file-upload-directive button {
        height: 30px !important;
        /*            border-bottom-right-radius: 0px !important;
            border-top-right-radius: 0px !important;*/
        border: 1px solid #B0AFB0 !important;
    }

    .file-upload-directive .btn-upload {
        border: none !important;
        border: 0px !important;
    }

    .upload-file-control .file-upload-directive .input-group .input-group-btn button.btn[disabled] {
        border: 1px solid #616161 !important;
    }

    .upload-file-control .file-upload-directive .input-group .input-group-btn button.btn[disabled] {
        border: 1px solid #616161 !important;
        border-bottom-right-radius: 5px !important;
        border-top-right-radius: 5px !important;
    }

    a[disabled] {
        pointer-events: none;
        opacity: 0.5;
    }

    .file-upload-disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}
