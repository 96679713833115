﻿@import '../../../../../shared/sass/_variables.scss';

.calculate-cost-dialog-window-class {
    overflow: auto;

    .modal-dialog {
        top: -5%;
        width: 800px;
        height: 462px !important;
    }
}

.modal-description {
    background-color: #E2E2E7;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.all-cost-calculation-modal {
    .all-cost-calculation-modal-content {
        height: auto;
        overflow: auto;

        .top-bottom-border {
            border-top: 0.5px solid #B0AFB0;
            border-bottom: 0.5px solid #B0AFB0;
        }

        .step {
            .step-marker {
                padding-top: 20px;
                display: flex;
            }

            .desc {
                padding-top: 6px;
                padding-left: 10px;
                padding-right: 40px;
            }

            .step-content {
                padding-left: 40px;
                padding-right: 40px;
                padding-bottom: 20px;

                .search-and-icon {
                    display: flex;

                    .search {
                        width: 80%;
                        padding-top: 20px;
                    }

                    .icon {
                        width: 20%;
                        padding-left: 15px;
                    }
                }

                .radio-buttons {
                    form {
                        display: flex;
                    }

                    .radio-button {
                        margin-top: 8px;
                        padding-top: 8px
                    }
                }

                .cell-aligned-left {
                    .ag-header-group-cell-label {
                        text-align: left;
                        padding-left: 4px;
                        padding-top: 6px;
                    }
                }

                .radio-cell-align {
                    margin-left: 4px;
                    margin-top: 7px;
                }

                .pull-top {
                    .grid-container {
                        margin-top: 10px !important;
                    }
                }

                .grid {
                    .grid-container {
                        margin: 15px 0px 0px 0px;
                        border: 1px solid grey;
                        border-top-right-radius: 5px;
                        border-top-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 5px;
                        background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);

                        .sp-grid .ag-floating-bottom-container .ag-cell {
                            background-color: #d1d1d1;
                            padding-top: 6px;
                        }

                        .ag-header-cell .select-all-checkbox {
                            .select-all-checkbox {
                                left: 4px;
                            }
                        }

                        .select-all-wrapper {
                            left: 3px;
                            top: -2px;
                        }

                        .sp-grid .ag-root.ag-root {
                            border-radius: 4px !important;
                        }

                        .sp-grid {
                            height: 240px;

                            .ag-header-select-all {
                                padding: 3px 0 0 3px;
                            }
                        }
                        /*.delete-button {
                    width: 12em;
                    margin-left: 9em;
                }*/
                    }

                    .ag-theme-fresh .ag-header-cell-label {
                        padding: 6px 2px;
                    }

                    .ag-theme-fresh .ag-header-cell-menu-button {
                        margin-top: 8px;
                        line-height: 1rem;
                    }

                    .grid-header-checkbox-label {
                        margin: 0;
                        left: -4px;
                        top: 2px;
                    }

                    .ag-floating-bottom-container {
                        font-size: 12px;
                        font-weight: bold;
                    }

                    .ag-cell {
                        .ag-selection-checkbox {
                            padding-left: 2px;
                            padding-top: 4px;
                        }

                        [type="checkbox"]:not(:checked) + label::after, [type="checkbox"]:checked + label::after {
                            top: 5px !important;
                        }

                        [type="checkbox"]:not(:checked) + label::before, [type="checkbox"]:checked + label::before {
                            top: 2px !important;
                        }
                    }

                    .cell-align-center {
                        text-align: center;
                    }

                    .text-cell {
                        padding-top: 5px;
                    }

                    .selection-cell {
                        padding-top: 4px;
                    }
                }
            }
        }

        .step_desc {
            height: 30px;
            width: 30px;
            background-color: #005f9e;
            color: white;
            text-align: right;
            padding-top: 6px;
            padding-right: 5px;
        }

        .harvesting-cost-search-bar {
            border: 1px solid $gray;
            border-radius: 0.5em;
            height: 2em;
            line-height: 2em;
            overflow: hidden;
            /*   margin-top: 20px;
    margin-bottom: 20px;*/
            input {
                border: 0;
                height: 2em;
                outline: 0;
                padding-left: 0.3em;
                width: calc(100% - 50px);
            }

            span.glyphicon-remove {
                margin-right: 0px;
                margin-left: 5px;
            }

            span.glyphicon {
                margin-right: 0px;
                margin-left: 5px;
            }
        }
    }

    .button-container {
        padding-top: 15px;
        padding-right: 10px;
        padding-left: 10px;

        .btn-silva {
            margin-bottom: 15px;
        }
    }
}
