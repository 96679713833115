
.follow-up-full-screen .container {
  width: 100%;
}

.follow-up {
  margin-top: 11px;

  .flat-btn {
    width: 120px;
    padding: 2px;
  }
  
  .groupHeaderText {
    font-weight: bold;
  }

  .panel {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    margin: 0;
    
    .panel-header {
      text-transform: uppercase;
    }
    
    .panel-value {
      font-weight: initial;
    }
    
    .top-bar {
      display: inline-flex;
      background: $gray;
      border-bottom: 0;
      padding: 0;
      width: 100%;
      
      input {
          background: inherit;
      }
      
      .panel-header-container {
        display: inline-block;
        flex-grow: 1;
        padding: 5px;
        &:not(:last-child) {
          border-right: $grid-border;
        }
        
        .panel-header {
          //text-transform: uppercase;
        }
        
        .panel-value {
           display: inherit;
           padding-right: 3px;
           
           input {
             padding-left: 0;
           }
        }
      }
    }
    
    .blue-bar {
      background: $darkblue;
      border-radius: 0;
      border-top: $grid-border;
      
      div {
        display: inline-block;
        
        &.panel-header {
          margin-right: 0.5em;
        }
        
        &.panel-value {
          margin-right: 1em;
        }
      }
    }
    
    .black-bar {
      background: $black;
      border-radius: 0;
      
      div {
        display: inline-block;
        
        &.month-button, &.week-button, &.day-button {
          padding-right: 6px;

          button {
            margin-top: -3px;
            height: 27px;
          }
        }
        
        .btn-blue {
          background-color: $blue;
        }
        
        .bars-button {
          background: url("/content/images/svg/Chart_ButtonSymbol_Grey.svg") no-repeat center;
          background-color: $white;
          margin-right: 6px;
          
          &:hover:not(.disabled),
          &.bars-green {
            background: url("/content/images/svg/Chart_ButtonSymbol_LimeGreen.svg") no-repeat center;
            background-color: $white;
          }
        }
      }
    }
  } // panel
  
  .sp-grid-container {
    margin: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0;
    
    .sp-grid #centerRow {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    /* this is for overriding manually sp-grid hover colors */
    .sp-grid .ag-body .ag-body-container .ag-row:hover {
      .ag-cell {
        background-color: $white !important;
        color: $black !important;

        &.gray-cell {
          background: $lightgray !important;
        }
      }

      &.light-gray-row {
        .ag-cell {
          background: $lightgray !important;
        }
      }
    }
      
    .followup-grid {
      font-size: 12px;

      .ag-scrolls {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
      
      .ag-row-group {
        font-size: 12px;
        cursor: pointer;
        
        .ag-group-contracted, .ag-group-expanded {
          vertical-align: text-top;
          line-height: initial;
        }
        
        .ag-group-value {
          vertical-align: middle;
        }
      }
      
      .ag-body-viewport { // remove that nasty scroll-to-bottom empty space
        //height: calc(100% + 5px);
      }
      
      .gray-bottom {
        border-bottom: 1px solid $gray;
      }
      
      .dark-gray-row {
        background: $gray;
      }
      
      .light-gray-row {
        background: $lightgray;
      }
      
      .white-row {
        background: $white;
      }
      
      .data-cell {
        text-align: right;
        line-height: 18px;
        padding-left: 1px;
      }

      .ag-cell {
        overflow: inherit;    // for the validation popup
        
        input {
          color: $black;
          text-align: right;
        }

        .validation-cell {
          .popover {
            z-index: 1;
          }
        }

        .validation-cell input {
          background-color: $white;
          padding-bottom: 0px;
          padding-right: 1px;
          border: 1px solid $gray;
          border-radius: 3px;
          margin-top: -1px;

          &:disabled {
            background: #d1d1d1;
          }
        }
        .disable-cell {
              background-color: $gray;
          pointer-events: none;
        }
        .percentage-input-day{
          width: 100px;
        }
        
        .percentage-input-month{
          width: 50px;
        }
        .percentage-right {
          display: inline-block;
          padding-right: 0px;
          margin-right: 6px;
        }
      }
      
      .ag-header-cell {
        .navi-button {
          width: 20px;
          height: 35px;
          margin: 2px;
          background: white;
          cursor: pointer;
          border-radius: 3px;
          border: 1px solid $gray;
          display: inline-block;
		      padding-top: 7px;
          
          .glyphicon-play {
            left: 2px;
            top: 2px;
            &.right {
              left: 4px;
            }
            &.reverse {
              transform: rotate(180deg);
            }
          }
        }
        
        .content {
          width: calc(100% - 48px);
		      padding-top: 10px;
          display: inline-block;
          text-align: center;
		      pointer-events: none;
        }
          
        &.header-cell {
          text-align: center;
          
          .ag-header-cell-label {
            padding: 3px 2px 4px 2px;
			      height: 45px;
          }
        }
      }
      
      .floating-borders {
        border-top: $grid-border;
        border-right: $grid-border;
      }
      
      .blue-cell {
        background: $darkblue !important; // important for the hover override
      }
      
      .darkblue-cell {
        background: #7e97b5 !important; // important for the hover override
      }

      .gray-cell {
        background: $lightgray !important;
      }
      
      .blue-text {
        color: $darkblue;
      }

      .dark-blue-text {
        color: $blue;
      }
      
      .orange-text {
        color: $orange;
      }
      
      .total-amount-cell {
        border: 0;
        padding: 0;
        overflow: visible; // for the "E/S/T" sign
      }
      
      .total-amount-container {
        font-size: 12px;
        
        .data-area {
          display: inline-block;
          padding: 0 1px 0 3px;
          width: calc(100% - 65px);
        
          span:first-child {
            text-align: left;
          }
          span:nth-child(2) {
            text-align: center;
          }
          span:last-child {
            text-align: right;
          }
        }
        .units {
          display: inline-flex; // inline-block doesn't work right
          width: 65px;
          height: 25px;
          
          div {
            display: inline-block;
            padding: 4px 3px 5px 2px;
            text-align: center;
            width: 50%;
            
            &.first {
              position: relative;
              font-weight: bold;
              border-left: $grid-border;
              border-right: $grid-border;
              
              span {  // for the "E/S/T" sign
                position: absolute;
                bottom: -8px;
                left: 12px;
                z-index: 100;
              }
            }
            &.third {
              border-left: $grid-border;
              border-right: $grid-border;
            }
            &.fourth {
              border-top: solid 1px $gray;
            }
          }
        }
        
        &.top {
          .data-area {
            font-weight: bold;
            padding: 0 3px 0 3px;
            position: relative;
            bottom: 5px;
          }
        }
        
        &.bottom {
          .data-area {
            span {
              display: inline-block;
              width: 33%;
            }
          }
        }
      }
      
      .ag-floating-bottom-viewport {
        background: $gray;
        
        .ag-floating-bottom-container {
          div {
            display: inline-block;
            overflow: hidden;
          }
          
          .first-floating-cell {
            padding: 4px 8px;
            border-right: $grid-border;
            border-top: $grid-border;
            vertical-align: top;
            width: 22px;
            background: $lightgray;
          }
          
          .second-floating-cell {
            border-right: 1px solid #808080;
            border-top: $grid-border;
            padding: 2px 3px;
            text-align: right;
            width: calc(100% - 22px);
            background: $lightgray;
            div {
              border-top: 0;
              line-height: 20px;
              width: 33%;
              padding-right: 5px;
            }
          }
        }
      }
      
    } // follow-up-grid
    
    .grid-buttons {
      overflow: auto;
      
      .btn-container {
        display: inline-block;
        vertical-align: sub;
        
        span {
          vertical-align: sub;
        }
        
        .radio-title {
          font-weight: bold;
          font-size: 12px;
          margin-right: 1em;
        }
        
        input[type=radio] {
          cursor: pointer;
          vertical-align: sub;
        }
      }
    }
  } // sp-grid-container

  .group-row {
    .pen-icon {
      display: inline-block;
      height: 16px;
      width: 16px;
      background-size: 16px;
      vertical-align: middle;
    }
  }
  
}

.graphs-container {
  margin-top: 1em;
  min-height: 255px;
  
  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: $gray;
    z-index: -1;
  }
  
  .control-panel {
    display: inline-block;
    margin: 1em 0;
    width: 100%;
    
    .unit-toggle {
      float: left;
      
      div {
        background-color: $white;
        border: 1px solid $lightgray;
        cursor: pointer;
        float: left;
        line-height: 32px;
        text-align: center;
        width: 34px;
        height: 34px;
        margin: 1px 0;
        
        &.black-bg {
          background-color: $black;
          color: $white;
        }
      }
    }
    
    .multiselection-drop-down {
      line-height: 34px;
      margin-left: 1em;
    
      .titleBox {
        background-color: $white;
        
        .titleBoxText {
          margin-left: 10px;
        }
      }
      
      .checkboxes {
        position: absolute; // for some reason its "fixed", needs to go "absolute" when dropdown clicked
      }
    }
    
    .product-dropdown {
      float: left
    }

    .multiselection-tools {
		.titleBox {
			height: 36px;
		}
	}
    
    .timings-dropdown {
      float: left
    }
    
    .legend {
      float: right;
      
      .item {
        float: left;
        line-height: 17px;
        margin-left: 1em;
        
        .box {
          float: left;
          width: 17px;
          height: 17px;
          margin-right: 0.5em;
        }
        
        .light-blue {
          background-color: $darkblue;
        }
        
        .dark-blue {
          background-color: $blue;
        }
        
        .planning-icon {
          background: url("/content/images/svg/Chart_Definition_PlannedDeliveryContracts.svg") no-repeat center;
        }
      }
    }
  }
  
  .charts {
    width: 100%;
    position: relative;
    
    div {
      height: 200px; // height for each chart
      margin-bottom: 1em;
    }
  }
}

