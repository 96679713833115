﻿@import '../../../../../shared/sass/_variables.scss';

.worksite-print-container {
    $font-family: $font-primary, $font-secondary;
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-family: $font-primary,$font-secondary;
    font-size: 12px;
    padding-right: 0px;
    margin-top: 0px;
    border: 1px solid rgb(97,97,97);
    border-radius: 5px;

    .worksite-print-header {
        text-transform: uppercase;

        .first-row {
            background: $gray;
            font-family: "Myriad Pro Semibold";
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding-top: 4px;
            padding-bottom: 3px;
            padding-left: 6px;
            padding-right: 6px;

            .customer-name {
                text-transform: none;
            }
        }

        .second-row {
            background-color: $black;

            .input-group {
                padding-bottom: 7px;
                padding-top: 7px;
            }

            .date-container {
                padding-left: 6px;
                margin-top: 9px;
                margin-bottom: 9px;

                .form-group {
                    padding: 0;

                    .form-control {
                        background-color: $gray;
                        padding-top: 0.5em;
                        padding-left: 0.5em;
                        height: 32px;
                        width: 101%;
                        font-size: 13px;

                        &:focus {
                            border: 1px solid $gray;
                            box-shadow: none;
                        }
                    }

                    .form-control-feedback {
                        width: 36px;
                        height: 32px;
                        right: 3px;
                        border-left: solid 1px $black;
                        padding-left: 3px;
                    }

                    img {
                        width: 26px;
                        height: 24px;
                        padding-bottom: 2px;
                    }
                }
            }
        }
    }

    .worksite-print-content {
        border-top: 1.5px solid $gray;
        background-color: $white;
        display: flex;

        .col-xs-6 {
            flex: 1;
        }

        .center-content {
            height: 35px;
            line-height: 27px;
        }

        .group-header {
            padding-left: 6px;
            padding-top: 4px;
            padding-bottom: 3px;
            background-color: $black;
            color: $white;

            &:after {
                border-top: 10px solid #000;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                bottom: 0px;
                content: "";
                position: relative;
                left: 5px;
                top: 4px;
                width: 0;
                height: 0;
            }
        }

        [type="checkbox"] + label {
            margin-left: 9px;
            margin-bottom: 0px;
            padding-top: 9px;
            padding-left: 39px;
            padding-bottom: 8px;
            font-weight: 600;
        }

        .checkbox-border {
            position: absolute;
            padding-left: 32px;
            width: 39px;

            &.first {
                height: 44px;
            }

            &.middle {
                height: 36px;

                &.accordion {
                    background-color: $lightgray;
                }
            }

            &.last {
                height: 35px;
            }
        }

        .first-item {
            margin-top: 8px;
        }

        .row.disabled-header {
            background-color: $lightgray;
        }

        .first-item-accordion-worksiteprint {
            padding-top: 12px;
        }

        .middle-item-accordion-worksiteprint {
            padding-top: 4px;
        }

        .accordion-header-left {
            margin-left: 6px;

            .accordion-header-text {
                padding-left: 22px;
            }

            .triangle-right-border {
                position: absolute;
                padding-left: 32px;
                width: 39px;
                margin-left: -12px;
                margin-top: -9px;
                height: 36px;
                border-right: 1px solid $darkgray;
            }

            .first-item {
                margin-top: -17px;
                height: 43px;
            }
        }

        .bottom-border {
            border-bottom: 1px solid $gray;
        }

        a[role=button] {
            font-family: $font-primary,$font-secondary;
            font-size: 12px;
            color: inherit;
            font-weight: 600;
        }

        .glyphicon {
            color: inherit;
            font-weight: 900;
        }

        a:focus, a:hover {
            text-decoration: none;
            outline-color: transparent;
        }

        .panel {
            border: none;
            border-radius: 0px;
            border-bottom: 1px solid $gray;
        }

        .panel-heading {
            background-color: inherit;
            font-weight: 600;
            margin-bottom: 6px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 5px;
            padding-left: 6px;

            .text-muted {
                color: inherit;
            }
        }

        .panel-group {
            margin-bottom: 0px;

            & .panel.panel {
                margin-top: 0px;
            }
        }

        .panel-body {
            padding-top: 0px;
            padding-bottom: 0px;
        }

        .right-border {
            border-right: 1px solid $darkgray;
        }

        .accordion-content {
            .row {
                .col-lg-1.col-md-1.col-sm-1.col-xs-1 {
                    width: 39px;
                    height: 22px;
                    background-color: $lightgray;

                    &.last {
                        height: 25px;
                    }
                }

                [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
                    top: 4px;
                }

                [type="checkbox"]:not(:checked) + label:after, [type="checkbox"]:checked + label:after {
                    top: 7px;
                }

                [type="checkbox"] + label {
                    padding-top: 4px;
                    padding-bottom: 0px;

                    &.last {
                        padding-bottom: 5px;
                    }
                }

                .checkbox-left-border {
                    border-left: 1px solid $darkgray;
                    position: absolute;
                    width: calc(6.9% + 37px);
                    height: 23px;
                    left: -0.5px;

                    &.last {
                        height: 25px;
                    }
                }

                .checkbox-right-border {
                    border-right: 1px solid $gray;
                    position: absolute;
                    width: calc(6.9% + 37px);
                    height: 23px;

                    &.last {
                        height: 25px;
                    }
                }
            }
        }
    }

    .worksite-print-footer {
        background-color: $lightgray;
        color: $darkgray;
        border-top: 1px solid rgb(97,97,97);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        .label-text {
            font-style: italic;
            font-weight: lighter;
            padding-left: 12px;
            padding-bottom: 11px;
            padding-top: 11px;
            margin-bottom: 0px;
            font-size: 13px;
        }
    }
}

.worksite-print-buttons {
	margin-top: 0.7em;

	.btn-silva {
		padding: 10px;
	}
}
