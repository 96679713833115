﻿@import '../../../../shared/sass/_variables.scss';

.divide-payment-modal {
    .modal-dialog {
        top: 20%;
        width: 60%;
    }


    .margin-left-10 {
        margin-left: 10px;
    }

    .top-section {
        padding: 15px;
    }

    .bottom-header {
        height: 45px;
        background-color: $gray;

        .bold-font {
            font-weight: bold;
            margin-left: 30px;
        }
    }

    .bottom-section {
        padding: 30px;
    }

    .panel-border {
        border: 0.75px solid #767876;
        border-radius: 5px;
    }

    .panel-padding {
        padding-right: 0px;
        padding-left: 0px;
    }

    .panel-header {
        //background: transparent linear-gradient(180deg, #F3F3F7 0%, #FFFFFF 0%, #E2E2E7 100%) 0% 0% no-repeat padding-box;
        background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 3px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        padding: 3px 5px;
        font-size: 12px;
        border-bottom: 0.75px solid $trimble_brand_grey
    }

    .payment-content {
        .payment-content-text {
            background-color: white;
        }

        .sp-grid {
            height: 231px;
        }
    }

    .grid-container {

        .sp-grid {
            border-radius: 0px;


            .grid-check-box {
                top: -8px;
            }

            .ag-root.ag-root {
                border-radius: 0px;
            }

            .ag-header-cell .select-all-checkbox {
                .select-all-checkbox {
                    top: -8px;
                }
            }

            .cell-header-right {
                .ag-header-cell-text {
                    float: right !important;
                }
            }
        }

        .select-all-wrapper {
            left: 3px;
        }

        .ag-header-group-text {
            text-transform: uppercase;
        }
    }
}
