﻿.timber-trade-unit-rate-modal {
    $table-border-color: #767876;

    .modal-trimble-content {
        padding: 15px 15px 0px 15px;
    }

    table {
        border-collapse: separate;
        border-spacing: 0;
        min-width: 350px;
    }

    table thead tr th,
    table tbody tr td {
        border-right: 1px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;
        padding: 5px;
        text-align: center;
    }

    .full-width {
        width: 100%;
        text-align: center;
    }

    table thead tr th:first-child,
    table tbody tr td:first-child {
        border-left: 1px solid $table-border-color;
    }

    table thead tr th {
        border-top: 1px solid $table-border-color !important;
    }
    /* top-left border-radius */
    table thead tr:first-child th:first-child {
        border-top-left-radius: 3px;
    }
    /* top-right border-radius */
    table thead tr:first-child th:last-child {
        border-top-right-radius: 3px;
    }
    /* bottom-left border-radius */
    table tbody tr:last-child td:first-child {
        border-bottom-left-radius: 3px;
    }
    /* bottom-right border-radius */
    table tbody tr:last-child td:last-child {
        border-bottom-right-radius: 3px;
    }

    .material-switch-container .material-switch-content > input[type="checkbox"]:checked + label::after, .material-switch-container .material-switch-content > input[type="checkbox"]:not(:checked) + label::after {
        top: -2px;
    }

    .pad-left-5 {
        padding-left: 5px;
    }

    .material-switch-container .material-switch-content > label::before {
        margin-top: -15px;
    }

    thead {
        background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
        text-align: center;
        text-transform: uppercase;
    }


    .assort-content {
        padding-top: 15px;
    }

    .assort-header-space {
        border-bottom: 0.75px solid #B0AFB0;
    }

    .assort-header {
        padding: 0px 15px 5px 15px;
    }

    .table-header {
        background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 3px 3px 0 0;
        padding: 3px 10px;
    }

    .label-upper {
        text-transform: uppercase;
    }
}
