﻿@import '../../../../../shared/sass/_variables.scss';

.edit-assortment-group-price-modal {

    #assortmentStemRatePriceGrid {
        border: 1px solid $half_trimble_brand_grey;
        overflow: hidden;
        border-radius: 3px 3px 3px 3px;
        height: 61px;

        .grid-header-checkbox-label {
            left: -4px;
        }

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 3px 3px 3px 3px;
        }

        .ag-theme-fresh .ag-header-cell-label {
            padding: 6px 2px !important;
        }

        .ag-header {
            background: $trimble-gradient-grey-header;
        }

        .ag-row-hover {
            .price-difference {
                color: $white;
            }

            .ag-column-hover {
                .price-difference {
                    color: $white;
                }
            }
        }

        .ag-body-viewport {
            overflow-y: hidden !important;
        }
    }

    #assortmentsGrid {
        border: 1px solid $half_trimble_brand_grey;
        overflow: hidden;
        border-radius: 3px 3px 3px 3px;
        height: 450px;

        .invalid-price {
            background-color: $lightred;
        }

        .grid-header-checkbox-label {
            left: -4px;
        }

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 3px 3px 3px 3px;
        }

        .ag-header {
            background: $trimble-gradient-grey-header;
        }

        .input-element {
            margin: -2px 0px;

            input {
                color: black;
                width: 100%;
                border: none;

                &.price-difference {
                    background-color: inherit;
                    pointer-events: none;
                    padding: 0;
                    margin: 0;
                    height: 100%;
                }

                &.input-with-border {
                    border: $grid-border;
                    border-radius: 4px;
                }
            }
        }

        .ag-row-hover {
            .price-difference {
                color: $white;
            }

            .ag-column-hover {
                .price-difference {
                    color: $white;
                }
            }
        }
    }

    .first-row {
        height: 45px;
        background-color: $gray;

        .first-row-label {
            padding-top: 15px;
        }
    }
}
