﻿@import '../../../../shared/sass/_variables.scss';

.calculate-matrix-price-window-class {
    .modal-dialog {
        top: 20%;
        width: 800px;
    }

    .calculate-matrix-price-content {
        padding: 15px;

        .width-error {
            width: 96%;
            word-break:break-all;
        }

        .notification-icon {
            margin-left: 2px;
            margin-top: -3px;
        }
    }

    ul {
        list-style-type: none;

        li:before {
            content: '\2014';
            position: absolute;
            margin-left: -20px;
        }
    }

    .assortment-header-text {
        font-weight: bold;
    }

    .padding-top-bottom {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
