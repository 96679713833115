.transferStorageQuantities {
    &.view-expanded {
        width: 100%;
    }

    .multiselect-option {
        display: flex;
    }


    .multiselection-drop-down .checkboxes .checkboxesList {
        width: 215px;
    }

    .row-no-margin .row-border {
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
    }

    .dropdown-menu.pull-right {
        left: -66px;
    }

    .refine-search-directive {
        margin-top: 6px;

        .refine-search-dropdown-directive {
            top: 64px;

            label {
                margin-bottom: 0;
            }

            .filter-date-field input {
                width: 134px;
            }

            .col-no-padding {
                padding-left: 10px;
            }

            .directive-margin {
                margin-left: 0;
            }

            .filter-date-field {
                height: inherit;

                [type="checkbox"]:not(:checked)+label:before,
                [type="checkbox"]:checked+label:before,
                [type="checkbox"]:not(:checked)+label:after,
                [type="checkbox"]:checked+label:after {
                    top: 8px;
                }
            }

            .settings-item {
                line-height: 34px;
                height: inherit;
            }

        }


        .settings-item.multiselect-margins-refine-search {
            margin-top: 0;
            margin-left: 0;
            display: block;

            .multiselect-option {
                div {
                    .multiselection-drop-down {
                        width: 248px;

                        .titleBox,
                        .checkboxes {
                            width: 248px;
                        }
                    }
                }
            }
        }
    }

    .button-wrapper {
        justify-content: flex-end;
        flex-grow: 1;
        display: flex;
    }

    .settings-item.advanced-filtering-options {
        line-height: 35px;
    }

    .col-xs-2 {
        padding-right: inherit;
        padding-left: inherit;
    }

    .grid-options-group {
        padding-right: 0px;
    }

    .expand-table-button {
        &:hover span {
            color: $trimble_tf_product_green;
        }

        span {
            color: $darkgray;
            font-size: 17px;
            vertical-align: middle;
        }

        .btn {
            width: 34px;
            padding: 6px;
        }
    }

    .grid-border {
        border-left: 1px solid #616161;
        border-right: 1px solid #616161;
        border-top: 1px solid #616161;
        border-bottom: 1px solid #616161;
        border-top-right-radius: 0.5em;
        border-top-left-radius: 0.5em;

        .ag-column-select-panel {
            .ag-column-select-label {
                font-size: 12px;
            }
        }

        .grid-header-checkbox-label {
            margin: 0;
            left: -4px;
            top: 2px;
        }

        .ag-selection-checkbox {
            padding-left: 2.5px;
        }

        .ag-floating-bottom-container {
            font-size: 12px;
            font-weight: bold;

            .ag-cell {
                background-color: $gray;
            }
        }
    }

    .select-all-wrapper {
        margin: auto;
        position: relative;
        width: 20px;
        left: 0;
        right: 0;
    }


    .saved-lists-dropdown {
        transform: translate(-50px);
    }

    .navigation-grid {
        height: 57vh;
        user-select: none !important;
    }

    .sp-grid .ag-header-group-cell-label {
        display: block;
        text-align: left;
    }


    .transfer-storage-quantities-list-footer {
        .transfer-storage-quantities-tablefoot-actionbar {
            border: 1px solid #616161;
            border-top: 0;
            border-radius: 0.5em;
            background-color: #e8e8e8;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            overflow: hidden;
            padding: 0.5em 0.5em;
        }
    }
}