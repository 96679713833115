@import '../../../../../shared/sass/_variables.scss';

.manage-investments-modal {
    .modal-dialog {
        width: 977px!important;
    }

    .margin-right-10 {
        margin-right: 10px;
    }

    .margin-top-15 {
        margin-top: 15px;
    }

    .grid-container {
        border: 0.75px solid $trimble_brand_grey;
        border-radius: 3px;

        .ag-icon-expanded,
        .ag-icon-contracted {
            margin-left: 6px;
        }

        .who-modified-icon {
            width: 16px;
            height: 16px;
            margin-bottom: 6px;
        }

        .ag-row-hover,
        .ag-row-focus {
            .who-modified-icon {
                content: url("../../../../content/images/svg/WhoModifiedIcon_white.svg");
                margin-bottom: 2px;
            }

            .form-control {
                color: white;
                background-color: transparent;
                font-size: 12px;
            }
        }

        .ag-row-no-focus {
            .form-control {
                background-color: transparent;
                font-size: 12px;
            }
        }

        .ag-floating-bottom {
            border-top: 0px !important;
        }

        .status-locked {
            float: left;
            background-image: url(../../../../content/images/svg/lock_grey.svg);
            /*background-size: 15px 15px;*/
            border-radius: 0.3em;
            cursor: pointer;
            margin: auto;
            display: block;
            height: 15px;
            width: 15px;
            margin-top: 2px;
        }

        .status-open {
            float: left;
            background-image: url(../../../../content/images/svg/lock_open.svg);
            background-size: 15px 15px;
            border-radius: 0.3em;
            cursor: pointer;
            margin: auto;
            display: block;
            height: 15px;
            width: 15px;
            margin-top: 2px;
        }

        .status-cancelled {
            float: left;
            background-image: url(../../../../content/images/svg/cancel.svg);
            background-size: 15px 15px;
            border-radius: 0.3em;
            cursor: pointer;
            margin: auto;
            display: block;
            height: 15px;
            width: 15px;
            margin-top: 2px;
        }

        .status-paid {
            float: left;
            background-image: url(../../../../content/images/svg/event_available.svg);
            background-size: 15px 15px;
            border-radius: 0.3em;
            cursor: pointer;
            margin: auto;
            display: block;
            height: 15px;
            width: 15px;
            margin-top: 2px;
        }

        .ag-row-group-indent-0 {
            padding-left: 9px !important;
        }
    }
}

@import './changeStatusModal/changeStatusModal.scss';