.no-border-right {
    border-right: 0px!important;
}

.modal-message {
    font-size: 15px!important;
}

.receipts-modal > .modal-dialog {
    width: 90%;
    height: 90%;
}

.receipts-modal > .modal-dialog > .modal-content {
    width: 100%;
    height: 100%;
    display: table-cell;
}

.common-grid .ag-header-group-cell-label {
    border-top: 0px;
}

.receipts-common-grid > .common-grid > .panel-sp-grid {
    border-radius: 0px;
    width: 1685px;

    .ag-floating-bottom-container {
        .ag-row {
            background-color: $gray;
        }
    }
}

.common-grid .common-grid-no-footer .ag-root {
    border-radius: 0px!important;
}

.receipt-nav-item {
    margin-left: 3px;
    margin-right: 3px;
    border-top-left-radius: 6px;
    padding: 0.2em 0 0.2em 0;
    border-top-right-radius: 6px;
    border-color: #616161;
    text-decoration: none;
    text-transform: none!important;
    color: black;
}

.receipt-nav-item.active {
    background-color: white;
}

.receipt-nav-item:hover {
    background: black;
    color: white;
}