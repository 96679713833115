.edit-reason-details-modal {
    .modal-dialog {
        width: 21%;
        top: 35%;
        
        .modal-body {
            padding: 0;

            .notes {
                height: 90px !important;
                width: 374px;
                resize: none;
            }
        }

        .btn-silva {
            width: 47%;
        }
    }

    .first-row {
        height: 45px;
        background-color: $gray;

        .product-name {
            height: auto;
            float: left;
            padding-left: 15px;
            padding-top: 15px;
            margin-right: 15px;
        }

        .delivery-destination {
            height: auto;
            float: left;
            padding-top: 15px;
        }        
    }

    .second-row {
        .description {
            height: auto;
            padding-left: 15px;
            padding-top: 15px;
            font-weight: bold;
            padding-bottom: 4px;
        }

        .characters-number {
            padding-right: 15px;
            padding-top: 15px;
            padding-bottom: 4px;
        }
    }

    .third-row {
        margin-bottom: 15px;
    }
}