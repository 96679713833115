﻿@import '../../shared/sass/_variables.scss';

.forest-equipment-chaining {
  
  .partial {
    border: 1px solid $darkgray;
    display: block;
    margin: 0.5em;
    width: auto;
    
    .partial-box-header {
      margin-bottom: 0;
    }
  }
  
  .left-pane {
    .sp-grid-container {
      margin: 0 0.5em 0.5em 0;
    }
    
    .notes-icon {
      margin: auto;
      display: block;
    }
    
    .fixed-checkbox-container {
      label {
        height: 18px;
        width: 18px;
        padding-left: 18px;
        margin: auto;
        display: block;
        
        &:before {
          top: 0;
        }
        &:after {
          top: 0px;
        }
      }
    }
    
    .chains-grid {
        height: 533px;
      
      .ag-floating-bottom-container {
        .ag-cell {
          border-right: none;
          &:first-of-type {
            width: 190px !important;
          }
          &:nth-of-type(3) {
            text-align: center;
          }
          &:nth-of-type(4) {
            text-align: center;
          }
        }
      }
    }
    
    .grid-buttons {
      button:first-of-type {
        flex: 1;  
      }
      button:nth-of-type(2) {
        margin-left: 0.5em; 
      }
    }
  }
  
  .right-pane {
    .chaining-container {
      .partial {
          margin: 0 0 0.5em 0.5em;
      }
      .content {
        padding: 1em;
        
        .own-machine-checkbox-label {
          &:before {
            top: 0;
          }
          &:after {
            top: 0px;
          }
        }
      }
    }    
  }
  
  .fat-button {
    padding: 10px;
    min-width: 150px;
    margin-top: 0.5em;
  }
}
