.price-matrix-modal {
    .modal-dialog {
        width: 1142px;
    }
    
    .col-lg-12 {
        padding-right: 0;
        padding-left: 0;
    }
    
    .ag-theme-fresh .ag-root {
         border: 1px solid darkgrey !important;
         border-radius: 0.5em;
    }
}