﻿@import "shared/sass/_variables.scss";
@import "shared/sass/_mixins.scss";

.uib-datepicker-popup {

  .btn.active {
    box-shadow: inset 0 3px 5px $trimble_grey_shadow;
  }
  
   .text-info {
    color: $trimble_dk_blue;
  }
  
  .text-muted {
    color: $black;
  }
  .btn-info {
    @extend .selected-calendar-item;
  }
  
  .btn-default:hover {
    @extend .hovered-calendar-item;
  }
  
  .btn-info:hover {
    @include btn-weekpicker($trimble_white, $trimble_blue_mid !important, $trimble_dk_blue !important);
  }
  
  .btn-default.active,
  .btn-default:active,
  .open > .dropdown-toggle.btn-default {
    @extend .active-calendar-item;
  }
  
  .btn-info.active,
  .btn-info:active,
  .open > .dropdown-toggle.btn-info {
    @extend .selected-calendar-item;
  }
  
  .btn-default.focus,
  .btn-default:focus {
    @extend .hovered-calendar-item;
  }
  
  .btn-info.active.focus,
  .btn-info.active:focus,
  .btn-info.active:hover,
  .btn-info:active.focus,
  .btn-info:active:focus,
  .btn-info:active:hover,
  .open > .dropdown-toggle.btn-info.focus,
  .open > .dropdown-toggle.btn-info:focus,
  .open > .dropdown-toggle.btn-info:hover {
    @extend .selected-calendar-item;
  }

  .hovered-calendar-item {
    @include btn-weekpicker(
      $trimble_grey,
      $trimble_blue_grey_light,
      $half_trimble_brand_grey
    );
  }

  .active-calendar-item {
    @include btn-weekpicker(
      $trimble_grey,
      $trimble_blue_grey_light !important,
      $half_trimble_brand_grey !important
    );
    box-shadow: inset 0 3px 5px $trimble_grey_shadow;
  }

  .selected-calendar-item {
    @include btn-weekpicker($trimble_white, $trimble_blue_light !important, $trimble_blue !important);
  }
}
