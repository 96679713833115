@mixin btn-silva($color, $background-color) {
    color: $color;
    background: $background-color;
    font-weight: 700;
    font-size: 14px;
    //background temporary removed
    //box-shadow: 0 0 3px $half_trimble_brand_grey, 0 0 3px $half_trimble_brand_grey;
    border-radius: 1px;
    text-transform: uppercase;
    border: none;
    //line-height: 12px;
    //padding: 9px 15px;
    border: 1px solid $background-color;
}

@mixin btn-weekpicker($color, $background-color, $border-color) {
    color: $color !important;
    background-color: $background-color;
    border-color: $border-color;
}

@mixin flex-grow($number: 1) {
  flex-grow: $number !important;
}

@mixin flex($flex-direction, $justify-content, $align-items) {
    display: flex;
    flex-direction: $flex-direction;
    justify-content: $justify-content;
    align-items: $align-items;
}

/*
* Adds double border to top of the element. 
* `border-style: double` won't cut it as we need to have space between the lines.
*/
@mixin double-border-top(/*border size in pixels*/$size, /*border color*/$color){
    border-top: $size solid $color;
    box-shadow: 0 0 0 $size white, 0 0 0 ($size * 2) $color;
}

@mixin opacity(/* number between [0, 1] */$decimal){
    filter: alpha(opacity=( $decimal * 100 )); // IE 5-7
    opacity: $decimal;
}