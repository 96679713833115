$smaller-font-szie: 12px;

.cfb-invoices-tab {
    margin-top: -10px;

    .cfb-invoice {
        padding: 5px 0 5px 0;
        display: flex;
    }

    .no-record {
        font-style: italic;
        color: $trimble_brand_grey;
    }

    .no-record-found {
        min-height: 192px;
        padding-top: 10px;
    }

    .status-width {
        width: 130px;
    }

    .row {
        div[class^="col-"] {
            padding: 0 5px;
        }

        div[class*="additional-info"] {
            text-transform: uppercase;
            padding: 10px;
            background-color: $gray;

            * {
                display: inline;
            }
        }
    }

    input,
    label {
        margin: 0;
        width: 100%;
        line-height: 16px;
        padding: 6px 4px 5px 6px;
    }

    input {
        height: 27px;
    }

    .invoice-numbering {
        width: 40px;
    }

    .invoice-controls {
        margin-left: 20px;
    }

    .index {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #0e77aa;
        color: #fff;
        display: inline-block;
        width: 27px;
        height: 27px;
        text-align: right;
        padding-right: 7px;
        padding-top: 4px;
        margin-left: -15px;
    }

    .void-button {
        width: 100%;
        padding: 6px 0; //the width of the button is quite small and text is aligned in the middle, therefore we can put 0 left and right padding
    }

    .additional-info-text-area {
        margin-top: 0;
        resize: none;
        padding: 6px;
    }

    .row-bottom-spacing {
        margin-bottom: 10px;
    }

    .row-smaller-bottom-spacing {
        margin-bottom: 7px;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .text-align-right {
        text-align: right;
    }

    .reference-line {
        margin-top: 0;
        min-height: 50px;
        max-height: 50px;
        resize: none;
        padding: 6px;
    }

    .text-overflow-ellipsis {
        text-overflow: ellipsis;
    }

    .smaller-font {
        font-size: $smaller-font-szie;
    }

    @media screen and (max-width: 1600px) {

        //for smaller screens reduce the fot size
        .btn-silva {
            font-size: $smaller-font-szie;
        }
    }
}