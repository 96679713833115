﻿@import '../../../../../shared/sass/_variables.scss';

.edit-assortment-group-modal {

    .treeSpeciesDropDown {
        border-color: #d1d1d1;
        color: black;
        background-color: white;
        border-radius: 3px;
        height: 30px;
        width: 150px;
        margin: 0px 20px;
    }

    .padding-top-bottom {
        padding-bottom: 20px;
        padding-top: 5px;
    }

    #assortmentsGrid {
        border: 1px solid $half_trimble_brand_grey;
        overflow: hidden;
        border-radius: 3px 3px 3px 3px;
        height: 300px;

        .grid-check-box {
            top: -8px;
        }

        .grid-header-checkbox-label {
            left: -4px;
        }

        .select-all-wrapper {
            padding-top: 4px;
        }

        .ag-selection-checkbox {
            margin-left: 2.5px;
        }

        .ag-root.ag-font-style.ag-scrolls {
            border-radius: 3px 3px 3px 3px;
        }

        .ag-header {
            background: $trimble-gradient-grey-header;
        }

        .ag-header-cell {
            padding-top: 2px;
        }

        .ag-cell {
            padding-top: 5px !important;
        }

        .ag-row-hover {
            .price-difference {
                color: $white;
            }

            .ag-column-hover {
                .price-difference {
                    color: $white;
                }
            }
        }
    }
}
