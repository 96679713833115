@import '../../../../shared/sass/_variables.scss';

.stand-browser-directive,
.real-estate-browser-directive {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 30px;

    .noborder {
        border: 0 solid transparent !important;
        background: transparent !important;
        position: absolute;
        top: 7px !important;
        right: 13px;
    }

    .glyphicon-remove {
        border: 0 solid transparent;
        background: transparent;
    }

    .product-column {
        width: 50px;
    }

    .browser-topic {
        margin-right: 1em;
        text-transform: uppercase;
        font-weight: bold;
        flex-grow: 1;
    }

    .browsing-button {
        height: 1.5em;
        width: 1.5em;
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        margin-right: 0.5em;

        &:hover {
            cursor: pointer;
        }

        &.disabled {
            opacity: 0.4;
            pointer-events: none;
        }

        &:last-child {
            margin-right: 0;
        }

        &.step-to-first {
            background-image: url('/content/images/svg/Step-to-first.svg');

            &:hover {
                background-image: url('/content/images/svg/Step-to-first-MouseOver.svg');
            }
        }

        &.step-to-last {
            background-image: url('/content/images/svg/Step-to-last.svg');

            &:hover {
                background-image: url('/content/images/svg/Step-to-last-MouseOver.svg');
            }
        }

        &.step-forward {
            background-image: url('/content/images/svg/Step-forward.svg');

            &:hover {
                background-image: url('/content/images/svg/Step-forward-MouseOver.svg');
            }
        }

        &.step-backward {
            background-image: url('/content/images/svg/Step-backward.svg');

            &:hover {
                background-image: url('/content/images/svg/Step-backward-MouseOver.svg');
            }
        }
    }

    .selected-stand {
        display: flex;
        width: 9.5em;
        border: 1px solid $darkgray;
        border-radius: 4px;
        align-items: center;
        margin-right: 0.5em;
        background-color: $trimble_white;

        > div {
            display: inline-block;
            padding: 0.2em;

            .glyphicon-play {
                transform: rotate(90deg);
            }
        }

        .down-arrow {
            font-size: 50%;
            color: $darkgray;
            margin-right: 0.5em;
        }

        .stand-number {
            flex-grow: 1;
        }
    }

    .selected-plot {
        display: flex;
        width: 9.5em;
        border: 1px solid $darkgray;
        border-radius: 4px;
        align-items: center;
        margin-right: 0.5em;

        > div {
            display: inline-block;
            padding: 0.2em;

            .glyphicon-play {
                transform: rotate(90deg);
            }
        }

        .down-arrow {
            font-size: 50%;
            color: $darkgray;
            margin-right: 0.5em;
        }

        .plot-number {
            flex-grow: 1;
        }
    }

    .second-browser-dropdown {
        top: 63px !important;
    }

    .plot-browser-dropdown {
        width: 410px !important;
        top: 33px !important;
    }

    .browser-dropdown {
        display: flex;
        flex-direction: column;
        min-height: 44px;
        max-height: 310px;
        overflow: hidden;
        border: 1px solid $darkgray;
        position: absolute;
        background-color: white;
        z-index: 810; // needs to be above the panel, not under it
        width: 470px;
        top: 35px; // alignment to the select dropdown divs
        border-radius: 0.5em;

        .search-bar {
            display: flex;
            margin: 0.5em;
            min-height: 2em;

            span {
                border: 1px solid $darkgray;
                border-top-left-radius: 0.5em;
                border-bottom-left-radius: 0.5em;
                border-right: 0;
                top: 0;
                padding-left: 0.5em;
                display: flex;
                align-items: center;
                height: 27px;
            }

            input {
                margin-left: 0;
                border-radius: 0.5em;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border: 1px solid $darkgray;
                border-left: 0;
                height: 27px;
                padding: 0.2em;
                flex-grow: 1;

                &:focus {
                    box-shadow: none;
                    border-color: $darkgray;
                }
            }
        }

        .result-rows {
            overflow-y: auto;
            flex-grow: 1;

            table {
                width: 100%;

                td {
                    white-space: nowrap;
                    padding: 0.2em 0.5em;
                    overflow: hidden;

                    &.long-text {
                        max-width: 210px;
                    }

                    &.short-text {
                        max-width: 120px;
                    }

                    &.product-column {
                        width: 50px;
                    }
                }

                tr:not(.mts-header-row) {
                    &:hover {
                        background-color: black;
                        color: white;
                    }
                }

                .mts-header-row {
                    background-color: $gray;
                }
            }
        }
    }
}

.real-estate-browser-directive {
	.selected-stand {
		width: 14em;
	}
}

.plot-browser-view {
    max-width: 415px !important;
    margin-top: 0.30em !important;
    margin-right: 0.45em !important;
    overflow: hidden !important;
}

.browser-container {
    overflow: hidden !important;
    display: inline-table;
    border: none;

    .browser {
        margin-right: 1em;
        width: 415px;
    }

    .browser,
    .basic-info {
        display: inline-block;
    }

    .basic-info {
        @media (max-width: 1000px) {
            padding-top: 1em;
        }
    }

    .small-green-font {
        color: $green;

        label {
            text-transform: uppercase;
            font-weight: bold;
        }

        a {
            text-decoration: underline;
        }

        a, span {
            color: $black;
        }
    }
}