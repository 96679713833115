.assortments-groups-modal {

    .modal-dialog {
        width: 900px;
    }

    #assortmentsGroupsModal {
        border-radius: 0.5em;
        border: 1px solid #616161;

        .ag-root {
            border-radius: 0.5em;
        }

        .ag-selection-checkbox {
            padding-left: 2px;
        }

        .ag-body-container {
            [type="checkbox"]:checked+label:after {
                top: 3px;
            }

            [type="checkbox"]:not(:checked)+label:before,
            [type="checkbox"]:checked+label:before {
                top: 0;
            }
        }

        .assortments-tooltips p {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 500px;
            display: block;
            text-align: left;
        }
    }

    .modal-footer {
        padding-left: 15px;
        padding-right: 15px;
    }
}