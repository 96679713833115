﻿.file-attachments {
    .attachment-grid-container {
        .attachmentGrid {
            border: 1px solid #616161;

            .icon-printer {
                background-color: #009AD9;
                background-image: url(../../content/images/svg/material-print-white-36dp.svg);
                color: #FFFFFF;
                background-size: 22px 22px;
                border-radius: 0.3em;
                cursor: pointer;
                margin: auto;
                display: block;
                height: 22px;
                width: 22px;
            }

            .disable-anchor {
                pointer-events: none;
                opacity: 0.5;
            }

            .icon-printer:hover {
                background-color: #005F9E;
            }

            .cell-header-center {
                .ag-header-cell-text {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}
