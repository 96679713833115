﻿@import '../../../../shared/sass/_variables.scss';

.edit-payment-deduction {
    .margin-left-10 {
        margin-left: 10px;
    }

    .top-section {
        padding: 30px;
    }

    .bottom-header {
        height: 45px;
        background-color: $gray;

        .bold-font {
            font-weight: bold;
            margin-left: 30px;
        }
    }

    .bottom-section {
        padding: 30px;

        .form-control {
            height: 27px;
        }

        .note-label {
            margin-top: 6px;
        }

        .note {
            min-height: 100px;
            resize: none;
            margin-top: 10px;
        }
    }
}
