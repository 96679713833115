﻿@import '../../../../shared/sass/_variables.scss';

.change-status-additional-info-modal {

    .modal-dialog {
        top: 250px;
        width: 525px;
    }

    .container-fluid {
        padding: 15px;

        .row {
            padding: 5px 0px;
        }

        .no-margin-top {
            margin-top: 0px;
        }
    }

    .textarea {
        max-height: 215px;
        resize: none;
    }

    .additional-information {
        background-color: $lightgray;
        height: 45px;
    }
}

.change-status-error-dialog {
    z-index: 2000 !important;
}
