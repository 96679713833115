﻿@import '../../shared/sass/_variables.scss';
$create-work-site-area-margins: 10px;
$create-work-site-modal-width: 750px;

.create-work-site-modal .modal-dialog {
    width: $create-work-site-modal-width;

    .customer-create-work-site {
        font-family: $font-family;
        font-size: $default-font-size;

        .modal-content {
            .title-bar {
                > div:not(.clearfix) {
                    padding: 1em;
                }

                .header {
                    font-family: $font-semibold;
                    margin: 0;
                    position: relative;
                    text-transform: uppercase;
                    width: auto;
                }

                .glyphicon-remove:hover {
                    color: $blue;
                    cursor: pointer;
                }
            }

            .modal-body {
                padding: 0px 15px 0px 15px;

                .area-title-text {
                    font-family: "Myriad Pro Semibold";
                    padding-left: 1em;
                    padding-top: 1.9px;
                }

                .area-padding {
                    padding-top: $create-work-site-area-margins;
                    padding-bottom: $create-work-site-area-margins;
                }

                .right-vertical-separator {
                    border-right: 1px solid #d1d1d1;
                }

                .top-separator-height{
                    height: 236px;
                }

                .no-padding {
                    padding: 0px;
                }

                .in-line-form-group {
                    margin: 0px;
                    text-align: center;
                    vertical-align: middle;
                }

                .in-line-form-group:not(:first-child) {
                    margin-left: 2px;
                }

                .in-line-form-group:not(:last-child) {
                    margin-right: 2px;
                }

                .in-line-form-group-text {
                    margin-top: 20px;
                }

                .no-border-radius-right {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                .no-border-radius-left {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                }

                .form-group-margin {
                    margin-top: 15px;
                    margin-left: -8px;
                }

                .arrow-container {
                    height: 20px;

                    .forest-plan-position {
                        position: absolute;
                        height: 21px;
                        width: 107%;
                        left: 6px;
                        top: 8px;
                    }
                }

                .estate-area {
                    .checkbox-wrapper {
                        display: flex;
                        flex-direction: row;
                        margin-top: 10px;
                        .chackebox-label {
                            margin-top: 8px;
                        }
                    }

                    .forest-compartments-group {
                        padding-left: 6px;
                    }

                    .radio-buttons-group-padding {
                        padding-top: 31px;
                    }

                    .remove-padding-top {
                        padding-top: 0px;
                    }

                    .area-title {
                        width: $create-work-site-modal-width - 3px;
                    }

                    .row {
                        .right-vertical-separator {
                            .form-horizontal {
                                padding-left: 3px;
                            }
                        }
                    }

                    .radio {
                        min-height: 0px;

                        label {
                            font-family: "Myriad Pro";
                            font-size: 12px;
                            font-weight: 700;
                        }
                    }

                    input[type=text] {
                        text-align: center;
                    }

                    .real-estates-select-align {
                        padding-left: 9px;
                    }
                }

                .form-horizontal {
                    padding-left: 8px;
                }
            }

            .modal-footer {
                .btn {
                    width: 100%;
                }
            }
        }

        .area-title {
            background-color: #d1d1d1;
        }
        
        .work-site-customer-notes {
            margin-top: 0px;
            resize: none;
            height: 54px;
        }
    }
}
