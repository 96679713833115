.filter-type {
    .list-filter {
        display: flex;
        align-items: center;

        label {
            flex: 2;
        }

        div {
            flex: 98;
            //margin-right: 6px;

            .multiselection-drop-down {
                width: auto;

                div {
                    //margin-right: 0;

                    .titleBox {
                        width: auto;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .grid-filter {
        display: flex;

        label {
            flex: 2;
        }

        multiselection-grid {
            flex: 98;
           // margin-right: 6px;

            .multiselection-grid-wrapper {
                width: auto;

                .titleBox {
                    width: auto;
                }
            }
        }
    }

    .select-municipality {
        select {
            width: 125px;
            height: 30px;
        }
    }

    .forest-plan {
        margin: 0.5em 0.2em 0.5em 0;

        input {
            height: 35px;
            width: 99px;
            border-radius: 5px;
        }
    }

    .radio-margin{
        margin-left: 3px;
        margin-right: 20px;
    }
}
