selected-definition {
	span, a {
		text-transform: uppercase;
	}

	.active-advanced-filter-modal {
		float: right;
		margin-left: 7px;
	}

	.is-opened {
		display: block;
	}	
}