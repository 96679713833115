﻿.correction-settings {	
    .selected-definition-label{
		line-height: 12px;
		margin-top:0.2em;
	}
	.radio-group { 
	    margin-top: 0.5em; 
	}

	.radio-col {
	    margin-top: 2px;
	}

    .content {
		input[type=radio] {
			margin-top: 0px;
			margin-bottom: 0px;
			height: initial;
			box-shadow: none;
		}
	}

    .header-div {
		margin-bottom: 0.5em; 
	}

	.btn-spacing {
		margin-right: 10px !important;
	}

	.navigation-grid {
		height: 34vh;		
	}

	.percentage-grid {
		height: 105px;		
	}

	.header-right {
		text-align: right;
		padding-right: 2px;
	}
}