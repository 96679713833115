@import './manangingHaravesting.scss';

.transportation-woodchipping-storage-amounts {
    margin-top: 0.5rem;

    .list-view-settings-row {
        .right-settings {
            width: 20%;
            align-items: flex-end;
        }
        .left-settings{
            width: 80%;
            align-items: flex-end;
        }
        .checkbox-group {
            .checkbox-setting:first-child {
                margin-bottom: 5px;
            }
        }

        .advanced-filtering-dropdown .dropdown-content {
            height: 395px;

        }

    }

    .transportation-woodchipping-grid {
        .sp-grid {
            height: calc(50vh + 35px);
        }

        .common-grid {
            .ag-group-cell {
                overflow: hidden !important;
            }
        }
    }

    &.view-expanded {
        width: 100%;
    }

    sp-common-grid-header {
        text-transform: uppercase;
    }

    .tooltips {
        width: inherit;
        height: inherit;
        padding: 3px;
    }

    [col-id=auto] {
        padding: 0 !important;
    }

    .ag-row-level-0 {
        &.ag-row-hover {
            .ag-cell-focus .tooltips .timber-truck-icon-empty {
                content: url(../../content/images/svg/TimberTruck_Blue.svg);
            }

            .ag-cell-no-focus .tooltips .timber-truck-icon-empty {
                content: url(../../content/images/svg/TimberTruck_Blue.svg);
            }
        }

        &.ag-row-selected {
            .ag-cell-no-focus .tooltips .timber-truck-icon-empty {
                content: url(../../content/images/svg/TimberTruck_Blue.svg);
            }

            .ag-cell-focus .tooltips .timber-truck-icon-empty {
                content: url(../../content/images/svg/TimberTruck_Blue.svg);
            }
        }
    }

    .ag-row-level-1 {
        &.ag-row-hover {
            .ag-cell-focus .tooltips .timber-truck-icon-empty {
                content: url(../../content/images/svg/TimberTruck_Blue.svg);
            }

            .ag-cell-no-focus .tooltips .timber-truck-icon-empty {
                content: url(../../content/images/svg/TimberTruck_Blue.svg);
            }
        }

        &.ag-row-selected {
            .ag-cell-no-focus .tooltips .timber-truck-icon-empty {
                content: url(../../content/images/svg/TimberTruck_Blue.svg);
            }

            .ag-cell-focus .tooltips .timber-truck-icon-empty {
                content: url(../../content/images/svg/TimberTruck_Blue.svg);
            }
        }
    }

    .center {
        text-align: center;
    }

    .common-grid {
        .custom-filter {
            input {
                border-radius: 3px;
                border: 1px #616161 solid !important;
                margin-top: 3px;
                padding: 1px 5px;
            }
        }

        .ag-header-group-cell-label {
            border: none;
            text-transform: uppercase;
        }

        .ag-row:not(.ag-row-group) {
            cursor: pointer;

            input {
                cursor: pointer;
            }
        }

        input.grid-input {
            background-color: inherit !important;
        }

        .image {
            height: 20px;
            margin-top: -5px;

            &.ban {
                margin-top: -19px;
            }
        }

        .timber-truck-icon {
            margin: 0 auto;
            width: 29px;
            height: 16px;
            display: block;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("/content/images/svg/TimberTruck_Black.svg");
        }

        .timber-truck-icon-empty {
            margin: 0 auto;
            width: 29px;
            height: 16px;
            display: block;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        option[value="number:0"] {
            background-image: url("/content/images/svg/TimberTruck_Blue.svg");
        }

        option[value="number:1"] {
            background-image: url("/content/images/svg/TimberTruck_Blue.svg");
        }

        option[value="number:2"] {
            background-image: url("/content/images/svg/TimberTruck_Blue.svg");
        }

        option[value="number:3"] {
            background-image: url("/content/images/svg/TimberTruck_Blue.svg");
        }

        .expand-button {
            height: 35px;
        }
    }

    &.view-expanded {
        .saved-lists-dropdown {
            left: -106% !important;
            top: 100% !important;
        }
    }

    .fine-border {
        cursor: pointer;
    }

    .sp-common-grid-toolbar-checkboxes {
        label {
            height: 20px;
        }
    }

    .sp-common-grid-toolbar-item {
        label {
            max-width: 350px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .saved-lists-dropdown-storage .favorite-radio-inline input {
        margin: 0;
    }

}