﻿@import '../../../../shared/sass/_variables.scss';

.select-timber-trade-modal-window {
    $grid-header-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    .execute-button {
        margin-left: 5px;
    }

    .title-bar {
        > div:not(.clearfix) {
            padding: 1em;
        }

        .header {
            font-family: $font-semibold;
            margin: 0;
            position: relative;
            text-transform: uppercase;
            width: auto;
        }

        .glyphicon-remove:hover {
            color: $blue;
            cursor: pointer;
        }
    }


    .search-bar {
        border: 1px solid $gray;
        border-radius: 0.5em;
        height: 2em;
        line-height: 2em;
        overflow: hidden;
        padding: 0 0.4em;
        margin: 1em;

        input {
            border: 0;
            height: 2em;
            outline: 0;
            padding-left: 0.2em;
            width: 93%;
        }

        span.glyphicon-remove {
            margin-right: 0px;
        }
    }

    .grid-container {
        margin: 1em;

        .modal-grid {
            border: 1px solid $darkgray;
            border-radius: 0.5em;            

            #centerRow {
                border-radius: 0.5em !important;
            }

            .ag-header-group-cell-label {
                text-transform: uppercase;
            }

            .master-cell-header, .ag-header-group-cell, .ag-row, .ag-body-container {
                width: 100% !important;
                border-right: 0;
            }
        }

        .timber-trades-grid {
            height: 300px;
        }        

		.ag-scrolls{
			.ag-header-row{
				position: static;
				.ag-header-group-cell-with-group{
					position: relative;
				}
			}
		}
    }

    .form-group {
        padding: 0;
    }

    .border-top {
        border-top: 1px solid $gray;
    }

    .border-bottom {
        border-bottom: 1px solid $gray;
    }

    .sp-grid {

        .grid-check-box {
            top: -9px;
        }

        .ag-header-cell {
            text-align: center;
            border-right: none;
        }

        .main-header {
            text-transform: uppercase;
            width: 570px !important;
        }

        .ag-row {
            .ag-row-disabled {
                background-color: $lightgray;
            }
        }

        .ag-header-group-cell-label, .ag-header-cell-label {
            text-align: left;
        }
    }

    .ag-header-container {
        border-right: none;
        border-bottom: none;
    }

    [type="checkbox"]:not(:checked) + label:before, [type="checkbox"]:checked + label:before {
        /*left:3px;*/
    }
}

