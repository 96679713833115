.common-grid {
    .bank-account-wrapper {
        height: 100%;
        
        input.iban {
            width: 150px;
            display: inline-block; 
            padding: 0.2em 0;
            height: 100%;
            border-radius: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0);
            box-shadow: inset 0 1px 1px rgba(0,0,0,0);
        }
        
        select.bic,
        input.bic {
           width: 90px;
           display: inline-block; 
        }
        input.bic {
            height: 100%;
            padding: 2px 12px;
        }
    }
    
    .account-exists-tooltip {
        visibility: visible !important;
        white-space: normal;
    }
    
    .invalid-input-field {
        input {
            background-color: $lightred !important;
        }
    }

    span.tooltiptext{
        white-space: normal;
    }
}