@import '../../../../shared/sass/variables';

.support-header ~ .main-view-container {
    min-height: calc(100vh - 130px) !important;
}

.main-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

    .partial-container.border {
        border-right: 0;
    }
}

.support-wrapper {
    display: flex;
    width: 75vw;
    justify-content: center;

    .support-request-information {
        P {
            margin: 0 0 18px;
        }
    }

    .left-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .right-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 10px;
    }

    .captcha-area {
        width: 50%;
    }


    .control-label.required::after {
        color: $trimble_blue;
    }

    .form-control {
        height: 32px;
        border: 1px solid $trimble_grey_light;
        padding: 5px 12px 1px 12px;
        margin-bottom: 4px;
    }

    label {
        color: $trimble_grey;
    }

    .bottom {
        display: flex;
        flex-direction: column;

        label {
            font-weight: normal;
        }

        .attachment {
            display: flex;
            flex-direction: row;
            margin-top: 15px;

            button {
                background-color: $trimble_very_light_grey;
                border: none;
            }

            .attachment-icon {
                background-image: url(../../../content/images/svg/Attachment_ButtonIcon_Grey_14x18px.svg);
                color: $trimble_grey;
                cursor: pointer;
                display: block;
                height: 24px;
                width: 24px;
                margin-right: 10px;
                margin-left: 5px;
                background-repeat: no-repeat;
            }
        }
    }

    .buttons-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 5px;
        width: 50%;

        .btn {
            width: 100px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child {
                margin-right: 15px;
            }
        }
    }
}

.landing-container .support-container {
    @extend .main-wrapper;
}