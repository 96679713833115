.settingsWrapper {
    .locale-selection {
        padding: 1em 3em;

        span {
            font-weight: bold;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.settings {
    .partial-header {
        @extend %silva-header;
    }

    &.partial {
        padding: 0px;
    }
}