﻿@import '../../../../shared/sass/_variables.scss';

.timber-trade-unit-rate {
    .grid-edit-modal .body-row .the-body .body-column label {
        border-color: transparent;
        width: 1px;
    }

    .unit-rate-grid-modal {
        width: 60%;
    }

    .grid-edit-modal {
        width: 60%;
    }

    .grid-edit-modal .the-header {
        width: 100%;
    }

    .grid-edit-modal .body-row .the-body {
        width: 100%;
    }

    .difference-explanation-width {
        width: 33%;
    }

    .amount-percentage-width {
        width: 33%;
    }

    .max-unit-price-width {
        width: 33%;
    }

    .selected-definition, .view-option-select, .dropdown-menu {
        width: 100%;
    }

    .select-option-label {
        font-size: 12px;
        font-weight: 600;
        height: 28px;
        padding-top: 6px;
        font-family: "Myriad Pro Semibold";
    }

    .navigation-section {
        height: 32px;

        .dropdown-menu {
            margin-top: 0px;
            position: sticky;

            @media screen and (max-width: 1300px) {
                width: 218px;
            }
        }
    }

    .lbl-marl {
        padding-left: 10%;
    }

    .dropdown-toggle {
        width: 100%;
        height: 30px;
        padding: 3px 6px;
        border: 1px solid $half_trimble_brand_grey;
    }

    .unit-rate-grid {
        height: 580px;
    }

    .content {
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid $half_trimble_brand_grey;
        border-radius: 3px;
        margin-top: 15px;

        .panel-header {
            background: linear-gradient(rgba(0, 0, 0, 0.075), #d3d3d3);
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 0 0 0 0;
            padding: 6px 3px;
            border-bottom: 1px solid #808080;
            height: 30px;
        }
    }

    .ag-theme-fresh .ag-ltr .ag-selection-checkbox {
        padding-left: 2px;
    }

    .ag-theme-fresh .ag-header-cell-label {
        padding: 6px 2px;
    }

    .content .grids .grid-border .ag-cell [type="checkbox"]:not(:checked) + label::after, .content .grids .grid-border .ag-cell [type="checkbox"]:checked + label::after {
        top: -2px !important;
    }

    .ag-cell.disabled {
        background-color: inherit !important;
    }

    .ag-cell.disabled.ag-cell {
        border-bottom: none !important;
    }

    [type="checkbox"]:checked + label:before {
        border: none;
    }

    [type="checkbox"]:not(:checked) + label:before {
        border: none;
    }

    .content .grids .grid-border .sp-grid .ag-root.ag-root {
        border-radius: 0em !important;

        .material-switch-content > label::before {
            margin-top: -14px;
        }
    }
    
    .customers-tablefoot-actionbar {
        border: 1px solid $half_trimble_brand_grey;
        border-top: 0;
        border-radius: 0.5em;
        background-color: $lightgray;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        padding: 0.5em 0.5em;
        height: 50px;

        button {
            margin-right: 0.5em;

            &.no-margin {
                margin-right: 0;
            }
        }
    }
}
