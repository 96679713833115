﻿@import '../../../shared//sass/_variables.scss';

.mixed-assortments {
    .additional-information {
        background-color: $gray;
        font-style: italic;
        padding: 1em;
    }

    .product-destination-section {
        background-color: $lightgray;
        padding: 1em;
    }

    .radio-text {
        bottom: 2px;
        position: relative;
        padding-left: 2px;
    }

    .margin-top-8 {
        margin-top: 8px;
    }

    .mar-5 {
        margin: 5px;
    }

    .margin-top-10 {
        margin-top: 10px;
    }

    .margin-bottom-5 {
        margin-bottom: 5px;
    }

    .border {
        border: 0.5px solid $half_trimble_brand_grey;
    }
}
